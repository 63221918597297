import RepeatAuditExtendStore from "./repeatAuditExtendStore"
import { observable, action, runInAction } from 'mobx'
import { Stores } from 'src/stores'
import _ from 'lodash'
import contentMgTypes from 'src/types/contentMgTypes';
import contentMgApi from "src/apis/contentMgApi";
import { l } from 'mam-core-react'
import { message } from 'antd';
import ccTypes from "src/types/ccTypes"

export default class RepeatAuditStore extends RepeatAuditExtendStore {
    @observable
    public selectedEntity: contentMgTypes.repeatAuditTypes.IQueryResultData 
    @observable
    public rejectModalVisible: boolean = false //打回
    @observable
    public isRejectOne: boolean = false //是否是打回当前一个素材
    @observable
    public clipsourceList: contentMgTypes.repeatAuditTypes.IClipsourceListItem[] 
    @observable
    public advanceFields: contentMgTypes.repeatAuditTypes.IAdvanceFieldItem[] = [
        {         
            alias: l('repeatAudit.repeat','重复'),
            controlData: JSON.stringify({"":"全部","true":"是","false":"否"}),
            controlType: 8,
            fieldName: "zj_duplicate",
            value: "",
            displayValue: "",
            nexus:"AND",
        },
        {
            alias: l('entity.column','栏目'),
            controlData: "{}",
            controlType: 8,
            fieldName: "column",
            value: "",
            displayValue: "",
            nexus:"AND",
        },
        {
            alias: l('entity.type','类型'),
            controlData: "{}",
            controlType: 8,
            fieldName: "type_",
            value: "",
            displayValue: "",
            nexus:"AND",
        },
        {
            alias: l('entity.importUser','版本'), //版本
            controlData: "{}",
            controlType: 8,
            fieldName: "createUser_",
            value: "",
            displayValue: "",
            nexus:"AND",
        },
        {
            alias: l('entity.status','来源'), // 来源
            controlData: "{}",
            controlType: 8,
            fieldName: "pickstatus",
            value: "",
            displayValue: "",
            nexus:"AND",
        },
        {
            alias: l('entity.importTime','入库时间'),
            controlType: 16,
            fieldName: "createDate_",
            value: "",
            displayValue: "",
            nexus:"AND",
        },
    ]
    //  x
    @observable
    public topNav: string = 'waited'   //待审任务waited/审核通过passed/审核不通过rejected
    @observable
    public waitTotal: number = 0
    //  x
    /*列表选中*/
    @observable
    public listSelectedRowKeys: any = []
    //  x
    @observable
    public listSelectedRows:any = []
    @observable
    public keyword: string = ''
    @observable
    public page: number = 1
    @observable
    public size: number = 30
    @observable
    public params: contentMgTypes.repeatAuditTypes.IQueryReq
    @observable
    public queryListRes: contentMgTypes.repeatAuditTypes.IQueryRes
    @observable
    public childrenList = {}
    @observable
    public isSearching: boolean = false
    @observable
    public showSearch: boolean = false
    
    public constructor(stores: Stores) {
        super(stores);
        this.initEntityStore();
        this.initReviewInfoStore();
    }

    @action
    public setSelectedEntity(selectedEntity: contentMgTypes.repeatAuditTypes.IQueryResultData) {
        this.selectedEntity = selectedEntity
    }

    @action
    public setRejectModalVisible(rejectModalVisible:boolean, isRejectOne: boolean) {
        this.rejectModalVisible = rejectModalVisible
        this.isRejectOne = isRejectOne
    }

    @action
    public setTopNav(topNav: string) {
        this.topNav = topNav
        this.clearSearch()
    }
    @action
    public setKeyword(keyword:string) {
        this.keyword = keyword
    }
    @action
    public setPage(page:number) {
        this.page = page
    }

    /* 清空筛选条件 */
    @action
    public clearSearch() {
        this.keyword = ""
        this.advanceFields.map((o)=>{
            o.value = ""
        })
    }
    /* 设置查询参数 */
    @action
    public setParams(params?: contentMgTypes.repeatAuditTypes.IQueryReq) {
        if(params){
            this.params = params
        }
    }
    /*列表选中项keys及设置选中的id*/
    @action
    public setListSelectedRowKeys=(selectedRowKeys:any,selectedRows:any[])=>{
        this.listSelectedRowKeys = selectedRowKeys
        this.listSelectedRows = selectedRows
    }
    @action
    public setChildrenList = (contentId:string,childrenList:contentMgTypes.repeatAuditTypes.IChildrenListItem[] | undefined) =>{
        this.childrenList[contentId] = childrenList
    }
    /*列表清空选中*/
    @action
    public clearListSelected=()=>{
        this.listSelectedRowKeys = []
        this.listSelectedRows = []
    }
    /* 获取全部来源 */
    @action
    public getClipsourceList(){
        return new Promise((resolve, reject) => {
            contentMgApi.repeatAuditApi.getClipsourceList().then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.clipsourceList = res.data.data
                        let clipsourceControlData = {
                            "": "全部"
                        }
                        if(this.clipsourceList){
                            this.clipsourceList.map((o)=>{
                                clipsourceControlData[o.id] = o.name
                            })
                        }
                        this.initFieldsControlData(4,clipsourceControlData)
                        resolve(true)
                    }else {
                        reject()
                    }
                })
            }, (res) => {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    /*获取所有元数据配置项：频道、栏目、版本说明*/
    @action
    public getAllMetadataFieldSet(){
        // this.getMetadataFieldSetFromId(-3,3) //-3频道
        this.getMetadataFieldSetFromId(-2,1) //-2栏目
        this.getMetadataFieldSet('version_description_centermam',3)
    }
    /* 条件筛选项 */
    @action
    public initFieldsControlData(index: number, controlData: any){
        this.advanceFields[index].controlData = JSON.stringify(controlData)
    }
    /* 获取单个元数据配置项 */
    @action
    public getMetadataFieldSet(fieldName: string, index: number){
        return new Promise((resolve, reject) => {
            contentMgApi.repeatAuditApi.getMetadataFieldSet(fieldName).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        let controlData = {"": "全部"}
                        if(res.data.data.length > 0){
                            res.data.data.map((o: ccTypes.IMetadataFieldSetItem)=>{
                                controlData[o.code] = o.name
                            })
                        }
                        this.initFieldsControlData(index,controlData)
                        resolve(true)
                    }else {
                        reject()
                    }
                })
            }, (res) => {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 依据id获取单个元数据配置项: 栏目/频道等特殊枚举字段 */
    @action
    public getMetadataFieldSetFromId(fieldId: number, index: number){
        return new Promise((resolve, reject) => {
            contentMgApi.repeatAuditApi.getMetadataFieldSetFromId(fieldId).then((res) => {
                  runInAction(() => {
                      if (res.data.data) {
                          let controlData = {"": "全部"}
                          if(res.data.data.length > 0){
                              res.data.data.map((o: contentMgTypes.repeatAuditTypes.IMetadataFieldSetFromIdItem)=>{
                                  controlData[o.key] = o.value
                              })
                          }
                          this.initFieldsControlData(index,controlData)
                          resolve(true)
                      }else {
                          reject()
                      }
                  })
                }, (res) => {
                  if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                    reject()
            })
        })
    }
    /*处理条件筛选项-默认 */
    @action
    public handleDefaultFields(){
        let typeControlData = {
            "": "全部"
        }
        if(this.stores.configStore.config.entityTypes){
            this.stores.configStore.config.entityTypes.map((o)=>{
                typeControlData[o.code] = o.name
            })
        }
        this.initFieldsControlData(2,typeControlData)
        this.getAllMetadataFieldSet()
        this.getClipsourceList()
    }
    /* 任务列表数据 */
    @action
    public queryList(page?: number,selectedContentId?: string){
        this.clearListSelected()
        if(page){
            this.setPage(page)
        }
        this.params = {
            page: this.page,
            size: this.size,
            keyword:[],
            conditions: [],
            resourceName: "entity",
            sortBys:[{fieldName: "createDate_", isDesc: true}]
        }
        if(this.keyword){
            this.params.keyword = [this.keyword]
        }
        if(this.advanceFields && this.advanceFields.length > 0){
            this.advanceFields.map((o)=>{
                if(o.value){
                    if(o.controlType === 16){
                        let dateArr = o.value.split(',')
                        if(dateArr[0] && dateArr[1]){
                            this.params.conditions.push({
                                field: o.fieldName,
                                value: ['['+dateArr[0]+' 00:00:00'+' TO '+dateArr[1]+' 23:59:59'+']'],
                                //nexus: o.nexus,
                            })
                        }
                    }else if(o.controlType === 8){
                        let newValue = JSON.parse(o.value)
                        if(newValue && newValue.length > 0){
                            this.params.conditions.push({
                                field: o.fieldName,
                                value: newValue,
                                //nexus: o.nexus,
                            })
                        }
                    }else{
                        this.params.conditions.push({
                            field: o.fieldName,
                            value: [o.value],
                            //nexus: o.nexus,
                        })
                    }
                }
                
            })
        }
        if(this.params.conditions.length === 0){
            this.params.conditions.push({
                "field": "type_",
                "value": ["audio", "video", 'document', 'hypermedia', 'materialpackage', 'picture', 'rundown', 'script']
            })
        }
        this.isSearching = true
        return new Promise((resolve, reject) => {
            contentMgApi.repeatAuditApi.queryList(this.params).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.getQueryResData(res.data.data, selectedContentId)
                        this.isSearching = false
                        resolve(true)
                    }else {
                        reject()
                    }
                })
            }, (res) => {
                reject()
            })
        })
    }
    //
    @action
    public getQueryResData(res: contentMgTypes.repeatAuditTypes.IQueryRes, selectedContentId?:string){
        if(res.queryResult && res.queryResult.data.length > 0){
            this.resolveQueryResult(res.queryResult.data)
        }
        this.queryListRes = res;
        if(this.queryListRes.queryResult.data && this.queryListRes.queryResult.data.length > 0){
            if(selectedContentId){
                let newItem = _.find(this.queryListRes.queryResult.data,{contentId: selectedContentId})
                if(newItem){
                    this.setSelectedEntity(newItem)
                }
            }else{
                this.setSelectedEntity(this.queryListRes.queryResult.data[0])
            }
        }
    }
    //获取待审的总数量
    @action
    public getWaitTotal(){
        return new Promise((resolve, reject) => {
            contentMgApi.repeatAuditApi.getWaitTotal().then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.waitTotal = res.data.data
                        resolve(true)
                    }else {
                        reject()
                    }
                })
            }, (res) => {
                reject()
            })
        })
    }

    //获取嵌套表格数据
    @action
    public getChildrenList(contentId: string){
        return new Promise((resolve, reject)=>{
            contentMgApi.repeatAuditApi.getChildrenList(contentId).then((res)=>{
                runInAction(()=>{
                    if(res.data.success && res.data.data){
                        this.setChildrenList(contentId,res.data.data)
                        resolve(1)
                    }else {
                        reject()
                    }
                   
                })
            })
        }).catch((res)=>{
            if(res.data.error && res.data.error.title){
                message.error(res.data.error.title)
            }
        })
    }

    // 
    @action
    public setShowSearch = (showSearch:boolean) => {
        this.showSearch = showSearch
    }

    @action
    public setFieldItemValue(index: number, value: string, displayValue?: string) {
        this.advanceFields[index].value = value
        this.advanceFields[index].displayValue = displayValue || value
    }
    @action
    public setFieldItemNexus(index: number, value: string) {
        this.advanceFields[index].nexus = value
    }

    @action
    public deleteItem(record:any) {
        return new Promise((resolve, reject)=>{
                contentMgApi.repeatAuditApi.deleteItem(record).then((res)=>{
                    runInAction(()=> {
                        if(res.data.data && res.data.success){
                            message.success('删除成功')
                        }
                    })
                }).catch(res=>{
                    if(res.data.error && res.data.error.title){
                        message.error(res.data.error.title)
                    }
                })
        })
       
    }

    /*处理查询数据 */
    protected resolveQueryResult(list: contentMgTypes.repeatAuditTypes.IQueryResultData[]) {
        list.map((item) => {
            if(!item.contentId){
                item.contentId = item.contentId_
            }
            if(!item.type){
                item.type = item.type_
            }
            if(!item.name){
                item.name = item.name_
            }
        })
    }
}

