import { Stores } from 'src/stores';
import ExtendStore from 'src/stores/base/extendStore';
import IIndexStore from '../iIndexStore';
import { action, runInAction, observable } from 'mobx';
import iIndexApi from 'src/apis/iIndexApi';
import iSearchApi from 'src/apis/iSearchApi';
import { message } from 'antd';
import _ from 'lodash'
import iIndexTypes from 'src/types/iIndexTypes';
import iSearchTypes from 'src/types/iSearchTypes';

interface IEventFact {
    field: string
    value: string[]
}

interface IEventItem {
    tagName: string
    count: number
    data: any[]
}

export default class EventListStore extends ExtendStore {
    @observable
    public tabCategories: iIndexTypes.secList.ICategoryTagCountRes[];
    // 事件列表
    @observable
    public eventRes?: iIndexTypes.secList.ICategoryTagRes;
    @observable
    public isReqList: boolean = false;
    @observable
    public isReqFact: boolean = false;
    /** 选中的类型 */
    @observable
    public selectedTab: string;
    @observable
    public keyword: string[] = [];
    @observable
    public category: string = '/事件';
    @observable
    public selectedConditions: iSearchTypes.ISearchCondition[] = []
    public showFacetConditions: iSearchTypes.IFacets[] = []
    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
        this.initSearchFilterStore(stores);
    }

    @action
    public setKeyword(keyword: string) {
        this.keyword = []
        this.keyword.push(keyword);
    }

    // 获取层面数据
    @action
    public getFacets() {
        this.isReqFact = true;
        let param: iSearchTypes.IFullSearchDataReq = {
            pageIndex: 1,
            pageSize: 5,
            personId: '',
            keyword: this.keyword.filter(item => item !== ''),
            facetConditions: [],
            conditions: [],
            sorts: [
                {
                    field: '_score',
                    desc: true
                }
            ]
        }

        iSearchApi.queryFullSearchData(param).then(res => {
            runInAction(() => {
                if (res.data.data && res.data.data.data) {
                    this.showFacetConditions = res.data.data.aggrs
                    this.getTabCategories()
                }
            })
        }, res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title);
            }
            runInAction(() => {
                this.isReqList = false;
            });
        });
    }

    // 获取素材列表
    @action
    public getLists(event: iIndexTypes.secList.ICategoryTag) {
        let param: iSearchTypes.IFullSearchDataReq = {
            pageIndex: 1,
            pageSize: 5,
            personId: '',
            keyword: [],
            facetConditions: [],
            conditions: [],
            sorts: [
                {
                    field: 'date_of_debut',
                    desc: true
                }
            ]
        }
        if (this.selectedConditions) {
            this.selectedConditions.forEach((condition) => {
                if(condition.field !== 'events_type') {
                    if (condition.items) {
                        condition.items.forEach((n) => {
                            let item = _.find(param.facetConditions, { field: n.field });
                            if (item == null) {
                                param.facetConditions.push(n);
                            } else {
                                if (_.isArray(item.value)) {
                                    if (!_.includes(item.value, n.value)) {
                                        item.value.push(n.value as string);
                                    }
                                } else {
                                    if (item.value !== n.value) {
                                        item.value = [item.value, n.value as string];
                                    }
                                }
                            }
                        });
                    } else {
                        if (condition.field && condition.value) {
                            param.facetConditions.push({ field: condition.field, value: condition.value })
                        }
                    }
                }
            });
        }

        if(event) {
            let obj: IEventFact = {
                field: 'events',
                value: [event.tagName].concat(event.statKeyword ? event.statKeyword : [])
            }
            param.facetConditions.push(obj)
        }

        iSearchApi.queryFullSearchData(param).then(res => {
            runInAction(() => {
                if (res.data.data && res.data.data.data) {
                    let arrData:any[] = res.data.data.data
                    if(this.eventRes) {
                        this.eventRes.data.forEach((item, index) => {
                            if(item.tagName === event.tagName) {
                                if(arrData.length > 0) {
                                    item.children = arrData
                                } else { // 检索不出素材，则隐藏事件
                                    this.eventRes?.data.splice(index, 1)
                                }
                            }
                        })
                    }
                    this.isReqList = false;
                }
            })
        }, res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title);
            }
            runInAction(() => {
                this.isReqList = false;
            });
        });
    }

    // 获取事件分类
    @action
    public getTabCategories() {
        return new Promise((resolve, reject) => {
            let params: iIndexTypes.secList.ICategoryTagCountReq = { category: this.category }
            iIndexApi.getCategoryTagCount(params).then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        this.tabCategories = res.data.data;
                        let arr: iSearchTypes.IFacetsFacetValue[] = []
                        this.tabCategories.forEach(item => {
                            let obj: iSearchTypes.IFacetsFacetValue = {
                                count: item.count,
                                showValue: item.category,
                                value: item.category
                            }
                            arr.push(obj)
                        })
                        let newFacts: iSearchTypes.IFacets = {
                            facetFieldName: 'events_type',
                            facetShowName: '事件分类',
                            facetValue: arr
                        }
                        this.showFacetConditions.unshift(newFacts);
                        this.initFacets(this.showFacetConditions);
                        this.isReqFact = false
                    }
                })
                resolve(true);
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                runInAction(() => {
                    this.isReqFact = false;
                });
            });
        })
    }

    @action
    public getEventList(page?: number) {
        let params: iIndexTypes.secList.ICategoryTagReq = {
            category: this.category + (this.selectedTab ? '/' + this.selectedTab : ''),
            tagName: this.keyword.filter(item => item !== '')[0],
            pageIndex: page || 1,
            pageSize: 20
        }
        this.isReqList = true
        this.eventRes = undefined;
        iIndexApi.getCategoryTag(params).then(res => {
            runInAction(() => {
                if (res.data.data) {
                    let names: string[] = [];
                    if (res.data.data.data) {
                        res.data.data.data.forEach((item) => {
                            names.push(item.tagName);
                            //获取拼音
                            if ((window as any).pinyinUtil) {
                                item.spell = (window as any).pinyinUtil.getFirstLetter(item.tagName);
                            }
                        })
                    }
                    this.eventRes = res.data.data;
                    this.eventRes.data.forEach(item => {
                        this.getLists(item)
                    })
                }
            })
        }, res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title);
            }
            runInAction(() => {
                this.isReqList = false;
            });
        });
    }

    @action
    public setSelectedConditions(conditions: iSearchTypes.ISearchCondition[]) {
        this.selectedConditions = conditions;
    }
    // 设置选中事件类型
    @action
    public setSelectedTab(tab: string) {
        this.selectedTab = tab;
    }

    /** 初始化层面数据 */
    private initFacets(facets: iSearchTypes.IFacets[]) {
        if (facets) {
            facets.forEach(item => {
                if (item.facetFieldName === 'createDate_') {
                    item.startDate = '';
                    item.endDate = '';
                }
                item.showMore = false;
                if (item.facetValue) {
                    item.facetValue.forEach((o: iSearchTypes.IFacetsFacetValue) => {
                        o.isShow = true;
                        o.isSelected = false;
                    });
                }
                item.checkboxIsOpen = false;
            });
        }
    }
}