import ExtendStore from "../../base/extendStore";
import {action, observable, runInAction} from "mobx";
import entityTypes from "../../../types/entityTypes";
import entityApi from "../../../apis/entityApi";
import { message } from 'antd';
import iEntityTypes from 'src/types/iEntityTypes';

export default class RundownStore extends ExtendStore{
    /** 关联素材数量，控制底部tab是否显示 */
    @observable
    public linkedListLength: number = 0;
    /** 文稿列表 */
    @observable
    public scriptsList: iEntityTypes.IRundownItem[];
    @observable
    public isReqScriptsList: boolean = false;
    @observable
    public selectedScript: iEntityTypes.IRundownItem;

    @action
    public setLinkedListLength(len: number){
        this.linkedListLength = len;
    }

    @action
    public queryScriptsList(){
        const entity = this.stores.iEntityStore.entity;
        this.isReqScriptsList = true;
        entityApi.getLinkedInfo(entity.contentId, entity.type).then(res => {
            runInAction(() => {
                if(res.data.success && res.data.data) {
                    this.scriptsList = res.data.data;
                    if (this.scriptsList.length > 0){
                        this.selectedScript = this.scriptsList[0];
                    }
                }
                this.isReqScriptsList = false;
            })
        }).catch(res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            runInAction(()=>{
                this.isReqScriptsList = false;
            })
        })
    }

    @action
    public setSelectedScript(script: iEntityTypes.IRundownItem){
        this.selectedScript = script;
    }
}