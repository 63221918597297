import ExtendStore from '../../base/extendStore';
import { action, observable, runInAction } from 'mobx';
import _ from 'lodash'
import { message } from 'antd';
import { Stores } from 'src/stores'
import PersonalCenterStore from '../personalCenterStore'
import personalCenterTypes from 'src/types/personalCenterTypes'

export default class CollectStore extends ExtendStore {
    public personalCenterStore: PersonalCenterStore

    @observable
    public dataList: personalCenterTypes.ITimeLineData[] = [
        {
            timeline: '2015-09-01',
            listArr: [
                {
                    title: '深圳，赶上最好的时代',
                    type: 'video',
                    category: '谈天说地',
                    quality: '4K',
                    longTime: '00:02:1',
                    imgUrl: 'https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2646607106,2533736614&fm=26&gp=0.jpg'
                }
            ]
        },
        {
            timeline: '2015-08-11',
            listArr: [
                {
                    title: '深圳，赶上最好的时代',
                    type: 'video',
                    category: '谈地说天',
                    quality: '4K',
                    longTime: '00:02:1',
                    imgUrl: 'https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2646607106,2533736614&fm=26&gp=0.jpg'
                },
                {
                    title: '特区不特？深圳破局',
                    type: 'album',
                    category: '谈天说地',
                    count: 32,
                    imgUrl: 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3351687647,4278738144&fm=11&gp=0.jpg'
                }
            ]
        },
        {
            timeline: '2015-02-11',
            listArr: [
                {
                    title: '深圳，赶上最好的时代',
                    type: 'audio',
                    category: '谈天说地',
                    longTime: '00:02:1',
                    imgUrl: 'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=592468034,3940027241&fm=26&gp=0.jpg'
                },
            ]
        }
    ]

    constructor(stores: Stores, personalCenterStore: PersonalCenterStore) {
        super(stores)
        this.personalCenterStore = personalCenterStore
    }
}