import { AxiosPromise } from 'axios'
import { IResponse, http } from 'mam-core-react/dist/modules/http'
import iSearchTypes from 'src/types/iSearchTypes';

namespace iSearchApi{
    export const queryFullSearchData = (params: iSearchTypes.IFullSearchDataReq, configOpts?: any):AxiosPromise<IResponse<iSearchTypes.IFullSearchDataRes>> => {
        return http.post<IResponse<iSearchTypes.IFullSearchDataRes>>('/es/search', params, configOpts);
    }

    export const vectorSimilarWords = (keyword: string):AxiosPromise<IResponse<iSearchTypes.IVectorSimilarWords[]>> => {
        return http.post<IResponse<iSearchTypes.IVectorSimilarWords[]>>(`/saims/v1/text/similarity-search`, {
            "text": keyword,
            "top": 10
          });
    }

    export const getRelativeEvents = (keyword: string):AxiosPromise<IResponse<iSearchTypes.IRelativeEventRes>> => {
        return http.get<IResponse<iSearchTypes.IRelativeEventRes>>(`/stagm/v1/tag?tagname=${keyword}&iscut=false&pagesize=5&tagType=事件`);
    }

    export const getSearchSuggest = (keyword: string): AxiosPromise<IResponse<string[]>> => {
        return http.get<IResponse<string[]>>(`/es/search/suggest?keyword=${keyword}`, {
            headers: {hideLoading: true}
        })
    }

    export const getSearchRecord = (): AxiosPromise<IResponse<iSearchTypes.ISearchRecord[]>> => {
        return http.get<IResponse<iSearchTypes.ISearchRecord[]>>(`/search/record`, {
            headers: {hideLoading: true}
        })
    }

    //删除一条历史记录
    export const deleteSearchRecord = (id: string): AxiosPromise<IResponse<iSearchTypes.ISearchRecord[]>> => {
        return http.delete(`/search/record?id=${id}`)
    }

    //清空历史记录
    export const clearSearchRecord = (): AxiosPromise<IResponse<iSearchTypes.ISearchRecord[]>> => {
        return http.delete(`/search/record/clear`)
    }

    export const favoriteCheck = (contentIds: string[]): AxiosPromise<IResponse<string[]>> => {
        return http.post<IResponse<string[]>>(`/favorite/check`, contentIds)
    }

    export const getSearchTemplates = ():AxiosPromise<IResponse<iSearchTypes.ISearchTemplateItem[]>> => {
        return http.get<IResponse<iSearchTypes.ISearchTemplateItem[]>>(`/es/search/template`);
    }
}

export default iSearchApi