import { action, observable, runInAction } from "mobx";
import BaseStore from "../base/baseStore";
import { stores } from '..';
import {Stores} from "../index";
import ccTypes from 'src/types/ccTypes'
import wuxiTypes from 'src/custom/wuxi/types/wuxiTypes';
import wuxiApi from 'src/custom/wuxi/apis/wuxiApi';

export default class DeleteStore extends BaseStore<Stores>{
    // 删除检查弹窗
    @observable
    public showDeleteCheckModal:boolean = false

    @observable
    public operateName:string = ''

    // 已选素材数组
    @observable
    public items:any[]

    @observable
    public taskId:string

    @observable
    public isCancel:boolean = false

    @observable
    public showDeleteConfirm:boolean = false

    @observable
    public requestURL:string = ''

    @observable
    public showDeleteErrorModal:boolean = false

    @observable
    public errorList:any[] = []

    @observable
    public success:()=>void

    @observable
    public isEnd:boolean = false

    //无锡定制项： 获取删除原因配置项； 删除原因、备注做记录
    @observable
    public wxReasonList:ccTypes.IMetadataFieldSetItem[] = []
    @observable
    public wxDeleteRecordBase:wuxiTypes.IDeleteRecordBase = {
        reason: '',
        notes: '',
        action: "DELETE"   //DELETE / RESTORE / CLEAR
    }

    @action
    public setIsEnd(isEnd:boolean){
        this.isEnd = isEnd
    }

    // 打开关闭删除检查弹窗
    @action
    public setShowDeleteCheckModal(show:boolean){
        this.showDeleteCheckModal = show
    }

    @action
    public setOperateName(operateName:string){
        this.operateName = operateName
    }

    @action
    public setItems(items:any[]){
        this.items = items
    }

    @action
    public setTaskId(taskId:string){
        this.taskId = taskId
    }

    @action
    public setIsCancel(isCancel:boolean){
        this.isCancel = isCancel
    }

    @action
    public setShowDeleteConfirm(showDeleteConfirm:boolean){
        this.showDeleteConfirm = showDeleteConfirm
        //若关闭弹窗，需要重置初始值（无锡定制项）
        if(!showDeleteConfirm && stores.configStore.config.wuXiCustomSwitch){
            this.wxDeleteRecordBase = {
                reason: '',
                notes: '',
                action: "DELETE"
            } 
        }
    }

    @action
    public setRequestURL(requestURL:string){
        this.requestURL = requestURL
    }
    
    @action
    public setShowDeleteErrorModal(showDeleteErrorModal:boolean){
        this.showDeleteErrorModal = showDeleteErrorModal
    }

    @action
    public setErrorList(errorList:any[]){
        this.errorList = errorList
    }

    @action 
    public checkDeletionTask(items:any[],requestURL:string,opName?:string){
        this.setItems(items)
        this.setRequestURL(requestURL)
        this.setErrorList([])
        if(stores.configStore.config.checkDeletionEnable){
            if(stores.configStore.config.wuXiCustomSwitch){
                this.getWxReasonList()
            }
            this.setShowDeleteCheckModal(true)
        }else{
            if(stores.configStore.config.wuXiCustomSwitch){
                this.getWxReasonList()
            }
            this.setShowDeleteConfirm(true)
        }
    }

    @action
    public showErrorList = (errorList:any[],success:()=>void) => {
        this.setErrorList(errorList)
        this.setShowDeleteErrorModal(true)
        this.success = success
    }


    //无锡定制-删除记录请求
    @action
    public setWxDeleteRecordBase(wxDeleteRecordBase:wuxiTypes.IDeleteRecordBase){
        this.wxDeleteRecordBase = wxDeleteRecordBase
    }
    //无锡定制-删除原因配置项数据
    @action
    public getWxReasonList(){
        return new Promise((resolve, reject) => {
            wuxiApi.getMetadataFieldSet('deletereason').then((res) => {
                runInAction(() => {
                    if (res.data && res.data.success && res.data.data) {
                        this.wxReasonList = res.data.data;
                        resolve(true)
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    //无锡定制-删除操作做记录
    @action
    public wxDeleteRecord(params: wuxiTypes.IDeleteRecordReqItem[]){
        return new Promise((resolve, reject) => {
            wuxiApi.deleteRecord(params).then((res) => {
                runInAction(() => {
                    if (res.data && res.data.success) {
                        resolve(true)
                    }else{
                        reject()
                    }
                })
            })
        })
    }

    

}
