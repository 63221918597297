import BaseStore from "../../base/baseStore";
import {action, observable, runInAction} from "mobx";
import CatalogStore from "../catalogStore";
import {Stores} from "../../index";
import catalogApi from "../../../apis/catalogApi";
import catalogTypes from "../../../types/catalogTypes";
import {message} from "antd";
import _ from 'lodash';

export default class FaceRecoStore extends BaseStore<Stores> {
    @observable
    public recoResult?: catalogTypes.faceReco.IFaceRecoItem[];
    private parentStore: CatalogStore

    public constructor(stores: Stores, parentStore: CatalogStore) {
        super(stores);
        this.parentStore = parentStore;
    }

    @action
    public executeFaceReco(){
        const nameField = this.parentStore.nameField;
        let img = this.parentStore.viewer.player.getCurrentKeyframe();
        let request: catalogTypes.faceReco.IFaceRecoReq = {
            imgBase64: img ? img.substring(img.indexOf('base64') + 7) : '',
            name: nameField ? nameField.value as string : ''
        }
        catalogApi.faceReco.executeFaceReco(request).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.recoResult = res.data.data.recognized.concat(res.data.data.unIdentification);
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }

    /** 修改人脸识别结果 */
    @action
    public updateRecoResult(item: catalogTypes.faceReco.IFaceRecoItem){
        if (!this.recoResult || this.recoResult.length === 0){
            return;
        }
        let index = _.findIndex(this.recoResult, (result)=>{
            return result.location.left === item.location.left
                && result.location.top === item.location.top
                && result.location.width === item.location.width
                && result.location.height === item.location.height;
        })
        if (index > -1){
            this.recoResult.splice(index, 1, item);
        }
    }

    @action
    public editResult(item: catalogTypes.faceReco.IFaceRecoItem, key: string, value: string){
        item[key] = value;
    }
}