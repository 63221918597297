import ExtendStore from "src/stores/base/extendStore";
import { observable, action, runInAction } from 'mobx';
import IndexStore from './subPages/indexStore/indexStore';
import PersonStore from './subPages/personStore/personStore';
import TenementStore from './subPages/tenementStore/tenementStore';
import { Stores } from 'src/stores/index';

export default class ZjStore extends ExtendStore {
    @observable
    public indexStore: IndexStore;
    public personStore: PersonStore;
    public tenementStore: TenementStore;
    public constructor(stores: Stores) {
        super(stores);
        this.indexStore = new IndexStore(stores, this);
        this.personStore = new PersonStore(stores, this);
        this.tenementStore = new TenementStore(stores, this);
    }
}
