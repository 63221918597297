import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import ccTypes from './../../types/ccTypes';
import mmsApi from 'src/apis/mmsApi';
import { Modal } from 'antd';
import { stores } from 'src/stores';
import {l} from "mam-core-react";
import {Stores} from "../index";


export default class OutputExcelStore extends BaseStore<Stores>{
    // 控制下载弹窗
    @observable
    public showOutputExcelModal:boolean = false

    @observable
    public req:ccTypes.searchTypes.IFullSearchReq

    @observable
    public isSelectItem:any

    @action
    public setShowOutputExcelModal(show:boolean){
        this.showOutputExcelModal = show
    }

    @action
    public setReq(req:ccTypes.searchTypes.IFullSearchReq){
        this.req =req
    }

    @action
    public outputExcel(isSelectItem:any[]){
        this.isSelectItem = isSelectItem;
        stores.searchStore.outputExcelStore.setShowOutputExcelModal(true)
        if(isSelectItem.length <= 0){
            const size: number = stores.configStore.config.searchBase.exportToExcelMaxLimit || 1000;
            this.getQueryRequest(size)
        }else{
            //清空列表选中
            this.stores.searchStore.selectAllStore.switchAllSelected(false);
            this.stores.searchStore.selectAllStore.switchListItemSelected(undefined, false);
        }
    }

    @action 
    public outputExcelPart(isSelectItem:any[]){
        if(isSelectItem.length > 0){
            const contentIds: string[] = isSelectItem.map(item => {
                return item.contentId_
            })
            mmsApi.OutputExcelModal.exportCsvForPart(contentIds)
        }
    }

    @action 
    public getQueryRequest(size:number){
        const p = stores.searchStore.params;
        const req: ccTypes.searchTypes.IFullSearchReq = {
            page: p.page,
            size: stores.configStore.config ? stores.configStore.config.searchResultNum : 24,
            keyword: p.keyword,
            sortBys: [{
                fieldName: stores.searchStore.currentSort.field,
                isDesc: stores.searchStore.currentSort.desc === 'desc' ? true : false
            }],
            conditions: []
        }
        if(p.advancedSearch){
            if(stores.configStore.config.wuXiCustomSwitch){
                const params: ccTypes.searchTypes.IAdvancedSearchTypes = {
                    field:  p.advancedSearch.field.filter(item => {
                        if(item.value !== '' && item.value !== "notFound" && item.value !== undefined && item.value !== '[]' && item.value !== null) {
                            return item
                        }else {
                            return false
                        }
                    }),
                    isOnlyAvSearching: p.advancedSearch.isOnlyAvSearching
                }
                if(params.field && params.field.length > 0 ){
                    req.advancedSearch = stores.searchStore.handleWuxiAdvancedSearch(params)
                }
            }else{
                req.advancedSearch = p.advancedSearch
            }
        }
        if (stores.searchStore.params.conditions) {
            stores.searchStore.params.conditions.forEach((condition:any) => {
                if (condition.items) {
                    condition.items.forEach((n:any) => {
                        let item = (window as any)._.find(req.conditions, { field: n.field });
                        if (item == null) {
                            req.conditions.push(n);
                        } else {
                            if ((window as any)._.isArray(item.value)) {
                                if (!(window as any)._.includes(item.value, n.value)) {
                                    item.value.push(n.value);
                                }
                            } else {
                                if (item.value !== n.value) {
                                    item.value = [item.value, n.value];
                                }
                            }
                        }
                    });
                } else {
                    if (condition.field && condition.value) {
                        req.conditions.push({ field: condition.field, value: condition.value })
                    }
                }
            });
        }
        if (stores.searchStore.params.resourceName){
            req.resourceName = stores.searchStore.params.resourceName;
        }
        if(!this.keywordCheck(p.keyword)){
            req.keyword = []
        }
        req.page = 1;
        req.size = size
        this.setReq(req)
    }

    //确认弹出框
    @action 
    public showConfirm(){
        let self = this;
        if (stores.searchStore.fullSearchData)
        {
            Modal.confirm({
                title: `确定导出${stores.searchStore.fullSearchData.queryResult.recordTotal}条记录？`,
                content: '',
                onOk() {
                    mmsApi.OutputExcelModal.exportCsvForAll(self.req)
                        // .then(res => {
                        //     if (res.data.success && res.data.data) {
                        //         window.location.href = res.data.data
                        //     } else {
                        //         message.error('导出失败')
                        //     }
                        // })
                        // .catch(err => {
                        //     message.error('导出失败' + err)
                        // })
                },
                centered:true,
                maskClosable:true,
                okText:l('com.ok','确定'),
                cancelText:l('com.cancel','取消'),
            })
        }
    }

    @action
    public keywordCheck(keyword:string[]){
        return true
    }
}
