import ExtendStore from "../base/extendStore"
import { observable, action } from 'mobx'
import { Stores } from '..';
import WorkbenchStore from "./workbenchStore";

export default class LeftNavStore extends ExtendStore {
    @observable
    public currentKey:string = '1'
    private workbenchStore: WorkbenchStore

    public constructor(stores: Stores, workbenchStore: WorkbenchStore){
        super(stores)
        this.workbenchStore = workbenchStore
    }

    @action
    public setCurrentKey = (currentKey:string)=>{
        this.currentKey = currentKey
    }
}
