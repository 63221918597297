import ExtendStore from 'src/stores/base/extendStore';
import { action, observable, runInAction } from 'mobx';
import ManageCenterStore from "../../manageCenterStore";
import { Stores } from 'src/stores';
import manageCenterApi from 'src/apis/manageCenterApi';
import manageCenterTypes from 'src/types/manageCenterTypes';
import { message } from 'antd';
import { commonUtil } from 'mam-common-utils';
import _ from 'lodash';


export default class HomePagePushStore extends ExtendStore {
    @observable
    public displayData:manageCenterTypes.homePagePush.IPortalEntity[] = []
    @observable
    public notDisplayData:manageCenterTypes.homePagePush.IPortalEntity[] = []
    @observable
    public uploadPosterModalVisible: boolean = false
    @observable
    public cropperModalVisible: boolean = false
    @observable
    public cropperImgResource:string = '' //图片源文件
    @observable
    public currentContentId: string = ''
    @observable
    public fileName: string = ''
    @observable
    public cropperImg:Blob // 裁剪之后的图片
    @observable
    public rotationSet: manageCenterTypes.homePagePush.IRotationSet = {
        interval: 5,
        max: 10
    }
    @observable
    public pageInfo: manageCenterTypes.homePagePush.IPage = {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 1
    }
    @observable
    public currentActiveKey: string = 'frontpage'
    private manageCenterStore: ManageCenterStore
    
    public constructor(stores: Stores, manageCenterStore: ManageCenterStore){
        super(stores)
        this.manageCenterStore = manageCenterStore
    }

    @action
    public setPageInfo = (showName: string,value:number)=> {
        this.pageInfo[showName] = value
    }

    @action
    public setCurrentContentId = (currentContentId: string)=> {
        this.currentContentId = currentContentId
    }
    @action
    public setFileName = (fileName:string)=> [
        this.fileName = fileName
    ]

    @action
    public setUploadPosterModalVisible = (uploadPosterModalVisible: boolean)=> {
        this.uploadPosterModalVisible = uploadPosterModalVisible
    }

    @action
    public setCropperModalVisible = (cropperModalVisible: boolean)=> {
        this.cropperModalVisible = cropperModalVisible
    }

    @action
    public setCropperImgResource = (cropperImgResource: string)=> {
        this.cropperImgResource = cropperImgResource
    }

    @action
    public setCropperImg = (cropperImg: Blob)=> {
        this.cropperImg = cropperImg
    }

    @action
    public setCurrentActiveKey = (activeKey: string)=> {
        this.currentActiveKey = activeKey
    }

    @action 
    public setDisplayData = (displayData: manageCenterTypes.homePagePush.IPortalEntity[])=> {
        this.displayData = commonUtil.copyObj(displayData)
    }

    @action 
    public setNotDisplayData = (notDisplayData: manageCenterTypes.homePagePush.IPortalEntity[])=> {
        this.notDisplayData = commonUtil.copyObj(notDisplayData)
    }

    @action
    public reduceOrAdd = (operation: string)=> {
        if(operation === 'add'){
            this.pageInfo.pageIndex++
        }else{
            this.pageInfo.pageIndex--
        }
        this.getRotationEntity(false)
    }
    @action
    public getRatationSet = ()=> {
        return new Promise((resolve, reject)=> {
            manageCenterApi.homePagePush.getRotationSet().then((res)=> {
                runInAction(()=> {
                    if(res.data.data){ 
                        this.rotationSet = res.data.data
                    }
                })
            }).catch((res)=> {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            })
        })
    }

    @action
    public changeRotationSet = (showName: string, value: number)=> {
        this.rotationSet[showName] = value
    }

    @action
    public setRotationSet = ()=> {
        return new Promise((resolve, reject)=> {
            manageCenterApi.homePagePush.setRotationSet(this.rotationSet).then((res)=> {
                if(res.data.data){
                    message.success('设置成功')
                    resolve(undefined)
                }
                reject()
            }).catch((res)=> {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            })
        })
    }

    @action
    public getRotationEntity = (isShow:boolean)=> {
        return new Promise((resolve, reject)=> {
            let params: manageCenterTypes.homePagePush.IPortalEntityReq = {
                category: this.currentActiveKey,
                isShow,
                pageIndex: this.pageInfo.pageIndex,
                pageSize: this.pageInfo.pageSize
            }
            manageCenterApi.homePagePush.getRotationEntity(params).then((res)=> {
                runInAction(()=> {
                    if(res.data.data){
                        res.data.data.data.map((item: manageCenterTypes.homePagePush.IPortalEntity)=> {
                            item.checked = false
                        })
                        if(isShow){
                            this.displayData = res.data.data.data
                        }else {
                            this.notDisplayData = res.data.data.data
                            this.pageInfo = {
                                pageSize: res.data.data.pageSize,
                                pageTotal: res.data.data.pageTotal,
                                pageIndex: res.data.data.pageIndex,
                            }
                        }
                        resolve(undefined)
                    }
                })
            }).catch(res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            })
        })
       
    }

    @action
    public publishRotation = (contentId?: string)=> {
        return new Promise((resolve, reject)=> {
            let contentIds = this.displayData.map((item)=> {
                return item.contentId
            })
            if(contentId){
                contentIds.push(contentId)
            }
            let params = {
                category:this.currentActiveKey,
                contentIds
            }
            manageCenterApi.homePagePush.publishRotation(params).then((res)=> {
                runInAction(()=> {
                    if(res.data.success){
                        message.success('发布成功')
                        setTimeout(()=> {
                            this.getRotationEntity(true)
                            this.getRotationEntity(false)
                        },300)
                    }
                })
            }).catch((res)=> {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            })
        })
    }

    @action
    public chooseItem = (display: boolean, id: string, checked: boolean)=> {
        if(display){
            this.displayData.forEach((item)=> {
                if(id === item.id){
                    item.checked = checked
                }
            })
        }else{
            this.notDisplayData.forEach((item)=> {
                if(id === item.id){
                    item.checked = checked
                }
            })
        }
    }

    @action
    public removeData = ()=> {
        let newData:manageCenterTypes.homePagePush.IPortalEntity[] = commonUtil.copyObj(this.displayData) 
        newData = _.remove(newData, (o) => !o.checked)
        this.displayData = [...newData]
    }

    @action
    public deleteData = (contentId?:string)=> {
        return new Promise((resolve, reject)=> {
            let contentIds: string[]
            if(contentId){
                contentIds = [contentId]
            }else{
                contentIds = this.notDisplayData.filter((item)=>item.checked).map((item)=>{
                    return item.contentId
                })
            }
            let params = {
                category: this.currentActiveKey,
                contentIds
            }
            manageCenterApi.homePagePush.deleteData(params).then((res)=> {
                runInAction(()=> {
                    if(res.data.success){
                        message.success('删除成功')
                        setTimeout(()=> {
                            this.getRotationEntity(true)
                            this.getRotationEntity(false)
                        },300)
                    }
                })
            }).catch((res)=> {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            })
        })
    }

    @action
    public uploadImage = (contentId?:string)=> {
        return new Promise((resolve,reject)=>{
            manageCenterApi.homePagePush.uploadImage(this.cropperImg,this.fileName,this.currentActiveKey,this.currentContentId).then((res)=> {
                runInAction(()=> {
                    if(res.data.success){
                        message.success('上传成功')
                    }
                })
            }).catch((res)=> {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            })
        })
    }
}