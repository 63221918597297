import { BaseStore } from 'mam-core-react';
import { Stores } from '..';
import IAlbumDetailStore from './iAlbumDetailStore';
import { observable, action, runInAction } from 'mobx';
import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import iAlbumDetailApi from 'src/apis/iAlbumDetailApi';
import { message } from 'antd';

export default class ModifyAutoCollectStore extends BaseStore<Stores> {
    @observable
    public selectedCollectFields?: IFormItem[]

    public iAlbumDetailStore: IAlbumDetailStore;

    public constructor(stores: Stores, iAlbumDetailStore: IAlbumDetailStore) {
        super(stores);
        this.iAlbumDetailStore = iAlbumDetailStore;
    }

    @action
    public getCurrentCollectFields(){
        iAlbumDetailApi.getAialbumConvergConditions(this.iAlbumDetailStore.params.contentId).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.selectedCollectFields = res.data.data;
                }
            })
        }).catch((res)=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }

    @action
    public setSelectedCollectFields(fields: IFormItem[]){
        this.selectedCollectFields = fields;
    }
}