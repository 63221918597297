import ccTypes from "../../types/ccTypes";
import { action, observable, runInAction, computed } from "mobx";
import ccApi from "../../apis/ccApi";
import { Stores } from "../index";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ExtendStore from '../base/extendStore'
import _ from 'lodash'
import { message } from 'antd';

export default class BasketStore extends ExtendStore {
    // 所有的item对象
    @observable
    public items: ccTypes.IBasketItemData[];
    @observable
    public queryData: ccTypes.IBasketItems;
    @observable
    public isAllChecked: boolean;
    @observable
    public request: ccTypes.IBasketItemsReqData
    @observable
    public requestState: boolean = false; //判断请求数据是否已经返回


    // // downloadStore
    // public downloadStore: DownloadStore;
    // public outstoreStore: OutstoreStore;
    // public createAlbumStore: CreateAlbumStore;
    constructor(stores: Stores) {
        super(stores)
        this.initDownloadStore(stores)
        this.initOutstoreStore(stores)
        this.initNewCreateAlbumStore(stores)
        this.initCreateDatasetStore(stores)
        this.request = {
            pageIndex: 1,
            pageSize: 50
        }
    }

    // 是否返回数据
    @action
    public setRequestState = (value:boolean) => {
        this.requestState = value
    }

    // 是否全选
    @action
    public handleChenckAllChange = (e: CheckboxChangeEvent) => {
        this.isAllChecked = e.target.checked
        this.items.forEach((item: ccTypes.IBasketItemData) => {
            item.selected = this.isAllChecked
        })
    }
    // 设置某一项的选中状态
    @action
    public handleCheckItemChange(index: number) {
        this.items[index].selected = this.items[index].selected ? !this.items[index].selected : true;
        this.checkIsAllChecked()
    }
    // 检查是否全选
    @action
    public checkIsAllChecked() {
        if (this.items.length === 0) {
            this.isAllChecked = false;
            return
        }
        this.isAllChecked = this.items.every(o => (o.selected))
    }

    @action
    public onChangePage = (pageIndex: number) => {
        this.request.pageIndex = pageIndex
        this.query()
    }

    @action
    public initAllData = (tempdata: ccTypes.IBasketItems) => {
        this.queryData = tempdata
        this.items = tempdata.data
        this.items.forEach(item => {
            item.contentId = item.entity.contentId
            item.entity.inpoint = item.inPoint
            item.entity.outpoint = item.outPoint
            item.entity.name = item.title
            item.entity.type_ = item.entityType
            if (item.guid){
                item.entity.guid_ = item.guid
            }
        })
    }
    @action
    public basketDeleteItems = (showMessage?: boolean, ignoreFragment?: boolean) => {
        let idArr: number[] = []
        this.items.forEach(item => {
            let isFragment = item.inPoint > 0 || item.outPoint > 0
            if (isFragment) {
                if (ignoreFragment) {
                    // 可不移除片段
                    item.selected = false
                } else {
                    if (item.selected) {
                        idArr.push(item.id)
                    }
                }
            } else {
                if (item.selected) {
                    idArr.push(item.id)
                }
            }
        })
        return ccApi.basketDeleteItems(idArr).then((result) => {
            if (showMessage) {
                message.success('删除成功')
            }
        }).catch((err) => {
            if (showMessage) {
                message.error('删除失败')
            }
        });
    }

    @action
    public changeEnityType = (type: any) => {
        this.request.type = type
        console.log(type)
        this.query()
    }

    @computed
    public get getSelectedItems(): ccTypes.IBasketItemData[] {
        return this.items ? this.items.filter(s => s.selected) : []
    }

    @computed
    public get getNotFragmentSelected(): ccTypes.IBasketItemData[] {
        return this.items ? this.items.filter(s => s.selected && (s.inPoint <= 0 && s.outPoint <= 0)) : []
    }

    public query = () => {
        ccApi.basketGetItems(this.request).then((result) => {
            let tempdata = result.data.data
            if (tempdata){
                this.initAllData(tempdata)
            }
            this.setRequestState(true)
            this.checkIsAllChecked()
        }).catch((err) => {
            console.log(err)
        });
    }
}

