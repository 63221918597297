import { Stores } from 'src/stores';
import ExtendStore from 'src/stores/base/extendStore';
import IIndexStore from '../iIndexStore';
import { action, runInAction, observable } from 'mobx';
import iIndexApi from 'src/apis/iIndexApi';
import { message } from 'antd';
import _ from 'lodash'
import iIndexTypes from 'src/types/iIndexTypes';
import iSearchTypes from 'src/types/iSearchTypes';

export default class DistrictListStore extends ExtendStore {
    /** 通用列表 */
    @observable
    public list?: iIndexTypes.secList.IDistrictListItem[];
    @observable
    public facets1?: iIndexTypes.secList.IDistrictListItem[];//一级层面
    @observable
    public facets2?: iIndexTypes.secList.IDistrictListItem[];//二级层面
    @observable
    public selectedFacets1?: iIndexTypes.secList.IDistrictListItem
    @observable
    public selectedFacets2?: iIndexTypes.secList.IDistrictListItem
    @observable
    public isReqList: boolean = false;
    @observable
    public selectedTab: string = '中国/广东省/深圳市'
    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
        this.initSearchFilterStore(stores);
    }

    @action
    public getList(){
        return new Promise((resolve, reject)=>{
            this.list = [];
            this.isReqList = true;
            let district = ''
            if (this.selectedFacets2){
                district = this.selectedFacets2.key;
            }
            else if (this.selectedFacets1){
                district = this.selectedFacets1.key;
            }
            else if (this.selectedTab){
                district = this.selectedTab;
            }
            iIndexApi.getDistrictList(district).then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        this.list = res.data.data;
                    }
                    this.isReqList = false;
                })
                resolve();
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                this.isReqList = false;
                reject();
            });
        })
    }

    @action
    public getFacets2(){
        return new Promise((resolve, reject)=>{
            if (!this.selectedFacets1){
                reject();
                return;
            }
            iIndexApi.getDistrictList(this.selectedFacets1.key).then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        this.facets2 = res.data.data;
                    }
                })
                resolve();
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }

    @action
    public setSelectedTab(tab: string){
        this.selectedTab = tab;
    }

    @action
    public setFacets1(facets1?: iIndexTypes.secList.IDistrictListItem[]){
        this.facets1 = facets1;
    }

    @action
    public setFacets2(facets2?: iIndexTypes.secList.IDistrictListItem[]){
        this.facets2 = facets2;
    }

    @action
    public setSelectedFacets1(facets1?: iIndexTypes.secList.IDistrictListItem){
        this.selectedFacets1 = facets1;
    }

    @action
    public setSelectedFacets2(facets2?: iIndexTypes.secList.IDistrictListItem){
        this.selectedFacets2 = facets2;
    }
}