import ExtendStore from '../../base/extendStore';
import { action, observable, runInAction } from 'mobx';
import _ from 'lodash'
import { message } from 'antd';
import { Stores } from 'src/stores'
import PersonalCenterStore from '../personalCenterStore'
import personalCenterTypes from 'src/types/personalCenterTypes'

export default class GroupStore extends ExtendStore {
    public personalCenterStore: PersonalCenterStore

    @observable
    public defaultSelectedKeys: string = '1'
    @observable
    public defaultSelectInfo: personalCenterTypes.IGroupObj = {   
        id: '1',
        title: '深圳40年专辑分享群',
        count: 30
    }
    @observable
    public isAddGroupFlag: boolean = false
    @observable
    public isAddUserFlag: boolean = false

    @observable
    public groupNameList: personalCenterTypes.IGroupObj[] = [
        {
            id: '1',
            imgUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1660422566,187228173&fm=26&gp=0.jpg',
            title: '深圳40年专辑分享群',
            count: 30
        },
        {
            id: '2',
            imgUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1660422566,187228173&fm=26&gp=0.jpg',
            title: '未定义的群名',
            count: 12
        },
        {
            id: '3',
            imgUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1660422566,187228173&fm=26&gp=0.jpg',
            title: '深圳美图',
            count: 34
        },
        {
            id: '4',
            imgUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1660422566,187228173&fm=26&gp=0.jpg',
            title: '深圳40年专辑分享群',
            count: 45
        }
    ]
    @observable
    public userListData: personalCenterTypes.ITableRes = {
        pageIndex: 1,
        pageTotal: 1,
        pageSize: 10,
        recordTotal: 2,
        data: [
            {
                avatarUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1660422566,187228173&fm=26&gp=0.jpg',
                name: '王强',
                department: '总编室'
            },
            {
                avatarUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1660422566,187228173&fm=26&gp=0.jpg',
                name: '小芳',
                department: '总编室'
            },
            {
                avatarUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1660422566,187228173&fm=26&gp=0.jpg',
                name: '光头强',
                department: '总编室'
            },
            {
                name: '熊大',
                department: '总编室'
            }
        ]
    }
    constructor(stores: Stores, personalCenterStore: PersonalCenterStore) {
        super(stores)
        this.personalCenterStore = personalCenterStore
    }

    @action
    public setDefaultSelectedKeys(key: string) {
        this.defaultSelectedKeys = key
        const obj = _.find(this.groupNameList, {id: key})
        if(obj) {
            this.setDefaultSelectInfo(obj)
        }
    }
    @action
    public setDefaultSelectInfo(obj: personalCenterTypes.IGroupObj) {
        this.defaultSelectInfo = obj
    }
    @action
    public setIsAddGroupFlag(flag: boolean) {
        this.isAddGroupFlag = flag
    }
    @action
    public setIsAddUserFlag(flag: boolean) {
        this.isAddUserFlag = flag
    }
}