import BaseStore from "../base/baseStore";
import { action, observable, runInAction } from 'mobx';
import { Stores } from '..';
import mmsApi from 'src/apis/mmsApi';
import mmsTypes from 'src/types/mmsTypes';
import _ from 'lodash'
import ISearchStore from '../iSearch/iSearchStore';
import { message } from 'antd';

export default class BatchInitiatingSmartProcess4AllStore extends BaseStore<Stores>{
    @observable
    public showModal:boolean = false
    @observable
    public selectedItems:any[]
    @observable
    public allSmartBtns: mmsTypes.batchInitiatingSmartProcess4All.ISmartFlowConfigItem[] = []; //处理前所有的配置项
    @observable
    public filterSmartBtns: mmsTypes.batchInitiatingSmartProcess4All.ISmartFlowConfigItem[] = []; //过滤后的类型可支持的配置项
    @observable
    public processBtns: mmsTypes.batchInitiatingSmartProcess4All.IProcessBtn[] = []; //过滤后的可用配置项
    @observable
    public selectedSmartBtn: mmsTypes.batchInitiatingSmartProcess4All.ISmartFlowConfigItem //默认选中的父级选项
    
    private searchStore: ISearchStore;

    public constructor(stores: Stores, searchStore: ISearchStore){
        super(stores);
        this.searchStore = searchStore;
    }

    @action
    public openModal(selectedItems: any[]){
        this.selectedItems = selectedItems;
        this.getSmartFlowConfig().then(()=>{
            runInAction(() => {
                this.showModal = true;
                if(this.filterSmartBtns && this.filterSmartBtns.length > 0){
                    this.selectedSmartBtn = this.filterSmartBtns[0]
                }
            })
        })
    }

    @action
    public setSelectedSmartBtn(selectedSmartBtn: mmsTypes.batchInitiatingSmartProcess4All.ISmartFlowConfigItem){
        //若切换了，则需要默认子项的全部选中
        if(this.selectedSmartBtn && this.selectedSmartBtn.key !== selectedSmartBtn.key && selectedSmartBtn.hasChild){
            selectedSmartBtn.children.map((o)=>{
                o.checked = true
            })
        }
        this.selectedSmartBtn = selectedSmartBtn
    }

    @action
    public hide(){
        this.showModal = false;
    }

    @action
    public handleBtnCheck = (item: mmsTypes.batchInitiatingSmartProcess4All.ISmartFlowConfigItem, e: any)=>{
        item.checked = e.target.checked;
    }

    @action
    public getFilterSmartBtns(){
        if (this.allSmartBtns){
            let currentFilterTypeCode = '';
            if (this.selectedItems.length === 0){ // 对所有素材发起
                const typeCondition = _.find(this.searchStore.params.facetConditions, {field: 'type_'})
                if (typeCondition && typeCondition.items){
                    currentFilterTypeCode = typeCondition.items[0].value[0];
                }else {
                    const typeSzCondition = _.find(this.searchStore.szSelectedAggr, {facetFieldName: 'type_'})
                    if (typeSzCondition){
                        currentFilterTypeCode = typeSzCondition.facetValueValue
                    }
                }
            }else { // 对选中素材发起
                currentFilterTypeCode = this.selectedItems[0].type_;
            }
            let filterSmartBtns = this.allSmartBtns.filter((item, index)=>{
                return item.enable && (!item.supportType || (item.supportType
                    && _.find(item.supportType, (o)=>{
                        return currentFilterTypeCode !== '' && o === currentFilterTypeCode;
                    })))
            })
            filterSmartBtns.map((o)=>{
                if(o.children && o.children.length > 0){
                    o.children = o.children.filter((child)=>{
                        return child.enable && (!child.supportType || (child.supportType
                        && _.find(child.supportType, (k)=>{
                            return currentFilterTypeCode !== '' && k === currentFilterTypeCode;
                        })))
                    })
                    if(o.children && o.children.length > 0){
                        o.children.map((child)=>{
                            child.checked = true
                        })
                    }
                }
            })
            this.filterSmartBtns = filterSmartBtns
        }
    }

    @action
    public getSmartFlowConfig = ()=>{
        return new Promise((resolve, reject) => {
            mmsApi.batchInitiatingSmartProcess4All.getSmartFlowConfig().then(res => {
                runInAction(() => {
                    if(res.data.data){
                        let allSmartBtns = res.data.data.filter((o)=>!o.parentKey && o.enable)
                        let childData = res.data.data.filter((o)=>o.parentKey)
                        allSmartBtns.map((o: mmsTypes.batchInitiatingSmartProcess4All.ISmartFlowConfigItem)=>{
                            o.children = childData.filter((child)=>child.parentKey && child.parentKey === o.key)
                        })
                        this.allSmartBtns = allSmartBtns
                        this.getFilterSmartBtns()
                    }
                    resolve(true)
                })
            },(res)=>{
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
}
