import ExtendStore from "../base/extendStore"
import { Stores } from '..';
import SecListStore from './subModules/secListStore';
import CategoryListStore from './subModules/categoryListStore';
import ColumnListStore from './subModules/columnListStore';
import DistrictListStore from './subModules/districtListStore';
import DetailStore from './subModules/detailStore';
import TimeListStore from './subModules/timeListStore';
import NewImportStore from './subModules/newImportStore';
import AlbumListStore from './subModules/albumListStore';
import FormatStaStore from './subModules/formatStaStore';
import EventListStore from './subModules/eventListStore';
import SceneListStore from './subModules/sceneListStore';
import SceneDetailStore from './subModules/sceneDetailStore';
import TopHotStore from './subModules/topHotStore';
import UserListStore from './subModules/userListStore';
import UserDetailStore from './subModules/userDetailStore';
import SmoisStore from './subModules/smoisStore';

export default class IIndexStore extends ExtendStore {
    public secListStore: SecListStore;
    public categoryListStore: CategoryListStore;
    public columnListStore: ColumnListStore;
    public timeListStore: TimeListStore;
    public districtListStore: DistrictListStore;
    public newImportStore: NewImportStore;
    public albumListStore: AlbumListStore;
    public detailStore: DetailStore;
    public formatStaStore: FormatStaStore;
    public eventListStore: EventListStore;
    public sceneListStore: SceneListStore;
    public sceneDetailStore: SceneDetailStore;
    public topHotStore: TopHotStore;
    public userListStore: UserListStore;
    public userDetailStore: UserDetailStore;
    public smoisStore: SmoisStore;

    public constructor(stores: Stores) {
        super(stores);
        this.secListStore = new SecListStore(stores, this);
        this.categoryListStore = new CategoryListStore(stores, this);
        this.columnListStore = new ColumnListStore(stores, this);
        this.timeListStore = new TimeListStore(stores, this);
        this.districtListStore = new DistrictListStore(stores, this);
        this.newImportStore = new NewImportStore(stores, this);
        this.albumListStore = new AlbumListStore(stores, this);
        this.formatStaStore = new FormatStaStore(stores, this);
        this.detailStore = new DetailStore(stores, this);
        this.eventListStore = new EventListStore(stores, this);
        this.sceneListStore = new SceneListStore(stores, this);
        this.sceneDetailStore = new SceneDetailStore(stores, this);
        this.topHotStore = new TopHotStore(stores, this);
        this.userListStore = new UserListStore(stores, this);
        this.userDetailStore = new UserDetailStore(stores, this);
        this.smoisStore = new SmoisStore(stores, this);
    }
}
