import {http} from "../utils/http";
import {AxiosPromise} from "axios";
import recycleBinTypes from "../types/recycleBinTypes";
import {IResponse} from "mam-core-react/dist/modules/http";

namespace recycleBinApi {
    export const getRecycleBinList = (params: recycleBinTypes.IRecycleParams): AxiosPromise<IResponse<recycleBinTypes.IGetRecycleBinListResponse>> => {
        return http.post('/recycle/query', params);
    }
    export const restore = (params: recycleBinTypes.IGetRecycleBinList[]): AxiosPromise<IResponse<boolean>> => {
        return http.post('/recycle/restore', params);
    }
    export const del = (params: recycleBinTypes.IDelData[]): AxiosPromise<IResponse<boolean>> => {
        return http.post('/recycle/delete', params);
    }
    export const clear = (params: recycleBinTypes.IRecycleParams): AxiosPromise<IResponse<any>> => {
        return http.post('/recycle/clear', params);
    }
}
export default recycleBinApi