import BaseStore from "../base/baseStore";
import {action, observable, runInAction} from "mobx";
import {Stores} from "../index";
import uploadTypes from "../../types/uploadTypes";
import uploadApi from "../../apis/uploadApi";
import _ from 'lodash';
import commonUtil from "mam-common-utils/dist/modules/commonUtil";

export interface IUploadPanelStore{
    uploadPanelStore: UploadPanelStore;

    initUploadPanelStore : (stores: Stores) => void;
}

export default class UploadPanelStore extends BaseStore<Stores> {
    @observable
    public showTaskPanel: boolean = false;
    @observable
    public tasks: uploadTypes.IUnfinishedTaskRes[];

    @observable
    public uploadedRefreshSpan:number = 3000

    @action
    public setShowTaskPanel(show: boolean){
        this.showTaskPanel = show;
    }

    @action
    public getUnFinishedTask(param: uploadTypes.IUnfinishedTaskReq){
        uploadApi.getUnfinishedTask(param).then((res) => {
            runInAction(() => {
                if (res.data.success === true && res.data.data)
                {
                    res.data.data.forEach((task) => {
                        task.status = 'error';
                        task.inited = true;
                        task.sizeTotal = task.chunkFinished = task.chunkTotal = 0;
                        if (task.files)
                        {
                            task.files.forEach((file) => {
                                file.fileSizeString = commonUtil.formatSize(file.fileSize || 0);
                                file.progress = this.calcFileProgress(file);
                                file.status = file.progress === 100 ? 'success' : 'error';
                                if (!task.sizeTotal)
                                {
                                    task.sizeTotal = 0;
                                }
                                if (file.fileSize)
                                {
                                    task.sizeTotal += file.fileSize ;
                                }
                                if (!task.chunkTotal)
                                {
                                    task.chunkTotal = 0;
                                }
                                if (file.chunkTotal)
                                {
                                    task.chunkTotal += file.chunkTotal;
                                }
                            });
                        }
                        task.progress = this.calcTaskProgress(task);
                        task.sizeTotalString = commonUtil.formatSize(task.sizeTotal);
                    });
                    this.tasks = res.data.data;
                }
            });
        });
    }

    @action
    public addTask(task: uploadTypes.IUnfinishedTaskRes){
        if (!this.tasks)
        {
            this.tasks = [];
        }
        this.tasks.push(task);
    }

    @action
    public updateTask(task: uploadTypes.IUnfinishedTaskRes){
        let targetTask = _.find(this.tasks, {taskId: task.taskId});
        if (targetTask && task.files && targetTask.files)
        {
            if (task.progress){
                targetTask.progress = parseFloat(task.progress as any);
            }

            task.files.forEach((file, index) => {
                if (targetTask && targetTask.files)
                {
                    targetTask.files[index].progress = parseFloat(file.progress as any);
                    targetTask.files[index].status = file.status;
                }
            });
        }
    }

    @action
    public removeTask(task: uploadTypes.IUnfinishedTaskRes){
        let idx = _.findIndex(this.tasks, {taskId: task.taskId});
        this.tasks.splice(idx, 1);
    }

    @action
    public getUploadConfig = () => {
        uploadApi.getUploadConfig()
            .then(res=>{
                if(res.data.success && res.data.data){
                    this.setUploadedRefreshSpan(res.data.data.config.uploadedRefreshSpan)
                }
            })
    }

    @action
    private setUploadedRefreshSpan = (uploadedRefreshSpan:number) => {
        this.uploadedRefreshSpan = uploadedRefreshSpan
    }

    private calcProgress = (current: number, total: number): number => {
        if (current !== 0 && current === total)
        {
            return 100;
        }
        let result: number = current && total ? ((current / total) * 100) : 0;
        if (result.toString().indexOf('.') === -1)
        {
            return result;
        }
        return parseFloat(result.toFixed(2));
    }

    private calcFileProgress(file: uploadTypes.IFile) {
        return this.calcProgress(file.chunkIndex || 0, file.chunkTotal || 0);
    }

    private calcTaskProgress(task: uploadTypes.ITask) {
        let count: number = 0;
        for (let i = 0; i < (task.files || []).length; i++)
        {
            if (task.files)
            {
                count += task.files[i].chunkIndex || 0;
            }
        }
        task.chunkFinished = count;
        return this.calcProgress(task.chunkFinished, task.chunkTotal || 0);
    }
}