import { http } from 'mam-core-react'
import personalCenterTypes from 'src/types/personalCenterTypes'
import { AxiosPromise } from 'axios'
import { IResponse } from 'mam-core-react/dist/modules/http'

namespace personalCenterApi {
    export const getMessageTable = (pageIndex: number): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/user/get-current-user-message?pageIndex=${pageIndex}`)
    }
    //个人信息
    export const getUserInfo = ():AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/user/get-user-extend-info`);
    }

    export const changeUserInfo = (model: personalCenterTypes.IUserInfo): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/user/change-user-info', model)
    }

    export const changePassword = (model:  personalCenterTypes.IChangePassword):AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/user/change-password', model)
    }

    //获取上传头像的配置
    export const getAvatarConfig = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/user/get-avatar-config')
    }
    //更新头像
    export const uploadAvatar = (file: personalCenterTypes.IUploadAvatar): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/user/set-avatar', file)
    }
}

export default personalCenterApi