import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import { observable, action, runInAction } from 'mobx';
import { message } from 'antd';
import _ from 'lodash'
import ContentSearchStore from '../common/contentSearchStore';
import IIndexStore from '../iIndexStore';
import iIndexApi from 'src/apis/iIndexApi';
import iIndexTypes from 'src/types/iIndexTypes';

export default class DetailStore extends ExtendStore {
    @observable
    public name: string = '';
    @observable
    public columnName: string = '';
    @observable
    public source: string = '';
    @observable
    public selectedTabKey: string = 'content';
    @observable
    public selectedSecTabKey: string = '';
    @observable
    public detail?: iIndexTypes.thiDetail.IThiDetails;
    
    public iIndexStore: IIndexStore
    public contentSearchStore: ContentSearchStore;

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);

        this.iIndexStore = iIndexStore;
        this.contentSearchStore = new ContentSearchStore(stores, this);
        this.contentSearchStore.initSelectAllStore(stores, 'stores.iIndexStore.detailStore.contentSearchStore.fullSearchData.data', 'selected');
        this.contentSearchStore.initDataViewModeStore(stores);
        this.contentSearchStore.initDownloadStore(stores);
        this.contentSearchStore.initOutstoreStore(stores);
        this.contentSearchStore.initDeleteStore(stores);
    }

    @action
    public getDetail(type: string, id?: string){
        iIndexApi.getThiDetails(type, this.name, id).then((res)=>{
            runInAction(()=>{
                if (res.data.data){
                    this.detail = res.data.data;
                }
            });
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        });
    }

    @action
    public setName(name: string){
        this.name = name;
    }
    @action
    public setColumnName(name: string){
        this.columnName = name;
    }
    @action
    public setSource(name: string){
        this.source = name;
    }

    @action
    public setSelectedTabKey(key: string){
        this.selectedTabKey = key;
        if (key === 'bochuban'){
            this.selectedSecTabKey = 'bochubanzhengdang';
        }
        else if (key === 'ziliaoban'){
            this.selectedSecTabKey = 'ziliaobanzhengdang';
        }
        else if (key === 'fabuqu'){
            this.selectedSecTabKey = 'fabuquzhengdang';
        }
    }

    @action
    public setSelectedSecTabKey(key: string){
        this.selectedSecTabKey = key;
    }
}