import ExtendStore from "../base/extendStore";
import { observable, action, runInAction } from 'mobx';
import FindStore from './subPages/findStore/findStore';
import DeskStore from './subPages/deskStore/deskStore';
import { Stores } from '..';

export default class AppStore extends ExtendStore {
    @observable
    public selectedTabKey: string = 'find'
    public findStore: FindStore;
    public deskStore: DeskStore;
    public constructor(stores: Stores) {
        super(stores);
        this.findStore = new FindStore(stores, this);
        this.deskStore = new DeskStore(stores, this);
    }

    @action
    public setSelectedTabKey(key: string){
        this.selectedTabKey = key;
    }
}
