import { action, observable } from "mobx";
import ccTypes from "../../../../types/ccTypes";
import * as React from "react";
import _ from "lodash";
import { Moment } from "moment";
import FilterTemplateStore from "./filterTemplateStore";
import BaseStore from "../../../base/baseStore";
import { Stores } from "../../../index";

export interface ISearchFilterStore {
    searchFilterStore: SearchFilterStore;

    initSearchFilterStore: (stores: Stores) => void;
}

type FacetsType = ccTypes.searchTypes.IFacets | ccTypes.cloudTypes.IFullSearchFacets;
type FacetValueType = ccTypes.searchTypes.IFacetsFacetValue | ccTypes.cloudTypes.IFullSearchFacetsFacetValue;

export default class SearchFilterStore extends BaseStore<Stores>{
    public filterTemplateStore: FilterTemplateStore = new FilterTemplateStore()
    /** 已选择层面 */
    @observable
    public selectedFacets: ccTypes.searchTypes.ISelectedFacets[] = [];
    /** 展示的层面数量，默认3 */
    @observable
    public showFacetsNum: number = 3;
    /** 参数中的condition对象数组 */
    @observable
    public paramConditions: ccTypes.searchTypes.ISearchCondition[];
    /** 层面查询数据 */
    @observable
    public facets: ccTypes.searchTypes.IFacets[] | ccTypes.cloudTypes.IFullSearchFacets[] = [];
    /** 查询的总条数 */
    @observable
    public recordTotal?: number;
    /** 多选开启或关闭 */
    @observable
    public openCheckBox: boolean = true;

    /** 层面检索 */
    @action
    public facetsKeyWordSearch(filterData: FacetsType, e: React.ChangeEvent<HTMLInputElement>) {
        if (!filterData.facetValue) {
            return;
        }
        filterData.searchKeyword = e.target.value;
        if (filterData.searchKeyword) {
            filterData.facetValue.forEach(item => {
                let m = (window as any).pinyinMatch.match(item.showValue, filterData.searchKeyword);
                if (m) {
                    item.isShow = true;
                } else {
                    item.isShow = false;
                }
            });
        } else {
            filterData.facetValue.forEach(item => {
                item.isShow = true;
            });
        }
    }

    /**
     * 是否显示更多层面
     */
    @action
    public showMoreFacets = (item: FacetsType, showMore: boolean) => {
        item.showMore = showMore;
    }

    /**
     * 开启或关闭多选
     */
    @action
    public facetsOpenCheckbox = (parent: FacetsType) => {
        if (!parent.facetValue) {
            return;
        }
        if (parent.checkboxIsOpen) {
            parent.checkboxIsOpen = false;
            parent.facetValue.forEach(item => {
                item.isShow = true;
                item.isSelected = false;
            });
            parent.showMore = false;
        } else if (parent.checkboxIsOpen === false) {
            parent.checkboxIsOpen = true;

            parent.facetValue.forEach(item => {
                item.isShow = true;
                item.isSelected = false;
            });
            parent.showMore = true;
        }
        parent.searchKeyword = '';
    }

    //开启或关闭多选
    @action
    public setOpenCheckBox(ishowCheckBox: boolean) {
        this.openCheckBox = ishowCheckBox
    }

    // 层面添加多选
    @action
    public facetsAddcheckbox = (item: FacetValueType) => {
        item.isSelected = !item.isSelected;
    }

    @action
    public addSelectedFacets(add: ccTypes.searchTypes.ISelectedFacets) {
        const keys = add.showValue.split('：')
        let idx = _.findIndex(this.selectedFacets, { showValue: add.showValue });
        //判断重复
        if (idx === -1) {
            // 搜索前先删除之前的条件
            this.selectedFacets.forEach((item, index) => {
                if (item.showValue.split('：')[0] === keys[0]) {
                    this.selectedFacets.splice(index, 1)
                }
            })
            this.selectedFacets.push(add);
        }
    }

    /** 入库日期自定义填写 */
    @action
    public onFacetCreateDateChanged(filterData: FacetsType, name: string, date: Moment, dateString: string) {
        filterData[name] = dateString;
    }

    @action
    public removeSelectedFacets(idx: number) {
        this.selectedFacets.splice(idx, 1);
    }

    @action
    public emptySelectedFacets() {
        this.selectedFacets.length = 0;
    }

    @action
    public setShowFacetsNum(num: number) {
        this.showFacetsNum = num;
    }

    @action
    public setParamConditions(conditions: ccTypes.searchTypes.ISearchCondition[]) {
        this.paramConditions = conditions;
    }

    @action
    public setFacets(facets: ccTypes.searchTypes.IFacets[] | ccTypes.cloudTypes.IFullSearchFacets[]) {
        this.facets = facets;
    }

    @action
    public setRecordTotal(total: number) {
        this.recordTotal = total;
    }
}