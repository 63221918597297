import CcStore from "../ccStore";
import { action, observable, runInAction } from "mobx";
import ccTypes from "../../../types/ccTypes";
import ccApi from "../../../apis/ccApi";
import { Stores } from "../../index";
import _ from 'lodash'
import PublicEntityStore from "./publicEntityStore";
import PersonalEntityStore from "./personalEntityStore";
import commonUtil from "mam-common-utils/dist/modules/commonUtil";

export default class CloudStore extends CcStore {
    public publicEntityStore: PublicEntityStore;
    public personalEntityStore: PersonalEntityStore;

    @observable
    public params: ccTypes.cloudTypes.ICloudParams;
    @observable
    public fullSearchData?: ccTypes.cloudTypes.IFullSearchRes;

    // 主搜索框的值
    @observable
    public clouldKeyword1: string = ''

    // 主搜索框前面的选项
    @observable
    public clouldKeyword1Type: string

    @observable
    public requestURL: string

    constructor(stores: Stores) {
        super(stores);
        this.initSelectAllStore(stores, 'stores.cloudStore.fullSearchData.data', 'selected');
        this.initUploadStore(stores);
        this.initUploadPanelStore(stores);
        this.initOutstoreStore(stores);
        this.initDeleteStore(stores);
        this.initCreatePlanningStore(stores);
        this.initMoveStore(stores);
        this.initUnlockStore(stores);
        this.initRenameStore(stores);
        this.initShareStore(stores);
        this.initCopyStore(stores);
        this.initCreateFolderStore(stores);
        this.initAdvancedSearchStore(stores);
        this.initBindBCProgramCodeStore(stores);
        
        this.publicEntityStore = new PublicEntityStore(stores);
        this.personalEntityStore = new PersonalEntityStore(stores);
    }

    @action
    public setParams(params: ccTypes.cloudTypes.ICloudParams) {
        this.params = params;
    }

    @action
    public queryFullSearchData(page?: number): Promise<any> {
        this.selectAllStore.switchAllSelected(false);
        this.fullSearchData = undefined;
        let param = this.params || {};
        let requestURL = param.isPublic ? '/public-tree/' : '/private-tree/';
        this.requestURL = requestURL
        return new Promise((resolve, reject) => {
            this.getSortItems();
            let req: ccTypes.cloudTypes.IFullSearchReq = {
                pageIndex: page || param.page,
                pageSize: this.stores.configStore.config.cloudSearchResultNum,
                sort: param.sort ? param.sort : this.currentSort.field,
                order: param.order ? param.order : this.currentSort.desc,
                keyword: param.keyword,
                folderCode: param.folderCode,
                type: param.type,
                name: param.name ? param.name : '',
                condition: param.conditions ? param.conditions.map(o => {
                    return {
                        field: o.field || '',
                        value: o.items ? o.items[0].value : (o.value ? o.value : '')
                    };
                }) : [],
                isQueryDirectSub: param.isQueryDirectSub === undefined ? true : param.isQueryDirectSub
            };
            if (param.advancedSearch && param.advancedSearch.field.length > 0 && param.advancedSearch.isOnlyAvSearching) {
                if(this.stores.configStore.config.wuXiCustomSwitch){
                    const params: ccTypes.searchTypes.IAdvancedSearchTypes = {
                        field:  param.advancedSearch.field.filter(item => {
                            if(item.value !== '' && item.value !== "notFound" && item.value !== undefined && item.value !== '[]' && item.value !== null) {
                                return item
                            }else {
                                return false
                            }
                        }),
                        isOnlyAvSearching: param.advancedSearch.isOnlyAvSearching
                    }
                    if(params.field && params.field.length > 0 ){
                        req.advancedSearch = this.handleWuxiAdvancedSearch(params)
                    }
                }else{
                    req.advancedSearch = param.advancedSearch
                }
            }
            if(this.stores.configStore.config.wuXiCustomSwitch && param.isPublic){
                req.condition.push({
                    field: "privilege_",
                    value: ["public_S1"] 
                })
            }
            ccApi.cloudApi.fullSearch(requestURL, req).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        this.initFacets(res.data.data.facets);
                        if (res.data.data.data) {
                            this.resolveQueryResult(res.data.data.data, (item: ccTypes.cloudTypes.IFullSearchData) => {
                            });
                        }
                        /** 无锡定制：根据素材id获取云端高低码状态 */
                        if(this.stores.configStore.config.pushPullSwitch){
                            this.getProgramNameCloud(res.data.data.data)
                        }
                        this.fullSearchData = res.data.data;
                        this.fullSearchData.data.forEach(item => {
                            item.selected = false
                        })
                    }
                });
                resolve();
            }, () => {
                reject();
            })
        });
    }

    /**
     * 重命名
     */

    @action
    public setRename(index: number, editMode: boolean) {
        if (this.fullSearchData) {
            if (editMode) {
                this.fullSearchData.data[index].oldName = this.fullSearchData.data[index].name
            }
            this.fullSearchData.data[index].editMode = editMode
        }
    }

    @action
    public setNewRename(index: number, newName: string) {
        if (this.fullSearchData) {
            this.fullSearchData.data[index].name = newName
            if (!this.fullSearchData.data[index].isCreate) {
                const contentId = this.fullSearchData.data[index].contentId
                if (!this.params.isPublic) {
                    const i = _.findIndex(this.personalEntityStore.folders, { contentId })
                    if (i >= 0) {
                        this.personalEntityStore.folders[i].name = newName
                    }
                } else {
                    const i = _.findIndex(this.publicEntityStore.publicTree, { contentId })
                    if (i >= 0) {
                        this.publicEntityStore.publicTree[i].name = newName
                    }
                }
            }
        }
    }

    @action
    public saveNewRename(index: number) {
        if (this.fullSearchData) {
            const requset: ccTypes.CheckName.ICheckNameReq = {
                ContentId: this.fullSearchData.data[index].contentId,
                Type: this.fullSearchData.data[index].type,
                NewName: this.fullSearchData.data[index].name,
                ParentId: this.fullSearchData.parent[this.fullSearchData.parent.length - 1].contentId
            }
            const url = this.params.isPublic ? '/public-tree/' : '/private-tree/';
            return ccApi.checkName(url, [requset])
        }
        return '';
    }

    @action
    public setClouldKeyword1(keyword: string) {
        this.clouldKeyword1 = keyword
    }

    @action
    public setClouldKeyword1Type(type: string) {
        this.clouldKeyword1Type = type
    }

    @action
    public setNewFullSearchData(fullSearchData: ccTypes.cloudTypes.IFullSearchData) {
        if (this.fullSearchData) {
            this.selectAllStore.switchAllSelected(false)
            this.selectAllStore.switchListItemSelected(undefined, false)
            if (!fullSearchData.isCreate) {
                setTimeout(() => {
                    runInAction(() => {
                        //延迟执行 不然会刷不出来
                        this.queryFullSearchData().then(() => {
                            if (!this.params.isPublic) {
                                this.personalEntityStore.setFolders([])
                                this.personalEntityStore.getFolders()
                            } else {
                                this.publicEntityStore.setPublicTree([], [], this.publicEntityStore.isFirstLevel)
                                this.publicEntityStore.getPublicTree()
                            }
                        })
                    })
                }, 500)
            } else {
                runInAction(() => {
                    if (this.fullSearchData) {
                        const fullSearchData1 = commonUtil.copyObj(this.fullSearchData)
                        fullSearchData1.data.unshift(fullSearchData)
                        this.fullSearchData = fullSearchData1
                    }
                })
            }
        }
    }

    @action
    public onCreateSuccess(fullSearchData: ccTypes.cloudTypes.IFullSearchData) {
        if (this.fullSearchData) {
            this.selectAllStore.switchAllSelected(false)
            this.selectAllStore.switchListItemSelected(undefined, false)
            if (this.fullSearchData.data && this.fullSearchData.data.length > 0) {
                this.fullSearchData.data[0].contentId = fullSearchData.contentId
                this.fullSearchData.data[0].name = fullSearchData.name
                this.fullSearchData.data[0].storagedDate = fullSearchData.storagedDate
                this.fullSearchData.data[0].canDelete = true
                this.fullSearchData.data[0].canWrite = true
                this.fullSearchData.recordTotal += 1
                if (this.params.isPublic) {
                    if (!this.params.folderCode) {
                        const publicTree = commonUtil.copyObj(this.publicEntityStore.publicTree)
                        publicTree.unshift(this.fullSearchData.data[0])
                        this.publicEntityStore.setPublicTree(publicTree, this.publicEntityStore.parent, this.publicEntityStore.isFirstLevel)
                    }
                } else {
                    const personalFolders = commonUtil.copyObj(this.personalEntityStore.folders)
                    personalFolders.unshift(this.fullSearchData.data[0])
                    this.personalEntityStore.setFolders(personalFolders)
                }

            }
        }
    }

    @action
    public refresh() {
        this.queryFullSearchData()
    }

    @action
    public create = (model: any) => {
        this.selectAllStore.switchAllSelected(false)
        this.selectAllStore.switchListItemSelected(undefined, false)
        if (this.fullSearchData) {
            this.setNewFullSearchData(model)
        }
    }

    public getParent = () => {
        if (this.fullSearchData && this.fullSearchData.parent && this.fullSearchData.parent.length > 0) {
            return this.fullSearchData.parent[this.fullSearchData.parent.length - 1]
        }
        return {
            canDelete: false,
            canExecute: false,
            canRead: false,
            canWrite: false,
            contentId: '',
            treeType: 1
        }
    }
    public getTreeParent = () => {
        if (this.fullSearchData && this.fullSearchData.parent && this.fullSearchData.parent.length > 0) {
            return this.fullSearchData.parent[this.fullSearchData.parent.length - 2]
        }
        return {
            canDelete: false,
            canExecute: false,
            canRead: false,
            canWrite: false,
            contentId: '',
            treeType: 1
        }
    }
}
