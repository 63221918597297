import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import AppStore from '../../appStore';
import { http } from 'mam-core-react';
import { IResponse } from 'mam-core-react/dist/modules/http';
import { message } from 'antd';
import { commonUtil } from 'mam-common-utils'
interface ITaskReq {
    conditions: any
    order: string
    keyword: string
    pageIndex: number
    pageSize: number
    sort: string
    userCode: string
}
interface IAuditReq {
    auditStatus: number
    order: string
    sort: string
    taskState: any
    auditCurrentStep: any
    createTaskTimeEnd: string
    createTaskTimeStart: string
    creatorName: string
    entityName: string
    keyword: string
    pageIndex: number
    pageSize: number
}
export default class DeskStore extends ExtendStore {
    @observable
    public selectedTabKey: string = 'task';  // 当前选中tab
    @observable
    public isReqList: boolean = false;  // 是否请求状态
    @observable
    public currSort: string = 'createtime|desc';  // 排序当前选中
    // 任务参数
    @observable
    public taskParams: ITaskReq = {
        conditions: {
            cliptype: '',
            createtime: '',
            createusername: '',
            targetInfoCode: '',
            taskstatus: '',
            tasktype: ''
        },
        order: this.currSort.split('|')[1],
        keyword: '',
        pageIndex: 1,
        pageSize: 20,
        sort: this.currSort.split('|')[0],
        userCode: ''
    }
    // 审核参数
    @observable
    public auditParams: IAuditReq = {
        auditStatus: 2,
        order: "desc",
        sort: "createTaskTime",
        taskState: null,
        auditCurrentStep: null,
        createTaskTimeEnd: "",
        createTaskTimeStart: "",
        creatorName: "",
        entityName: "",
        keyword: "",
        pageIndex: 1,
        pageSize: 20
    }
    // 我的任务列表
    @observable
    public taskList?: any
    // 我的审核列表
    @observable
    public auditList?: any
    private appStore: AppStore;

    public constructor(stores: Stores, appStore: AppStore) {
        super(stores);
        this.appStore = appStore;
        this.initSelectAllStore(stores, 'stores.appStore.deskStore.auditList.data', 'selected');
    }

    // 获取任务列表
    @action
    public queryTaskList(page?: number, isAppend?: boolean) {
        this.setIsReqList(true)
        if (!isAppend) {
            this.taskList = undefined;
        }
        http.post<IResponse<any>>(`/mytask/query`, {
            ...this.taskParams,
            pageIndex: page || 1
        }).then(res=>{
            if(res.data.data && res.data.success) {
                if (!isAppend) {
                    this.setTaskList(res.data.data)
                } else if (this.taskList && res.data.data && res.data.data.data.length > 0) {
                    let newList = commonUtil.copyObj(this.taskList)
                    newList.data = this.taskList.data.concat(res.data.data.data);
                    newList.pageIndex = res.data.data.pageIndex;
                    newList.pageSize = res.data.data.pageSize;
                    newList.pageTotal = res.data.data.pageTotal;
                    newList.recordTotal = res.data.data.recordTotal;
                    this.setTaskList(newList)
                }
                this.setIsReqList(false)
            }
        }, res=>{
            if (res.data.error && res.data.error.title){
                this.setIsReqList(false)
                message.error(res.data.error.title);
            }
        });
    }

    // 获取审核列表
    @action
    public queryAuditList(page?: number, isAppend?: boolean) {
        this.setIsReqList(true)
        if (!isAppend) {
            this.auditList = undefined;
        }
        http.post<IResponse<any>>(`/flowstep/exportaudit/list-audittask`, {
            ...this.auditParams,
            pageIndex: page || 1
        }).then(res=>{
            if(res.data.data && res.data.success) {
                if (!isAppend) {
                    this.setAuditList(res.data.data)
                } else if (this.auditList && res.data.data && res.data.data.data.length > 0) {
                    let newList = commonUtil.copyObj(this.auditList)
                    newList.data = this.auditList.data.concat(res.data.data.data);
                    newList.pageIndex = res.data.data.pageIndex;
                    newList.pageSize = res.data.data.pageSize;
                    newList.pageTotal = res.data.data.pageTotal;
                    newList.recordTotal = res.data.data.recordTotal;
                    this.setAuditList(newList)
                }
                this.setIsReqList(false)
            }
        }, res=>{
            if (res.data.error && res.data.error.title){
                this.setIsReqList(false)
                message.error(res.data.error.title);
            }
        });
    }

    // 设置当前选中tab
    @action
    public setSelectedTabKey(key: string) {
        this.selectedTabKey = key;
    }

    // 设置任务参数
    @action
    public setTaskParams(payload: ITaskReq) {
        this.taskParams = payload;
        this.queryTaskList()
    }

    // 设置审核参数
    @action
    public setAuditParams(payload: IAuditReq) {
        this.auditParams = payload;
        this.queryAuditList()
    }

    // 设置请求状态
    @action
    public setIsReqList(payload: boolean) {
        this.isReqList = payload;
    }
    // 设置请求状态
    @action
    public setTaskList(payload: any) {
        this.taskList = payload;
    }
    @action
    public setAuditList(payload: any) {
        this.auditList = payload;
    }
}