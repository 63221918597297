import { IBasePageRequest } from './commonTypes';
import { Moment } from 'moment';
import IFormItem from "mam-metadata-react/dist/types/IFormItem";

namespace emptyEntityTypes {
    export interface IProgramField {
        groupName: string,
        groupCode: string,
        fields: IFormItem[],
        metadataTable?: any
    }

    export interface IProgramInfo {
        id: number,
        programName: string,
        programCode: string
    }

    export interface IQueryWwProgramRequest extends IBasePageRequest {
        startTime?: Moment
        endTime?: Moment
        keywordType: QueryWwProgramKeywordType
    }

    export enum QueryWwProgramKeywordType {
        programName = 'program_name',
        programCode = 'program_code'
    }

    export interface IWwProgramModel {
        id: number
        programName: string
        programCode: string
        createUser: string
        createTime: Date
        lastUpdateUser: string
        lastUpdateTime: Date
        key?: number
    }

    export enum UploadTaskStatus {
        init = 0,
        inited = 1,
        wait = 2,
        uploading = 3,
        uploaded = 4,
        success = 5,
        deleted = 6
    }

    export interface IEmptyEnityInfo {
        contentId: string,
        name: string,
        tapeno: string
    }

    export interface IEmptyEntityUploadTask {
        id: string,
        tapeno: string,
        contentId: string,
        contentName: string,
        storageInfoJson?: string,
        files: IEmptyEntityFileModel[],

        status: UploadTaskStatus,
        percent: number,
        canUpload: boolean
    }

    export interface IEmptyEntityFileModel {
        id: number,
        taskId: string,
        fileName: string,
        fileSize: number,
        chunkSize: number,
        chunkIndex: number,
        chunkTotal: number,
        relativePath: string,
        absolutePath: string,

        file?: any,
        status: UploadTaskStatus
        isAttachment: boolean
        percent?: number
    }

    export interface IEmptyEntityFileUploadDto {
        taskId: string,
        fileSize: number,
        chunkSize: number,
        fileData: object,
        isFinished: boolean
    }
}

export default emptyEntityTypes