import {stores} from "../../index";
import {IEntityType} from "../../../types/configTypes";
import {message} from "antd";
import uploadTypes from "../../../types/uploadTypes";
import {uploader} from "../../../components/upload/core/uploader";
import {runInAction} from "mobx";
import UploadStore from "./uploadStore";
import _ from 'lodash';
import commonUtil from "mam-common-utils/dist/modules/commonUtil";

export default class PicPkgHandler{
    private uploadStore: UploadStore;
    constructor(uploadStore: UploadStore) {
        this.uploadStore = uploadStore;
    }

    /** 组装图片包数据 */
    public handleFiles(options: uploadTypes.IUploadOptions, tasks: uploadTypes.ITask[], files: uploadTypes.IFile[]): Promise<uploadTypes.IFile[]> {
        let result: uploadTypes.IFile[] = [];
        let resultP: Array<Promise<any>> = [];
        let picType = _.find(stores.configStore.config.entityTypes, { code: 'picture' });
        let ext: string[] = [];
        let fileName: string[] = [];
        let flg = false;
        _.forEach(files, (file: uploadTypes.IFile, index: number) => {
            if ((tasks && tasks.length > 0 && tasks[0].files
                    && uploader.checkExist(tasks[0].files, file))) {
                return;
            }
            if (file.metadata.ext && picType && _.indexOf(picType.extensions, file.metadata.ext.toLowerCase()) > -1) { //检查是否为允许的格式
                if (index === 0 && (tasks[0].metadata.name == null || tasks[0].metadata.name === '')) {
                    tasks[0].metadata.name = file.metadata.name;
                    if (tasks[0].metadata.field)
                    {
                        let metaField = _.find(tasks[0].metadata.field, { fieldName: 'name_' });
                        if (metaField)
                        {
                            metaField.value = file.metadata.name;
                        }
                    }
                }
                result.push(file);
                resultP.push(this.getPreviewImage(options, picType, file));
            } else {
                flg = true;
                let temp = _.filter(ext, (item: string) => {
                    return item === file.metadata.ext;
                });
                if (!temp || temp.length === 0) {
                    ext.push(file.metadata.ext as string);
                }
                fileName.push((file.file as File).name);
            }
        });
        if (flg) {
            let a = ext.join("、");
            let b = fileName.join("、");
            message.error('对不起，图片包不支持 ' + a + ' 格式的文件，' + b + ' 添加失败')
        }
        return new Promise((resolve, reject)=>{
            Promise.all(resultP).then(()=>{
                resolve(result);
            });
        });
    }

    private getPreviewImage(options: uploadTypes.IUploadOptions, typeInfo: IEntityType, file: uploadTypes.IFile): Promise<any> {
        file.image = typeInfo.keyframe.replace('~', '');
        if (options.transferType === uploadTypes.TransferTypes.WEB) {
            //判断大小
            if (file.image.length >= (2 * 1024 * 1024)) {
                return Promise.resolve();
            }
            //判断扩展名
            let allowExt = ['png', 'gif', 'jpg', 'bmp', 'jpeg'];
            let ext = commonUtil.getExtension((file.file as File).name).toLowerCase();
            if (_.indexOf(allowExt, ext) === -1) {
                return Promise.resolve();
            }

            return new Promise((resolve, reject)=>{
                let reader = new FileReader();
                reader.onload = () => {
                    runInAction(()=>{
                        file.image = reader.result as string;
                        resolve();
                    })
                }
                reader.readAsDataURL(file.file as File);
            });
        } else { //vtube
            return Promise.resolve();
        }
    }
}