import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import ccTypes from 'src/types/ccTypes';
import {Stores} from "../index";

export default class RenameStore extends BaseStore<Stores>{
    @observable
    public showRenameModal:boolean = false

    @observable
    public requestUrl:string

    @observable
    public request:ccTypes.CheckName.ICheckNameReq

    @observable
    public index:number

    @action
    public setShowRenameModal(show:boolean){
        this.showRenameModal = show
    }

    @action
    public setRequestUrl(url:string){
        this.requestUrl = url
    }

    @action
    public setRequest(request:ccTypes.CheckName.ICheckNameReq){
        this.request = request
    }

    @action
    public setIndex(index:number){
        this.index = index
    }
}