import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import _ from 'lodash';
import {Stores} from "../index";
import SelectAllStore from "./selectAllStore";
import { commonUtil } from "mam-common-utils";
// import {IConfig} from "src/types/configTypes";

// interface IsmartProcessList {
//     label: string;
//     value: string;
//     type: string;
//   }
export default class BatchExecuteSmartProcessStore extends BaseStore<Stores>{
    @observable
    public showModal:boolean = false

    @observable
    public selectedItems:any[] = []
    @observable
    public allSelected: boolean = false;
    public selectAllStore: SelectAllStore
 
    public supportType:string[] | undefined = []//保存ips中的sendBtn里supportType所有的值（去重）
    @observable
    public smartProcessList:any[] = [];//智能处理列表

    public constructor(stores: Stores){
        super(stores);
        this.selectAllStore = new SelectAllStore(stores, 'this.selectedItems', 'checked', this)
    }

    @action
    public openModal(selectedItems: any[]){
        this.selectAllStore.allSelected = false;
        this.selectedItems = commonUtil.copyObj(selectedItems)
        this.selectedItems.forEach((item)=>{
            item.checked = false;
            item.checkedList = []
        })
        this.showModal = true;
        this.getSupportType();
        this.getSmartProcessList();
    }

    @action
    public hide(){
        this.showModal = false;
        //关闭弹窗，清空列表选中
        if(this.stores.configStore.config.ips.enableSendBtnFlow){
            this.cleanCheckBox()
        }else{
            this.stores.searchStore.selectAllStore.switchAllSelected(false);
            this.stores.searchStore.selectAllStore.switchListItemSelected(undefined, false);
        }
    }

    @action
    public removeSelectedItem(item: any){
        _.remove(this.selectedItems, (o)=>{
            return o === item;
        })
    }

    //获取sendBtn中是否有supportType并找出supportType包含的所有值（去重）
    @action
    public getSupportType = ()=>{
        let sendBtn = this.stores.configStore.config.ips.sendBtn;
        let temp = new Set<string>();
        sendBtn = sendBtn.filter((item)=>{
            return item.enable && item.supportType
        })
        sendBtn.forEach((item)=>{
            item.supportType?.forEach(menber=>{
                temp.add(menber)
            })
        })
        this.supportType = Array.from(temp)
    }
    @action
    public setSmList = ()=>{
        this.smartProcessList = this.supportType?.map((item)=>{
            let type = item.split('_')[2]
            let param = {
                    'type': type,
                    'type_':'biz_sobey_'+[type],
                    'List':[]
            }
            return param
        }) || []
    }
    //获取智能处理checkbox的列表
    @action
    public getSmartProcessList = () => {
        let configStore = this.stores.configStore;
        let sendBtn = configStore.config.ips.sendBtn;
        this.setSmList()
        if(!this.supportType?.length){
            return
        }else {
            sendBtn = sendBtn.filter((item)=>{
                return item.enable //&& item.isStructure //筛选出符合条件原子能力选项
            })
            sendBtn.forEach((item)=>{
                if(item.supportType && !item.supportType[0]){
                    item.supportType = this.supportType
                }
                item.supportType?.forEach((menber)=>{
                    for(let i = 0; i <  this.smartProcessList.length; i++){
                        if((menber === this.smartProcessList[i].type_)){
                            this.smartProcessList[i].List.push({
                                label:item.name,
                                value:item.key,
                            })
                        }
                    }
                })
            })
        }
    }

    //单选
    @action
    public handleSelect = (selectedItem:any, checkedList:string[])=>{
        let num = 0;
        selectedItem.checkedList = checkedList;
        this.selectedItems.forEach((item)=>{
            let sameType = this.smartProcessList?.filter((menber)=> item.type === menber.type)[0];
            if( sameType && sameType.List && sameType.List.length === item.checkedList.length){
                num++
            }
        })
        if(num === this.selectedItems.length){
            this.allSelected = true;
        }else{
            this.allSelected = false
        }
    }

    //全选
    @action
    public handleCheckAll = (e:any)=>{
        this.selectedItems.forEach(item=>{
            if(e.target.checked){
                let sameType = this.smartProcessList.filter((menber)=> item.type === menber.type)[0].List.map((menber:any)=> menber.value);
                item.checkedList = sameType;
            }else{
                this.allSelected = false;
                item.checkedList = []
            }
            
        })
        this.allSelected = e.target.checked;
    }

    //清空选中的checkBox
    @action
    public cleanCheckBox = () =>{
        this.selectedItems.forEach((item)=>{
            item.checkedList = []
        })
        this.allSelected = false
    }

}