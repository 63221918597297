import { Stores } from 'src/stores';
import ExtendStore from 'src/stores/base/extendStore';
import { observable, action, runInAction } from 'mobx';
import iSearchTypes from 'src/types/iSearchTypes';
import iSearchApi from 'src/apis/iSearchApi';
import { message } from 'antd';
import _ from 'lodash'
import DetailStore from '../subModules/detailStore';
import iIndexTypes from 'src/types/iIndexTypes';
import iIndexApi from 'src/apis/iIndexApi';
import { http } from 'mam-core-react';

export default class ContentSearchStore extends ExtendStore {
    @observable
    public searchKeyword: string = '';  // 主搜索框值
    @observable
    public searchEntityType = ''; //检索素材类型
    @observable
    public years: string[] = []; //年份列表
    @observable 
    public selectedYear: string | undefined; //选中的年份
    @observable
    public fullSearchData?: iSearchTypes.IFullSearchDataRes;
    @observable
    public leftAggr: iSearchTypes.IFacets | undefined;
    @observable
    public isReqLeftAggr = true;
    @observable
    public detailFacetSum: number = 0;
    /** 当前选中的层面 */
    @observable
    public selectedQFacet: iIndexTypes.thiDetail.ISelectedQFacet | undefined;
    @observable
    public pageSize: number = 45;
    @observable
    public isLoadingFullSearchData = true;
    @observable
    public appSelectedData: any = {}
    /** 选中的层面 */
    @observable
    public selectedConditions?: iSearchTypes.ISearchCondition[] = []
    @observable
    public showAggrs = false;
    @observable
    public sortItems: iSearchTypes.ISortItem[] = []
    /** 当前选中排序类型 */
    @observable
    public currentSort: iSearchTypes.ISortItem;

    public detailStore: DetailStore

    public constructor(stores: Stores, detailStore: DetailStore) {
        super(stores);
        this.initSearchFilterStore(stores);
        this.detailStore = detailStore;
    }

    /**
     * 查询左侧层面
     * @param conditions 
     * @param sourceType 
     * @param facetFields 
     */
     @action
     public queryLeftAggr(conditions: iIndexTypes.thiDetail.ISearchByEsOriginCondition[], sourceType: string, facetFields?: string[], sorts?: iIndexTypes.thiDetail.ISearchByEsOriginSort[]) {
         return new Promise((resolve, reject) => {
             let param: iIndexTypes.thiDetail.ISearchByEsOriginReq = {
                 pageIndex: 1,
                 pageSize: 1,
                 conditions: [],
                 sourceType,
                 extension: {
                     highlightFields: [
                         "name_"
                     ],
                     returnFields: [
                         "*"
                     ]
                 },
                 sorts
             }
             if (conditions) {
                 param.conditions = param.conditions.concat(conditions);
             }
             if (this.searchKeyword){
                 param.keyword = [this.searchKeyword]
             }
             if (this.searchEntityType) {
                 param.conditions.push({
                     field: 'type_',
                     nexus: 'Eq',
                     value: [
                         this.searchEntityType
                     ]
                 })
             }
             if (facetFields && facetFields.length > 0) {
                 let facet = {};
                 facetFields.forEach((facetName)=>{
                     facet[facetName] = null;
                 })
                 param.extension.facetFields = facet;
             }
             this.leftAggr = undefined;
             this.isReqLeftAggr = true;
             iIndexApi.searchByEsOrigin(param).then(res => {
                 runInAction(() => {
                     if (res.data.data && res.data.data.aggrs  && res.data.data.aggrs.length > 0) {
                        let taggrs = res.data.data.aggrs;
                        if (this.detailStore.detail){
                            let tdetailFacetSum  = _.sumBy(this.detailStore.detail.aggrs, (item)=> {
                                return item.count || 0;
                            });
                            this.detailFacetSum = tdetailFacetSum;
                        }
                        this.leftAggr = taggrs[0];
                     }
                     this.isReqLeftAggr = false;
                     resolve(undefined);
                 })
             }, res => {
                 if (res.data.error && res.data.error.title) {
                     message.error(res.data.error.title);
                 }
                 runInAction(()=>{
                    this.isReqLeftAggr = false;
                 })
                 reject();
             });
         })
     }

     /** 查询活动，全部数量 */
     @action 
     public queryDetailFacetSum(){
        let param: iIndexTypes.thiDetail.ISearchByEsOriginReq = {
            pageIndex: 1,
            pageSize: 1,
            conditions: [
                {
                    field: 'relatedperson_keyword.name',
                    nexus: 'Eq',
                    value: [this.detailStore.name]
                },
                {
                    field: 'type_',
                    nexus: 'Eq',
                    value: ["AI_Story"]
                }
            ],
            sourceType: 'custom',
            extension: {
                highlightFields: [
                    "name_"
                ],
                returnFields: [
                    "*"
                ]
            }
        }
        iIndexApi.searchByEsOrigin(param).then(res => {
            runInAction(() => {
                if (res.data.data) {
                    console.log(res.data.data.recordTotal)
                }
            })
        }).catch(res=>{
            if (res.data && res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            else {
                console.error(res);
            }
        })
     }

    /**
     * 内容检索
     * @param page 
     * @param conditions 
     * @param sourceType 
     * @param facetFields 
     * @param data 如果是滚动分页，则传入之前的data，fullSearchData就不会清空，会拼接
     */
    @action
    public queryFullSearchData(page: number, conditions: iIndexTypes.thiDetail.ISearchByEsOriginCondition[], sourceType: string, facetFields?: string[], sorts?: iIndexTypes.thiDetail.ISearchByEsOriginSort[], data?: any[], groupConditions?: iIndexTypes.thiDetail.ISearchByEsOriginGroupCondition[]) {
        return new Promise((resolve, reject) => {
            this.selectAllStore.switchAllSelected(false);
            const pageSizeStorage = localStorage.getItem('searchStore.pageSize');
            if (pageSizeStorage !== null) {
                this.pageSize = parseInt(pageSizeStorage, 10)
            }

            let param: iIndexTypes.thiDetail.ISearchByEsOriginReq = {
                pageIndex: page || 1,
                pageSize: this.pageSize,
                conditions: [],
                sourceType,
                extension: {
                    highlightFields: [
                        "name_"
                    ],
                    returnFields: [
                        "*"
                    ]
                },
                sorts: sorts || [{
                    field: this.currentSort.field,
                    desc: this.currentSort.desc === 'desc'
                }]
            }
            if (conditions) {
                param.conditions = param.conditions.concat(conditions);
            }
            if (groupConditions){
                param.groupConditions = groupConditions;
            }
            if (this.searchKeyword){
                param.keyword = [this.searchKeyword]
            }
            if (this.searchEntityType) {
                param.conditions.push({
                    field: 'type_',
                    nexus: 'Eq',
                    value: [
                        this.searchEntityType
                    ]
                })
            }
            if (this.selectedQFacet) {
                param.conditions.push({
                    field: this.selectedQFacet.facetFieldName,
                    nexus: 'Eq',
                    value: [this.selectedQFacet.value as string]
                })
            }
            //选中的层面
            if (this.selectedConditions){
                this.selectedConditions.forEach((condition) => {
                    if (condition.items && condition.items.length > 0 && condition.items[0].value && condition.items[0].value.length > 0){
                        param.conditions.push({
                            field: condition.field as string,
                            nexus: 'Eq',
                            value: condition.items[0].value as string[]
                        })
                    }
                });
            }
            if (facetFields && facetFields.length > 0) {
                let facet = {};
                facetFields.forEach((facetName)=>{
                    facet[facetName] = null;
                })
                param.extension.facetFields = facet;
            }
            //选中的年份
            if (this.selectedYear){
                param.conditions.push({
                    field: 'date_of_debut',
                    nexus: 'Eq',
                    value: [`${this.selectedYear}-01-01 00:00:00 TO ${this.selectedYear}-12-31 23:59:59`]
                })
            }
            if (!data){
                this.fullSearchData = undefined;
            }
            this.isLoadingFullSearchData = true;
            iIndexApi.searchByEsOrigin(param).then(res => {
                runInAction(() => {
                    if (res.data.data && res.data.data.data) {
                        res.data.data.data.forEach((item) => {
                            item.name = item.name_;
                            item.duration = item.duration_;
                            item.contentId = item.contentId_;
                            item.framerate = item.framerate_;
                        })
                        if (!res.data.data.aggrs) {
                            res.data.data.aggrs = []
                        }
                        this.initFacets(res.data.data.aggrs);
                        if (!data){//未传data，覆盖原结果
                            this.fullSearchData = res.data.data;
                            if (this.fullSearchData){
                                this.favoriteCheck(this.fullSearchData.data);
                            }
                        }
                        else if (res.data.data.data.length > 0) {//传了data，拼接
                            if (this.fullSearchData){
                                this.fullSearchData.data = this.fullSearchData.data.concat(res.data.data.data);
                                this.fullSearchData.pageIndex = res.data.data.pageIndex;
                                this.favoriteCheck(res.data.data.data);
                            }
                        }
                    }
                    resolve(undefined);
                    this.isLoadingFullSearchData = false;
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public getSortItems = () => {
        const config = this.stores.configStore.config;
        let sortItems: iSearchTypes.ISortItem[] = []
        if (config && config.searchSortFields && config.searchSortFields.length > 0) {
            sortItems = config.searchSortFields.filter((item)=>{
                return item.field !== '_score';
            }).map((item) => {
                return {
                    text: item.name || '',
                    field: item.field,
                    desc: item.desc ? 'desc' : 'asc',
                    hideDirection: true
                }
            })
        } else {
            sortItems = [
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'desc' },
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'asc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'desc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'asc' }
            ]
        }
        this.sortItems = sortItems;
        if (!this.currentSort) {
            this.setCurrentSort(sortItems[0])
        }
    }

    @action
    public setCurrentSort(sort: iSearchTypes.ISortItem) {
        this.currentSort = sort;
    }

    @action
    public favoriteCheck(data: any[]) {
        if (data) {
            let contentIds: string[] = data.map((item) => {
                return item.contentId;
            })
            iSearchApi.favoriteCheck(contentIds).then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        res.data.data.forEach((id) => {
                            if (data) {
                                let item = _.find(data, { contentId: id });
                                item.isFavorite = true;
                                item.isCollected = true;
                            }
                        })
                    }
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            })
        }
    }

    /** 设置收藏状态 */
    @action
    public setItemFavorite(item: any, favorite: boolean) {
        if (this.fullSearchData && this.fullSearchData.data) {
            let target = _.find(this.fullSearchData.data, { contentId: item.contentId });
            if (target) {
                target.isFavorite = favorite;
                target.isCollected = favorite;
            }
        }
    }

    @action
    public setSearchKeyword(str: string) {
        this.searchKeyword = str;
    }

    @action
    public setSelectedQFacet(facet: iIndexTypes.thiDetail.ISelectedQFacet | undefined) {
        this.selectedQFacet = facet;
    }

    @action
    public setSearchEntityType(searchEntityType: string) {
        this.searchEntityType = searchEntityType;
    }

    @action
    public setPageSize(pageSize: number) {
        this.pageSize = pageSize;
    }
    @action
    public setAppSelectedData(data: any) {
        this.appSelectedData = data;
    }

    @action
    public setSelectedConditions(conditions?: iSearchTypes.ISearchCondition[]){
        this.selectedConditions = conditions;
    }

    @action
    public setShowAggrs(show: boolean){
        this.showAggrs = show;
    }

    @action
    public getYears(name: string){
        return new Promise((resolve, reject)=>{
            this.fullSearchData = undefined;
            this.years = [];
            this.selectedYear = undefined;
            this.isLoadingFullSearchData = true;
            http.get(`/bigdata-platform/es/szmg/people-behiver/year-facets?keyword=${name}&behavior=${this.selectedQFacet? this.selectedQFacet.value : ''}`).then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        let result = _.map(_.filter(res.data.data, (item)=>{
                            return item.count > 0;
                        }), (item)=>{
                            return item.key.split('-')[0];
                        });
                        result.sort((a,b)=>{
                            return parseInt(b, 10) - parseInt(a, 10);
                        });
                        this.years = result;
                        if (this.years.length > 0){
                            this.selectedYear = this.years[0];
                        }
                    }
                    resolve(undefined);
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            });
        })
    }

    @action
    public setSelectedYear(year?: string){
        this.selectedYear = year;
    }

    /** 初始化层面数据 */
    private initFacets(facets: iSearchTypes.IFacets[]) {
        if (facets){
            facets.forEach(item => {
                if (item.facetFieldName === 'createDate_') {
                    item.startDate = '';
                    item.endDate = '';
                }
                item.showMore = false;
                if (item.facetValue) {
                    item.facetValue.forEach((o: iSearchTypes.IFacetsFacetValue) => {
                        o.isShow = true;
                        o.isSelected = false;
                    });
                }
                item.checkboxIsOpen = false;
            });
        }
    }
}