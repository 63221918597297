import ExtendStore from 'src/stores/base/extendStore';
import ReviewInfoStore from './extends/reviewInfoStore';

export default class EntityExtendStore extends ExtendStore {
    public reviewInfoStore: ReviewInfoStore;

    public initReviewInfoStore() {
        this.reviewInfoStore = new ReviewInfoStore(this.stores);
    }
}
