import * as React from 'react';
import {observer} from "mobx-react";
import {stores} from "../stores";

export enum LoadingComponentModes{
    ROUTE = 1,  // 路由切换
    REQUEST = 2 // 请求
}

interface ILoadingComponentProps extends LoadableExport.LoadingComponentProps{
    mode?: string
}

@observer
class LoadingComponent extends React.Component<ILoadingComponentProps, any> {
    constructor(props: ILoadingComponentProps)
    {
        super(props);
    }

    public render(){
        const mode = this.props.mode || LoadingComponentModes.ROUTE;
        const load1 = <div className="loading-container animation-2">
            <div className="shape shape1"></div>
            <div className="shape shape2"></div>
            <div className="shape shape3"></div>
            <div className="shape shape4"></div>
        </div>
        if (mode !== LoadingComponentModes.ROUTE && stores.configStore.showRequestLoading){
            return load1;
        }
        else {
            return null;
        }
    }
}

export default LoadingComponent