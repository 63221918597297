import ExtendStore from '../../../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import ContentMgStore from '../../contentMgStore'
import { message } from 'antd'
import _, { reject } from 'lodash'
import { Stores } from 'src/stores'
import contentMgApi from 'src/apis/contentMgApi'
import contentMgTypes from 'src/types/contentMgTypes'
export default class CatalogStatisticStore extends ExtendStore {
    //获取完成编目数量
    @observable
    public finishTaskNum: any = {
        delay: { count: 0, totalDuration: 0 },
        today: { count: 0, totalDuration: 0 },
        total: { count: 0, totalDuration: 0 }
    }
    @observable
    public auditTaskNum: any = {
        delay: { count: 0, totalDuration: 0 },
        today: { count: 0, totalDuration: 0 },
        total: { count: 0, totalDuration: 0 }
    }
    @observable
    public finishTaskNumTrend: contentMgTypes.catalogStatistic.ILeftData[] = []
    @observable
    public finishTaskNumTrendType: string = 'Week'
    @observable
    public finishIsDelay: boolean = false;
    @observable
    public auditTaskNumTrend: contentMgTypes.catalogStatistic.ILeftData[] = []
    @observable
    public auditTaskNumTrendType: string = 'Week'
    @observable
    public auditIsDelay: boolean = false;
    @observable
    public passRateTaskNumTrend: contentMgTypes.catalogStatistic.ILeftDataPassRate = { audits: [], trend: [] }
    @observable
    public passRateTaskNumTrendType: string = 'Week'
    @observable
    public userFinishTaskNum: contentMgTypes.catalogStatistic.IRightData[] = []
    @observable
    public userFinishTaskNumDelay: contentMgTypes.catalogStatistic.IRightDataDelay[] = []
    @observable
    public userAuditTaskNum:contentMgTypes.catalogStatistic.IRightData[] = []
    @observable
    public userAuditTaskNumDelay: contentMgTypes.catalogStatistic.IRightDataDelay[] = []
    @observable
    public userPassRate: any = []

    public constructor(stores: Stores, contentMgStore: ContentMgStore) {
        super(stores);
    }

    @action.bound
    public setFinishTaskNumTrendType(type: string) {
        this.finishTaskNumTrendType = type
    }

    @action.bound
    public setAuditTaskNumTrendType(type: string) {
        this.auditTaskNumTrendType = type
    }

    @action.bound
    public setPassRateTaskNumTrendType(type: string) {
        this.passRateTaskNumTrendType = type
    }

    @action.bound
    public setFinishIsDelay(isDelay: boolean) {
        this.finishIsDelay = isDelay
    }
    @action.bound
    public setAuditIsDelay(isDelay: boolean) {
        this.auditIsDelay = isDelay
    }

    /* 获取完成编目数量yl */
    @action.bound
    public getFinishTaskNum() {
        contentMgApi.catalogStatistic.getFinishNum().then((res) => {
            runInAction(() => {
                this.finishTaskNum = res.data.data
            })
        }).catch((res) => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    /* 获取审核编目数量yl */
    @action.bound
    public getAuditTaskNum() {
        contentMgApi.catalogStatistic.getAuditNum().then((res) => {
            runInAction(() => {
                this.auditTaskNum = res.data.data
            })
        }).catch((res) => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    /* 获取完成编目数量trend yl */
    @action.bound
    public getFinishTaskNumTrend() {
        contentMgApi.catalogStatistic.getFinishNumTrend(this.finishTaskNumTrendType).then((res) => {
            runInAction(() => {
                this.finishTaskNumTrend = res.data.data
            })
        }).catch((res) => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    /* 获取审核编目数量trend yl */
    @action.bound
    public getAuditTaskNumTrend() {
        contentMgApi.catalogStatistic.getAuditNumTrend(this.auditTaskNumTrendType).then((res) => {
            runInAction(() => {
                this.auditTaskNumTrend = res.data.data
            })
        }).catch((res) => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    /* 获取合格率 yl */
    @action.bound
    public getPassRateNum() {
        contentMgApi.catalogStatistic.getPassRate(this.passRateTaskNumTrendType).then((res) => {
            runInAction(() => {
                this.passRateTaskNumTrend = res.data.data
            })
        }).catch((res) => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    /* 获取用户编目排名 yl */
    @action.bound
    public getUserFinishTaskNum(isDelay:boolean) {
        contentMgApi.catalogStatistic.getUserFinishNum(isDelay).then((res) => {
            runInAction(() => {
                if(!isDelay){
                    this.userFinishTaskNum = res.data.data
                }else{
                    this.userFinishTaskNumDelay = res.data.data
                }
            })
        }).catch((res) => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    /* 获取用户审核排名 yl */
    @action.bound
    public getUserAuditTaskNum(isDelay:boolean) {
        contentMgApi.catalogStatistic.getUserAuditNum(isDelay).then((res) => {
            runInAction(() => {
                if(!isDelay){
                    this.userAuditTaskNum = res.data.data
                }else{
                    this.userAuditTaskNumDelay = res.data.data
                }
            })
        }).catch((res) => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    /* 获取用户合格率排名 yl */
    @action.bound
    public getUserPassRateTaskNum() {
        contentMgApi.catalogStatistic.getUserPassRate().then((res) => {
            runInAction(() => {
                this.userPassRate = res.data.data
            })
        }).catch((res) => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

}