import BaseStore from "../base/baseStore";
import emptyEntityTypes from 'src/types/emptyEntityTypes';
import { action, observable, runInAction } from 'mobx';
import emptyEntityApi from 'src/apis/emptyEntityApi';
import { message } from 'antd';
import _ from 'lodash';
import {Stores} from "../index";

const bindUploadLogger = 'bindUploadLogger'

export default class BindUploadStore extends BaseStore<Stores> {
    @observable
    public tasks: emptyEntityTypes.IEmptyEntityUploadTask[] = []
    @observable
    public entityCodeSource: string[] = []
    public entityCodeInfo: emptyEntityTypes.IEmptyEnityInfo[] = []
    public loggerInfo: any[]
    @action
    public onEntityCodeChange = (code: string, item: emptyEntityTypes.IEmptyEntityUploadTask) => {
        item.tapeno = code
        item.canUpload = false
        if (item.tapeno) {
            emptyEntityApi.getEmptyEntity(code).then(res => {
                this.entityCodeInfo = res.data.data || []
                runInAction(() => {
                    this.entityCodeSource = this.entityCodeInfo.map(s => s.tapeno).filter(s => s)
                    if (this.entityCodeSource.length && item.tapeno === this.entityCodeSource[0]) {
                        this.selectEntityCode(code, item)
                    }
                })
            }, () => {
                this.entityCodeInfo = []
                runInAction(() => {
                    this.entityCodeSource = []
                })
            })
        } else {
            this.entityCodeInfo = []
            this.entityCodeSource = []
        }
    }

    @action
    public selectEntityCode = (code: string, item: emptyEntityTypes.IEmptyEntityUploadTask) => {
        let entityCodeInfo = this.entityCodeInfo.find(s => s.tapeno === code)
        item.tapeno = code
        item.canUpload = true
        if (entityCodeInfo) {
            item.contentName = entityCodeInfo.name
            item.contentId = entityCodeInfo.contentId
        }
    }

    @action
    public onChangeName = (name: string, item: emptyEntityTypes.IEmptyEntityUploadTask) => {
        item.contentName = name
    }

    @action
    public removeTask = (task: emptyEntityTypes.IEmptyEntityUploadTask) => {
        task.status = emptyEntityTypes.UploadTaskStatus.deleted
        _.remove(this.tasks, s => s.status === emptyEntityTypes.UploadTaskStatus.deleted)
    }

    @action
    public addAttachment = (task: emptyEntityTypes.IEmptyEntityUploadTask, file: any) => {
        task.files.push({
            id: 0,
            taskId: '',
            fileName: file.name,
            fileSize: file.size,
            chunkIndex: 0,
            chunkSize: 20 * 1024 * 1024,
            chunkTotal: 0,
            relativePath: '',
            absolutePath: '',
            file,
            status: emptyEntityTypes.UploadTaskStatus.init,
            isAttachment: true
        })
    }

    @action
    public removeAttachment = (task: emptyEntityTypes.IEmptyEntityUploadTask, file: emptyEntityTypes.IEmptyEntityFileModel) => {
        _.remove(task.files, file)
    }

    @action
    public initTask = (file: any) => {
        const task: emptyEntityTypes.IEmptyEntityUploadTask = {
            files: [{
                id: 0,
                taskId: '',
                fileName: file.name,
                fileSize: file.size,
                chunkIndex: 0,
                chunkSize: 20 * 1024 * 1024,
                chunkTotal: 0,
                relativePath: '',
                absolutePath: '',
                file,
                status: emptyEntityTypes.UploadTaskStatus.init,
                isAttachment: false
            }],
            tapeno: '',
            id: '',
            contentId: '',
            contentName: file.entityName,

            percent: 0,
            status: emptyEntityTypes.UploadTaskStatus.init,
            canUpload: false
        }
        this.tasks.push(task)
    }

    public startUpload = () => {
        localStorage.removeItem(bindUploadLogger)
        this.loggerInfo = []
        let preparationTask = this.tasks.filter(s => s.canUpload && s.contentName && (s.status === emptyEntityTypes.UploadTaskStatus.init || s.status === emptyEntityTypes.UploadTaskStatus.wait))
        if (preparationTask.length === 0) {
            message.info('請填寫节目编号和名稱'.l('emptyEntity.plzInputUploadInfo'))
            return
        }
        preparationTask.forEach((s, index) => {
            emptyEntityApi.uploadInit(s).then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        s.status = emptyEntityTypes.UploadTaskStatus.inited
                        s.id = res.data.data.id
                        res.data.data.files.forEach((f, i) => {
                            f.file = s.files[i].file
                            s.files[i] = f
                            s.files[i].status = emptyEntityTypes.UploadTaskStatus.inited
                        })
                    }
                })
                this.prepareUpload()
            }, res => {
                if (res.data.error) {
                    message.error(res.data.error.title);
                }
            })
        })
    }

    //准备上传
    @action
    public prepareUpload = () => {
        const progress = this.getFilesByStatus([emptyEntityTypes.UploadTaskStatus.uploading]);
        let len = this.stores.configStore.config.webUploadThreads - progress.length;
        if (len <= 0) {
            return;
        }
        const prepared = this.getFilesByStatus([emptyEntityTypes.UploadTaskStatus.inited, emptyEntityTypes.UploadTaskStatus.wait]);
        if (len > prepared.length) {
            len = prepared.length;
        }
        if (len === 0) //判断是否存在需要上传的任务
        {
            return;
        }
        for (let i = 0; i < len; i++) {
            prepared[i].task.status = prepared[i].file.status = emptyEntityTypes.UploadTaskStatus.uploading;
            this.upload(prepared[i].task, prepared[i].file);
        }
    }

    @action
    public upload = (task: emptyEntityTypes.IEmptyEntityUploadTask, file: emptyEntityTypes.IEmptyEntityFileModel) => {
        const form = new FormData()
        const start = file.chunkIndex * file.chunkSize
        const end = Math.min(file.file.size, start + file.chunkSize)
        const data = file.file.slice(start, end);
        if (data === null || data.size === 0) {
            task.status = file.status = emptyEntityTypes.UploadTaskStatus.wait;
            file.file = null;
            return;
        }

        form.append('fileData', data);
        form.append('fileId', file.id + '');
        form.append('contentId', task.contentId);
        form.append('chunkIndex', file.chunkIndex + '');

        emptyEntityApi.upload(form).then(res => {
            runInAction(() => {
                if (res.data.data) {
                    file.chunkIndex++
                    file.percent = Math.floor((file.chunkIndex / file.chunkTotal) * 100)
                    if (file.chunkIndex === file.chunkTotal) {
                        file.status = emptyEntityTypes.UploadTaskStatus.success
                        if (res.data.data.isFinished) {
                            task.status = emptyEntityTypes.UploadTaskStatus.success
                            this.complete(task)
                        } else {
                            this.prepareUpload()
                        }
                    } else {
                        task.status = file.status = emptyEntityTypes.UploadTaskStatus.uploading
                        this.upload(task, file)
                    }
                } else {
                    task.status = file.status = emptyEntityTypes.UploadTaskStatus.wait
                    this.retry(file, res.data)
                }
            })
        }).catch(res => {
            runInAction(() => {
                task.status = file.status = emptyEntityTypes.UploadTaskStatus.wait
            })
            if (res.data.error) {
                message.error(res.data.error.title);
            }
            this.retry(file, res.data)
        });
    }

    private complete = (task: emptyEntityTypes.IEmptyEntityUploadTask) => {
        emptyEntityApi.uploadComplete(task.contentId).then(() => {
            this.prepareUpload()
            message.success(`${task.contentName} 文件上傳成功。`.l('emptyEntity.uploadSuccess', { name: task.contentName }))
        }, () => {
            runInAction(() => {
                task.status = emptyEntityTypes.UploadTaskStatus.uploaded
            })
            message.error(`${task.contentName} 文件上傳失敗。`.l('emptyEntity.uploadFail', { name: task.contentName }));
            // 记录日志
            this.loggerInfo.push({
                api: `/ww_custom/empty-material/file/upload/complete?taskId=${task.contentId}`,
                task,
                request: task.contentId
            })
            localStorage.setItem(bindUploadLogger, JSON.stringify(this.loggerInfo))
        })
    }

    private getFilesByStatus = (status: emptyEntityTypes.UploadTaskStatus[]) => {
        const result: Array<{ task: emptyEntityTypes.IEmptyEntityUploadTask, file: emptyEntityTypes.IEmptyEntityFileModel }> = [];
        for (let x = 0; x < this.tasks.length; x++) {
            for (let y = 0; y < this.tasks[x].files.length; y++) {
                for (let z = 0; z < status.length; z++) {
                    if (this.tasks[x].files[y].status === status[z]) {
                        result.push({ task: this.tasks[x], file: this.tasks[x].files[y] });
                        break;
                    }
                }
            }
        }
        return result;
    }

    private retry = (file: emptyEntityTypes.IEmptyEntityFileModel, result: any) => {
        let loggerItem = this.loggerInfo.find(s => s.fileId === file.id)
        if (loggerItem) {
            loggerItem.retryTimes++
            loggerItem.result = result
        } else {
            loggerItem = {
                api: '/ww_custom/empty-material/file/upload',
                result,
                fileId: file.id,
                retryTimes: 0
            }
            this.loggerInfo.push(loggerItem)
        }
        localStorage.setItem(bindUploadLogger, JSON.stringify(this.loggerInfo))
        if (loggerItem.retryTimes < 6) {
            this.prepareUpload()
        }
    }
}