import { http } from 'mam-core-react'
import { INavigationRes } from 'src/types/navTypes'
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { IResponse } from 'mam-core-react/dist/modules/http'

namespace navApi {
    export const getNavigation = (parentid?: number, cfg?: AxiosRequestConfig):AxiosPromise<IResponse<INavigationRes[]>> => {
        return http.get<IResponse<INavigationRes[]>>(`/navigation` + (parentid ? `?parentid=${parentid}` : ''), cfg);
    }

    export const getNavigationByParentCode = (parentcode?: string):AxiosPromise<IResponse<INavigationRes[]>> => {
        return http.get<IResponse<INavigationRes[]>>(`/navigation` + (parentcode ? `?parentcode=${parentcode}` : ''));
    }
}

export default navApi