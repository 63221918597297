import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import {Stores} from "../index";

class AdvancedSearchStore extends BaseStore<Stores>{
    @observable
    public showAdvancedSearch:boolean = false

    @observable
    public advancedSearchEnable:boolean = false

    @action
    public setShowAdvancedSearch(showAdvancedSearch:boolean){
        this.showAdvancedSearch = showAdvancedSearch
    }

    @action
    public setAdvancedSearchEnable(advancedSearchEnable:boolean){
        this.advancedSearchEnable = advancedSearchEnable
    }

}

export default AdvancedSearchStore