import {AxiosPromise} from "axios";
import {http} from "../utils/http";
import catalogTypes from "../types/catalogTypes";
import entityTypes from "../types/entityTypes";
import {IResponse} from "mam-core-react/dist/modules/http";

namespace catalogApi {
    export const combinePart = (request: catalogTypes.ICombinePartReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/api/entity/merge-entity', request);
    }

    export const updateCatalogData = (request: entityTypes.IUpdateEntityReq, isCatalog: string): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/update-catalogue-data?isCatalog=' + isCatalog, request);
    }

    export const submitCatalog = (taskId: number[]): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/Newcatalog/task/submit', {taskId});
    }

    export const passCatalog = (taskIds: number[]): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/Newcatalog/task/auditcomplete', {taskIds});
    }

    export const auditrework = (taskIds: number[]): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/Newcatalog/task/auditrework', {taskIds});
    }

    /** 添加标签修改记录，提供给机器学习 */
    export const userOperate = (param: catalogTypes.IUserOperateReq) => {
        return http.post<IResponse<any>>('/user/operate', param);
    }

    export namespace faceReco{
        export const executeFaceReco = (request: catalogTypes.faceReco.IFaceRecoReq): AxiosPromise<IResponse<catalogTypes.faceReco.IFaceRecoRes>> => {
            return http.post<IResponse<catalogTypes.faceReco.IFaceRecoRes>>('/knowledgebase/personality/facerecognition', request);
        }
        export const addPeople = (request: catalogTypes.faceReco.IFaceRecoAddEditReq[]): AxiosPromise<IResponse<catalogTypes.faceReco.IFaceRecoAddEditReq[]>> => {
            return http.post<IResponse<catalogTypes.faceReco.IFaceRecoAddEditReq[]>>('/knowledgebase/mediaassets/batchaddpeople', request);
        }
        export const updatePeople = (request: catalogTypes.faceReco.IFaceRecoAddEditReq[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/knowledgebase/mediaassets/batchupdatepeople', request);
        }
        export const queryBindPerson = (request: catalogTypes.faceReco.IQueryBindPersonReq): AxiosPromise<IResponse<catalogTypes.faceReco.IQueryBindPersonRes>> => {
            return http.post<IResponse<catalogTypes.faceReco.IQueryBindPersonRes>>('/knowledgebase/commonality/simplesearch', request);
        }
        export const getPersonClassify = (): AxiosPromise<IResponse<catalogTypes.faceReco.IGetPersonClassifyData[]>> => {
            return http.get<IResponse<catalogTypes.faceReco.IGetPersonClassifyData[]>>('/knowledgebase/commonality/getpersonclassify');
        }
    }

    export namespace catalogTemplate{
        export const getTemplate = (entityType:string,isCommon:boolean,pageIndex:number,keyword?:string):AxiosPromise<IResponse<catalogTypes.catalogTemplateTypes.IGetcatalogTemplateRes>> => {
            return http.get<IResponse<catalogTypes.catalogTemplateTypes.IGetcatalogTemplateRes>>(`/entity/edit-metadata-template?entityType=${entityType}&isCommon=${isCommon}&metadataType=base&module=1&pageIndex=${pageIndex}&pageSize=10&keyword=${keyword}`)
        }

        export const addTemplate = (data:catalogTypes.catalogTemplateTypes.IAddCatalogTemplateReq):AxiosPromise<IResponse<catalogTypes.catalogTemplateTypes.IAddCatalogTemplateReq>> => {
            return http.post<IResponse<catalogTypes.catalogTemplateTypes.IAddCatalogTemplateReq>>('/entity/edit-metadata-template',data)
        }

        export const deleteTemplate = (id:string):AxiosPromise<IResponse<string>> => {
            return http.delete('/entity/edit-metadata-template/' + id)
        }
    }
}
export default catalogApi