import ExtendStore from 'src/stores/base/extendStore'
import _ from 'lodash'
import IIndexStore from '../iIndexStore'
import { Stores } from 'src/stores'
import { http } from "mam-core-react"
import { action, observable, runInAction } from 'mobx'
import { message } from 'antd'
import { treeUtil } from 'mam-common-utils'
import smoisTypes from 'src/types/smoisTypes'
import smoisApi from 'src/apis/smoisApi';

export default class SmoisStore extends ExtendStore {
    
    //查询进度提示
    public queryProgressTip: smoisTypes.IProgressTip = {
        'starting':'开始发起',
        'waiting': '等待分析中',
        'doing': '正在分析中',
    }
    ///原子服务类型
    public atomServiceList: smoisTypes.IAtomService = {
        "asr":"语音转写",
        "proofreading":"文本校对",
        "summary":"文本摘要",
        "keywords":"文本关键词",
        "face":"人脸识别",
        "ocr":"字幕识别",
    }
    //文件类型
    public sourceTypeList: smoisTypes.ISourceType = {
        "text": "文本",
        "audio": "音频",
        "video": "视频",
        "image": "图片",
    }
    //人物字段翻译
    public personFields = {
        "name": "名称".l('kb.name'),
        "nick_name": "别名".l('kb.nickName'),
        "description": "简介".l('kb.description'),
        "type": "人物分类".l('kb.personType'),
        "belong_district": "所属地区".l('kb.belongArea'),
        "title": "头衔".l('kb.personTitle'),
        "level": '重要级别'.l('kb.mainLevel'),
        "tag": '标签'.l('kb.personTag'),
        //"is_sensitive": "人物标识".l('kb.personSensitiveType')
    }
    // 需要展示的人物字段
    public showPersonFields = [
        "nick_name",
        "type",
        "belong_district",
        "title",
        "level",
        "tag"
    ]
    //文本校对级别
    // public checkList = [
    //     {
    //         level: 1,
    //         name: "严重错误",
    //     },
    //     {
    //         level: 2,
    //         name: "错误",
    //     },
    //     {
    //         level: 3,
    //         name: "疑错",
    //     },
    // ]
    @observable 
    public atomType: string = "asr" //当前原子服务类型
    @observable 
    public sourceType: string = "audio"  //当前上传文件类型

    @observable 
    public isUploading: boolean = false
    @observable 
    public isAddTasking: boolean = false
    @observable 
    public isSearching: boolean = false

    @observable 
    public file: any //正在上传时的本地文件或录音文件
    @observable 
    public filePath: string = '' //上传完成后，后端返回的文件地址
    
    @observable 
    public taskId: string = '' //当前查询的任务id
    @observable 
    public progress: string = '' //当前查询的进度
    //分析结果数据
    @observable 
    public result: smoisTypes.IResult = {
        asr: undefined,
        face: undefined,
        ocr: undefined,
    }
    @observable 
    public isShowProgressModal: boolean = false
    @observable
    public typeOptions: smoisTypes.ITypeOptionsItem[] = [] //类型
    @observable
    public areaTree: smoisTypes.IAreaTreeItem[] = [] //地区树
    //假数据
    public resultData = {
        "asr":[
            {
              "sentence": "语音听写可以将语音转为文字，语音听写可以将语音转为文字,",
              "begin": 0,
              "end": 45500000
            },
            {
              "sentence": "是不是？",
              "begin": 45600000,
              "end": 50900000
            },
        ],
        "proofreading":[
            {
              "CheckResultStr": "大会主席团常务主席、省人大常委会主任王国生主持会议并讲话。大会主席团常务主席喻红秋、孔昌生、李文慧、徐济超、王保存、张维宁、乔新江、丁巍和新当选的省人大常委会副主任赵素萍、马懿【懿,疑错】出席大会并在主席台前排就座。",
              "CheckResult": [
                {
                    "ExtensionData": null,
                    "CorWord": {
                      "string": [
                        "疑错"
                      ]
                    },
                    "ErrWord": "疑错",
                    "Level": 2,
                    "Pos": 87
                },
                {
                  "ExtensionData": null,
                  "CorWord": {
                    "string": [
                      "懿"
                    ]
                  },
                  "ErrWord": "懿",
                  "Level": 2,
                  "Pos": 87
                },
              ]
            }
        ],
        "summary":[
            {
              "titie": "海军陆战队",
              "summary": "军队"
            }
        ],
        "keywords":[
            {
              "keyword": "海军陆战队",
              "probability": 0.99,
              "begin": 0,
              "end": 5
            }
        ],
        "face":[
            {
              "name": "张三",
              "personId": "63488814-2b7d-11ea-be56-ac2b6e696ea4",
              "confidence": 0.842127108828126,
              "tag": "明星,娱乐人物",
              "location": {
                "left": 10,
                "top": 10,
                "width": 50,
                "height": 50
              }
            },
            {
              "name": "李四",
              "personId": "63488814-2b7d-11ea-be56-ac2b6e696ea4",
              "confidence": 0.842127108828126,
              "tag": "明星,娱乐人物",
              "location": {
                "left": 20,
                "top": 30,
                "width": 200,
                "height": 200
              }
            }
        ],
        "ocr":[
            {
              "words": "修公四围,日然两漫国身心",
              "location": {
                "left": 840,
                "top": 853,
                "width": 312,
                "height": 32
              },
              "confidence": 1
            },
            {
              "words": "黑忙与感然游刃有亲",
              "location": {
                "left": 840,
                "top": 890,
                "width": 199,
                "height": 25
              },
              "confidence": 1
            },
            {
              "words": "工作与体闻进旧自如",
              "location": {
                "left": 840,
                "top": 927,
                "width": 201,
                "height": 26
              },
              "confidence": 1
            }
        ],
    }
    @observable
    public personRes: smoisTypes.IPersonListItem | undefined
    private iIndexStore: IIndexStore
    
    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore
    }
    
    @action
    public clearResult(){
        this.result = {
            asr: undefined,
            face: undefined,
            ocr: undefined,
        }
        this.filePath = ''
        this.file = undefined
    }
    @action
    public initSearching(){
        this.taskId = ''
        this.progress = ''
        this.isUploading = false
        this.isAddTasking = false
        this.isSearching = false
        this.isShowProgressModal = false
    }
    @action
    public setAtomType(atomType: string){ //原子服务类型
        if(atomType !== this.atomType){
            this.initSearching()
            this.clearResult()
        }
        this.atomType = atomType
        // test: 分析结果数据
        // this.testResult()
    }
    @action
    public testResult(){
        // test假数据: 分析结果数据
        this.result[this.atomType] = this.resultData[this.atomType]
        // this.filePath = "http://172.16.152.73/bucket-p/u-a9r6ms20h9rumm51/2021/08/09/f8e6ff5a770249478ffafe465e003207_videogroup_a84cfa5c188d440fb229fffa8a59a195_000.mp4"
    }
    @action
    public setSourceType(sourceType: string){ //上传文件类型
        this.sourceType = sourceType
    }
    //1、文件上传
    @action 
    public uploadFile(file: any){
        this.file = file
        this.progress = 'starting'
        this.isShowProgressModal = true
        this.isUploading = true
        let form = new FormData()
        /*
        //文件流
        let blob = null //音视频文件流
        let reader = new FileReader()
        let rs = reader.readAsBinaryString(file)
        reader.onload = (e) => {
            if (typeof e.target.result === 'object') {
                blob = new Blob([e.target.result])
            } else {
                blob = e.target.result
            }
            if(blob){
                form.append('filename', file.name)
                form.append('file', blob)
                http.post(`/smois/v1/intelligent/file?atom_type=${this.atomType}`,form).then(res=>{
                    runInAction(()=>{
                        if(res.data.data){
                            this.isUploading = false
                            //获取音视频的文件地址，然后添加任务
                            if(res.data.data.path){
                                this.addTask(res.data.data.path)
                            }
                        }
                    })
                },res=>{
                    runInAction(()=>{
                        this.isUploading = false
                        if (res.data.message){
                            message.error(res.data.message);
                        }
                    })
                })
            }
            
        }*/

        //文件对象
        form.append('file', file)
        http.post(`/smois/v1/intelligent/file?atom_type=${this.atomType}`,form,{headers:{hideLoading: true}}).then(res=>{
            runInAction(()=>{
                if(res.data.data){
                    this.isUploading = false
                    //获取音视频的文件地址，然后添加任务
                    if(res.data.data.path){
                        this.addTask(res.data.data.path,true)
                    }else{
                        this.isShowProgressModal = false
                    }
                }
            })
        },res=>{
            runInAction(()=>{
                this.isShowProgressModal = false
                this.isUploading = false
                if (res.data.message){
                    message.error(res.data.message);
                }
            })
        })
        
    }
    //2、添加分析任务
    @action
    public addTask(data: string, isUpload?: boolean){
        this.progress = 'starting'
        if(!isUpload){
            this.isShowProgressModal = true
        }
        this.taskId = ''
        let req: smoisTypes.IAddTaskReq = {
            type: this.sourceType,
        }
        if(this.sourceType === "text"){
            req.txt = data //文本内容
        }
        // if(this.sourceType === "image"){
        //     req.base64 = data //图片base64
        //     this.filePath = data
        // }
        if(this.sourceType === "image"){
            req.path = data //图片文件地址
            this.filePath = data
        }
        if(this.sourceType === "audio" || this.sourceType === "video"){
            req.path = data  //文件地址
            this.filePath = data
        }
        this.isAddTasking = true
        http.post(`/smois/v1/intelligent/task?atom_type=${this.atomType}`,req,{headers:{hideLoading: true}}).then(res=>{
            runInAction(()=>{
                if(res.data.data){
                    this.isAddTasking = false
                    //获取任务id后再查询分析结果
                    if(res.data.data.task_id){
                        this.taskId = res.data.data.task_id
                        this.progress = 'waiting'
                        this.query()
                    }else{
                        this.isShowProgressModal = false
                    }
                }
            })
        },res=>{
            runInAction(()=>{
                this.isShowProgressModal = false
                this.isAddTasking = false
                if (res.data.message){
                    message.error(res.data.message);
                }
            })
        })
    }
    //3、查询任务
    @action
    public query(){
        if(this.taskId){
            this.isSearching = true
            http.get(`/smois/v1/intelligent/task?atom_type=${this.atomType}&task_id=${this.taskId}`,{headers:{hideLoading: true}}).then(res=>{
                runInAction(()=>{
                    if(res.data.success && res.data.code === '0' && res.data.data){
                        this.isShowProgressModal = false
                        this.isSearching = false
                        this.result[this.atomType] = res.data.data
                        if(this.atomType === 'face'){
                            //依据personId查知识库identify_code对应值的人物信息
                            if(res.data.data[0] && res.data.data[0].personId){
                                this.getAreaTree()
                                this.getTypeOptions()
                                this.queryPerson(res.data.data[0].personId)
                            }else{
                                this.personRes = undefined
                            }
                        }
                    }else{
                        if(res.data.code === '1' || res.data.code === '2'){
                            this.progress = res.data.message || (res.data.code === '1' ? 'waiting' : 'doing')
                            setTimeout(() => {
                                this.query()
                            }, 2000);
                        }else{
                            this.isShowProgressModal = false
                            this.isSearching = false
                            message.error(res.data.message)
                        }
                    }
                })
            },res=>{
                runInAction(()=>{
                    this.isShowProgressModal = false
                    this.isSearching = false
                    if (res.data.message){
                        message.error(res.data.message);
                    }
                })
            })
        }
        
    }

    //查询知识库中的人物
    @action
    public queryPerson(personId: string){
        let queryReqParams: smoisTypes.IQueryPersonReq = {
            type: "people",
            page: 1,
            size: 10,
            sort_bys: [],
            conditions: [
                {
                    field_name: "identify_code",
                    field_value: personId,
                    search_relation: "and"
                },
            ]
        }
        return new Promise((resolve, reject) => {
            smoisApi.personGetQueryList(queryReqParams).then((res) => {
                runInAction(() => {
                    if (res && res.data && res.data.success) {
                        if(res.data.data && res.data.data.data && res.data.data.data.length > 0){
                            this.personRes = res.data.data.data[0]
                        }
                        resolve(true)
                    }else{
                        reject()
                    }
                })
            }, res=>{
                runInAction(()=>{
                    if(res.data.error_msg){
                        message.error(res.data.error_msg)
                    }
                    reject()
                })
            })
        })
    }
    /*获取人物类型项*/
    @action
    public getTypeOptions(){
        return new Promise((resolve, reject) => {
            smoisApi.getTypeOptions().then((res) => {
                runInAction(() => {
                    if (res && res.data && res.data.success && res.data.data) {
                        this.typeOptions = res.data.data
                        resolve(true)
                    }else{
                        reject()
                    }
                })
            }, res=>{
                runInAction(()=>{
                    if(res.data.error_msg){
                        message.error(res.data.error_msg)
                    }
                    reject()
                })
            })
        })
    }

    /*获取区域树数据*/
    @action
    public getAreaTree(){
        return new Promise((resolve, reject) => {
            smoisApi.getAreaTree().then((res) => {
                runInAction(() => {
                    if (res && res.data && res.data.data) {
                        this.areaTree = [JSON.parse(res.data.data)]
                        resolve(true)
                    }else{
                        reject()
                    }
                })
            }, res=>{
                runInAction(()=>{
                    if(res.data.error_msg){
                        message.error(res.data.error_msg)
                    }
                    reject()
                })
            })
        })
    }

    public getTypeName(code: string){
        return _.get(_.find(this.typeOptions, {type_code:code}), 'type_name', '') as string
    }

    public getTreeItem(code: string): smoisTypes.IAreaTreeItem | undefined{
        let ret: smoisTypes.IAreaTreeItem | undefined
        if(this.areaTree && this.areaTree.length > 0){
            this.areaTree.forEach((treeItem: smoisTypes.IAreaTreeItem)=>{
                if(treeItem.code === code){
                    ret = treeItem
                }else if (!ret){
                    ret = treeUtil.getTreeDataRecursively<smoisTypes.IAreaTreeItem>(treeItem, 'code', code)
                }
            })
        }
        return ret
    }

    public getTreeItemName(code: string): string{
        if(!code){
            return ""
        }
        let item: smoisTypes.IAreaTreeItem | undefined = this.getTreeItem(code)
        return item ? item.name : ""
    }
}
