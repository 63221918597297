import ExtendStore from '../base/extendStore';
import _ from 'lodash'
import { Stores } from "../index"
import ContributeStore from './subModules/contributeStore'
import CollectStore from './subModules/collectStore'
import BacklogStore from './subModules/backlogStore'
import MessageStore from './subModules/messageStore'
import HistoryStore from './subModules/historyStore'
import GroupStore from './subModules/groupStore'
import InformationStore from './subModules/informationStore'

export default class PersonalCenterStore extends ExtendStore {
    public contributeStore: ContributeStore
    public collectStore: CollectStore
    public backlogStore: BacklogStore
    public messageStore: MessageStore
    public historyStore: HistoryStore
    public groupStore: GroupStore
    public informationStore: InformationStore
    constructor(stores: Stores) {
        super(stores)
        this.contributeStore = new ContributeStore(stores, this)
        this.collectStore = new CollectStore(stores, this)
        this.backlogStore = new BacklogStore(stores, this)
        this.messageStore = new MessageStore(stores, this)
        this.historyStore = new HistoryStore(stores, this)
        this.groupStore = new GroupStore(stores, this)
        this.informationStore = new InformationStore(stores, this)
    }
}