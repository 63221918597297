import BaseStore from "../../base/baseStore";
import { action, observable, runInAction } from "mobx";
import { Stores } from "../../index";
import mmsTypes from "../../../types/mmsTypes";
import mmsApi from "../../../apis/mmsApi";
import EntityTableStore from "./entityTableStore";
import * as React from "react";
import _ from 'lodash';
import playerUtil from "mam-common-utils/dist/modules/playerUtil";
import MetadataForm from "mam-metadata-react/dist/metadataForm";
import { http } from 'mam-core-react';
import ITreeItem from 'mam-metadata-react/dist/types/ITreeItem';
import { commonUtil } from 'mam-common-utils';
import { message } from 'antd';

export interface IOutstoreStore {
    outstoreStore: OutstoreStore;

    initOutstoreStore: (stores: Stores) => void;
}

export default class OutstoreStore extends BaseStore<Stores> {
    public entityTableStore: EntityTableStore;
    @observable
    public visible: boolean;
    @observable
    public items: any[];
    @observable
    public externalsystemList: mmsTypes.exportTypes.IGetExternalsystem[];
    @observable
    public transcodeTemplateList: mmsTypes.exportTypes.IGetTranscodeTemplate[];
    /** 当前选中的外部系统 */
    @observable
    public selectedSystem: mmsTypes.exportTypes.IGetExternalsystem | undefined;
    @observable
    public entitys: mmsTypes.exportTypes.IGetEntityInfo[];
    @observable
    public interactionFieldsForm: React.RefObject<MetadataForm>;
    @observable
    public errorModalEnable: boolean
    @observable
    public errorModalInfo: mmsTypes.IErrorTableInfo[] = []
    /** 显示素材版权详情 */
    @observable
    public copyrightVisible: boolean | undefined;
    @observable
    public currentCopyrightInfo: mmsTypes.exportTypes.ICopyRightInfo;
    @observable
    public applyReason?: string
    @observable
    public selectedRandown?: mmsTypes.exportTypes.IScriptRundown
    
    /** 节目代码绑定 */
    @observable
    public bindProgramCodeModalVisible: boolean = false;
    @observable
    public bindProgramCodeEntity: mmsTypes.exportTypes.IGetEntityInfo;
    @observable
    public columnData: any[] = [];
    @observable
    public programCodeList: any[] = [];
    @observable
    public isCloseBindProgramCode: boolean = false;

    constructor(stores: Stores) {
        super(stores);
        this.entityTableStore = new EntityTableStore(this);
        this.interactionFieldsForm = React.createRef();
    }

    @action
    public openModal(items: any[]) {
        this.items = items;
        this.visible = true;
        Promise.all([this.getExternalsystem(), this.getTranscodeTemplate()]).then(()=>{
            this.getEntityInfo();
        })
    }
    @action
    public setItems(items: any[]) {
        this.items = items;
        Promise.all([this.getExternalsystem(), this.getTranscodeTemplate()]).then(()=>{
            this.getEntityInfo();
        })
    }
    @action
    public hideModal() {
        this.visible = false;
        //关闭弹窗，清空列表选中
        this.stores.searchStore.selectAllStore.switchAllSelected(false);
        this.stores.searchStore.selectAllStore.switchListItemSelected(undefined, false);
    }

    @action
    public getExternalsystem() {
        return new Promise((resolve, reject)=>{
            mmsApi.exportModal.getExternalsystem().then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        this.externalsystemList = res.data.data.filter((item)=>{
                            return item.exportConfigureInfo.enableExport;
                        });
                        if (this.externalsystemList && this.externalsystemList.length > 0) {
                            let sSelectedSystemId = localStorage.getItem('externalSystemID');
                            if (sSelectedSystemId) {
                                this.selectedSystem = this.externalsystemList.find(item => {
                                    return item.externalSystemID === sSelectedSystemId
                                })
                            }
                            if (!this.selectedSystem) {
                                this.selectedSystem = this.externalsystemList[0];
                            }
                            this.externalsystemList.forEach((system) => {
                                if (system.exportConfigureInfo.exportSupportType) {
                                    system.exportConfigureInfo.exportSupportTypeArr = JSON.parse(system.exportConfigureInfo.exportSupportType);
                                }
                                if (system.exportConfigureInfo.extendParameter)//扩展参数
                                {
                                    system.exportConfigureInfo.extendParameterObj = {};
                                    const extendParam = JSON.parse(system.exportConfigureInfo.extendParameter);
                                    extendParam.forEach((param: any) => {
                                        system.exportConfigureInfo.extendParameterObj[param.key] = param.value;
                                    })
                                }
                            })
    
                            this.getCategoryTreeByApi()

                            this.initProgramCode()
                        }
                        resolve();
                    }
                    else {
                        reject();
                    }
                })
            }, (res)=>{
                if (res && res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public getTranscodeTemplate() {
        return new Promise((resolve, reject)=>{
            mmsApi.exportModal.getTranscodeTemplate().then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        this.transcodeTemplateList = res.data.data;
                        if (this.transcodeTemplateList && this.transcodeTemplateList.length > 0) {
                            //存在部分素材出库不支持原格式的情况，因此注释掉默认选中原格式
                            //this.entityTableStore.setAllTranscodeTemplateID(this.transcodeTemplateList[0].transcodeTemplateID);
                        }
                        resolve();
                    }
                    else {
                        reject();
                    }
                })
            }, (res)=>{
                if (res && res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            })
        });
    }

    @action
    public getEntityInfo() {
        const request = {
            "contentIds": new Array<string>()
        }
        // 用于获取权限
        const itemDict = {}
        this.items.forEach(s => {
            s.canWrite = s.canWrite || (s.extensions && s.extensions.canWrite)
            itemDict[s.contentId] = s
            request.contentIds.push(s.contentId)
        })
        mmsApi.exportModal.getEntityInfo(request).then(res => {
            runInAction(() => {
                if (res.data.success) {
                    if (res.data.data) {
                        res.data.data.forEach((item, index) => {
                            const currentType = item.entityType;
                            const currentTemplates = this.loopMatchTemplate(currentType);

                            //获取多音轨信息
                            const videoGroup = _.find(item.groupInfos, { groupName: 'videogroup' });

                            let originalItem = _.find(this.items, {contentId: item.contentId})

                            item.entity = {
                                contentId: item.contentId,
                                title: item.entityName,
                                type: item.entityType,
                                typeName: item.entityType,
                                templates: currentTemplates,
                                transcodetemplate: this.getDefaultTemplateFormat(currentTemplates,item.canExportOriginalFormat),
                                oldTitle: item.entityName,
                                timeCode: item.timeCode,
                                frameRate: originalItem.frameRate,
                                storyid: originalItem.storyid,
                                sceneid: originalItem.sceneid,
                                shotid: originalItem.shotid,
                                partType: originalItem.type,
                                canWrite: itemDict[item.contentId] && itemDict[item.contentId].canWrite,
                                canExport:item.canExport,
                                canExportOriginalFormat:item.canExportOriginalFormat,
                            }
                            //如果是片段导出，赋予入出点和片段标题
                            if ((!originalItem.type_ || originalItem.type_.indexOf('biz_sobey') !== 0) 
                                && originalItem.inpoint !== undefined && originalItem.outpoint !== undefined && originalItem.inpoint !== -1 && originalItem.outpoint !== -1) {
                                item.entity.inpoint = originalItem.inpoint;
                                item.entity.outpoint = originalItem.outpoint;
                                const inpoint = playerUtil.second2TC((originalItem.inpoint!) / Math.pow(10, 7), item.entityType, item.frameRate).replace(/\:/g, '');
                                const outpoint = playerUtil.second2TC((originalItem.outpoint!) / Math.pow(10, 7), item.entityType, item.frameRate).replace(/\:/g, '');
                                let newTitle = item.entity.title + '_' + '片段'.l('entity.section') + '_' + inpoint + '_' + outpoint;
                                item.entity.title = originalItem.title || originalItem.name || newTitle;
                            }
                            item.entity.title = commonUtil.extractTextFromHtml(item.entity.title);
                            item.audioTracks = videoGroup ? videoGroup.fileItems.filter((fitem) => {
                                return fitem.track && fitem.track.startsWith('A');
                            }).map((fitem1) => {
                                return fitem1.track;
                            }) : undefined
                        })
                        this.entitys = res.data.data
                    }

                }
            })
        })
    }

    @action
    public changeExternalSystem(externalSystemID: string) {
        localStorage.setItem('externalSystemID', externalSystemID);
        this.selectedSystem = _.find(this.externalsystemList, { 'externalSystemID': externalSystemID });
        //切换外部系统后，转码模板下拉重新生成
        if(this.entitys && this.entitys.length > 0){
            this.entitys.forEach((item, index)=>{
                const currentType = item.entityType;
                const currentTemplates = this.loopMatchTemplate(currentType);
                item.entity.templates = currentTemplates;
                item.entity.transcodetemplate = this.getDefaultTemplateFormat(currentTemplates,item.canExportOriginalFormat)
            })
        }
        this.entityTableStore.setAllTranscodeTemplateID('1');
        
        this.getCategoryTreeByApi()

        this.initProgramCode()
    }

    /** 判断素材是否支持选择的外部系统 */
    public isEntitySupportSystem(entityInfo: mmsTypes.exportTypes.IGetEntityInfo) {
        let support = false;
        if (this.selectedSystem) {
            this.selectedSystem.exportConfigureInfo.exportSupportTypeArr?.forEach((type) => {
                if (entityInfo.entity.type === type.code) {
                    support = true;
                }
            })
        }
        return support;
    }

    @action
    public selectTracks(entityInfo: mmsTypes.exportTypes.IGetEntityInfo, vals: string[]) {
        entityInfo.selectedTracks = vals;
    }

    @action
    public showErrorModal(show: boolean) {
        this.errorModalEnable = show
    }

    @action
    public openErrorModal(errorList: Array<{ contentId: string, entityName: string, message: string }>) {
        const datas: Array<{ name: string, reason: string, contentId: string }> = [];
        errorList.forEach((s: { contentId: string, entityName: string, message: string }, index: number) => {
            const msg = s.message.split(':').join('包含敏感信息"'.l('modal.outstoreSensitiveJoin')) + '"';
            let data = datas.find(o => o.contentId === s.contentId);
            if (data) {
                data.reason += '，' + msg;
            } else {
                data = {
                    name: s.entityName,
                    reason: msg,
                    contentId: s.contentId
                };
                datas.push(data);
            }
            if (index === errorList.length - 1) {
                data.reason += '。';
            }
        });

        if (datas.length > 1) {
            const errorInfos = [{
                tip: '以下素材检测到敏感信息，出库失败'.l('modal.outstoreSensitiveTitle'),
                tableTitle: '素材名'.l('entity.name'),
                tableReason: '敏感信息'.l('entity.sensitive'),
                datas
            }];
            this.errorModalInfo = errorInfos;
            this.showErrorModal(true)
        }
        return datas
    }


    /** 版权信息 */
    @action
    public showCopyrightModal(show: boolean) {
        this.copyrightVisible = show
    }

    @action
    public openCopyright = (item: mmsTypes.exportTypes.IGetEntityInfo) => {
        mmsApi.CopyrightModal.getFields(item.contentId).then(res => {
            runInAction(() => {
                if (res.data && res.data.data) {
                    this.currentCopyrightInfo = {
                        name: item.entityName,
                        contentId: item.contentId,
                        createDate: item.createDate,
                        createUser: item.createUser,
                        metadata: res.data.data
                    }
                    this.showCopyrightModal(true)
                }
            })
        })
    }
    /** 申请原因 */
    @action
    public setApplyReason = (applyReason: string) => {
        this.applyReason = applyReason;
    }

    @action
    public setSelectedRandown(selectedRandown?: mmsTypes.exportTypes.IScriptRundown){
        this.selectedRandown = selectedRandown;
    }

    /** 节目代码绑定:弹框显示 */
    @action
    public setBindProgramCodeModalVisible(bindProgramCodeModalVisible:boolean) {
        this.bindProgramCodeModalVisible = bindProgramCodeModalVisible;
    }
    /** 节目代码绑定:素材对象 */
    @action
    public setBindProgramCodeEntity(item:mmsTypes.exportTypes.IGetEntityInfo) {
        this.bindProgramCodeEntity = item;
    }
    @action
    public initProgramCode() {
        //若选中的外部系统支持绑定节目代码，则获取拟播栏目数据
        if(this.selectedSystem && this.selectedSystem.exportConfigureInfo && this.selectedSystem.exportConfigureInfo.enableProgramCode){
            this.getColumnsData()
        }
        //获取isCloseBindProgramCode
        if (this.selectedSystem && this.selectedSystem.exportConfigureInfo && this.selectedSystem.exportConfigureInfo.programExtendParameter) {
            let programExtendParameter = JSON.parse(this.selectedSystem.exportConfigureInfo.programExtendParameter);
            let isCloseBindProgramCode = _.find(programExtendParameter, { key: 'isCloseBindProgramCode' });
            if (isCloseBindProgramCode) {
                this.isCloseBindProgramCode = isCloseBindProgramCode.value === 'true' ? true : false;
            }else {
                this.isCloseBindProgramCode = false;
            }
        }
    }
    /** 节目代码绑定:获取拟播栏目 */
    @action
    public getColumnsData() {
        mmsApi.exportModal.getLm().then(res => {
            runInAction(() => {
                if (res.data && res.data.data) {
                    this.columnData = JSON.parse(res.data.data)
                }
            })
        })
    }
    /** 节目代码绑定:清空节目代码列表 */
    @action
    public clearProgramcode() {
        this.programCodeList = []
    }
    /** 节目代码绑定:获取节目代码列表 */
    @action
    public getProgramcode(params:any) {
        mmsApi.exportModal.getProgramcode(params).then(res => {
            runInAction(() => {
                if (res.data && res.data.success && res.data.data) {
                    let newProgramCodeList = JSON.parse(res.data.data)
                    if(newProgramCodeList && newProgramCodeList.length > 0){
                        newProgramCodeList.map((item:any,index:number)=>item.key = index)
                    }
                    this.programCodeList = newProgramCodeList
                }
            })
        }, (res)=>{
            if (res && res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }

    /** 节目代码绑定:绑定 */
    @action
    public bindProgramcode(params:any) {
        return new Promise((resolve, reject)=>{
            mmsApi.exportModal.bindProgramcode(params).then(res => {
                runInAction(() => {
                    if (res.data && res.data.success) {
                        if(params.contentId && this.entitys && this.entitys.length > 0){
                            this.entitys.forEach((item:mmsTypes.exportTypes.IGetEntityInfo)=>{
                                if(item.contentId === params.contentId){
                                    item.programCode = params.programCode
                                }
                            })
                        }
                        message.success('绑定成功！'.l('modal.bindSuccess'))
                        resolve()
                    }
                })
            }, (res)=>{
                if (res && res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            })
        })
    }
    /** 节目代码绑定:解绑 */
    @action
    public unbindProgramcode(params:any) {
        return new Promise((resolve, reject)=>{
            mmsApi.exportModal.unbindProgramcode(params).then(res => {
                runInAction(() => {
                    if (res.data && res.data.success) {
                        if(params.contentId && this.entitys && this.entitys.length > 0){
                            this.entitys.forEach((item:mmsTypes.exportTypes.IGetEntityInfo)=>{
                                if(item.contentId === params.contentId){
                                    item.programCode = ''
                                }
                            })
                        }
                        message.success('解绑成功！'.l('modal.unbindSuccess'))
                        resolve()
                    }
                })
            }, (res)=>{
                if (res && res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            })
        })
    }
    /** 节目代码绑定:关联 */
    @action
    public relatedProgramcode(contentId: string,programCode: string) {
        if(this.entitys && this.entitys.length > 0){
            this.entitys.forEach((item: mmsTypes.exportTypes.IGetEntityInfo)=>{
                if(item.contentId === contentId){
                    item.programCode = programCode
                }
            })
        }
    }

    /** 
     * 获取多码率信息，拼接成字符串，分号分割
     * 
     * @param type id:获取id；name:获取name
     */
    public getMultiTransTemplateInfo = (type: string) => {
        let str = '';
        if (this.selectedSystem && this.selectedSystem.exportConfigureInfo.exportTranscodeTemplate) {
            const exportTranscodeTemplate = JSON.parse(this.selectedSystem.exportConfigureInfo.exportTranscodeTemplate);
            exportTranscodeTemplate.forEach((template: any) => {
                let obj = _.find(this.transcodeTemplateList, { transcodeTemplateName: template.transcodeTemplateName });
                if (obj) {
                    if (type === 'id') {
                        str += obj.transcodeTemplateID + ';';
                    }
                    else {
                        str += obj.transcodeTemplateName + ';';
                    }
                }
            });
            if (str.length > 0) {
                str = str.substring(0, str.length - 1);
            }
        }
        return str;
    }

    // private filterOriginalFormat(template: mmsTypes.exportTypes.IGetTranscodeTemplate[]): mmsTypes.exportTypes.IGetTranscodeTemplate[] {
    //     return _.filter(template, (item)=>item.transcodeTemplateID !== '1')  //过滤原格式
    // }
    private getDefaultTemplateFormat(template: mmsTypes.exportTypes.IGetTranscodeTemplate[],enableOriginalFormat:boolean): string {
        if(template && template[0]){
            if(enableOriginalFormat){
                return template[0].transcodeTemplateID
            }else if(template[1]){
                return template[1].transcodeTemplateID
            }else{
                return ''
            }
        }else{
            return ''
        }
    }

    private loopMatchTemplate(type: string): mmsTypes.exportTypes.IGetTranscodeTemplate[] {
        let configTransTmpList: mmsTypes.exportTypes.IGetTranscodeTemplate[] | undefined;//外部系统配置支持的转码模板
        if (this.selectedSystem && this.selectedSystem.exportConfigureInfo 
            && this.selectedSystem.exportConfigureInfo.exportTranscodeTemplate){
            configTransTmpList = JSON.parse(this.selectedSystem.exportConfigureInfo.exportTranscodeTemplate);
        }
        let ts: any[] = []
        for (let i = 0; i < this.transcodeTemplateList.length; i++) {
            for (let j = 0; j < this.transcodeTemplateList[i].supportType.length; j++) {
                if (this.transcodeTemplateList[i].supportType[j] === type) {
                    if (configTransTmpList && configTransTmpList.length > 0){
                        if (_.findIndex(configTransTmpList, {transcodeTemplateID : this.transcodeTemplateList[i].transcodeTemplateID}) > -1){
                            ts.push(this.transcodeTemplateList[i])
                            break
                        }
                    }
                }
            }
        }
        if (!ts || ts.length === 0){//为空默认添加原格式
            ts = [
                {
                    transcodeTemplateID: '1',
                    transcodeTemplateName: '原格式',
                    supportType: [type]
                }
            ]
        }
        return ts
    }

    @action
    private getCategoryTreeByApi = () => {
        // 给js表达式让eval使用
        const user = this.stores.userStore.currentUser;
        const config = this.stores.configStore.config;
        if (this.selectedSystem && this.selectedSystem.exportConfigureInfo
             && this.selectedSystem.exportConfigureInfo.interactionFields) {
            this.selectedSystem.exportConfigureInfo.interactionFields.forEach((item, index) => {
                if (Number(item.controlType) === 404) {
                    item.controlType = 14
                    item.isShow = false
                    if (item.value && this.selectedSystem) {
                        let url = String(item.value);
                        let controlData = '';
                        try {
                            url = commonUtil.evalSyntax(url, ['user', 'config'], [user, config]);
                        } catch (error) { }
                        const isDefaultSelectFirst = commonUtil.getQueryParam(url, 'isDefaultSelectFirst');
                        let newInteractionFields = commonUtil.copyObj(this.selectedSystem.exportConfigureInfo.interactionFields);
                        http.get(url).then(res => {
                            runInAction(()=>{
                                if (res.data.data && _.isArray(res.data.data)) {
                                    const columns = res.data.data;
                                    const columnsTree = this.bindColumnTree(columns, {
                                        id: 0,
                                        categoryCode: '',
                                        categoryName: '',
                                        categoryParent: '',
                                        categoryType: '',
                                        children: []
                                    }, '').children;
                                    controlData = JSON.stringify(columnsTree);
                                    if (columnsTree.length && this.selectedSystem) {
                                        newInteractionFields[index].isShow = true
                                        newInteractionFields[index].controlData = controlData;
                                        newInteractionFields[index].value = '';
                                        if (isDefaultSelectFirst === 'true' && columns.length > 0){
                                            newInteractionFields[index].value = columns[0].code
                                        }
                                        this.selectedSystem.exportConfigureInfo.interactionFields = newInteractionFields;
                                    }
                                }
                            })
                        }, err => {
                            console.log(err);
                        });
                    }
                }
            })
        }
    }

    private bindColumnTree(columns: mmsTypes.exportTypes.ICategoryApiTree[], parent: ITreeItem, parentCode: string) {
        const haveChild = columns.find(s => s.parentCode === parentCode);
        if (haveChild || !parentCode) {
            columns.forEach(c => {
                const isFirstTree = !parentCode && !c.parentCode;
                if ((isFirstTree || c.parentCode === parentCode) && c.code) {
                    const tree: ITreeItem = {
                        "children": [],
                        "id": 0,
                        "categoryCode": (!parentCode ? '' : (parentCode + '/')) + c.code,
                        "categoryName": c.name,
                        "categoryParent": parentCode || '',
                        "categoryType": ''
                    };
                    parent.children.push(tree);
                    this.bindColumnTree(columns, tree, c.code);
                }
            });
        }
        return parent;
    }
}
