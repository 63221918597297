import ExtendStore from "../base/extendStore";
import { action, observable, runInAction } from "mobx";
import ccTypes from "../../types/ccTypes";
import ccApi from "../../apis/ccApi";
import workteamApi from "../../apis/workteamApi";
import workteamTypes from "../../types/workteamTypes";
import { AddEditMode } from "../../types/commonTypes";
import { Stores } from "../index";
import { message } from "antd";
import _ from 'lodash';
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import {l} from "mam-core-react";

export default class WorkteamStore extends ExtendStore {
    @observable
    public teams: ccTypes.sideContentTypes.IWorkteamRes[];

    @observable
    public team: ccTypes.sideContentTypes.IWorkteamRes

    /** 当前操作的workteam */
    @observable
    public opeWorkteam: ccTypes.sideContentTypes.IWorkteamRes;
    @observable
    public teamUsers: workteamTypes.ITeamUserRes[];
    /** 新增或修改 */
    @observable
    public modalType: number = AddEditMode.ADD;
    @observable
    public showModal: boolean = false;

    constructor(stores: Stores) {
        super(stores)
        this.initDataViewModeStore(stores)
    }

    @action
    public queryTeams() {
        ccApi.sideContentApi.getWorkteam().then((res) => {
            runInAction(() => {
                if (res.data && res.data.success && res.data.data) {
                    this.teams = _.sortBy(res.data.data, (o: ccTypes.sideContentTypes.IWorkteamRes) => {
                        if (this.stores.userStore.currentUser) {
                            return o.creatorCode !== this.stores.userStore.currentUser.userCode;
                        }
                        return true;
                    });
                } else {
                    this.teams = [];
                }
            });
        });
    }

    @action
    public queryTeamUsers() {
        workteamApi.getTeamUsers(this.opeWorkteam.groupCode).then((res) => {
            runInAction(() => {
                if (res.data.success && res.data.data) {
                    this.teamUsers = res.data.data;
                }
            });
        });
    }

    @action
    public openModal(item?: ccTypes.sideContentTypes.IWorkteamRes, type?: number) {
        if (!item) {
            if (this.stores.userStore.currentUser) {
                this.opeWorkteam = {
                    groupName: '',
                    creatorCode: this.stores.userStore.currentUser.userCode,
                    groupCode: ''
                };
                let user: workteamTypes.ITeamUserRes = commonUtil.copyObj(this.stores.userStore.currentUser);
                this.teamUsers = [user];
            }
        }
        else {
            this.setOpeWorkteam(commonUtil.copyObj(item));
            this.queryTeamUsers();
        }
        if (type !== undefined) {
            this.setModalType(type);
        }
        this.setShowModal(true);
    }

    @action
    public deleteWorkteam(codes: string[]) {
        workteamApi.deleteTeam(codes).then((res) => {
            if (res.data.success) {
                message.success(l('workteam.006', '操作成功'));
                this.stores.workteamStore.queryTeams();
            }
        });
    }

    @action
    public exitWorkteam(codes: string[]) {
        workteamApi.exitTeam(codes).then((res) => {
            if (res.data.success) {
                message.success(l('workteam.006', '操作成功'));
                this.stores.workteamStore.queryTeams();
            }
        });
    }

    @action
    public setTeamUsers(users: workteamTypes.ITeamUserRes[]) {
        this.teamUsers = users;
    }

    @action
    public setOpeWorkteam(workteam: ccTypes.sideContentTypes.IWorkteamRes) {
        this.opeWorkteam = workteam;
    }

    @action
    public setModalType(type: number) {
        this.modalType = type;
    }

    @action
    public setShowModal(show: boolean) {
        this.showModal = show;
    }

    @action
    public setTeam(team: ccTypes.sideContentTypes.IWorkteamRes) {
        this.team = team
    }

    @action
    public updateGroupName(value: string) {
        this.opeWorkteam.groupName = value;
    }

    @action
    public removeTeamUsers(item: workteamTypes.ITeamUserRes) {
        _.remove(this.teamUsers, (o: workteamTypes.ITeamUserRes) => {
            return o === item;
        })
    }

    @action
    public addTeamUsers(user: workteamTypes.ITeamUserRes) {
        this.teamUsers.push(user);
    }
}