import { action, observable, runInAction } from "mobx";
import ccTypes from "src/types/ccTypes";
import CcStore from "src/stores/cc/ccStore";
import { Stores } from "src/stores/index";
import ccApi from "src/apis/ccApi";
import _ from 'lodash';
import {extractTags} from "src/pages/newEntity/modules/rightTabContent/contentStruct/storyTreeList/tags";
import { message } from "antd";
import mmsTypes from "src/types/mmsTypes";
import { commonUtil } from "mam-common-utils";


export default class PrivateSaveStore extends CcStore {
    @observable
    public tab: number = 1  //1成品素材 2密级素材
    @observable
    public params: ccTypes.searchTypes.ISearchParams
    @observable
    public fullSearchData?: ccTypes.searchTypes.IFullSearchRes;

    @observable
    public searchKeyword1: string = '';  // 主搜索框值
    @observable
    public searchKeyword2: string = '';  // 次检索框值

    @observable
    public pageSize: number = 30;
    public pageSizes: number[] = [30,60,100]

    @observable
    public indeterminate: boolean = false
    @observable
    public isAllSelect: boolean = false
    @observable
    public wxAdvancedSearchParams: ccTypes.searchTypes.IFullSearchReq //增加存储检索params
    @observable
    public arrayOfCheckBox: mmsTypes.mmsOutputExcelWX.IField[] = []
    public oldArrofCheckBox:  mmsTypes.mmsOutputExcelWX.IField[]

    constructor(stores: Stores) {
        super(stores)
        this.initSelectAllStore(stores,'stores.privateSaveStore.fullSearchData.queryResult.data', 'selected')
        this.initAdvancedSearchStore(stores)
        this.initDeleteStore(stores)
        this.initDownloadStore(stores)
        this.initFavoriteStore(stores)
    }

    @action
    public setTab(tab: number) {
        this.tab = tab
    }
    
    @action
    public queryFullSearchData(page?: number): Promise<any> {
        this.selectAllStore.switchAllSelected(false);
        this.fullSearchData = undefined;
        return new Promise((resolve, reject) => {
            this.getSortItems();
            if (this.stores.configStore.config.searchResultNum){
                this.pageSize = this.stores.configStore.config.searchResultNum;
            }
            const pageSizeStorage = localStorage.getItem('searchStore.pageSize');
            if (pageSizeStorage !== null){
                this.pageSize = parseInt(pageSizeStorage, 10)
            }
            let req: ccTypes.searchTypes.IFullSearchReq = {
                page: page || this.params.page,
                size: this.pageSize,
                keyword: this.params.keyword,
                sortBys: [{
                    fieldName: this.currentSort.field,
                    isDesc: this.currentSort.desc === 'desc'
                }],
                conditions: [],
                highLight: true,
            };
            
            if(this.tab === 1 && !(_.find(this.params.conditions, { field: "tree_path_"}))){
                //成品素材菜单中的素材是从半成品中找的
                this.params.conditions.push({
                    field: "tree_path_",
                    value: ["global_sobey_defaultclass/public/半成品素材"]  
                })
                //之前成品素材菜单过滤状态:"M2待认领","M2编辑","M2待审","M2审核中","M2编目驳回","M2正常通过","M2修改通过"
                // this.params.conditions.push({
                //     field: "wx_catalogue_step",
                //     value: ["m2editwait","m2edit","m2auditwait","m2audit","m2editreject","m2pass","m2editpass"]  
                // })
            }
            if(this.tab === 1){
                //后面改为粗精切分别过滤状态，粗切："M2待认领","M2编辑","M2待审","M2审核中","M2编目驳回","M2正常通过","M2修改通过"; 精切："M1自动通过","M1正常通过","M1修改通过","粗精切关联","基因校验","M2待认领","M2编辑","M2待审","M2审核中","M2编目驳回","M2正常通过","M2修改通过"
                req.editConditions = [
                    {
                        "editType":0,
                        "conditions":[{
                            "field":"wx_catalogue_step",
                            "value":["m2editwait","m2edit","m2auditwait","m2audit","m2editreject","m2pass","m2editpass"]
                        }]
                    },
                    {
                        "editType":1,
                        "conditions":[{
                            "field":"wx_catalogue_step",
                            "value":["m1zdpass","m1pass","m1editpass","cjqrelated","dnacheck","m2editwait","m2edit","m2auditwait","m2audit","m2editreject","m2pass","m2editpass"]
                        }]
                    }
                ]
                //无锡定制除了密级素材菜单外其他只能查公共的
                if(this.stores.configStore.config.wuXiCustomSwitch){
                    req.conditions.push({
                        field: "privilege_",
                        value: ["public_S1"] 
                    })
                }
            }
            if(this.tab === 2 && !(_.find(this.params.conditions, { field: "privilege_"}))){
                this.params.conditions.push({
                    field: "privilege_",
                    value: ["template_juemi_S1","template_jimi_S1","template_mimi_S1"] 
                })
            }
            if (this.params.conditions) {
                this.params.conditions.forEach((condition) => {
                    if (condition.items) {
                        condition.items.forEach((n) => {
                            let item = _.find(req.conditions, { field: n.field });
                            if (item == null) {
                                req.conditions.push(n);
                            } else {
                                if (_.isArray(item.value)) {
                                    if (!_.includes(item.value, n.value)) {
                                        item.value.push(n.value as string);
                                    }
                                } else {
                                    if (item.value !== n.value) {
                                        item.value = [item.value, n.value as string];
                                    }
                                }
                            }
                        });
                    } else {
                        if (condition.field && condition.value) {
                            req.conditions.push({ field: condition.field, value: condition.value })
                        }
                    }
                });
            }
            if (this.params.advancedSearch && this.params.advancedSearch.field.length > 0 && this.params.advancedSearch.isOnlyAvSearching) {
                const params: ccTypes.searchTypes.IAdvancedSearchTypes = {
                    field:  this.params.advancedSearch.field.filter(item => {
                        if(item.value !== '' && item.value !== "notFound" && item.value !== undefined && item.value !== '[]' && item.value !== null) {
                            return item
                        }else {
                            return false
                        }
                    }),
                    isOnlyAvSearching: this.params.advancedSearch.isOnlyAvSearching
                }
                if(params.field && params.field.length > 0 ){
                    req.advancedSearch = this.stores.privateSaveStore.handleWuxiAdvancedSearch(params)
                }
            }
            if (this.params.resourceName) {
                req.resourceName = this.params.resourceName
            }
            this.wxAdvancedSearchParams = req;
            if(this.tab === 1){//成品素材的接口是无锡定制的
                ccApi.searchApi.wxFinishedFullSearch(req).then((res) => {
                    runInAction(() => {
                        if (res.data.success === true && res.data.data) {
                            res.data.data = this.mappingData2SectionSearch(res.data.data)
                            this.initFacets(res.data.data.facets);
                            if (res.data.data.queryResult && res.data.data.queryResult.data) {
                                this.resolveQueryResult(res.data.data.queryResult.data);
                            }
                            /** 无锡定制：根据素材id获取云端高低码状态 */
                            if(this.stores.configStore.config.pushPullSwitch){
                                this.getProgramNameCloud(res.data.data.queryResult.data)
                            }
                            this.fullSearchData = res.data.data;
                        }
                    })
                    resolve();
                }, () => {
                    reject();
                });
            }else{
                ccApi.searchApi.fullSearch(req).then((res) => {
                    runInAction(() => {
                        if (res.data.success === true && res.data.data) {
                            res.data.data = this.mappingData2SectionSearch(res.data.data)
                            this.initFacets(res.data.data.facets);
                            if (res.data.data.queryResult && res.data.data.queryResult.data) {
                                this.resolveQueryResult(res.data.data.queryResult.data);
                            }
                            /** 无锡定制：根据素材id获取云端高低码状态 */
                            if(this.stores.configStore.config.pushPullSwitch){
                                this.getProgramNameCloud(res.data.data.queryResult.data)
                            }
                            this.fullSearchData = res.data.data;
                        }
                    })
                    resolve();
                }, () => {
                    reject();
                });
            }
        });
    }

    @action
    public setParams(searchParams: ccTypes.searchTypes.ISearchParams) {
        this.params = searchParams;
        if (searchParams.keyword){
            if (searchParams.keyword[0]){
                this.setSearchKeyword1(searchParams.keyword[0]);
            }
            if (searchParams.keyword[1]){
                this.setSearchKeyword2(searchParams.keyword[1]);
            }
        }
        //如果有高级检索条件，则自动显示高级检索面板
        if (searchParams.advancedSearch && searchParams.advancedSearch.field && searchParams.advancedSearch.field.length > 0
            && this.advancedSearchStore){
            this.advancedSearchStore.setShowAdvancedSearch(true)
        }
    }

    @action
    public setSearchKeyword1(str: string) {
        this.searchKeyword1 = str;
    }
    @action
    public setSearchKeyword2(str: string) {
        this.searchKeyword2 = str;
    }

    /**
     * 重命名
     */
    @action
    public setRename(index: number, editMode: boolean) {
        if (this.fullSearchData) {
            if (editMode) {
                this.fullSearchData.queryResult.data[index].oldName = this.fullSearchData.queryResult.data[index].name
            }
            this.fullSearchData.queryResult.data[index].editMode = editMode
        }
    }

    @action
    public setNewRename(index: number, newName: string) {
        if (this.fullSearchData) {
            this.fullSearchData.queryResult.data[index].name = newName
        }
    }

    @action
    public setPageSize(size: number){
        this.pageSize = size;
        localStorage.setItem('searchStore.pageSize', size.toString());
        this.queryFullSearchData(1).then(()=>{});
    }

    /** 将片段检索结果映射成素材检索所需要的字段 */
    public mappingData2SectionSearch(res: ccTypes.searchTypes.IFullSearchRes | ccTypes.searchTypes.ISmartFullSearchRes): ccTypes.searchTypes.IFullSearchRes {
        if (!this.params.resourceName || this.params.resourceName === 'entity') {
            return res as ccTypes.searchTypes.IFullSearchRes
        } else {
            let newRes: ccTypes.searchTypes.IFullSearchRes = {
                facets: res.facets || [],
                sortFields: res.sortFields,
                usedTime: res.usedTime,
                queryResult: {
                    pageIndex: res.queryResult.pageIndex,
                    pageTotal: res.queryResult.pageTotal,
                    pageSize: res.queryResult.pageSize,
                    recordTotal: res.queryResult.recordTotal,
                    data: (res.queryResult.data as ccTypes.searchTypes.ISmartQueryResultData[]).map((item): ccTypes.searchTypes.IQueryResultData => {
                        return {
                            id: '',
                            site_: item.site_,
                            contentId_: item.contentId_,
                            contentId: item.contentId_,
                            name_: item.scenename || item.title,
                            name: item.scenename || item.title,
                            createDate_: item.createDate_,
                            createUser_: item.userName,
                            createdate: item.createDate_,
                            creator: item.createUser_,
                            catalogstate: '',
                            type_: item.type_,
                            type: item.type_,
                            aspect_: 0,
                            filesize: 0,
                            fileSize_: 0,
                            size_: 0,
                            tree_path_: '',
                            treePath: '',
                            formatFlag: 0,
                            trimin: '',
                            trimout: '',
                            frameRate: 0,
                            durationTc: item.durationTc,
                            distributionMark: '',
                            isCollected: false,
                            siteName: item.site_,
                            isPickedup: false,
                            isPlaceholder: 0,
                            isPublish: 0,
                            keyframepath: item.keyframepath || '',
                            keyframe: item.keyframepath || '',
                            privilege_: item.privilege_,
                            permissionInfo: {
                                canRead: false,
                                canWrite: false,
                                canExecute: false,
                                canDelete: false,
                            },
                            resourceType: '',
                            archiveList: [],
                            barcodes: [],
                            meterialLabel: [],
                            dynamicResponse: {},
                            duration: '',
                            isFavorite: item.isFavorite,
                            storagedDate: '',
                            storagedUser: '',
                            isMy: false,
                            canRead: false,
                            canWrite: false,
                            canExecute: false,
                            canDelete: false,
                            fileExt: '',
                            storyid: item.storyid,
                            inpoint: item.inpoint,
                            outpoint: item.outpoint,
                            tags: extractTags(item),
                            sceneid:item.sceneid || undefined,
                            shotid:item.shotid || undefined
                        }
                    })
                }
            };
            return newRes
        }
    }

    // 获取导出字段
    @action
    public wxGetCatalogueField = () =>{
        return new Promise((resolve,reject)=>{
            ccApi.searchApi.wxGetCatalogueField().then((res)=>{
                runInAction(() => {
                    this.arrayOfCheckBox = res.data.data || []
                    this.arrayOfCheckBox.map((item:any)=>{
                        item.isSelected = false
                        if(item.isEnable === true){
                            item.isSelected = true
                        }
                    })
                    this.oldArrofCheckBox = JSON.parse(JSON.stringify(this.arrayOfCheckBox))
                })
            }).catch(res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            })
        })
    }
    //单选
    @action
    public setCheckBoxSelected = (item:any, checked:any)=>{
        item.isSelected = checked
    }    
    //全选
    @action
    public setAllSelected = (checkedValues:any)=>{
        if(this.arrayOfCheckBox){
            this.arrayOfCheckBox.map((item:any)=>{
                item.isSelected = checkedValues
            })
            this.isAllSelect = checkedValues
        }

    }
    //控制全选checkBox的样式变化
    @action
    public setIndeterminate = (indeterminate:boolean)=>{
        this.indeterminate = indeterminate
    }

    //导出excel
    @action 
    public wxExportExcel = (params:any,token?:string)=>{
        return new Promise((resolve,reject)=>{
            ccApi.searchApi.wxExportExcel(params,token).then((res)=>{
                runInAction(()=>{
                    if(res.data.success === true){
                        message.success('导出成功')
                    }
                    this.restoreDefaultCheck()
                })
            }).catch(res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            })
        })
    }

    @action
    public restoreField = (checkedList:any)=>{
        let checkedLists = commonUtil.copyObj(checkedList)
        checkedLists.map((o:any)=>{
            delete o.isSelected
        })
        return checkedLists
    }

    @action
    public restoreDefaultCheck = ()=> {
        this.arrayOfCheckBox = [...this.oldArrofCheckBox]
    }
}
