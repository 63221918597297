import ExtendStore from "src/stores/base/extendStore";
import EntityStore from "./extends/entityStore";
import ReviewInfoStore from "./extends/reviewInfoStore";
import { Stores } from "src/stores/index";

export default class RepeatAuditExtendStore extends ExtendStore {
    public entityStore: EntityStore;
    public reviewInfoStore: ReviewInfoStore;

    public initEntityStore() {
        this.entityStore = new EntityStore(this.stores, this as any);
    }
    public initReviewInfoStore() {
        this.reviewInfoStore = new ReviewInfoStore(this.stores, this as any);
    }
}
