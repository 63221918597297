import {observable, action} from 'mobx'
import {ILang} from "../types/langTypes"
import BaseStore from "./base/baseStore"
import {Stores} from "./index";

/**
 * 用户相关Store
 */
export default class LangStore extends BaseStore<Stores> {
    @observable
    public langCfg?: ILang

    @action
    public setLangCfg(langCfg?: ILang): void {
        this.langCfg = langCfg
    }
}
