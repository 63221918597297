import { IBasePermission } from './commonTypes';

namespace folderPermissionTypes {
    export interface IRelatedPermission {
        owner: IUserInfo;
        users: IUserInfo[]
        privilege: string
        privilegeName: string
        privilegeEnable: boolean
    }

    export interface IUserInfo extends IBasePermission {
        userCode: string
        loginName: string
        nickName: string
        selected?: boolean
    }

    export interface ISaveFolderPermissionRequest {
        model: CreatePublicFolder
        users?: IUserInfo[]
        isCovered?: boolean
        isCoveredOwner?: boolean
        ownerUserCode: string
    }

    export class CreatePublicFolder {
        public id: string = ''
        public name: string
        public parentId: string
        public privilegeEnable: boolean
        public privilege: string
    }

    export interface IOpenModalInfo {
        operateType: folderOperate
        parentId: string
        contentId: string
        name: string
        oldName?: string
        isFirstLevel: boolean
    }

    export interface IUpdateFailEntiy {
        title: string
        contentId: string
        reason: string
        resultCode: string
    }

    export interface IUpdatePermissionTask {
        current: number
        taskId: string
        status: string
        progress: number
        total: number
        errorList: IUpdateFailEntiy[]
    }
}
export default folderPermissionTypes

export enum folderOperate {
    create = 'create',// 创建
    edit = 'edit', // 编辑
    view = 'view'// 详情
}