import ExtendStore from "../base/extendStore";
import MediaStore from "./extends/mediaStore";
import RundownStore from "./extends/rundownStore";
import SmartViewStore from './extends/smartViewStore';
import ContentIndexStore from "./extends/contentIndexStore";
import ReviewInfoStore from './extends/reviewInfoStore';
import PreviewImageStore from './extends/previewImageStore';
import CuttingsStore from './extends/cuttingsStore';
import CommentStore from './extends/commentStore';

export default class EntityExtendStore extends ExtendStore {
    public mediaStore: MediaStore;
    public rundownStore: RundownStore;
    public smartViewStore: SmartViewStore;
    public contentIndexStore: ContentIndexStore;
    public reviewInfoStore: ReviewInfoStore;
    public previewImageStore: PreviewImageStore;
    public cuttingsStore: CuttingsStore;
    public commentStore: CommentStore;

    public initMediaStore() {
        this.mediaStore = new MediaStore(this.stores, this as any);
    }

    public initRundownStore() {
        this.rundownStore = new RundownStore(this.stores);
    }

    public initSmartViewStore() {
        this.smartViewStore = new SmartViewStore(this.stores, this as any);
    }

    public initContentIndexStore() {
        this.contentIndexStore = new ContentIndexStore(this.stores, this as any);
    }

    public initReviewInfoStore() {
        this.reviewInfoStore = new ReviewInfoStore(this.stores);
    }

    public initPreviewImageStore(){
        this.previewImageStore = new PreviewImageStore(this.stores, this as any);
    }

    public initCuttingsStore() {
        this.cuttingsStore = new CuttingsStore(this.stores);
    }

    public initCommentStore(){
        this.commentStore = new CommentStore(this.stores, this as any)
    }
}
