import { message, Modal } from "antd";
import uploadTypes from "../../../types/uploadTypes";
import { stores } from "../../../stores";
import _ from 'lodash';
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import {l} from "mam-core-react";


class Uploader {
    public options: uploadTypes.IUploadOptions;
    private tf: any;
    private taskInitSuccess: (task: uploadTypes.ITask) => void;
    private taskInitBefore: (task: uploadTypes.ITask) => void;
    private taskUploadProgress: (task: uploadTypes.ITask) => void;
    private taskDeleteSuccess: (task: uploadTypes.ITask) => void;
    private taskUploadSuccess: (task: uploadTypes.ITask) => void;
    private taskUploadSurplusTime: (task: uploadTypes.ITask) => void;

    constructor() {
    }

    public setTaskInitSuccess(fun: (task: uploadTypes.ITask) => void) {
        this.taskInitSuccess = fun;
    }
    public setTaskInitBefore(fun: (task: uploadTypes.ITask) => void) {
        this.taskInitBefore = fun;
    }
    public setTaskUploadProgress(fun: (task: uploadTypes.ITask) => void) {
        this.taskUploadProgress = fun;
    }
    public setTaskDeleteSuccess(fun: (task: uploadTypes.ITask) => void) {
        this.taskDeleteSuccess = fun;
    }
    public setTaskUploadSuccess(fun: (task: uploadTypes.ITask) => void) {
        this.taskUploadSuccess = fun;
    }
    public setTaskUploadSurplusTime(fun: (task: uploadTypes.ITask) => void) {
        this.taskUploadSurplusTime = fun;
    }

    public init() {
        return new Promise((resolve, reject)=>{
            commonUtil.asyncLoadScriptArr(['/libs/mam-upload/dist/libs/oss/aliyun-oss-sdk.min.js',
            '/libs/mam-upload/dist/libs/oss-s3/aws-sdk.min.js',
            '/libs/mam-upload/dist/libs/oos/oos-sdk.min.js',
            '/libs/mam-upload/dist/libs/plupload/plupload.full.min.js',
            '/libs/mam-upload/dist/libs/ksyun/ks3jssdk.min.js',
            '/libs/mam-upload/dist/mam-upload-pure.js']).then(()=>{
                if (!(window as any).mamUpload.web || !(window as any).mamUpload.vtube) {
                    let opts: any = {
                        configInst: stores.configStore.config
                    };
                    if (commonUtil.isDebug()){
                        opts.baseServerUrl = 'http://localhost:8888/proxy';
                    }
                    (window as any).mamUpload.init(opts);
                    this.initEvents();
                }
                resolve(undefined);
            })
        })
    }

    public selectFile(options: uploadTypes.IUploadOptions) {
        this.init().then(()=>{
            this.options = this.getOptions(options);
            if (this.options.transferType === uploadTypes.TransferTypes.WEB) {
                this.getTransfer.openFileSelector((tasks: uploadTypes.ITask[]) => {
                    if (this.options.writeMetadataBefore && this.options.writeMetadataBefore(this.getTasksByType(tasks), this.options) === false) {
                        return;
                    }
                    if (options.openModal) {
                        options.openModal(this.getTasksByType(tasks), this.options);
                    }
                });
            } else {
                // 客户端操作，因此要先验证客户端是否存在
                this.getTransfer.openFileSelector((tasks: uploadTypes.ITask[]) => {
                    if (this.options.writeMetadataBefore && this.options.writeMetadataBefore(this.getTasksByType(tasks), this.options) === false) {
                        return;
                    }
                    if (options.openModal) {
                        options.openModal(this.getTasksByType(tasks), this.options);
                    }
                }, () => {
                    let vtubeDownloadPath: string = (window as any).nxt.config.vtubeDownloadPath;
                    const modal = Modal.confirm(
                        {
                            title: '客户端异常',
                            content: '请确定已经正常开启客户端或重启客户端再重试。',
                            okText: '下载客户端',
                            onOk: () => {
                                message.info('正在下载客户端')
                                setTimeout(() => {
                                    window.open(vtubeDownloadPath)
                                }, 1000);
                            }
                        }
                    );
                });
            }
        });
    }

    public getFileTasks(files: File[], options: uploadTypes.IUploadOptions): Promise<uploadTypes.ITask[]>{
        return new Promise((resolve, reject)=>{
            this.init().then(()=>{
                this.options = this.getOptions(options);
                let tasks = this.getTransfer.getTasksByFiles(files);
                resolve(this.getTasksByType(tasks));
            });
        })
    }

    public createTask(tasks: uploadTypes.ITask | uploadTypes.ITask[]) {
        this.getTransfer.createTask(tasks, this.options);
    }

    public get getTransfer() {
        if (this.tf){
            return this.tf;
        }
        else {
            if (this.options.transferType === uploadTypes.TransferTypes.WEB) {
                this.tf = (window as any).mamUpload.web;
            }
            else {
                this.tf = (window as any).mamUpload.vtube;
            }
            return this.tf;
        }
    }

    /** 检查文件是否重复 */
    public checkExist(files: uploadTypes.IFile[], file: uploadTypes.IFile) {
        let isHas;
        if (this.options.transferType === uploadTypes.TransferTypes.WEB) {
            isHas = _.find(files, (item: uploadTypes.IFile) => {
                return item !== file && (item.file as File).name === (file.file as File).name &&
                    (item.file as File).size === (file.file as File).size &&
                    (item.file as File).lastModified === (file.file as File).lastModified;
            });
        } else { //vtube
            isHas = _.find(files, (item: uploadTypes.IFile) => {
                return item !== file
                    && (file.file as uploadTypes.IVtubeFile).FilePath === (item.file as uploadTypes.IVtubeFile).FilePath;
            });
        }
        if (isHas != null) {
            message.error(l('upload.fileExist', '文件 ' + file.fileName + ' 已存在', { name: file.fileName }));
            return true;
        }
        return false;
    }

    public getTasksByType(tasks: uploadTypes.ITask[]): uploadTypes.ITask[] {
        if (this.options.taskType === uploadTypes.TaskTypes.GENERAL) {
            return tasks;
        }
        else if (this.options.taskType === uploadTypes.TaskTypes.PICTUREPACKAGEUPLOAD
            || this.options.taskType === uploadTypes.TaskTypes.GROUPUPLOAD) {
            return [this.getGroupTask(tasks, this.options.taskType === uploadTypes.TaskTypes.PICTUREPACKAGEUPLOAD ?
                'picture' : 'video')];
        }
        return [];
    }

    /** 获取配置参数 */
    public getOptions(options: uploadTypes.IUploadOptions){
        let opts = $.extend({}, {
            module: uploadTypes.UploadModules.YUNPAN,
            taskType: uploadTypes.TaskTypes.GENERAL,
            transferType: uploadTypes.TransferTypes.WEB,
            targetFolder: '',
            relationContentType: uploadTypes.RelationContentType.NONE,
            relationContentId: ''
        }, options);
        return opts;
    }

    /** 获取图片包或成组上传任务 */
    private getGroupTask(tasks: uploadTypes.ITask[], code: string): uploadTypes.ITask {
        let type = _.find(stores.configStore.config.entityTypes, { 'code': code });

        let rettask: uploadTypes.ITask = {
            id: commonUtil.getUuid(),
            entityType: type ? type.code : '',
            files: tasks.map((task) => {
                return {
                    fileName: task.fileName,
                    file: task.file,
                    metadata: task.metadata
                }
            }) as uploadTypes.IFile[],
            'metadata': {
                name: ''
            },
            targetFolder: this.options.targetFolder,
            taskType: this.options.taskType,
            transferType: this.options.transferType,
            status: 'added',
            progress: 0
        };
        return rettask;
    }

    private initEvents() {
        (window as any).mamUpload.web.off('task-init-success');
        (window as any).mamUpload.web.on('task-init-success', (e: any, task: uploadTypes.ITask) => {
            if (this.taskInitSuccess) {
                this.taskInitSuccess(task);
            }
        });
        (window as any).mamUpload.web.on('task-init-error', (e: any, task: uploadTypes.ITask, res: any) => {
            message.error(res.error.title)
        });

        (window as any).mamUpload.web.off('task-init-before');
        (window as any).mamUpload.web.on('task-init-before', (e: any, task: uploadTypes.ITask) => {
            if (this.taskInitBefore) {
                this.taskInitBefore(task);
            }
        });
        (window as any).mamUpload.web.off('task-upload-progress');
        (window as any).mamUpload.web.on('task-upload-progress', (e: any, task: uploadTypes.ITask) => {
            if (this.taskUploadProgress) {
                this.taskUploadProgress(task);
            }
        });

        (window as any).mamUpload.web.off('task-delete-success');
        (window as any).mamUpload.web.on('task-delete-success', (e: any, task: uploadTypes.ITask) => {
            if (this.taskDeleteSuccess) {
                this.taskDeleteSuccess(task);
            }
        });

        (window as any).mamUpload.web.off('task-upload-success');
        (window as any).mamUpload.web.on('task-upload-success', (e: any, task: uploadTypes.ITask) => {
            if (this.taskUploadSuccess) {
                this.taskUploadSuccess(task);
            }
        });

        (window as any).mamUpload.web.off('task-upload-surplusTime');
        (window as any).mamUpload.web.on('task-upload-surplusTime', (e: any, task: uploadTypes.ITask) => {
            if (this.taskUploadSurplusTime) {
                this.taskUploadSurplusTime(task);
            }
        });
    }
}

export let uploader = new Uploader();
