import ExtendStore from '../../base/extendStore';
import { action, observable, runInAction } from 'mobx';
import _ from 'lodash'
import { message } from 'antd';
import { Stores } from 'src/stores'
import PersonalCenterStore from '../personalCenterStore'
import personalCenterTypes from 'src/types/personalCenterTypes'
import personalCenterApi from 'src/apis/personalCenterApi'

export default class InformationStore extends ExtendStore {
    public personalCenterStore: PersonalCenterStore

    @observable
    public userInfo: personalCenterTypes.IUserInfo; 

    @observable
    public changePasswordModal: boolean = false;
    @observable
    public avatarMaxSize: number;
    @observable
    public avatarBase64: string;

    constructor(stores: Stores, personalCenterStore: PersonalCenterStore) {
        super(stores)
        this.personalCenterStore = personalCenterStore
    }

    @action
    public setUserInfo(obj: personalCenterTypes.IUserInfo) {
        this.userInfo = obj
    }

    @action
    public queryUserInfo() {
        return new Promise((resolve, reject) => {
            personalCenterApi.getUserInfo().then((res) => {
                runInAction(() => {
                    this.setUserInfo(res.data.data)
                    resolve(true)
                })
            }, err => {
                if (err.data.error && err.data.error.title){
                    message.error(err.data.error.title);
                }
                reject();
            })
        })
    }

    @action
    public setChangePasswordModal(flag: boolean) {
        this.changePasswordModal = flag
    }

    @action
    public changePassword(model: personalCenterTypes.IChangePassword) {
        return new Promise((resolve, reject) => {
            personalCenterApi.changePassword(model).then(res => {
                //这里还需要根据实际接口处理(需要修改)
                if(res.data.success && res.data.data) {
                    message.success(res.data.data)
                    resolve(true)
                }
                else {
                    // message.error(res.data.title)
                    reject()
                }
            }, err => {
                if (err.data.error && err.data.error.title){
                    message.error(err.data.error.title);
                }
                reject();
            })
        })
    }

    @action
    public setAvatarBase64(base64: string) {
        this.avatarBase64 = base64
    }

    @action
    public changeUserInfo(model: personalCenterTypes.IUserInfo) {
        //保存基本信息
        const { nickName, organizationName, isMale, email, telephone} = model
        let basicInfo = { 
            nickName, 
            organizationName, 
            isMale, 
            email, 
            telephone, 
            loginname: this.userInfo && this.userInfo.loginname,
            birthday: this.userInfo && this.userInfo.birthday}
        
        let arr = []
            
        let saveBasicInfo =  new Promise((resolve, reject) => {
            personalCenterApi.changeUserInfo(basicInfo).then(res => {
                if(res.data.success) {
                    resolve(true)
                }
            }, err => {
                if (err.data.error && err.data.error.title){
                    message.error(err.data.error.title);
                }
                reject();
            })
        })

        let saveAvatar = new Promise((resolve, reject) => {
            if(this.avatarBase64) {
                personalCenterApi.uploadAvatar({avatar: this.avatarBase64}).then(res => {
                    if(res.data.success) {
                        resolve(true)
                    }
                }, err => {
                    if (err.data.error && err.data.error.title){
                        message.error(err.data.error.title);
                    }
                    reject();
                })
            }
        })

        arr.push(saveBasicInfo)
        if(this.avatarBase64) {
            arr.push(saveAvatar)
        }
        Promise.all(arr).then(res => {
            let flag: boolean = true
            res.forEach((item) => {
                if(!item) {
                    flag = false
                }
            })
            if(flag) {
                message.success('更新信息成功'.l('personalCenter.updateMessage'))
            }
        })
    }
    @action
    public getAvatarConfig() {
        return new Promise((resolve, reject) => {
            personalCenterApi.getAvatarConfig().then(res => {
                runInAction(() => {
                    if(res.data.success && res.data.data) {
                        this.avatarMaxSize = res.data.data.maxSize
                        resolve(true)
                    }
                })
                
            }, err => {
                if (err.data.error && err.data.error.title){
                    message.error(err.data.error.title);
                }
                reject();
            })
        })
    }
}