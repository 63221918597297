
namespace exportAuditTypes {
    export enum Type {
        WAIT = 2,       // 待审批
        RESOLVE = 8,   // 已通过
        REJECT = 4,   // 已拒绝
    }
    export interface IGetExportauditListParams{
        auditStatus: number
        order: string
        sort: string
        taskState: null
        keyword: string
        pageIndex: number
        pageSize: number
    }
    export interface IAudittask{
        auditPass: boolean
        reason: string
        taskIds: number[]
    }
    export interface IAudittaskResponse{
        data: IAudittaskData[]
        message: string
        status: number
        total: number
    }
    export interface IAudittaskData{
        entityName: string
        exportUser: string
        flowSendSuccess: boolean
        taskId: number
        message: string
    }
    export interface IExportData{
        index: number
        start: number
        end: number
    }
    export interface ITableColumns{
        title: string,
        key: string,
        dataIndex: string,
        width?:number,
        className?:string
        ellipsis?:boolean
        render?:any
    }
    export interface IExportAuditListResponse{
        data: IGetExportauditList[]
        pageIndex: number
        pageSize: number
        pageTotal: number
        recordTotal: number
    }
    export interface ITableOptions{
        label:string
        value:string
    }
    export interface IScreenData{
        entityName:string
        creatorName:string
        auditCurrentStep:number|null
        createTaskTimeStart:string|null
        createTaskTimeEnd:string|null
    }
    export interface IGetExportauditList{
        applyReason: string
        auditConfig: string
        auditReason: string
        auditStatus: number
        auditCurrentStep: number
        auditStepCount: number
        auditTime: string
        auditUser: string
        auditUserGroup: string
        auditValue: string
        contentId: string
        contentID: string
        createTaskTime: string
        creator: string
        creatorName: string
        creatorUserGroup: string
        durationTc: string
        entityChannel: string
        entityName: string
        entityType: string
        exportTaskID: number
        externalSystemID: string
        externalSystemName: string
        fileSize: number
        framerate: number
        guid: string
        isEnableReserve: boolean
        isRoleAudit: boolean
        messageID: string
        reserveTime: string
        status: number
        targetChannel: string
        transcodeTemplateName: string
        trimIn:number
        trimOut:number
        contentId_: string
        keyframe_: string
        createUser_: string
    }
}

export default exportAuditTypes
