import BaseStore from "../../base/baseStore";
import {action, observable, runInAction} from "mobx";
import ccApi from "../../../apis/ccApi";
import ccTypes from "../../../types/ccTypes";
import {Stores} from "../../index";

export default class RelativeInfoStore extends BaseStore<Stores>{
    @observable
    public relativeInfo?: ccTypes.relativeInfo.IGetRelativeInfoRes[]
    @observable
    public selectedReInfoIdx?: number
    /** 相关专辑 */
    @observable
    public relativeAlbums?: ccTypes.relativeInfo.IGetRelativeAlbums[]
    /** 相关事件 */
    @observable
    public relativeEvents?: ccTypes.relativeInfo.IGetRelativeEventsRes[]
    private currentKeyword: string;//记录当前keyword，防止下一次查询显示出上一次的查询结果

    @action
    public getRelativeInfo(keyword: string){
        ccApi.relativeInfoApi.getRelativeInfo(keyword).then(res=>{
            runInAction(()=>{
                this.relativeInfo = res.data.data;
                if (res.data.data && res.data.data.length > 0){
                    this.selectedReInfoIdx = 0;
                    this.getRelativeAlbums(keyword);
                    this.getRelativeEvents(keyword);
                }
            })
        })
    }

    @action
    public getRelativeAlbums(tag: string){
        this.currentKeyword = tag;
        this.relativeAlbums = [];
        ccApi.relativeInfoApi.getRelativeAlbums(tag).then(res=>{
            runInAction(()=>{
                if (res.data.data && tag === this.currentKeyword){
                    this.relativeAlbums = res.data.data
                }
            })
        })
    }

    @action
    public getRelativeEvents(tag: string){
        this.currentKeyword = tag;
        this.relativeEvents = [];
        ccApi.relativeInfoApi.getRelativeEvents(tag).then(res=>{
            runInAction(()=> {
                if (res.data.data && tag === this.currentKeyword){
                    this.relativeEvents = res.data.data;
                }
            });
        })
    }

    @action
    public emptyRelativeInfo(){
        this.relativeInfo = undefined
    }

    @action
    public setSelectedReInfoIdx(idx: number){
        this.selectedReInfoIdx = idx;
    }
}