import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import { IResponse } from "mam-core-react/dist/modules/http";
import batchEditTypes from 'src/types/batchEditTypes'
namespace batchEditApi {
    
    //获取选择的数据
    export const getEntityBatch = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/entity/batch/edit');
    }
    //删除
    export const deleteEntityBatch = (contentIdArr: string[]): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/batch/edit/delete', contentIdArr);
    }
    //获取元数据
    export const getEntityData = (entityType: string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/metadatafield/entitydata?entityType=' + entityType);
    }

    //获取单个内容
    export const getEntityBaseData = (entityType: string, contentId: string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/entity/get-entity-base-data?pathtype=' + 1 + '&permission=' + 2 + '&entityType=' + entityType + '&contentid='
            + contentId);
    }

    //批量编辑
    export const updateEntityBatchForm = (updateInfo: batchEditTypes.IUpdateInfoTypes): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/update-entity-data', updateInfo);
    }
}

export default batchEditApi