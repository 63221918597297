import ccTypes from "../../types/ccTypes";
import {action, observable, runInAction} from "mobx";
import ccApi from "../../apis/ccApi";
import { Stores } from "../index";
import ExtendStore from "../base/extendStore";
import {SmartMetadataType} from "../entity/extends/smartViewStore";
import _ from 'lodash';
import iSearchApi from 'src/apis/iSearchApi';
import { message } from 'antd';
import { playerUtil, commonUtil } from 'mam-common-utils';

export default abstract class CcStore extends ExtendStore {
    /** 是否显示层面过滤 */
    @observable
    public showFacetFilter: boolean = true;
    @observable
    public sortItems: ccTypes.searchTypes.ISortItem[] = []
    /** 当前选中排序类型 */
    @observable
    public currentSort: ccTypes.searchTypes.ISortItem;
    public abstract params: ccTypes.IParams;

    constructor(stores: Stores) {
        super(stores);
        this.initDownloadStore(stores)
        this.initFavoriteStore(stores)
        this.initDataViewModeStore(stores);
        this.initHypermediaPreviewBoxStore(stores);
        this.initOutstoreStore(stores);
        this.initSearchFilterStore(stores);
        this.initBatchSmartProcess(stores);

        let sCurrentSort = localStorage.getItem('horizon_search_currentSort');
        if (sCurrentSort) {
            this.currentSort = JSON.parse(sCurrentSort);
        }
        //无锡定制，初始化进入默认创建时间，不读取缓存
        if(this.stores.configStore.config?.wuXiCustomSwitch){
            this.currentSort = {
                desc: "desc",
                field: "createDate_",
                text: '创建时间 ↓'.l('search.createDateDesc'),
                hideDirection: true
            }
        }
    }

    public abstract setParams(searchParams: ccTypes.IParams): void;

    @action
    public setShowFacetFilter(show: boolean) {
        this.showFacetFilter = show;
    }

    @action
    public getSortItems = () => {
        const config = this.stores.configStore.config;
        let sortItems: ccTypes.searchTypes.ISortItem[] = []
        if (config && config.searchSortFields && config.searchSortFields.length > 0) {
            sortItems = config.searchSortFields.map((item: any) => {
                return {
                    text: item.name,
                    field: item.field,
                    desc: item.desc ? 'desc' : 'asc',
                    hideDirection: true
                }
            })

        } else {
            sortItems = process.env.BUILD_TYPE === 'mah' ? [
                { text: '相关度'.l('search.relatedRate'), field: 'default', desc: 'desc', hideDirection: true },
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'desc' },
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'asc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'desc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'asc' }
            ] : [
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'desc' },
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'asc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'desc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'asc' },
                { text: '相关度'.l('search.relatedRate'), field: 'default', desc: 'desc', hideDirection: true }
            ]
        }
        this.setSortItems(sortItems)
        if(!this.currentSort){
            this.setCurrentSort(sortItems[0])
        }
    }

    @action
    public setSortItems = (sortItems: ccTypes.searchTypes.ISortItem[]) => {
        this.sortItems = sortItems
    }

    @action
    public setCurrentSort(sort: ccTypes.searchTypes.ISortItem) {
        this.currentSort = sort;
        //无锡定制，不进行缓存
        if(this.stores.configStore.config?.wuXiCustomSwitch){
            return;
        }
        localStorage.setItem('horizon_search_currentSort', JSON.stringify(this.currentSort));
    }
    //search中自动切换到相关度
    @action
    public autoRelatedSort(isToRelated: boolean) {
        this.stores.configStore.getUiModulesEnable('autoRelatedSortEnable').then(()=>{
            if(this.stores.configStore.uiModulesSwitch.autoRelatedSortEnable){
                if(isToRelated){
                    this.currentSort = {
                        desc: "desc",
                        field: "default",
                        text: '相关度'.l('search.relatedRate'),
                        hideDirection: true
                    }
                }else{
                    this.currentSort = {
                        desc: "desc",
                        field: "createDate_",
                        text: '创建时间 ↓'.l('search.createDateDesc'),
                        hideDirection: true
                    }
                };
            }
        })
        
    }
    //newSearch中自动切换到相关度
    @action
    public autoRelatedSort2(isToRelated: boolean) {
        this.stores.configStore.getUiModulesEnable('autoRelatedSortEnable').then(()=>{
            if(this.stores.configStore.uiModulesSwitch.autoRelatedSortEnable){
                if(isToRelated){
                    this.currentSort = {
                        desc: "asc",
                        field: "default",
                        text: '相关度'.l('search.relatedRate'),
                        hideDirection: true
                    }
                }else{
                    let sCurrentSort = localStorage.getItem('horizon_search_currentSort');
                    if (sCurrentSort) {
                        this.currentSort = JSON.parse(sCurrentSort);
                    }else{
                        this.currentSort = {
                            desc: "desc",
                            field: "createDate_",
                            text: '入库时间 ↓'.l('search.createDateDesc'),
                            hideDirection: true
                        }
                    }
                };
            }
        })
        
    }

    /** 记录用户操作习惯 */
    public recordOperationHabit(id: string, entityType: string){      
        let param: ccTypes.searchTypes.IRecordOperationHabitReq = {
            id,
            entityType
        }
        ccApi.searchApi.recordOperationHabit(param); 
    }
    /** 本地缓存记录用户已点击过的整档素材,非整档素材不做记录 */
    public recordViewEntitys(id: string, entityType: string){
        if(entityType !== SmartMetadataType.story && entityType !== SmartMetadataType.scene && entityType !== SmartMetadataType.shot){
            let contentIdsStr = localStorage.getItem('search_view_contentIds') || "";
            let viewContentIds = contentIdsStr ? JSON.parse(contentIdsStr) : [];
            viewContentIds.push(id);
            localStorage.setItem('search_view_contentIds', JSON.stringify(viewContentIds));
            //给素材contentId的设置当前已点击过的样式
            $('#'+id+' .title-wrap a').addClass('entity-selected-record') //非列表模式
            $('#'+id+'.title-wrap a').addClass('entity-selected-record') //列表模式
        }
    }
    /** 检查是否是本地缓存记录用户已点击过的素材，是的话，列表中该素材名字置灰 */
    public checkIsRecordViewEntitys(id: string){
        let contentIdsStr = localStorage.getItem('search_view_contentIds') || "";
        let viewContentIds = contentIdsStr ? JSON.parse(contentIdsStr) : [];
        return viewContentIds.indexOf(id) > -1
    }
    /** 清空本地缓存记录用户已点击过的素材 */
    public clearRecordViewEntitys(){
        localStorage.setItem('search_view_contentIds', '[]');
    }
    /** 监听滚动使功能按钮栏悬浮固定 */
    public checkScroll(){
        if(this.stores.configStore.config?.wuXiCustomSwitch){//无锡定制
            let height: number = 0
            if($('.page-header') && $('.page-header')[0]){
                height+= $('.page-header')[0].clientHeight
            }
            if($('.advanced-search') && $('.advanced-search')[0]){
                height+= $('.advanced-search')[0].clientHeight
            }
            if($('.filter') && $('.filter')[0]){
                height+= $('.filter')[0].clientHeight
            }
            $('#appContent').scroll(()=> {
                let num = $('#appContent').scrollTop();
                if (num as number > height) {
                    $('.operate-toolbar').addClass('operate-toolbar-fixed')
                } else {
                    $('.operate-toolbar').removeClass('operate-toolbar-fixed')
                }
            })
        }
    }
    /* 无锡高级检索定制的栏目检索: 需要特殊处理高级检索查询时栏目的传参 */
    public handleWuxiAdvancedSearch(advancedSearch:ccTypes.searchTypes.IAdvancedSearchTypes):ccTypes.searchTypes.IAdvancedSearchTypes{
        let copyAdvancedSearch = commonUtil.copyObj(advancedSearch)
        let columnField = _.find(copyAdvancedSearch.field,{field:"column"})
        if(columnField && columnField.controlData){
            let value: string[] = []
            let obj = JSON.parse(columnField.controlData)
            let selectedColumnItems = JSON.parse(columnField.value as string)
            if(selectedColumnItems && selectedColumnItems.length > 0){
                selectedColumnItems.map((item: any)=>{
                    if(_.keys(obj).indexOf(item.value) > -1){
                        value.push(item.value)
                    }else{
                        _.keys(obj).map((k)=>{
                            if(obj[k].indexOf(item.value) > -1){
                                value.push(k)
                            }
                        })
                    }
                })
            }
            value = Array.from(new Set(value))
            if(value && value.length > 100){
                value.slice(0,100)
            }
            columnField.value = value && value.length > 0 ? value.join(' OR ') : "notFound"
            delete columnField.controlData
        }
        copyAdvancedSearch.field.map((item:any)=> {
            if(item.value === ''){
                item.value = "notFound"
            }
        })
        return copyAdvancedSearch
    }

    protected resolveQueryResult(list: Array<ccTypes.searchTypes.IQueryResultData | ccTypes.cloudTypes.IFullSearchData>, callback?: (item: ccTypes.searchTypes.IQueryResultData | ccTypes.cloudTypes.IFullSearchData) => void) {
        let pictures: Array<ccTypes.searchTypes.IQueryResultData | ccTypes.cloudTypes.IFullSearchData> = [];
        let parts: Array<ccTypes.searchTypes.IQueryResultData | ccTypes.cloudTypes.IFullSearchData> = []; 
        let contentIds: string[] = []
        list.forEach((item) => {
            contentIds.push(item.contentId);

            if (item.type === 'picture') {
                pictures.push(item);
            }

            if (item.type === 'sequence' || item.type === 'scene' || item.type === 'shot'){
                if (item.inpoint !== undefined && item.outpoint !== undefined){// 片段时长处理
                    item.duration = (item.outpoint - item.inpoint).toString();
                }
                parts.push(item);
            }

            if (callback && typeof (callback) === 'function') {
                callback(item);
            }
        });

        if (contentIds.length > 0){
            this.favoriteCheck(list, contentIds);
        }

        // 多图片标志
        if (pictures.length > 0 && process.env.BUILD_TYPE === 'mch') {
            ccApi.getPictureCount(pictures.map((pic) => {
                return pic.contentId;
            })).then((res1) => {
                runInAction(() => {
                    if (res1.data.data){
                        pictures.forEach((pic) => {
                            if (res1.data.data[pic.contentId]) {
                                let target = _.find((this as any).fullSearchData.queryResult.data, {contentId: pic.contentId});
                                target.pictureCount = res1.data.data[pic.contentId];
                            }
                        });
                    }
                });
            });
        }

        //片段查询关键帧
        if (parts.length > 0){
            ccApi.getPartsKeyframes(parts.map((part) => {
                return {
                    contentId: part.contentId.substring(0, part.contentId.indexOf('_')),
                    keyFrameNo: part.inpoint ? playerUtil.second2Frame(part.inpoint / Math.pow(10, 7), part.frameRate).toString() : '',
                    frameRate: part.frameRate
                };
            })).then((res1) => {
                if (res1.data.data){
                    runInAction(() => {
                        parts.forEach((item) => {
                            let resItem = res1.data.data[item.contentId.substring(0, item.contentId.indexOf('_'))];
                            if (resItem) {
                                let itemKeyframeNo = item.inpoint ? playerUtil.second2Frame(item.inpoint / Math.pow(10, 7), item.frameRate) : ''
                                let partItem = _.find(resItem, {keyFrameNo: itemKeyframeNo});
                                if (partItem){
                                    let target = _.find((this as any).fullSearchData.queryResult.data, {contentId: item.contentId});
                                    if (target){
                                        target.keyframe = partItem.filePath;
                                    }
                                }
                            }
                        });
                    });
                }
            });
        }
    }

    /** 初始化层面数据 */
    protected initFacets(facets: ccTypes.searchTypes.IFacets[] | ccTypes.cloudTypes.IFullSearchFacets[]) {
        facets.forEach(item => {
            if (item.facetFieldName === 'createDate_') {
                item.startDate = '';
                item.endDate = '';
            }
            item.showMore = false;
            if (item.facetValue) {
                item.facetValue.forEach((o: ccTypes.searchTypes.IFacetsFacetValue | ccTypes.cloudTypes.IFullSearchFacetsFacetValue) => {
                    o.isShow = true;
                    o.isSelected = false;
                });
            }
            item.checkboxIsOpen = false;
        });
    }

    /*无锡定制：获取云端高低码H和L状态展示 */
    protected getProgramNameCloud(list: Array<ccTypes.searchTypes.IQueryResultData | ccTypes.cloudTypes.IFullSearchData>, callback?: (item: ccTypes.searchTypes.IQueryResultData | ccTypes.cloudTypes.IFullSearchData) => void) {
        let contentIds = _.map(list, 'contentId');
        ccApi.searchApi.getProgramNameCloud(contentIds).then((res)=>{
            runInAction(() => {
                if(res.data.success && res.data.data){
                    let programNameLocalAndCloudRes:ccTypes.searchTypes.IProgramNameCloudResItem[] = res.data.data
                    _.forEach(programNameLocalAndCloudRes, (item: ccTypes.searchTypes.IProgramNameCloudResItem) => {
                        if(list && list.length > 0){
                            _.forEach(list, (o: ccTypes.searchTypes.IQueryResultData | ccTypes.cloudTypes.IFullSearchData) => {
                                if(o.contentId === item.contentId){
                                    o.programNameCloud = item.programNameCloud || ''
                                }
                            })
                        }
                    })
                }
            })
        })
    }

    private favoriteCheck(list: Array<ccTypes.searchTypes.IQueryResultData | ccTypes.cloudTypes.IFullSearchData>, contentIds: string[]){
        iSearchApi.favoriteCheck(contentIds).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    res.data.data.forEach((id)=>{
                        if (list){
                            let item = _.find(list, {contentId: id});
                            if (item){
                                item.isFavorite = true;
                                item.isCollected = true;
                            }
                        }
                    })
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }
}
