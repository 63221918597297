import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import { IResponse } from "mam-core-react/dist/modules/http";

namespace copyrightApi {
    //获取左侧初始数据
    export const getFullSearchData = (requestParam: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/search/full-search', requestParam);
    }

    //获取左侧初始数据
    export const getFacetsData = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/copyright/config/facets');
    }

    // 获取左侧精确筛选数据
    export const getFieldsData = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/copyright/config/advancedsearch/fields');
    }

    // 获取右侧排序
    export const getConfigSort = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/copyright/config');
    }

    // 获取右侧列表数据
    export const getFullSearchTableData = (value:any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/copyright/fullSearch',value);
    }

    // 获取左侧弹出框树形结构数据
    export const getLeftTreeData = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/organization/get-organization-all-subs?code=');
    }

    // 获取左侧弹出框入库人员
    export const getLeftPersonData = (value: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/organization/get-organizations-users', value);
    }

    // 获取弹出框元数据
    export const getModalMetadata = (contentId: string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/copyright/getCopyrightFields?contentId='+contentId);
    }

    // 获取弹出框元数据
    export const getModalMetadataMult = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/copyright/getCopyrightFields');
    }

    // 更新元数据
    export const updateMetadata = (value: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/copyright/update',value);
    }

    // 批量更新元数据
    export const updateMetadataMult = (value: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/copyright/batchUpdate',value);
    }
}

export default copyrightApi