import BaseStore from "../base/baseStore";
import { action, observable, runInAction } from "mobx";
import { Stores } from "../index";
import _ from 'lodash';
import { ContentBoxItemType } from 'src/pages/cc/modules/contentBox/contentBox';
import entityTypes from "src/types/entityTypes";
import recycleTypes from 'src/types/recycleTypes';
import mmsApi from "src/apis/mmsApi"

export interface IBindBCProgramCodeModel {
    selectedChannel: any
    selectedColumn: any;
    selectedProgram: any;
    versionName: any;
    programCode: any;
    selectedHDFlag: any;
    selectedProgramCode: any;
}

export default class BindBCProgramCodeStore extends BaseStore<Stores> {
    @observable
    public visible: boolean = false;
    @observable
    public items: any[];
    @observable
    public selectedEntity: ContentBoxItemType | entityTypes.IEntity | entityTypes.ILinkedEntity | recycleTypes.IQueryRecycleResponse;

    @observable
    public programCodes: any[] = [];

    @observable
    public bindBCProgramCodeModel: IBindBCProgramCodeModel = {
        selectedChannel: '',
        selectedColumn: '',
        selectedProgram: undefined,
        versionName: '',
        programCode: '',
        selectedHDFlag: '',
        selectedProgramCode: ''
    };
  
    @action
    public setShowModal(visible: boolean) {
        this.visible = visible;
        if (!visible) { this.programCodes = []; }
    }

    @action
    public setSelectedColumn(selectedColumn: any) {
        this.bindBCProgramCodeModel.selectedColumn = selectedColumn;
    }

    @action
    public setSelectedProgram(selectedProgram: any) {
        this.bindBCProgramCodeModel.selectedProgram = selectedProgram;
    }

    @action
    public setVersionName(versionName: any) {
        this.bindBCProgramCodeModel.versionName = versionName;
    }

    @action
    public setProgramCode(programCode: any) {
        this.bindBCProgramCodeModel.programCode = programCode;
    }

    @action
    public setSelectedHDFlag(selectedHDFlag: any) {
        this.bindBCProgramCodeModel.selectedHDFlag = selectedHDFlag;
    }

    @action
    public setSelectedChannel(selectedChannel: any) {
        this.bindBCProgramCodeModel.selectedChannel = selectedChannel;
    }

    @action
    public setSelectedProgramCode(selectedProgramCode: any) {
        this.bindBCProgramCodeModel.selectedProgramCode = selectedProgramCode;
    }

    @action
    public setProgramCodeDatas(programCodes: any[]) {
        this.programCodes = programCodes;
    }

    @action
    public init(item: ContentBoxItemType | entityTypes.IEntity | entityTypes.ILinkedEntity | recycleTypes.IQueryRecycleResponse) {
        this.selectedEntity = item;
        this.bindBCProgramCodeModel.selectedColumn = '';
        this.bindBCProgramCodeModel.selectedProgram = undefined;
        this.bindBCProgramCodeModel.versionName = '';
        this.bindBCProgramCodeModel.programCode = '';
        this.bindBCProgramCodeModel.selectedHDFlag = '';
        this.bindBCProgramCodeModel.selectedProgramCode = '';
    }

}
