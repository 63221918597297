import { http } from "../utils/http";
import { AxiosPromise } from "axios";
import emptyEntityTypes from 'src/types/emptyEntityTypes';
import { IBasePageResponse } from 'src/types/commonTypes';
import {IResponse} from "mam-core-react/dist/modules/http";
import { stores } from 'src/stores';

namespace emptyEntityApi {
    // 節目
    export const getProgram = (code: string): AxiosPromise<IResponse<emptyEntityTypes.IProgramInfo[]>> => {
        return http.get<IResponse<emptyEntityTypes.IProgramInfo[]>>(`/ww_custom/program/${code}`);
    }

    export const getProgramFields = (code: string): AxiosPromise<IResponse<emptyEntityTypes.IProgramField[]>> => {
        return http.get<IResponse<emptyEntityTypes.IProgramField[]>>(`/ww_custom/program/${code || 'empty'}/fields`);
    }

    export const createProgram = (code: string | number, programFields: emptyEntityTypes.IProgramField[]): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>(`/ww_custom/program/${code}`, programFields);
    }

    export const updateProgramFields = (code: string | number, programFields: emptyEntityTypes.IProgramField[]): AxiosPromise<IResponse<any>> => {
        return http.put<IResponse<any>>(`/ww_custom/program/${code}`, programFields);
    }


    // 空素材
    export const getEmptyEntityFields = (): AxiosPromise<IResponse<emptyEntityTypes.IProgramField[]>> => {
        return http.get<IResponse<emptyEntityTypes.IProgramField[]>>(`/ww_custom/empty-material/fields`);
    }

    export const createEmptyEntity = (fields: emptyEntityTypes.IProgramField[]): AxiosPromise<IResponse<emptyEntityTypes.IProgramField[]>> => {
        return http.post<IResponse<emptyEntityTypes.IProgramField[]>>(`/ww_custom/empty-material`, fields);
    }

    export const getEmptyEntity = (code: string): AxiosPromise<IResponse<emptyEntityTypes.IEmptyEnityInfo[]>> => {
        return http.get<IResponse<emptyEntityTypes.IEmptyEnityInfo[]>>(`/ww_custom/empty-material?tapeno=${code}`);
    }

    // 上傳
    export const uploadInit = (dto: emptyEntityTypes.IEmptyEntityUploadTask): AxiosPromise<IResponse<emptyEntityTypes.IEmptyEntityUploadTask>> => {
        return http.post<IResponse<emptyEntityTypes.IEmptyEntityUploadTask>>(`/ww_custom/empty-material/file/bind`, dto);
    }
    export const upload = (dto: FormData): AxiosPromise<IResponse<emptyEntityTypes.IEmptyEntityFileUploadDto>> => {
        return http.post<IResponse<emptyEntityTypes.IEmptyEntityFileUploadDto>>(`/ww_custom/empty-material/file/upload`, dto);
    }
    export const uploadComplete = (contentId: string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/ww_custom/empty-material/file/upload/complete?taskId=${contentId}`);
    }

    // 节目管理
    export const queryProgram = (queryUrl: string): AxiosPromise<IResponse<IBasePageResponse<emptyEntityTypes.IWwProgramModel>>> => {
        let reqUrl = '/ww_custom/program' + queryUrl
        return http.get<IResponse<IBasePageResponse<emptyEntityTypes.IWwProgramModel>>>(reqUrl);
    }

    export const deleteProgram = (codes: string[]): AxiosPromise<IResponse<any>> => {
        let reqUrl = '/ww_custom/program'
        codes.forEach((code, index) => {
            if (index === 0) {
                reqUrl += `?`
            } else {
                reqUrl += `&`
            }
            reqUrl += `codes=${code}`
        })
        return http.delete(reqUrl);
    }
    /** 导出excel */
    export const exportProgram = (queryUrl: string) => {
        let reqUrl = '/ww_custom/program/export' + queryUrl
        
        let token = ''
        if (stores.userStore.currentUser) {
            token = stores.userStore.currentUser.userToken
        }
        let timestamp = new Date().getTime();
        let xmlResquest = new XMLHttpRequest();
        xmlResquest.open("GET", process.env.DEV_SERVER + `${reqUrl}&token=${token}`, true);
        xmlResquest.setRequestHeader("Content-type", "application/json");
        xmlResquest.responseType = "blob";
        xmlResquest.onload = () => {
            let content = xmlResquest.response;
            let elink = document.createElement('a');
            elink.download = timestamp + ".xls";
            elink.style.display = 'none';
            let blob = new Blob([content]);
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
        };
        xmlResquest.send();
    }
}

export default emptyEntityApi;
