import ExtendStore from '../../base/extendStore';
import { action, observable, runInAction } from 'mobx';
import _ from 'lodash'
import { message } from 'antd';
import { Stores } from 'src/stores'
import PersonalCenterStore from '../personalCenterStore'
import personalCenterTypes from 'src/types/personalCenterTypes'

export default class CollectStore extends ExtendStore {
    public personalCenterStore: PersonalCenterStore
    @observable
    public collectNav:personalCenterTypes.ICollectNav[] = [
        {
            key: '1',
            value: '默认文件夹',
            count: 9
        },
        {
            key: '2',
            value: '深圳',
            count: 12
        },
        {
            key: '3',
            value: '自用',
            count: 2
        },

    ]
    @observable
    public defaultSelectedKeys = '1'
    @observable
    public defaultDataInfo: any;
    @observable
    public chooseNav: string = 'last'
    constructor(stores: Stores, personalCenterStore: PersonalCenterStore) {
        super(stores)
        this.personalCenterStore = personalCenterStore
    }
    @action
    public setDefaultSelectedKeys(key: string) {
        this.defaultSelectedKeys = key
        //最后不要，暂时只是为了展示
        let obj = _.find(this.collectNav, {key})
        this.setDefaultDataInfo(obj)
    }
    @action
    public addCollectNav(obj: personalCenterTypes.ICollectNav) {
        this.collectNav.push(obj)
    }
    @action
    public setChooseNav(key:string) {
        this.chooseNav = key
    }

    @action
    public setDefaultDataInfo(obj: any) {
        this.defaultDataInfo = obj
    }
}