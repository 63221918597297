import ExtendStore from "../base/extendStore"
import { action, observable, runInAction } from "mobx"
import indexTypes from "../../types/indexTypes"
import indexApi from "../../apis/indexApi"
import { Stores } from "../index"

export default class IndexStore extends ExtendStore {
    @observable
    public addModalVisible: boolean = false
    @observable
    public keyword: string = ''
    //热门关键词
    @observable
    public hotSearchWord: indexTypes.IHotSearchWordResItem[]
    //热门关键词
    @observable
    public hotWord: indexTypes.IHotWordResItem[]
    //热门检索：包含最新入库节目latestImport、热门浏览hotBroswer、热门收藏hotCollection、热门使用hotExport
    @observable
    public hotRank: indexTypes.IHotRankRes
    @observable
    public hotEvent: indexTypes.IHotEventResItem[]
    @observable
    public hotHistory: indexTypes.IHotHistoryResItem[]
    @observable
    public hotOrg: indexTypes.IHotOrgResItem[]
    @observable
    public hotPeople: indexTypes.IHotPeopleResItem[]
    @observable
    public hotNews: indexTypes.IHotNewsResStoryItem[][]
    @observable
    public hotColumn: indexTypes.IHotColumnResItem[]
    @observable
    public hotScene: indexTypes.IHotSceneResItem[]
    @observable
    public hotLike: indexTypes.IHotLikeResItem[]

    constructor(stores: Stores) {
        super(stores)
        this.initDataViewModeStore(stores)
    }

    @action
    public setKeyword(keyword:string){
        this.keyword = keyword
    }
    //获取检索词
    @action
    public getHotSearchWord(top:number){
        return new Promise((resolve, reject) => {
            indexApi.getHotSearchWord(top).then((res) => {
                runInAction(() => {
                    if (res && res.data) {
                        if(res.data.success && res.data.data){
                            let hotSearchWord = JSON.parse(JSON.stringify(res.data.data))
                            this.hotSearchWord  = hotSearchWord 
                            //console.log(this.hotWord)
                        }else{
                            console.log(res.data.data)
                        }
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    //获取热门关键词
    @action
    public getHotWord(top:number,day:number){
        return new Promise((resolve, reject) => {
            indexApi.getHotWord(top,day).then((res) => {
                runInAction(() => {
                    if (res && res.data) {
                        if(res.data.success && res.data.data){
                            let hotWord = JSON.parse(JSON.stringify(res.data.data))
                            this.hotWord = hotWord
                            //console.log(this.hotWord)
                        }else{
                            console.log(res.data.data)
                        }
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    //获取热门排行
    @action
    public getHotRank(top:number){
        return new Promise((resolve, reject) => {
            indexApi.getHotRank(top).then((res) => {
                runInAction(() => {
                    if (res && res.data) {
                        if(res.data.success && res.data.data){
                            let hotRank = JSON.parse(JSON.stringify(res.data.data))
                            this.hotRank = hotRank
                        }else{
                            console.log(res.data.data)
                        }
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    //获取热门事件
    @action
    public getHotEvent(){
        return new Promise((resolve, reject) => {
            indexApi.getHotEvent().then((res) => {
                runInAction(() => {
                    if (res && res.data) {
                        if(res.data.success && res.data.data){
                            let hotEvent = JSON.parse(JSON.stringify(res.data.data))
                            this.hotEvent = hotEvent
                        }else{
                            console.log(res.data.data)
                        }
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    //获取历史上的今天
    @action
    public getHotHistory(top:number){
        return new Promise((resolve, reject) => {
            indexApi.getHotHistory(top).then((res) => {
                runInAction(() => {
                    if (res && res.data) {
                        if(res.data.success && res.data.data){
                            let hotHistory = JSON.parse(JSON.stringify(res.data.data))
                            this.hotHistory = hotHistory
                        }else{
                            console.log(res.data.data)
                        }
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    //获取热门机构
    @action
    public getHotOrg(top:number){
        return new Promise((resolve, reject) => {
            indexApi.getHotOrg(top).then((res) => {
                runInAction(() => {
                    if (res && res.data) {
                        if(res.data.success && res.data.data){
                            let hotOrg = JSON.parse(JSON.stringify(res.data.data))
                            this.hotOrg = hotOrg
                        }else{
                            console.log(res.data.data)
                        }
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    //获取热门新闻人物
    @action
    public getHotNews(day:number){
        return new Promise((resolve, reject) => {
            indexApi.getHotNews(day).then((res) => {
                runInAction(() => {
                    if (res && res.data) {
                        if(res.data.success && res.data.data){
                            let hotNews = JSON.parse(JSON.stringify(res.data.data))
                            this.hotNews = hotNews
                        }else{
                            console.log(res.data.data)
                        }
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    //获取热门人物
    @action
    public getHotPeople(top:number,day:number){
        return new Promise((resolve, reject) => {
            indexApi.getHotPeople(top,day).then((res) => {
                runInAction(() => {
                    if (res && res.data) {
                        if(res.data.success && res.data.data){
                            let hotPeople = JSON.parse(JSON.stringify(res.data.data))
                            this.hotPeople = hotPeople
                        }else{
                            console.log(res.data.data)
                        }
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    //获取热门栏目
    @action
    public getHotColumn(top:number,day:number){
        return new Promise((resolve, reject) => {
            indexApi.getHotColumn(top,day).then((res) => {
                runInAction(() => {
                    if (res && res.data) {
                        if(res.data.success && res.data.data){
                            let hotColumn = JSON.parse(JSON.stringify(res.data.data))
                            this.hotColumn = hotColumn
                        }else{
                            console.log(res.data.data)
                        }
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    //获取热门场景
    @action
    public getHotScene(top:number,day:number){
        return new Promise((resolve, reject) => {
            indexApi.getHotScene(top,day).then((res) => {
                runInAction(() => {
                    if (res && res.data) {
                        if(res.data.success && res.data.data){
                            let hotScene = JSON.parse(JSON.stringify(res.data.data))
                            this.hotScene = hotScene
                        }else{
                            console.log(res.data.data)
                        }
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    //获取猜你喜欢hotLike
    @action
    public getHotLike(top:number){
        return new Promise((resolve, reject) => {
            indexApi.getHotLike(top).then((res) => {
                runInAction(() => {
                    if (res && res.data) {
                        if(res.data.success && res.data.data){
                            let hotLike = JSON.parse(JSON.stringify(res.data.data))
                            this.hotLike = hotLike
                        }else{
                            console.log(res.data.data)
                        }
                        resolve()
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    
    
}
