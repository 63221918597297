import ExtendStore from '../../base/extendStore';
import { action, observable, runInAction } from 'mobx';
import _ from 'lodash'
import { message } from 'antd';
import { Stores } from 'src/stores'
import PersonalCenterStore from '../personalCenterStore'
import personalCenterTypes from 'src/types/personalCenterTypes'

export default class ContributeStore extends ExtendStore {
    public personalCenterStore: PersonalCenterStore
    @observable
    public currentOrder: string = 'week'

    @observable
    public appList:any[] = []

    @observable
    public currentWeekNav:string = 'all'

    @observable
    public graphData = {
        'shenzhen': {arr: [10,30,40,23,78,54,21], title: '壹深圳'},
        'douyin': {arr: [87,32,21,43,65,32,56], title: '抖音'},
        'blog': {arr: [43,76,45,23,32,76,54], title: '微博'},
        'nimble-handed': {arr: [23,32,54,68,54,78,54], title: '快手'},
        'volcano': {arr: [23,45,65,78,34,67,43], title: '火山'},
        'netease': {arr: [45,65,34,65,34,67,14], title: '网易'}
    }
    @observable
    public trendNav = 'browse'
    @observable
    public defaultTime = {
        startTime: '2020-05-01',
        endTime: '2020-06-01'
    }
    @observable
    public trendData = {
        time: ['05/01','05/02','05/03','05/04','05/05','05/06','05/07','05/08','05/09','05/10','05/11','05/12','05/13','05/14','05/15'],
        arr: [21,32,54,74,32,65,85,54,42,21,53,87,76,45,76]
    } 

    @observable
    public statisticCreateArr: personalCenterTypes.IStatisticArr[] = [{value: 123, name: '社会'},{value: 87, name: '教育'},{value: 53, name: '文化'}, {value: 23, name: '交通'}, {value: 14, name: '经济'}]
    @observable
    public statisticPublishArr: personalCenterTypes.IStatisticArr[] = [{value: 123, name: '壹深圳'},{value: 87, name: '抖音'},{value: 53, name: '快手'}, {value: 23, name: '今日头条'}, {value: 14, name: '直新闻'}]

    //表格
    @observable
    public defaultNavSelect:string = 'TV'
    @observable
    public defaultSelectValue:string = 'time'
    @observable
    public tableDataInfo: personalCenterTypes.ITableRes= {
        pageIndex: 1,
        pageTotal: 1,
        pageSize: 10,
        recordTotal: 2,
        data: [
            {
                key: '4321342',
                name: '特区不特？深圳破局特不特？深圳破局',
                createTime: '2020-11-23 19:23:45',
                column: '正午30分',
                use: '426',
                audience: 0.8912,
                clickNumber: 12254
            },
            {
                key: '432134222',
                name: '特区不特？深圳破局特不特？深圳破局',
                createTime: '2020-11-23 19:23:45',
                column: '正午30分',
                use: '42622',
                audience: 0.8912,
                clickNumber: 1225411
            }
        ]
    }
    constructor(stores: Stores, personalCenterStore: PersonalCenterStore) {
        super(stores)
        this.personalCenterStore = personalCenterStore
        this.setAppList([{key: 'shenzhen', title: '壹深圳'}, {key: 'douyin', title: '抖音'},{key: 'blog', title: '微博'},{key: 'nimble-handed', title: '快手'},{key: 'volcano', title: '火山'},{key: 'netease', title: '网易'}])
    }
    @action
    public setCurrentOrder(value: string) {
        this.currentOrder = value
    }
    @action
    public setAppList(arr: any[]) {
        this.appList = arr
    }
    @action
    public setCurrentWeekNav(value:string) {
        this.currentWeekNav = value
    }
    @action
    public setTrendNav(value: string) {
        this.trendNav = value
    }
    @action
    public setDefaultTime(arr: string[]) {
        this.defaultTime = {
            startTime: arr[0],
            endTime: arr[1]
        }
    }
    //表格
    @action
    public setDefaultSelectValue(value:string) {
        this.defaultSelectValue = value
    }
    @action
    public setDefaultNavSelect(value:string) {
        this.defaultNavSelect = value
    }
}
