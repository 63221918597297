import ExtendStore from "../base/extendStore";
import {action, observable, runInAction} from "mobx";
import shareApi from "../../apis/shareApi";
import shareTypes from "../../types/shareTypes";
import {Stores} from "../index";
import _ from 'lodash';
import ccTypes from 'src/types/ccTypes';
import {l} from "mam-core-react";

export default class ShareStore extends ExtendStore{
    @observable
    public params: shareTypes.IParams;
    /** 站点下拉框 */
    @observable
    public sites: shareTypes.ISite[];
    /** 已选站点 */
    @observable
    public selectedSite: shareTypes.ISite;
    /** 排序下拉框 */
    @observable
    public sorts: ccTypes.searchTypes.ISortItem[];
    /** 已选排序方式 */
    @observable
    public selectedSort: ccTypes.searchTypes.ISortItem;
    @observable
    public shareList?: shareTypes.IShareList;
    /** 收到的或是发出的 */
    @observable
    public type: number;

    @observable
    public keywords:string = ''

    @observable
    public isShowSource:boolean = false

    constructor(stores: Stores){
        super(stores);
        this.initDataViewModeStore(stores);
        this.initSelectAllStore(stores, 'stores.shareStore.shareList.data', 'selected');
        this.initCopyStore(stores);
    }

    @action
    public emptyShareList(){
        this.shareList = undefined;
    }

    @action
    public getSites(){
        return new Promise((resolve, reject)=>{
            shareApi.getSites().then((res)=>{
                runInAction(()=>{
                    if (res.data.success && res.data.data)
                    {
                        this.sites = res.data.data;

                        this.sites.push({ siteName: l('share.allSite', '全部站点'), siteCode: '' });
                        if (this.stores.configStore.config.share !== undefined && this.stores.configStore.config.share.shieldSiteS1) {
                            let cSite = _.find(this.sites, { isCurrent: true });
                            if (cSite !== undefined && cSite.siteCode !== 'S1')
                            {
                                _.remove(this.sites, { siteCode: 'S1' });
                            }
                        }
                        let selectedItem = _.find(this.sites, { siteCode: this.params.siteselect });
                        if (selectedItem)
                        {
                            this.setSelectedSite(selectedItem);
                        }

                        resolve(this.sites);
                    }
                })
            })
        });
    }

    @action
    public initSorts(){
        this.sorts = [];
        this.sorts.push({ field: 'ctime', desc: 'desc',text: l('share.timeDesc', '时间降序') });
        this.sorts.push({ field: 'ctime', desc: 'asc',text: l('share.timeAsc', '时间升序') });
        if (this.type === 1) {
            this.sorts.push({ field: 'username', desc: 'desc',text: l('share.userNameDesc', '分享者降序') });
            this.sorts.push({ field: 'username', desc: 'asc',text: l('share.userNameAsc', '分享者升序') });
        }
        this.selectedSort = this.sorts[0];
    }

    @action
    public getMyReceive(page: number, keyword?: string){
        let req: shareTypes.IShareListReq = {
            pageIndex: page || 1,
            pageSize: 24,
            'sort': this.selectedSort && this.selectedSort.field ? this.selectedSort.field : 'ctime',
            'order': this.selectedSort && this.selectedSort.desc ? this.selectedSort.desc : 'desc',
            'keyword': keyword || this.keywords || '',
            'siteSelect': this.selectedSite && this.selectedSite.siteCode ? this.selectedSite.siteCode : ''
        }
        shareApi.getMyReceive(req).then((res)=>{
            runInAction(()=>{
                if (res.data.success && res.data.data)
                {
                    this.shareList = res.data.data;
                    this.handleShareList();
                }
            });
        })
    }

    @action
    public getMySend(page: number, keyword?: string){
        let req: shareTypes.IShareListReq = {
            pageIndex: page || 1,
            pageSize: 24,
            'sort': this.selectedSort && this.selectedSort.field ? this.selectedSort.field : 'ctime',
            'order': this.selectedSort && this.selectedSort.desc ? this.selectedSort.desc : 'desc',
            'keyword': keyword || this.keywords || ''
        }
        shareApi.getMySend(req).then((res)=>{
            runInAction(()=>{
                if (res.data.success && res.data.data)
                {
                    this.shareList = res.data.data;
                    this.handleShareList();
                }
            });
        })
    }

    @action
    public setParams(params: shareTypes.IParams){
        this.params = params;
    }

    @action
    public setType(type: number){
        this.type = type;
    }

    @action
    public setSelectedSite(site: shareTypes.ISite){
        this.selectedSite = site;
    }

    @action
    public setSelectedSort(sort: ccTypes.searchTypes.ISortItem){
        this.selectedSort = sort;
    }

    @action
    public setKeywords(keywords:string){
        this.keywords = keywords
    }

    private handleShareList(){
        if (this.shareList && this.shareList.data)
        {
            this.shareList.data.forEach((item)=>{
                item.selected = false;
                item.siteName = this.uncodeSite(item.userSite);
                item.name = item.fileName;
                if (item.entityCount > 1)
                {
                    item.type = 'folder';
                }
            })
            const group = _.groupBy(this.shareList.data,'userSite')
            let i = 0
            // tslint:disable-next-line: forin
            for(let k in group){
                i++
            }
            if(i>1){
                this.isShowSource = true
            }
        }
    }

    private uncodeSite = (siteCode:string):string => {
        const site = _.find(this.sites,{siteCode})
        if(!site){
            return siteCode
        }
        if(site.isCurrent){
            return l('share.currentSite', '本站点')
        }
        return !site.siteName ? siteCode : site.siteName
    }
}