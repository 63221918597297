import {action, observable, runInAction} from "mobx";
import {IUser} from "../types/userTypes";
import BaseStore from "./base/baseStore";
import {Stores} from "./index";
import userApi from "src/apis/userApi";
import {IUserItem} from "mam-components-react/dist/modules/selectUser/selectUser";

/**
 * 用户相关Store
 */
export default class UserStore extends BaseStore<Stores> {
    @observable
    public currentUser?: IUser

    /** 获取所有用户 */
    @observable
    public users: IUserItem[] = [];


    @action
    public setCurrentUser(user?: IUser): void {
        this.currentUser = user;
        (window as any).nxt.user = {
            current: user
        }
    }

    @action
    public getUsersData(keyword:string,pageSize:number) {
        userApi.getUsersData(keyword,pageSize).then(res => {
            runInAction(()=>{
                if(res.data.data && res.data.data.data){
                    this.users = res.data.data.data
                }
            })
        })
    }
}
