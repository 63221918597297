import ExtendStore from "../../../base/extendStore"
import { observable, action, runInAction } from 'mobx'
import ContentMgStore from '../../contentMgStore'
import { Stores } from 'src/stores'
import recycleBinApi from 'src/apis/recycleBinApi'
import recycleBinTypes from 'src/types/recycleBinTypes'
import _ from 'lodash'
import { message } from 'antd'

export default class RecycleBinStore extends ExtendStore {
    @observable 
    public order:string//时间升序降序
    @observable 
    public keyword:string//搜索
    @observable 
    public checkedData:recycleBinTypes.IGetRecycleBinList[]=[]//选中的数组
    @observable 
    public type:boolean = false//模式
    @observable
    public recycleBinListResponse:  recycleBinTypes.IGetRecycleBinListResponse |undefined
    public constructor(stores: Stores, contentMgStore: ContentMgStore) {
        super(stores)
    }
    @action
    public getRecycleBinList(page:number){
        let params={
            keyword: "",
            order: "desc",
            pageIndex: page,
            pageSize: 20,
            sort: "deleteDate",
            userCode: ""
        }
        if(this.order){
            params={...params,order:this.order}
        }
        if(this.keyword){
            params={...params,keyword:this.keyword}
        }
        this.recycleBinListResponse=undefined
        return new Promise((resolve, reject) => {
            recycleBinApi.getRecycleBinList(params).then(res => {
                runInAction(() => {
                    if(res.data.success && res.data.data) {
                        this.recycleBinListResponse=res.data.data
                        resolve(true)
                    } else {
                        reject(false)
                    }
                })
            }, res => {
                if(res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public changeType(type:boolean){
        this.type=type
    }
    @action
    public changeOrder(type:string){
        this.order=type
        this.getRecycleBinList(1)
    }
    @action
    public changeKeyword(keyword:string){
        this.keyword=keyword
        this.getRecycleBinList(1)
    }
    @action
    public changeCheckedData(checkedData:recycleBinTypes.IGetRecycleBinList[]){
        this.checkedData=checkedData
    }
}