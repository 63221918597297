import { IUserOrganization } from "./userTypes";
import { IBaseEntity } from './commonTypes';
import { SmartMetadataType } from "../stores/entity/extends/smartViewStore";
import SmartViewStore from "../stores/entity/extends/smartViewStore";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";

namespace entityTypes {
    export interface IEntityParam {
        contentId: string
        storyId?: string
        sceneId?: string
        shotId?: string
        site: string
        keyword?: string
        inpoint?: number
        outpoint?: number
        isCatalog?: string
    }

    export interface ISelectedTabObj {
        code: string
        name: string
    }

    export interface IPath {
        duration: number
        filePathPrefix: string
        bitRate: number
        fileGUID: string
        sourceFileGuid: string
        filePath: string
        frameRate: number
        size: number
        height: number
        width: number
        durationTc: string
        groupName: string
        imageHeight: number
        imageWidth: number
        bitRateType?: string,//'h'或'l'
    }

    export interface IKeyframe {
        fileGroup: string
        filePath: string
        filePathPrefix: string
        keyFrameNo: number
    }

    export interface IExtensionsEntityCount {
        readingNum: number
        shareNum: number
        downNum: number
        exportNum: number
    }

    export interface IExtensionsEntityOperateLink {
        entityCanDelete: boolean
        entityCanExcute: boolean
        entityCanDownload: boolean
        entityCanDownloadOriginalFile: boolean
    }

    export interface IExtensions {
        entityCount: IExtensionsEntityCount
        entityOperateLink: IExtensionsEntityOperateLink
        favorite: boolean
        canWrite: boolean
        canEdit: boolean
        entityStatus: string
        selector: string
        canDelete: boolean
    }

    export interface IGroupInfoFileItem {
        entityName: string
        fileName: string
        filePath: string
        fileHttpPath: string
        downLoadPath: string
        fileSize: number
        bitRate: number
        fileGuid: string
        groupTypeName?: string
        audioFormat?: string
        videoFormat?: string
        showPath: string
        relativePath: string
    }

    export interface IGroupInfo {
        groupName: string
        groupType: string
        bitRate: number
        isArchive: boolean
        showBitRate: string
        fileItems: IGroupInfoFileItem[]
    }

    export interface IFileGroupSpecificSequence {
        fileGUID: string
        track: string
        mediaChannel: number
        fileInPoint: number
        fileOutPoint: number
        refInPoint: number
        refOutPoint: number
    }

    export interface IFileGroupSpecific {
        inPoint: number
        outPoint: number
        frameRate: number
        bitRate: number
        sequences: IFileGroupSpecificSequence[]
        picCount: number
        fileOrders: any[]
    }

    export interface IFileGroupFileItemFileFormatVideoFormat {
        afd: number
        bitCount: number
        bitRate: number
        inPoint: number
        outPoint: number
        verifyCode: string
        viewCount: number
        mediaType: number
        pixelFormat: number
        scanMode: number
        bitsPerPixel: number
        compression: number
        dataRate: number
        fileFormat: string
        frameRate: number
        imageHeight: number
        imageWidth: number
        gopSize: number
        bFrameCount: number
        pFrameCount: number
        nleFormatId: string
        rotation: number
    }

    export interface IFileGroupFileItemFileFormatVideoFormatAudioFormat {
        inPoint: number
        outPoint: number
        verifyCode: string
        bitsPerSample: number
        channels: number
        fileFormat: number
        mediaType: number
        samplesRate: number
        audioStreamNum: number
        nleFormatId: string
        imageHeight: number
        imageWidth: number
    }
    export interface IFileGroupFileItemFileFormatKeyframeFormat {
        keyFrameNo: number
        iconKeyframe: number
        width: number
        height: number
        format: string
        fromFileGUID: string
        sourceGroupName: string
    }

    export interface IFileGroupFileItemFileFormat {
        videoFormat: IFileGroupFileItemFileFormatVideoFormat
        audioFormat: IFileGroupFileItemFileFormatVideoFormatAudioFormat
        keyFrameFormat: IFileGroupFileItemFileFormatKeyframeFormat
    }

    export interface IFileGroupFileItem {
        fileGUID: string
        fileLength: number
        fileSize: number
        fileState: string
        verifyCode: string
        fileCreateTime: string
        filePath: string
        extraData: string
        fileFormat: IFileGroupFileItemFileFormat
    }

    export interface IFileGroup {
        groupName: string
        groupType: string
        specific: IFileGroupSpecific
        fileItems: IFileGroupFileItem[]
        status: string
        keyFrames: any[]
        streamMedias: any[]
    }

    export interface IEntityScenes {
        guid?: string
        iconFrameNO?: number
        keyframePath?: string
        markDesc?: string
        /** 百纳秒 */
        markIn: number
        markKeyFrame?: string
        markOut: number
        isNew?: boolean
        duration?: number
        character?: string
        subtitle?: string
        location?: string
    }

    export enum CatalogState{
        UNCLAIMED = 0,
        CATALOG = 20,
        AUDIT = 40,
        COMPLETE = 3
    }

    export interface IEntityExtinfo{
        fileGuid: string
        exif: any
    }

    export interface IEntity extends IBaseEntity {
        contentId: string
        type: string
        entitydata: IFormItem[]
        programeType: string
        paths: IPath[]
        keyframe: IKeyframe
        markeyframes: IMarKeyframe[]
        tagedata: IFormItem[]
        frontcover: any[]
        scenes: IEntityScenes[]
        extensions: IExtensions
        groupInfo: IGroupInfo[]
        copyright: any[]
        rundown: any
        script: any
        fileGroups: IFileGroup[]
        smartdata: any
        extinfo: IEntityExtinfo[]
        techcensor: any
        entityName: string
        planDeleteTime: string
        catalogState?: CatalogState
        duration?: number
        durationTc?: string
    }

    export interface IRelatedMaterialReq {
        contentId: string
        pageIndex: number
        pageSize: number
        title: string,
        keyword: string
        type: string
        partInfo?: entityTypes.contentIndex.IMetaOriginal
    }

    export interface ISelectedTab {
        top: string
        bottom: string
    }

    export interface ISelectedTabObj {
        code: string
        name: string
    }

    export interface IUpdateEntityBasicInfo {
        contentId: string,
        entityType?: string,
        groupname: string,
        entityName: string,
        frameRate: number
    }
    export interface IUpdateEntityUpdateInfo {
        newValue: IFormItem[]
        oldValue: IFormItem[]
    }
    export interface IUpdateEntityReq {
        operateSite: string,
        operaType: string,
        basicInfo: IUpdateEntityBasicInfo,
        updateInfo: IUpdateEntityUpdateInfo
    }

    export interface IUpdateKeyframeReq {
        operateSite: string,
        operaType: number,
        basicInfo: IUpdateEntityBasicInfo,
        updateInfo: IMarKeyframe
    }

    export interface IDeleteKeyframeReq {
        isAllUpdate: boolean
        operateSite: string,
        operaType: number,
        basicInfo: IUpdateEntityBasicInfo,
        updateInfo: IMarKeyframe
    }
    /** keyFrameNo:秒(seconds) */
    export interface IMarKeyframe {
        isNew?: boolean
        filePath: string
        //秒
        inpoint: number
        keyframeNo: number
        description: string
        isIconFrame: boolean
        iconkeyframe?: number
        groupName?: string
        guid?: string
        width?: number
        height?: number
        frameRate?: number
    }

    export interface IUpdateTagReq {
        operateSite: string,
        operaType: string,
        basicInfo: IUpdateEntityBasicInfo,
        updateInfo: IUpdateEntityUpdateInfo
    }

    export interface IHistoryOperator {
        avatarUrl: string
        loginName: string
        nickName: string
        organizationName: string
        organizations: IUserOrganization[]
        siteCode: string
        userCode: string
    }
    export interface IHistory {
        content: string
        ip: string
        operator: IHistoryOperator
        other: string
        time: string
        type: number
        // 操作历史显示时间
        year?: string
        month?: string
        // 最后一个结束的年份
        endYear?: boolean
        // 显示年份title
        titleYear?: boolean
    }

    export interface IUpdateClipReq {
        operateSite: string,
        operaType: string,
        basicInfo: IUpdateEntityBasicInfo,
        updateInfo: IEntityScenes
    }

    export interface IDeleteClipReq {
        operateSite: string,
        operaType: string,
        basicInfo: IUpdateEntityBasicInfo,
        updateInfo: IEntityScenes
    }

    export interface ICatalogBaseData {
        title: string
        inPoint: number
        outPoint: number
        duration?: number
    }
    export interface ICatalogData {
        baseData: ICatalogBaseData
        itemData?: IFormItem[]
        fieldData?: IFormItem[]
        keyframePath: string
    }
    export interface ICatalog {
        alias: string
        code: string
        data?: ICatalogData[]
        layerFields?: ICatalogData[]
        fields: IFormItem[]
        guid: string
    }

    export interface ICatalogTreeItem extends entityTypes.ICatalogData {
        key?: string    //主键
        code?: string
        guid?: string
        level?: number   //树层级
        num?: number     //当前层第几个
        hasParent?: boolean
        children?: ICatalogTreeItem[]
        notesLength?: number
    }

    export interface IEditCatalog extends ICatalogData {
        isAdd: boolean
        guid: string
        code: string
    }

    export interface ICatalogDataCount {
        program: number
        sequence: number
        scene: number
        shot: number
    }

    export interface IUpdateCatalogUpdateInfo {
        guid: string,
        code: string,
        alias: string,
        updateInfo?: IFormItem[],
        data: IFormItem[][]
    }
    export interface IUpdateCatalogReq {
        operateSite?: string,
        operaType?: number,
        basicInfo: IUpdateEntityBasicInfo,
        updateInfo: IUpdateCatalogUpdateInfo
    }

    export interface IExternalsystemTemplateListTranscodeTemplate {
        transcodeTemplateID: string
        transcodeTemplateName: string
        multiTransTemplateName?: string
    }
    export interface IExternalsystemTemplate {
        enableExportMultiTranscodeTemplate: boolean
        externalSystemID: string
        externalSystemName: string
        listTranscodeTemplate: IExternalsystemTemplateListTranscodeTemplate[]
    }
    export interface IUploadCoverpageBasicInfo {
        contentId: string,
        entityName: string,
        entityType: string,
        groupname: string,
        frameRate?: number
    }
    export interface IUploadCoverpage {
        basicInfo: IUploadCoverpageBasicInfo,
        operaType: string,
        operaSite: string,
        updateInfo: {
            FileData: string,
            FileName: string,
            IsCustom: boolean
        }
    }
    export interface IUpdateCoverpage {
        basicInfo: IUploadCoverpageBasicInfo,
        operaType: string,
        operaSite: string,
        updateInfo: {
            filePath: string,
            filePathPrefix: string,
            guid: string,
            frontCoverType: number,
            keyFrameNO: number,
            isKeyframe: boolean,
        }
    }

    export interface IExportSectionReqExport {
        contentID: string
        entityName: string
        trimIn: number
        duration: number
        transcodeTemplateId: string
        entityType: string
        isChangeEntityName: boolean
    }
    export interface IExportSectionReq {
        externalSystemID: string
        exports: IExportSectionReqExport[],
        applyReason: string,
        auditUsers: any[]
    }
    // 视频素材片段另存为类型
    export interface ISaveSectionReq {
        contentID: string
        description: string
        duration: number
        entityName: string
        entityType: string
        keyWord: string
        savePosition: number
        targetFolderID: string
        transcodeTemplateId: string
        trimIn: number
        saveFields?: IFormItem[]
    }
    // 素材的转换模板类型
    export interface ITransCodeTemplateTemplate {
        supportType: string[]
        transcodeTemplateID: string
        transcodeTemplateName: string
    }

    export interface ILinkedEntity {
        contentId: string
        createTime: string
        createUser: string
        duration: number
        fileExt: string
        frameRate: number
        isFavorite: boolean
        keyframe: string
        name: string
        type: string
        site_?: string
    }

    export interface IScriptContent {
        content: string
    }

    export interface IKeyframeInfo {
        keyframePath: string
    }

    export interface ICut {
        /** 审片为百纳秒 */
        in: number
        out: number
        keyframe?: number
        timeSize: number
        name?: string
        left?: number
        top?: number
        width?: number
        height?: number
    }
    export interface IPersonFace extends ICut {
        filePath?: string
        isSensitivePerson?: boolean
    }
    export interface ISmartClip {
        keyframePath?: string
        guid: string,
        markDesc: string,
        markIn: number,
        markOut: number,
        frameSize: number,
        metadatasType: string
    }

    export interface IExportSectionModalRequest {
        img: string
        /** 秒 */
        trimout: number
        /** 秒 */
        trimin: number
        title: {
            name: string
            bcDate?: string
            maxLen: number
            minLen: number
        }
    }
    // 审片后台返回数据
    export interface IReviewReuslt {
        inPoint: number
        outPoint: number
        begin: number
        end: number
        analysisType: string
        metadataType: string
        description: string
        scenes: string
        label: string
        silentTracks: any[]
        keyframepath?: string
        confidence?: number
    }
    export interface IDescGroup{
        value: string
        label: string
    }

    /** 智能分析结果 */
    export namespace SmartInfo {
        export enum FaceViewType{
            KNOWN = 1,   //已知人脸
            UNKNOWN = 2    //未知人脸
        }
        export interface ISmartViewParentStore {
            params: entityTypes.IEntityParam
            entity: entityTypes.IEntity
            smartViewStore: SmartViewStore
            viewer: any
        }
        export interface IFacePosition {
            bottom: number
            frame: number
            left: number
            right: number
            top: number
        }
        /** 含出入点帧号 */
        export interface ISegment {
            confidence: number
            detail: IFacePosition[]
            in: number
            keyFrame: number
            out: number
            keyFrameUrl: string
            left: number
            top: number
            width: number
            height: number
        }
        export interface ISmartPersonMetadata {
            groups: { group?: string[] }
            guid_: string
            id: number
            name: string
            segment: ISegment[]
            type: number
            keyFrameUrl?: string
            personId: string
            videoWidth: number
            videoHeight: number
            isKnown: boolean
            facepath?: string
        }
        export interface ISmartVoiceMetadata {
            guid_: string
            confidence: string
            in: number
            out: number
            index: number
            text: string
            word: ISmartMetadatasMetadataWord[]
        }
        export interface ISmartSubtitleMetadata {
            confidence: string
            guid_: string
            in: number
            keyFrame: number
            out: number
            sensitiveResult: string[]
            text: string
        }


        /** 含出入点帧号 */
        export interface IViewSegment extends ICut {
            keyFrameUrl: string
            confidence: number
        }

        export interface ISmartPersonView {
            guid_: string
            personId: string
            name: string
            groups: {
                group?: string[]
            }
            /** 总时长 */
            totalTime: string
            /** 出镜率 */
            appearanceRate: number
            /** 敏感人物 */
            isSensitive: boolean
            segment: IViewSegment[]
            /** 描述信息 */
            desc?: {
                text: string
                tags: string[]
            }
            keyFrameUrl?: string
            facepath?: string
            videoWidth: number
            videoHeight: number
            isKnown: boolean
        }
        export interface ISmartCaptions extends ICut {
            id: string
        }

        export interface ISmartMetadatasMetadataWord {
            in: number
            out: number
            wordText: string
        }
        export interface ISmartMetadatasSubtitleMetadata {
            confidence: string
            guid_: string
            in: number
            keyFrame: number
            out: number
            text: string
            sensitiveResult: string[]
        }
        export interface ISmartMetadatasVoiceMetadata {
            confidence: string
            guid_: string
            in: number
            out: number
            text: string
            word: ISmartMetadatasMetadataWord[]
        }
        export interface ISmartMetadatas {
            [key: string]: {
                enable: boolean
                name: string
                type: string
                metadata: ISmartMetadatasSubtitleMetadata[] | ISmartMetadatasVoiceMetadata[]
            }
        }
        export interface IPatchUpdateIntelligentReq {
            contentId: string
            type: SmartMetadataType
            operaType: number
            metadatas: ISmartMetadatasVoiceMetadata[]
        }
    }

    export namespace contentIndex {
        export interface IMetaOriKeyframeListItem {
            keyframeno: number,
            keyframepath?: string
        }
        export interface IMetadata {
            name?: string
            type: string
            metadata: entityTypes.contentIndex.IMetaOriginal[]
        }
        export interface IMetadataSmartPerson{
            name?: string
            type: string
            metadata: entityTypes.SmartInfo.ISmartPersonMetadata[]
        }
        export interface IMetaOriginal {
            storyid: string
            keyframeno: number
            keyframePath?: string
            contentid?: string
            inpoint: number
            title?: string
            eventpeople?: string
            topic?: string
            eventdate?: string
            outpoint: number
            guid_: string
            eventorg?: string
            eventplace?: string
            keyword?: string
            keyword_hide?: string
            scenepeople?: string
            sceneid?: string
            scenename?: string
            behavior?: string
            video_category?: string
            top_topics?: string
            background?: string
            face?: string
            shotid?: string
            partType: SmartMetadataType
            emotionality?: number
            children?: IMetaOriginal[]
            childrenCount: number
            // segment:any
        }
        export interface IMetaInfo {
            id: string
            metadata: IFormItem[]
            type: SmartMetadataType
        }
        export interface ISmartEntityTimeline {
            inpoint: number
            outpoint: number
            source: number
        }
        export interface ISmartEntityEntitydata {
            ids: string
            name: string
            timeline: ISmartEntityTimeline[]
        }
        export interface ISmartEntity {
            entitydata: ISmartEntityEntitydata[]
            guid_: string
            type: string
        }
        export interface ITermDetail {
            baike_id: string,
            tag: string[],
            name: string,
            description: string,
            icon_path: string,
            keyframePath: string[],
            save_time: string
            title: string,
            level: any,
            update_time: string
        }
        export interface ISelectedTagDesc {
            description: string
        }
    }

    export namespace characterRecognitionTypes {
        export interface ICharacterRecognitionRequset {
            contentId: string
            type: string
            operaType: number
            metadata: {
                guid_?: string
                segment: ISegment[]
            }
            name: string
            groups: {
                group: string[]
            }
            imageData: string
        }

        interface ISegment {
            keyFrame: number
            in: number
            out: number
        }

        export interface ISmartMetadatasRequest {
            contentId: string
            type: SmartMetadataType
            operaType: number
            metadata: entityTypes.SmartInfo.ISmartMetadatasSubtitleMetadata | entityTypes.SmartInfo.ISmartMetadatasVoiceMetadata | entityTypes.contentIndex.IMetaOriginal
            isCatalogue: boolean
        }
    }

    export interface IAttachmentFileItemServerInfo {
        hostName: string
        password: string
        pathRoot: string
        port: number
        scheme: string
        userName: string
    }
    export interface IAttachmentFileItem {
        clientDownloadPath: string
        entityName: string
        fileGuid: string
        fileHttpPath: string
        fileName: string
        filePath: string
        fileSize: number
        isOSS: boolean
        serverInfo: IAttachmentFileItemServerInfo
        showPath: string
        webDownloadPath: string
    }
    export interface IAttachmentRes {
        fileItems: IAttachmentFileItem[]
        groupName: string
        groupType: string
        isArchive: boolean
        showBitRate: string
    }

    export interface IQLexiconReqCondition {
        field: string
        value: string
    }
    export interface IQLexiconSortBy {
        sortInput: string
        desc: boolean
    }
    export interface IQLexiconReq {
        pageIndex?: number
        pageSize?: number
        keyword: string
        sortBy: IQLexiconSortBy
        conditions: IQLexiconReqCondition[]
    }
    export interface IQLexiconDataEntryListItem {
        id: string
        keyword: string
        pinyin: string
        isManual: number
        createTime: string
        creator: string
        count: number
        type: number
        enable: number
    }
    export interface IQLexiconData {
        lexiconName: string
        entryList: IQLexiconDataEntryListItem[]
        isRed: boolean
    }
    export interface IQLexiconRes {
        pageIndex: number
        pageTotal: number
        pageSize: number
        recordTotal: number
        data: IQLexiconData[]
    }

    export interface IModifyUnknowFaceReq {
        contentId: string
        personId: string
        personName: string
        personTag: string
        personCategoryType: string
    }

    export interface IPersonCategory{
        typeCode: string
        typeName: string
    }

    export interface IAttachmentParam{ //附件预览的路由参数
        contentId: string  //素材id
        site: string
        fileGuid: string  //素材id下的附件id
    }
    
    export interface IRewriteFilegroupReq{
        contentIds: string[]
        filegroupName: string
    }

    export interface IReivewInfoColorRes{
        key: string
        color: string
    }

    export interface IScoreRecord{
        score: number
        contentId: string
        contentType: number
        entityType: string
        duration: number
        source: string
        contentSize: number
        contentName: string
        contentDepartment: string
        contentUserCode: string
        addUserCode: string
        addUserDepartment: string
        createdAt: string
        anonymous: boolean
        comment: string
        userName: string
    }
    export interface IScoreRecordRes{
        statistics: {
            average: string
            total: number
            people: number
            star: {
                oneStar: number
                twoStar: number
                threeStar: number
                fourStar: number
                fiveStar: number
            }
        },
        pageIndex: number
        pageTotal: number
        pageSize: number
        recordTotal: number
        data: IScoreRecord[]
    }
    export interface IScoreRecordReq{
        contentId: string
        isPage: boolean
        pageIndex: number
        pageSize: number
    }

    export interface IAddScoreRecordReq{
        score: number
        contentId: string
        anonymous: boolean
        comment: string
    }

    export interface ITrimListItem {
        trimIn: number 
        duration: number 
    }
    export interface IClipSaveReq {
        contentId: string 
        trimList: ITrimListItem[]
    }

    export interface IRelatedTopicsData {
        keyframepath_: string
        contentId: string,
        name: string,
        child?: any
    }
}
export default entityTypes
