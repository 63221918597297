import ExtendStore from "../base/extendStore";
import RundownStore from "../entity/extends/rundownStore";
import ReviewInfoStore from '../entity/extends/reviewInfoStore';
import PreviewImageStore from '../entity/extends/previewImageStore';
import CuttingsStore from '../entity/extends/cuttingsStore';
import ContentIndexStore from './extends/contentIndexStore';
import SmartViewStore from './extends/smartViewStore';
import MediaStore from './extends/mediaStore';
import CommentStore from 'src/stores/entity/extends/commentStore';
import IntelligentTimelineStore from './extends/intelligentTimeline/intelligentTimelineStore';
import SensitiveParts4PlayerStore from './extends/sensitiveParts4PlayerStore';

export default class EntityExtendStore extends ExtendStore {
    public mediaStore: MediaStore;
    public rundownStore: RundownStore;
    public smartViewStore: SmartViewStore;
    public contentIndexStore: ContentIndexStore;
    public reviewInfoStore: ReviewInfoStore;
    public previewImageStore: PreviewImageStore;
    public cuttingsStore: CuttingsStore;
    public commentStore: CommentStore;
    public intelligentTimelineStore: IntelligentTimelineStore;
    public sensitiveParts4PlayerStore: SensitiveParts4PlayerStore;

    public initMediaStore() {
        this.mediaStore = new MediaStore(this.stores, this as any);
    }

    public initRundownStore() {
        this.rundownStore = new RundownStore(this.stores);
    }

    public initSmartViewStore() {
        this.smartViewStore = new SmartViewStore(this.stores, this as any);
    }

    public initContentIndexStore() {
        this.contentIndexStore = new ContentIndexStore(this.stores, this as any);
    }

    public initReviewInfoStore() {
        this.reviewInfoStore = new ReviewInfoStore(this.stores);
    }

    public initPreviewImageStore(){
        this.previewImageStore = new PreviewImageStore(this.stores, this as any);
    }

    public initCuttingsStore() {
        this.cuttingsStore = new CuttingsStore(this.stores);
    }

    public initCommentStore(){
        this.commentStore = new CommentStore(this.stores, this as any)
    }

    public initIntelligentTimelineStore(){
        this.intelligentTimelineStore = new IntelligentTimelineStore(this.stores, this as any)
    }

    public initSensitiveParts4PlayerStore(){
        this.sensitiveParts4PlayerStore = new SensitiveParts4PlayerStore(this.stores, this as any)
    }
}
