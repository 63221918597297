import BaseStore from "../../base/baseStore";
import ccTypes from "../../../types/ccTypes";
import {action, observable} from "mobx";
import ccApi from "../../../apis/ccApi";
import {Stores} from "../../index";

export default class PublicEntityStore extends BaseStore<Stores>{
    @observable
    public publicTree: ccTypes.cloudTypes.IFullSearchData[] = []

    @observable
    public parent: ccTypes.cloudTypes.IFullSearchParent[]

    @observable
    public isFirstLevel: boolean = false

    @action
    public getPublicTree() {
        let param: ccTypes.sideContentTypes.IFolderReq = {
            pageIndex: 1,
            pageSize: 100,
            sort: this.stores.configStore.config.dohaUserSortSetting.sortField ?
                this.stores.configStore.config.dohaUserSortSetting.sortField : 'createTime',
            order: this.stores.configStore.config.dohaUserSortSetting.isDesc ? 'desc' : 'asc',
            folderCode: '',
            type: 'folder',
            keyword: '',
            name: ''
        }
        ccApi.sideContentApi.getPublicTree(param).then((res) => {
            if (res.data.success === true && res.data.data) {
                const isFirstLevel = res.data.data.parent ? res.data.data.parent.length === 1 : false
                this.setPublicTree(res.data.data.data, res.data.data.parent, isFirstLevel)
            }
        })
    }

    @action
    public setPublicTree(publicTree: ccTypes.cloudTypes.IFullSearchData[], parent: ccTypes.cloudTypes.IFullSearchParent[], isFirstLevel: boolean) {
        this.publicTree = publicTree
        this.parent = parent
        this.isFirstLevel = isFirstLevel
    }
}