import BaseStore from "../base/baseStore"
import { action, observable } from 'mobx'
import {Stores} from "../index";

export default class ShareStore extends BaseStore<Stores>{
    @observable
    public showShareModal:boolean = false

    @observable
    public contentIds:string[]

    @observable
    public arg:number[]
    
    @observable
    public closeType:number[] = []

    @action
    public setShowShareModal(show:boolean){
        this.showShareModal = show
    }

    @action
    public share(contentIds:string[],arg:number[],openType?:string){
        let closeType:number[] = []
        if(openType){
            if(openType.indexOf('mams') > -1){
                closeType = [3]
            }
            if (openType.indexOf('program') > -1){
                closeType.push(7)
            }
            if (openType === 'hasFolder') {
                closeType = [4, 7];
            }
        }
        this.closeType = closeType
        this.contentIds = contentIds
        this.arg = arg
        this.setShowShareModal(true)
    }
}