import ExtendStore from 'src/stores/base/extendStore';
import _ from 'lodash'
import IIndexStore from '../iIndexStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction, computed } from 'mobx';
import iIndexApi from 'src/apis/iIndexApi';
import { message } from 'antd';
import iIndexTypes from 'src/types/iIndexTypes';

export default class UserListStore extends ExtendStore {
    @observable
    public allChannelAndColumn: iIndexTypes.secList.IUserChannelAndColumn[] = [];
    @observable
    public channels: iIndexTypes.secList.IUserTabItem[] = []
    @observable
    public selectedChannel?: iIndexTypes.secList.IUserTabItem;
    @observable
    public selectedColumn?: iIndexTypes.secList.IUserTabItem;
    @observable
    public userList: iIndexTypes.secList.ISecUserListItem[] = []
    @observable
    public keyword: string = ''
    @observable
    public currType: string = 'create_num'
    @observable
    public isReq: boolean = false;
    
    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
    }

    @action
    public getUserChannelAndColumn(){
        return new Promise((resolve, reject)=>{
            iIndexApi.getUserChannelAndColumn().then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        this.allChannelAndColumn = res.data.data;
                        let newChannels: iIndexTypes.secList.IUserTabItem[] = [];
                        newChannels.push({
                            code: '',
                            name: '全部'
                        });
                        res.data.data.forEach((item)=>{
                            if (_.findIndex(newChannels, {code: item.channel_id}) === -1){
                                newChannels.push({
                                    code: item.channel_id,
                                    name: item.channel_name
                                });
                            }
                        })
                        this.channels = newChannels;
                        this.setSelectedChannel(this.channels[0]);
                        if (this.columns.length > 0){
                            this.setSelectedColumn(this.columns[0]);
                        }
                    }
                })
                resolve(true);
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @computed
    public get columns(): iIndexTypes.secList.IUserTabItem[]{
        if (!this.selectedChannel){
            return [];
        }
        else if (this.selectedChannel.code === ''){
            let newColumns: iIndexTypes.secList.IUserTabItem[] = [];
            newColumns.push({
                code: '',
                name: '全部'
            });
            this.allChannelAndColumn.forEach((item)=>{
                if (_.findIndex(newColumns, {code: item.column_id}) === -1 && item.column_id && item.column_name){
                    newColumns.push({
                        code: item.column_id,
                        name: item.column_name
                    });
                }
            })
            return newColumns;
        }
        else {
            console.log(11)
            return [{
                code: '',
                name: '全部'
            }].concat(this.allChannelAndColumn.filter((item)=>{
                return item.channel_id === this.selectedChannel?.code;
            }).filter((m) => {
                return m.column_id && m.column_name
            }).map((item)=>{
                return {
                    code: item.column_id,
                    name: item.column_name
                }
            }))
        }
    }

    @action
    public getUserList(){
        this.isReq = true;
        this.userList = [];
        iIndexApi.getSecUserList(this.selectedChannel?.code, this.selectedColumn?.code, this.currType, true, this.keyword).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.userList = res.data.data;
                }
                this.isReq = false;
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            runInAction(()=>{
                this.isReq = false;
            })
        });
    }

    @action
    public setSelectedChannel(channel: iIndexTypes.secList.IUserTabItem){
        this.selectedChannel = channel;
    }

    @action
    public setSelectedColumn(column: iIndexTypes.secList.IUserTabItem){
        this.selectedColumn = column;
    }
    @action
    public setKeyword(keyword: string){
        this.keyword = keyword;
    }
    @action
    public setCurrType(currType: string){
        this.currType = currType;
    }
}