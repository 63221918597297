import { action, observable, runInAction } from 'mobx';
import datasetDetailsApi from 'src/apis/datasetDetailsApi';
import { message } from 'antd'
import { Stores } from 'src/stores';
import datasetDetailsTypes from 'src/types/datasetDetailsTypes';
import DatasetDetailsStore from '../../datasetDetailsStore';
import ExtendStore from 'src/stores/base/extendStore';

export default class EntitysDataStore extends ExtendStore {
    @observable
    public datasetDetailsStore: DatasetDetailsStore
    @observable
    public entitysData: datasetDetailsTypes.IQueryEntitysRes;//右侧内容区域
    public constructor(stores: Stores, datasetDetailsStore: DatasetDetailsStore) {
        super(stores);
        this.datasetDetailsStore = datasetDetailsStore;
    }
    //查询右侧内容
    @action
    public queryEntitysData = (page: number) => {
        return new Promise((resolve, reject) => {
            if(this.entitysData&&this.entitysData.data.length){
                this.entitysData.data=[]
            }
            let params = {
                datasetId: this.datasetDetailsStore.contentId,
                folderId: this.datasetDetailsStore.folderId === this.datasetDetailsStore.contentId ? "" : this.datasetDetailsStore.folderId,
                keyword: "",
                order: "desc",
                pageIndex: page,
                pageSize: 60,
                sort: "add_time",
                type: this.datasetDetailsStore.filterEntityTypesChecked
            }
            if (this.datasetDetailsStore.selectSort) {
                params = { ...params, order:this.datasetDetailsStore.selectSort.order,sort: this.datasetDetailsStore.selectSort.sort}
            }
            if (this.datasetDetailsStore.keyword) {
                params.keyword = this.datasetDetailsStore.keyword
            }
            datasetDetailsApi.queryEntitys(params).then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        this.entitysData = res.data.data
                        if(!res.data.data.data.length){
                            resolve(true)
                        }
                    }
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
}