import ExtendStore from 'src/stores/base/extendStore';
import _ from 'lodash'
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import manageCenterApi from 'src/apis/manageCenterApi';
import { message } from 'antd';
import manageCenterTypes from 'src/types/manageCenterTypes';
import SearchTemplateStores from './searchTemplateStore';

export default class CreateEditTemplateStore extends ExtendStore {
    @observable
    public showModal: boolean = false;
    public id?: string;
    @observable
    public templateName: string = '';
    @observable
    public allFields: manageCenterTypes.search.ISearchTemplateFieldsItem[] = [];
    private searchTemplateStores: SearchTemplateStores;

    public constructor(stores: Stores, searchTemplateStores: SearchTemplateStores){
        super(stores)
        this.searchTemplateStores = searchTemplateStores;
    }

    @action
    public openModal(item?: manageCenterTypes.search.ISearchTemplateItem){
        this.id = item ? item.id : undefined;
        this.templateName = item ? item.templateName : '';
        this.showModal = true;
        this.getAllFields().then(()=>{
            runInAction(()=>{
                // 编辑模式还原conditions
                if (item){
                    const checkedValues: string[] = [];
                    item.templateSearchConditions.forEach(condition=>{
                        checkedValues.push(condition.fieldCode);
                        const targetField = _.find(this.allFields, {fieldCode: condition.fieldCode});
                        if (targetField){
                            targetField.nexus = condition.nexus;
                            targetField.values = condition.values;
                        }
                    })
                    this.checkFields(checkedValues);
                }
            })
        });
    }

    @action
    public closeModal(){
        this.showModal = false;
    }

    @action
    public getAllFields(){
        return new Promise((resolve, reject)=>{
            manageCenterApi.search.getSearchTemplateFields().then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        res.data.data.forEach(item=>{
                            item.selected = false;
                            item.nexus = 'eq'
                        })
                        this.allFields = res.data.data;
                    }
                    resolve(undefined);
                })
            }).catch(res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            })
        })
    }

    @action
    public checkFields(checkedValues: string[]){
        this.allFields.forEach(item=>{
            item.selected = false;
        })
        checkedValues.forEach(val=>{
            const field = _.find(this.allFields, {fieldCode: val});
            if (field){
                field.selected = true;
            }
        })
    }

    @action
    public setItemNexus(item: manageCenterTypes.search.ISearchTemplateFieldsItem, nexus: string){
        item.nexus = nexus;
    }

    @action
    public setItemValues(item: manageCenterTypes.search.ISearchTemplateFieldsItem, values?: string[]){
        item.values = values;
    }

    public setId(id?: string){
        this.id = id;
    }

    @action
    public setTemplateName(name: string){
        this.templateName = name;
    }
}