import ExtendStore from '../base/extendStore';
import { observable, action, runInAction } from 'mobx';
import { workteamDetailsTypes } from 'src/types/workteamDetailsTypes';
import workteamDetailsApi from 'src/apis/workteamDetails';
import { Stores } from '..';
import ccTypes from 'src/types/ccTypes';
import {l} from "mam-core-react";

export default class WorkteamDetailsStore extends ExtendStore {
    @observable
    public params: workteamDetailsTypes.IWorkteamDetailsParams

    @observable
    public fullSearchData?:workteamDetailsTypes.IWorkteamDetailsRes

    @observable
    public keywords:string = ''

    @observable
    public sortItems:ccTypes.searchTypes.ISortItem[] = [
        { text: l('workteam.sortCreateTimeDesc', '创建时间'), field: 'createDate_', desc: 'desc' },
        { text: l('workteam.sortCreateTimeAsc', '创建时间'), field: 'createDate_', desc: 'asc' },
        { text: l('workteam.sortNameDesc', '名称'), field: 'name_', desc: 'desc' },
        { text: l('workteam.sortNameAsc', '名称'), field: 'name_', desc: 'asc' }
    ]

    @observable
    public currentSort:ccTypes.searchTypes.ISortItem = this.sortItems[0]

    constructor(stores: Stores) {
        super(stores)
        this.initSelectAllStore(stores, 'stores.workteamDetailsStore.fullSearchData.data', 'selected');
        this.initDownloadStore(stores)
        this.initFavoriteStore(stores)
        this.initOutstoreStore(stores)
        this.initCreatePlanningStore(stores)
        this.initDataViewModeStore(stores)
        this.initCopyStore(stores)
    }

    @action
    public setParams(params: workteamDetailsTypes.IWorkteamDetailsParams) {
        this.params = params
    }

    @action 
    public queryEntity(){
        this.fullSearchData = undefined
        return new Promise((resolve, reject)=>{
            if(!this.params.groupCode){
                reject()
            }else{
                workteamDetailsApi.queryEntity(this.params).then(res=>{
                    runInAction(()=>{
                        if(res.data.success && res.data.data){
                            this.fullSearchData = res.data.data
                        }
                    })
                    resolve();
                },()=>{
                    reject();
                })
            }
        })
    }

    @action
    public setKeywords(keywords:string){
        this.keywords = keywords
    }

    @action
    public setCurrentSort(currentSort:ccTypes.searchTypes.ISortItem){
        this.currentSort = currentSort
        this.params.request.sort = this.currentSort.field
        this.params.request.order = this.currentSort.desc
    }
}