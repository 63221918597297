import BaseStore from "../../base/baseStore";
import {action, observable} from "mobx";
import {Stores} from "../../index";
import {default as catalogTypes} from "../../../types/catalogTypes";
import ICatalogStore from '../iCatalogStore';
import { SmartMetadataType } from 'src/stores/iEntity/extends/smartViewStore';
import iCatalogTypes from 'src/types/iCatalogTypes';

export default class CombinePartStore extends BaseStore<Stores> {
    /** 是否显示片段合并 */
    @observable
    public isShowCombinePartModal: boolean = false;
    /** 合并成什么类型 */
    @observable
    public combineMode: SmartMetadataType;
    /** 是否演播室 */
    @observable
    public checkTvStudioList: iCatalogTypes.ICheckTvStudioItem[] = [];

    private parentStore: ICatalogStore

    public constructor(stores: Stores, parentStore: ICatalogStore) {
        super(stores);
        this.parentStore = parentStore;
    }

    @action
    public openCombinePartModal(){
        this.isShowCombinePartModal = true;
        const checkedParts = this.parentStore.contentIndexStore.getCheckedParts();
        let currCheckedPartType;
        if (checkedParts && checkedParts.length > 0){
            currCheckedPartType = checkedParts[0].storyid ? SmartMetadataType.story : 
                (checkedParts[0].sceneid ? SmartMetadataType.scene : SmartMetadataType.shot);
            this.checkTvStudioList = [];
            this.setCombineMode(currCheckedPartType);
        }
    }

    @action
    public closeCombinePartModal() {
        this.isShowCombinePartModal = false;
    }

    @action
    public setCombineMode(combineMode: SmartMetadataType) {
        this.combineMode = combineMode;
        //合并成story，需要处理演播室
        if (this.combineMode === SmartMetadataType.story){
            const checkedParts = this.parentStore.contentIndexStore.getCheckedParts();
            if (checkedParts && checkedParts.length > 0){
                //story合成story
                if (checkedParts[0].storyid){
                    this.checkTvStudioList.push({
                        id: checkedParts[0].storyid,
                        keyframePath: checkedParts[0].keyframePath,
                        isChecked: false
                    })
                }
                //scene合成story
                if (checkedParts[0].sceneid){
                    const prev = this.parentStore.contentIndexStore.getPrev(checkedParts[0])
                    const next = this.parentStore.contentIndexStore.getNext(checkedParts[checkedParts.length - 1])
                    if (prev === undefined && next !== undefined){//开头的几个，拆分为2个story
                        this.checkTvStudioList.push({
                            id: checkedParts[0].sceneid,
                            keyframePath: checkedParts[0].keyframePath,
                            isChecked: false
                        })
                        this.checkTvStudioList.push({
                            id: next.sceneid as string,
                            keyframePath: next.keyframePath,
                            isChecked: false
                        })
                    }
                    else if (prev !== undefined && next === undefined){//末尾的几个
                        const parent = this.parentStore.contentIndexStore.getParent(checkedParts[0]);
                        let allSceneChildren;
                        if (parent){
                            allSceneChildren = this.parentStore.contentIndexStore.getSceneByStory(parent);
                        }
                        if (allSceneChildren){
                            this.checkTvStudioList.push({
                                id: allSceneChildren[0].sceneid as string,
                                keyframePath: allSceneChildren[0].keyframePath,
                                isChecked: false
                            })
                            this.checkTvStudioList.push({
                                id: checkedParts[0].sceneid,
                                keyframePath: checkedParts[0].keyframePath,
                                isChecked: false
                            })
                        }
                    }
                    else if (prev !== undefined && next !== undefined) {//中间的几个
                        const parent = this.parentStore.contentIndexStore.getParent(checkedParts[0]);
                        let allSceneChildren;
                        if (parent){
                            allSceneChildren = this.parentStore.contentIndexStore.getSceneByStory(parent);
                        }
                        if (allSceneChildren){
                            this.checkTvStudioList.push({
                                id: allSceneChildren[0].sceneid as string,
                                keyframePath: allSceneChildren[0].keyframePath,
                                isChecked: false
                            })
                            this.checkTvStudioList.push({
                                id: checkedParts[0].sceneid,
                                keyframePath: checkedParts[0].keyframePath,
                                isChecked: false
                            })
                            this.checkTvStudioList.push({
                                id: next.sceneid as string,
                                keyframePath: next.keyframePath,
                                isChecked: false
                            })
                        }
                    }
                }
            }
        }
    }

    @action
    public setCheckTvStudioItemChecked(item: catalogTypes.ICheckTvStudioItem, check: boolean){
        item.isChecked = check;
    }
}