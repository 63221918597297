import { Stores } from 'src/stores';
import ExtendStore from 'src/stores/base/extendStore';
import IIndexStore from '../iIndexStore';
import { action, runInAction, observable } from 'mobx';
import iIndexApi from 'src/apis/iIndexApi';
import { message } from 'antd';
import _ from 'lodash'
import iIndexTypes from 'src/types/iIndexTypes';
import iSearchTypes from 'src/types/iSearchTypes';
import iSearchApi from 'src/apis/iSearchApi';

interface ISceneItem {
    count?: number
    showValue: string
    value: string | string[]
    keyframe?: string
}

export default class SceneDetailStore extends ExtendStore {
    @observable
    public currName: string = '';
    @observable
    public isReqList: boolean = false;
    @observable
    public fullSearchData?: iSearchTypes.IFullSearchDataRes;
    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
        this.initSelectAllStore(stores, 'stores.iIndexStore.sceneDetailStore.fullSearchData.data', 'selected');
        this.initDownloadStore(stores);
        this.initOutstoreStore(stores);
        this.initDeleteStore(stores);
    }

    @action
    public queryFullSearchData(page?: number, isAppend?: boolean): Promise<iSearchTypes.IFullSearchDataRes | undefined> {
        return new Promise((resolve, reject) => {
            this.selectAllStore.switchAllSelected(false);
            let param: iSearchTypes.IFullSearchDataReq =
            {
                pageIndex: page || 1,
                pageSize: 60,
                personId: "",
                keyword: [],
                facetConditions: [],
                conditions: [
                    {
                        field: "type_",
                        value: ["AI_Shot"]
                    },
                    {
                        field: "background",
                        value: [this.currName]
                    }
                ],
                sorts: [
                    {
                        field: "date_of_debut",
                        desc: true
                    }
                ]
            }
            this.isReqList = true
            iSearchApi.queryFullSearchData(param).then(res => {
                runInAction(() => {
                    if (res.data.data && res.data.data.data) {
                        res.data.data.data.forEach((item) => {
                            item.name = item.name_;
                            item.duration = item.duration_;
                            item.contentId = item.contentId_;
                            item.framerate = item.framerate_;
                        })
                        if (!res.data.data.aggrs) {
                            res.data.data.aggrs = []
                        }
                        if (!isAppend){
                            this.fullSearchData = res.data.data;
                        }
                        else if (this.fullSearchData && res.data.data && res.data.data.data.length > 0) {
                            this.fullSearchData.data = this.fullSearchData.data.concat(res.data.data.data);
                            this.fullSearchData.pageIndex = res.data.data.pageIndex;
                            this.fullSearchData.pageSize = res.data.data.pageSize;
                            this.fullSearchData.pageTotal = res.data.data.pageTotal; 
                            this.fullSearchData.recordTotal = res.data.data.recordTotal;
                        }
                    }
                    this.isReqList = false;
                    resolve(res.data.data);
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            })
        })
    }

    // 设置当前场景三级名称
    @action
    public setName(name: string) {
        this.currName = name;
    }
}