import { AxiosPromise } from 'axios'
import {http} from '../utils/http'
import {IConfigRes, IConfig} from "../types/configTypes";
import {IResponse} from "mam-core-react/dist/modules/http";
import { ILoginReq } from 'src/types/userTypes';
import smoisTypes from 'src/types/smoisTypes';

namespace smoisApi {
    // 因123接口返回数据格式不符合IResponse，所以接口定义暂时注释
    /* 1、文件上传 */
    // export let uploadFile = (atomType: string, form: any): AxiosPromise<IResponse<smoisTypes.IUploadFileRes>> => {
    //     return http.post<IResponse<smoisTypes.IUploadFileRes>>(`/smois/v1/intelligent/file?atom_type=${atomType}`, form, {headers:{hideLoading: true}})
    // }
    /* 2、添加分析任务 */
    // export let addTask = (atomType: string, req: smoisTypes.IAddTaskReq): AxiosPromise<IResponse<smoisTypes.IAddTaskRes>> => {
    //     return http.post<IResponse<smoisTypes.IAddTaskRes>>(`/smois/v1/intelligent/task?atom_type=${atomType}`, req, {headers:{hideLoading: true}})
    // }
    /* 3、查询任务 */
    // export let query = (atomType: string, taskId: string): AxiosPromise<IResponse<any>> => {
    //     return http.get<IResponse<any>>(`/smois/v1/intelligent/task?atom_type=${atomType}&task_id=${taskId}`, {headers:{hideLoading: true}})
    // }
    /* 知识库查询人物 */
    export let personGetQueryList = (req: smoisTypes.IQueryPersonReq): AxiosPromise<IResponse<smoisTypes.IQueryPersonRes>> => {
        return http.post<IResponse<smoisTypes.IQueryPersonRes>>('/kb/v1/entity/full-search', req, {headers:{hideLoading: true}})
    }
    /* 获取人物类型 */
    export let getTypeOptions = (): AxiosPromise<IResponse<smoisTypes.ITypeOptionsItem[]>> => {
        return http.get<IResponse<smoisTypes.ITypeOptionsItem[]>>('/kb/v1/entity/people/categorys', {headers:{hideLoading: true}})
    }
    /* 获取区域树  */
    export let getAreaTree = (): AxiosPromise<IResponse<string>> => {
        return http.get<IResponse<string>>('/kb/v1/entity/area', {headers:{hideLoading: true}})
    }

}

export default smoisApi
