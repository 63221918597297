import IFormItem from "mam-metadata-react/dist/types/IFormItem";

export const defaultMetaField: IFormItem[] = [
    {
        alias: "标题",
        controlType: 5,
        fieldName: "scenename",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "标题",
        controlType: 5,
        fieldName: "title",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "分类",
        controlType: 12,
        fieldName: "topic",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "人物",
        controlType: 12,
        fieldName: "eventpeople",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "人物",
        controlType: 12,
        fieldName: "scenepeople",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "日期",
        controlType: 12,
        fieldName: "eventdate",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "日期",
        controlType: 12,
        fieldName: "scenedate",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "机构",
        controlType: 12,
        fieldName: "eventorg",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "机构",
        controlType: 12,
        fieldName: "sceneorg",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "地点",
        controlType: 12,
        fieldName: "eventplace",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "地点",
        controlType: 12,
        fieldName: "sceneplace",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "关键词",
        controlType: 12,
        fieldName: "keyword",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "行为",
        controlType: 12,
        fieldName: "behavior",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "背景",
        controlType: 12,
        fieldName: "background",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    },
    {
        alias: "摘要",
        controlType: 6,
        fieldName: "summarization",
        fieldPath: "",
        isArray: false,
        isMultiSelect: false,
        isMustInput: false,
        isReadOnly: false,
        isShow: true,
        maxLength: 0,
        minLength: 0,
        value: ""
    }
]