import {action} from "mobx";
import CatalogBaseStore from "../base/catalogBaseStore/catalogBaseStore";
import {Stores} from "../../index";
import EntityStore from "../entityStore";
import IEntityStore from 'src/stores/iEntity/iEntityStore';

/** 内容结构 */
export default class ContentIndexStore extends CatalogBaseStore {
    private entityStore: EntityStore | IEntityStore
    public constructor(stores: Stores, entityStore: EntityStore | IEntityStore) {
        super(stores);
        this.entityStore = entityStore;
    }

    @action
    public setStoryId(id?: string){
        this.entityStore.params.storyId = id;
        this.metadataOpeStore.selectedTag = undefined;
        this.metadataOpeStore.selectedTagDesc = [];
        //切换story时需要切换当前选中的person
        this.entityStore.smartViewStore.changePerson(undefined);
    }

    @action
    public setSceneId(id?: string){
        this.entityStore.params.sceneId = id;
        this.metadataOpeStore.selectedTag = undefined;
        this.metadataOpeStore.selectedTagDesc = [];
        this.entityStore.smartViewStore.changeCaption('');//切换scene时需要清空caption选中
        //切换scene时需要切换当前选中的person
        this.entityStore.smartViewStore.changePerson(undefined);
    }

    @action
    public setShotId(id?: string){
        this.entityStore.params.shotId = id;
        this.metadataOpeStore.selectedTag = undefined;
        this.metadataOpeStore.selectedTagDesc = [];
        this.entityStore.smartViewStore.changeCaption('');//切换scene时需要清空caption选中
        //切换shot时需要切换当前选中的person
        this.entityStore.smartViewStore.changePerson(undefined);
    }

    protected getCurrentStore(){
        return this.entityStore;
    }
}