import { AxiosPromise } from 'axios'
import { IResponse, http } from 'mam-core-react/dist/modules/http'
import iIndexTypes from 'src/types/iIndexTypes'
import { commonUtil } from 'mam-common-utils'
import zhejiangTypes from 'src/custom/zhejiang/types/zhejiangTypes';

namespace zhejiangApi{
    // 获取轮播图
    export const queryBannerList = ():AxiosPromise<IResponse<any[]>> => {
        return http.get<IResponse<any[]>>(`/aialbum/push`)
    }
    // 模块统一获取数据，根据statisticsName的变化获取不同模块的数据
    export const  queryStatistics= (name: string):AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/statistic/portal/report-statistics?statisticsName=${name}`)
    }
    // 人物库列表获取数据
    export const  getPersonList= (params: zhejiangTypes.IPersonReq):AxiosPromise<IResponse<zhejiangTypes.IPersonRes>> => {
        return http.get<IResponse<zhejiangTypes.IPersonRes>>(`/statistic/portal/report-person?category=${params.category}&name=${params.name}&pageIndex=${params.pageIndex}&pageSize=${params.pageSize}`)
    }
    // 获取租户
    export const  getTenement= ():AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/zjcustom/content-pick/site`)
    }
}

export default zhejiangApi