import { AxiosPromise } from 'axios'
import { IResponse, http } from 'mam-core-react/dist/modules/http'
import catalogTypes from 'src/types/catalogTypes'
import { IFaceSearchReq, IFaceSearchRes, ISearchBySimilarityReq, ISearchBySimilarityRes, IGetPersonDetailReq, IPersonTypeCollectionItem, IGetPersonDetailRes } from 'src/types/imageSearchTypes'

namespace imageSearchApi {
    export const searchFace = (request: catalogTypes.faceReco.IFaceRecoReq): AxiosPromise<IResponse<catalogTypes.faceReco.IFaceRecoRes>> =>  {
        return http.post<IResponse<catalogTypes.faceReco.IFaceRecoRes>>('/knowledgebase/personality/facerecognition', request)
    }
    // 高光时刻
    export const highlightSearch = (pageIndex: number, pageSize:number, personId: any, clusterIds: any): AxiosPromise<IResponse<IFaceSearchRes>> => {
        if(clusterIds) {
            if(clusterIds.length > 1) {
                let url: string = `/es/search/wonderful-moment?pageIndex=${pageIndex}&pageSize=${pageSize}&personId=${personId}`
                clusterIds.forEach((item: any) => {
                    url += `&clusterIds=${item}`
                })
                return http.get<IResponse<IFaceSearchRes>>(url)
            } else {
                return http.get<IResponse<IFaceSearchRes>>(`/es/search/wonderful-moment?pageIndex=${pageIndex}&pageSize=${pageSize}&personId=${personId}&clusterIds=${clusterIds[0]}`)
            }
        } else {
            return http.get<IResponse<IFaceSearchRes>>(`/es/search/wonderful-moment?pageIndex=${pageIndex}&pageSize=${pageSize}&personId=${personId}`)
        }
    }

    export const faceSearch = (req: IFaceSearchReq): AxiosPromise<IResponse<IFaceSearchRes>> => {
        return http.post<IResponse<IFaceSearchRes>>('/es/search/face', req)
    }

    export const searchBySimilarity = (req: ISearchBySimilarityReq, currentTab: string): AxiosPromise<IResponse<ISearchBySimilarityRes>> => {
        if (currentTab === 'biz_sobey_picture'){
            return http.post<IResponse<ISearchBySimilarityRes>>('/vector/search/picture', req)
        }
        else {
            return http.post<IResponse<ISearchBySimilarityRes>>('/vector/search/video', req)
        }
    }

    export let getPersonDetail = (req: IGetPersonDetailReq): AxiosPromise<IResponse<IGetPersonDetailRes>> => {
        return http.post<IResponse<IGetPersonDetailRes>>('/knowledgebase/commonality/fullsearch', req)
    }

    export let getPersonTypeCollection = (): AxiosPromise<IResponse<IPersonTypeCollectionItem[]>> => {
        return http.get<IResponse<IPersonTypeCollectionItem[]>>('/kb/v1/entity/people/categorys')
    }

    export const removeFaceSegment = (contentId: string, name: string, id?: string): AxiosPromise<IResponse<any>> => {
        return http.delete(`/aidata/face/${contentId}/segment?id=${id || ''}&name=${name}`)
    }
}

export default imageSearchApi