import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import IIndexStore from '../iIndexStore';
import iIndexTypes from 'src/types/iIndexTypes';
import { observable, action, runInAction } from 'mobx';
import iSearchTypes from 'src/types/iSearchTypes';
import iIndexApi from 'src/apis/iIndexApi';
import { message } from 'antd';

export default class NewImportStore extends ExtendStore {
    public tabs: iIndexTypes.secList.INewImportTab[] = [
        {
            code: 'biz_sobey_video',
            name: '视频'
        },
        {
            code: 'biz_sobey_audio',
            name: '音频'
        }
    ];
    @observable
    public selectedTab: string = 'biz_sobey_video';
    @observable
    public searchData: iSearchTypes.IFullSearchDataRes | undefined;
    @observable
    public isReqSearchData: boolean = false;
    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
    }

    @action
    public setSelectedTab(tab: string){
        this.selectedTab = tab;
    }

    @action
    public query(page?: number){
        if (this.isReqSearchData){
            return;
        }
        this.isReqSearchData = true;
        if (page){
            this.searchData = undefined;
        }
        let qPage = page || 1;
        if (this.searchData && this.searchData.pageIndex){
            qPage = this.searchData.pageIndex + 1;
            if (qPage > this.searchData.pageTotal){
                return;
            }
        }
        iIndexApi.queryNewImport(this.selectedTab, qPage).then(res=>{
            runInAction(()=>{
                if (res.data.data && res.data.data.data){
                    res.data.data.data.forEach((item)=>{
                        item.name = item.name_;
                        item.duration = item.duration_;
                        item.contentId = item.contentId_;
                        item.framerate = item.frameRate_;
                    })
                    if (qPage > 1 && this.searchData){
                        this.searchData.data = this.searchData.data.concat(res.data.data.data);
                        this.searchData.pageIndex = res.data.data.pageIndex;
                        this.searchData.pageSize = res.data.data.pageSize;
                        this.searchData.pageTotal = res.data.data.pageTotal; 
                        this.searchData.recordTotal = res.data.data.recordTotal;
                    }
                    else{
                        this.searchData = res.data.data;
                    }
                }
                this.isReqSearchData = false;
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            this.isReqSearchData = false;
        });
    }
}