import uploadTypes from "../../../types/uploadTypes";
import {action, observable} from "mobx";
import {uploader} from "../../../components/upload/core/uploader";
import UploadStore from "./uploadStore";
import _ from 'lodash';
import { commonUtil } from 'mam-common-utils';

export default class GroupStore{
    @observable
    public video?: uploadTypes.IFile;
    @observable
    public audios: uploadTypes.IFile[] = [];
    private uploadStore: UploadStore;

    constructor(uploadStore: UploadStore) {
        this.uploadStore = uploadStore;
    }

    public getFileIdx(task: uploadTypes.ITask, file?: uploadTypes.IFile): number{
        if (!this.video)
        {
            return -1;
        }
        let videoIdx = _.findIndex(task.files, (item: uploadTypes.IFile)=>{
            if (file)
            {
                return (item.file as File).name === (file.file as File).name &&
                    (item.file as File).size === (file.file as File).size &&
                    (item.file as File).lastModified === (file.file as File).lastModified;
            }
            return false;
        });
        return videoIdx;
    }

    @action
    public handleFiles(options: uploadTypes.IUploadOptions, tasks: uploadTypes.ITask[], files: uploadTypes.IFile[]): uploadTypes.IFile[] {
        let result: uploadTypes.IFile[] = [];
        let match;
        this.uploadStore.uploadConfig.config.uploadRules.forEach((rule)=> {
            _.forEach(files, (file: uploadTypes.IFile) => {
                if ((file as any).matched) {
                    return;
                }
                if ((tasks && tasks.length > 0 && tasks[0].files
                        && uploader.checkExist(tasks[0].files, file))) {
                    return;
                }
                match = file.fileName.match(commonUtil.evalSyntax(rule.video));
                if (match) {
                    if (this.video)//替换原来的video
                    {
                        let videoIdx = this.getFileIdx(tasks[0], this.video);
                        if (tasks[0].files && videoIdx !== undefined && videoIdx !== -1)
                        {
                            tasks[0].files.splice(videoIdx, 1, file);
                            _.remove(result, (o: uploadTypes.IFile)=>{
                                return o === this.video;
                            });
                            tasks[0].metadata.name = '';
                        }
                    }
                    result.push(file);
                    this.video = file;
                    if (tasks && tasks.length > 0)
                    {
                        if (tasks[0].metadata.name == null || tasks[0].metadata.name === "") {
                            tasks[0].metadata.name = file.metadata.name;
                            if (tasks[0].metadata.field)
                            {
                                let metaField = _.find(tasks[0].metadata.field, { fieldName: 'name_' });
                                if (metaField)
                                {
                                    metaField.value = file.metadata.name;
                                }
                            }
                        }
                    }
                    (file as any).matched = true;
                }
                match = file.fileName.match(commonUtil.evalSyntax(rule.audio));
                if (match) {
                    this.audios.push(file);
                    result.push(file);
                    (file as any).matched = true;
                }
            });
            if (this.video){
                tasks[0].fileName = this.video.fileName;
            }
        });
        return result;
    }

    @action
    public setAudios(audios: uploadTypes.IFile[]){
        this.audios = audios;
    }

    @action
    public setVideo(video?: uploadTypes.IFile){
        this.video = video;
    }

    @action
    public deleteVideo = () => {
        if (this.uploadStore.tasks.length > 0)
        {
            let videoIdx = this.getFileIdx(this.uploadStore.tasks[0], this.video);
            if (videoIdx !== undefined && videoIdx > -1)
            {
                this.setVideo(undefined);
                this.uploadStore.removeSelectedTaskFileByIdx(videoIdx);
            }
        }
    }

    @action
    public deleteAudio = (item: uploadTypes.IFile, audioIdx: number)=>{
        let idx = this.getFileIdx(this.uploadStore.tasks[0], item);
        this.audios.splice(audioIdx, 1);
        this.uploadStore.removeSelectedTaskFileByIdx(idx);
    }
}