import { http } from 'mam-core-react'
import { AxiosPromise } from 'axios'
import bizTypes from 'src/types/bizTypes'
import { IResponse } from 'mam-core-react/dist/modules/http'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'

namespace bizApi {
    export namespace common{
        export namespace subscribe{
            export const addSubscribe = (params: bizTypes.common.subscribe.IAddSubscribeReq):AxiosPromise<IResponse<any>> => {
                return http.post<IResponse<any>>('/subscribe/add', params)
            } 
            export const removeSubscribe = (key: string, value: string):AxiosPromise<IResponse<any>> => {
                return http.delete(`/subscribe?key=${key}&value=${value}`)
            } 
            export const subscribeCheck = (params: bizTypes.common.subscribe.ISubscribeCheckReq):AxiosPromise<IResponse<string[]>> => {
                return http.post<IResponse<string[]>>('/subscribe/check', params, {
                    headers: {hideLoading: true}
                })
            }
        }
    }
    export namespace publish{
        export const publishEntitys = (contentIds: string[]):AxiosPromise<IResponse<bizTypes.publish.IPublishRes[]>> => {
            return http.post<IResponse<bizTypes.publish.IPublishRes[]>>('/release', contentIds)
        }
        export const cancelPublishEntitys = (contentIds: string[]):AxiosPromise<IResponse<bizTypes.publish.IPublishRes[]>> => {
            return http.post<IResponse<bizTypes.publish.IPublishRes[]>>('/release/cancle', contentIds)
        }
    }
    export namespace album{
        export const uploadAlbumKeyframe = (base64: string):AxiosPromise<IResponse<string>> => {
            return http.post<IResponse<string>>('/aialbum/keyframe', {
                keyframeBase64: base64
            })
        }
        export const getAlbumMetadataFields = ():AxiosPromise<IResponse<bizTypes.createAlbum.IGetAlbumMetadataFieldRes>> => {
            return http.post<IResponse<bizTypes.createAlbum.IGetAlbumMetadataFieldRes>>('/upload/get-fields-by-types', {
                source: "webupload", 
                entityTypes :["album"]
            })
        }
        export const getTopicItems = ():AxiosPromise<IResponse<bizTypes.createAlbum.ITopicItem[]>> => {
            return http.get<IResponse<bizTypes.createAlbum.ITopicItem[]>>('/metadatafield/fixitems?fieldName=topic')
        }
        export const createAlbum = (params: bizTypes.createAlbum.ICreateAlbumReq):AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/aialbum/create', params)
        }
        export const addIntoAlbum = (params: bizTypes.createAlbum.IAddIntoAlbumReq):AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/aialbum/add', params)
        }
        export const moveAlbum = (params: bizTypes.createAlbum.IMoveAlbumReq):AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/aialbum/move', params)
        }
        export const searchAlbumByTopic = (topic: string,name?:string):AxiosPromise<IResponse<bizTypes.createAlbum.ISearchAlbumByTopicRes[]>> => {
            return http.get<IResponse<bizTypes.createAlbum.ISearchAlbumByTopicRes[]>>(`/es/search/album/name?topic=${topic}&name=${name || ''}`)
        }
    }
    export namespace push{
        export const pushEntity = (params:any):AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/business/portal-carousel/push`, params)
        }
    }
}

export default bizApi