import ExtendStore from "../../base/extendStore";
import {action, observable, runInAction} from "mobx";
import entityTypes from "../../../types/entityTypes";
import entityApi from "../../../apis/entityApi";
import ccTypes from "src/types/ccTypes";
import { message } from 'antd';
import _ from 'lodash';

export default class CuttingsStore extends ExtendStore{
    /** 粗精切信息 */
    public roughCuttings: ccTypes.searchTypes.IQueryResultData[] = [];
    public meticulousCuttings: ccTypes.searchTypes.IQueryResultData[] = [];
    @observable
    public currCuttingId: string = ''; //当前选中的某个粗切或精切的contentId
    @observable
    public showMetaEdit: boolean = true; //基础信息显示元数据编辑按钮，当点击粗精切时，不显示该按钮

    /** 获取粗精切 */
    @action
    public getCuttings(contentId: string){
        entityApi.getCuttings(contentId).then((res) => {
            runInAction(() => {
                if (res && res.data) {
                    if(res.data.success && res.data.data){
                        let cuttings = JSON.parse(JSON.stringify(res.data.data))
                        if(cuttings && cuttings.length > 0){
                            this.roughCuttings = _.filter(cuttings, (item)=>
                            item.dynamicResponse.editmode === '0')
                            this.meticulousCuttings = _.filter(cuttings, (item)=>
                            item.dynamicResponse.editmode === '1')
                        }
                    }else if(!res.data.success && res.data.error){
                        message.error(res.data.error.title)
                    }
                }
            })
        })
    }
    /** 粗精切点击后获取粗精切的元数据entitydata */
    @action
    public getCuttingMetadata(contentId: string, entityType: string): Promise<entityTypes.IEntity> {
        this.currCuttingId = contentId
        return new Promise((resolve, reject) => {
            entityApi.getCuttingMetadata(contentId, entityType).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.stores.entityStore.entity.entitydata = res.data.data
                        resolve();
                        this.showMetaEdit = false;
                    }
                    else {
                        reject();
                    }
                });
            }, (res) => {
                runInAction(() => {
                    if (res.data.error.code === 'B1066') {
                        this.stores.entityStore.loadError = '系统未定义该类型，无法浏览。';
                    } else {
                        this.stores.entityStore.loadError = res.data.error.title;
                    }
                    reject();
                });
            })
        });
    }

}
