import BaseStore from "../../base/baseStore";
import CatalogStore from "../catalogStore";
import {Stores} from "../../index";
import entityTypes from "../../../types/entityTypes";
import {message} from "antd";
import {action} from "mobx";
import {SmartMetadataType} from "../../entity/extends/smartViewStore";
import commonUtil from "mam-common-utils/dist/modules/commonUtil";

export default class PartMoveStore extends BaseStore<Stores> {
    private parentStore: CatalogStore
    public constructor(stores: Stores, parentStore: CatalogStore) {
        super(stores);
        this.parentStore = parentStore;
    }

    /**
     * 移动片段到上一个或下一个父节点下
     *
     * @param moveType prev：上一个；next：下一个
     */
    @action
    public set2SideParent(part: entityTypes.contentIndex.IMetaOriginal, moveType: string, contentId: string, site: string){
        let parent = this.parentStore.getParent(part);
        let target: entityTypes.contentIndex.IMetaOriginal | undefined;
        if (parent){
            if (moveType === 'prev'){
                target = this.parentStore.getPrev(parent);
            }
            else{
                target = this.parentStore.getNext(parent);
            }
            //找到旁边的父级目标，开始移动
            if (target){
                let targetMeta = commonUtil.copyObj(target);
                let parentMeta = commonUtil.copyObj(parent);
                this.executeMovePart(part, moveType, [parentMeta], [targetMeta], contentId, site).then(()=>{
                    message.success('片段移动成功！'.l('newEntity.movePartSuccess'));
                    // this.parentStore.getMetadatas(this.parentStore.params.contentId).then(()=>{
                    //     this.parentStore.handleSmartMetadatas(this.parentStore.metadatas);
                    //     this.parentStore.smartViewStore.handleSmartMetadatas(this.parentStore.entity, this.parentStore.metadatas);
                    // });
                });
            }
            //找不到目标，可能是第一个或最后一个片段的移动
            else{
                if (part.partType === SmartMetadataType.shot){//shot移动
                    let currstory = this.parentStore.getParent(parent);
                    let targetstory: entityTypes.contentIndex.IMetaOriginal | undefined;
                    let targetscene: entityTypes.contentIndex.IMetaOriginal | undefined;
                    if (currstory){
                        if (moveType === 'prev'){
                            let prev = this.parentStore.getPrev(currstory);
                            if (!prev){
                                message.error('当前所属片段层是第一个片段层，无法上移'.l('catalog.cannotMoveUpForFirstParent'));
                            }
                            else{
                                targetstory = prev;
                                let scenelist = this.parentStore.getSceneByStory(targetstory);
                                if (scenelist && scenelist.length > 0){
                                    targetscene = scenelist[scenelist.length - 1];
                                }
                            }
                        }
                        else {
                            let next = this.parentStore.getNext(currstory);
                            if (!next){
                                message.error('当前所属片段层是最后一个片段层，无法下移'.l('catalog.cannotMoveDownForLastParent'));
                            }
                            else {
                                targetstory = next;
                                let scenelist = this.parentStore.getSceneByStory(targetstory);
                                if (scenelist && scenelist.length > 0){
                                    targetscene = scenelist[0];
                                }
                            }
                        }
                        //目标找到，准备移动
                        if (targetstory && targetscene){
                            let targetstoryMeta = commonUtil.copyObj(targetstory);
                            let targetsceneMeta = commonUtil.copyObj(targetscene);
                            let currstoryMeta = commonUtil.copyObj(currstory);
                            let currsceneMeta = commonUtil.copyObj(parent);
                            this.executeMovePart(part, moveType, [currstoryMeta, currsceneMeta], [targetstoryMeta, targetsceneMeta], contentId, site).then(()=>{
                                message.success('片段移动成功！'.l('newEntity.movePartSuccess'));
                                this.parentStore.getStructureMetadatas().then(()=>{
                                });
                                this.parentStore.getEntityMetadatas().then(()=>{
                                });
                            });
                        }
                    }
                }
                else {//scene移动
                    if (moveType === 'prev'){
                        message.error('当前所属片段层是第一个片段层，无法上移'.l('catalog.cannotMoveUpForFirstParent'));
                    }
                    else {
                        message.error('当前所属片段层是最后一个片段层，无法下移'.l('catalog.cannotMoveDownForLastParent'));
                    }
                }
            }
        }
    }

    /**
     * 执行片段移动
     *
     * @param currentParents 当前的parent集合，如果有多层parent，需要按story->scene的顺序传入数组
     * @param targetParents 移动到的parent集合，长度和放入顺序必须和currentParents一致，否则后果自负
     */
    private executeMovePart(part: entityTypes.contentIndex.IMetaOriginal, moveType: string,
                            currentParents: entityTypes.contentIndex.IMetaOriginal[],
                            targetParents: entityTypes.contentIndex.IMetaOriginal[],
                            contentId: string, site: string){
        //let retPromise = [];
        let updateArr = [];
        for (let i = 0; i < currentParents.length; i++){
            let currParentMeta = currentParents[i];
            let targetParentMeta = targetParents[i];
            if (moveType === 'prev'){
                targetParentMeta.outpoint = part.outpoint;
                currParentMeta.inpoint = part.outpoint;
            }
            else {
                targetParentMeta.inpoint = part.inpoint;
                currParentMeta.outpoint = part.inpoint;
            }
            //请求服务器修改片段数据
            if (currParentMeta.inpoint === currParentMeta.outpoint){//如果之前的父级入出点相同，表示已没有子节点，则删除
                updateArr.push(this.parentStore.updateOriginalMeta(2, currParentMeta, true, false));
            }
            else{//反之，执行更新
                updateArr.push(this.parentStore.updateOriginalMeta(1, currParentMeta, true, false));
            }
            updateArr.push(this.parentStore.updateOriginalMeta(1, targetParentMeta, true, false));
            //更新界面上的片段数据
            // retPromise.push(Promise.all(updateArr).then(()=>{
            //     if (targetParentMeta && currParentMeta){
            //         this.modifyPart(targetParentMeta, {
            //             inpoint: targetParentMeta.inpoint,
            //             outpoint: targetParentMeta.outpoint
            //         });
            //         if (currParentMeta.inpoint === currParentMeta.outpoint){
            //             this.removePart(currParentMeta);
            //         }
            //         else {
            //             this.modifyPart(currParentMeta, {
            //                 inpoint: currParentMeta.inpoint,
            //                 outpoint: currParentMeta.outpoint
            //             });
            //         }
            //     }
            // }));
        }
        return Promise.all(updateArr);
    }
}