import { Stores } from 'src/stores'
import ExtendStore from "src/stores/base/extendStore"
import { observable, action, runInAction } from 'mobx'
import CatalogTaskStore from '../catalogTaskStore'
import contentMgTypes from 'src/types/contentMgTypes'
import contentMgApi from 'src/apis/contentMgApi'
import { message } from 'antd'
import _ from 'lodash'
import { commonUtil } from 'mam-common-utils'

export default class CatalogAdvancedSearchStore extends ExtendStore{
    public parentStore: CatalogTaskStore;
    @observable
    public showAdvancedSearch: boolean = false
    @observable
    public allFields?: contentMgTypes.catalogTask.IField[]
    @observable
    public usedFields?: contentMgTypes.catalogTask.IField[]
    @observable
    public isSetValueStatus: boolean = true
    @observable
    public conditions: contentMgTypes.catalogTask.ISearchCondition[]

    public constructor(stores: Stores, parentStore: CatalogTaskStore) {
        super(stores);
        this.parentStore = parentStore;
    }

    @action
    public setShowAdvancedSearch(showAdvancedSearch: boolean) {
        this.showAdvancedSearch = showAdvancedSearch
    }

    @action
    public setConditions(conditions: contentMgTypes.catalogTask.ISearchCondition[]) {
        this.conditions = conditions
    }

    @action
    public getFields() {
        contentMgApi.catalogTask.getFields().then(res => {
            runInAction(() => {
                if (res.data.data) {
                    res.data.data.forEach((item) => {
                        item.alias = '';
                        item.fieldPath = '';
                        item.minLength = 0;
                        item.maxLength = 0;
                        item.isShow = true;
                        item.isReadOnly = false;
                        item.isMustInput = false;
                        item.isMultiSelect = true;
                        item.isArray = false;
                        item.value = '';
                        item.nexus = 'eq';
                        if (item.controlType === 16) {
                            item.controlData = '{\"type\":\"onlypass\",\"showTime\":false}'
                        }
                    })
                    this.allFields = res.data.data;
                    let usedFieldsStorage = localStorage.getItem('catalogTask_advancedSearch_usedFields');
                    if (usedFieldsStorage) {
                        this.usedFields = JSON.parse(usedFieldsStorage);
                    }
                    let qconditions = commonUtil.getUrlQueryParam('conditions')
                    if (!this.usedFields || this.usedFields.length === 0) {
                        // this.stores.configStore.getUiModulesConfig('defaultAdvancedSearchFieldCodes').then(config => {
                        //     runInAction(() => {
                        //         if (config) {
                        //             let fieldsArr: string[] = JSON.parse(config);
                        //             if (fieldsArr.length > 0 && this.allFields) {
                        //                 this.usedFields = this.allFields.filter(field => {
                        //                     return _.find(fieldsArr, f => {
                        //                         return f === field.fieldCode;
                        //                     })
                        //                 })
                        //                 if (qconditions) {
                        //                     this.setDefaultValue(JSON.parse(qconditions))
                        //                 }
                        //             }
                        //             else {
                        //                 this.usedFields = [];
                        //             }
                        //         }
                        //         else {
                        //             this.usedFields = [];
                        //         }
                        //     })
                        // });
                    } else {
                        if (qconditions) {
                            this.setDefaultValue(JSON.parse(qconditions))
                        }
                    }
                }
            })
        }, res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title);
            }
        });
    }

    @action
    public setDefaultValue(conditions: contentMgTypes.catalogTask.ISearchCondition[]) {
        let newFields: contentMgTypes.catalogTask.IField[] | undefined = commonUtil.copyObj(this.usedFields)
        conditions.forEach(condition => {
            if (condition.items) {
                condition.items.forEach((n) => {
                    let item = _.find(newFields, { fieldCode: n.field });
                    if (item) {
                        if(item.controlType === 5){
                            item.value = JSON.stringify(n.value).replace('[','').replace(']','').replace('"','').replace('"','')
                        }else{
                            item.value = JSON.stringify(n.value)
                        }
                        this.usedFields = newFields
                    } else {
                        let newItem = _.find(this.allFields, { fieldCode: n.field })
                        if (newItem) {
                            if(newItem.controlType === 5){
                                newItem.value = JSON.stringify(n.value).replace('[','').replace(']','').replace('"','').replace('"','')
                            }else{
                                newItem.value = JSON.stringify(n.value)
                            }
                            this.usedFields?.push(newItem)
                        }
                    }
                });
            }
        })
        this.isSetValueStatus = false
    }

    @action
    public addUsedField() {
        if (!this.usedFields){
            this.usedFields = [];
        }
        if (this.allFields && this.allFields.length > 0) {
            this.usedFields.push(commonUtil.copyObj(this.allFields[0]));
        }
    }

    @action
    public changeUsedFieldType(index: number, fieldCode: string) {
        let field = _.find(this.allFields, { fieldCode });
        if (field && this.usedFields) {
            this.usedFields.splice(index, 1, commonUtil.copyObj(field));
        }
    }

    @action
    public removeUsedField(index: number) {
        if (this.usedFields) {
            this.usedFields.splice(index, 1)
        }
    }

    @action
    public setUsedFields(fields?: contentMgTypes.catalogTask.IField[]) {
        this.usedFields = fields;
    }

    @action
    public setUsedFieldRelation(index: number, value: string) {
        if (this.usedFields) {
            this.usedFields[index].nexus = value;
        }
    }

    @action
    public setUsedFieldValue(index: number, value: string | number) {
        if (this.usedFields) {
            this.usedFields[index].value = value;
        }
    }

    public saveUsedFieldsInStorage() {
        localStorage.setItem('catalogTask_advancedSearch_usedFields', JSON.stringify(this.usedFields || []));
    }
}
