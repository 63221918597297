import { BaseStore } from 'mam-core-react';
import { Stores } from 'src/stores';
import IMetadataOpeStore, { ICustomControl, IHighlightKeywords } from 'mam-metadata-react/dist/store/metadataOpeStore';
import IMetadataTagOpeStore from 'mam-metadata-react/dist/store/metadataTagOpeStore';
import CatalogBaseStore from './catalogBaseStore';
import { observable, action, runInAction } from 'mobx';
import MfcExtraTag from 'mam-metadata-react/dist/controls/mfcTag/mfcExtraTag';
import React from 'react';
import { ISelectedTag } from 'mam-metadata-react/dist/types/metadataOpeTypes';
import entityTypes from 'src/types/entityTypes';
import ccTypes from 'src/types/ccTypes';
import entityApi from 'src/apis/entityApi';
import { sensitiveReg } from 'mam-metadata-react/dist/controls/mfcTag/mfcTag';
import IMetadataTableOpeStore from 'mam-metadata-react/dist/store/metadataTableOpeStore';
import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import IMetadataInputOpeStore, { IPrefixNode } from 'mam-metadata-react/dist/store/metadataInputOpeStore';

export default class MetadataOpeStore extends BaseStore<Stores> implements IMetadataOpeStore,IMetadataTagOpeStore,IMetadataTableOpeStore,IMetadataInputOpeStore{
    /** 格外的标签组件 */
    @observable
    public ExtraTagComs?: Array<typeof MfcExtraTag>;
    /** 标签能否支持修改 */
    @observable
    public tagEditable: boolean = false;
    /** 删除标签前 */
    @observable
    public beforeHandleDeleteTag: (done: ()=>void)=>void
    /** 格外的元数据组件 */
    public StartExtraComs?: Array<typeof React.Component>;
    /** 格外的元数据组件 */
    public EndExtraComs?: Array<typeof React.Component>;
    /** 标签新增回调 */
    public tagOnAddCallback?: (fieldPath: string, fieldName: string, tagText: string)=>void;
    /** 标签修改回调 */
    public tagOnEditCallback?: (fieldPath: string, fieldName: string, tagText: string, oldText: string)=>void;
    /** 删除标签回调 */
    public tagOnDeleteCallback?: (fieldPath: string, fieldName: string, tagText: string)=>void
    /** 检索词标红 */
    public highlightKeywords?: IHighlightKeywords[]
    /** 元数据组件选中的tag */
    @observable
    public selectedTag?: ISelectedTag;
    @observable
    public selectedTagDesc: entityTypes.contentIndex.ITermDetail[];
    /** 正在查询tag的desc的请求数 */
    @observable
    public requestTagDescCount: number = 0;
    @observable
    public selectedTagRelatedParts: ccTypes.searchTypes.ISmartQueryResultData[];
    /** 正在查询tag的desc的请求数 */
    @observable
    public requestTagRelatedPartsCount: number = 0;
    /** 修改复杂类型之前调用 */
    public beforeEditMetadataTableRow?: (item: IFormItem, tableDatas: IFormItem[][], x: number, y: number, value: string) => void;
    /** 输入框后缀集合 */
    public suffixNodes?: IPrefixNode[]
    /** 自定义组件 */
    public customControls?: ICustomControl[]

    public catalogBaseStore: CatalogBaseStore;

    public constructor(stores: Stores, catalogBaseStore: CatalogBaseStore) {
        super(stores);
        this.catalogBaseStore = catalogBaseStore;
    }

    @action
    public setExtraTagComs(com?: Array<typeof MfcExtraTag>){
        this.ExtraTagComs = com;
    }

    @action
    public setTagEditable(tagEditable: boolean){
        this.tagEditable = tagEditable;
    }

    @action
    public setBeforeHandleDeleteTag(beforeHandleDeleteTag: (done: ()=>void)=>void){
        this.beforeHandleDeleteTag = beforeHandleDeleteTag;
    }

    @action
    public setStartExtraComs(com?: Array<typeof React.Component>){
        this.StartExtraComs = com;
    }
    @action
    public setEndExtraComs(com?: Array<typeof React.Component>){
        this.EndExtraComs = com;
    }
    @action
    public setTagOnAddCallback(tagOnAddCallback: (fieldPath: string, fieldName: string, tagText: string)=>void){
        this.tagOnAddCallback = tagOnAddCallback;
    }
    @action
    public setTagOnEditCallback(tagOnEditCallback: (fieldPath: string, fieldName: string, tagText: string, oldText: string)=>void){
        this.tagOnEditCallback = tagOnEditCallback;
    }
    @action
    public setTagOnDeleteCallback(tagOnDeleteCallback: (fieldPath: string, fieldName: string, tagText: string)=>void){
        this.tagOnDeleteCallback = tagOnDeleteCallback;
    }
    @action
    public setHighlightKeywords(keywords: IHighlightKeywords[]){
        this.highlightKeywords = keywords;
    }

    @action
    public setSelectedTag(selTag?: ISelectedTag){
        this.selectedTag = selTag;
        if (selTag && (selTag.fieldName === 'eventpeople'
                || selTag.fieldName === 'scenepeople'
                || selTag.fieldName === 'eventorg'
                || selTag.fieldName === 'sceneorg'
                || selTag.fieldName === 'relatedperson'
                || selTag.fieldName === 'face'
                || selTag.fieldName === 'host')){
            this.querySelectedTagDesc();
            this.querySelectedTagRelatedParts();
        }
        else {
            this.selectedTagDesc = [];
            this.selectedTagRelatedParts = [];
        }
    }

    @action
    public querySelectedTagDesc(){
        if (this.selectedTag){
            this.selectedTagDesc = [];
            const type = this.catalogBaseStore.getTypeByFieldName(this.selectedTag.fieldName);
            if (!type){
                return;
            }
            this.requestTagDescCount++;
            const id = type === 'NAME' ? this.catalogBaseStore.getPersonIdFromEntity(this.selectedTag.tag) : '';
            entityApi.getTermDetail(id, this.selectedTag.tag.replace(sensitiveReg, '').replace(/\(.*?\)/, ''), type).then((res)=>{
                runInAction(()=>{
                    this.requestTagDescCount--;
                    if (this.selectedTag && res.data.data && res.data.data.length > 0){
                        if (this.selectedTag.tag.replace(sensitiveReg, '').replace(/\(.*?\)/, '') === res.data.data[0].name){
                            this.selectedTagDesc = res.data.data;
                        }
                    }
                });
            }, err=>{
                runInAction(()=>{
                    this.requestTagDescCount--;
                    this.selectedTagDesc = [];
                });
            });
        }
    }

    @action
    public querySelectedTagRelatedParts(){
        const currMeta = this.catalogBaseStore.currentMeta;
        if (this.selectedTag){
            this.selectedTagRelatedParts = [];
            this.requestTagRelatedPartsCount++;
            entityApi.searchTagRelevantPart(this.selectedTag.tag.replace(sensitiveReg, ''), currMeta ? currMeta.guid_ : '').then((res)=>{
                runInAction(()=>{
                    this.requestTagRelatedPartsCount--;
                    if (res.data.data){
                        this.selectedTagRelatedParts = res.data.data;
                    }
                });
            }, err=>{
                runInAction(()=>{
                    this.requestTagRelatedPartsCount--;
                    this.selectedTagRelatedParts = [];
                });
            });
        }
    }

    /** 从词库查询标红敏感词 */
    @action
    public querySensitiveKeywords(){
        let req: entityTypes.IQLexiconReq = {
            pageIndex: 1,
            pageSize: 9999,
            keyword:"",
            sortBy: {
                sortInput:"count",
                desc:true
            },
            conditions: [
                {
                    field: "name",
                    value: "规则词"
                },
                {
                    field: "type",
                    value: "5"
                }
            ]
        }
        entityApi.queryLexiconList(req).then(res=>{
            let keywords: string[] = [];
            if (res.data.success === true && res.data.data && res.data.data.data){
                res.data.data.data.filter((item)=>item.isRed).forEach(lexicon => {
                    lexicon.entryList.forEach(item=>{
                        keywords.push(item.keyword);
                    })
                });
            }
            this.setHighlightKeywords([{keywords, color: '#c00'}]);
        });
    }

    public setBeforeEditMetadataTableRow(fun: (item: IFormItem, tableDatas: IFormItem[][], x: number, y: number, value: string) => void){
        this.beforeEditMetadataTableRow = fun;
    }
    
    public setSuffixNodes(nodes: IPrefixNode[]){
        this.suffixNodes = nodes;
    }

    public setCustomControls(customControls?: ICustomControl[]){
        this.customControls = customControls;
    }
}