import ExtendStore from "../../../base/extendStore"
import { observable, action, runInAction } from 'mobx'
import ContentMgStore from '../../contentMgStore'
import { Stores } from 'src/stores'
import contentMgApi from 'src/apis/contentMgApi'
import contentMgTypes from 'src/types/contentMgTypes'
import _ from 'lodash'
import { message } from 'antd'

export default class ColumnManageStore extends ExtendStore {
    @observable
    public selectNav: string = 'all'
    @observable
    public navArr: contentMgTypes.column.INavs[]
    @observable
    public conditionChoose: contentMgTypes.column.IChoose[]
    @observable
    public sortChoose: contentMgTypes.column.IChoose[]
    @observable
    public tableData: any[] = []     //表格数据
    @observable 
    public paginationInfo: contentMgTypes.column.IPaginationInfo = {
        pageIndex: 1,
        pageTotal: 0,
        recordTotal: 0
    }
    @observable 
    public selectValue = {
        conditionValue: 'value1',
        sortValue: 'value1'
    }
    @observable
    public selectedRowKeys: string[] = []

    public constructor(stores: Stores, contentMgStore: ContentMgStore) {
        super(stores)
        this.setNavArr([{type:'news', title: '新闻'},{type:'movies', title: "影视"}])
        this.setConditionChoose([{value: 'value1', title: '选项一'},{value: 'value2', title: '选项二'}])
        this.setSortChoose([{value: 'value1', title: '选项一'},{value: 'value2', title: '选项二'}])
        const obj = {
            key: 0,
            num: 1,
            columnName: '栏目一',
            category: '新闻',
            creator: '菲菲',
            createTime: '2020-1-19 12:09:12',
            status: '在播',
            contentNum: 12
        }
        let arr:any[] = []
        for(let i = 0; i < 50; i++ ) {
            obj.num = i + 1
            obj.key = i + 1
            const obj1 = _.cloneDeep(obj)
            arr.push(obj1)
        }
        this.setTableData(arr)
    }

    @action
    public setSelectNav(str: string) {
        this.selectNav = str
    }
    @action
    public setNavArr(arr: contentMgTypes.column.INavs[]) {
        this.navArr = arr
    }
    @action
    public setConditionChoose(arr: contentMgTypes.column.IChoose[]) {
        this.conditionChoose = arr
    }
    @action
    public setSortChoose(arr: contentMgTypes.column.IChoose[]) {
        this.sortChoose = arr
    }
    @action
    public setTableData(arr: any[]) {
        this.tableData = arr
    }
    @action
    public setPaginationInfo(obj: contentMgTypes.column.IPaginationInfo) {
        this.paginationInfo = obj
    }
    @action
    public setSelectedRowKeys(arr: string[]) {
        this.selectedRowKeys = arr
    }
    @action
    public setSelectValue(flag: string, value: string) {
        if(flag === 'condition') {
            this.selectValue.conditionValue = value
        }
        if(flag === 'sort') {
            this.selectValue.sortValue = value
        }
    }
}