import {http} from "../utils/http";
import {AxiosPromise} from "axios";
import shareTypes from "../types/shareTypes";
import {IResponse} from "mam-core-react/dist/modules/http";

namespace shareApi {
    export const getSites = () : AxiosPromise<IResponse<shareTypes.ISite[]>> => {
        return http.get<IResponse<shareTypes.ISite[]>>('/home/get-sites');
    }

    export const getMyReceive = (param: shareTypes.IShareListReq) : AxiosPromise<IResponse<shareTypes.IShareList>> => {
        return http.get<IResponse<shareTypes.IShareList>>('/share/get-my-receive-shares?pageIndex='+(param.pageIndex || 1)+'&pageSize='+(param.pageSize || 24)+'&sort='+param.sort+'&order='+param.order+'&keyword='+(param.keyword || '')+'&siteSelect='+(param.siteSelect || '')+'&linkType=6');
    }

    export const getMySend = (param: shareTypes.IShareListReq) : AxiosPromise<IResponse<shareTypes.IShareList>> => {
        return http.get<IResponse<shareTypes.IShareList>>('/share/get-my-send-shares?pageIndex='+(param.pageIndex || 1)+'&pageSize='+(param.pageSize || 24)+'&sort='+param.sort+'&order='+param.order+'&keyword='+(param.keyword || '')+'&linkType=6');
    }

    export const extendLinkshare = (shareUrl:string): AxiosPromise<IResponse<boolean>> => {
        return http.get<IResponse<boolean>>(`/share/extend-linkshare?shareUrl=${shareUrl}$linkType=${6}`)
    }

    export const quitShareGroup = (ids:string[]):AxiosPromise<IResponse<boolean>> => {
        return http.post<IResponse<boolean>>('/share/quit-share-group',ids)
    }
}
export default shareApi