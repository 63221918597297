import ExtendStore from "../../../base/extendStore"
import { observable, action, runInAction } from 'mobx'
import ContentMgStore from '../../contentMgStore'
import { Stores } from 'src/stores'
import contentMgApi from 'src/apis/contentMgApi'
import contentMgTypes from 'src/types/contentMgTypes'
import _ from 'lodash'
import { message } from 'antd'

export default class BulletinManageStore extends ExtendStore {
    //顶部导航
    @observable
    public topNav: string = 'option'
    //公告选项
    @observable
    public navsArr: contentMgTypes.bulletin.INav[] = []
    @observable 
    public currentNav: contentMgTypes.bulletin.INav   //保存当前的导航的信息
    @observable
    public PersonArr: any[] = [{value: 'james', title: '詹姆斯'},{value: 'kobe', title: '科比'}]
    @observable
    public addModalFlag: boolean = false

    //公告列表
    @observable
    public selectedRowKeys: string[] = []
    @observable 
    public selectedRows: contentMgTypes.bulletin.ITableList[] = []
    @observable
    public tableList: contentMgTypes.bulletin.ITableList[] = []
    @observable 
    public paginationInfo: contentMgTypes.bulletin.IPaginationInfo;
    @observable
    public lookModalFlag: boolean = false
    @observable
    public saveSelectObj: contentMgTypes.bulletin.ITableList
    @observable
    public reqQuery:contentMgTypes.bulletin.IReqData = {
        name: '',
        pageSize: 30,
        pageIndex: 1,
        status: ''
    }
    @observable
    public modalData: contentMgTypes.bulletin.IAddBulletinInfo;

    public constructor(stores: Stores, contentMgStore: ContentMgStore) {
        super(stores)
    }
    //顶部导航
    @action
    public setTopNav(str: string) {
        this.topNav = str
    }
    //公告选项
    @action
    public setOptionNav(str: string) {
        let arr = _.filter(this.navsArr, {code: str})
        this.setCurrentNav(arr[0])
    }
    @action
    public setNavsArr(arr: contentMgTypes.bulletin.INav[]) {
        this.navsArr = arr
    }
    @action
    public setCurrentNav(obj: contentMgTypes.bulletin.INav) {
        this.currentNav = obj
    }
    @action
    public setAddModalFlag(flag: boolean) {
        this.addModalFlag = flag
    }
    @action
    public setChangeBasicInfo(type: string, value: string) {
        if(type === 'radio') {
            this.currentNav.enable = Boolean(value)
        }
        if(type === 'checkbox') { 
            this.currentNav.noticeType = value
        } 
        if(type === 'select') {
            this.currentNav.lifeTime = Number(value)
        }
        contentMgApi.bulletinManage.updateBulletinType(this.currentNav).then(res => {
            if(res.data.success) {
                
            }
        }, res => {
            if(res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
        
    }
    //添加公告类型
    @action
    public addBulletinType(value: string) {
        return new Promise((resolve, reject) => {
            contentMgApi.bulletinManage.addBulletinType(value).then(res => {
                if(res.data.success && res.data.data) {
                    message.success('添加成功')
                    this.queryBulletinType()
                    resolve(true)
                } else {
                    reject()
                }
            }, res => {
                if(res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        })
    }
    //删除公告类型
    @action
    public deleteNavArr(code: string) {
        return new Promise((resolve, reject) => {
            contentMgApi.bulletinManage.deleteBulletinType(code).then(res => {
                if(res.data.success) {
                    message.success('删除成功')
                    resolve(true)
                } else {
                    reject()
                }
            }, res => {
                if(res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        })
    }
    @action
    public queryBulletinType() {
        return new Promise((resolve, reject) => {
            contentMgApi.bulletinManage.getBulletinType().then(res => {
                runInAction(() => {
                    if(res.data.success && res.data.data) {
                        this.setNavsArr(res.data.data)
                        this.setCurrentNav(res.data.data[0])
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, res => {
                if(res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        })
    }
    //新增公告
    @action
    public addBulletin(values: contentMgTypes.bulletin.IAddBulletinInfo) {
        return new Promise((resolve, reject) => {
            contentMgApi.bulletinManage.addBulletin(values).then(res => {
                if(res.data.success && res.data.data) {
                    if(res.data.data.status === 0) {
                        message.success('保存成功')
                    } else {
                        message.success('发布成功')
                    }
                    this.query()
                    resolve(true)
                } else {
                    reject()
                }
            }, res => {
                if(res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        })
    }

    //公告列表
    @action
    public setSelectedRowKeys(arr: string[]) {
        this.selectedRowKeys = arr
    }
    @action
    public setSelectedRows(arr: contentMgTypes.bulletin.ITableList[]) {
        this.selectedRows = arr
    }
    @action
    public setTableList(arr: contentMgTypes.bulletin.ITableList[]) {
        this.tableList = arr
    }
    @action
    public setReqQuery(flag: string, value: string) {
        if(flag === 'input') {
            this.reqQuery.name = value
        }
        if(flag === 'select') {
           this.reqQuery.status = Number(value)
        }
    }
    @action
    public query() {
        return new Promise((resolve, reject) => {
            contentMgApi.bulletinManage.getBulletinList(this.reqQuery).then(res => {
                runInAction(() => {
                    if(res.data.success && res.data.data && res.data.data.data) {
                        this.setPaginationInfo(res.data.data)
                        this.setTableList(res.data.data.data)
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, res => {
                if(res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        })
    }
    @action
    public setPaginationInfo(obj: contentMgTypes.bulletin.IPaginationInfo) {
        this.paginationInfo = obj
    }
    @action
    public deleteItems(codeArr?: string[]) {
        let arr:string[] = []
        if(codeArr) {  
            arr = codeArr
        } 
        else {
            arr = this.selectedRows.map((item) => {
                return item.code
            })
        }
        return new Promise((resolve, reject) => {
            contentMgApi.bulletinManage.deleteBulletins(arr).then(res => {
                if(res.data.success) {
                    message.success('删除成功')
                    this.query()
                    resolve(true)
                } else {
                    reject()
                }
            }, err => {
                if(err.data.error && err.data.error.data) {
                    message.error(err.data.error.data)
                }
            })
        })
    }
    //草稿详情发布或则保存
    @action
    public updateContent(obj: contentMgTypes.bulletin.IAddBulletinInfo) {
        return new Promise((resolve, reject) => {
            contentMgApi.bulletinManage.updateBulletin(obj).then(res => {
                if(res.data.success && res.data.data) {
                    if(res.data.data.status === 0) {
                        message.success('保存成功')
                    } else {
                        message.success('发布成功')
                    }
                    resolve(true)
                } else {
                    reject()
                }
            }, res =>{
                if(res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        })
    }
    //发布
    @action    
    public publishBulletinItem(code:string) {
        return new Promise((resolve, reject) => {
            contentMgApi.bulletinManage.getBulletinDetail(code).then(res => {
                runInAction(() => {
                    if(res.data.success && res.data.data) {
                        let dataDetail = res.data.data
                        dataDetail.status = 1    //改为发布状态
                        this.updateContent(dataDetail)
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, res => {
                if(res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        })
    }
    //弹框
    @action
    public setLookModalFlag(flag: boolean) {
        this.lookModalFlag = flag
    }
    @action
    public setSaveSelectObj(obj: contentMgTypes.bulletin.ITableList) {
        this.saveSelectObj = obj
    }
    @action 
    public getBulletinDetail(code: string) {
        return new Promise((resolve, reject) => {
            contentMgApi.bulletinManage.getBulletinDetail(code).then(res => {
                runInAction(() => {
                    if(res.data.success && res.data.data) {
                        this.modalData = res.data.data
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, res => {
                if(res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        })
    }
    @action
    public clearModalData() {
        if(this.modalData && this.modalData.code) {
            this.modalData.code = ''
        }
    }
}