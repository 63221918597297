import React from 'react'
import ExtendStore from "../../../base/extendStore"
import { observable, action, runInAction } from 'mobx'
import { Stores } from 'src/stores'
import allTopicTypes from 'src/types/allTopicTypes'
import { message } from 'antd'
import SelectAllStore from "../../../extends/selectAllStore"
import { IEntityType } from "src/types/configTypes"
import contentMgTypes from 'src/types/contentMgTypes'
import entityUtil from "src/utils/entityUtil"
import iIndexApi from 'src/apis/iIndexApi'
import contentMgApi from 'src/apis/contentMgApi'
import iAlbumDetailApi from 'src/apis/iAlbumDetailApi'
import { IParams, IEntitiesReq, IEntitiesRes ,IGetBreadcrumbRes} from 'src/types/iAlbumDetailTypes'
import iSearchTypes from 'src/types/iSearchTypes'
export default class AllTopicStore extends ExtendStore {
    @observable
    public isExpanded: boolean = true  //已展开
    @observable
    public isSearchingTree: boolean = false  //是否正在初始化搜索树
    @observable
    public toggleInitTree: boolean = false  //切换初始化树
    @observable
    public mode: number = iSearchTypes.DataViewModes.LIST  /** 模式 */
    @observable
    public keyword: string
    @observable
    public status: string = "0"  //状态：所有、上架、下架
    @observable
    public startTime: string
    @observable
    public endTime: string
    @observable
    public currentSort: string = "createDate_:desc"  //desc降序asc升序
    @observable
    public currentPushType: string = ""  //已推送、未推送
    // @observable
    // public filterEntityTypes: IEntityType[] = []//分类

    // @observable
    // public addOSEdit: string//添加或编辑
    // @observable
    // public expandedKeys: string[] = []//左侧树菜单展开key
    // @observable
    // public selectedKeys: string[] = []//左侧树菜单选中的key
    // @observable
    // public pathArray: any[] = []//面包屑路径
    // @observable
    // public TreeDataArr: any[] = []//左侧树菜单

    
    @observable
    public currentTopic: any //左侧树菜单选中的专题
    @observable
    public breadcrumb: IGetBreadcrumbRes[] //面包屑
    @observable
    public entitiesRes: IEntitiesRes | undefined //专题详情查询结果(包含专题和非专题的)
    @observable
    public topicList: any[] | undefined //专题详情查询结果(包含专题)
    @observable
    public otherList: any[] | undefined //专题详情查询结果(包含非专题)
    @observable
    public isLoadingEntities = true //是否正在查询专题详情
    
    public topicTree: any = React.createRef()

    public selectAllStore: SelectAllStore
    
    public constructor(stores: Stores) {
        super(stores)
        this.initSelectAllStore(stores, 'stores.contentMgStore.allTopicStore.allTopicListResponse.data', 'selected');
        this.initNewCreateAlbumStore(stores)
    }

    @action
    public refreshEntitys = (currentTopic?: any) => {
        if(currentTopic){
            this.getEntitys(currentTopic.contentId)
            this.getBreadcrumb(currentTopic.contentId)
        }else{
            this.breadcrumb = []
            this.topicList = undefined
            this.otherList = undefined
        }
    }
    @action
    public setCurrentTopic= (currentTopic: any) => {
        this.currentTopic = currentTopic
        this.refreshEntitys(currentTopic)
    }
    @action
    public switchToggleInitTree() {
        this.toggleInitTree = !this.toggleInitTree
    }
    @action
    public setStatus(status: string) {
        this.status = status
    }
    @action
    public setStartTime(startTime: string) {
        this.startTime = startTime
    }
    @action
    public setEndTime(endTime: string) {
        this.endTime = endTime
    }
    @action
    public setCurrentSort(currentSort: string) {
        this.currentSort = currentSort
    }
    @action
    public setCurrentPushType(currentPushType: string) {
        this.currentPushType = currentPushType
    }
    @action
    public setMode(mode: number) {
        this.mode = mode
    }
    @action
    public setKeyword(keyword: string) {
        this.keyword = keyword
    }
    @action
    public setIsExpanded(isExpanded: boolean) {
        this.isExpanded = isExpanded
    }
    //查询左侧树: 不传contentId查根节点树，传contentId查子节点树
    @action
    public getTreeData = (contentId?: string) => {
        let params: any = {
            pageIndex: 1,
            pageSize: 500,
            keyword: [],
            facetConditions: [],
            conditions: [
                {
                    field: "type_",
                    value: ["biz_sobey_album"]
                },
                {
                    field: "albumType_",
                    value: ["Manual"]
                },
            ],
            sorts: [
                {
                    field: "createDate_",
                    desc: true
                }
            ],
            keywordSearchType: "fullText"
        }
        if(contentId){
            params.conditions.push({
                field: "album",
                value: [contentId]
            })
        }else{
            this.isSearchingTree = true
        }
        if(this.keyword){
            params.keyword = [this.keyword]
        }
        if(this.startTime && this.endTime){
            params.conditions.push({
                field: "createDate_",
                value: [this.startTime+" 00:00:00 To "+this.endTime+" 23:59:59"]
            })
        }
        return new Promise((resolve, reject) => {
            iIndexApi.searchByEsOrigin(params).then((res) => {
                runInAction(()=>{
                    if (res.data.data) {
                        this.isSearchingTree = false
                        if(res.data.data.data){
                            res.data.data.data.map((item) => {
                                item.name = item.name_
                                item.duration = item.duration_
                                item.contentId = item.contentId_
                                item.framerate = item.framerate_
                            })
                            resolve(res.data.data.data)
                        }else{
                            resolve([])
                        }
                    }else{
                        reject()
                    }
                })
            }, res => {
                runInAction(()=>{
                    if (res.data.error && res.data.error.title) {
                        message.error(res.data.error.title)
                    }
                    this.isSearchingTree = false
                    reject()
                })
            })
        })
    }
    //查询专题详情
    @action
    public getEntitys(contentId: string){
        let param: IEntitiesReq = {
            pageIndex: 1,
            pageSize: 500,
            keyword: [],
            contentId,
            sorts:[
                {
                    field: this.currentSort.split(":")[0],
                    desc: this.currentSort.split(":")[1] === "desc",
                }
            ],
        }
        this.isLoadingEntities = true
        // this.entitiesRes = undefined
        this.topicList = undefined
        this.otherList = undefined
        return new Promise((resolve, reject) => {
            iAlbumDetailApi.queryEntities(param).then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        if(res.data.data.data){
                            res.data.data.data.map((item)=>{
                                item.name = item.name_
                                item.duration = item.duration_
                                item.contentId = item.contentId_
                                item.framerate = item.framerate_
                            })
                            // this.entitiesRes = res.data.data
                            this.topicList = res.data.data.data.filter((o)=>o.type === "album")
                            this.otherList = res.data.data.data.filter((o)=>o.type !== "album")
                        }else{
                            this.topicList = undefined
                            this.otherList = undefined
                        }
                        this.isLoadingEntities = false
                        resolve(true)
                    }else{
                        reject()
                    }
                })
            }, res=>{
                runInAction(()=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title)
                    }
                    this.isLoadingEntities = false
                    reject()
                })
            })
        })
    }
    //查询面包屑
    @action
    public getBreadcrumb(contentId:string){
        return new Promise((resolve, reject) => {
            iAlbumDetailApi.getBreadcrumb(contentId).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.breadcrumb = res.data.data
                        resolve(true)
                    }else{
                        reject()
                    }
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject()
            })
        })
    }
}
