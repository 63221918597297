import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import { message } from 'antd';
import _ from 'lodash'
import IIndexStore from '../iIndexStore';
import iIndexTypes from 'src/types/iIndexTypes';
import iIndexApi from 'src/apis/iIndexApi';

export default class AlbumListStore extends ExtendStore {
    @observable
    public allAlbumsRes: iIndexTypes.secList.IAlbumItemsRes | undefined;
    @observable
    public isReqAlbums: boolean = false;
    @observable
    public categories: iIndexTypes.secList.IAlbumTopicRes[] = [];
    /** 选中的类型 */
    @observable
    public selectedCate: string;
    /** 排序列表 */
    @observable
    public sortList: iIndexTypes.secList.ISortItem[] = [
        {
            value: 'count',
            showValue: '按数量'
        },
        {
            value: 'duration',
            showValue: '按时长'
        }
    ];
    @observable
    public selectedSortValue: string = 'count';
    public isScrollReqLock: boolean = false;
    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
        this.initSearchFilterStore(stores);
        this.initSelectAllStore(stores, 'stores.contentMgStore.allAlbumsStore.allAlbumsRes.data', 'selected');
    }

    @action
    public searchCates(){
        return new Promise((resolve, reject)=>{
            iIndexApi.getAlbumTopic().then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        this.categories = res.data.data;
                        if (this.categories && this.categories.length > 0){
                            this.selectedCate = this.categories[0].topic;
                        }
                    }
                    resolve(undefined);
                })
            }).catch((res)=>{
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }

    @action
    public searchAlbums(page?: number, data?: any){
        return new Promise((resolve, reject)=>{
            if (this.isScrollReqLock){
                return;
            }
            if (!data){
                this.allAlbumsRes = undefined;
            }
            this.isReqAlbums = true;
            iIndexApi.getAlbumItems(page || 1, this.selectedCate, this.selectedSortValue, true).then(res=>{
                runInAction(()=>{
                    if (res.data.data && res.data.data.data){
                        if (res.data.data.data.length === 0 && data){
                            this.isScrollReqLock = true;
                        }
                        else {
                            res.data.data.data.forEach((item)=>{
                                item.name = item.name;
                                item.duration = item.duration;
                                item.content_id = item.content_id;
                            })
                            if (!data){
                                this.allAlbumsRes = res.data.data;
                            }
                            else {
                                if (this.allAlbumsRes){
                                    this.allAlbumsRes.data = this.allAlbumsRes.data.concat(res.data.data.data);
                                    this.allAlbumsRes.page_index = res.data.data.page_index;
                                }
                            }
                        }

                    }
                    this.isReqAlbums = false;
                    resolve(undefined);
                })
            }).catch((res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                runInAction(()=>{
                    this.isReqAlbums = false;
                })
                reject();
            })
        })
    }

    @action
    public setSelectedCate(tab: string){
        this.selectedCate = tab;
    }

    @action
    public setSelectedSortValue(value: string){
        this.selectedSortValue = value;
    }
}