import { action, observable, runInAction } from "mobx";
import ccTypes from "../../../types/ccTypes";
import CcStore from "../ccStore";
import { Stores } from "../../index";
import ccApi from "../../../apis/ccApi";
import _ from 'lodash';


export default class RejectTaskStore extends CcStore {
    @observable
    public params: ccTypes.searchTypes.ISearchParams
    @observable
    public fullSearchData?: ccTypes.searchTypes.IFullSearchRes;

    @observable
    public searchKeyword1: string = '';  // 主搜索框值
    @observable
    public searchKeyword2: string = '';  // 次检索框值

    @observable
    public pageSize: number = 30;
    public pageSizes: number[] = [30,60,100]

    constructor(stores: Stores) {
        super(stores)
        this.initOutstoreStore(stores);
        this.initDeleteStore(stores);
        this.initCreatePlanningStore(stores);
        this.initOutputExcelStore(stores);
        this.initExportExcelFieledSettingStore(stores);
        this.initUnlockStore(stores);
        this.initShareStore(stores);
        this.initSelectAllStore(stores, 'stores.rejectTaskStore.fullSearchData.queryResult.data', 'selected');
        this.initAdvancedSearchStore(stores)
        this.initBatchExecuteSmartProcessStore(stores)
    }

    @action
    public queryFullSearchData(page?: number): Promise<any> {
        this.selectAllStore.switchAllSelected(false);
        this.fullSearchData = undefined;
        return new Promise((resolve, reject) => {
            this.getSortItems();
            if (this.stores.configStore.config.searchResultNum){
                this.pageSize = this.stores.configStore.config.searchResultNum;
            }
            const pageSizeStorage = localStorage.getItem('searchStore.pageSize');
            if (pageSizeStorage !== null){
                this.pageSize = parseInt(pageSizeStorage, 10)
            }
            let req: ccTypes.searchTypes.IFullSearchReq = {
                page: page || this.params.page,
                size: this.pageSize,
                keyword: this.params.keyword,
                sortBys: [{
                    fieldName: this.currentSort.field,
                    isDesc: this.currentSort.desc === 'desc'
                }],
                conditions: [
                    { field: 'privilege_', value: ['public_S1'] },
                    { field: 'createUser_', value: [this.stores.userStore.currentUser?.userCode as string] }
                ],
                highLight: true
            };
            if (this.params.conditions) {
                this.params.conditions.forEach((condition) => {
                    if (condition.items) {
                        condition.items.forEach((n) => {
                            let item = _.find(req.conditions, { field: n.field });
                            if (item == null) {
                                req.conditions.push(n);
                            } else {
                                if (_.isArray(item.value)) {
                                    if (!_.includes(item.value, n.value)) {
                                        item.value.push(n.value as string);
                                    }
                                } else {
                                    if (item.value !== n.value) {
                                        item.value = [item.value, n.value as string];
                                    }
                                }
                            }
                        });
                    } else {
                        if (condition.field && condition.value) {
                            req.conditions.push({ field: condition.field, value: condition.value })
                        }
                    }
                });
            }
            if (this.params.advancedSearch && this.params.advancedSearch.field.length > 0 && this.params.advancedSearch.isOnlyAvSearching) {
                if(this.stores.configStore.config.wuXiCustomSwitch){
                    const params: ccTypes.searchTypes.IAdvancedSearchTypes = {
                        field:  this.params.advancedSearch.field.filter(item => {
                            if(item.value !== '' && item.value !== "notFound" && item.value !== undefined && item.value !== '[]' && item.value !== null) {
                                return item
                            }else {
                                return false
                            }
                        }),
                        isOnlyAvSearching: this.params.advancedSearch.isOnlyAvSearching
                    }
                    if(params.field && params.field.length > 0 ){
                        req.advancedSearch = this.handleWuxiAdvancedSearch(params)
                    }
                }else{
                    req.advancedSearch = this.params.advancedSearch
                }
            }
            if (this.params.resourceName) {
                req.resourceName = this.params.resourceName
            }
            req.rejectConditions = [
                {
                    "field":"wx_catalogue_step",
                    "value":[
                        "m1editreject",
                        "m1clipreject",
                        "m2clipreject"
                    ]
                },
                    {
                    "field":"wx_current_callback_step",
                    "value":[
                        "callbackauditeditreject",
                        "callbackauditclipreject"
                    ]
                }
            ]
            ccApi.searchApi.wxFinishedFullSearch(req).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        this.initFacets(res.data.data.facets);
                        if (res.data.data.queryResult && res.data.data.queryResult.data) {
                            this.resolveQueryResult(res.data.data.queryResult.data);
                        }
                        /** 无锡定制：根据素材id获取云端高低码状态 */
                        if(this.stores.configStore.config.pushPullSwitch){
                            this.getProgramNameCloud(res.data.data.queryResult.data)
                        }
                        this.fullSearchData = res.data.data;
                    }
                })
                resolve();
            }, () => {
                reject();
            });
        });
    }

    @action
    public setParams(searchParams: ccTypes.searchTypes.ISearchParams) {
        this.params = searchParams;
        if (searchParams.keyword){
            if (searchParams.keyword[0]){
                this.setSearchKeyword1(searchParams.keyword[0]);
            }
            if (searchParams.keyword[1]){
                this.setSearchKeyword2(searchParams.keyword[1]);
            }
        }
        //如果有高级检索条件，则自动显示高级检索面板
        if (searchParams.advancedSearch && searchParams.advancedSearch.field && searchParams.advancedSearch.field.length > 0
            && this.advancedSearchStore){
            this.advancedSearchStore.setShowAdvancedSearch(true)
        }
    }

    @action
    public setSearchKeyword1(str: string) {
        this.searchKeyword1 = str;
    }
    @action
    public setSearchKeyword2(str: string) {
        this.searchKeyword2 = str;
    }

    /**
     * 重命名
     */
    @action
    public setRename(index: number, editMode: boolean) {
        if (this.fullSearchData) {
            if (editMode) {
                this.fullSearchData.queryResult.data[index].oldName = this.fullSearchData.queryResult.data[index].name
            }
            this.fullSearchData.queryResult.data[index].editMode = editMode
        }
    }

    @action
    public setNewRename(index: number, newName: string) {
        if (this.fullSearchData) {
            this.fullSearchData.queryResult.data[index].name = newName
        }
    }

    @action
    public setPageSize(size: number){
        this.pageSize = size;
        localStorage.setItem('searchStore.pageSize', size.toString());
        this.queryFullSearchData(1).then(()=>{});
    }
}
