import iEntityTypes from 'src/types/iEntityTypes';
import { Thread } from 'mam-common-utils';

let thread: Thread | undefined;

const doThread = (e: any)=>{
    let data = JSON.parse(e.data);
    let permutationTmpArr: iEntityTypes.IFacetSearchPart[] = [];
    let allParts: iEntityTypes.IFacetSearchPart[] = data.allParts;
    let selectedFacets: iEntityTypes.ISelectedFacetItem[] = data.selectedFacets;

    const groupBy = <T>(arr: T[], key: string)=>{
        let map = {};
        arr.forEach((item)=>{
            if (!map[item[key]]){
                map[item[key]] = [];
            }
            map[item[key]].push(item);
        })
        return map;
    }

    const getPartsByFacets = (facets: iEntityTypes.ISelectedFacetItem[])=>{
        let tempResult: iEntityTypes.IFacetSearchPart[] = [];
        facets.forEach((facet)=>{
            allParts.forEach((part)=>{
                if (part.type === facet.typeName && part.name === facet.facetValue){
                    tempResult.push(part);
                }
            })
        })
        return tempResult;
    }

    /** 
     * 组合排列遍历二维数组 
     * 
     * @param arr 待遍历的二维数组
     * @param row 当前是第几列
     * @param callback 返回临时变量数组permutationTmpArr，将每一行的项输出
     */
    const traversePermutation = (arr: iEntityTypes.IFacetSearchPart[][], row: number, callback: (arr: iEntityTypes.IFacetSearchPart[])=>void)=>{
        for (let i = 0; i < arr[row].length; i++){
            permutationTmpArr[row] = arr[row][i];
            if (row < arr.length - 1){
                traversePermutation(arr, row + 1, callback);
            }
            else {
                callback(permutationTmpArr);
            }
        }
    }

    const isPartsCross = (parts: iEntityTypes.IFacetSearchPart[]): boolean=>{
        if (parts.length === 2){
            if (parts[0].outpoint < parts[1].inpoint || parts[0].inpoint > parts[1].outpoint){
                return false;
            }
            return true;
        }
        else{ // 大于2的情况
            for (let i = 0; i < parts.length; i++){
                for (let j = 0; j < parts.length - 1 - i; j++){
                    let a = parts[i];
                    let b = parts[i + j + 1];
                    if (!isPartsCross([a, b])){//排列组合，判断是否交叉，其中一个未交叉也算是false
                        return false;
                    }
                }
            }
            return true;
        }
    }

    /** 将多个片段根据入出点交集合并为一个片段 */
    const combineParts = (parts: iEntityTypes.IFacetSearchPart[]): iEntityTypes.IFacetSearchPart=>{
        if (parts.length === 2){
            let inpoint = Math.max(parts[0].inpoint, parts[1].inpoint);
            let outpoint = Math.min(parts[0].outpoint, parts[1].outpoint);
            return {
                inpoint,
                outpoint,
                framerate: parts[0].framerate,
                guid_: '',
                keyframeno: -1,
                name: '',
                type: ''
            }
        }
        else{ // 大于2的情况
            let tmpPart: iEntityTypes.IFacetSearchPart = parts[0];
            for (let i = 1; i < parts.length; i++){
                //执行前，已经用isPartsCross判断过，必定排列组合下两两交叉，所以只需两两合并，取得最终合并片段
                tmpPart = combineParts([tmpPart, parts[i]]);
            }
            return tmpPart;
        }
    }

    let facetTypes = groupBy(selectedFacets, 'typeName');
    let facetKeys = Object.keys(facetTypes);
    if (facetKeys.length === 1){
        (self as any).postMessage(JSON.stringify(getPartsByFacets(facetTypes[facetKeys[0]])));
    }
    else {
        let outerArr: iEntityTypes.IFacetSearchPart[][] = [];// 选择的每一种层面对应的片段列表
        facetKeys.forEach((key)=>{
            outerArr.push(getPartsByFacets(facetTypes[key]))
        })
        let tmpResult: iEntityTypes.IFacetSearchPart[] = [];
        traversePermutation(outerArr, 0, (arr)=>{
            if (isPartsCross(arr)){
                tmpResult.push(combineParts(arr));
            }
        });
        (self as any).postMessage(JSON.stringify(tmpResult));
    }
}

const searchByThread = (allParts: iEntityTypes.IFacetSearchPart[], selectedFacets: iEntityTypes.ISelectedFacetItem[]): Promise<iEntityTypes.IFacetSearchPart[]>=>{
    return new Promise((resolve, reject)=>{
        if (thread){
            thread.terminate();
        }
        thread = new Thread(doThread);
        thread.run({
            allParts, 
            selectedFacets
        }).then((data: string)=>{
            resolve(JSON.parse(data));
            if (thread){
                thread.terminate();
                thread = undefined;
            }
        })
    })
}

export default searchByThread