import {action, computed, observable, runInAction} from "mobx";
import entityApi from "../../apis/entityApi";
import entityTypes from "../../types/entityTypes";
import {Stores} from "../index";
import _ from 'lodash';
import CatalogBaseStore from "../entity/base/catalogBaseStore/catalogBaseStore";
import SmartViewStore from "../entity/extends/smartViewStore";
import KeyframeStore from "./extends/keyframeStore";
import PartMoveStore from "./extends/partMoveStore";
import FaceRecoStore from "./extends/faceRecoStore";
import CombinePartStore from "./extends/combinePartStore";
import playerUtil from "mam-common-utils/dist/modules/playerUtil";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import catalogTypes from 'src/types/catalogTypes';
import MetadataForm from 'mam-metadata-react/dist/metadataForm';

export default class CatalogStore extends CatalogBaseStore {
    @observable
    public params: catalogTypes.ICatalogEntityParam;
    @observable
    public viewer: any;
    @observable
    public entity: entityTypes.IEntity;
    @observable
    public nameField?: IFormItem
    /** 查询素材详情报错信息 */
    @observable
    public loadError?: string
    /** 视频帧率 */
    public frameRate: number = 0;
    /** 智能相关元数据信息 */
    @observable
    public metadatas: entityTypes.contentIndex.IMetadata[];
    /** 关键帧字典，可通过帧号寻找关键帧 */
    @observable
    public keyframeDict: any;
    @observable
    public selectedTab: string = 'baseInfo';
    @observable
    public editable: boolean = true;
    @observable
    public leftBottomModule: string = 'default';
    @observable
    public isShiftMode: boolean = false;
    /** 人脸、语音、字幕相关 */
    public smartViewStore: SmartViewStore;
    /** 关键帧相关操作 */
    public keyframeStore: KeyframeStore;
    /** 片段移动相关操作 */
    public partMoveStore: PartMoveStore;
    /** 人脸识别相关操作 */
    public faceRecoStore: FaceRecoStore;
    /** 片段合并相关操作 */
    public combinePartStore: CombinePartStore;
    /** 编目信息 */
    @observable
    public catalog: entityTypes.ICatalog[];
    /** 是否显示右侧滑动面板 */
    @observable
    public showRight: boolean = false;

    @observable
    public form: MetadataForm | undefined

    /** 获取按入出点过滤的标记点 */
    @computed
    public get filterKeyframes(): entityTypes.IMarKeyframe[] | undefined{
        if (!this.entity || !this.entity.markeyframes){
            return undefined;
        }
        const currentMeta = this.currentMeta;
        if (currentMeta){
            const inpoint = currentMeta.inpoint
            const outpoint = currentMeta.outpoint
            return this.entity.markeyframes.filter((keyframe)=>{
                let l100ns = parseInt(keyframe.inpoint.toFixed(7).replace('.',''), 10)
                return l100ns >= inpoint && l100ns <= outpoint;
            })
        }
        else {
            return this.entity.markeyframes;
        }
    }

    public constructor(stores: Stores) {
        super(stores);
        this.smartViewStore = new SmartViewStore(this.stores, this);
        this.keyframeStore = new KeyframeStore(this.stores, this);
        this.partMoveStore = new PartMoveStore(this.stores, this);
        this.faceRecoStore = new FaceRecoStore(this.stores, this);
        this.combinePartStore = new CombinePartStore(this.stores, this);
    }

    @action
    public setStoryId(id?: string){
        this.params.storyId = id;
        this.metadataOpeStore.selectedTag = undefined;
        this.metadataOpeStore.selectedTagDesc = [];
        //切换story时需要切换当前选中的person
        this.smartViewStore.changePerson(undefined);
    }

    @action
    public setSceneId(id?: string){
        this.params.sceneId = id;
        this.metadataOpeStore.selectedTag = undefined;
        this.metadataOpeStore.selectedTagDesc = [];
        this.smartViewStore.changeCaption('');//切换scene时需要清空caption选中
        //切换scene时需要切换当前选中的person
        this.smartViewStore.changePerson(undefined);
    }

    @action
    public setShotId(id?: string){
        this.params.shotId = id;
        this.metadataOpeStore.selectedTag = undefined;
        this.metadataOpeStore.selectedTagDesc = [];
        this.smartViewStore.changeCaption('');//切换scene时需要清空caption选中
        //切换shot时需要切换当前选中的person
        this.smartViewStore.changePerson(undefined);
    }

    @action
    public setParams(params: catalogTypes.ICatalogEntityParam) {
        this.params = params;
    }

    @action
    public setViewer(viewer: any) {
        this.viewer = viewer;
    }

    @action
    public getInitializeData(contentId: string): Promise<entityTypes.IEntity> {
        return new Promise((resolve, reject) => {
            entityApi.getInitializeData(contentId, this.stores.catalogStore.params.isCatalog).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.entity = res.data.data;
                        this.entity.contentId = contentId;
                        this.nameField = _.find(this.entity.entitydata, {
                            fieldName: 'name_'
                        });
                        this.entity.entityName = !this.nameField ? '' : this.nameField.value as string;
                        this.frameRate = this.entity.paths && this.entity.paths.length > 0 ? this.entity.paths[0].frameRate : 0;
                        if (!this.frameRate){//如果paths里没有帧率，则到元数据里取
                            const frameRateMeta = _.find(this.entity.entitydata, {fieldName: 'framerate'});
                            if (frameRateMeta && frameRateMeta.value){
                                this.frameRate = frameRateMeta.value as number;
                            }
                        }
                        this.initKeyframeDict(this.entity.fileGroups);
                        resolve(this.entity);
                    }
                    else {
                        reject();
                    }
                });
            }, (res) => {
                runInAction(() => {
                    if (res.data.error.code === 'B1066') {
                        this.loadError = '系统未定义该类型，无法浏览。';
                    } else {
                        this.loadError = res.data.error.title;
                    }
                    reject();
                });
            })
        });
    }

    @action
    public getCatalog() {
        return new Promise((resolve, reject) => {
            const entity = this.entity;
            //获取节目类型
            let programForm = '';
            let pf = _.find(this.entity.entitydata, {
                fieldName: 'programform'
            });
            if (pf && pf.value) {
                let pfvalue = pf.value 
                try{
                    pfvalue = JSON.parse(pf.value as string);
                }catch(err){
                    pfvalue = pf.value
                }
                if (_.isArray(pfvalue) && pfvalue.length > 0) {
                    programForm = pfvalue[0];
                }
            }
            entityApi.getCatalog(entity.contentId, entity.type, entity.paths && entity.paths.length > 0 ? entity.paths[0].frameRate : 0, programForm).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.catalog = res.data.data;
                        resolve();
                    }
                })
            })
        });
    }

    @action
    public setEditable(editable: boolean) {
        this.editable = editable;
    }

    @action
    public initKeyframeDict(fileGroups: entityTypes.IFileGroup[]) {
        this.keyframeDict = {};
        fileGroups.forEach(groupItem => {
            if (!groupItem || groupItem.groupName !== 'keyframe_') {
                return;
            }
            groupItem.fileItems.forEach(fileItem => {
                if (fileItem.fileFormat.keyFrameFormat) {
                    this.keyframeDict[fileItem.fileFormat.keyFrameFormat.keyFrameNo] = {
                        keyframePath: fileItem.filePath
                    };
                }
            });
        });
    }

    /** 切换片段是否check状态 */
    @action
    public switchPartCheckStatus(part: entityTypes.contentIndex.IMetaOriginal, check: boolean){
        let checkedParts = this.getCheckedParts();
        if (checkedParts && checkedParts.length > 0){
            //如果选择了不同类型的片段，这清除之前选择的
            if (checkedParts[0].partType !== part.partType){
                checkedParts.forEach((o)=>{
                    super.switchPartCheckStatus(o, false);
                })
                checkedParts = [];
                super.switchPartCheckStatus(part, check);
                return;
            }
            let parent = this.getParent(checkedParts[0]);
            //如果选择了不同层级的片段，这清除之前选择的
            if (parent && !this.isParentOf(part, parent)){
                checkedParts.forEach((o)=>{
                    super.switchPartCheckStatus(o, false);
                })
                checkedParts = [];
                super.switchPartCheckStatus(part, check);
                return;
            }
            let firstPartIdx = this.getPartIndex(checkedParts[0]);
            let lastPartIdx = this.getPartIndex(checkedParts[checkedParts.length - 1]);
            let partIdx = this.getPartIndex(part);
            let metaList= this.getMetaListByPart(part);
            if (this.isShiftMode){//按住shift键
                if (check && checkedParts && checkedParts.length > 0){
                    //选中中间的片段
                    if (lastPartIdx < partIdx){
                        for (let i = lastPartIdx + 1; i < partIdx; i++){
                            super.switchPartCheckStatus(metaList[i], check);
                        }
                    }
                    else{
                        for (let i = partIdx + 1; i < lastPartIdx; i++){
                            super.switchPartCheckStatus(metaList[i], check);
                        }
                    }
                }
                else if (!check && partIdx > firstPartIdx && checkedParts && checkedParts.length > 0){
                    //清空后面的片段
                    for (let i = partIdx + 1; i <= lastPartIdx; i++){
                        super.switchPartCheckStatus(metaList[i], check);
                    }
                }
            }
            else {
                if (check && checkedParts && checkedParts.length > 0){
                    //清除之前的不连续片段
                    if (partIdx - lastPartIdx > 1){
                        checkedParts.forEach((o)=>{
                            super.switchPartCheckStatus(o, false);
                        })
                        checkedParts = [];
                    }
                    //清除之后的不连续片段
                    else if (firstPartIdx - partIdx > 1){
                        checkedParts.forEach((o)=>{
                            super.switchPartCheckStatus(o, false);
                        })
                        checkedParts = [];
                    }
                }
                else if (!check && partIdx > firstPartIdx && checkedParts && checkedParts.length > 0){
                    //清空后面的片段
                    for (let i = partIdx + 1; i <= lastPartIdx; i++){
                        super.switchPartCheckStatus(metaList[i], check);
                    }
                }
            }
        }
        super.switchPartCheckStatus(part, check);
    }

    @action
    public changeTab(tabKey: string) {
        this.selectedTab = tabKey
    }

    @action
    public setLeftBottomModule(moduleName: string){
        this.leftBottomModule = moduleName;
    }

    @action
    public setShiftMode(mode: boolean){
        this.isShiftMode = mode;
    }

    @action
    public setEntity(entity:entityTypes.IEntity){
        this.entity = entity
    }

    @action
    public setForm(form:MetadataForm){
        this.form = form
        return this.form
    }

    @action
    public setShowRight(show: boolean){
        this.showRight = show;
    }

    protected getCurrentStore(){
        return this;
    }
}