import ExtendStore from "../base/extendStore"
import { observable, action, runInAction } from 'mobx'
import navApi from 'src/apis/navApi';
import { message } from 'antd';
import { Stores } from '..';
import ManageCenterStore from "./manageCenterStore";

export default class LeftNavStore extends ExtendStore {
    @observable
    public currentKey:string = '10'
    @observable
    public openKey: string[] = ['1']
    private manageCenterStore: ManageCenterStore

    public constructor(stores: Stores, manageCenterStore: ManageCenterStore){
        super(stores)
        this.manageCenterStore = manageCenterStore
    }

    @action
    public setCurrentKey = (currentKey:string)=>{
        this.currentKey = currentKey
    }

    @action
    public setOpenKey = (openKey:string[])=>{
        this.openKey = openKey
    }
    
}
