import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import ZjStore from '../../zjStore';
import zhejiangApi from 'src/custom/zhejiang/apis/zhejiangApi'
import { message } from 'antd'

export default class IndexStore extends ExtendStore {
    @observable
    public currBanner: any; // 当前选中的banner
    @observable
    public bannerList: any[] = [] // 首页banner列表
    @observable
    public thumbList: any[] = [] // 首页banner列表
    @observable
    public isClickBanner: boolean = true // 控制是否触发首页轮播图点击事件
    private zjStore: ZjStore;
    
    public constructor(stores: Stores, zjStore: ZjStore) {
        super(stores);
        this.zjStore = zjStore;
    }

    //获取bannerList
    @action
    public getBannerList(type?: string){
        return new Promise((resolve, reject) => {
            zhejiangApi.queryStatistics(type ? type : 'homepage_push_entitys').then((res) => {
                runInAction(() => {
                    if(res.data.success && res.data.data){
                        if(res.data.data.statistics_result.entitys.length >= 8) {
                            res.data.data.statistics_result.entitys.forEach((item: any, index: number)=> {
                                if(index < 8) {
                                    this.bannerList.push(item)
                                }
                            })
                        } else {
                            this.bannerList = res.data.data.statistics_result.entitys
                        }
                        this.setCurrBanner(this.bannerList[0])
                    }
                    resolve(true)
                })
            },res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject()
            })
        })
    }

    @action
    public getThumbList(){
        return new Promise((resolve, reject) => {
            zhejiangApi.queryStatistics('homepage_carousel_entitys').then((res) => {
                runInAction(() => {
                    if(res.data.success && res.data.data){
                        this.thumbList = res.data.data.statistics_result.entitys
                    }
                    resolve(true)
                })
            },res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject()
            })
        })
    }

    @action
    public setCurrBanner(banner: any){
        this.currBanner = banner;
    }

    @action
    public setIsClickBanner(payload: boolean){
        this.isClickBanner = payload;
    }
}