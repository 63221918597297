import BaseStore from "../base/baseStore";
import { action, observable, runInAction } from "mobx";
import { Stores } from "../index";
import entityTypes from "../../types/entityTypes";
import entityApi from "../../apis/entityApi";
import { ContentBoxItemType } from "../../pages/cc/modules/contentBox/contentBox";
import _ from 'lodash';
import playerUtil from "mam-common-utils/dist/modules/playerUtil";

export interface IHypermediaPreviewBoxStore {
    hypermediaPreviewBoxStore: HypermediaPreviewBoxStore;

    initHypermediaPreviewBoxStore: (stores: Stores) => void;
}

export default class HypermediaPreviewBoxStore extends BaseStore<Stores> {
    @observable
    public showHypermediaPreviewBox: boolean = false;
    @observable
    public viewer: any;
    @observable
    public currentEntity: entityTypes.IEntity;
    @observable
    public reporter?: string;
    @observable
    public dateTime?: string;

    @action
    public setShowHypermediaPreviewBox(show: boolean) {
        this.showHypermediaPreviewBox = show;
    }

    @action
    public setViewer(viewer: any) {
        this.viewer = viewer;
    }

    @action
    public preview(item: ContentBoxItemType) {
        this.getInitializeData(item.contentId).then(() => {
            this.setShowHypermediaPreviewBox(true);
        });
    }

    private getInitializeData(contentId: string) {
        return new Promise((resolve) => {
            entityApi.getInitializeData(contentId).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.currentEntity = res.data.data;
                        this.currentEntity.contentId = contentId;
                        const nameField = _.find(this.currentEntity.entitydata, { fieldName: 'name_' });
                        const reporterField = _.find(this.currentEntity.entitydata, { fieldName: 'reporter' });
                        const createDateField = _.find(this.currentEntity.entitydata, { fieldName: 'createDate_' });
                        if (nameField) {
                            this.currentEntity.entityName = nameField.value as string;
                        }
                        if (reporterField) {
                            this.reporter = reporterField.value as string;
                        }
                        if (createDateField) {
                            this.dateTime = createDateField.value as string;
                        }
                        this.viewer.exec('init', {
                            'baseServerPath': process.env.DEV_SERVER,
                            entity: this.currentEntity
                        });
                        resolve();
                    }
                })
            }, (res) => {
            })
        });
    }
}
