import OutstoreStore from "./outstoreStore";
import {action, observable} from "mobx";
import mmsTypes from "../../../types/mmsTypes";
import _ from 'lodash';

export default class EntityTableStore{
    @observable
    public allTranscodeTemplateID?: string;
    private outstoreStore: OutstoreStore;
    constructor(outstoreStore: OutstoreStore){
        this.outstoreStore = outstoreStore;
    }

    @action
    public handleAllTranscodeTemplate = (transcodeTemplateID: string) => {
        this.setAllTranscodeTemplateID(transcodeTemplateID);
        if(this.outstoreStore.entitys && this.outstoreStore.entitys.length > 0){
            this.outstoreStore.entitys.forEach((entityInfo)=>{
                if (_.findIndex(entityInfo.entity.templates, {'transcodeTemplateID': transcodeTemplateID}) > -1)
                {
                    if(transcodeTemplateID === '1' && !entityInfo.entity.canExportOriginalFormat){
                        entityInfo.entity.transcodetemplate = ''//若全部选中原格式，但该素材又不支持原格式出库，则需要清空
                    }else{
                        entityInfo.entity.transcodetemplate = transcodeTemplateID;
                    }
                }
            })
        }
    }

    @action
    public handleEntityTranscodeTemplate = (entityInfo: mmsTypes.exportTypes.IGetEntityInfo, transcodeTemplateID: string) => {
        entityInfo.entity.transcodetemplate = transcodeTemplateID;
        this.setAllTranscodeTemplateID(undefined);
    }

    @action
    public setAllTranscodeTemplateID(id?: string){
        this.allTranscodeTemplateID = id;
    }

    @action
    public showOrHideRename = (entityInfo: mmsTypes.exportTypes.IGetEntityInfo, show: boolean)=>{
        entityInfo.entity.editMode = show;
    }
    @action
    public rename = (entityInfo: mmsTypes.exportTypes.IGetEntityInfo, value: string)=>{
        entityInfo.entity.title = value;
    }
}
