import { Stores } from 'src/stores';
import ExtendStore from 'src/stores/base/extendStore';
import ZjStore from '../../zjStore';
import { action, runInAction, observable } from 'mobx';
import iIndexTypes from 'src/types/iIndexTypes';
import zhejiangTypes from 'src/custom/zhejiang/types/zhejiangTypes';
import iIndexApi from 'src/apis/iIndexApi';
import zhejiangApi from 'src/custom/zhejiang/apis/zhejiangApi';
import { message } from 'antd';
import _ from 'lodash'

interface IPersonReq {
    category: string
    pageIndex: number
    pageSize: number
}
export default class PersonStore extends ExtendStore {
    @observable
    public tabCategories: iIndexTypes.secList.ICategoryTagCountRes[];
    /** 选中的类型 */
    @observable
    public selectedTab: string;
    /** 关键字 */
    @observable
    public keyword: string = '';
    /** 列表 */
    @observable
    public listRes?: zhejiangTypes.IPersonRes;
    @observable
    public isReqList: boolean = false;
    @observable
    public pageSize: number = 30;
    public category: string;
    private zjStore: ZjStore

    public constructor(stores: Stores, zjStore: ZjStore) {
        super(stores);
        this.zjStore = zjStore;
    }

    @action
    public getTabCategories() {
        return new Promise((resolve, reject) => {
            zhejiangApi.queryStatistics('all_person_category').then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        let newArr: iIndexTypes.secList.ICategoryTagCountRes[] = []
                        for(let i in res.data.data.statistics_result) {
                            if(i) {
                                let obj: any = {}
                                obj.category = i
                                obj.count = res.data.data.statistics_result[i]
                                newArr.push(obj)
                            }
                        }
                        let allCount = _.sumBy(newArr, 'count')
                        newArr.unshift({
                            category: '全部',
                            count: allCount
                        });
                        this.tabCategories = newArr;
                        if (this.tabCategories.length > 0) {
                            this.setSelectedTab(this.tabCategories[0].category);
                        }
                    }
                })
                resolve(true);
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public getList(page?: number) {
        let params: zhejiangTypes.IPersonReq = {
            category: this.selectedTab === '全部' ? '' : this.selectedTab,
            name: this.keyword,
            pageIndex: page || 1,
            pageSize: this.pageSize
        }
        this.listRes = undefined;
        this.isReqList = true;
        zhejiangApi.getPersonList(params).then(res => {
            runInAction(() => {
                if (res.data.data) {
                    this.listRes = res.data.data;
                    this.isReqList = false;
                }
                else {
                    this.isReqList = false;
                }
            })
        }, res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title);
            }
            runInAction(() => {
                this.isReqList = false;
            });
        });
    }

    @action
    public setSelectedTab(tab: string) {
        this.selectedTab = tab;
    }

    @action
    public setKeyword(keyword: string) {
        this.keyword = keyword;
    }

    @action
    public setPageSize(size: number) {
        this.pageSize = size;
    }
}