import * as React from 'react';
import BaseComponent from "../../../../../../baseComponent/baseComponent";
import {observer} from "mobx-react";
import {Tag,Tooltip} from "antd";
import _ from 'lodash';
import {stores} from "../../../../../../stores";
import {SmartMetadataType} from "../../../../../../stores/entity/extends/smartViewStore";
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import {sensitiveReg} from "mam-metadata-react/dist/controls/mfcTag/mfcTag";

interface IProps{
    partInfo: ITagPartInfo
}

export interface ITagPartInfo{
    topic?: string
    eventpeople?: string
    scenepeople?: string
    eventplace?: string
    sceneplace?: string
    eventorg?: string
    sceneorg?: string
    keyword?: string
    behavior?: string
    background?: string
    face?: string
    partType?: SmartMetadataType
}

export interface ITag{
    name: string
    fieldAlias?: string
    type: string
    priority: number
}

/** 提取标签 */
export let extractTags = (item: ITagPartInfo): ITag[]=>{
    const topic = item.topic ? item.topic.split(',') : [];//分类
    const eventpeople = item.eventpeople ? item.eventpeople.split(',') : [];//人物
    const scenepeople = item.scenepeople ? item.scenepeople.split(',') : [];//人物
    const face = item.face ? item.face.split(',') : [];//人脸
    const eventplace = item.eventplace ? item.eventplace.split(',') : [];//地点
    const sceneplace = item.sceneplace ? item.sceneplace.split(',') : [];//地点
    const eventorg = item.eventorg ? item.eventorg.split(',') : [];//机构
    const sceneorg = item.sceneorg ? item.sceneorg.split(',') : [];//机构
    const keyword = item.keyword ? item.keyword.split(',') : [];//关键词
    const behavior = item.behavior ? item.behavior.split(',') : [];//行为
    //const background = item.background ? item.background.split(',') : [];//背景
    const tags: ITag[] = [];
    for (let i = 0; i < 10; i++){
        if (tags.length >= 16){
            break;
        }
        if (topic[i] !== undefined){
            pushTag(item, tags, topic[i], 'topic', 1);
        }
        if (eventpeople[i] !== undefined){
            pushTag(item, tags, eventpeople[i], 'eventpeople', 2);
        }
        if (scenepeople[i] !== undefined){
            pushTag(item, tags, scenepeople[i], 'scenepeople', 3);
        }
        if (face[i] !== undefined){
            pushTag(item, tags, face[i], 'face', 11);
        }
        if (eventplace[i] !== undefined){
            pushTag(item, tags, eventplace[i], 'eventplace', 4);
        }
        if (sceneplace[i] !== undefined){
            pushTag(item, tags, sceneplace[i], 'sceneplace', 5);
        }
        if (eventorg[i] !== undefined){
            pushTag(item, tags, eventorg[i], 'eventorg', 6);
        }
        if (sceneorg[i] !== undefined){
            pushTag(item, tags, sceneorg[i], 'sceneorg', 7);
        }
        if (keyword[i] !== undefined){
            pushTag(item, tags, keyword[i], 'keyword', 8);
        }
        if (behavior[i] !== undefined){
            pushTag(item, tags, behavior[i], 'behavior', 9);
        }
        // if (background[i] !== undefined){
        //     pushTag(item, tags, background[i], 'background', 10);
        // }
    }
    //先按type分类，然后再按priority排序
    return _.orderBy(_.sortBy(tags, ['type']), ['priority'], ['asc']);
}

let getAliasByFieldName = (item: ITagPartInfo, fieldName: string): string | undefined=>{
    if (item.partType && stores.entityStore.mediaStore.catalog){
        let catalogCode = item.partType.substring(item.partType.lastIndexOf('_') + 1);
        if (catalogCode === 'story'){//暂时将story转换成sequence
            catalogCode = 'sequence';
        }
        let catalogInfo = _.find(stores.entityStore.mediaStore.catalog, {code: catalogCode})
        if (catalogInfo){
            let fd = _.find(catalogInfo.fields, {'fieldName': fieldName});
            if (fd){
                return fd.alias;
            }
        }
    }
    return undefined;
}

let pushTag = (item: ITagPartInfo, arr: ITag[], val: string, type: string, priority: number)=>{
    if (_.findIndex(arr, (o)=>{
            return o.name.replace(/\(.*?\)/g, '') === val.replace(/\(.*?\)/g, '');
        }) === -1){
        arr.push({
            name: val.replace(sensitiveReg, '').replace(/\(.*?\)/g, ''),
            fieldAlias: getAliasByFieldName(item, type),
            type,
            priority
        });
    }
}

@observer
class Tags extends BaseComponent<IProps, any> {
    constructor(props: IProps) {
        super(props)
    }

    public getRender() {
        const tags = extractTags(this.props.partInfo);
        return <div className="tags">
            {
                tags.map((kw, index: number)=>{
                    return <Tooltip title={(kw.fieldAlias ? kw.fieldAlias + '：' : '') + kw.name} placement={'bottom'} key={index}>
                        <Tag className={'tag-' + kw.type}>{commonUtil.getCutStr(kw.name, 6)}</Tag>
                    </Tooltip>;
                })
            }
        </div>
    }
}

export default Tags