import { AxiosPromise } from 'axios'
import {http} from '../utils/http'
import {IConfigRes, IConfig} from "../types/configTypes";
import {IResponse} from "mam-core-react/dist/modules/http";
import { ILoginReq } from 'src/types/userTypes';
import { IUsersDataRes } from 'src/types/userTypes';

namespace userApi {

    export const login = (req: ILoginReq): AxiosPromise<IResponse<IConfigRes<IConfig>>> => {
        return http.post<IResponse<IConfigRes<IConfig>>>('/user/login', req)
    }

    /**
     * 获取所有用户
     */
    export const getUsersData = (keyword:string,pageSize:number): AxiosPromise<IResponse<IUsersDataRes>> => {
        return http.post<IResponse<IUsersDataRes>>('/user/get-users-by-keyword',{keyword,pageSize});
    }

}

export default userApi
