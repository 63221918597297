import ExtendStore from "src/stores/base/extendStore";
import AuditEntityStore from "./extends/entityStore";

export default class ExportAuditExtendStore extends ExtendStore {
    public entityStore: AuditEntityStore;

    public initEntityStore() {
        this.entityStore = new AuditEntityStore(this.stores, this as any);
    }
}
