import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import {Stores} from "../index";

export default class BatchSmartProcessStore extends BaseStore<Stores>{
    @observable
    public showBatchSmartProcessModal:boolean = false

    @observable
    public items:any[]

    @action
    public setShowobBatchSmartProcessModal(show:boolean){
        this.showBatchSmartProcessModal = show
    }

    @action
    public setItems(items:any[]){
        this.items = items
    }
}