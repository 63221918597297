import BaseStore from 'src/stores/base/baseStore';
import { observable, action, runInAction, computed } from 'mobx';
import entityTypes from 'src/types/entityTypes';
import ccTypes from 'src/types/ccTypes';
import _ from 'lodash';
import entityApi from "../../../apis/entityApi";
import ccApi from 'src/apis/ccApi';
import { Stores } from "../../index";
import EntityStore from "../entityStore";
import CatalogStore from "../../catalog/catalogStore";
import playerUtil from "mam-common-utils/dist/modules/playerUtil";
import RegularSettingStore from './regularSettingStore';
import { message } from 'antd';
import IEntityStore from 'src/stores/iEntity/iEntityStore';

export enum SmartMetadataType {
    face = 'model_sobey_smart_face_',// 人脸
    voice = 'model_sobey_smart_voice_', // 语音
    subtitle = 'model_sobey_smart_subtitles_',// 字幕
    cut = 'model_sobey_smart_cut_',// 拆条
    fragment = 'model_sobey_smart_fragment_',// 转场片段
    story = 'model_sobey_smart_story',
    scene = 'model_sobey_smart_scene',
    shot = 'model_sobey_smart_shot',//镜头层
    entity = 'model_sobey_smart_entity',//片段tags如出点信息
    otcInfo = 'model_sobey_object_otcinfo'//otc时码信息
}

export default class SmartViewStore extends BaseStore<Stores> {
    /** 视频帧率 */
    public frameRate: number = 0
    @observable
    public selectedPerson: entityTypes.SmartInfo.ISmartPersonView | undefined
    @observable
    public selectedCaption: string

    /** 智能分析数据 */
    @observable
    public smartMetadatas: entityTypes.SmartInfo.ISmartMetadatas;
    /** 关键帧以帧号为key的字典 */
    // @observable
    // public keyframeDict: { [key: number]: entityTypes.IKeyframeInfo; } = {}
    /** 智能人物列表，person.tsx顶部显示的那些头像列表 */
    @observable
    public smartPerson: entityTypes.SmartInfo.ISmartPersonView[] = []
    /** 未识别人物列表，person.tsx顶部显示的那些头像列表 */
    @observable
    public unknownSmartPerson: entityTypes.SmartInfo.ISmartPersonView[] = []
    @observable
    public smartVoice: entityTypes.SmartInfo.ISmartCaptions[] = []
    @observable
    public smartSubtitle: entityTypes.SmartInfo.ISmartCaptions[] = []
    // 包含此人物的视频
    @observable
    public containePerson: ccTypes.searchTypes.IFullSearchRes | undefined = {
        queryResult: {
            pageIndex: 0,
            pageTotal: 0,
            pageSize: 0,
            recordTotal: 0,
            data: []
        },
        facets: [],
        sortFields: [],
        usedTime: 0
    }
    @observable
    public selectedSegment: entityTypes.SmartInfo.IViewSegment | undefined;
    /** 当前显示的人脸类型 */
    @observable
    public currPersonFaceViewType: entityTypes.SmartInfo.FaceViewType = entityTypes.SmartInfo.FaceViewType.KNOWN
    /** 是否正在加载人脸数据 */
    @observable
    public isLoadingFaces = true;
    @observable
    public isLoadingVoices = true;
    @observable
    public isLoadingSubtitles = true;

    public regularSettingStore: RegularSettingStore;

    public parentStore: EntityStore | IEntityStore | CatalogStore;

    public constructor(stores: Stores, parentStore: EntityStore | IEntityStore | CatalogStore) {
        super(stores)
        this.parentStore = parentStore;
        this.regularSettingStore = new RegularSettingStore(stores, this);
    }

    public getSmartPerson(){
        if (this.currPersonFaceViewType ===  entityTypes.SmartInfo.FaceViewType.KNOWN){
            return this.smartPerson;
        }
        return this.unknownSmartPerson;
    }

    @computed
    public get smartPerson4Part() {
        const currentMeta = this.getCatalogStore().currentMeta;
        if (!currentMeta) {
            return this.getSmartPerson();
        }
        else {
            let persons = this.getSmartPerson();
            if (this.currPersonFaceViewType ===  entityTypes.SmartInfo.FaceViewType.UNKNOWN){
                persons = this.unknownSmartPerson;
            }
            return persons.filter((person) => {
                let isSame = false;
                person.segment.forEach((seg) => {
                    // if (seg.in >= currentMeta.inpoint && seg.out <= currentMeta.outpoint){
                    //     isSame = true;
                    // }
                    if ((seg.in >= currentMeta.inpoint && seg.in <= currentMeta.outpoint
                        && seg.out >= currentMeta.inpoint && seg.out <= currentMeta.outpoint)
                        || (seg.in < currentMeta.inpoint && seg.out > currentMeta.inpoint)
                        || (seg.out > currentMeta.outpoint && seg.in < currentMeta.outpoint)) {
                        isSame = true;
                    }
                })
                return isSame
            });
        }
    }
    /** 某一person满足条件的segment */
    public getSmartPersonSegment4Part(person: entityTypes.SmartInfo.ISmartPersonView) {
        const currentMeta = this.getCatalogStore().currentMeta;
        if (!currentMeta) {
            return person.segment;
        }
        else {
            return person.segment.filter((segment) => {
                // return (segment.in >= currentMeta.inpoint && segment.in <= currentMeta.outpoint)
                //     || (segment.out >= currentMeta.inpoint && segment.out <= currentMeta.outpoint)
                return (segment.in >= currentMeta.inpoint && segment.in <= currentMeta.outpoint
                    && segment.out >= currentMeta.inpoint && segment.out <= currentMeta.outpoint)
                    || (segment.in < currentMeta.inpoint && segment.out > currentMeta.inpoint)
                    || (segment.out > currentMeta.outpoint && segment.in < currentMeta.outpoint)
            });
        }
    }
    /** 某一片段的subtitle */
    @computed
    public get smartSubtitle4Part() {
        const currentMeta = this.getCatalogStore().currentMeta;
        if (!currentMeta) {
            return this.smartSubtitle;
        }
        else {
            return this.smartSubtitle.filter((subtitle) => {
                // return (subtitle.in >= currentMeta.inpoint && subtitle.in <= currentMeta.outpoint)
                //     || (subtitle.out >= currentMeta.inpoint && subtitle.out <= currentMeta.outpoint)
                return (subtitle.in >= currentMeta.inpoint && subtitle.in <= currentMeta.outpoint
                    && subtitle.out >= currentMeta.inpoint && subtitle.out <= currentMeta.outpoint)
                    || (subtitle.in < currentMeta.inpoint && subtitle.out > currentMeta.inpoint)
                    || (subtitle.out > currentMeta.outpoint && subtitle.in < currentMeta.outpoint)
            });
        }
    }
    /** 某一片段的voice */
    @computed
    public get smartVoice4Part() {
        const currentMeta = this.getCatalogStore().currentMeta;
        if (!currentMeta) {
            return this.smartVoice;
        }
        else {
            return this.smartVoice.filter((voice) => {
                // return (voice.in >= currentMeta.inpoint && voice.in <= currentMeta.outpoint)
                //     || (voice.out >= currentMeta.inpoint && voice.out <= currentMeta.outpoint)
                return (voice.in >= currentMeta.inpoint && voice.in <= currentMeta.outpoint
                    && voice.out >= currentMeta.inpoint && voice.out <= currentMeta.outpoint)
                    || (voice.in < currentMeta.inpoint && voice.out > currentMeta.inpoint)
                    || (voice.out > currentMeta.outpoint && voice.in < currentMeta.outpoint)
            });
        }
    }

    // 构建智能展示信息，目前只有search22在用
    @action
    public handleSmartMetadatas(entity: entityTypes.IEntity, metadatas: any[]) {
        let res = {};
        _.forEach(metadatas, (item: any) => {
            switch (item.type) {
                case SmartMetadataType.subtitle:// 字幕
                    item.metadata = _.orderBy(item.metadata, ['in'], ['asc']);
                    this.getSubtitleCaptions(item.metadata)
                    break;
                case SmartMetadataType.voice:
                    item.metadata = _.orderBy(item.metadata, ['in'], ['asc']);
                    this.getVoiceCaptions(item.metadata)
                    break;
                case SmartMetadataType.face:
                    this.getPersonInfo(item.metadata)
                    break;
                default:
                    break;
            }
            res[item.type] = item;
        });

        if (_.isArray(this.stores.configStore.config.ips.field)) {
            this.stores.configStore.config.ips.field.forEach(item => {
                if (!res[item.key]) {
                    res[item.key] = { enable: item.enable };
                } else {
                    res[item.key].enable = item.enable;
                }
            });
        }
        this.smartMetadatas = res;
    }

    //获取人脸信息
    @action
    public getFaces(){
        return new Promise((resolve, reject)=>{
            this.isLoadingFaces = true;
            this.smartPerson = [];
            this.changePerson(undefined);
            this.setSelectedSegment(undefined);
            entityApi.getMetadataByType(this.getEntityStore().params.contentId, SmartMetadataType.face).then(res=>{
                runInAction(()=>{
                    if (res.data.data && res.data.data.length > 0 && res.data.data[0].metadata){
                        //无管理员权限，则屏蔽unnamed的人脸
                        if (!this.stores.userStore.currentUser?.isAdmin){
                            res.data.data[0].metadata = res.data.data[0].metadata.filter((item: any)=>{
                                return item.name !== 'unnamed';
                            })
                        }
                        res.data.data[0].metadata = _.orderBy(res.data.data[0].metadata, ['isKnown'], ['desc']);
                        this.getPersonInfo(res.data.data[0].metadata as any)
                        if (!this.smartMetadatas){
                            this.smartMetadatas = {};
                        }
                        this.smartMetadatas[SmartMetadataType.face] = res.data.data[0] as any;
                        this.isLoadingFaces = false;
                        resolve();
                    }
                    else {
                        this.isLoadingFaces = false;
                        resolve();
                    }
                })
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                    reject();
                }
            });
        })
    }

    //获取语音信息
    @action
    public getVoices(){
        return new Promise((resolve, reject)=>{
            this.isLoadingVoices = true;
            this.smartVoice = [];
            entityApi.getMetadataByType(this.getEntityStore().params.contentId, SmartMetadataType.voice).then(res=>{
                runInAction(()=>{
                    if (res.data.data && res.data.data.length > 0 && res.data.data[0].metadata){
                        res.data.data[0].metadata = _.orderBy(res.data.data[0].metadata, ['in'], ['asc']);
                        this.getVoiceCaptions(res.data.data[0].metadata as any)
                        if (!this.smartMetadatas){
                            this.smartMetadatas = {};
                        }
                        this.smartMetadatas[SmartMetadataType.voice] = res.data.data[0] as any;
                        this.isLoadingVoices = false;
                        resolve();
                    }
                    else {
                        this.isLoadingVoices = false;
                        resolve();
                    }
                })
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    //获取字幕信息
    @action
    public getSubtitles(){
        return new Promise((resolve, reject)=>{
            this.isLoadingSubtitles = true;
            this.smartSubtitle = [];
            entityApi.getMetadataByType(this.getEntityStore().params.contentId, SmartMetadataType.subtitle).then(res=>{
                runInAction(()=>{
                    if (res.data.data && res.data.data.length > 0 && res.data.data[0].metadata){
                        res.data.data[0].metadata = _.orderBy(res.data.data[0].metadata, ['in'], ['asc']);
                        this.getSubtitleCaptions(res.data.data[0].metadata as any)
                        if (!this.smartMetadatas){
                            this.smartMetadatas = {};
                        }
                        this.smartMetadatas[SmartMetadataType.subtitle] = res.data.data[0] as any;
                        this.isLoadingSubtitles = false;
                        resolve();
                    }
                    else {
                        this.isLoadingSubtitles = false;
                        resolve();
                    }
                })
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public changePerson(guid: string | undefined) {
        this.selectedPerson = this.getSmartPerson().find(s => s.guid_ === guid);
        this.getPersonDesc();
        if (this.selectedPerson) {
            let segments = this.getSmartPersonSegment4Part(this.selectedPerson);
            if (segments && segments.length > 0) {
                this.setSelectedSegment(segments[0]);
            }
        }
    }
    @action
    public changeCaption(id: string) {
        this.selectedCaption = id
    }
    // 获取包含此人物的视频
    @action
    public getContainPerson = (name: string) => {
        let reqData: ccTypes.searchTypes.IFullSearchReq = {
            conditions: [{ field: "type_", value: ["video"] }],
            keyword: [name],
            page: 1,
            size: 6,
            sortBys: [{ fieldName: "default", isDesc: false }]
        }
        ccApi.searchApi.fullSearch(reqData).then((result) => {
            runInAction(() => {
                this.containePerson = result.data.data
            })
        }).catch((err) => {
            console.log(err)
        });
    }

    public watchPlayer() {
        if (this.getEntityStore().viewer && this.getEntityStore().viewer.player) {
            this.getEntityStore().viewer.player.media.addEventListener('accurateTimeUpdate', (res: any) => {
                this.getSelectedCaption()
            })
        }
    }

    public getSelectedCaption() {
        let seconds = this.getEntityStore().viewer.player.getCurrentTime()
        let n100s = parseInt(seconds.toFixed(7).replace('.', ''), 10);
        let next: entityTypes.SmartInfo.ISmartCaptions | undefined;
        if (this.getSelectedTab() === 'voiceInfo') {
            next = this.smartVoice4Part.find(s => s.in <= n100s && s.out >= n100s)
        } else if (this.getSelectedTab() === 'subtitleInfo') {
            next = this.smartSubtitle4Part.find(s => s.in <= n100s && s.out >= n100s)
        }
        this.changeCaption(next ? next.id : '')
    }

    @action
    public addSmartPerson(person: entityTypes.SmartInfo.ISmartPersonView) {
        this.getSmartPerson().push(person);
    }

    @action
    public updateSmartVoiceItemName(item: entityTypes.SmartInfo.ISmartCaptions, name: string) {
        item.name = name;
        this.updateSmartMetadatasText(SmartMetadataType.voice, item);
    }

    @action
    public updateSmartSubtitleItemName(item: entityTypes.SmartInfo.ISmartCaptions, name: string) {
        item.name = name;
        this.updateSmartMetadatasText(SmartMetadataType.subtitle, item);
    }
    @action
    public setSelectedSegment(seg: entityTypes.SmartInfo.IViewSegment | undefined) {
        this.selectedSegment = seg;
        if (this.selectedSegment) {
            if (this.getEntityStore().viewer && this.getEntityStore().viewer.player && this.selectedSegment.keyframe !== undefined) {
                this.getEntityStore().viewer.player.setCurrentTime(this.selectedSegment.keyframe / Math.pow(10, 7));
            }
        }
    }

    @action
    public buildPersonInfo(metadata: entityTypes.SmartInfo.ISmartPersonMetadata, totalL100Ns: number): entityTypes.SmartInfo.ISmartPersonView {
        const duration = this.getEntityStore().entity.paths && this.getEntityStore().entity.paths.length > 0 ? 
            this.getEntityStore().entity.paths[0].duration : 0;
        const groups = metadata.groups || {}
        const person: entityTypes.SmartInfo.ISmartPersonView = {
            name: metadata.name,
            groups,
            totalTime: '00:00:00:00',
            appearanceRate: 0,
            isSensitive: groups && groups.group ? groups.group.indexOf('敏感人物') > -1 : false,
            segment: [],
            keyFrameUrl: metadata.keyFrameUrl,
            personId: metadata.personId,
            guid_: metadata.guid_,
            videoWidth: metadata.videoWidth,
            videoHeight: metadata.videoHeight,
            isKnown: metadata.isKnown !== undefined ? metadata.isKnown : true,
            facepath: metadata.facepath
        }
        if (metadata.segment) {
            metadata.segment.forEach(o => {
                let obj: entityTypes.SmartInfo.IViewSegment = {
                    timeSize: o.out - o.in,
                    in: o.in,
                    out: o.out,
                    keyframe: o.keyFrame,
                    keyFrameUrl: o.keyFrameUrl,
                    confidence: o.confidence,
                    left: o.left,
                    top: o.top,
                    width: o.width,
                    height: o.height
                };
                totalL100Ns += obj.timeSize
                person.segment.push(obj);
            });
            person.totalTime = playerUtil.second2TC(totalL100Ns / Math.pow(10, 7), 'video', this.frameRate)
            person.appearanceRate = parseFloat((totalL100Ns / duration * 100).toFixed(2))
        }
        return person
    }

    /** 按入点找到对应的人脸和片段 */
    @action
    public selectPersonSegByInpoint(inpoint: number){
        let selectedPerson: entityTypes.SmartInfo.ISmartPersonView | undefined;
        let selectedSeg: entityTypes.SmartInfo.IViewSegment | undefined;
        this.smartPerson.forEach(person=>{
            person.segment.forEach((seg)=>{
                if (seg.in === inpoint){
                    selectedPerson = person;
                    selectedSeg = seg;
                }
            })
        })
        if (selectedPerson && selectedSeg){
            this.changePerson(selectedPerson.guid_);
            this.setSelectedSegment(selectedSeg);
        }
        else {
            console.error('未能按入点找到人脸片段，自动定位失败！');
        }
        return [selectedPerson, selectedSeg]
    }

    /** 把SmartCaptions的name值更新到smartMetadata的text */
    private updateSmartMetadatasText(key: SmartMetadataType, item: entityTypes.SmartInfo.ISmartCaptions) {
        const metadata = _.find(this.smartMetadatas[key].metadata, { guid_: item.id, in: item.in, out: item.out });
        if (metadata) {
            (metadata as any).text = item.name as string || '';
        }
    }

    // 组装人物信息
    @action
    private getPersonInfo(metadataList: entityTypes.SmartInfo.ISmartPersonMetadata[]) {
        metadataList.forEach(s => {
            let totalL100Ns = 0
            let person = this.buildPersonInfo(s, totalL100Ns);
            this.smartPerson.push(person)
        });
    }


    @action
    private getVoiceCaptions(metadataList: entityTypes.SmartInfo.ISmartVoiceMetadata[]) {
        this.smartVoice = metadataList.map(s => {
            return {
                timeSize: s.out - s.in,
                in: s.in,
                out: s.out,
                name: s.text,
                id: s.guid_
            };
        })
    }

    @action
    private getSubtitleCaptions(metadataList: entityTypes.SmartInfo.ISmartSubtitleMetadata[]) {
        this.smartSubtitle = metadataList.map((s) => {
            return {
                timeSize: s.out - s.in,
                in: s.in,
                out: s.out,
                name: s.text,
                id: s.guid_,
                keyframeno: s.keyFrame
            };
        })
    }

    private getPersonDesc() {
        if (this.selectedPerson) {
            entityApi.getTermDetail(this.selectedPerson.personId, this.selectedPerson.name, 'NAME').then((res) => {
                runInAction(() => {
                    if (this.selectedPerson && res.data.data) {
                        this.selectedPerson.desc = {
                            text: res.data.data[0].description,
                            tags: res.data.data[0].tag || []
                        }
                    }
                });
            })
        }
    }

    private getCatalogStore() {
        return (this.parentStore as EntityStore).contentIndexStore || this.parentStore as CatalogStore;
    }

    private getEntityStore() {
        return this.parentStore;
    }

    private getSelectedTab() {
        if (this.getEntityStore() instanceof EntityStore) {
            return (this.getEntityStore().selectedTab as entityTypes.ISelectedTab).top;
        }
        else {
            return this.getEntityStore().selectedTab as string
        }
    }
}
