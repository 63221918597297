import ExtendStore from "../base/extendStore"
import { Stores } from '..';
import { observable, action } from 'mobx';
import contentMgTypes from 'src/types/contentMgTypes';
import LeftNavStore from './leftNavStore';

export default class WorkbenchStore extends ExtendStore {
    @observable
    public iframeInfo: contentMgTypes.IIframeInfo = {
        src: '',
        show: false
    };
    public leftNavStore:LeftNavStore
    
    public constructor(stores: Stores) {
        super(stores);
        this.leftNavStore = new LeftNavStore(stores, this)
    }

    @action
    public setIframeInfo(info: contentMgTypes.IIframeInfo){
        this.iframeInfo = info;
    }
}
