import BaseStore from "../../base/baseStore";
import ccTypes from "../../../types/ccTypes";
import ccApi from "../../../apis/ccApi";
import {action, observable} from "mobx";
import {Stores} from "../../index";
import { message } from 'antd';

export default class PersonalEntityStore extends BaseStore<Stores>{
    @observable
    public folders: ccTypes.cloudTypes.IFullSearchData[] = []
    // 个人空间左侧列表移入store
    @action
    public getFolders() {
        let param: ccTypes.sideContentTypes.IFolderReq = {
            pageIndex: 1,
            pageSize: 100,
            sort: this.stores.configStore.config.dohaUserSortSetting.sortField ?
                this.stores.configStore.config.dohaUserSortSetting.sortField : 'createTime',
            order: this.stores.configStore.config.dohaUserSortSetting.isDesc ? 'desc' : 'asc',
            folderCode: '',
            type: 'folder',
            keyword: '',
            name: ''
        }
        ccApi.sideContentApi.getFolders(param).then((res) => {
            if (res.data.success === true && res.data.data) {
                this.setFolders(res.data.data.data)
            }
        }, (res)=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title)
            }
        })
    }

    @action
    public setFolders(folders: ccTypes.cloudTypes.IFullSearchData[]) {
        this.folders = folders
    }
}