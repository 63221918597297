import favoriteTypes from 'src/types/favoriteTypes';
import { AxiosPromise } from 'axios';
import { http } from "../utils/http";
import {IResponse} from "mam-core-react/dist/modules/http";

namespace favoriteApi {
    export const collect = (req: favoriteTypes.ICollectRequest): AxiosPromise<IResponse<boolean>> => {
        return http.post<IResponse<boolean>>('/favorite', req);
    }

    export const queryTags = (): AxiosPromise<IResponse<favoriteTypes.IFavoritePageResponse>> => {
        return http.get<IResponse<favoriteTypes.IFavoritePageResponse>>('/favorite/query/tags');
    }

    export const queryPageData = (url:string ,req: favoriteTypes.IQueryFullDataRequest): AxiosPromise<IResponse<favoriteTypes.IFavoritePageResponse>> => {
        return http.post<IResponse<favoriteTypes.IFavoritePageResponse>>(url, req) // '/favorite/query/full'
    }
}

export default favoriteApi