import { IConfig, IEntityType } from "./configTypes";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import { IUser } from './userTypes';
import { IValidationErrInfo } from 'mam-core-react/dist/types/validationTypes';

namespace uploadTypes {
    export enum TransferTypes {
        WEB = 1,
        VTUBE = 2
    }

    export enum TaskTypes {
        GENERAL = 1,     // 普通模式
        PICTUREPACKAGEUPLOAD = 3,   // 图片包上传
        GROUPUPLOAD = 2     // 视音频分离上传
    }

    export interface IUploadTypeBtn {
        type: number
        title: string
        icon: string
    }

    export enum UploadModules {
        YUNPAN = 1, // 云盘
        WEBUPLOAD = 2, // web引入
        NECS = 3 // 新闻通联
    }

    export enum RelationContentType {
        NONE = 0, // 不关联其他内容
        MANUSCRIPT = 1, // 新闻通联的稿件
        DATASET = 2, // 资料集
        ATTACHMENT = 3  // 附件上传
    }

    export interface IUploadOptions {
        configInst?: any
        openModal?: (tasks: uploadTypes.ITask[], options: uploadTypes.IUploadOptions) => void
        module?: number
        taskType?: number,
        transferType?: number,
        targetFolder?: string,
        relationContentType?: number,
        relationContentId?: string,
        loginToken?: string,
        writeMetadataBefore?: (tasks: uploadTypes.ITask[], params: IUploadOptions) => boolean
        extraField?: IFormItem[]
    }

    export interface IFile {
        entityType?: string
        file: File | IVtubeFile
        fileName: string
        fileSize?: number
        metadata: ITaskMetadata
        progress?: number
        status?: string
        fileSizeString?: string
        surplusTime?: number
        chunkTotal?: number
        chunkIndex?: number
        image?: string
        type?: number
    }

    export interface IVtubeFile {
        FilePath: string
        FileSize: number
    }

    export interface ITaskMetadata {
        field?: IFormItem[]
        name: string
        ext?: string
        isPass?: boolean
        isReady?: boolean
    }
    export interface ITask {
        id: string
        taskId?: string
        entityType: string
        attachmentFiles?: IFile[]
        files?: IFile[]
        file?: File | IVtubeFile
        fileName?: string
        metadata: ITaskMetadata
        progress: number
        status: string
        checked?: boolean
        sizeTotal?: number
        sizeTotalString?: string
        surplusTime?: number
        chunkTotal?: number
        chunkFinished?: number
        taskType?: number
        targetFolder?: string
        transferType?: number
        taskClientId?: string
        type?: string
        inited?: boolean
        extendData?: any
        createTime?: string
        fileTotal?: number
        isJsUpload?: boolean
        keyframe?: string
        targetFolderName?: string
        targetType?: number
        taskStatus?: number
        userCode?: string
        validErrs?: IValidationErrInfo[]
    }

    export interface IMetadataFieldReq {
        source: string
    }

    export interface IMetadataFieldRes {
        audio: IFormItem[]
        dataset: IFormItem[]
        document: IFormItem[]
        hypermedia: IFormItem[]
        other: IFormItem[]
        picture: IFormItem[]
        rundown: IFormItem[]
        script: IFormItem[]
        video: IFormItem[]
    }

    export interface IUnfinishedTaskReq {
        relationId: string
        relationContentType: number
        targetType: number
    }

    export interface IUnfinishedTaskRes extends uploadTypes.ITask {
        createTime: string
        fileTotal: number
        isJsUpload: boolean
        keyframe: string
        targetFolderName: string
        targetType: number
        taskId: string
        taskStatus: number
        userCode: string
    }

    export interface IUploadConfigUploadRule{
        audio: string
        example: string[]
        name: string
        video: string
    }
    export interface IUpoladConfig {
        // 暂时只用这个参数 没把type写完
        config: {
            uploadedRefreshSpan: number
            uploadRules: IUploadConfigUploadRule[]
        }
        currentUser: IUser  
    }

    export interface IFilterEntityTypes extends IEntityType{
        unchecked?: boolean
    }

    export interface IUploadUrl{
        name: string
        url: string
    }
}
export default uploadTypes
