import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import mmsTypes from '../../types/mmsTypes'
import mmsApi from "../../apis/mmsApi";
import { notification, message } from 'antd'
import _ from 'lodash'
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import {Stores} from "../index";


export default class DownloadStore extends BaseStore<Stores> {
    // 所有的选中的item 
    @observable
    public selectedItems: mmsTypes.downloadTypes.ISingleDownloadItemInfo[] = []
    // 下载弹窗的显示与隐藏
    @observable
    public isShowDownload: boolean = false
    // 所有item 包含选择的和没有选择的
    @observable
    public allItems: mmsTypes.downloadTypes.ISingleDownloadItemInfo[] = []
    // 是否有选中的项目 用于底部footer的下载的使能
    @observable
    public isSelectedItem: boolean = false
    // 所有的a链接，触发下载
    @observable
    public allALinks: object = {}
    // 所有准备初始化的contentIds 
    @observable
    public allContentIds: string[] = []

    // 控制下载弹窗的显示与隐藏
    @action
    public setModalSH(show: boolean) {
        this.isShowDownload = show;
    }

    // 初始化items
    @action
    public initAllItems() {
        this.allItems = []
        // 临时的测试数据的contentId
        let contentIdsArr: string[] = this.allContentIds;
        // let selectedItems: mmsTypes.downloadTypes.ISingleDownloadItemInfo[] = _.filter(this.allItems, (ele: mmsTypes.downloadTypes.ISingleDownloadItemInfo) => {
        //     return ele.selected
        // })
        // selectedItems.forEach((ele: mmsTypes.downloadTypes.ISingleDownloadItemInfo) => {
        //     contentIdsArr.push(ele.contentId)
        // })
        let validData: mmsTypes.downloadTypes.IValidOrGetFileDownload = {
            contentIds: contentIdsArr,
            shortUrls: []
        }
        let getDataInfo: object = {
            contentIds: contentIdsArr,
        }
        mmsApi.download.validDownload(validData).then(vali => {
            let noPermissions: any = [];
            let copyrightExpires: any = [];
            let copyrightNotices: any = [];
            let checkedEntities: any = [];
            _.forEach(vali.data.data, (entity: any) => {
                if (!entity.canDownload) {
                    noPermissions.push(entity);
                } else {
                    if (entity.copyrightExpired) {
                        copyrightExpires.push(entity);
                    } else {
                        checkedEntities.push(entity);
                    }
                    if (entity.copyrightWarning) {
                        copyrightNotices.push(entity);
                    }
                }
            });
            if (noPermissions.length > 0) {
                let tipStr = _.map(noPermissions, 'name').join('、');

                if (noPermissions.length === contentIdsArr.length) {
                    if (noPermissions.length === 1) {
                        message.error('您无权下载该文件！');
                    } else {
                        notification.error({
                            message: '下载错误',
                            description: `您无权下载以下文件：${tipStr}`
                        });
                    }
                } else {
                    // 这里打开modal
                    // onClick触发的函数
                    notification.error({
                        message: '下载错误',
                        description: `您无权下载以下文件：${tipStr}，因此已经为您过滤。`
                    });
                    // 移除无权限素材
                    _.forEach(noPermissions, (entity: any) => {
                        contentIdsArr = contentIdsArr.filter((contentId: string) => {
                            return contentId !== entity.contentId
                        })
                    });
                    if (contentIdsArr.length <= 0) {
                        return
                    }
                }
            }

            // 获取下载信息
            getDataInfo = {
                contentIds: contentIdsArr,
            }
            let c: mmsTypes.downloadTypes.ISingleDolValiItem[] = vali.data.data
            mmsApi.download.getFileDownloadInfo(getDataInfo).then((info) => {
                function getGroupInfosTotal(groupInfos: any[]) {
                    let total = groupInfos.length;
                    _.forEach(groupInfos, (group: mmsTypes.downloadTypes.ISingleGroupInfo) => {
                        if (group.fileItems.length > 1) {
                            total = group.fileItems.length - 1 + total;
                        }
                    });
                    return total;
                }
                // 获取文件组的信息
                function getGroupInfo(group: mmsTypes.downloadTypes.ISingleGroupInfo, index: number, entity: mmsTypes.downloadTypes.ISingleDownloadItemInfo) {
                    let obj: mmsTypes.downloadTypes.IDownloadLastItem = {
                        canDownloadOriginal: false,
                        canDownload: false,
                        downloadPathExt: '',
                        entity: {
                            contentId: entity.contentId,
                            name: ''
                        },
                        file: {},
                        fileCount: 0,
                        fileIndex: 0,
                        groupCount: 0,
                        groupIndex: 0,
                        groupName: '',
                        groupTransName: '',
                        groupType: '',
                        contentId: '',
                        isArchive: false,
                        showBitRate: '',
                        isRecording: false,
                    };
                    obj.groupName = group.groupName;
                    obj.showBitRate = group.showBitRate;
                    obj.groupType = group.groupType;
                    obj.groupIndex = index;
                    obj.groupCount = group.total;
                    obj.entity = {
                        contentId: entity.contentId,
                        name: entity.entityName
                    };
                    obj.isArchive = group.isArchive;
                    obj.isRecording = group.isRecording;

                    obj.groupTransName = obj.groupName;
                    if (obj.showBitRate && obj.groupName !== 'supertranscodevideo') {
                        obj.groupTransName = obj.showBitRate;
                    }
                    // 原代码是负责groupname的转换
                    // if ($scope.translate.hasOwnProperty(obj.groupName)) {
                    //     obj.groupTransName = $scope.translate[obj.groupName];
                    //     if (obj.showBitRate && (obj.groupType === 'video' || obj.groupType === 'audio')) {
                    //         obj.groupTransName = $scope.translate[obj.groupName] + '(' + obj.showBitRate + ')';
                    //     }
                    // }
                    return obj;
                }
                let d: mmsTypes.downloadTypes.ISingleDownloadItemInfo[] = info.data.data;
                let models: mmsTypes.downloadTypes.ISingleDownloadItemInfo[] = [];
                _.forEach(d, (entity: mmsTypes.downloadTypes.ISingleDownloadItemInfo) => {
                    let checkedEntity = _.find(c, { contentId: entity.contentId });
                    if (checkedEntity) {
                        entity.canDownloadOriginal = checkedEntity.canDownloadOriginalFile;
                        entity.canDownload = checkedEntity.canDownload;
                    }
                    let index: number = 0;
                    _.forEach(entity.groupInfos, (group: mmsTypes.downloadTypes.ISingleGroupInfo) => {
                        if (!group.hasOwnProperty('total')) {
                            group.total = getGroupInfosTotal(entity.groupInfos);
                        }
                        let obj: mmsTypes.downloadTypes.IDownloadLastItem = {
                            canDownloadOriginal: false,
                            canDownload: false,
                            downloadPathExt: '',
                            entity: {
                                contentId: '',
                                name: ''
                            },
                            file: {},
                            fileCount: 0,
                            fileIndex: 0,
                            groupCount: 0,
                            groupIndex: 0,
                            groupName: '',
                            groupTransName: '',
                            groupType: '',
                            contentId: entity.contentId,
                            isArchive: false,
                            showBitRate: '',
                            isRecording: false,
                        };
                        if (group.fileItems.length === 0) { // 文件组为零处理
                            obj = getGroupInfo(group, index, entity);
                            obj.fileIndex = 0;
                            obj.fileCount = 1;
                            index++;
                            models.push((window as any)._.cloneDeep(obj));
                        } else {
                            let count = group.fileItems.length;
                            _.forEach(group.fileItems, (file: mmsTypes.downloadTypes.ISingleFileItem, fileIndex: number) => { // 文件组不为零处理
                                obj = getGroupInfo(group, index, entity);
                                obj.file = (window as any)._.cloneDeep(file);
                                if (!obj.file.hasOwnProperty('entityName')) {
                                    obj.file.entityName = obj.entity.name + '.' + commonUtil.getExtension(obj.file.fileName);
                                }
                                obj.fileCount = count;
                                obj.fileIndex = fileIndex;
                                obj.canDownloadOriginal = entity.canDownloadOriginal;
                                obj.canDownload = entity.canDownload as boolean;
                                if (obj.file.fileName) {
                                    obj.downloadPathExt = commonUtil.getExtension(obj.file.fileName);
                                }
                                index++;
                                models.push((window as any)._.cloneDeep(obj));
                            });
                        }
                    })
                })
                if (models.length > 0) {
                    models.forEach(ele => {
                        this.addOneItem(ele)
                    })
                    this.setModalSH(true)
                } else {
                    this.setModalSH(false)
                    message.error('暂无可用下载项！')
                }
            }, err => {
            })
        }).catch(() => {
            message.error('请求信息失败')
        })
    }

    // 设置某一项的选中状态
    @action
    public setItemsSelected(indexArr: number[], value: boolean) {
        // this.setAllItemStatus(false)
        indexArr.forEach((index: number) => {
            this.allItems[index].selected = value;
        })
        this.checkItemsSelected()
    }

    // 设置所有项的选中状态
    @action
    public setAllItemStatus(value: boolean) {
        this.allItems.forEach((item: mmsTypes.downloadTypes.ISingleDownloadItemInfo) => {
            item.selected = value;
        })
        this.checkItemsSelected()
    }
    // 检查所有中有没有是选中状态
    @action
    public checkItemsSelected() {
        this.selectedItems = []
        this.allItems.forEach((item) => {
            if (item.selected) {
                this.selectedItems.push(item)
            }
        })
        this.isSelectedItem = this.selectedItems.length > 0
    }
    // 设置某一项的选中状态
    @action
    public addOneItem(item: mmsTypes.downloadTypes.ISingleDownloadItemInfo) {
        this.allItems.push(item)
    }
    // 设置全选和选择源文件 预览画质
    @action
    public selectAll = (type?: string) => {
        // 检测是否是源文件
        function isOriginal(item: mmsTypes.downloadTypes.ISingleDownloadItemInfo) {
            return item.groupName.indexOf('_proxy') === -1 && item.groupName !== 'streamgroup' && item.groupName !== 'preview_file_';
        };
        // 当前所有的item
        let models: mmsTypes.downloadTypes.ISingleDownloadItemInfo[] = this.allItems
        if (type) {
            // 不是全部选择 并且
            if (this.isSelectedItem) {
                this.setAllItemStatus(false)
            }
        }
        // 返回一个布尔值  是不是还有没有选择的
        let select = _.filter(models, (o: mmsTypes.downloadTypes.ISingleDownloadItemInfo) => {
            if (!type) {
                // 全选
                if(this.stores.configStore.config && this.stores.configStore.config.wuXiCustomSwitch){ //无锡定制如果资源处于收录中(isRecording为true)则不支持下载，因为这样的素材即使下载下来也无法正常播放
                    return  !o.isRecording && !o.selected
                }else{
                    return !o.selected;
                }
            } else if ((type === 'original') && isOriginal(o)) {
                return o.canDownloadOriginal && o.hasOwnProperty('file');
            } else if (type === 'preview') {
                // return nxt.config.storageType != 2 && o.hasOwnProperty('file') && o.downloadPathExt != 'mpd';
                return o.hasOwnProperty('file') && o.downloadPathExt !== 'mpd';
            }
            return false
        }).length > 0;
        // 筛选原格式
        let aSelctedIndex: number[] = [];
        if (type === 'original') {
            _.forEach(models, (item: mmsTypes.downloadTypes.ISingleDownloadItemInfo, index: number) => {
                if (isOriginal(item) && item.hasOwnProperty('file') && item.canDownloadOriginal) {
                    aSelctedIndex.push(index)
                }
            });
        } else if (type === 'preview') {
            // 筛选预览格式 && nxt.config.storageType != 2
            _.forEach(models, (item: mmsTypes.downloadTypes.ISingleDownloadItemInfo, index: number) => {
                if (!isOriginal(item) && item.hasOwnProperty('file') && item.downloadPathExt !== 'mpd') {
                    aSelctedIndex.push(index)
                }
            });
        } else {
            // 全选 全选还要区分是不是原格式，能不能下载原格式；否则还要查看能不能预览格式下面有没有文件 而不是之间吧全部都勾选
            _.forEach(models, (item: mmsTypes.downloadTypes.ISingleDownloadItemInfo, index: number) => {
                if (isOriginal(item)) {
                    // 原格式
                    if (item.canDownloadOriginal && item.hasOwnProperty('file')) {
                        aSelctedIndex.push(index)
                    }
                } else {
                    // 低质量、预览格式 nxt.config.storageType != 2 && nxt.config.hiveStorageType != 'oss' && 
                    if (item.hasOwnProperty('file') && item.downloadPathExt !== 'mpd') {
                        aSelctedIndex.push(index)
                    }
                }
            });
        }
        this.setItemsSelected(aSelctedIndex, select)
    };
    // 设置添加a链接,方便后面的批量下载的点击
    @action
    public setAlinks(alinks: object) {
        this.allALinks = alinks
    }
    // 为allContentIds赋值 用于单个点击下载
    @action
    public addContentIds(contentIdsArr: string[]) {
        this.allContentIds = contentIdsArr;
    }

    public getClientDownloadPath(fileItem: mmsTypes.downloadTypes.INewFileItems) {
        let path = fileItem.clientDownloadPath
        if (this.stores.configStore.config.isHandleHttpPath && fileItem.isOSS) {
            let hostname = window.location.hostname
            let absolutePath = fileItem.clientDownloadPath.split('@')
            const startChar = absolutePath[1].indexOf(':') > -1 ? ':' : '/'
            path = absolutePath[0] + '@' + hostname + absolutePath[1].substr(absolutePath[1].indexOf(startChar))
        }
        return path
    }
}
