import ExtendStore from '../base/extendStore';
import { action, observable, runInAction } from 'mobx';
import iSearchTypes from 'src/types/iSearchTypes';
import iSearchApi from 'src/apis/iSearchApi';
import { message } from 'antd';
import _ from 'lodash'
import { Stores } from 'src/stores';
import IOutputExcelStore from './extends/iOutputExcelStore';
import IAdvancedSearchStore from './extends/iAdvancedSearchStore';
import { ISelectAggr } from 'src/pages/iSearch/modules/szFilter/szFilter';
import ccApi from 'src/apis/ccApi';
import { commonUtil } from 'mam-common-utils'

export default class ISearchStore extends ExtendStore {
    @observable
    public params: iSearchTypes.ISearchParams
    @observable
    public searchKeyword1: string = '';  // 主搜索框值
    @observable
    public searchKeyword2: string = '';  // 次检索框值
    @observable
    public searchDateRangeStart: string | undefined;  // 开始日期
    @observable
    public searchDateRangeEnd: string | undefined;  // 结束日期
    /** 是否显示层面过滤 */
    @observable
    public showFacetFilter: boolean = true;
    @observable
    public fullSearchData?: iSearchTypes.IFullSearchDataRes;
    @observable
    public fullSearchQuerySecs: number = 0;
    @observable
    public sortItems: iSearchTypes.ISortItem[] = []
    /** 当前选中排序类型 */
    @observable
    public currentSort: iSearchTypes.ISortItem;

    @observable
    public pageSize: number = 60;

    @observable
    public isLoadingFullSearchData = true;

    @observable
    public showRightBox = false; // 是否显示右侧区域

    @observable
    public szSelectedAggr: ISelectAggr[] = []

    @observable
    public showFacetsNum: number = 3;

    @observable
    public aggrs: any[] = [];

    /** 选中的检索模板 */
    @observable
    public searchTemplateId?: string;

    /** 检索范围 */
    @observable
    public keywordSearchType: string = 'fullText';

    public iOutputExcelStore: IOutputExcelStore;
    public iAdvancedSearchStore: IAdvancedSearchStore;

    constructor(stores: Stores) {
        super(stores)
        this.iOutputExcelStore = new IOutputExcelStore(stores);
        this.iAdvancedSearchStore = new IAdvancedSearchStore(stores);
        this.initSelectAllStore(stores, 'stores.iSearchStore.fullSearchData.data', 'selected');
        this.initSearchFilterStore(stores);
        this.initDataViewModeStore(stores);
        this.initDownloadStore(stores);
        this.initOutstoreStore(stores);
        this.initNewCreateAlbumStore(stores)
        this.initBatchExecuteSmartProcessStore(stores);
        this.initBatchInitiatingSmartProcess4AllStore(stores, this);
        this.initDeleteStore(stores);
        this.initExportExcelFieledSettingStore(stores);
        this.initCreateDatasetStore(stores);

        let storeSzSelectedAggr = sessionStorage.getItem('iSearch.szSelectedAggr')
        if (storeSzSelectedAggr) {
            this.szSelectedAggr = JSON.parse(storeSzSelectedAggr);
        }

        let searchTemplateId = sessionStorage.getItem('iSearch.searchTemplateId');
        if (searchTemplateId){
            this.searchTemplateId = searchTemplateId;
        }

        let keywordSearchType = sessionStorage.getItem('iSearch.keywordSearchType');
        if (keywordSearchType){
            this.keywordSearchType = keywordSearchType;
        }
    }

    public getFullSearchParam(page?: number): iSearchTypes.IFullSearchDataReq {
        this.getSortItems();
        let searchPageSizesConfig = this.stores.configStore.getUiModule('searchPageSizes');
        if (searchPageSizesConfig && searchPageSizesConfig.value){
            let searchPageSizes = JSON.parse(searchPageSizesConfig.value);
            if (searchPageSizes.length > 0){
                runInAction(()=>{
                    this.pageSize = searchPageSizes[0];
                })
            }
        }
        const pageSizeStorage = localStorage.getItem('searchStore.pageSize');
        if (pageSizeStorage !== null) {
            runInAction(()=>{
                this.pageSize = parseInt(pageSizeStorage, 10)
            })
        }
        let param: iSearchTypes.IFullSearchDataReq = {
            pageIndex: page || 1,
            pageSize: this.pageSize,
            personId: '',
            keyword: this.params.keyword.filter(item => item !== ''),
            facetConditions: [],
            conditions: [],
            sorts: [
                {
                    field: this.currentSort.field,
                    desc: this.currentSort.desc === 'desc'
                }
            ]
        }
        if (this.params.facetConditions) {
            this.params.facetConditions.forEach((condition) => {
                if (condition.items) {
                    condition.items.forEach((n) => {
                        let item = _.find(param.facetConditions, { field: n.field });
                        if (item == null) {
                            param.facetConditions.push(n);
                        } else {
                            if (_.isArray(item.value)) {
                                if (!_.includes(item.value, n.value)) {
                                    item.value.push(n.value as string);
                                }
                            } else {
                                if (item.value !== n.value) {
                                    item.value = [item.value, n.value as string];
                                }
                            }
                        }
                    });
                }
            });
        }
        // 高级检索
        if (this.params.conditions) {
            this.params.conditions.forEach((condition) => {
                if (condition.type !== 'advancedSearch') {
                    return;
                }
                if (condition.items) {
                    condition.items.forEach((n) => {
                        let value = n.value;
                        //日期参数处理
                        if (condition.fieldType === 'date' && value && value instanceof Array && value.length > 0) {
                            let valueArr = value[0].split(',');
                            if (valueArr && valueArr.length === 2) {
                                value = [`${valueArr[0]} 00:00:00 TO ${valueArr[1]} 23:59:59`]
                            }
                        }
                        param.conditions.push({
                            field: n.field,
                            nexus: n.nexus,
                            value
                        });
                    });
                }
            });
        }
        //开始和结束时间
        if (this.searchDateRangeStart && this.searchDateRangeEnd) {
            param.conditions.push({ field: 'date_of_debut', value: [`${this.searchDateRangeStart} 00:00:00 TO ${this.searchDateRangeEnd} 23:59:59`] })
        }
        //深圳台定制层面参数
        if (this.szSelectedAggr.length > 0) {
            let szConditions: iSearchTypes.ISearchReqCondition[] = [];
            if (this.szSelectedAggr && this.szSelectedAggr.length > 0) {
                this.szSelectedAggr.map(item => {
                    if (item.facetValueValue) {
                        szConditions.push({
                            field: item.facetFieldName,
                            value: [item.facetValueValue]
                        })
                    }
                })
            }
            param.conditions = param.conditions.concat(szConditions);
        }
        //选中的检索模板
        if (this.searchTemplateId){
            param.templateId = this.searchTemplateId;
        }
        //检索范围
        if (this.keywordSearchType){
            param.keywordSearchType = this.keywordSearchType;
        }
        return param;
    }
    /**
     * @param page 
     * @param isAppend 是否追加模式，滚动分页时使用
     * @returns 
     */
    @action
    public queryFullSearchData(page?: number, isAppend?: boolean): Promise<iSearchTypes.IFullSearchDataRes | undefined> {
        return new Promise((resolve, reject) => {
            this.selectAllStore.switchAllSelected(false);
            let param = this.getFullSearchParam(page);
            if (!isAppend) {
                this.fullSearchData = undefined;
            }
            this.isLoadingFullSearchData = true;
            this.fullSearchQuerySecs = 0;
            let before = new Date();
            // // 浙江跳转需要根据入库时间排序
            // if(_.find(param.facetConditions,{field: "district",value: ["中国/浙江省/"]})){
            //     param.sorts[0].field = 'createDate_'
            // }
            iSearchApi.queryFullSearchData(param).then(res => {
                runInAction(() => {
                    if (res.data.data && res.data.data.data) {
                        res.data.data.data.forEach((item) => {
                            item.name = item.name_;
                            item.duration = item.duration_;
                            item.contentId = item.contentId_;
                            item.framerate = item.framerate_;
                        })
                        if (!res.data.data.aggrs) {
                            res.data.data.aggrs = []
                        }
                    }
                    if (!isAppend) {
                        this.fullSearchData = res.data.data;
                        if (this.fullSearchData) {
                            this.favoriteCheck(this.fullSearchData.data);
                            this.getPicturesCount(this.fullSearchData.data);
                            this.initFacets(this.fullSearchData.aggrs);
                        }
                    }
                    else if (this.fullSearchData && res.data.data && res.data.data.data.length > 0) {
                        this.fullSearchData.data = this.fullSearchData.data.concat(res.data.data.data);
                        this.fullSearchData.pageIndex = res.data.data.pageIndex;
                        this.fullSearchData.pageSize = res.data.data.pageSize;
                        this.fullSearchData.pageTotal = res.data.data.pageTotal;
                        this.fullSearchData.recordTotal = res.data.data.recordTotal;
                        this.favoriteCheck(res.data.data.data);
                        this.getPicturesCount(res.data.data.data);
                    }
                    this.isLoadingFullSearchData = false;
                    resolve(res.data.data);
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            }).finally(() => {
                runInAction(() => {
                    let after = new Date();
                    this.fullSearchQuerySecs = parseFloat(((after.getTime() - before.getTime()) / 1000).toFixed(2));
                })
            });
        })
    }

    @action
    public favoriteCheck(items: any[]) {
        if (items) {
            let contentIds: string[] = items.map((item) => {
                return item.contentId;
            })
            iSearchApi.favoriteCheck(contentIds).then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        res.data.data.forEach((id) => {
                            if (this.fullSearchData) {
                                let item = _.find(this.fullSearchData.data, { contentId: id });
                                item.isFavorite = true;
                                item.isCollected = true;
                            }
                        })
                    }
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            })
        }
    }

    /** 查询图片包图片数量 */
    @action
    public getPicturesCount(items: any[]) {
        if (items) {
            let contentIds: string[] = [];
            items.forEach((item) => {
                if (item.type_ === 'biz_sobey_picture') {
                    contentIds.push(item.contentId_);
                }
            })
            ccApi.getPictureCount(contentIds).then(res => {
                runInAction(() => {
                    for (let key in res.data.data) {
                        if (this.fullSearchData) {
                            let item = _.find(this.fullSearchData.data, { contentId_: key });
                            item.pictureCount = res.data.data[key];
                        }
                    }
                });
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            })
        }
    }

    /** 设置收藏状态 */
    @action
    public setItemFavorite(item: any, favorite: boolean) {
        if (this.fullSearchData && this.fullSearchData.data) {
            let target = _.find(this.fullSearchData.data, { contentId: item.contentId });
            if (target) {
                target.isFavorite = favorite;
                target.isCollected = favorite;
            }
        }
    }

    @action
    public setParams(params: iSearchTypes.ISearchParams) {
        this.params = params;
    }

    @action
    public setPageSize(size: number) {
        this.pageSize = size;
        localStorage.setItem('searchStore.pageSize', size.toString());
        this.queryFullSearchData(1).then(() => { });
    }

    @action
    public setSearchKeyword1(str: string) {
        this.searchKeyword1 = str;
    }
    @action
    public setSearchKeyword2(str: string) {
        this.searchKeyword2 = str;
    }

    @action
    public setShowFacetFilter(show: boolean) {
        this.showFacetFilter = show;
    }

    @action
    public setShowFacetsNum(num: number) {
        this.showFacetsNum = num;
    }

    @action
    public setAggrs(value: any) {
        this.aggrs = value;
    }

    @action
    public getSortItems = () => {
        const config = this.stores.configStore.config;
        let sortItems: iSearchTypes.ISortItem[] = []
        if (config && config.searchSortFields && config.searchSortFields.length > 0) {
            sortItems = config.searchSortFields.map((item) => {
                return {
                    text: item.name || '',
                    field: item.field,
                    desc: item.desc ? 'desc' : 'asc',
                    hideDirection: true
                }
            })
        } else {
            sortItems = [
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'desc' },
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'asc' },
                { text: '相关度'.l('search.relatedRate'), field: '_score', desc: 'desc', hideDirection: true },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'desc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'asc' }
            ]
        }
        this.setSortItems(sortItems)
        if (!this.currentSort) {
            //参数里关键字不为空，默认选中相关度，否则默认取第一个
            const keyword = this.params.keyword.filter(item=>item !== '');
            if (keyword.length === 0){
                // 根据默认选中排序条件配置，设置排序
                let defaultSearchSortConfig = this.stores.configStore.getUiModule('defaultSearchSort');
                if (defaultSearchSortConfig && defaultSearchSortConfig.value){
                    let defaultSearchSort = JSON.parse(defaultSearchSortConfig.value);
                    let sortItem = _.find(this.sortItems, {'field': defaultSearchSort.field, 'desc': defaultSearchSort.desc})
                    if (sortItem){
                        this.setCurrentSort(sortItem);
                    }
                }
                else {
                    this.setCurrentSort(sortItems[0])
                }
            }
            else {
                let scoreItem = _.find(this.sortItems, {field: '_score'});
                if (scoreItem){
                    this.setCurrentSort(scoreItem);
                }
                else {
                    this.setCurrentSort(sortItems[0])
                }
            }
            let isDefaultDateOfDebut = commonUtil.getUrlQueryParam('isDefaultDateOfDebut')
            if(config.zheJiangCustomSwitch && isDefaultDateOfDebut) {
                let defaultScoreItem = _.find(this.sortItems, {field: 'date_of_debut', desc: "desc"})
                if (defaultScoreItem){
                    this.setCurrentSort(defaultScoreItem);
                }
                else {
                    this.setCurrentSort(sortItems[0])
                }
            }
            let isDefaultCreateDate = commonUtil.getUrlQueryParam('isDefaultCreateDate')
            let zjindex = commonUtil.getUrlQueryParam('zjindex')
            if(zjindex && isDefaultCreateDate) {
                let defaultScoreItem = _.find(this.sortItems, {field: 'createDate_', desc: "desc"})
                if (defaultScoreItem){
                    this.setCurrentSort(defaultScoreItem);
                }
                else {
                    this.setCurrentSort(sortItems[0])
                }
            }
        }
    }

    @action
    public setSortItems = (sortItems: iSearchTypes.ISortItem[]) => {
        this.sortItems = sortItems
    }

    @action
    public setCurrentSort(sort: iSearchTypes.ISortItem) {
        this.currentSort = sort;
    }

    @action
    public setShowRightBox(showRightBox: boolean) {
        this.showRightBox = showRightBox;
    }

    //iSearch中自动切换到相关度
    @action
    public autoRelatedSort(isToRelated: boolean) {
        this.stores.configStore.getUiModulesEnable('autoRelatedSortEnable').then(() => {
            if (this.stores.configStore.uiModulesSwitch.autoRelatedSortEnable) {
                if (isToRelated) {
                    this.currentSort = {
                        desc: "desc",
                        field: "_score",
                        text: '相关度'.l('search.relatedRate'),
                    }
                } else {
                    this.currentSort = {
                        desc: "desc",
                        field: "createDate_",
                        text: '入库时间'.l('search.importTime'),
                    }
                };
            }
        })
    }

    @action
    public setSearchDateRangeStart(start?: string) {
        this.searchDateRangeStart = start;
    }

    @action
    public setSearchDateRangeEnd(end?: string) {
        this.searchDateRangeEnd = end;
    }

    @action
    public setSzSelectedAggr(aggrs: ISelectAggr[]) {
        this.szSelectedAggr = aggrs;
        sessionStorage.setItem('iSearch.szSelectedAggr', JSON.stringify(this.szSelectedAggr));
    }

    @action
    public setSearchTemplateId(id?: string){
        this.searchTemplateId = id;
        sessionStorage.setItem('iSearch.searchTemplateId', this.searchTemplateId || '');
    }

    @action
    public setKeywordSearchType(type: string){
        this.keywordSearchType = type;
        sessionStorage.setItem('iSearch.keywordSearchType', this.keywordSearchType || '');
    }

    /** 初始化层面数据 */
    protected initFacets(facets: iSearchTypes.IFacets[]) {
        if (facets) {
            facets.forEach(item => {
                if (item.facetFieldName === 'createDate_') {
                    item.startDate = '';
                    item.endDate = '';
                }
                item.showMore = false;
                if (item.facetValue) {
                    item.facetValue.forEach((o: iSearchTypes.IFacetsFacetValue) => {
                        o.isShow = true;
                        o.isSelected = false;
                    });
                }
                item.checkboxIsOpen = false;
            });
        }
    }
}
