import { IImageSearchParams, IFaceSearchReq, IFaceSearchRes, ISearchBySimilarityReq, ISearchBySimilarityRes } from 'src/types/imageSearchTypes'
import { observable, action, runInAction } from 'mobx'
import { Stores } from '..'
import ExtendStore from '../base/extendStore'
import imageSearchApi from 'src/apis/imageSearchApi';
import { message } from 'antd';
import catalogTypes from 'src/types/catalogTypes';
import _ from 'lodash';

export default class ImageSearchStore extends ExtendStore {
    @observable
    public params: IImageSearchParams;
    @observable
    public faces: catalogTypes.faceReco.IFaceRecoItem[];
    @observable
    public selectedFace?: catalogTypes.faceReco.IFaceRecoItem;
    @observable
    public keyword: string;
    @observable
    public fullSearchData?: IFaceSearchRes;
    @observable
    public currentTab: string = 'biz_sobey_video';
    /** 相似检索结果 */
    @observable
    public similarFaces: ISearchBySimilarityRes | undefined;
    @observable
    public isSearching: boolean = false;
    @observable
    public searchDateRangeStart: string | undefined;  // 开始日期
    @observable
    public searchDateRangeEnd: string | undefined;  // 结束日期
    @observable
    public showHighlight: boolean = false;  // 是否显示高光时刻

    constructor(stores: Stores) {
        super(stores)
    }

    @action
    public searchFace(){
        this.isSearching = true;
        this.fullSearchData = undefined;
        this.faces = [];
        let request: catalogTypes.faceReco.IFaceRecoReq = {
            imgBase64: this.params.base64.replace(/data\:image\/([a-zA-Z]+)\;base64\,/, ''),
            name: ''
        }
        imageSearchApi.searchFace(request).then(res=>{
            if (res.data.success === true && res.data.data){
                runInAction(()=>{
                    if (res.data.data){
                        this.faces = res.data.data.recognized.concat(res.data.data.unIdentification);
                        this.faces = _.orderBy(this.faces, ['confidence'], ['desc'])
                        if (this.faces.length > 0){
                            this.setSelectedFace(this.faces[0]);
                        }
                        else {
                            this.setSelectedFace(undefined);
                        }
                    }
                })
            }
            else {
                this.setSelectedFace(undefined);
            }
        }, (res)=>{
            message.error(res.data.error?.title);
        })
    }

    @action
    public queryFullSearchData(page: number): Promise<any> {
        this.isSearching = true;
        this.similarFaces = undefined;
        this.fullSearchData = undefined;
        if (!this.selectedFace || (!this.selectedFace.id && !this.selectedFace.clusterIds)){
            console.error('返回人脸未返回id或clusterIds！');
            this.isSearching = false;
            return Promise.reject();
        }
        return new Promise((resolve, reject) => {
            let req: IFaceSearchReq = {
                pageIndex: page,
                pageSize: 25,
                keyword: this.keyword ? [this.keyword] : [],
                conditions: [{field: 'type_', value: [this.currentTab]}],
                sorts: []
            };
            let faceSort = _.find(this.stores.configStore.config.searchSortFields, {faceSort: true});
            if (!faceSort){
                faceSort = {field: "_score", desc: true}
            }
            req.sorts.push(faceSort);
            if (this.selectedFace){
                if (this.selectedFace.id){
                    req.personId = this.selectedFace.id;
                }
                if (this.selectedFace.clusterIds){
                    req.clusterIds = this.selectedFace.clusterIds;
                }
            }
            //开始和结束时间
            if (this.searchDateRangeStart && this.searchDateRangeEnd){
                req.conditions.push({ field: 'date_of_debut', value: [`${this.searchDateRangeStart} 00:00:00 TO ${this.searchDateRangeEnd} 23:59:59`] })
            }
            imageSearchApi.faceSearch(req).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        this.fullSearchData = res.data.data;
                        if (this.fullSearchData.data){
                            this.fullSearchData.data.forEach((item)=>{
                                item.contentId = item.contentId_;
                            })
                        }
                    }
                    this.isSearching = false;
                })
                resolve(true);
            }, (res) => {
                runInAction(() => {
                    this.isSearching = false;
                });
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }
    @action
    public highlightFullSearch(page: number): Promise<any> {
        this.isSearching = true;
        this.similarFaces = undefined;
        this.fullSearchData = undefined;
        if (!this.selectedFace || (!this.selectedFace.id && !this.selectedFace.clusterIds)){
            return Promise.reject();
        }
        return new Promise((resolve, reject) => {
            let req: IFaceSearchReq = {
                pageIndex: page,
                pageSize: 25,
                keyword: this.keyword ? [this.keyword] : [],
                conditions: [{field: 'type_', value: [this.currentTab]}],
                sorts: [
                    {field: "date_of_debut", desc: true}
                ]
            };
            if (this.selectedFace){
                if (this.selectedFace.id){
                    req.personId = this.selectedFace.id;
                }
                if (this.selectedFace.clusterIds){
                    req.clusterIds = this.selectedFace.clusterIds;
                }
            }
            //开始和结束时间
            if (this.searchDateRangeStart && this.searchDateRangeEnd){
                req.conditions.push({ field: 'date_of_debut', value: [`${this.searchDateRangeStart} 00:00:00 TO ${this.searchDateRangeEnd} 23:59:59`] })
            }
            imageSearchApi.highlightSearch(req.pageIndex, req.pageSize, req.personId, req.clusterIds).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        this.fullSearchData = res.data.data;
                        if (this.fullSearchData.data){
                            this.fullSearchData.data.forEach((item)=>{
                                item.contentId = item.contentId_;
                            })
                        }
                    }
                    this.isSearching = false;
                })
                resolve(true);
            }, (res) => {
                runInAction(() => {
                    this.isSearching = false;
                });
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }

    @action
    public searchBySimilarity(page: number){
        this.faces = [];
        this.fullSearchData = undefined;
        this.similarFaces = undefined;
        this.isSearching = true;
        let req: ISearchBySimilarityReq = {
            imgBase64: this.params.base64.replace(/data\:image\/([a-zA-Z]+)\;base64\,/, ''),
            page_index: page,
            page_size: 50
        }
        return new Promise((resolve, reject) => {
            imageSearchApi.searchBySimilarity(req, this.currentTab).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        this.similarFaces = res.data.data;
                    }
                    this.isSearching = false;
                })
                resolve(true);
            }, (res) => {
                runInAction(() => {
                    this.isSearching = false;
                });
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public setCurrentTab(tab: string, searchType: string){
        this.currentTab = tab;
        if (searchType === 'face'){
            this.queryFullSearchData(1);
        }
        else {
            this.searchBySimilarity(1);
        }
    }

    @action
    public setSelectedFace(face?: catalogTypes.faceReco.IFaceRecoItem){
        this.selectedFace = face;
        this.queryFullSearchData(1);
    }

    @action
    public setParams(params: IImageSearchParams){
        this.params = params;
    }

    @action
    public setKeyword(keyword: string){
        this.keyword = keyword;
    }

    @action
    public setSearchDateRangeStart(start?: string){
        this.searchDateRangeStart = start;
    }

    @action
    public setSearchDateRangeEnd(end?: string){
        this.searchDateRangeEnd = end;
    }
    @action
    public setShowHighlight(show: boolean){
        this.showHighlight = show;
    }
}