import { action, observable, runInAction } from "mobx";
import BaseStore from "../base/baseStore";
import mmsApi from './../../apis/mmsApi';
import {Stores} from "../index";
import wuxiTypes from 'src/custom/wuxi/types/wuxiTypes';
import wuxiApi from 'src/custom/wuxi/apis/wuxiApi';


export default class RestoreStore extends BaseStore<Stores>{
    @observable
    public showRestoreModal:boolean = false

    @observable
    public questionModels:any[]

    //无锡定制：还原操作记录请求项
    @observable
    public wxDeleteRecordBase:wuxiTypes.IDeleteRecordBase = {
        reason: '',
        notes: '',
        action: "RESTORE"   //DELETE / RESTORE / CLEAR
    }

    @action
    public setShowRestoreModal(show:boolean){
        this.showRestoreModal = show
        //若关闭弹窗，需要重置初始值（无锡定制项）
        if(!show && this.stores.configStore.config.wuXiCustomSwitch){
            this.wxDeleteRecordBase = {
                reason: '',
                notes: '',
                action: "RESTORE"
            } 
        }
    }

    @action
    public setQuestionModels(questionModels:any[]){
        this.questionModels = questionModels
    }

    @action
    public restoreSend = (items: any[]) => {
        return new Promise((resolve, reject) => {
            mmsApi.restoreModal.restore(items)
                .then(res => {
                    if (res.data.success && res.data.data) {
                        if(this.stores.configStore.config.wuXiCustomSwitch){
                            this.wxDeleteRecord(items)
                        }
                        resolve(true)
                    } else {
                        reject()
                    }
                })
                .catch(err => {
                    reject()
                })
        })
    }
    //无锡定制-还原操作做记录
    @action
    public wxDeleteRecord(items: any[]){
        let params:wuxiTypes.IDeleteRecordReqItem[] = items.map((item)=>{
            return {
                contentId: item.contentId || item.contentId_,
                reason: this.wxDeleteRecordBase.reason,
                notes: this.wxDeleteRecordBase.notes,
                action: this.wxDeleteRecordBase.action,
            }
        })
        return new Promise((resolve, reject) => {
            wuxiApi.deleteRecord(params).then((res) => {
                runInAction(() => {
                    if (res.data && res.data.success) {
                        resolve(true)
                    }else{
                        reject()
                    }
                })
            })
        })
    }
}
