import ExtendStore from '../../base/extendStore';
import { action, observable, runInAction } from 'mobx';
import _ from 'lodash'
import { message } from 'antd';
import { Stores } from 'src/stores'
import PersonalCenterStore from '../personalCenterStore'
import personalCenterTypes from 'src/types/personalCenterTypes'
import { observer } from 'mobx-react';

export default class BacklogStore extends ExtendStore {
    public personalCenterStore: PersonalCenterStore

    @observable
    public defaultNav = '1'
    @observable
    public tableDataInfo: personalCenterTypes.ITableRes= {
        pageIndex: 1,
        pageTotal: 1,
        pageSize: 10,
        recordTotal: 2,
        data: [
            {
                key: '4321342',
                title: '5分钟搞定火爆朋友圈的蓝调夜景调色',
                timeLong: '00:11:23:09',
                createTime: '2020-11-23 19:23:45',
                type: '入库',
                status: '已完成',
            },
            {
                key: '432132321342',
                title: '5分钟搞定火爆朋友圈的蓝调夜景调色',
                timeLong: '00:11:23:09',
                createTime: '2020-11-23 19:23:45',
                type: '出库',
                status: '进行中',
            }
        ]
    }

    constructor(stores: Stores, personalCenterStore: PersonalCenterStore) {
        super(stores)
        this.personalCenterStore = personalCenterStore
    }

    @action
    public setDefaultNav(key: string) {
        this.defaultNav = key
    }
}