import { observable, action } from 'mobx';
import { BaseStore } from 'mam-core-react';
import { Stores } from '..';
import { IBaseEntity } from 'src/types/commonTypes';

export default class CreateDatasetStore extends BaseStore<Stores>{
    /** 创建资料集弹框 */
    @observable
    public showCreateDataset: boolean
    @observable
    public isCreating: boolean
    @observable
    public name: string
    @observable
    public description: string = ''
    @observable
    public datasetName: string
    @observable
    public items: IBaseEntity[] = []
    @action
    public setModalShow = (isShow: boolean) => {
        this.showCreateDataset = isShow
    }

    @action
    public setCreating = (isCreating: boolean) => {
        this.isCreating = isCreating
    }

    @action
    public open = (items: IBaseEntity[]) => {
        this.items = items
        if (!this.datasetName) {
            const datasetType = this.stores.configStore.config.entityTypes.find(s => s.code === 'dataset')
            this.datasetName = (datasetType && datasetType.name) || '资料集'.l('entity.datasetName')
        }
        this.name = ''
        this.isCreating = false
        this.setModalShow(true)
    }

    @action
    public changeName = (name: string) => {
        this.name = name
    }
    @action
    public changeDescription = (description: string) => {
        this.description = description
    }
}
