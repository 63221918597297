import {action, observable, runInAction} from "mobx";
import {IConfig, ILoginConfig, IUiModule} from "../types/configTypes";
import BaseStore from "./base/baseStore";
import {Stores} from "./index";
import configApi from 'src/apis/configApi';
import { message } from 'antd';
import _ from 'lodash'

export default class ConfigStore extends BaseStore<Stores> {
    @observable
    public config: IConfig;
    @observable
    public loginConfig: ILoginConfig
    /**
     * 是否显示请求加载动画
     */
    @observable
    public showRequestLoading: boolean = false;
    /**
     * 调用uiModulesEnable方法之后uiModulesSwitch里面才会有值！
     */
    @observable
    public uiModulesSwitch: any = {};
    private uiModules: IUiModule[] = [];
    /** 避免同一个key值同时发起多次查询，在请求结束前发起同样的请求，就用第一次发起的那个Promise */
    private queryUiModulesQueue: any = {};

    @action
    public setConfig(config?: IConfig): void {
        if (config)
        {
            config.server = process.env.DEV_SERVER as string;
            this.config = config;
            (window as any).nxt.config = config;
        }
    }

    @action
    public setShowRequestLoading(show: boolean){
        this.showRequestLoading = show;
    }

    @action
    public getLoginConfig(){
        return new Promise(((resolve, reject) => {
            configApi.getLoginConfig()
                .then(res => {
                    runInAction(()=>{
                        if (res.data.data){
                            this.loginConfig = res.data.data.config
                            resolve();
                        }
                    })
                })
        }))
    }

    @action
    public setSearchTableTemplate(template: string){
        if (this.config){
            this.config.searchTableTemplate = template;
        }
    }

    /** 开关是否全部开启 */
    public getUiModulesEnable(keys: string): Promise<any>{
        return new Promise((resolve, reject)=>{
            let enable = true;
            this.queryUiModules(keys).then(()=>{
                runInAction(()=>{
                    keys.split(',').forEach((key)=>{
                        if (this.uiModulesSwitch[key] === false){
                            enable = false;
                        }
                        else if (this.uiModulesSwitch[key] === undefined) {
                            let module = _.find(this.uiModules, {key});
                            if (!module || !module.value || module.value.toLowerCase() === 'false'){
                                enable = false;
                                this.uiModulesSwitch[key] = false;
                            }
                            else {
                                this.uiModulesSwitch[key] = true;
                            }
                        }
                    });
                })
                if (enable){
                    resolve();
                }
                else {
                    reject();
                }
            }, ()=>{
                reject();
            })
        })
    }

    /** 获取配置，非开关 */
    public getUiModulesConfig(key: string): Promise<any>{
        return new Promise((resolve, reject)=>{
            this.queryUiModules(key).then(()=>{
                let module = _.find(this.uiModules, {key})
                if (module){
                    resolve(module.value);
                }
                else {
                    resolve(undefined);
                }
            }, ()=>{
                reject();
            })
        })
    }

    public queryUiModules(keys: string): Promise<any>{
        if (this.queryUiModulesQueue[keys]){
            return this.queryUiModulesQueue[keys];
        }
        const qPromise = new Promise((resolve, reject)=>{
            configApi.getUiModules(keys).then(res=>{
                if (res.data.data){
                    res.data.data.forEach((item)=>{
                        if (_.findIndex(this.uiModules, {key: item.key}) === -1){
                            this.uiModules.push(item);
                        }
                    })
                }
                resolve();
            }).catch(res=>{
                if (res.data && res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            }).finally(()=>{
                this.queryUiModulesQueue[keys] = undefined;
            })
        })
        this.queryUiModulesQueue[keys] = qPromise;
        return qPromise;
    }
    
    /** 根据key值获取当前配置，确保已经调用过queryUiModules接口以后，可获取到配置 */
    public getUiModule(key: string){
        return _.find(this.uiModules, {key});
    }
}