import { IBasePageResponse } from 'src/types/commonTypes';
import emptyEntityTypes from 'src/types/emptyEntityTypes';
import { observable, runInAction, action, computed } from 'mobx';
import { Stores } from '..';
import emptyEntityApi from 'src/apis/emptyEntityApi';
import ProgramInfoStore from './programInfoStore';
import { message, Modal } from 'antd';
import ExcelExportModalStore from '../extends/excelExportModalStore'
import _ from 'lodash';

export default class ProgramListStore extends ExcelExportModalStore {
    @observable
    public searchData: IBasePageResponse<emptyEntityTypes.IWwProgramModel>
    @observable
    public request: emptyEntityTypes.IQueryWwProgramRequest
    @observable
    public selectedRowKeys: number[]
    public programStore: ProgramInfoStore
    @observable
    public currentSortNo = 4
    public sortFields = [
        { text: '节目代码'.l('emptyEntity.programCode') + ' ↓', sort: 'program_code', order: 'desc' },
        { text: '节目代码'.l('emptyEntity.programCode') + ' ↑', sort: 'program_code', order: 'asc' },
        { text: '节目名称'.l('emptyEntity.programName') + ' ↓', sort: 'program_name', order: 'desc' },
        { text: '节目名称'.l('emptyEntity.programName') + ' ↑', sort: 'program_name', order: 'asc' },
        { text: '创建时间'.l('emptyEntity.createTime') + ' ↓', sort: 'create_time', order: 'desc' },
        { text: '创建时间'.l('emptyEntity.createTime') + ' ↑', sort: 'create_time', order: 'asc' }
    ]

    constructor(stores: Stores) {
        super(stores)
        this.request = {
            keywordType: emptyEntityTypes.QueryWwProgramKeywordType.programCode,
            pageIndex: 1,
            pageSize: 15,
            sort: '',
            order: '',
            keyword: ''
        }
        this.programStore = new ProgramInfoStore(stores, 'common-metadata')
        this.selectedRowKeys = []
    }

    @action
    public query = () => {
        let queryUrl = this.getReqUrl(this.request)
        emptyEntityApi.queryProgram(queryUrl).then(res => {
            runInAction(() => {
                if (res.data.data) {
                    this.searchData = res.data.data
                    this.searchData.data.forEach((s, index) => {
                        s.key = index
                    })
                }
                this.selectedRowKeys = []
            })
        })
    }

    @action
    public init() {
        this.selectedRowKeys = []
        this.query()
    }

    @action
    public changeSelectedRowKeys = (selectedRowKeys: number[]) => {
        this.selectedRowKeys = selectedRowKeys
    }

    @computed
    public get getSelectedItems(): emptyEntityTypes.IWwProgramModel[] {
        return this.searchData ? this.selectedRowKeys.map(i => this.searchData.data[i]) : []
    }

    @action
    public onRequestChange = (field: string, value: any) => {
        this.request[field] = value
    }

    @action
    public onPageChange = (page: number) => {
        if (page) {
            this.request.pageIndex = page
        }
        this.query()
    }

    @action
    public onSortChange = (index: number) => {
        const sortField = this.sortFields[index]
        this.request.sort = sortField.sort
        this.request.order = sortField.order
        this.currentSortNo = index
        this.query()
    }

    public openProgramInfo = (programCode?: string, type?: 'create' | 'edit') => {
        this.programStore.onProgramCodeChange(programCode || '')
        this.programStore.selectProgramCode(programCode || '')
        this.programStore.setProgramModalShow(true, type)
    }

    public deleteProgram = (item?: emptyEntityTypes.IWwProgramModel) => {
        let request: string[] = []
        if (item) {
            request = [item.programCode]
        } else {
            request = this.getSelectedItems.map(s => s.programCode)
        }
        if (!request.length) {
            message.info('请选择一条节目'.l('emptyEntity.noSelected'))
            return
        }

        Modal.confirm({
            title: '确定要删除选中节目吗？'.l('emptyEntity.sureDeletePrograms'),
            content: '',
            onOk: () => {
                emptyEntityApi.deleteProgram(request).then(res => {
                    this.query()
                    message.success('删除成功'.l('toolbar.deleteTipOk'))
                })
            },
            centered: true,
            maskClosable: true,
            okText: '确定'.l('com.ok'),
            cancelText: '取消'.l('com.cancel'),
        })
    }

    public exportSelectedItems(): void {
        throw new Error("Method not implemented.");
    }

    public exportPageData = (page: number) => {
        const request = _.clone(this.request)
        request.pageSize = this.pageSize
        request.pageIndex = page + 1
        emptyEntityApi.exportProgram(this.getReqUrl(request))
    }

    public open = () => {
        // this.selectedItems = this.getSelectedItems
        this.setShowExcelExportModal(true)
        this.initPageData(this.searchData.recordTotal)
    }

    private getReqUrl = (request: emptyEntityTypes.IQueryWwProgramRequest) => {
        let queryUrl = ''
        Object.keys(request).forEach((key, index) => {
            let value = request[key]
            if (value !== undefined && value !== null) {
                if (index === 0) {
                    queryUrl += `?`
                } else {
                    queryUrl += `&`
                }
                if ((key === 'startTime' || key === 'endTime')) {
                    value = value.format('YYYY-MM-DD')
                }
                queryUrl += `${key}=${value}`
            }
        })
        return queryUrl
    }
}