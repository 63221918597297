import BaseStore from "../../base/baseStore";
import entityApi from "../../../apis/entityApi";
import {action, observable} from "mobx";
import entityTypes from "../../../types/entityTypes";
import {Stores} from "../../index";
import CatalogStore from "../catalogStore";
import * as React from "react";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import playerUtil from "mam-common-utils/dist/modules/playerUtil";

export default class KeyframeStore extends BaseStore<Stores> {
    /** 当前正在编辑的标记点 */
    @observable
    public currentKeyframe?: entityTypes.IMarKeyframe;
    private parentStore: CatalogStore

    public constructor(stores: Stores, parentStore: CatalogStore) {
        super(stores);
        this.parentStore = parentStore;
    }

    /**
     * 显示新增标记点界面
     */
    @action
    public showCreateKeyframe(currentTime: number, img: string) {
        let keyframe: entityTypes.IMarKeyframe = {
            isNew: true,
            keyframeNo: currentTime,
            inpoint: currentTime,
            filePath: img,
            description: '',
            isIconFrame: false
        };
        this.setCurrentKeyframe(keyframe);
    }

    @action
    public setCurrentKeyframe(keyframe?: entityTypes.IMarKeyframe) {
        this.currentKeyframe = keyframe;
    }

    @action
    public onCurrentKeyframeDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (this.currentKeyframe){
            this.currentKeyframe.description = e.target.value;
        }
    }

    @action
    public handleKeyframeIsIconFrame = (e: CheckboxChangeEvent) => {
        if (this.currentKeyframe){
            this.currentKeyframe.isIconFrame = e.target.checked;
        }
    }

    /** 显示指定标记点界面 */
    @action
    public showKeyframeTab(keyframe: entityTypes.IMarKeyframe, edit: boolean) {
        this.setCurrentKeyframe(keyframe);
    }

    @action
    public getKeyframes(): Promise<entityTypes.IMarKeyframe[]> {
        return new Promise((resolve, reject)=>{
            let entity = this.parentStore.entity;
            entityApi.getKeyframes(entity.contentId, this.parentStore.params.site, entity.type, entity.paths && entity.paths.length > 0 ? entity.paths[0].frameRate : 0).then((res) => {
                if (res.data.data) {
                    this.updateMarkeyframes(res.data.data);
                    resolve(res.data.data);
                }
            })
        })
    }

    @action
    public updateMarkeyframes(keyframes: entityTypes.IMarKeyframe[]) {
        this.parentStore.entity.markeyframes = keyframes;
    }
}