import { AxiosPromise } from 'axios';
import { IResponse, http } from 'mam-core-react/dist/modules/http';
import navConfigTypes from 'src/types/navConfigTypes';
import { stores } from 'src/stores';

namespace navConfigApi{
    export const queryAllNavigation = (): AxiosPromise<IResponse<navConfigTypes.IQueryAllNavigationRes[]>> => {
        return http.get(`/navigation/query`)
    }
    export const addNavigation = (params:navConfigTypes.ISelectedNav): AxiosPromise<IResponse<string>> => {
        return http.post(`/navigation/add`,params)
    }
    export const editNavigation = (params:navConfigTypes.ISelectedNav): AxiosPromise<IResponse<string>> => {
        return http.post(`/navigation/update`,params)
    }
    export const deleteNavigation = (id:number): AxiosPromise<IResponse<string>> => {
        return http.get(`/navigation/delete?id=${id}`)
    }
    export const orderNavigation = (params:navConfigTypes.IQueryAllNavigationRes): AxiosPromise<IResponse<boolean>> => {
        return http.post(`/navigation/order`,params)
    }
}

export default navConfigApi
