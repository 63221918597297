import { BaseStore } from 'mam-core-react';
import { Stores } from 'src/stores';
import IntelligentTimelineStore from './intelligentTimelineStore';
import { action, runInAction, observable } from 'mobx';
import { message } from 'antd';
import iEntityTypes from 'src/types/iEntityTypes'
import iEntityApi from 'src/apis/iEntityApi';
import _ from 'lodash'
import searchByThread from './facetSearchThread';

export default class FacetSearchStore extends BaseStore<Stores> {
    @observable
    public allParts: iEntityTypes.IFacetSearchPart[] = [];
    @observable
    public facets: iEntityTypes.IFacetSearchFacetItem[] = [];
    @observable
    public selectedFacets: iEntityTypes.ISelectedFacetItem[] = [];
    @observable
    public searchPartResult: iEntityTypes.IFacetSearchPart[] = [];
    @observable
    public isSearchingPartList: boolean = false;
    @observable
    public selectedPart?: iEntityTypes.IFacetSearchPart;
    private parentStore: IntelligentTimelineStore;

    public constructor(stores: Stores, parentStore: IntelligentTimelineStore){
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public queryAllParts(type: string){
        iEntityApi.getMetadataObject(this.parentStore.parentStore.params.contentId, type).then(res=>{
            runInAction(()=>{
                if (res.data.data && res.data.data.length > 0 && res.data.data[0].metadata){
                    this.allParts = res.data.data[0].metadata.filter((item: iEntityTypes.IFacetSearchPart)=>{
                        return item.type !== '出镜演员' || item.name !== 'unnamed';
                    });
                    this.getFacets(type);
                }
            });
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        });
    }

    @action
    public setAllParts(type: string, parts: iEntityTypes.IFacetSearchPart[]){
        this.allParts = parts.filter((item: iEntityTypes.IFacetSearchPart)=>{
            return item.type !== '出镜演员' || item.name !== 'unnamed';
        });
        this.getFacets(type);
    }

    @action
    public setSelectedFacets(typeName: string, facetValue: string){
        let facet = _.find(this.selectedFacets, {typeName, facetValue});
        if (facet){//已经选中，则删除
            _.remove(this.selectedFacets, (item)=>facet === item);
        }
        else {
            _.remove(this.selectedFacets, {typeName});
            this.selectedFacets.push({
                typeName, 
                facetValue
            });
        }
        this.searchPartList();
    }

    @action
    public setSelectedPart(part: iEntityTypes.IFacetSearchPart){
        this.selectedPart = part;
    }

    @action
    public searchPartList(){
        if (this.selectedFacets.length === 0){
            return;
        }
        this.searchPartResult = [];
        this.isSearchingPartList = true;
        searchByThread(this.allParts, this.selectedFacets).then(data=>{
            runInAction(()=>{
                this.searchPartResult = data;
                this.isSearchingPartList = false;
            })
        })
    }

    private getFacetsByType(facets: iEntityTypes.IFacetSearchFacetItem[], type: string, part: iEntityTypes.IFacetSearchPart){
        if (part.type === type){
            let facet = _.find(facets, {showValue: type})
            if (!facet){
                facets.push({
                    showValue: type,
                    values: [{
                        name: part.name,
                        icon: part.icon
                    }]
                });
            }
            else {
                if (_.findIndex(facet.values, (value)=>value.name === part.name) === -1){
                    facet.values.push({
                        name: part.name,
                        icon: part.icon
                    });
                }
            }
        }
    }

    private getFacets(type: string){
        let facets: iEntityTypes.IFacetSearchFacetItem[] = []
        this.allParts.forEach((part)=>{
            if (type === 'model_sobey_smart_partyquery'){
                this.getFacetsByType(facets, '精彩时刻', part);
                this.getFacetsByType(facets, '出镜嘉宾', part);
                this.getFacetsByType(facets, '节目类型', part);
            }
            else if (type === 'model_sobey_smart_tv'){
                this.getFacetsByType(facets, '影视片段', part);
                this.getFacetsByType(facets, '人物动作', part);
                this.getFacetsByType(facets, '人物表情', part);
                this.getFacetsByType(facets, '出镜演员', part);
            }
        })
        this.facets = facets;
    }
}