import { stores } from 'src/stores';
import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import mmsTypes from '../types/mmsTypes';
import ccTypes from './../types/ccTypes';
import { IRequset } from './../components/biz/toolbar/btns/mmsTask';
import favoriteTypes from 'src/types/favoriteTypes';
import { IResponse } from "mam-core-react/dist/modules/http";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import iSearchTypes from 'src/types/iSearchTypes';

namespace mmsApi {
    export namespace download {
        export const validDownload = (data: mmsTypes.downloadTypes.IValidOrGetFileDownload) => {
            return http.post<any>(`/entity/valid-download`, data)
        }
        export const getFileDownloadInfo = (data: object) => {
            return http.post<any>(`/entity/get-download-info`, data)
        }
        // 验证积分
        export const getCheckValidScore = (num: number) => {
            return http.get<any>(`/user/valid-score?type=1003&materialCount=${num}`)
        }
        export const recordDownload = (contentId: string, path: string)=>{
            return http.get<any>(`/download/resource?contentId=${contentId}&path=${encodeURIComponent(path)}&isOss=true`)
        }
    }
    export namespace favorite {
        export const getFavoriteTags = () => {
            return http.get<IResponse<favoriteTypes.IFavoritePageResponse>>(`/favorite/query/tags`)
        }
        export const setFavoriteTags = (data: mmsTypes.favoriteTypes.ISetFavorite) => {
            return http.post<IResponse<any>>(`/favorite`, data)
        }
        export const cancelFavorite = (data: mmsTypes.favoriteTypes.ISetFavorite) => {
            return http.post<any>(`/favorite`, data)
        }
        export const updateFavoriteTags = (data: mmsTypes.favoriteTypes.IFavoriteTagsUpdateRequest) => {
            return http.post<IResponse<boolean>>(`/favorite/update/tags`, data)
        }
        // export const favoriteSaveAs = (data: mmsTypes.favoriteTypes.ISetFavorite) => {
        //     return http.post<any>(`/favorite/copy`, data)
        // }
        export const importGuids = (url: string,data: mmsTypes.favoriteTypes.IImportGuids) => {
            return http.post<IResponse<any>>(url, data) // `/favorite/import`
        }
    }
    export namespace collection {
        export const collect = (data: mmsTypes.collectionTypes.ICollectReq) => {
            return http.post(`/favorite/favorite`, data)
        }
    }
    export const getHistoryKeywords = (param: string): AxiosPromise<IResponse<mmsTypes.downloadTypes.IHistoryKeywords[]>> => {
        return http.get<IResponse<mmsTypes.downloadTypes.IHistoryKeywords[]>>(`/search/tip-search?keyword=${param}`)
    }
    export const deleteHistoryKeywords = (param?: string): AxiosPromise<IResponse<boolean>> => {
        return http.delete(`/search/history${!param ? '' : '/' + param}`)
    }

    export const getHotKeywords = (top: number): AxiosPromise<IResponse<mmsTypes.hotKeywordsTypes.IHotKeywords[]>> => {
        return http.get<IResponse<mmsTypes.hotKeywordsTypes.IHotKeywords[]>>(`/search/hot/keyword?top=${top}`)
    }

    export namespace exportModal {
        export const getExternalsystem = (): AxiosPromise<IResponse<mmsTypes.exportTypes.IGetExternalsystem[]>> => {
            return http.get<IResponse<mmsTypes.exportTypes.IGetExternalsystem[]>>('/export/get-externalsystem')
        }

        export const getTranscodeTemplate = (): AxiosPromise<IResponse<mmsTypes.exportTypes.IGetTranscodeTemplate[]>> => {
            return http.get<IResponse<mmsTypes.exportTypes.IGetTranscodeTemplate[]>>('/export/get-transcodetemplate')
        }

        export const getEntityInfo = (param: mmsTypes.exportTypes.IGetInfoParams): AxiosPromise<IResponse<mmsTypes.exportTypes.IGetEntityInfo[]>> => {
            return http.post<IResponse<mmsTypes.exportTypes.IGetEntityInfo[]>>('/export/entity/info', param)
        }

        export const getLm = () => {
            return http.get('/programcode/get-column')
        }

        export const getProgramcode = (params: any) => {
            return http.post('/programcode/get', params)
        }
        export const bindProgramcode = (params: any) => {
            return http.post('/programcode/bind', params)
        }
        export const unbindProgramcode = (params: any) => {
            return http.post('/programcode/unbind', params)
        }

        export const getValidateInteractionFields = (url: string, params: any) => {
            return http.post(url, params)
        }

        export const exporttask = (params: mmsTypes.exportTypes.IExportTaskReq) => {
            return http.post('/export/exporttask', params)
        }
        //出库查重
        export const repeatCheck = (params: mmsTypes.exportTypes.IExportTaskReq): AxiosPromise<IResponse<mmsTypes.exportTypes.IRepeatCheckResItem[]>> => {
            return http.post<IResponse<mmsTypes.exportTypes.IRepeatCheckResItem[]>>('/export/repeat-check', params)
        }
        //出库检查素材是否在线，在线才能继续出库
        export const onlineCheck = (params: mmsTypes.exportTypes.IOnlineCheckReq[]): AxiosPromise<IResponse<mmsTypes.exportTypes.IOnlineCheckRes[]>> => {
            return http.post<IResponse<mmsTypes.exportTypes.IOnlineCheckRes[]>>('/archivemanager/api/external/medium/offline-check', params)
        }

        //出库前判断是否有敏感人物
        export const exportCheck = (request:mmsTypes.exportTypes.IExportTaskReq): AxiosPromise<IResponse<mmsTypes.exportTypes.IExportCheckRes>> => {
            return http.post<IResponse<mmsTypes.exportTypes.IExportCheckRes>>('/export/check', request)
        }

        //查询栏目
        export const queryColumns = (): AxiosPromise<IResponse<mmsTypes.exportTypes.IColumn[]>> => {
            return http.get('/column/get-current-column')
        }

        //查询串联单
        export const queryScriptRundown = (startTime: string, endTime: string, columnid: string): AxiosPromise<IResponse<mmsTypes.exportTypes.IScriptRundown[]>> => {
            return http.get(`/scriptdept/rundown?startTime=${startTime} 00:00:00&endTime=${endTime} 23:59:59&columnid=${columnid}`)
        }
    }

    export namespace deleteModal {
        export const getStartCheckDeletion = (params: mmsTypes.deleteTypes.IStartCheckdeletion): AxiosPromise<IResponse<string>> => {
            return http.post<IResponse<string>>('/resource/start-check-deletion', params)
        }

        export const getCheckResult = (params: string): AxiosPromise<IResponse<mmsTypes.deleteTypes.IGetCheckResultRespones>> => {
            return http.get<IResponse<mmsTypes.deleteTypes.IGetCheckResultRespones>>(`/resource/get-check-result?taskId=${params}`)
        }

        export const getLocks = (lockIds: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/get-locks', lockIds)
        }

        export const deleteItems = (reqUrl: string, items: any[]) => {
            return http.post(`${reqUrl}delete`, items)
        }

        export const deleteLocks = (url: string, items: any) => {
            return http.post(url, items)
        }

        export const cancelCheckDeletion = (taskId: string) => {
            return http.get(`/resource/cancel-check-deletion?taskId=${taskId}`)
        }
    }

    export namespace CreatePlanningModal {
        export const createTopicTask = (data: any) => {
            return http.post('/api/financial-media/create-topic-task', data)
        }
    }

    export namespace OutputExcelModal {

        export const exportCsvForPart = (contentIds: string[]) => {
            let token = ''
            if (stores.userStore.currentUser) {
                token = stores.userStore.currentUser.userToken
            }
            let timestamp = new Date().getTime();
            let xmlResquest = new XMLHttpRequest();
            xmlResquest.open("POST", "/search/exportCsvForPart", true);
            xmlResquest.setRequestHeader("Content-type", "application/json");
            xmlResquest.responseType = "arraybuffer";
            xmlResquest.send(JSON.stringify(contentIds));
            xmlResquest.onload = () => {
                let filename = "";
                let disposition = xmlResquest.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '')
                    };
                }
                let content = xmlResquest.response;
                let elink = document.createElement('a');
                elink.download = decodeURI(filename);
                elink.style.display = 'none';
                let blob = new Blob([content]);
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
            };
            // return http.post<IResponse<string>>('/search/exportCsvForPart', contentIds)
        }

        export const exportCsvForAll = (req: ccTypes.searchTypes.IFullSearchReq | iSearchTypes.IFullSearchDataReq) => {
            let token = ''
            if (stores.userStore.currentUser) {
                token = stores.userStore.currentUser.userToken
            }
            let timestamp = new Date().getTime();
            let xmlResquest = new XMLHttpRequest();
            // xmlResquest.open("POST", process.env.DEV_SERVER + "/search/exportCsvForAll?token=" + token, true);
            xmlResquest.open("POST", "/search/exportCsvForAll", true);
            xmlResquest.setRequestHeader("Content-type", "application/json");
            xmlResquest.responseType = "arraybuffer";
            xmlResquest.send(JSON.stringify(req));
            xmlResquest.onload = () => {
                let filename = "";
                let disposition = xmlResquest.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '')
                    };
                }
                let content = xmlResquest.response;
                let elink = document.createElement('a');
                elink.download = decodeURI(filename);
                elink.style.display = 'none';
                let blob = new Blob([content]);
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
            };
            // return http.post<IResponse<string>>('/search/exportCsvForAll', req)
        }
    }

    export namespace MoveModal {
        export const getDefaultChildrens = (params: any) => {
            return http.post('/folder/get-default-childrens/', params)
        }

        export const checkName = (type: string, models: mmsTypes.moveTypes.IMoveCheckName[]): AxiosPromise<IResponse<mmsTypes.moveTypes.IMoveCheckNameRes[] | mmsTypes.moveTypes.IMoveCheckNameResErr>> => {
            return http.post<IResponse<mmsTypes.moveTypes.IMoveCheckNameRes[] | mmsTypes.moveTypes.IMoveCheckNameResErr>>(`/${type}-tree/check-name`, models)
        }

        export const move = (url: string, request: mmsTypes.moveTypes.IMoveRequest): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>(url, request)
        }
    }

    export namespace UnlockModal {
        export const getLocks = (arr: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/get-locks', arr)
        }

        export const deleteLocks = (params: any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/delete-locks', params)
        }
    }

    export namespace SmartProcessModal {
        export const sendToFlow = (request: mmsTypes.smartProcessTypes.ISmartProcessSendToFlowReq[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/intelligentProcess/send-to-flow', request)
        }
    }

    export namespace TaskModal {
        export const getSpecifiedEntityData = (contentIds: any[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/get-specified-entity-data', contentIds)
        }

        export const getDescription = (request: IRequset): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/get-description', request)
        }

        export const getRelationEntityByIds = (hsIds: any[]): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/entity/get-relation-entity-by-ids?${hsIds.join('&')}`)
        }

        export const updateBaseEntityData = (item: any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/update-base-entity-data', item)
        }
    }

    export namespace ShareModal {
        export const getSites = (): AxiosPromise<IResponse<mmsTypes.shareTypes.ISites[]>> => {
            return http.get<IResponse<mmsTypes.shareTypes.ISites[]>>('/home/get-sites')
        }

        export const getFiles = (contentIds: string[]): AxiosPromise<IResponse<mmsTypes.shareTypes.IFiles[]>> => {
            return http.post<IResponse<mmsTypes.shareTypes.IFiles[]>>('/file/getFiles', contentIds)
        }

        export const getWorkTeam = (): AxiosPromise<IResponse<mmsTypes.shareTypes.IMyTeams[]>> => {
            return http.get<IResponse<mmsTypes.shareTypes.IMyTeams[]>>('/work-team/my-teams')
        }

        export const shareWorkteam = (contentIds: string[], groupCodes: string[]): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/share/workteam', { contentIds, groupCodes })
        }

        export const getOrganizationAllSubs = (siteCode: string): AxiosPromise<IResponse<mmsTypes.shareTypes.IOrganizationAllSubs>> => {
            return http.get<IResponse<mmsTypes.shareTypes.IOrganizationAllSubs>>(`/organization/get-organization-all-subs?opsite=${siteCode}`)
        }

        export const getOrganizationsUsers = (req: mmsTypes.shareTypes.IQueryUserReq, siteCode?: string): AxiosPromise<IResponse<mmsTypes.shareTypes.IOrganizationsUsersRes>> => {
            return http.post<IResponse<mmsTypes.shareTypes.IOrganizationsUsersRes>>(`/organization/get-organizations-users${siteCode ? `?opsite=${siteCode}` : ''}`, req)
        }

        export const directToUsers = (data: mmsTypes.shareTypes.IDirectToUsersReq) => {
            return http.post('/share/direct-to-users', data)
        }
    }

    export namespace selectDirectoryModal {
        export const getChildren = (parentId: string, trees?: number[]): AxiosPromise<IResponse<mmsTypes.selectDirectoryModalTypes.ITreeItem[]>> => {
            return http.post<IResponse<mmsTypes.selectDirectoryModalTypes.ITreeItem[]>>('/folder/get-default-childrens', {
                'parentId': parentId,
                'trees': trees
            })
        }
        export const createDirectory = (params: mmsTypes.selectDirectoryModalTypes.ISelectDirectoryParams,
            item: mmsTypes.selectDirectoryModalTypes.ITreeItem): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/' + params.requestType + '-tree/create', item);
        }
    }
    //新专辑
    export namespace createAlbumModal {
        export const getAlbumConditions = (): AxiosPromise<IResponse<IFormItem[]>> => {
            return http.get<IResponse<IFormItem[]>>('/aialbum/conditions')
        }
    }

    export namespace TechanditModal {
        export const sendflow = (params: string, ids: string[]): AxiosPromise<IResponse<string>> => {
            return http.post<IResponse<string>>(`/flowstep/hiveflow/sendflow/${params}`, ids)
        }
    }

    export namespace clearInvalidShareModal {
        export const clearInvalidShare = (isSend: boolean): AxiosPromise<IResponse<boolean>> => {
            return http.get<IResponse<boolean>>(`/share/clear-invalid-share?sort=ctime&order=desc&linkType=6&isSend=${isSend}`)
        }
    }

    export namespace cancelShareModal {
        export const cancelShareWorkteam = (contentIds: string[], groupCodes: string[]): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/share/cancel-share-workteam', { contentIds, groupCodes })
        }

        export const cancelShareToUser = (data: string[]): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/share/cancel-share-to-user', data)
        }
    }

    export namespace copyModal {
        export const checkName = (type: string, data: any) => {
            return http.post(`/${type}-tree/check-name`, data)
        }

        export const treeCopy = (requestUrl: string, request: mmsTypes.copyTypes.IRequest): AxiosPromise<IResponse<{ [key: string]: string }>> => {
            return http.post<IResponse<{ [key: string]: string }>>(requestUrl, request)
        }

        export const createById = (request: mmsTypes.copyTypes.ICreateByIdTypes): AxiosPromise<IResponse<mmsTypes.copyTypes.ICreateByIdRes>> => {
            return http.post<IResponse<mmsTypes.copyTypes.ICreateByIdRes>>('/folder/create-by-id', request)
        }

        export const getFilesByPath = (contentId: string): AxiosPromise<IResponse<mmsTypes.copyTypes.IGetFilesByPathRes[]>> => {
            return http.get<IResponse<mmsTypes.copyTypes.IGetFilesByPathRes[]>>(`/file/get-files-by-path?parentId=${contentId}`)
        }
    }

    export namespace CopyrightModal {
        export const getFields = (contentId: string): AxiosPromise<IResponse<IFormItem[]>> => {
            return http.get<IResponse<IFormItem[]>>('/copyright/getCopyrightFields?contentId=' + contentId)
        }
    }

    export namespace restoreModal {
        export const checkRestore = (models: mmsTypes.restoreTypes.IRestoreReq[]): AxiosPromise<IResponse<mmsTypes.restoreTypes.IRestoreRes>> => {
            return http.post<IResponse<mmsTypes.restoreTypes.IRestoreRes>>('/recycle/check-restore', models)
        }

        export const restore = (models: mmsTypes.restoreTypes.IRestoreReq[]): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/recycle/restore', models)
        }
    }

    export namespace deleteThoroughlyModal {
        export const recycleDelete = (items: any[]): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/recycle/delete', items)
        }

        export const syncUserQuota = (): AxiosPromise<IResponse<boolean>> => {
            return http.get<IResponse<boolean>>('/user/sync-user-quota')
        }
    }

    export namespace clearModal {
        export const clear = (params: mmsTypes.clearTypes.IClearReq): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/recycle/clear', params)
        }
    }

    export namespace createFolderModal {
        export const getPermissiontemplate = (): AxiosPromise<IResponse<mmsTypes.createFolderTypes.IPermissiontemplate[]>> => {
            return http.get<IResponse<mmsTypes.createFolderTypes.IPermissiontemplate[]>>('/business/external-system/get-permissiontemplate')
        }

        export const savePublicFolder = (request: mmsTypes.createFolderTypes.ISavePublicFolderReq): AxiosPromise<IResponse<mmsTypes.createFolderTypes.ISavePublicFolderRes>> => {
            return http.post<IResponse<mmsTypes.createFolderTypes.ISavePublicFolderRes>>('/folder/save-public-folder', request)
        }
    }
    //发起归档
    export namespace applyArchiveModal {
        export const getArchiveGroup = (): AxiosPromise<IResponse<any[]>> => {
            let siteCode = ''
            if (stores.userStore.currentUser) {
                siteCode = stores.userStore.currentUser.siteCode
            }
            return http.get<IResponse<any[]>>('/api/archive/get-flowpolicy?site=' + siteCode)
        }
        //以前旧的检查素材是否归档的接口：返回的实际是有任务的素材
        export const checkEntityArchived = (request: mmsTypes.applyArchiveTypes.ICheckEntityArchivedReq[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>('/archivemanager/api/entity/CheckEntityArchived', request)
        }
        export const applyArchivePost = (request: mmsTypes.applyArchiveTypes.IApplyArchivePostReq): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/archivemanager/api/flow/manual-archive', request)
        }
        //新的检查素材是否归档的接口：返回全部的素材，依据选中的归档组以及返回具体素材状态进行判断是否未发起过归档、是否归档中、是否已归档
        export const newCheckEntityArchived = (request: mmsTypes.applyArchiveTypes.ICheckEntityArchivedReq[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>('/archivemanager/api/entity/archive-info', request)
        }
    }
    //发起回迁
    export namespace applyRestoreModal {
        export const applyRestore = (request: mmsTypes.applyRestoreTypes.IApplyRestoreTypesReq): AxiosPromise<IResponse<string>> => {
            return http.post<IResponse<string>>('/megateway/api/entity/restore', request)
        }
    }
    //获取归档组名称
    export namespace getOriginGroupNameModal {
        export const getOriginGroupName = (): AxiosPromise<IResponse<string[]>> => {
            let token = ''
            if (stores.userStore.currentUser) {
                token = stores.userStore.currentUser.userToken
            }
            return http.get<IResponse<string[]>>('/api/entity/origingroupname?token=' + token)
        }
    }

    // 挑选篮批量编辑
    export namespace batchEditModal {
        export const edit = (data: ccTypes.IBasketEditItem[]) => {
            return http.post('/entity/batch/edit', data)
        }
    }

    //导出Excel字段配置
    export namespace exportExcel {
        //获取字段配置信息
        export const getExportFields = () => {
            return http.get('/search/get-export-fields-user');
        }
        //导出字段配置选择成功
        export const saveExportFields = (exportFileds: ccTypes.IExportFileds[]) => {
            return http.post('/search/save-export-fields-user', exportFileds);
        }
    }

    //同步推送
    export namespace syncPushModal {
        export const syncPush = (contentIds: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/entity-push', contentIds)
        }
    }
    //拉取
    export namespace syncPullModal {
        export const syncPull = (contentIds: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/entity-pull', contentIds)
        }
    }

    //批量素材更新元数据
    export const updateEntitydatas = (items: any[]): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/update-entities-entitydatas', items)
    }

    export namespace programCode {
        export const bindBCProgramCode = (params: any) => {
            return http.post('/broadcast/bind-bc-programcode', params)
        }
        export const unbindBCProgramCode = (params: any) => {
            return http.post('/broadcast/unbind-bc-programcode', params)
        }
        export const listProgramCode = (params: any) => {
            return http.post('/dic_data/broadcast/list-program-code', params)
        }
        export const canBindProgramcode = (params: any) => {
            return http.post('/broadcast/can-bind-bc-programcode', params)
        }
    }

    export const recycleAdd = (params: mmsTypes.IRecycleAddReq[]) => {
        return http.post('/recycle/add', params)
    }

    // 审核完成：smg定制功能
    export const auditCompleted = (contentId: string): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/smg/mark-scene-audit', {contentId})
    }

    //编目相关
    export namespace catalog {
        //发起编目
        export const catalogCreate = (contentIds: string[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/task/create`,contentIds)
        }
        //编目提交
        export const catalogSubmit = (taskIds: number[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/mytask/submit`,taskIds)
        }
        //审核完成：审核通过/打回
        export const auditComplete = (req: mmsTypes.ICatalogAuditCompleteReq): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/catalogue/v2/audit/review`,req)
        }
    }

    //导出审核
    export namespace exportAudit {
        //通过/打回
        export const auditComplete = (req: mmsTypes.IExportAuditCompleteReq): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/flowstep/exportaudit/audittask`,req)
        }
    }

    export namespace batchInitiatingSmartProcess4All{
        //之前发起原子能力的接口：检索过滤的素材
        export const sendBatchFlow = (req: mmsTypes.batchInitiatingSmartProcess4All.ISendBatchFlowReq): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/intelligentProcess/send-batch-flow`,req)
        }
        //之前发起原子能力的接口：已选的素材
        export const sendBatchFlowCustom = (req: mmsTypes.batchInitiatingSmartProcess4All.ISendBatchFlowCustomReq[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/intelligentProcess/send-batch-flow/custom`,req)
        }
        //智能分析新接口：检索过滤的素材
        export const sendSmartFlowForSearch = (req: mmsTypes.batchInitiatingSmartProcess4All.ISendSmartFlowForSearchReq): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/intelligentProcess/v2/send-batch-flow`,req)
        } 
        //智能分析新接口：已选的素材
        export const sendSmartFlowForEntity = (req: mmsTypes.batchInitiatingSmartProcess4All.ISendSmartFlowForEntityReq): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/intelligentProcess/v2/send-smart-flow`,req)
        } 
        //智能分析获取配置项接口
        export const getSmartFlowConfig = (): AxiosPromise<IResponse<mmsTypes.batchInitiatingSmartProcess4All.ISmartFlowConfigItem[]>> => {
            return http.get<IResponse<mmsTypes.batchInitiatingSmartProcess4All.ISmartFlowConfigItem[]>>(`/intelligentProcess/v2/smart-flow-config`)
        }
    }

    export namespace sr{
        export const getTranscodetemplates = (): AxiosPromise<IResponse<mmsTypes.sr.ITranscodetemplateItem[]>> => {
            return http.get<IResponse<mmsTypes.sr.ITranscodetemplateItem[]>>(`/supertranscode/super-transcodetemplate`)
        }

        export const supertranscode = (param: mmsTypes.sr.ISupertranscodeReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/supertranscode/super-transcode-filegroup`, param)
        }
    }
}
export default mmsApi;
