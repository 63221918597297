import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import { message } from 'antd';
import _ from 'lodash'
import ManageCenterStore from "../../manageCenterStore";
import manageCenterApi from 'src/apis/manageCenterApi';
import manageCenterTypes from 'src/types/manageCenterTypes';
export default class ProcessMonitoringStores extends ExtendStore {

    @observable
    public processRes:manageCenterTypes.processMonitoring.ISearchProcessRes | undefined;
    @observable
    public tasktype:number = 1; //出入库判定条件
    public priority:number;
    @observable
    public modalVisiable:boolean = false;
    @observable
    public page: number = 1
    @observable
    public selectedRows: manageCenterTypes.processMonitoring.ITableListItem[]
    @observable
    public listSelectedRowKeys: any[]
    @observable
    public listSelectedRows: any[]
    private manageCenterStore: ManageCenterStore

    public constructor(stores: Stores, manageCenterStore: ManageCenterStore){
        super(stores)
        this.manageCenterStore = manageCenterStore
    }

    @action
    public searchProcess =  (page?:number,keyword?:string)=>{
        return new Promise((resolve, reject)=>{
            let params: manageCenterTypes.processMonitoring.ISearchProcessReq = {
                conditions:{
                    cliptype: '',
                    createusername: '',
                    targetInfoCode: '',
                    taskstatus: '',
                    tasktype: this.tasktype
                },
                order: 'desc',
                pageIndex:page || 1,
                pageSize:15,
                sort:'createtime',
                userCode: this.stores.userStore.currentUser?.userCode
            }
            if(keyword){
                params.keyword = keyword
            }
            manageCenterApi.processMonitoring.searchProcess(params).then((res)=>{
                runInAction(()=>{
                    if(res.data.success){
                        this.processRes = res.data.data
                    }
                })
            })
        })
    }

    @action
    public taskOperate = (type:string,description:string,priority?:number)=>{
        let taskIds:number[] = []
        if(this.selectedRows.length === 0){
            message.warning('请至少选择一个流程');
            return
        }
        switch(type){
            case 'redo':
                taskIds = this.selectedRows.filter((item: any)=> (item.taskStatus !== 2 )).map((item:any)=>item.id)
                if(taskIds.length === this.selectedRows.length){

                }else if(taskIds.length !== this.selectedRows.length && taskIds.length !== 0){
                    message.warning(`已过滤出已完成流程共${this.selectedRows.length - taskIds.length}条`)
                }else{
                    message.warning('请选择执行中或者失败的流程')
                    return
                }
                break
            case 'priority':
                taskIds = this.selectedRows.filter((item: any)=> (item.taskStatus === 1 )).map((item:any)=>item.id)
                break
            case 'stop':
                taskIds = this.selectedRows.filter((item: any)=> (item.taskStatus === 1 )).map((item:any)=>item.id)
                if(taskIds.length === this.selectedRows.length){
                    
                }else if(taskIds.length !== this.selectedRows.length && taskIds.length !== 0){
                    message.warning(`已过滤出已完成和失败的流程共${this.selectedRows.length - taskIds.length}条`)
                }else{
                    message.warning('请选择执行中的流程')
                    return
                }
                break
            default:
                message.error('有未知流程存在')
                break
        }
        return new Promise((resolve, reject)=>{
            manageCenterApi.processMonitoring.taskOperate(type,taskIds,priority).then((res)=>{
                runInAction(()=>{
                    if(res.data.data === true){
                        message.success(description);
                        this.searchProcess(this.page)
                        this.clearListSelected()
                        resolve()
                    }else{
                        message.error('相关操作失败')
                        reject()
                    }
                    reject()
                })
            })
        })
    }

    @action
    public setTaskType = (tasktype:number)=>{
        this.tasktype = tasktype;
    }

    @action
    public setSelectedRows = (selectedRows: manageCenterTypes.processMonitoring.ITableListItem[])=>{
        this.selectedRows = selectedRows;
    }

    @action
    public setModalVisiable = (modalVisiable:boolean)=>{
        this.modalVisiable = modalVisiable
    }

    @action
    public setPriority = (priority:number)=>{
        this.priority = priority
    }

    @action
    public setPage = (page:number)=>{
        this.page = page
    }

    @action
    public setListSelectedRowKeys = (listSelectedRowKeys:any[],selectedRows:any[])=>{
        this.listSelectedRowKeys = listSelectedRowKeys
        this.listSelectedRows = selectedRows
    }

    /*列表清空选中*/
    @action
    public clearListSelected=()=>{
        this.listSelectedRowKeys = []
        this.listSelectedRows = []
    }
}