import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import { IBasePageResponse } from 'src/types/commonTypes';
import recycleTypes from 'src/types/recycleTypes';
import {IResponse} from "mam-core-react/dist/modules/http";

namespace recycleApi {
    export const fullSearch = (p: recycleTypes.IRecyclePageRequest): AxiosPromise<IResponse<IBasePageResponse<recycleTypes.IQueryRecycleResponse>>> => {
        return http.post<IResponse<IBasePageResponse<recycleTypes.IQueryRecycleResponse>>>('/recycle/query'
            // + p.pageIndex + '&pageSize=' + p.pageSize + '&sort=' + p.sort + '&order=' + p.order + '&keyword=' + encodeURI(p.keyword) +
            // '&conditions.tree path=' + encodeURI(p.conditions && p.conditions['treepath'] ? p.conditions['treepath'] : '')
            , p)
    }

    export const checkCanRestore = (req: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/recycle/check-restore');
    }

    export const deleteCompletely = (req: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/recycle/delete', req);
    }
}

export default recycleApi