import { Stores } from 'src/stores';
import ExtendStore from 'src/stores/base/extendStore';
import IIndexStore from '../iIndexStore';
import { action, runInAction, observable } from 'mobx';
import iIndexApi from 'src/apis/iIndexApi';
import { message } from 'antd';
import _ from 'lodash'
import iIndexTypes from 'src/types/iIndexTypes';

export default class SceneListStore extends ExtendStore {
    @observable
    public scenes: iIndexTypes.secList.ISceneListItem[] = [];
    @observable
    public isReqList: boolean = false;
    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
    }

    @action
    public queryScenes(){
        this.isReqList = true
        return new Promise((resolve, reject)=>{
            iIndexApi.getSceneList().then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        this.scenes = res.data.data;
                        let names: string[] = _.map(this.scenes, 'showKey')
                        if (this.scenes.length > 0){
                            this.getKeyframes('background', names, 1);
                        }
                    }
                    resolve(true);
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    // 获取关键帧
    @action
    public getKeyframes(type:string, names: string[], num: number) {
        iIndexApi.getKeyframe(type, names, num).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    if(this.scenes) {
                        this.scenes.forEach((item)=>{
                            item.keyframe = res.data.data[item.showKey][0]
                        })
                    }
                }
                this.isReqList = false;
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            this.isReqList = false;
        });
    }
}