import ExtendStore from 'src/stores/base/extendStore';
import _ from 'lodash'
import IIndexStore from '../iIndexStore';
import { Stores } from 'src/stores';
import { observable, action, runInAction } from 'mobx';
import iIndexTypes from 'src/types/iIndexTypes';
import iIndexApi from 'src/apis/iIndexApi';
import { message } from 'antd';

export default class FormatStaStore extends ExtendStore {
    public entityTypes: iIndexTypes.secList.IEntityType[] = [
        {
            name: '视频',
            code: 'video'
        },
        {
            name: '音频',
            code: 'audio'
        },
        {
            name: '图片',
            code: 'picture'
        }
    ]
    @observable
    public selectedEntityType: string = 'video';
    public formatTypes: iIndexTypes.secList.IFormatType[] = [
        {
            name: '画质',
            code: 'Imagequality'
        },
        {
            name: '幅面',
            code: 'Aspectratio'
        },
        {
            name: '制式',
            code: 'Standard'
        },
        {
            name: '封装格式',
            code: 'Fileext'
        },
        {
            name: '码率',
            code: 'bitRate'
        }
    ]
    @observable
    public selectedFormatType: string = 'Imagequality';
    @observable
    public formatSummations?: iIndexTypes.secList.IFormatSummation[];
    @observable
    public selectedFormat?: string;
    private iIndexStore: IIndexStore;

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
    }

    @action
    public getFormatSummation(){
        this.formatSummations = undefined;
        iIndexApi.getFormatSummation(this.selectedEntityType, this.selectedFormatType).then((res)=>{
            runInAction(()=>{
                if (res.data.data){
                    this.formatSummations = res.data.data;
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        });
    }

    @action
    public setSelectedEntityType(selectedEntityType: string){ 
        this.selectedEntityType = selectedEntityType;
        this.selectedFormat = undefined;
    }

    @action
    public setSelectedFormatType(selectedFormatType: string){ 
        this.selectedFormatType = selectedFormatType;
        this.selectedFormat = undefined;
    }

    @action
    public setSelectedFormat(selectedFormat?: string){
        this.selectedFormat = selectedFormat;
    }
}