import BaseStore from "../../base/baseStore";
import {action, observable} from "mobx";
import {SmartMetadataType} from "../../entity/extends/smartViewStore";
import CatalogStore from "../catalogStore";
import {Stores} from "../../index";
import {default as catalogTypes} from "../../../types/catalogTypes";

export default class CombinePartStore extends BaseStore<Stores> {
    /** 是否显示片段合并 */
    @observable
    public isShowCombinePartModal: boolean = false;
    /** 合并成什么类型 */
    @observable
    public combineMode: SmartMetadataType;
    /** 是否演播室 */
    @observable
    public checkTvStudioList: catalogTypes.ICheckTvStudioItem[] = [];

    private parentStore: CatalogStore

    public constructor(stores: Stores, parentStore: CatalogStore) {
        super(stores);
        this.parentStore = parentStore;
    }

    @action
    public openCombinePartModal(){
        this.isShowCombinePartModal = true;
        const checkedParts = this.parentStore.getCheckedParts();
        let currCheckedPartType;
        if (checkedParts && checkedParts.length > 0){
            currCheckedPartType = checkedParts[0].partType;
            this.checkTvStudioList = [];
            this.setCombineMode(currCheckedPartType);
        }
    }

    @action
    public closeCombinePartModal() {
        this.isShowCombinePartModal = false;
    }

    @action
    public setCombineMode(combineMode: SmartMetadataType) {
        this.combineMode = combineMode;
        //合并成story，需要处理演播室
        if (this.combineMode === SmartMetadataType.story){
            const checkedParts = this.parentStore.getCheckedParts();
            if (checkedParts && checkedParts.length > 0){
                //story合成story
                if (checkedParts[0].partType === SmartMetadataType.story){
                    this.checkTvStudioList.push({
                        keyframeno: checkedParts[0].keyframeno,
                        isChecked: false
                    })
                }
                //scene合成story
                if (checkedParts[0].partType === SmartMetadataType.scene){
                    const prev = this.parentStore.getPrev(checkedParts[0])
                    const next = this.parentStore.getNext(checkedParts[checkedParts.length - 1])
                    if (prev === undefined && next !== undefined){//开头的几个，拆分为2个story
                        this.checkTvStudioList.push({
                            keyframeno: checkedParts[0].keyframeno,
                            isChecked: false
                        })
                        this.checkTvStudioList.push({
                            keyframeno: next.keyframeno,
                            isChecked: false
                        })
                    }
                    else if (prev !== undefined && next === undefined){//末尾的几个
                        const parent = this.parentStore.getParent(checkedParts[0]);
                        let allSceneChildren;
                        if (parent){
                            allSceneChildren = this.parentStore.getSceneByStory(parent);
                        }
                        if (allSceneChildren){
                            this.checkTvStudioList.push({
                                keyframeno: allSceneChildren[0].keyframeno,
                                isChecked: false
                            })
                            this.checkTvStudioList.push({
                                keyframeno: checkedParts[0].keyframeno,
                                isChecked: false
                            })
                        }
                    }
                    else if (prev !== undefined && next !== undefined) {//中间的几个
                        const parent = this.parentStore.getParent(checkedParts[0]);
                        let allSceneChildren;
                        if (parent){
                            allSceneChildren = this.parentStore.getSceneByStory(parent);
                        }
                        if (allSceneChildren){
                            this.checkTvStudioList.push({
                                keyframeno: allSceneChildren[0].keyframeno,
                                isChecked: false
                            })
                            this.checkTvStudioList.push({
                                keyframeno: checkedParts[0].keyframeno,
                                isChecked: false
                            })
                            this.checkTvStudioList.push({
                                keyframeno: next.keyframeno,
                                isChecked: false
                            })
                        }
                    }
                }
            }
        }
    }

    @action
    public setCheckTvStudioItemChecked(item: catalogTypes.ICheckTvStudioItem, check: boolean){
        item.isChecked = check;
    }
}