import * as React from 'react'
import { notification } from "antd"
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import {l} from "mam-core-react";
import { BellFilled } from '@ant-design/icons';

interface IProps{
    ip?:string
}

class SignalrNotification extends React.Component<IProps, any> {

    constructor(props: IProps) {
        super(props)
    }

    public componentDidMount(){
        this.init()
        //测试使用
        // notification.open({
        //     message: l('modal.notice','通知'),
        //     description: <a href="">测试1</a>,
        //     placement:'bottomRight',
        //     duration:0,
        //     icon: <Icon type="bell" />,
        //     className:'signalr-notification',
        // });
        // (window as any).setTimeout(() => {
        //     notification.open({
        //         message: l('modal.notice','通知'),
        //         description: <a href="">测试2</a>,
        //         placement:'bottomRight',
        //         duration:0,
        //         icon: <Icon type="bell" />,
        //         className:'signalr-notification',
        //     });
        // }, 1000);
    }

    public render() {
        return (<></>)
    }

    public init = () => {
        commonUtil.asyncLoadScript('/libs/signalr/signalr.min.js',()=>{
            let signalR = (window as any).signalR
            let connection = new signalR.HubConnectionBuilder()
                .withUrl(this.props.ip?'http://'+this.props.ip+'/MessageHub':'/MessageHub')
                .configureLogging(signalR.LogLevel.Information)
                .build()
            connection.start().catch((err:any) => { console.log(err.toString()) })
    
            connection.on('SendMessage', (res:any) => {
                if (res && res.content) {
                    notification.open({
                        message: l('modal.notice','通知'),
                        description: (res.senderNickName ? (res.senderNickName + ': '):'') + res.content,
                        placement:'bottomRight',
                        duration:0,
                        icon: <BellFilled />,
                        className:'signalr-notification',
                    })
                }
            })
        })
    } 
}

export default SignalrNotification
