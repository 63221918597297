import { Stores } from "../index";
import ExtendStore from "../base/extendStore";
import { action, observable, runInAction, computed } from "mobx";
import uploadTypes from "src/types/uploadTypes";
import _ from 'lodash'
import uploadApi from "src/apis/uploadApi";
import { commonUtil } from "mam-common-utils";
import { uploader } from 'src/components/upload/core/uploader';

/** web上传模块store */
export default class WebUploadStore extends ExtendStore {
    @observable
    public showEditMetaModal: boolean = false;
    @observable
    public showAttachmentModal: boolean = false;
    @observable
    public filterEntityTypes: uploadTypes.IFilterEntityTypes[] = [];

    public constructor(stores: Stores) {
        super(stores);
        this.initUploadStore(stores);
    }

    @computed
    public get getFilterEntityTypes(){
        if (!this.uploadStore.tasks || this.uploadStore.tasks.length === 0){
            return [];
        }
        return this.filterEntityTypes.filter((item)=>{
            return _.findIndex(this.uploadStore.tasks, {entityType: item.code}) > -1;
        })
    }

    @computed
    public get getCheckedFilterEntityTypes(){
        return this.getFilterEntityTypes.filter(item=>!item.unchecked);
    }

    @action
    public init(){
        this.filterEntityTypes = this.stores.configStore.config.entityTypes.filter((item)=>{
            return item.code !== 'rundown' && item.code !== 'sequence' 
                    && item.code !== 'scene' && item.code !== 'shot' && item.code !== 'biz_sobey_newsscript'
        })
    } 

    @action
    public setFilterEntityTypeStatus(item: uploadTypes.IFilterEntityTypes, unchecked: boolean){
        item.unchecked = unchecked;
    }

    @action
    public getUnFinishedTask(param: uploadTypes.IUnfinishedTaskReq){
        uploadApi.getUnfinishedTask(param).then((res) => {
            runInAction(() => {
                if (res.data.success === true && res.data.data)
                {
                    let newTasks: uploadTypes.ITask[] = []
                    res.data.data.filter((task)=>{
                        return task.files && task.files.length > 0;
                    }).forEach((task, index) => {
                        task.status = 'error';
                        task.inited = true;
                        task.sizeTotal = task.chunkFinished = task.chunkTotal = 0;
                        if (task.files)
                        {
                            task.files.forEach((file) => {
                                file.fileSizeString = commonUtil.formatSize(file.fileSize || 0);
                                file.progress = this.calcFileProgress(file);
                                file.status = file.progress === 100 ? 'success' : 'error';
                                if (!task.sizeTotal)
                                {
                                    task.sizeTotal = 0;
                                }
                                if (file.fileSize)
                                {
                                    task.sizeTotal += file.fileSize ;
                                }
                                if (!task.chunkTotal)
                                {
                                    task.chunkTotal = 0;
                                }
                                if (file.chunkTotal)
                                {
                                    task.chunkTotal += file.chunkTotal;
                                }
                            });
                        }
                        task.progress = this.calcTaskProgress(task);
                        task.sizeTotalString = commonUtil.formatSize(task.sizeTotal);
                        newTasks.push(task);

                        if (index === 0){
                            this.uploadStore.setOptions(uploader.getOptions({
                                taskType: task.taskType,
                                transferType: task.transferType
                            }))
                            if (task.taskType === uploadTypes.TaskTypes.GROUPUPLOAD 
                                || task.taskType === uploadTypes.TaskTypes.PICTUREPACKAGEUPLOAD){
                                this.uploadStore.setSelectedTask(task);
                            }
                        }
                    });
                    this.uploadStore.tasks = newTasks;
                }
            });
        });
    }

    @action
    public openEditMetaModal(task?: uploadTypes.ITask){
        if (task){
            this.uploadStore.setSelectedTask(task);
        }
        this.showEditMetaModal = true;
    }

    @action
    public closeEditMetaModal(){
        this.showEditMetaModal = false;
    }

    @action
    public openAttachmentModal(task?: uploadTypes.ITask){
        if (task){
            this.uploadStore.setSelectedTask(task);
            this.uploadStore.setSelectedTaskAttachmentFiles(task.attachmentFiles && task.attachmentFiles.length > 0 ? task.attachmentFiles : undefined);
            this.showAttachmentModal = true;
        }
    }

    @action
    public closeAttachmentModal(){
        this.showAttachmentModal = false;
    }

    /** 调用init接口以后，更新task状态，比如taskId，进度等 */
    @action
    public updateTaskStatusFromInit(task: uploadTypes.ITask){
        let targetTask = _.find(this.uploadStore.tasks, {id: task.id})
        if (targetTask){
            targetTask.taskId = task.taskId;
            targetTask.progress = task.progress;
            targetTask.status = task.status;
            targetTask.files = task.files;
            targetTask.inited = true;
            (targetTask as any).taskStatus = task.taskStatus;
        }
    }

    @action
    public updateTaskProgress(task: uploadTypes.ITask){
        let targetTask = _.find(this.uploadStore.tasks, {taskId: task.taskId});
        if (targetTask && task.files && targetTask.files)
        {
            if (task.progress){
                targetTask.progress = parseFloat(task.progress as any);
            }
            targetTask.status = task.status;

            task.files.forEach((file, index) => {
                if (targetTask && targetTask.files)
                {
                    targetTask.files[index].progress = parseFloat(file.progress as any);
                    targetTask.files[index].status = file.status;
                }
            });
        }
    }

    @action
    public updateTaskSurplusTime(task: uploadTypes.ITask){
        let targetTask = _.find(this.uploadStore.tasks, {taskId: task.taskId});
        if (targetTask && task.files && targetTask.files)
        {
            if (task.surplusTime){
                targetTask.surplusTime = task.surplusTime;
            }

            task.files.forEach((file, index) => {
                if (targetTask && targetTask.files)
                {
                    targetTask.files[index].surplusTime = file.surplusTime;
                }
            });
        }
    }
    
    @action
    public removeTask(task: uploadTypes.ITask){
        let idx = _.findIndex(this.uploadStore.tasks, {taskId: task.taskId});
        this.uploadStore.tasks.splice(idx, 1);
    }

    @action
    public removeTaskById(task: uploadTypes.ITask){
        let idx = _.findIndex(this.uploadStore.tasks, {id: task.id});
        this.uploadStore.tasks.splice(idx, 1);
    }

    private calcProgress = (current: number, total: number): number => {
        if (current !== 0 && current === total)
        {
            return 100;
        }
        let result: number = current && total ? ((current / total) * 100) : 0;
        if (result.toString().indexOf('.') === -1)
        {
            return result;
        }
        return parseFloat(result.toFixed(2));
    }

    private calcFileProgress(file: uploadTypes.IFile) {
        return this.calcProgress(file.chunkIndex || 0, file.chunkTotal || 0);
    }

    private calcTaskProgress(task: uploadTypes.ITask) {
        let count: number = 0;
        for (let i = 0; i < (task.files || []).length; i++)
        {
            if (task.files)
            {
                count += task.files[i].chunkIndex || 0;
            }
        }
        task.chunkFinished = count;
        return this.calcProgress(task.chunkFinished, task.chunkTotal || 0);
    }
}
