namespace iSearchTypes{
    export enum DataViewModes {
        LARGE = 1,   // 图例模式
        CARD = 2,    // 明信片模式
        LIST = 3,   // 列表模式
        PREVIEW = 4  // 预览模式
    }

    export interface IAdvancedSearchTypes {
        test: string
    }

    export interface ISelectedFacets {
        showValue: string,
        parentName: string,
        value: string[]
    }

    export interface IFacetsFacetValue {
        count?: number
        showValue: string
        value: string | string[]
        isShow?: boolean
        isSelected?: boolean
    }

    export interface IFacets {
        facetFieldName: string
        facetLayer?: number
        facetShowName: string
        facetValue?: IFacetsFacetValue[]
        checkboxIsOpen?: boolean
        startDate?: string
        endDate?: string
        showMore?: boolean
        searchKeyword?: string
    }

    export interface ISearchParams {
        page: number
        keyword: string[]
        facetConditions?: iSearchTypes.ISearchCondition[]
        conditions?: iSearchTypes.ISearchCondition[]
        searchDateRangeStart?: string
        searchDateRangeEnd?: string
    }

    export interface ISearchCondition {
        key?: string
        value?: string[]
        items?: ISearchReqCondition[]
        nexus?: string
        name?: string
        field?: string
        fieldType?: string
        array?: boolean
        type?: string
    }

    export interface ISearchReqCondition {
        field: string
        nexus?: string
        value: string | string[]
    }

    export interface IFullSearchDataSort{
        field: string
        desc: boolean
    }

    export interface IFullSearchDataReq{
        pageIndex: number
        pageSize: number
        personId?: string
        keyword: string[]
        facetConditions: ISearchReqCondition[]
        conditions: ISearchReqCondition[]
        sorts: IFullSearchDataSort[]
        extension?: {
            highlightFields?: string[],
            returnFields: string[],
            facetFields?: any
        }
        sourceType?: string
        templateId?: string
        keywordSearchType?: string
    }

    export interface IFullSearchDataRes{
        pageIndex: number
        pageTotal: number
        pageSize: number
        recordTotal: number
        data: any[]
        aggrs: iSearchTypes.IFacets[]
    }

    export interface ISortItem {
        text: string
        field: string
        desc: string
        hideDirection?: boolean
    }

    export interface ISearchRecord{
        addTime: string
        id: string
        keyword: string
        userCode: string
    }

    export interface IVectorSimilarWords{
        sim: number
        keyword: string
    }

    export interface IRelativeEvent{
        browse: number
        category: string[]
        count: number
        createTime: string
        id: string
        identifyCode: string
        isControlled: string
        sortWeight: number
        source: string
        tagName: string
        tagType: string
    }

    export interface IRelativeEventRes{
        pageIndex: number
        pageSize: number
        pageTotal: number
        recordTotal: number
        data: IRelativeEvent[]
    }

    export interface ISearchTemplateItem{
        id: string
        templateName: string
    }

    export enum FormatFlag {
        SD = 1,
        HD = 2,
        FHD = 3,
        SHD = 4,
        None = 0
    }
}
export default iSearchTypes