import {http} from "../utils/http";
import {AxiosPromise} from "axios";
import {IResponse} from "mam-core-react/dist/modules/http";
import datasetDetailsTypes from "../types/datasetDetailsTypes";

namespace datasetDetailsApi {
    export const getInfo = (params:string): AxiosPromise<IResponse<datasetDetailsTypes.IInfoRes>> => {
        return http.get(`/entity/initialize-data?${params}`)
    }
    export const getTree = (params:string): AxiosPromise<IResponse<datasetDetailsTypes.ITreeRes[]>> => {
        return http.get(`/dataset/get-all-entitys-relation?${params}`)
    }
    export const queryEntitys = (params:datasetDetailsTypes.IQueryEntitysParams): AxiosPromise<IResponse<datasetDetailsTypes.IQueryEntitysRes>> => {
        return http.post(`/dataset/query-entitys`,params)
    }
    export const add = (params:datasetDetailsTypes.IAddParams): AxiosPromise<IResponse<datasetDetailsTypes.IAddRes>> => {
        return http.post(`/dataset/create-folder`,params)
    }
    export const queryUpload = (params:datasetDetailsTypes.IQueryUploadParams): AxiosPromise<IResponse<datasetDetailsTypes.IQueryUploadRes>> => {
        return http.post(`/upload/get-all-fields-by-source`,params)
    }
    export const queryDel = (params:datasetDetailsTypes.IQueryDel): AxiosPromise<IResponse<any[]>> => {
        return http.post(`/dataset/check-can-delete-folder`,params)
    }
    export const del = (params:datasetDetailsTypes.IQueryDel): AxiosPromise<IResponse<boolean>> => {
        return http.post(`/dataset/delete-subs`,params)
    }
    export const queryChooseBlue = (params:datasetDetailsTypes.IQueryChooseBlueParams): AxiosPromise<IResponse<datasetDetailsTypes.IQueryChooseBlueRes>> => {
        return http.post(`/search_pickedup/query`,params)
    }
    export const moveMaterial = (params:datasetDetailsTypes.IMoveMaterialParams): AxiosPromise<IResponse<boolean>> => {
        return http.post(`/dataset/move`,params)
    }
    export const moveDel = (params:datasetDetailsTypes.IMoveDelParams): AxiosPromise<IResponse<boolean>> => {
        return http.post(`/dataset/remove-entity`,params)
    }
    export const editFolderName = (params:datasetDetailsTypes.IEditFolderNameParams): AxiosPromise<IResponse<boolean>> => {
        return http.post(`/dataset/update-folder`,params)
    }
    export const uploadImg = (params:datasetDetailsTypes.IUploadImgParams): AxiosPromise<IResponse<boolean>> => {
        return http.post(`/dataset/upload-keyframe`,params)
    }
    export const updateEntityData = (params:datasetDetailsTypes.IUpdateEntityDataParams): AxiosPromise<IResponse<any>> => {
        return http.post(`/entity/update-entity-data`,params)
    }
    export const collection = (params:datasetDetailsTypes.ICollectionParams): AxiosPromise<IResponse<datasetDetailsTypes.ICollectionRes>> => {
        return http.post(`/favorite/favorite`,params)
    }
}

export default datasetDetailsApi
