import ExtendStore from "../../base/extendStore";
import _ from 'lodash';
import EntityStore from '../entityStore';
import IEntityStore from 'src/stores/iEntity/iEntityStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import entityTypes from 'src/types/entityTypes';
import entityApi from 'src/apis/entityApi';
import { message } from 'antd';

export default class CommentStore extends ExtendStore{
    @observable
    public scoreRecord: entityTypes.IScoreRecordRes;
    public entityStore: EntityStore | IEntityStore;
    private pageSize = 20;

    public constructor(stores: Stores, entityStore: EntityStore | IEntityStore) {
        super(stores)
        this.entityStore = entityStore;
    }

    @action
    public queryScoreRecord(page: number){
        entityApi.getScoreRecord({
            contentId: this.entityStore.params.contentId,
            isPage: true,
            pageIndex: page || 1,
            pageSize: this.pageSize
        }).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.scoreRecord = res.data.data;
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        });
    }
}
