import { BaseStore, http } from 'mam-core-react';
import { Stores } from 'src/stores';
import { observable, action, runInAction } from 'mobx';
import IEntityStore from '../iEntityStore';
import { message } from 'antd';
import _ from 'lodash';

interface IPoint{
    inpoint: number
    outpoint: number
}

interface ISensitivePart{
    level: string
    points: IPoint[]
}

export default class SensitiveParts4PlayerStore extends BaseStore<Stores> {
    /** 敏感片段列表，用于控制播放器标识敏感信息 */
    @observable
    public isReqSensitiveParts: boolean = true;
    public sensitiveParts: ISensitivePart[] = [];
    public parentStore: IEntityStore;

    private watchPlayerSensitivePartLock: boolean = false;

    public constructor(stores: Stores, parentStore: IEntityStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public getSensitiveParts(){
        this.isReqSensitiveParts = true;
        http.get(`/aidata/sensitive?contentId=${this.parentStore.params.contentId}`).then(res=>{
            if (res.data.data){
                this.sensitiveParts = res.data.data;
            }
        }).catch(res=>{
            if (res.data && res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            else {
                console.error(res);
            }
        }).finally(()=>{
            runInAction(()=>{
                this.isReqSensitiveParts = false;
            })
        })
    }

    /** 监听播放器敏感片段，然后屏蔽 */
    public watchPlayerSensitivePart(viewer: any){
        if (this.sensitiveParts.length === 0){
            return;
        }
        const playerSensitiveMask = $('.layer-overlay-html-inject').children('.container').children('.player-sensitive-mask');
        const playerSensitiveShow = $('.layer-overlay-html-inject').children('.container').children('.player-sensitive-show');
        const highLevelParts = _.find(this.sensitiveParts, {level: 'High'});
        const lowLevelParts = _.find(this.sensitiveParts, {level: 'Low'});
        viewer.player.media.addEventListener('accurateTimeUpdate', (res: any) => {
            if (this.watchPlayerSensitivePartLock){
                return;
            }
            this.watchPlayerSensitivePartLock = true;
            setTimeout(()=>{
                const currentTime = parseInt(viewer.player.getCurrentTime().toFixed(7).replace('.', ''), 10);
                if (highLevelParts){ // 限制为高的片段
                    let isFind = false;
                    highLevelParts.points.forEach(part=>{
                        if (part.inpoint <= currentTime 
                            && part.outpoint >= currentTime){
                            isFind = true;
                        }
                    })
                    if (isFind){
                        playerSensitiveMask.css('display', 'flex');
                    }
                    else {
                        playerSensitiveMask.css('display', 'none');
                    }
                }
                if (lowLevelParts){ // 限制为低的片段
                    let isFind = false;
                    lowLevelParts.points.forEach(part=>{
                        if (part.inpoint <= currentTime 
                            && part.outpoint >= currentTime){
                            isFind = true;
                        }
                    })
                    if (isFind){
                        playerSensitiveShow.css('display', 'flex');
                    }
                    else {
                        playerSensitiveShow.css('display', 'none');
                    }
                }
                this.watchPlayerSensitivePartLock = false;
            }, 500)
        })
    }
}