import { http } from "../utils/http";
import { AxiosPromise } from "axios";
import { IResponse } from 'mam-core-react/dist/modules/http';
import iEntityTypes from 'src/types/iEntityTypes';
import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import { commonUtil } from 'mam-common-utils';

namespace iEntityApi {
    export const getContentStructLayer = (contentId: string, type: string, id: string): AxiosPromise<IResponse<iEntityTypes.IContentStructLayer[]>> => {
        return http.get<IResponse<iEntityTypes.IContentStructLayer[]>>(`/aidata/content-struct/layer?contentId=${contentId}&type=${type}&id=${id}`);
    }

    export const getStoryAidata = (contentId: string): AxiosPromise<IResponse<iEntityTypes.IAidataRes>> => {
        return http.get<IResponse<iEntityTypes.IAidataRes>>(`/aidata/story?contentId=${contentId}`);
    }

    export const getStorySingle = (storyid: string): AxiosPromise<IResponse<iEntityTypes.IMetaOriginal>> => {
        return http.get<IResponse<iEntityTypes.IMetaOriginal>>(`/aidata/story/${storyid}`);
    }

    export const getStoryMetadata = (contentId: string, storyid: string): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.get<IResponse<IFormItem[]>>(`/aidata/story/metadata/${contentId}/${storyid}`);
    }

    export const updateStoryMetadata = (contentId: string, storyid: string, metadata: IFormItem[]): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.patch<IResponse<IFormItem[]>>(`/aidata/story/metadata/${contentId}/${storyid}`, metadata);
    }

    export const updateSceneMetadata = (contentId: string, sceneid: string, metadata: IFormItem[]): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.patch<IResponse<IFormItem[]>>(`/aidata/scene/metadata/${contentId}/${sceneid}`, metadata);
    }

    export const updateShotMetadata = (contentId: string, shotid: string, metadata: IFormItem[]): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.patch<IResponse<IFormItem[]>>(`/aidata/shot/metadata/${contentId}/${shotid}`, metadata);
    }

    export const getSceneAidata = (contentId: string, parent?: iEntityTypes.IMetaOriginal): AxiosPromise<IResponse<iEntityTypes.IAidataRes>> => {
        return http.get<IResponse<iEntityTypes.IAidataRes>>(`/aidata/scene?contentId=${contentId}${parent ? '&inpoint=' + parent.inpoint + '&outpoint=' + parent.outpoint : ''}`);
    }

    export const getSceneSingle = (sceneid: string): AxiosPromise<IResponse<iEntityTypes.IMetaOriginal>> => {
        return http.get<IResponse<iEntityTypes.IMetaOriginal>>(`/aidata/scene/${sceneid}`);
    }

    export const getSceneMetadata = (contentId: string, sceneid: string): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.get<IResponse<IFormItem[]>>(`/aidata/scene/metadata/${contentId}/${sceneid}`);
    }

    export const getShotAidata = (contentId: string, parent?: iEntityTypes.IMetaOriginal): AxiosPromise<IResponse<iEntityTypes.IAidataRes>> => {
        return http.get<IResponse<iEntityTypes.IAidataRes>>(`/aidata/shot?contentId=${contentId}${parent ? '&inpoint=' + parent.inpoint + '&outpoint=' + parent.outpoint : ''}`);
    }

    export const getShotSingle = (shotid: string): AxiosPromise<IResponse<iEntityTypes.IMetaOriginal>> => {
        return http.get<IResponse<iEntityTypes.IMetaOriginal>>(`/aidata/shot/${shotid}`);
    }

    export const getShotMetadata = (contentId: string, shotid: string): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.get<IResponse<IFormItem[]>>(`/aidata/shot/metadata/${contentId}/${shotid}`);
    }

    export const getContentStructExtend = (contentId: string, type: 'entity' | 'story', inpoint?: number, outpoint?: number): AxiosPromise<IResponse<iEntityTypes.IContentStructExtendRes>> => {
        return http.get<IResponse<iEntityTypes.IContentStructExtendRes>>(`/aidata/content-struct/extend?contentId=${contentId}&type=${type}` + (inpoint !== undefined && outpoint !== undefined ? `&inpoint=${inpoint}&outpoint=${outpoint}` : ``));
    }

    export const getFaceAidata = (contentId: string, inpoint?: number, outpoint?: number, fileGuid?: string): AxiosPromise<IResponse<iEntityTypes.IFaceAidataRes>> => {
        if (inpoint !== undefined && outpoint !== undefined){
            return http.get<IResponse<iEntityTypes.IFaceAidataRes>>(`/aidata/face?contentId=${contentId}&inpoint=${inpoint}&outpoint=${outpoint}` + (fileGuid ? `&fileGuid=${fileGuid}` : ''));
        }
        return http.get<IResponse<iEntityTypes.IFaceAidataRes>>(`/aidata/face?contentId=${contentId}` + (fileGuid ? `&fileGuid=${fileGuid}` : ''));
    }

    export const getFaceSequence = (contentId: string, personId: string, clusterId: string): AxiosPromise<IResponse<iEntityTypes.IFaceSequenceRes>> => {
        return http.get<IResponse<iEntityTypes.IFaceSequenceRes>>(`/aidata/face/sequence?contentId=${contentId}&personId=${personId || ''}&clusterId=${clusterId || ''}`);
    }

    export const getSpeechAidata = (contentId: string, page: number, pageSize: number, inpoint?: number, outpoint?: number, keyword?: string): AxiosPromise<IResponse<iEntityTypes.ISpeechAidataRes>> => {
        let url = `/aidata/speech?contentId=${contentId}&pageIndex=${page}&pageSize=${pageSize}`;
        if (inpoint !== undefined && outpoint !== undefined){
            url = commonUtil.addUrlParam(url, `inpoint=${inpoint}&outpoint=${outpoint}`)
        }
        if (keyword !== undefined){
            url = commonUtil.addUrlParam(url, `keyword=${keyword}`)
        }
        return http.get<IResponse<iEntityTypes.ISpeechAidataRes>>(url);
    }

    export const getSubtitleAidata = (contentId: string, page: number, pageSize: number, inpoint?: number, outpoint?: number, keyword?: string, fileGuid?: string): AxiosPromise<IResponse<iEntityTypes.ISpeechAidataRes>> => {
        let url = `/aidata/subtitle?contentId=${contentId}&pageIndex=${page}&pageSize=${pageSize}`;
        if (inpoint !== undefined && outpoint !== undefined){
            url = commonUtil.addUrlParam(url, `inpoint=${inpoint}&outpoint=${outpoint}`)
        }
        if (keyword !== undefined){
            url = commonUtil.addUrlParam(url, `keyword=${keyword}`)
        }
        if (fileGuid){
            url = commonUtil.addUrlParam(url, `fileGuid=${fileGuid}`)
        }
        return http.get<IResponse<iEntityTypes.ISpeechAidataRes>>(url);
    }

    export const updateSubtitleAidata = (contentId: string, item: iEntityTypes.ISpeechAidata): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/aidata/subtitle/text/${contentId}`, [item]);
    }

    export const updateSpeechAidata = (contentId: string, item: iEntityTypes.ISpeechAidata): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/aidata/speech/text/${contentId}`, [item]);
    }

    export const getContentStructCount = (contentId: string, type: string, id?: string): AxiosPromise<IResponse<iEntityTypes.IContentStructCountRes>> => {
        return http.get<IResponse<iEntityTypes.IContentStructCountRes>>(`/aidata/content-struct/count?contentId=${contentId}&type=${type}` + (id ? `&id=${id}` : ''));
    }

    /** 查询标签时间线 */
    export const getNamedEntity = (contentId: string, name: string): AxiosPromise<IResponse<iEntityTypes.INamedEntityRes[]>> => {
        return http.get<IResponse<iEntityTypes.INamedEntityRes[]>>(`/aidata/named-entity?contentId=${contentId}&keyword=${name}`);
    }

    /** 查询关键帧 */
    export const getKeyframes = (contentId: string): AxiosPromise<IResponse<iEntityTypes.IKeyframesRes>> => {
        return http.get<IResponse<iEntityTypes.IKeyframesRes>>(`/aidata/markkeyframe?contentId=${contentId}`);
    }

    export const deleteKeyframes = (contentId: string, ids: string[]): AxiosPromise<IResponse<any>> => {
        let paramIds = '';
        ids.forEach((id)=>{
            paramIds += 'ids=' + id + '&';
        })
        paramIds = paramIds.substring(0, paramIds.length - 1);
        return http.delete(`/aidata/markkeyframe/${contentId}?` + paramIds);
    }

    export const updateKeyframes = (contentId: string, keyframe: iEntityTypes.IKeyframe): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/aidata/markkeyframe/${contentId}`, keyframe);
    }

    export const addKeyframes = (contentId: string, keyframe: iEntityTypes.IKeyframe): AxiosPromise<IResponse<iEntityTypes.IKeyframe>> => {
        return http.post<IResponse<iEntityTypes.IKeyframe>>(`/aidata/markkeyframe/${contentId}`, keyframe);
    }

    export const getPicAidata = (contentId: string, fileGuid: string): AxiosPromise<IResponse<iEntityTypes.IPicAidataRes>> => {
        return http.get<IResponse<iEntityTypes.IPicAidataRes>>(`/aidata/pic-shot/${contentId}/${fileGuid}`);
    }

    /** 修改未知人脸 */
    export const modifyUnknowFace = (req: iEntityTypes.IModifyUnknowFace): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/aidata/face/person-info', req);
    }

    //上传封面
    export const addCoverPage = (req: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/add-frontcover', req)
    }

    //设为封面
    export const settingFace = (req: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/update-frontcover', req)
    }

    //删除封面图片
    export const deleteImage = (req: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/delete-frontcover', req)
    }

    //刷新封面
    export const refreshFun = (req : iEntityTypes.IRefreshReq): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/entity/get-frontcover?contentId=${req.contentId}&queryAdress=${req.site}&entityType=${req.fileType}&frameRate=${req.frameRate}`)
    }

    export const getMetadataObject = (contentId: string, type : string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/entity/get-metadata?contentId=${contentId}&type=${type}`)
    }

    /** 获取有时间线的标签名 */
    export const getNamedEntityTags = (contentId: string): AxiosPromise<IResponse<string[]>> => {
        return http.get<IResponse<string[]>>(`/aidata/named-entity/tags?contentId=${contentId}`);
    }
}
export default iEntityApi;
