import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import batchEditTypes from 'src/types/batchEditTypes'
import batchEditApi from 'src/apis/batchEditApi'
import configtApi from 'src/apis/configApi'
import { message } from 'antd'
import _ from 'lodash'
import { Stores } from 'src/stores'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'
export default class BatchEditStore extends ExtendStore {
    @observable
    public batchEntityTypes: batchEditTypes.IEntityTypes[] = [];
    @observable
    public batchData: batchEditTypes.IEntityBatchTypes[] = [];
    @observable
    public batchCurrentType: string;
    @observable
    public metadatas: IFormItem[] = [];
    @observable
    public isMetaEdit: boolean
    @observable
    public batchEditSelectedRowKeys: string[] = []
    @observable
    public allChecked: boolean //控制全选
    @observable
    public activeId: number = 0//控制激活的类型

    public constructor(stores: Stores) {
        super(stores);
    }

    @action
    public setAllChecked(value: boolean) {
        this.allChecked = value;
    }

    @action
    public setActiveId(value: number) {
        this.activeId = value;
    }

    //获取顶部类型数据
    @action
    public getEntityTypes() {
        configtApi.getEntityConfig().then((res) => {
            runInAction(() => {
                this.batchEntityTypes = res.data.data.config.entityTypes;
            })
        }).catch(res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    //获取左侧列表数据
    @action
    public getBatchData() {
        batchEditApi.getEntityBatch().then((res) => {
            runInAction(() => {
                this.batchData = res.data.data
            })
        }).catch(res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    //设置当前类型
    @action
    public setBatchCurrentType(currentType: string) {
        this.batchCurrentType = currentType
    }

    // 获取元数据
    @action
    public getBasicDatas() {
        this.metadatas = [];
        if (this.batchCurrentType !== undefined) {
            batchEditApi.getEntityData(this.batchCurrentType).then((res) => {
                runInAction(() => {
                    this.metadatas = res.data.data
                })
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        }
    }

    //metadataForm编辑模式
    @action
    public setMetaEdit(isEdit: boolean) {
        this.isMetaEdit = isEdit;
    }

    //实现选择
    @action
    public setBatchEditSelectedRowKeys(value: string[]) {
        this.batchEditSelectedRowKeys = value;
    }

    //获取单个
    @action
    public getEntityBasicData(contentId: string) {
        if (this.batchCurrentType !== undefined) {
            batchEditApi.getEntityBaseData(this.batchCurrentType, contentId).then((res) => {
                runInAction(() => {
                    this.metadatas = res.data.data
                })
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        }
    }

}