import {AxiosPromise} from "axios";
import {http} from "../utils/http";
import indexTypes from "../types/indexTypes";
import {IResponse} from "mam-core-react/dist/modules/http";

namespace indexApi {
    // 主页
    //检索-大家都在搜
    export const getHotSearchWord = (top: number): AxiosPromise<IResponse<indexTypes.IHotSearchWordResItem[]>> => {
        return http.get<IResponse<indexTypes.IHotSearchWordResItem[]>>('/search/hot/keyword?top='+top)
    }
    //热门关键词
    export const getHotWord = (top: number,day: number): AxiosPromise<IResponse<indexTypes.IHotWordResItem[]>> => {
        return http.post<IResponse<indexTypes.IHotWordResItem[]>>('/search/hot/keywords',{top,day})
    }
    //热门检索：包含最新入库节目、热门浏览、热门收藏、热门使用
    export const getHotRank = (top: number): AxiosPromise<IResponse<indexTypes.IHotRankRes>> => {
        return http.post<IResponse<indexTypes.IHotRankRes>>('/search/hot/rank',{top})
    }
    //热门事件
    export const getHotEvent = (): AxiosPromise<IResponse<indexTypes.IHotEventResItem[]>> => {
        return http.get<IResponse<indexTypes.IHotEventResItem[]>>('/search/hot/event')
    }
    //历史上的今天
    export const getHotHistory = (top: number): AxiosPromise<IResponse<indexTypes.IHotHistoryResItem[]>> => {
        return http.post<IResponse<indexTypes.IHotHistoryResItem[]>>('/search/hot/history',{top})
    }
    //热门机构
    export const getHotOrg = (top: number): AxiosPromise<IResponse<indexTypes.IHotOrgResItem[]>> => {
        return http.post<IResponse<indexTypes.IHotOrgResItem[]>>('/search/hot/org',{top})
    }
    //热门人物
    export const getHotPeople = (top: number,day:number): AxiosPromise<IResponse<indexTypes.IHotPeopleResItem[]>> => {
        return http.post<IResponse<indexTypes.IHotPeopleResItem[]>>('/search/hot/people',{top,day})
    }
    //热门新闻人物story
    export const getHotNews = (day: number): AxiosPromise<IResponse<indexTypes.IHotNewsResStoryItem[][]>> => {
        return http.post<IResponse<indexTypes.IHotNewsResStoryItem[][]>>('/search/hot/people/story',{day})
    }
    //热门栏目
    export const getHotColumn = (top:number,day: number): AxiosPromise<IResponse<indexTypes.IHotColumnResItem[]>> => {
        return http.post<IResponse<indexTypes.IHotColumnResItem[]>>('/search/hot/column',{top,day})
    }
    //热门场景
    export const getHotScene = (top: number,day: number): AxiosPromise<IResponse<indexTypes.IHotSceneResItem[]>> => {
        return http.post<IResponse<indexTypes.IHotSceneResItem[]>>('/search/hot/background',{top,day})
    }
    //猜你喜欢
    export const getHotLike = (top: number): AxiosPromise<IResponse<indexTypes.IHotLikeResItem[]>> => {
        return http.post<IResponse<indexTypes.IHotLikeResItem[]>>('/search/hot/like',{top})
    }

}
export default indexApi
