import * as React from "react";
import EntityExtendStore from "./entityExtendStore";
import { Stores } from 'src/stores';
import { observable, computed, action, runInAction } from 'mobx';
import { message } from 'antd';
import UseAuditStore from '../useAuditStore'
import iEntityApi from 'src/apis/iEntityApi';
import entityApi from "src/apis/entityApi";
import entityTypes from "src/types/entityTypes";
import _ from 'lodash';
import BaseInfo from "src/pages/contentMg/subModules/useAudit/viewEntity/modules/rightTabContent/baseInfo/baseInfo";
import permissionUtil from "mam-common-utils/dist/modules/permissionUtil";
import playerUtil from "mam-common-utils/dist/modules/playerUtil";
import timeUtil from "mam-common-utils/dist/modules/timeUtil";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
//import { SmartMetadataType } from './extends/smartViewStore';

export default class EntityStore extends EntityExtendStore {
    public parentStore: UseAuditStore;
    @observable
    public params: entityTypes.IEntityParam
    @observable
    public viewer: any;
    @observable
    public entity: entityTypes.IEntity;
    @observable
    public nameField?: IFormItem
    /** 查询素材详情报错信息 */
    @observable
    public loadError?: string
    /** 选中的tab code */
    @observable
    public selectedTab: entityTypes.ISelectedTab = { top: 'reviewInfo', bottom: 'keyframe' }
    /** 临时添加的tab信息，比如新增标记点tab等，新增完后tab会消失 */
    @observable
    public selectedTabObj: entityTypes.ISelectedTabObj = { code: '', name: '' }
    /** 元数据是否为编辑状态 */
    @observable
    public metaEditable: boolean = false;
    /** 智能相关元数据信息 */
    @observable
    public metadatas: entityTypes.contentIndex.IMetadata[];
    /** 视频帧率 */
    public frameRate: number = 0
    public baseInfo: React.RefObject<BaseInfo>;
    public constructor(stores: Stores, parentStore: UseAuditStore) {
        super(stores)
        this.parentStore = parentStore;
        this.initReviewInfoStore()
        this.baseInfo = React.createRef();
    }

    @action
    public setParams(params: entityTypes.IEntityParam) {
        this.params = params;
    }

    @action
    public setViewer(viewer: any) {
        this.viewer = viewer;
    }

    @action
    public getInitializeData(contentId: string): Promise<entityTypes.IEntity> {
        return new Promise((resolve, reject) => {
            //let testContentId = "b2fc670a1ff64f4e81d73ca6e24b207e"
            entityApi.getInitializeData(contentId).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.entity = res.data.data;
                        this.entity.contentId = contentId;
                        this.nameField = _.find(this.entity.entitydata, {
                            fieldName: 'name_'
                        });
                        this.entity.entityName = !this.nameField ? '' : this.nameField.value as string;
                        this.entity.planDeleteTime = _.get(_.find(this.entity.entitydata, { fieldName: 'planDelTime_' }), 'value', '') as string;
                        this.entity.planDeleteTime = timeUtil.comingDateTime(this.entity.planDeleteTime, 'hour', 72 * 3600, 'min');
                        this.frameRate = this.entity.paths && this.entity.paths.length > 0 ? this.entity.paths[0].frameRate : 0;
                        //如果paths里没有帧率，则到元数据里取
                        if (!this.frameRate){
                            const frameRateMeta = _.find(this.entity.entitydata, {fieldName: 'framerate'});
                            if (frameRateMeta && frameRateMeta.value){
                                this.frameRate = frameRateMeta.value as number;
                            }
                        }
                        //处理时长
                        this.entity.duration = this.entity.paths && this.entity.paths.length > 0 ? this.entity.paths[0].duration : 0;
                        if (this.entity.duration && this.frameRate){
                            this.entity.durationTc = playerUtil.l100Ns2Tc(this.entity.duration, this.entity.type, this.frameRate);
                        }
                        this.loadError = ""
                        resolve(this.entity);
                    }else {
                        reject();
                    }
                });
            }, (res) => {
                runInAction(() => {
                    if (res.data.error.code === 'B1066') {
                        this.loadError = '系统未定义该类型，无法浏览。';
                    } else {
                        this.loadError = res.data.error.title;
                    }
                    reject();
                });
            })
        });
    }
    // @action
    // public getOtcinfo(): Promise<any[]> {
    //     return new Promise((resolve, reject)=>{
    //         entityApi.getMetadataByType(this.params.contentId, SmartMetadataType.otcInfo).then(res=>{
    //             runInAction(()=>{
    //                 if (res.data.data){
    //                     this.metadatas = res.data.data || [];
    //                 }
    //                 resolve();
    //             })
    //         }, res=>{
    //             if (res.data.error && res.data.error.title){
    //                 message.error(res.data.error.title);
    //             }
    //         });
    //     })
    // }

    @action
    public setEntityData(entityData: IFormItem[]){
        this.entity.entitydata = entityData;
    }

    @action
    public changeTopTab(name: string) {
        this.selectedTab.top = name;
    }

    @action
    public changeBottomTab(name: string) {
        this.selectedTab.bottom = name;
    }

    @action
    public setMetaEditable(editable: boolean) {
        this.metaEditable = editable;
    }

    @action
    public updateEntityTagedata(tagedata: IFormItem[]) {
        this.entity.tagedata = tagedata;
    }

    @action
    public setSelectedTabObj(obj: entityTypes.ISelectedTabObj ){
        this.selectedTabObj = obj;
    }

    public setPlayerPosition = (item: entityTypes.ICut) => {
        this.viewer.player.setCurrentTime(playerUtil.frame2Second(item.in, this.frameRate));
        this.viewer.player.setTrimin(playerUtil.frame2Second(item.in, this.frameRate));
        this.viewer.player.setTrimout(playerUtil.frame2Second(item.out, this.frameRate));
    }

    /** 能否使用历史 */
    public canUseHistory() {
        if (!_.get(this.stores.configStore, 'config.entity.history.enable', false)) {
            return false;
        }
        if (permissionUtil.judge('Default_ActionLogView')) {
            return true;
        }
        if (this.stores.configStore.config.entity.history.allowImportUser) {
            return (_.get(this.entity, 'importUser.userCode')
                === (this.stores.userStore.currentUser && this.stores.userStore.currentUser.userCode));
        }
        return false;
    }

}
