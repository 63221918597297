import { AxiosPromise } from 'axios'
import { IResponse, http } from 'mam-core-react/dist/modules/http'
import iIndexTypes from 'src/types/iIndexTypes'
import { commonUtil } from 'mam-common-utils'
import iSearchTypes from 'src/types/iSearchTypes'

namespace iIndexApi{
    export const queryFacets = (name: string):AxiosPromise<IResponse<iSearchTypes.IFacetsFacetValue[]>> => {
        return http.get<IResponse<iSearchTypes.IFacetsFacetValue[]>>(`/es/search/getFacet?name=${name}`)
    }

    export const searchByEsOrigin = (params: iIndexTypes.thiDetail.ISearchByEsOriginReq):AxiosPromise<IResponse<iSearchTypes.IFullSearchDataRes>> => {
        return http.post<IResponse<iSearchTypes.IFullSearchDataRes>>('/es/search/origin', params)
    }

    export const getCategoryTagCount = (params: iIndexTypes.secList.ICategoryTagCountReq):AxiosPromise<IResponse<iIndexTypes.secList.ICategoryTagCountRes[]>> => {
        return http.post<IResponse<iIndexTypes.secList.ICategoryTagCountRes[]>>('/ficus-statistic/portal/category/tagcount', params)
    }

    export const getCategoryTag = (params: iIndexTypes.secList.ICategoryTagReq):AxiosPromise<IResponse<iIndexTypes.secList.ICategoryTagRes>> => {
        return http.post<IResponse<iIndexTypes.secList.ICategoryTagRes>>('/ficus-statistic/portal/category/tag', params)
    }

    export const getUnknowPersonTag = (pageIndex: number, pageSize: number):AxiosPromise<IResponse<iIndexTypes.secList.IUnknowPersonTagRes>> => {
        return http.get<IResponse<iIndexTypes.secList.IUnknowPersonTagRes>>(`/smcds/v1/report/tag/unknown-face?page_index=${pageIndex}&page_size=${pageSize}`)
    }

    export const getPersonDetails = (req: iIndexTypes.secList.IPersonDetailsReq[]):AxiosPromise<IResponse<iIndexTypes.secList.IPersonDetail[]>> => {
        return http.post<IResponse<iIndexTypes.secList.IPersonDetail[]>>('/bigdata-platform/es/szmg/face', req)
    }

    export const getOrgDetails = (req: iIndexTypes.secList.IPersonDetailsReq[]):AxiosPromise<IResponse<iIndexTypes.secList.IPersonDetail[]>> => {
        return http.post<IResponse<iIndexTypes.secList.IPersonDetail[]>>('/bigdata-platform/es/szmg/org', req)
    }

    /** 查询分类列表 */
    export const getTopicList = (startTime?: string, endTime?: string):AxiosPromise<IResponse<iIndexTypes.secList.ITopicItem[]>> => {
        let url = '/bigdata-platform/es/szmg/topic';
        if (startTime !== undefined && endTime !== undefined){
            url = commonUtil.addUrlParam(url, `startTime=${startTime}&endTime=${endTime}`);
        }
        return http.get<IResponse<iIndexTypes.secList.ITopicItem[]>>(url)
    }

    /** 查询栏目列表 */
    export const getColumnList = (params: iIndexTypes.secList.IColumnListReq):AxiosPromise<IResponse<iIndexTypes.secList.IColumnListRes>> => {
        return http.post<IResponse<iIndexTypes.secList.IColumnListRes>>('/column/bigdata-platform/data', params);
    }

    /** 查询栏目列表详细 */
    export const getColumnListDetail = (codes: string[]):AxiosPromise<IResponse<iIndexTypes.secList.IColumnListDetail[]>> => {
        return http.post<IResponse<iIndexTypes.secList.IColumnListDetail[]>>('/bigdata-platform/es/szmg/column', codes);
    }

    /** 查询地区列表 */
    export const getDistrictList = (district: string):AxiosPromise<IResponse<iIndexTypes.secList.IDistrictListItem[]>> => {
        return http.get<IResponse<iIndexTypes.secList.IDistrictListItem[]>>(`/bigdata-platform/es/szmg/district?district=${district}`);
    }

    /** 查询场景列表 */
    export const getSceneList = ():AxiosPromise<IResponse<iIndexTypes.secList.ISceneListItem[]>> => {
        return http.get<IResponse<iIndexTypes.secList.ISceneListItem[]>>(`/bigdata-platform/es/szmg/background`);
    }

    /** 查询三级详情 */
    export const getThiDetails = (type: string, keyword: string, id?: string):AxiosPromise<IResponse<iIndexTypes.thiDetail.IThiDetails>> => {
        return http.get<IResponse<iIndexTypes.thiDetail.IThiDetails>>(`/bigdata-platform/es/szmg/${type}/detail?keyword=${encodeURIComponent(keyword)}&id=${id || ''}`)
    }

    //实体关系
    export const getEntityRelation = (name: string, type: string, title?: string): AxiosPromise<IResponse<iIndexTypes.thiDetail.IGetEntityRelationRes>> => {
        return http.post<IResponse<iIndexTypes.thiDetail.IGetEntityRelationRes>>('/search/recommend/relation', {
            name,
            type,
            title
        })
    }
    // 物体事件查询多张关键帧
    export const getKeyframe = (type: string, name: string[], num: number):AxiosPromise<IResponse<any>> => {
        let url = `/bigdata-platform/es/szmg/${type}/tag/keyframes?istree=true&size=${num}&`
        name.forEach((item, index) => {
            let params = ''
            if(name.length - 1 === index) {
                params = `tags=${item}`
            } else {
                params = `tags=${item}&`
            }
            url += params
        })
        return http.get<IResponse<any>>(url)
    }

    // 物体事件查询多张关键帧
    export const getTagCount = (names: string[], type: string):AxiosPromise<IResponse<iIndexTypes.secList.IGetTagCountRes[]>> => {
        return http.post<IResponse<iIndexTypes.secList.IGetTagCountRes[]>>(`/bigdata-platform/es/szmg/keyword`, names)
    }

    export const queryNewImport = (currTab: string, pageIndex: number):AxiosPromise<IResponse<iSearchTypes.IFullSearchDataRes>> => {
        return http.get<IResponse<iSearchTypes.IFullSearchDataRes>>(`/bigdata-platform/es/szmg/protals/last-import?pageIndex=${pageIndex}&pageSize=20&entityType=${currTab}`)
    }

    export const searchAlbums = (params: iIndexTypes.secList.ISearchAlbumsReq): AxiosPromise<IResponse<iIndexTypes.secList.ISearchAlbumsRes>> => {
        return http.post<IResponse<iIndexTypes.secList.ISearchAlbumsRes>>(`/es/search/album`, params)
    }

    export const getFormatSummation = (clipType: string, formatType: string):AxiosPromise<IResponse<iIndexTypes.secList.IFormatSummation[]>> => {
        return http.get<IResponse<iIndexTypes.secList.IFormatSummation[]>>(`/statistic/szmg/format/summation?clipType=${clipType}&formatType=${formatType}`)
    }

    export const getFormatGrowingTrend = (clipType: string, formatType: string):AxiosPromise<IResponse<iIndexTypes.secList.IFormatGrowingTrend[]>> => {
        return http.get<IResponse<iIndexTypes.secList.IFormatGrowingTrend[]>>(`/statistic/szmg/format/growing-trend?clipType=${clipType}&formatType=${formatType}`)
    }

    export const getFormatColumnDistribution = (clipType: string, formatType: string):AxiosPromise<IResponse<iIndexTypes.secList.IFormatColumnDistribution[]>> => {
        return http.get<IResponse<iIndexTypes.secList.IFormatColumnDistribution[]>>(`/statistic/szmg/format/column-distribution?clipType=${clipType}&formatType=${formatType}`)
    }

    // 获取最热二级列表数据
    export const getTopHotList = (hotenum: string, top: number):AxiosPromise<IResponse<iIndexTypes.secList.ITopHotItem[]>> => {
        return http.get<IResponse<iIndexTypes.secList.ITopHotItem[]>>(`/statistic/szmg/hot/clip?top=${top}&hotenum=${hotenum}`)
    }

    export const getUserChannelAndColumn = ():AxiosPromise<IResponse<iIndexTypes.secList.IUserChannelAndColumn[]>> => {
        return http.get<IResponse<iIndexTypes.secList.IUserChannelAndColumn[]>>(`/smcds/v1/report/user/channel`)
    }

    export const getSecUserList = (channelCode?: string, columnCode?: string, sortField?: string, isDesc?: boolean, keyword?: string):AxiosPromise<IResponse<iIndexTypes.secList.ISecUserListItem[]>> => {
        return http.get<IResponse<iIndexTypes.secList.ISecUserListItem[]>>(`/smcds/v1/report/user/creator?channel_id=${channelCode || ''}&column_id=${columnCode || ''}&keyword=${keyword || ''}` + (sortField !== undefined ? `&sort_field=${sortField || ''}` : '') + (isDesc ? `&is_desc=${isDesc}` : ''))
    }

    export const getAlbumTopic = ():AxiosPromise<IResponse<iIndexTypes.secList.IAlbumTopicRes[]>> => {
        return http.get<IResponse<iIndexTypes.secList.IAlbumTopicRes[]>>(`/smcds/v1/report/album/topic`)
    }

    export const getAlbumItems = (pageIndex: number, topic: string, sortField: string, idDesc: boolean):AxiosPromise<IResponse<iIndexTypes.secList.IAlbumItemsRes>> => {
        return http.get<IResponse<iIndexTypes.secList.IAlbumItemsRes>>(`/smcds/v1/report/album/term?page_index=${pageIndex}&page_size=30&topic=${topic}&sort_field=${sortField}&is_desc=${idDesc}`)
    }

    export const getTimeListAndColumns = (dateType: string, column?: string):AxiosPromise<IResponse<iIndexTypes.secList.IGetTimeListAndColumnsRes>> => {
        return http.get<IResponse<iIndexTypes.secList.IGetTimeListAndColumnsRes>>(`/bigdata-platform/es/szmg/import-date?dateType=${dateType}&column=${column || ''}`)
    }
}

export default iIndexApi