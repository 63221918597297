import BaseStore from "./baseStore";
import UploadStore, { IUploadStore } from "../extends/uploadStore/uploadStore";
import DownloadStore from "../extends/downloadStore";
import FavoriteStore from "../extends/FavoriteStore";
import SelectAllStore, { ISelectAllStore } from "../extends/selectAllStore";
import { Stores } from "../index";
import UploadPanelStore, { IUploadPanelStore } from "../extends/uploadPanelStore";
import DeleteStore from './../extends/deleteStore';
import CreatePlanningStore from './../extends/createPlanningStore';
import OutputExcelStore from './../extends/outputExcelStore';
import MoveStore from './../extends/moveStore';
import UnlockStore from './../extends/unlocStore';
import BatchSmartProcessStore from '../extends/batchSmartProcessStore';
import RenameStore from '../extends/renameStore';
import { IDataViewModeStore } from "../extends/dataViewModeStore";
import DataViewModeStore from "../extends/dataViewModeStore";
import ShareStore from '../extends/shareStore';
import CopyStore from './../extends/copyStore';
import ResotoreStore from './../extends/resotoreStore';
import CreateFolderStore from './../extends/createFolderStore';
import { IHypermediaPreviewBoxStore } from "../extends/hypermediaPreviewBoxStore";
import HypermediaPreviewBoxStore from "../extends/hypermediaPreviewBoxStore";
import { IOutstoreStore, default as OutstoreStore } from "../extends/outstoreStore/outstoreStore";
import AdvancedSearchStore from './../extends/advancedSearchStore';
import { ISearchFilterStore, default as SearchFilterStore } from "../extends/searchCommon/filter/searchFilterStore";
import AddEntityStore from './../extends/addEntity'
import ExportExcelFieledSettingStore from '../extends/exportExcelFieledSettingStore';
import BatchExecuteSmartProcessStore from "../extends/batchExecuteSmartProcessStore";
import CreateDatasetStore from '../extends/createDataset';
import BindBCProgramCodeStore from '../extends/bindBCProgramCodeStore';
import NewCreateAlbumStore from '../extends/newCreateAlbumStore';
import BatchInitiatingSmartProcess4AllStore from '../extends/batchInitiatingSmartProcess4AllStore';
import ISearchStore from '../iSearch/iSearchStore';
import RepeatAuditExtendStore from "../contentMg/subModules/repeatAudit/repeatAuditExtendStore";

export default abstract class ExtendStore extends BaseStore<Stores>
    implements ISelectAllStore, IUploadStore, IUploadPanelStore, IDataViewModeStore, IHypermediaPreviewBoxStore,
    IOutstoreStore, ISearchFilterStore {
    public selectAllStore: SelectAllStore;
    public uploadStore: UploadStore;
    public downloadStore: DownloadStore;
    public favoriteStore: FavoriteStore;
    public uploadPanelStore: UploadPanelStore;
    public deleteStore: DeleteStore;
    public createPlanningStore: CreatePlanningStore;
    public outputExcelStore: OutputExcelStore;
    public exportExcelFieledSettingStore: ExportExcelFieledSettingStore;
    public moveStore: MoveStore;
    public unlockStore: UnlockStore;
    public batchSmartProcessStore: BatchSmartProcessStore;
    public batchInitiatingSmartProcess4AllStore: BatchInitiatingSmartProcess4AllStore;
    public batchExecuteSmartProcessStore: BatchExecuteSmartProcessStore;
    public renameStore: RenameStore;
    public dataViewModeStore: DataViewModeStore;
    public shareStore: ShareStore
    public newCreateAlbumStore: NewCreateAlbumStore
    public copyStore: CopyStore
    public resotoreStore: ResotoreStore
    public createFolderStore: CreateFolderStore
    public hypermediaPreviewBoxStore: HypermediaPreviewBoxStore
    public outstoreStore: OutstoreStore
    public advancedSearchStore: AdvancedSearchStore
    public searchFilterStore: SearchFilterStore
    public addEntityStore: AddEntityStore
    public createDatasetStore: CreateDatasetStore
    public bindBCProgramCodeStore: BindBCProgramCodeStore
    // public repeatAuditExtendStore: RepeatAuditExtendStore

    public initSelectAllStore(stores: Stores, listSyntax: string, itemSelectKey: string, currentStore?: BaseStore<Stores>) {
        this.selectAllStore = new SelectAllStore(stores, listSyntax, itemSelectKey, currentStore);
    }
    public initUploadStore(stores: Stores) {
        this.uploadStore = new UploadStore(stores);
    }
    public initDownloadStore(stores: Stores) {
        this.downloadStore = new DownloadStore(stores);
    }
    public initFavoriteStore(stores: Stores) {
        this.favoriteStore = new FavoriteStore(stores);
    }
    public initUploadPanelStore(stores: Stores) {
        this.uploadPanelStore = new UploadPanelStore(stores);
    }
    public initDeleteStore(stores: Stores) {
        this.deleteStore = new DeleteStore(stores)
    }
    public initCreatePlanningStore(stores: Stores) {
        this.createPlanningStore = new CreatePlanningStore(stores)
    }
    public initOutputExcelStore(stores: Stores) {
        this.outputExcelStore = new OutputExcelStore(stores)
    }
    public initExportExcelFieledSettingStore(stores: Stores) {
        this.exportExcelFieledSettingStore = new ExportExcelFieledSettingStore(stores)
    }
    public initMoveStore(stores: Stores) {
        this.moveStore = new MoveStore(stores)
    }
    public initUnlockStore(stores: Stores) {
        this.unlockStore = new UnlockStore(stores)
    }
    public initBatchSmartProcess(stores: Stores) {
        this.batchSmartProcessStore = new BatchSmartProcessStore(stores)
    }
    public initBatchExecuteSmartProcessStore(stores: Stores) {
        this.batchExecuteSmartProcessStore = new BatchExecuteSmartProcessStore(stores)
    }
    public initBatchInitiatingSmartProcess4AllStore(stores: Stores, searchStore: ISearchStore){
        this.batchInitiatingSmartProcess4AllStore = new BatchInitiatingSmartProcess4AllStore(stores, searchStore)
    }
    public initRenameStore(stores: Stores) {
        this.renameStore = new RenameStore(stores)
    }
    public initDataViewModeStore(stores: Stores) {
        this.dataViewModeStore = new DataViewModeStore(stores);
    }
    public initShareStore(stores: Stores) {
        this.shareStore = new ShareStore(stores)
    }
    public initNewCreateAlbumStore(stores: Stores) {
        this.newCreateAlbumStore = new NewCreateAlbumStore(stores)
    }
    public initCopyStore(stores: Stores) {
        this.copyStore = new CopyStore(stores)
    }
    public initResotoreStore(stores: Stores) {
        this.resotoreStore = new ResotoreStore(stores)
    }
    public initCreateFolderStore(stores: Stores) {
        this.createFolderStore = new CreateFolderStore(stores)
    }
    public initHypermediaPreviewBoxStore(stores: Stores) {
        this.hypermediaPreviewBoxStore = new HypermediaPreviewBoxStore(stores);
    }
    public initOutstoreStore(stores: Stores) {
        this.outstoreStore = new OutstoreStore(stores);
    }
    public initAdvancedSearchStore(stores: Stores) {
        this.advancedSearchStore = new AdvancedSearchStore(stores)
    }
    public initSearchFilterStore(stores: Stores) {
        this.searchFilterStore = new SearchFilterStore(stores);
    }
    public initAddEntityStore(stores: Stores) {
        this.addEntityStore = new AddEntityStore(stores)
    }
    public initCreateDatasetStore(stores: Stores) {
        this.createDatasetStore = new CreateDatasetStore(stores)
    }
    public initBindBCProgramCodeStore(stores: Stores) {
        this.bindBCProgramCodeStore = new BindBCProgramCodeStore(stores)
    }
    // public initRepeatAuditExtendStore(stores: Stores) {
    //     this.repeatAuditExtendStore = new RepeatAuditExtendStore(stores);
    // }
}
