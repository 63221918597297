import ExtendStore from "../base/extendStore";
import { observable, action, runInAction } from 'mobx';
import recycleTypes from 'src/types/recycleTypes';
import { IBasePageResponse } from 'src/types/commonTypes';
import recycleApi from 'src/apis/recycleApi';
import { Stores } from '..';
import ccTypes from 'src/types/ccTypes';
import {l} from "mam-core-react";
import {IFilterData} from "src/custom/wuxi/components/mmsRecycleSearch/mmsRecycleSearch";
import wuxiTypes from 'src/custom/wuxi/types/wuxiTypes';
import wuxiApi from 'src/custom/wuxi/apis/wuxiApi';

export default class RecycleStore extends ExtendStore {
    @observable
    public params: recycleTypes.IRecycleParams
    @observable
    public fullSearchData?: IBasePageResponse<recycleTypes.IQueryRecycleResponse>
    public sortItems: ccTypes.searchTypes.ISortItem[] = [
        { text: l('recycle.sortDateDesc', '删除时间降序'), field: 'deleteDate', desc: 'desc' },
        { text: l('recycle.sortDateAsc', '删除时间升序'), field: 'deleteDate', desc: 'asc' }
    ]
    @observable
    public searchKeyword1: string = '';  // 主搜索框值
    /** 当前选中排序类型 */
    @observable
    public currentSort: ccTypes.searchTypes.ISortItem = this.sortItems[0];

    //无锡定制检索项
    @observable
    public filterData: IFilterData = {
        name_: '',
        wx_file_guid: '',
        deleteUser_: '',
        column: '',
    }

    //无锡定制还原/彻底删除（清空）做操作记录： 还原暂无弹窗
    @observable
    public wxDeleteCompletelyShowModal:boolean = false  //彻底删除
    @observable
    public wxClearShowModal:boolean = false  //清空
    //无锡定制项： 获取删除原因配置项； 删除原因、备注做记录
    @observable
    public wxReasonList:ccTypes.IMetadataFieldSetItem[] = []
    @observable
    public wxDeleteRecordBase:wuxiTypes.IDeleteRecordBase = {
        reason: '',
        notes: '',
        action: "CLEAR"   //DELETE / RESTORE / CLEAR
    }

    constructor(stores: Stores) {
        super(stores)
        this.initSelectAllStore(stores, 'stores.recycleStore.fullSearchData.data', 'selected')
        this.initResotoreStore(stores)
        this.initDataViewModeStore(stores)
        this.initAdvancedSearchStore(stores)
    }

    @action
    public setFilterData(filterData: IFilterData) {
        this.filterData = filterData;
    }
    @action
    public setSearchKeyword1(str: string) {
        this.searchKeyword1 = str;
    }

    @action
    public setParams(p: recycleTypes.IRecycleParams) {
        this.params = p;
    }

    @action
    public setCurrentSort(sort: ccTypes.searchTypes.ISortItem) {
        this.currentSort = sort;
    }

    @action
    public queryFullSearchData(page?: number) {
        this.fullSearchData = undefined;
        let param = this.params || {};
        let req: recycleTypes.IRecyclePageRequest = {
            pageIndex: page || param.page || 1,
            pageSize: 20,
            sort: param.sort ? param.sort : this.currentSort.field,
            order: param.order ? param.order : this.currentSort.desc,
            keyword: param.keyword,
            conditions: param.conditions
        };
        if (this.stores.configStore.config && this.stores.configStore.config.searchResultNum){
            req.pageSize = this.stores.configStore.config.searchResultNum;
        }
        if (this.params.advancedSearch && this.params.advancedSearch.field.length > 0 && this.params.advancedSearch.isOnlyAvSearching) {
            req.advancedSearch = this.params.advancedSearch
        }
        //无锡定制的检索
        if(this.stores.configStore.config && this.stores.configStore.config.wuXiCustomSwitch){
            req.conditions = Object.assign(req.conditions,this.filterData)
        }

        return new Promise((resolve, reject) => {
            recycleApi.fullSearch(req).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.fullSearchData = res.data.data;
                    }
                });
                resolve(true);
            }, () => {
                reject();
            });
        });
    }

    @action
    public checkRestore(req?: any) {
        return new Promise((resolve, reject) => {
            recycleApi.checkCanRestore(req).then(res => {
                resolve(true);
            }, () => {
                reject();
            });
        });
    }


    //无锡定制-彻底删除弹窗
    @action
    public setWxDeleteCompletelyShowModal(show: boolean){
        this.wxDeleteCompletelyShowModal = show
        if(!show){//恢复初始值
            this.wxDeleteRecordBase = {
                reason: '',
                notes: '',
                action: "CLEAR" 
            }
        }
    }
    //无锡定制-清空弹窗
    @action
    public setWxClearShowModal(show: boolean){
        this.wxClearShowModal = show
        if(!show){//恢复初始值
            this.wxDeleteRecordBase = {
                reason: '',
                notes: '',
                action: "CLEAR" 
            }
        }
    }
    //无锡定制-删除记录请求
    @action
    public setWxDeleteRecordBase(wxDeleteRecordBase:wuxiTypes.IDeleteRecordBase){
        this.wxDeleteRecordBase = wxDeleteRecordBase
    }
    //无锡定制-删除原因配置项数据
    @action
    public getWxReasonList(){
        return new Promise((resolve, reject) => {
            wuxiApi.getMetadataFieldSet('deletereason').then((res) => {
                runInAction(() => {
                    if (res.data && res.data.success && res.data.data) {
                        this.wxReasonList = res.data.data;
                        resolve(true)
                    }else{
                        reject()
                    }
                })
            })
        })
    }
    //无锡定制-删除操作做记录
    @action
    public wxDeleteRecord(params: wuxiTypes.IDeleteRecordReqItem[]){
        return new Promise((resolve, reject) => {
            wuxiApi.deleteRecord(params).then((res) => {
                runInAction(() => {
                    if (res.data && res.data.success) {
                        resolve(true)
                    }else{
                        reject()
                    }
                })
            })
        })
    }

    /** 监听滚动使功能按钮栏悬浮固定 */
    public checkScroll(){
        if(this.stores.configStore.config?.wuXiCustomSwitch){//无锡定制
            let height: number = 0
            if($('.page-header') && $('.page-header')[0]){
                height+= $('.page-header')[0].clientHeight
            }
            if($('.advanced-search') && $('.advanced-search')[0]){
                height+= $('.advanced-search')[0].clientHeight
            }
            if($('.filter') && $('.filter')[0]){
                height+= $('.filter')[0].clientHeight
            }
            $('#appContent').scroll(()=> {
                let num = $('#appContent').scrollTop();
                if (num as number > height) {
                    $('.operate-toolbar').addClass('operate-toolbar-fixed')
                } else {
                    $('.operate-toolbar').removeClass('operate-toolbar-fixed')
                }
            })
        }
    }
}
