import React from 'react';
import {action, observable, computed, runInAction} from "mobx";
import {Stores} from "../../index";
import ICatalogStore from '../iCatalogStore';
import iEntityTypes from 'src/types/iEntityTypes';
import { SmartMetadataType } from 'src/stores/iEntity/extends/smartViewStore';
import MetadataOpeStore from 'src/stores/iEntity/base/metadataOpeStore/metadataOpeStore';
import { message } from 'antd';
import _ from 'lodash'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import { commonUtil } from 'mam-common-utils';
import entityTypes from 'src/types/entityTypes';
import entityApi from 'src/apis/entityApi';
import iCatalogApi from 'src/apis/iCatalogApi';
import { BaseStore } from 'mam-core-react';
import iEntityApi from 'src/apis/iEntityApi';

export interface IAddPartParam{
    title?: string
    inpoint?: number
    outpoint?:number
}

export interface IModifyPartParam{
    title?: string
    keyword?: string
    inpoint?: number
    outpoint?:number
}

/** 内容结构 */
export default class ContentIndexStore extends BaseStore<Stores> {
    /** story和scene接口原始数据 */
    @observable
    public storyMetaList: iEntityTypes.IMetaOriginal[] = [];
    @observable
    public sceneMetaList: iEntityTypes.IMetaOriginal[] = [];
    @observable
    public shotMetaList: iEntityTypes.IMetaOriginal[] = [];
    /** story和scene转化为元数据结构类型 */
    @observable
    public metaInfoList: iEntityTypes.IMetaInfo[]
    /** 展开的片段id集合 */
    @observable
    public expandPartIds: string[] = [];
    /** 是否全部展开场景层 */
    @observable
    public expandAllScene: boolean = false;
    /** 是否全部展开镜头层 */
    @observable
    public expandAllShot: boolean = false;
    /** 选中的片段id集合 */
    @observable
    public checkedPartIds: string[] = [];
    /** 是否正在加载 */
    @observable
    public isLoadingStructureMetadatas = true;
    @observable
    public currentShowNotePanel: string | undefined;
    /** 元数据相关扩展操作 */
    public metadataOpeStore: MetadataOpeStore;
    /** 智能结构化ref */
    public structContentRef = React.createRef<HTMLDivElement>()
    private parentStore: ICatalogStore

    public constructor(stores: Stores, entityStore: ICatalogStore) {
        super(stores);
        this.parentStore = entityStore;
        this.metadataOpeStore = new MetadataOpeStore(stores, this);
    }

    @action
    public setStoryId(id?: string){
        this.parentStore.params.storyId = id;
        this.metadataOpeStore.selectedTag = undefined;
        this.metadataOpeStore.selectedTagDesc = [];
        //切换story时需要切换当前选中的person
        this.parentStore.smartViewStore.changePerson(undefined);
    }

    @action
    public setSceneId(id?: string){
        this.parentStore.params.sceneId = id;
        this.metadataOpeStore.selectedTag = undefined;
        this.metadataOpeStore.selectedTagDesc = [];
        this.parentStore.smartViewStore.changeCaption('');//切换scene时需要清空caption选中
        //切换scene时需要切换当前选中的person
        this.parentStore.smartViewStore.changePerson(undefined);
    }

    @action
    public setShotId(id?: string){
        this.parentStore.params.shotId = id;
        this.metadataOpeStore.selectedTag = undefined;
        this.metadataOpeStore.selectedTagDesc = [];
        this.parentStore.smartViewStore.changeCaption('');//切换scene时需要清空caption选中
        //切换shot时需要切换当前选中的person
        this.parentStore.smartViewStore.changePerson(undefined);
    }

    public getParentStore(){
        return this.parentStore;
    }

    /** 获取当前story */
    @computed
    public get currentStory(): iEntityTypes.IMetaOriginal | undefined{
        const storyId = this.getParentStore().params.storyId;
        if (storyId){
            return _.find(this.storyMetaList, {storyid: storyId});
        }
        else{
            //shot找story
            let currentShot = this.currentShot;
            if (currentShot){
                let scene = this.getParent(currentShot);
                if (scene){
                    return this.getParent(scene);
                }
            }
            else {
                //scene找story
                let currentScene = this.currentScene;
                if (currentScene){
                    return this.getParent(currentScene);
                }
            }
        }
        return undefined;
    }
    /** 获取当前scene */
    @computed
    public get currentScene(): iEntityTypes.IMetaOriginal | undefined{
        const sceneId = this.getParentStore().params.sceneId;
        if (sceneId){
            return _.find(this.sceneMetaList, {sceneid: sceneId});
        }
        else{
            //shot找scene
            let currentShot = this.currentShot;
            if (currentShot){
                return this.getParent(currentShot);
            }
        }
        return undefined;
    }
    /** 获取当前shot */
    @computed
    public get currentShot(): iEntityTypes.IMetaOriginal | undefined{
        const shotId = this.getParentStore().params.shotId;
        if (shotId){
            return _.find(this.shotMetaList, {shotid: shotId});
        }
        return undefined;
    }

    /** 获取当前片段 */
    @computed
    public get currentMeta(): iEntityTypes.IMetaOriginal | undefined{
        const currentShot = this.currentShot;
        if (currentShot){
            return currentShot;
        }
        else{
            let currentScene = this.currentScene;
            if (currentScene){
                return currentScene;
            }
            else{
                let currentStory = this.currentStory;
                if (currentStory){
                    return currentStory;
                }
            }
        }
        return undefined;
    }
    
    @action
    public getStoryStructureDatas(isManual?: boolean){
        return new Promise((resolve, reject)=>{
            this.isLoadingStructureMetadatas = true;
            if (!this.stores.configStore.uiModulesSwitch.smartToManualCatalogue){
                isManual = undefined;
            }
            let defer;
            if (this.getParentStore().params.taskId){
                defer = iCatalogApi.getAiData('story', this.getParentStore().params.contentId, this.getParentStore().params.taskId, undefined, undefined, isManual);
            }
            else {
                defer = iEntityApi.getStoryAidata(this.getParentStore().params.contentId);
            }
            defer.then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        res.data.data.data.forEach(item=>{
                            if (!item.parentType){
                                item.partType = SmartMetadataType.story;
                            }
                            if (item.sceneid){
                                this.sceneMetaList.push(item);
                            }
                            else if (item.shotid) {
                                this.shotMetaList.push(item);
                            }
                            else {
                                this.storyMetaList.push(item);
                            }
                        })
                    }
                    this.isLoadingStructureMetadatas = false;
                    resolve();
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }

    @action
    public getSceneStructureDatas(story: iEntityTypes.IMetaOriginal, isManual?: boolean){
        return new Promise((resolve, reject)=>{
            if (!this.stores.configStore.uiModulesSwitch.smartToManualCatalogue){
                isManual = undefined;
            }
            let defer;
            if (this.getParentStore().params.taskId){
                defer = iCatalogApi.getAiData('scene', this.getParentStore().params.contentId, this.getParentStore().params.taskId, 'story', story._id, isManual);
            }
            else {
                defer = iEntityApi.getSceneAidata(this.getParentStore().params.contentId, story);
            }
            defer.then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        res.data.data.data.forEach(item=>{
                            if (!item.parentType){
                                item.partType = SmartMetadataType.scene;
                            }
                            if (item.shotid) {
                                this.shotMetaList.push(item);
                            }
                            else {
                                this.sceneMetaList.push(item);
                            }
                        })
                        story.children = res.data.data.data;
                    }
                    resolve();
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public getShotStructureDatas(scene: iEntityTypes.IMetaOriginal, isManual?: boolean){
        return new Promise((resolve, reject)=>{
            if (!this.stores.configStore.uiModulesSwitch.smartToManualCatalogue){
                isManual = undefined;
            }
            let defer;
            if (this.getParentStore().params.taskId){
                defer = iCatalogApi.getAiData('shot', this.getParentStore().params.contentId, this.getParentStore().params.taskId, 'scene', scene._id, isManual);
            }
            else {
                defer = iEntityApi.getShotAidata(this.getParentStore().params.contentId, scene);
            }
            defer.then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        res.data.data.data.forEach(item=>{
                            item.partType = SmartMetadataType.shot;
                        })
                        this.shotMetaList = this.shotMetaList.concat(res.data.data.data);
                        scene.children = res.data.data.data;
                    }
                    resolve();
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    /**
     * 查询出所有展开的子节点
     */
    @action
    public clearMetaList(){
        this.storyMetaList = [];
        this.sceneMetaList = [];
        this.shotMetaList = [];
        this.metaInfoList = [];
    }

    /**
     * 查询出所有展开的子节点
     */
    @action
    public getExpandedDatas(isManual?: boolean){
        this.clearMetaList();
        this.getStoryStructureDatas(isManual).then(res=>{
            if (this.storyMetaList.length > 0){
                this.storyMetaList.forEach((story)=>{
                    if (this.isExpand(story)){
                        this.getSceneStructureDatas(story, isManual).then(res1=>{
                            if (story.children && story.children.length > 0){
                                story.children.forEach(scene=>{
                                    if (this.isExpand(scene)){
                                        this.getShotStructureDatas(scene, isManual);
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    @action
    public queryPartMetadata(part: iEntityTypes.IMetaOriginal){
        let metaInfo = this.getMetaInfoByPart(part);
        if (!metaInfo){
            let promise;
            if (this.getParentStore().params.taskId){
                if (part.storyid){
                    promise = iCatalogApi.getPartMetadata('story', part._id, this.getParentStore().entity.contentId, this.getParentStore().params.taskId)
                }
                else if (part.sceneid){
                    promise = iCatalogApi.getPartMetadata('scene', part._id, this.getParentStore().entity.contentId, this.getParentStore().params.taskId)
                }
                else if (part.shotid){
                    promise = iCatalogApi.getPartMetadata('shot', part._id, this.getParentStore().entity.contentId, this.getParentStore().params.taskId)
                }
            }
            else {
                if (part.storyid){
                    promise = iEntityApi.getStoryMetadata(this.getParentStore().entity.contentId, part.storyid)
                }
                else if (part.sceneid){
                    promise = iEntityApi.getSceneMetadata(this.getParentStore().entity.contentId, part.sceneid)
                }
                else if (part.shotid){
                    promise = iEntityApi.getShotMetadata(this.getParentStore().entity.contentId, part.shotid)
                }
            }
            if (promise){
                promise.then(res=>{
                    runInAction(()=>{
                        let type = SmartMetadataType.story;
                        if (part.sceneid){
                            type = SmartMetadataType.scene;
                        }
                        else if (part.shotid){
                            type = SmartMetadataType.shot;
                        }
                        metaInfo = {
                            id: part.shotid || part.sceneid || part.storyid,
                            metadata: res.data.data as IFormItem[],
                            'type': type
                        }
                        if (!this.metaInfoList){
                            this.metaInfoList = [];
                        }
                        this.metaInfoList.push(metaInfo);
                    })
                }, res=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                })
            }
        }
    }

    @action
    public setChildren(part: iEntityTypes.IMetaOriginal, children: iEntityTypes.IMetaOriginal[]){
        part.children = children;
    }

    @action
    public clearExpandPartIds(){
        this.expandPartIds = [];
    }
    @action
    public switchPartExpand(part: iEntityTypes.IMetaOriginal, expand: boolean){
        if (part.storyid){
            if (expand){
                this.expandPartIds.push(part.storyid);
            }
            else {
                _.remove(this.expandPartIds, (o)=>{
                    return o === part.storyid
                })
            }
        }
        else if (part.sceneid){
            if (expand) {
                this.expandPartIds.push(part.sceneid as string);
            }
            else {
                _.remove(this.expandPartIds, (o)=>{
                    return o === part.sceneid
                })
            }
        }
        else if (part.shotid){
            if (expand) {
                this.expandPartIds.push(part.shotid as string);
            }
            else {
                _.remove(this.expandPartIds, (o)=>{
                    return o === part.shotid
                })
            }
        }
    }
    public isExpand(part: iEntityTypes.IMetaOriginal): boolean{
        let id;
        if (part.storyid){
            id = _.find(this.expandPartIds, (o)=>{
                return o === part.storyid
            })
        }
        else if (part.sceneid){
            id = _.find(this.expandPartIds, (o)=>{
                return o === part.sceneid
            })
        }
        else if (part.shotid){
            id = _.find(this.expandPartIds, (o)=>{
                return o === part.shotid
            })
        }
        return id !== undefined;
    }

    public getSceneByStory(story: iEntityTypes.IMetaOriginal): iEntityTypes.IMetaOriginal[]{
        return story.children || [];
    }

    public getShotByScene(scene: iEntityTypes.IMetaOriginal): iEntityTypes.IMetaOriginal[]{
        return scene.children || [];
    }

    /** 获取当前片段的所有列表 */
    public getMetaListByPart(part: iEntityTypes.IMetaOriginal): iEntityTypes.IMetaOriginal[]{
        if (part.storyid){
            return this.storyMetaList;
        }
        else if (part.sceneid){
            return this.sceneMetaList;
        }
        else{
            return this.shotMetaList;
        }
    }

    public getPartIndex(part: iEntityTypes.IMetaOriginal): number{
        let index: number = -1;
        if (part.storyid){
            index = _.findIndex(this.storyMetaList, {storyid: part.storyid});
        }
        else if (part.sceneid){
            index = _.findIndex(this.sceneMetaList, {sceneid: part.sceneid});
        }
        else if (part.shotid){
            index = _.findIndex(this.shotMetaList, {shotid: part.shotid});
        }
        return index;
    }

    public isParentOf(part: iEntityTypes.IMetaOriginal, parent: iEntityTypes.IMetaOriginal): boolean{
        return parent.inpoint <= part.inpoint && parent.outpoint >= part.outpoint;
    }

    public getParent(part: iEntityTypes.IMetaOriginal): iEntityTypes.IMetaOriginal | undefined{
        let parent: iEntityTypes.IMetaOriginal | undefined;
        if (part.shotid){
            parent = _.find(this.sceneMetaList, (item)=>{
                return item.inpoint <= part.inpoint && item.outpoint >= part.outpoint;
            })
        }
        else if (part.sceneid){
            parent = _.find(this.storyMetaList, (item)=>{
                return item.inpoint <= part.inpoint && item.outpoint >= part.outpoint;
            })
        }
        return parent;
    }

    /** 获取上一个片段 */
    public getPrev(part: iEntityTypes.IMetaOriginal): iEntityTypes.IMetaOriginal | undefined{
        let prev: iEntityTypes.IMetaOriginal | undefined;
        let prevIdx: number;
        if (part.sceneid){
            let parentStory = this.getParent(part);
            let currSceneList: iEntityTypes.IMetaOriginal[] = [];
            if (parentStory){
                currSceneList = this.getSceneByStory(parentStory);
            }
            if (currSceneList){
                prevIdx = _.findIndex(currSceneList, (item)=>{
                    return item.sceneid === part.sceneid;
                })
                if (prevIdx === 0){
                    prev = undefined;
                }
                else{
                    prev = currSceneList[prevIdx - 1];
                }
            }
        }
        else if (part.storyid){
            prevIdx = _.findIndex(this.storyMetaList, (item)=>{
                return item.storyid === part.storyid;
            });
            if (prevIdx === 0){
                prev = undefined;
            }
            else{
                prev = this.storyMetaList[prevIdx - 1];
            }
        }
        return prev;
    }

    /** 获取下一个片段 */
    public getNext(part: iEntityTypes.IMetaOriginal): iEntityTypes.IMetaOriginal | undefined{
        let next: iEntityTypes.IMetaOriginal | undefined;
        let nextIdx: number;
        if (part.sceneid){
            let parentStory = this.getParent(part);
            let currSceneList: iEntityTypes.IMetaOriginal[] = [];
            if (parentStory){
                currSceneList = this.getSceneByStory(parentStory);
            }
            if (currSceneList){
                nextIdx = _.findIndex(currSceneList, (item)=>{
                    return item.sceneid === part.sceneid;
                })
                if (nextIdx === currSceneList.length - 1){
                }
                else{
                    next = currSceneList[nextIdx + 1];
                }
            }
        }
        else if (part.storyid){
            nextIdx = _.findIndex(this.storyMetaList, (item)=>{
                return item.storyid === part.storyid;
            })
            if (nextIdx === this.storyMetaList.length - 1){
            }
            else{
                next = this.storyMetaList[nextIdx + 1];
            }
        }
        return next;
    }

    @action
    public checkAllParts(check: boolean) {
        this.storyMetaList.forEach((story) => {
            this.switchPartCheckStatus(story, check);
        });
        this.sceneMetaList.forEach((scene) => {
            this.switchPartCheckStatus(scene, check);
        });
        this.shotMetaList.forEach((shot) => {
            this.switchPartCheckStatus(shot, check);
        });
    }

    /** 切换片段是否check状态 */
    @action
    public switchPartCheckStatus(part: iEntityTypes.IMetaOriginal, check: boolean){
        if (part.storyid){
            if (check){
                this.checkedPartIds.push(part.storyid);
            }
            else {
                _.remove(this.checkedPartIds, (o)=>{
                    return o === part.storyid
                })
            }
        }
        else if (part.sceneid){
            if (check) {
                this.checkedPartIds.push(part.sceneid as string);
            }
            else {
                _.remove(this.checkedPartIds, (o)=>{
                    return o === part.sceneid
                })
            }
        }
        else if (part.shotid){
            if (check) {
                this.checkedPartIds.push(part.shotid as string);
            }
            else {
                _.remove(this.checkedPartIds, (o)=>{
                    return o === part.shotid
                })
            }
        }
    }
    public isChecked(part: iEntityTypes.IMetaOriginal): boolean{
        let id: number = -1;
        if (part.storyid){
            id = _.findIndex(this.checkedPartIds, (o)=>{
                return o === part.storyid
            })
        }
        else if (part.sceneid){
            id = _.findIndex(this.checkedPartIds, (o)=>{
                return o === part.sceneid
            })
        }
        else if (part.shotid){
            id = _.findIndex(this.checkedPartIds, (o)=>{
                return o === part.shotid
            })
        }
        return id > -1;
    }

    /** 获取选中的片段 */
    public getCheckedParts(): iEntityTypes.IMetaOriginal[]{
        let selectedParts: iEntityTypes.IMetaOriginal[] = [];
        if (this.storyMetaList) {
            this.storyMetaList.forEach((story)=>{
                if (this.isChecked(story)){
                    selectedParts.push(story);
                }
            });
        }
        if (this.sceneMetaList){
            this.sceneMetaList.forEach((scene)=>{
                if (this.isChecked(scene)){
                    selectedParts.push(scene);
                }
            });
        }
        if (this.shotMetaList) {
            this.shotMetaList.forEach((shot) => {
                if (this.isChecked(shot)){
                    selectedParts.push(shot);
                }
            });
        }
        selectedParts = _.orderBy(selectedParts, ['inpoint'], ['asc']);//按入点排序
        return selectedParts;
    }

    @action
    public clearCheckedParts(){
        this.checkedPartIds = [];
    }

    /**
     * 新增片段信息
     */
    @action
    public addPart(part: iEntityTypes.IMetaOriginal, param: IAddPartParam): boolean{
        return true;
    }
    /**
     * 修改片段信息
     *
     * @return 是否修改成功
     */
    @action
    public modifyPart(part: iEntityTypes.IMetaOriginal, param: IModifyPartParam): boolean{
        let metaInfo = this.getMetaInfoByPart(part);
        if (param.title){
            if (part.storyid){//story
                part.title = param.title;
            }
            else if (part.sceneid) {
                part.scenename = param.title;
            }
            else {
                part.title = param.title;
            }
        }
        if (param.inpoint && param.outpoint){
            return this.modifyInoutpoint(part, param.inpoint, param.outpoint);
        }
        if (param.keyword){
            part.keyword = param.keyword;
            if (metaInfo){
                let keywordMetas = commonUtil.copyObj(metaInfo.metadata);
                let keywordMeta = _.find(keywordMetas, {fieldName: 'keyword'});
                if (keywordMeta){
                    keywordMeta.value = part.keyword;
                }
                metaInfo.metadata = keywordMetas;
            }
        }
        return true;
    }

    @action
    public removePart(part: iEntityTypes.IMetaOriginal): boolean{
        if (part.storyid){
            _.remove(this.storyMetaList, {storyid: part.storyid});
        }
        else if (part.sceneid) {
            _.remove(this.sceneMetaList, {sceneid: part.sceneid});
        }
        else{
            _.remove(this.shotMetaList, {shotid: part.shotid});
        }
        return true;
    }

    /** 获取当前part的标签元数据信息 */
    public getMetaInfoByPart(part: iEntityTypes.IMetaOriginal){
        let metaInfo;
        const metaInfoList = this.metaInfoList;
        if (part.storyid){
            metaInfo = _.find(metaInfoList, {id: part.storyid});
        }
        else if (part.sceneid){
            metaInfo = _.find(metaInfoList, {id: part.sceneid});
        }
        else if (part.shotid){
            metaInfo = _.find(metaInfoList, {id: part.shotid});
        }
        return metaInfo;
    }

    /** 获取当前part的某个fieldName的元数据信息 */
    public getMetadataFieldByPart(part: iEntityTypes.IMetaOriginal, fieldName: string){
        const metaInfo = this.getMetaInfoByPart(part);
        if (metaInfo){
            return _.find(metaInfo.metadata, (item)=>{
                return item.fieldName.toLowerCase() === fieldName.toLowerCase();
            });
        }
        return undefined;
    }

    /** 用元数据信息获取片段原始数据 */
    public getOriginalMetaByMetaInfo(metaInfo: iEntityTypes.IMetaInfo){
        let orimeta: iEntityTypes.IMetaOriginal | undefined;
        if (metaInfo.type === SmartMetadataType.story){
            orimeta = _.find(this.storyMetaList, {storyid: metaInfo.id});
        }
        else if (metaInfo.type === SmartMetadataType.scene) {
            orimeta = _.find(this.sceneMetaList, {sceneid: metaInfo.id});
        }
        else{
            orimeta = _.find(this.shotMetaList, {shotid: metaInfo.id});
        }
        return orimeta;
    }

    /**
     * 修改元数据信息
     *
     * @param operaType 1：修改；2：删除
     */
    public updateOriginalMeta(operaType: number, orimeta: iEntityTypes.IMetaOriginal, isCatalogue: boolean,
                              showSuccess?: boolean){
        return new Promise((resolve, reject)=> {
            const contentId = this.getParentStore().entity.contentId;
            const site = this.getParentStore().params.site;
            const request: entityTypes.characterRecognitionTypes.ISmartMetadatasRequest = {
                'contentId': contentId,
                'type': orimeta.partType,
                operaType,
                metadata: orimeta,
                isCatalogue
            }
            entityApi.updateIntelligentResult(request, site, 'true').then(res => {
                if (res.data.success) {
                    if (showSuccess === undefined || showSuccess === true){
                        if (operaType === 1){
                            message.success('修改成功！'.l('entity.modifySuccess'));
                        }
                        else if (operaType === 2){
                            message.success('删除成功！'.l('entity.deleteSuccess'));
                        }
                    }
                    resolve();
                }
                else if (res.data.error) {
                    message.error(res.data.error.title)
                    reject();
                }
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                    reject();
                }
            })
        });
    }

    /** 
     * 保存所有语言信息,只需传入type，就可以把所有前端的metadata保存到后台
     * 
     * @param type 要保存的metadata type
     */
    public patchUpdateOriginalMeta(type: SmartMetadataType){
        const entity = this.getParentStore().entity;
        const smartViewStore = this.getParentStore().smartViewStore;
        const site = this.getParentStore().params.site;
        const allVoiceMetadatas: entityTypes.SmartInfo.ISmartMetadatasVoiceMetadata[] 
        = commonUtil.copyObj(smartViewStore.smartMetadatas[type].metadata as entityTypes.SmartInfo.ISmartMetadatasVoiceMetadata[]);
        const req: entityTypes.SmartInfo.IPatchUpdateIntelligentReq = {
            contentId: entity.contentId,
            'type': type,
            operaType: 3,
            metadatas: allVoiceMetadatas
        }
        entityApi.patchUpdateIntelligentResult(req, site).then(res => {
            if (res.data.success) {
                message.success('修改成功！'.l('entity.modifySuccess'));
            }
            else if (res.data.error) {
                message.error(res.data.error.title)
            }
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title)
            }
        });
    }

    @action
    public setCurrentShowNotePanel(panel?: string){
        this.currentShowNotePanel = panel;
    }

    @action
    public setExpandAllScene(expand: boolean){
        this.expandAllScene = expand;
    }

    @action
    public setExpandAllShot(expand: boolean){
        this.expandAllShot = expand;
    }

    /** 将fieldName转换成后端需要用的type参数 */
    public getTypeByFieldName(fieldName: string): string{
        let ret = '';
        switch (fieldName.toLocaleLowerCase()){
            case 'relatedpeople':
            case 'eventpeople':
            case 'scenepeople':
            case 'relatedperson':
            case 'reporter':
            case 'face':
            case 'host':
                ret = 'NAME';
                break;
            case 'eventplace':
            case 'sceneplace':
            case 'district':
                ret = 'LOC';
                break;
            case 'eventorg':
            case 'sceneorg':
                ret = 'ORG';
                break;
            case 'eventdate':
            case 'scenedate':
                ret = 'TIME';
                break;
            case 'audio_category':
                ret = 'audio_category';
                break;
            default: break;
        }
        return ret;
    }

    /**
     * 修改片段入出点
     *
     * @return 是否修改成功
     */
    private modifyInoutpoint(part: iEntityTypes.IMetaOriginal, inpoint: number, outpoint: number): boolean{
        let metaList: iEntityTypes.IMetaOriginal[];
        let currIdx: number;
        if (!part.sceneid){//story
            metaList = this.storyMetaList;
            currIdx = _.findIndex(metaList, {storyid: part.storyid});
        }
        else {
            metaList = this.sceneMetaList;
            currIdx = _.findIndex(metaList, {sceneid: part.sceneid});
        }
        //判断是否超出上一个相邻片段的入点
        let lastPart = metaList[currIdx - 1];
        if (lastPart){
            if (lastPart.inpoint > inpoint){
                message.error('设置入点已经超过上一个片段 ${name} 的入点!'.l('newEntity.inpointIsLessThanLast', {
                    name: lastPart.title || lastPart.scenename
                }));
                return false;
            }
            else {
                lastPart.outpoint = inpoint;
            }
        }
        //判断是否超出下一个相邻节点的出点
        let nextPart = metaList[currIdx + 1];
        if (nextPart){
            if (nextPart.outpoint < outpoint){
                message.error('设置出点已经超过下一个片段 ${name} 的出点！'.l('newEntity.outpointIsGreaterThanNext', {
                    name: nextPart.title || nextPart.scenename
                }));
                return false;
            }
            else {
                nextPart.inpoint = outpoint;
            }
        }
        part.inpoint = inpoint;
        part.outpoint = outpoint;
        return true;
    }
}