import {http} from "../utils/http";
import {AxiosPromise} from "axios";
import myCollectionTypes from "../types/myCollectionTypes";
import {IResponse} from "mam-core-react/dist/modules/http";

namespace MyCollectionApi {
    export const getMyCollectionList = (params: myCollectionTypes.IMyCollectionParams): AxiosPromise<IResponse<myCollectionTypes.IGetMyCollectionListResponse>> => {
        return http.post('/favorite/query', params);
    }
}
export default MyCollectionApi