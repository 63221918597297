import datasetDetailsTypes from 'src/types/datasetDetailsTypes';
import { action, observable, runInAction } from 'mobx';
import { Stores } from "../index";
import datasetDetailsApi from 'src/apis/datasetDetailsApi';
import { message } from 'antd'
import SelectAllStore from "../extends/selectAllStore";
import ExtendStore from "../base/extendStore";
import TreeStore from './subModules/treeStore/treeStore';
import EntitysDataStore from './subModules/entitysDataStore/entitysDataStore';
import {IEntityType} from "src/types/configTypes";
export default class DatasetDetailsStore extends ExtendStore {
    public treeStore:TreeStore//左侧树仓库
    public entitysDataStore:EntitysDataStore//右侧内容区域仓库
    @observable
    public isEditData: boolean = true//编辑切换
    @observable
    public infoData: datasetDetailsTypes.IInfoRes//左侧信息
    @observable
    public type: boolean = false//模式
    @observable
    public tab: boolean = false//左侧tab切换
    @observable
    public contentId: string//用户id
    @observable
    public title: string
    @observable
    public folderId: string = ""//左侧选中文件id
    @observable
    public modalfolderId: string = ""//移动模态框选中文件id
    @observable
    public isCollection: boolean //是否收藏
    
    public selectAllStore: SelectAllStore
    @observable//排序方式
    public sort: datasetDetailsTypes.ISort[] = [
        { sort: "add_time", order: "desc", name: "添加时间降序" },
        { sort: "add_time", order: "asc", name: "添加时间升序" },
        { sort: "name", order: "desc", name: "名称降序" },
        { sort: "name", order: "asc", name: "名称升序" },
        { sort: "type", order: "desc", name: "类型降序" },
        { sort: "type", order: "asc", name: "类型升序" },
    ]
    @observable//选中排序方式
    public selectSort: datasetDetailsTypes.ISort
    @observable
    public keyword: string
    @observable
    public addEditModal: boolean = false//添加编辑的模态框
    @observable
    public isAdd: string//添加编辑模态框头部文字
    @observable
    public moveModal: boolean = false//移动素材模态框
    @observable
    public addEditName: string = ""//添加修改文件名
    @observable
    public filterEntityTypes: IEntityType[] = []//分类
    @observable//选中的分类
    public filterEntityTypesChecked: string[] = []

    constructor(stores: Stores) {
        super(stores);
        this.treeStore = new TreeStore(stores, this);
        this.entitysDataStore = new EntitysDataStore(stores, this)
        this.initSelectAllStore(stores, 'stores.datasetDetailsStore.entitysDataStore.entitysData.data', 'selected');
        this.initDownloadStore(stores)
        this.initUploadStore(stores);
        this.initUploadPanelStore(stores);
        this.initUploadPanelStore(stores);
        this.initFavoriteStore(stores)
        this.initOutstoreStore(stores);
    }
    @action
    public changeAddEditModal = (type: boolean) => {
        this.addEditModal = type
    }
    @action
    public setIsCollection = (type: boolean) => {
        this.isCollection = type
    }
    @action
    public changeMoveModal = (type: boolean) => {
        this.moveModal = type
    }
    @action
    public changeAddEditName = (name: string) => {
        this.addEditName = name
    }
    @action
    public changeIsAdd = (type: string) => {
        this.isAdd = type
    }
    @action
    public changeIsEditData = () => {
        this.isEditData = !this.isEditData
    }
    @action
    public changeTab = (type: boolean) => {
        this.tab = type
    }
    @action
    public changeKeyword = (keyword: string) => {
        this.keyword = keyword
    }
    @action
    public setContentId = (id: string) => {
        this.contentId = id
    }
    @action
    public setTitle = (title: string) => {
        this.title = title
    }
    @action
    public changeFolderId = (id: string) => {
        console.log(id)
        this.folderId = id
        this.treeStore.setPathArray(this.treeStore.findIndexArray(this.treeStore.TreeDataArr,id,[]))

    }
    @action
    public changeModalFolderId = (id: string) => {
        this.modalfolderId = id
    }
    @action
    public init = () => {
        // //设置分类和选中分类
        this.filterEntityTypes = this.stores.configStore.config.entityTypes.filter((item)=>{
            return item.code !== 'rundown' && item.code !== 'sequence' 
                    && item.code !== 'scene' && item.code !== 'shot' && item.code !== 'biz_sobey_newsscript'
        })
        this.filterEntityTypesChecked=[...this.stores.configStore.config.entityTypes.map(v=>v.code),"folder"]
    }
    @action
    public changeFilterEntityTypesChecked = (item: IEntityType) => {
        if (this.filterEntityTypesChecked.includes(item.code)) {
            this.filterEntityTypesChecked.splice(this.filterEntityTypesChecked.indexOf(item.code), 1)
        } else {
            this.filterEntityTypesChecked.push(item.code)
        }
        this.entitysDataStore.queryEntitysData(1)
    }
    //查询左侧信息
    @action
    public queryInfoData = (params: datasetDetailsTypes.IInfoParams) => {
        return new Promise((resolve, reject) => {
            datasetDetailsApi.getInfo(`pathtype=1&permission=1&contentid=${params.contentId}&shortUrl=`).then(res => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.infoData = res.data.data
                        this.isCollection=res.data.data.extensions.favorite//是否收藏
                        resolve(true)
                    } else {
                        reject(false)
                    }
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public changeType(type: boolean) {
        this.type = type
    }
    @action
    public setSelectSort(sort: string) {
        this.selectSort = this.sort.filter(v => v.name === sort)[0]
        this.entitysDataStore.queryEntitysData(1)
    }
    //删除选中素材或文件夹
    @action
    public del = (params:datasetDetailsTypes.IQueryDel) => {
        return new Promise((resolve, reject) => {
            datasetDetailsApi.queryDel(params).then(res => {
                if (!res.data.data?.length) {
                    datasetDetailsApi.del(params).then(ress => {
                        if (ress.data.data) {
                            resolve(true)
                        }
                    })
                }
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    //移动素材
    @action
    public move = () => {
        return new Promise((resolve, reject) => {
            if (!this.modalfolderId) {
                message.error("请选择目标文件夹")
                return
            }
            let arr = this.entitysDataStore.entitysData.data.filter(v => v.selected).map(v => {
                return {
                    contentId: v.contentId,
                    folderPath: v.folderPath,
                    name: v.name,
                    type: v.type
                }
            })
            let params = {
                datasetId: this.contentId,
                request: {
                    models: arr,
                    targetContentId: this.modalfolderId
                }
            }
            datasetDetailsApi.moveMaterial(params).then(res => {
                if (res.data) {
                    message.success("移动成功")
                    this.changeFolderId("")
                    this.entitysDataStore.queryEntitysData(1)
                    this.treeStore.initializationTreeData()
                    resolve(true)
                }
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                    reject()
                }
            })
            this.changeMoveModal(false)
        })
    }
    //收藏
    @action
    public collection = (params:datasetDetailsTypes.ICollectionParams) => {
        return new Promise((resolve, reject) => {
            datasetDetailsApi.collection(params).then(res => {
                if(res){
                    if(!params.favorite){
                        message.success("收藏成功")
                    }else{
                        message.success("取消收藏成功")
                    }
                    this.setIsCollection(!params.favorite)
                }
                resolve(res)
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

}
