import ExtendStore from "src/stores/base/extendStore"
import CatalogAdvancedSearchStore from './extends/catalogAdvancedSearchStore'

export default class CatalogTaskExtendStore extends ExtendStore {
    public catalogAdvancedSearchStore: CatalogAdvancedSearchStore;

    public initCatalogAdvancedSearchStore() {
        this.catalogAdvancedSearchStore = new CatalogAdvancedSearchStore(this.stores, this as any)
    }
}
