import BaseStore from "../base/baseStore";
import { action, observable, runInAction, computed } from 'mobx'
import mmsApi from '../../apis/mmsApi'
import mmsTypes from '../../types/mmsTypes'
import { stores } from '..';
import { message } from 'antd';
import { IBaseEntity } from 'src/types/commonTypes';
import {Stores} from "../index";

/** mch的收藏功能 */
export default class FavoriteStore extends BaseStore<Stores> {
    // 选择了的
    @observable
    public selected: string[] = []
    @observable
    public isShowMore: boolean = false
    @observable
    public isShowFavModal: boolean = false
    /** 是否是编辑标签 */
    @observable
    public isEdit: boolean = false
    @observable
    public tags: mmsTypes.favoriteTypes.IGetFavoriteTag[] = []
    @observable
    public onFavoriteCallback?: () => void // 收藏回调
    @observable
    public selectedItems: IBaseEntity[] = []
    @computed
    public get getContentIds() {
        return this.selectedItems.map(s => s.contentId)
    }

    // @action
    // public getGuids() {
    //     return this.selectedItems.map(s => s.dynamicResponse.wx_file_guid)
    // }
    // 已经选择的删除一个
    @action
    public removeSelectedItem(index: number) {
        this.selected.splice(index, 1);
    }
    // 添加到已经选择
    @action
    public addSelectedItem(tagName: string) {
        this.selected.push(tagName)
    }
    //  是否显示更多
    @action
    public enableShowMore() {
        this.isShowMore = true
    }
    //  选择的是否已经存在此项
    @action
    public isExistItem(value: string) {
        return this.selected.some(ele => ele === value)
    }
    //  显示隐藏模态框
    @action
    public setFavModalSH(value: boolean) {
        this.isShowFavModal = value
        if (!value) {
            if (this.onFavoriteCallback) {
                this.onFavoriteCallback()
            }
            this.isEdit = false
        }
    }
    // 过滤文件夹
    @action
    public setItems(items: IBaseEntity[]) {
        this.selectedItems = items.filter(s => s.type !== 'folder')
    }
    // 添加素材进来
    public addContentIds(items: IBaseEntity[]) {
        this.setItems(items)
        if (stores.configStore.config.favoriteTagEnable) {
            this.initStoreDate()
        } else {
            const data: mmsTypes.favoriteTypes.ISetFavorite = {
                favorite: false,
                contentIds: this.getContentIds,
                favoriteTags: [],
                shortUrls: []
            }
            this.setFavorite(data)
        }
    }
    // 批量导入id进行收藏时先获取标签
    @action
    public getFavoriteTags() {
        this.selected = []
        mmsApi.favorite.getFavoriteTags().then(res => {
            if (res.data.data) {
                this.addOneTags(res.data.data.tags)
            }
        })
    }
    // 初始配置，防止冲突
    @action
    public initStoreDate(bizContentId?: string ) {
        this.isShowMore = false
        this.selected = []
        mmsApi.favorite.getFavoriteTags().then(res => {
            if (res.data.data) {
                this.addOneTags(res.data.data.tags)
                runInAction(() => {
                    if (this.isEdit && bizContentId) {
                        this.tags.forEach(s => {
                            if (s.contentId.indexOf(bizContentId) > -1) {
                                this.selected.push(s.tagName)
                                s.selected = true
                            }
                        })
                    }
                    this.isShowMore = this.tags.length > 10
                })
            }
        }).then(() => {
            this.setFavModalSH(true);
        })
    }
    // 修改标签
    @action
    public updateTags(bizContentId: string) {
        this.isEdit = true
        this.selectedItems = [{
            contentId: bizContentId,
            isFavorite: true,
            type: ''
        }]
        this.initStoreDate(bizContentId)
    }
    // 恢复一个标签
    @action
    public recoverOneTags(tagName: string) {
        this.tags.forEach(tag => {
            if (tag.tagName === tagName) {
                tag.selected = false;
            }
        })
    }
    // 选中某个tag
    @action
    public selectOneTags(inpValNum: string) {
        this.tags.forEach(tag => {
            if (tag.tagName === inpValNum) {
                tag.selected = true
            }
        })
    }
    // 添加一个tag
    @action
    public addOneTags(elements: mmsTypes.favoriteTypes.IGetFavoriteTag[]) {
        this.tags = []
        elements.forEach((element: mmsTypes.favoriteTypes.IGetFavoriteTag) => {
            this.tags.push(element)
        });
    }

    @action
    public changeItemsFavorite(isFavorite: boolean) {
        this.selectedItems.forEach(s => {
            s.isFavorite = isFavorite
        })
    }

    public setFavorite(request: mmsTypes.favoriteTypes.ISetFavorite) {
        mmsApi.favorite.setFavoriteTags(request).then(res => {
            let isFavoriteTip = request.favorite ? '取消收藏成功'.l('favorite.cancelOk') : '收藏成功'.l('favorite.setOk')
            message.success(isFavoriteTip, 1.5);
            runInAction(() => {
                this.changeItemsFavorite(!request.favorite)
                if (this.onFavoriteCallback) {
                    this.onFavoriteCallback()
                }
            })
        }).catch(err => {
            // let isFavoriteTip = request.favorite ? '取消收藏失败'.l('favorite.cancelFailed') : '收藏失败'.l('favorite.setFailed')
            // message.error(isFavoriteTip, 1.5);
            if(err.data.error && err.data.error.title){
                message.error(err.data.error.title)
            }
            
        })
    }
}
