import * as React from 'react'

class NotFound extends React.Component {
    public render() {
        return (
            <div className="page-404">
                <h1>404</h1>
            </div>
        )
    }
}

export default NotFound
