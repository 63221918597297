import { AxiosPromise } from "axios";
import { http } from "src/utils/http";
import ccTypes from 'src/types/ccTypes'
import wuxiTypes from 'src/custom/wuxi/types/wuxiTypes';
import {IResponse} from "mam-core-react/dist/modules/http";
namespace wuxiApi {
    //回调功能
    export const getCallbackGroups = (): AxiosPromise<IResponse<wuxiTypes.callbackTypes.IGroupsItem[]>> => {
        return http.get<IResponse<wuxiTypes.callbackTypes.IGroupsItem[]>>('/wxexport/config')
    }
    export const callback = (params:wuxiTypes.callbackTypes.ICallbackReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/wxexport', params)
    }

    //获取元数据某项的配置项： 涉密原因-secretreason、 删除原因-deletereason
    export const getMetadataFieldSet = (fieldName:string): AxiosPromise<IResponse<ccTypes.IMetadataFieldSetItem[]>> => {
        return http.get<IResponse<ccTypes.IMetadataFieldSetItem[]>>('/metadatafield/fixitems?fieldName='+fieldName);
    }

    //设置密集操作记录到操作记录
    export const setPrivilegeRecord = (params:wuxiTypes.ISetPrivilegeRecordReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/wxexport/sent-mothball-message', params)
    }
    //删除/彻底删除/还原记录到操作记录
    export const deleteRecord = (params:wuxiTypes.IDeleteRecordReqItem[]): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/wxexport/sent-delete-message', params)
    }

    //重新关联
    export const cancelRelated = (params: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/wxcatalogue/m2pass/renew/cjqrelation',params)
    }
}

export default wuxiApi
