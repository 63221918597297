import { Stores } from 'src/stores';
import ExtendStore from 'src/stores/base/extendStore';
import IIndexStore from '../iIndexStore';
import { action, runInAction, observable } from 'mobx';
import iIndexTypes from 'src/types/iIndexTypes';
import iIndexApi from 'src/apis/iIndexApi';
import { message } from 'antd';
import _ from 'lodash'
import { IResponse } from 'mam-core-react/dist/modules/http';
import { AxiosResponse } from 'axios';

export default class SecListStore extends ExtendStore {
    @observable
    public tabCategories: iIndexTypes.secList.ICategoryTagCountRes[];
    @observable
    public secTabCategories: iIndexTypes.secList.ICategoryTagCountRes[]
    /** 选中的类型 */
    @observable
    public selectedTab: string;
    /** 选中的二级类型 */
    @observable
    public selectedSecTab: string = '全部';
    /** 关键字 */
    @observable
    public keyword: string;
    /** 通用列表 */
    @observable
    public listRes?: iIndexTypes.secList.ICategoryTagRes;
    /** 未知人脸列表 */
    public unknownPersonRes?: iIndexTypes.secList.IUnknowPersonTagRes;
    @observable
    public isReqList: boolean = false;
    @observable
    public pageSize: number = 30;
    /** 排序列表 */
    @observable
    public sortList: iIndexTypes.secList.ISortItem[] = [
        {
            value: 'sortWeight',
            showValue: '按权重'
        },
        {
            value: 'edition_count',
            showValue: '按数量'
        },
        {
            value: 'edition_duration',
            showValue: '按时长'
        }
    ];
    @observable
    public selectedSortValue: string = 'sortWeight';
    public category: string;
    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
    }

    @action
    public getTabCategories(category: string){
        this.category = category;
        return new Promise((resolve, reject)=>{
            let params: iIndexTypes.secList.ICategoryTagCountReq = {
                category
            }
            iIndexApi.getCategoryTagCount(params).then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        let allCount = _.sumBy(res.data.data, 'count')
                        res.data.data.unshift({
                            category: '全部',
                            count: allCount
                        });
                        this.tabCategories = res.data.data;
                        if (this.tabCategories.length > 0){
                            this.setSelectedTab(this.tabCategories[0].category).finally(()=>{
                                resolve();
                            });
                        }
                    }
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public getList(category: string, page?: number){
        this.category = category;
        this.listRes = undefined;
        this.unknownPersonRes = undefined;
        if (this.selectedSecTab !== '未知人脸'){
            let params: iIndexTypes.secList.ICategoryTagReq = {
                category: category + (this.selectedTab !== '全部' ? '/' + this.selectedTab + (this.selectedSecTab !== '全部' ? '/' + this.selectedSecTab : '') : ''),
                tagName: this.keyword,
                pageIndex: page || 1,
                pageSize: this.pageSize,
                isControlled:  '1'
            }
            // 已知人脸或已知人物
            if (category === '/人物' && this.selectedTab === '全部'){
                if (this.selectedSecTab === '已知人脸') {
                    params.hasFace = '1';
                }
                else {
                    params.hasFace = '0';
                }
            }
            // 排序
            if (this.selectedSortValue){
                params.sortField = this.selectedSortValue;
                params.isDesc = true;
            }
            this.isReqList = true;
            iIndexApi.getCategoryTag(params).then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        let names: string[] = [];
                        let nameAndSynonyms: iIndexTypes.secList.IPersonDetailsReq[] = [];
                        if (res.data.data.data){
                            res.data.data.data.forEach((item)=>{
                                names.push(item.tagName);
                                nameAndSynonyms.push({
                                    keyword: item.tagName,
                                    synonyms: item.statKeyword
                                })
    
                                //获取拼音
                                if ((window as any).pinyinUtil){
                                    item.spell = (window as any).pinyinUtil.getFirstLetter(item.tagName);
                                }
                            })
                        }
                        this.listRes = res.data.data;
                        this.listRes.isDataReady = false;
                        //查询详情
                        if (category === '/人物' && names.length > 0){
                            this.getPersonDetails(nameAndSynonyms);
                        }
                        else if (category === '/机构' && names.length > 0){
                            this.getOrgDetails(nameAndSynonyms);
                        } else if (category === '/物体' && names.length > 0) {
                            this.getKeyframes('goods', names, 4)
                            this.getTagCount(names, 'goods');
                        } else if (category === '/主题' && names.length > 0) {
                            this.getKeyframes('keyword', names, 1)
                            this.getTagCount(names, 'keyword');
                        } 
                        else if (category === '/数据' && names.length > 0){
                            this.getTagCount(names, 'datum');
                        }
                        else {
                            this.isReqList = false;
                        }
                    }
                    else {
                        this.isReqList = false;
                    }
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                runInAction(()=>{
                    this.isReqList = false;
                });
            });
        }
        else {
            this.isReqList = true;
            iIndexApi.getUnknowPersonTag(page || 1, this.pageSize).then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        this.unknownPersonRes = res.data.data;
                    }
                    this.isReqList = false;
                })
            }).catch(res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                runInAction(()=>{
                    this.isReqList = false;
                });
            })
        }
    }

    // 物体关键词获取关键帧
    @action
    public getKeyframes(type: string, names: string[], num: number) {
        iIndexApi.getKeyframe(type, names, num).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    if(this.listRes) {
                        this.listRes.data.forEach((item)=>{
                            item.keyframePaths = res.data.data[item.tagName]
                        })
                    }
                    if (this.listRes){
                        this.listRes.isDataReady = true;
                    }
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        }).finally(()=>{
            runInAction(()=>{
                this.isReqList = false;
            })
        });
    }

    @action
    public getTagCount(names: string[], type: string){
        iIndexApi.getTagCount(names, type).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    if(this.listRes) {
                        this.listRes.data.forEach((item)=>{
                            const countItem = _.find(res.data.data, {key: item.tagName});
                            if (countItem) {
                                item.qCount = countItem.count;
                            }
                        })
                    }
                }
            })
        }).catch(res=>{
            if (res.data && res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            else {
                console.error(res);
            }
        }).finally(()=>{
            runInAction(()=>{
                this.isReqList = false;
            })
        })
    }

    @action
    public getPersonDetails(req: iIndexTypes.secList.IPersonDetailsReq[]){
        iIndexApi.getPersonDetails(req).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    res.data.data.forEach((item)=>{
                        if (this.listRes){
                            let listItem = _.find(this.listRes.data, {tagName: item.name});
                            if (listItem) {
                                listItem.personDetail = item;
                            }
                        }
                    })
                    if (this.listRes){
                        this.listRes.isDataReady = true;
                    }
                }
                this.isReqList = false;
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            this.isReqList = false;
        });
    }

    @action
    public getOrgDetails(req: iIndexTypes.secList.IPersonDetailsReq[]){
        iIndexApi.getOrgDetails(req).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    res.data.data.forEach((item)=>{
                        if (this.listRes){
                            let listItem = _.find(this.listRes.data, {tagName: item.name});
                            if (listItem) {
                                listItem.orgDetail = item;
                            }
                        }
                    })
                    if (this.listRes){
                        this.listRes.isDataReady = true;
                    }
                }
                this.isReqList = false;
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            this.isReqList = false;
        });
    }

    @action
    public setSelectedTab(tab: string){
        this.selectedTab = tab;
        this.selectedSecTab = '全部';
        if (!tab){
            return Promise.resolve();
        }

        return new Promise((resolve, reject)=>{
            //查询二级分类
            this.secTabCategories = [];
            if ((this.category === '/人物' || this.category === '/机构') && this.selectedTab !== '全部'){
                iIndexApi.getCategoryTagCount({
                    category: this.category + '/' + this.selectedTab
                }).then(res=>{
                    runInAction(()=>{
                        if (res.data.data){
                            let allCount = _.sumBy(res.data.data, 'count')
                            this.secTabCategories = [{
                                category: "全部",
                                count: allCount
                            }].concat(res.data.data);
                        }
                        resolve();
                    })
                }, res=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                    reject();
                });
            }
            else if (this.selectedTab === '全部' && this.category === '/人物'){
                // 查询未知人脸数量
                const promise1 = new Promise<iIndexTypes.secList.IUnknowPersonTagRes>((resolve1, reject1)=>{
                    iIndexApi.getUnknowPersonTag(1, 1).then(res=>{
                        resolve1(res.data.data);
                    }).catch(res=>{
                        if (res.data.error && res.data.error.title){
                            message.error(res.data.error.title);
                        }
                        reject1();
                    })
                })

                // 查询已知人脸数量，已知人脸
                const promise2 = new Promise<number>((resolve2, reject2)=>{
                    let params: iIndexTypes.secList.ICategoryTagReq = {
                        category: this.category,
                        tagName: '',
                        pageIndex: 1,
                        pageSize: 1,
                        isControlled:  '1',
                        hasFace: '1'
                    }
                    iIndexApi.getCategoryTag(params).then(res=>{
                        resolve2(res.data.data ? res.data.data.recordTotal : 0);
                    }).catch(res=>{
                        if (res.data.error && res.data.error.title){
                            message.error(res.data.error.title);
                        }
                        reject2();
                    });
                });

                // 查询已知人脸数量，已知人物
                const promise3 = new Promise<number>((resolve3, reject3)=>{
                    let params1: iIndexTypes.secList.ICategoryTagReq = {
                        category: this.category,
                        tagName: '',
                        pageIndex: 1,
                        pageSize: 1,
                        isControlled:  '1',
                        hasFace: '0'
                    }
                    iIndexApi.getCategoryTag(params1).then(res=>{
                        resolve3(res.data.data ? res.data.data.recordTotal : 0);
                    }).catch(res=>{
                        if (res.data.error && res.data.error.title){
                            message.error(res.data.error.title);
                        }
                        reject3();
                    });
                });

                Promise.all([promise1, promise2, promise3]).then((resArr)=>{
                    runInAction(()=>{
                        if (resArr[0]){
                            let allCount = _.sumBy(this.tabCategories.filter((tag)=>{
                                return tag.category !== '全部'
                            }), 'count')
                            this.secTabCategories = [{
                                category: "已知人脸",
                                count: resArr[1]
                            },
                            {
                                category: "已知人物",
                                count: resArr[2]
                            },
                            {
                                category: "未知人脸",
                                count: resArr[0].record_total
                            }]
                            this.selectedSecTab = '已知人脸';
                            // 更新全部数量,已知人脸+未知人脸
                            let allCategory = _.find(this.tabCategories, {category: '全部'});
                            if (allCategory){
                                allCategory.count = allCount + resArr[0].record_total
                            }
                        }
                        resolve();
                    })
                }).catch((e)=>{
                    console.error(e);
                    reject();
                })
            }
            else {
                resolve();
            }
        })
    }

    @action
    public setSelectedSecTab(tab: string){
        this.selectedSecTab = tab;
    }

    @action
    public setKeyword(keyword: string){
        this.keyword = keyword;
    }

    @action
    public setPageSize(size: number){
        this.pageSize = size;
    }

    @action
    public setSelectedSortValue(value: string){
        this.selectedSortValue = value;
    }
}