import { Stores } from 'src/stores';
import ExtendStore from 'src/stores/base/extendStore';
import IIndexStore from '../iIndexStore';
import { action, runInAction, observable } from 'mobx';
import iIndexApi from 'src/apis/iIndexApi';
import { message } from 'antd';
import _ from 'lodash'
import iIndexTypes from 'src/types/iIndexTypes';
import monent from 'moment'

export default class CategoryListStore extends ExtendStore {
    /** 通用列表 */
    @observable
    public list?: iIndexTypes.secList.ITopicItem[];
    @observable
    public isReqList: boolean = false;
    @observable
    public selectedTime = 'all';
    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
    }

    @action
    public getList(){
        this.list = [];
        this.isReqList = true;
        let startTime;
        let endTime;
        if (this.selectedTime === 'week' || this.selectedTime === 'month'){
            let nowDate = new Date();
            let startDate = new Date();
            if (this.selectedTime === 'week'){
                startDate.setDate(nowDate.getDate() - 7);
            }
            else if (this.selectedTime === 'month'){
                startDate.setDate(nowDate.getDate() - 30);
            }
            startTime = monent(startDate).format('YYYY-MM-DD');
            endTime = monent(nowDate).format('YYYY-MM-DD');
        }
        iIndexApi.getTopicList(startTime, endTime).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.list = res.data.data;
                }
                this.isReqList = false;
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            this.isReqList = false;
        });
    }

    @action
    public setSelectedTime(time: string){
        this.selectedTime = time;
    }
}