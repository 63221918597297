import ExtendStore from "src/stores/base/extendStore"
import { Stores } from "src/stores/index"
import _ from 'lodash'
import ExhibitionStore from './exhibition/exhibitionStore'

//mrc定制
export default class MrcStore extends ExtendStore {
    public exhibitionStore: ExhibitionStore

    constructor(stores: Stores) {
        super(stores)
        this.exhibitionStore = new ExhibitionStore(stores, this)

    }

    
}
