import {action, observable, runInAction} from "mobx";
import _ from 'lodash'
import { BaseStore } from 'mam-core-react';
import { Stores } from '..';

export default class IHeaderStore extends BaseStore<Stores> {
    @observable
    public isCollapse: boolean = false;
    /** 是否一直保持收起 */
    @observable
    public allwaysCollapse: boolean = false;

    @action
    public setIsCollapse(collapse: boolean){
        this.isCollapse = collapse;
    }

    @action
    public setAllwaysCollapse(allwaysCollapse: boolean){
        this.allwaysCollapse = allwaysCollapse;
    }
}