import { action, observable, runInAction } from "mobx";
import ccTypes from "../../../types/ccTypes";
import CcStore from "../ccStore";
import { Stores } from "../../index";
import ccApi from "../../../apis/ccApi";
import _ from 'lodash';
import SearchTreeStore from "./searchTreeStore";
import {extractTags} from "../../../pages/newEntity/modules/rightTabContent/contentStruct/storyTreeList/tags";
import RelativeInfoStore from "./relativeInfoStore";
import commonUtil from "mam-common-utils/dist/modules/commonUtil";


export default class SearchStore extends CcStore {
    public searchTreeStore: SearchTreeStore;
    public relativeInfoStore: RelativeInfoStore;
    @observable
    public params: ccTypes.searchTypes.ISearchParams
    @observable
    public fullSearchData?: ccTypes.searchTypes.IFullSearchRes;

    @observable
    public previewEntity: ccTypes.searchTypes.IQueryResultData;
    @observable
    public selectedPreviewEntity: ccTypes.searchTypes.IEntityPathData | undefined;

    @observable
    public searchKeyword1: string = '';  // 主搜索框值
    @observable
    public searchKeyword2: string = '';  // 次检索框值

    @observable
    public pageSize: number = 30;
    public pageSizes: number[] = [30,60,100]

    // 主检索结果少于6条情况下才显示相关推荐的检索
    @observable
    public countForShowRecommendSearch: number = 6;
    @observable
    public recommendSearchSize: number = 20;
    @observable
    public recommendSearchData: ccTypes.searchTypes.IRecommendSearchRes;
    
    @observable
    public isLoadingFullSearchData = true;

    constructor(stores: Stores) {
        super(stores)
        this.initOutstoreStore(stores);
        this.initDeleteStore(stores);
        this.initCreatePlanningStore(stores);
        this.initOutputExcelStore(stores);
        this.initExportExcelFieledSettingStore(stores);
        this.initUnlockStore(stores);
        this.initShareStore(stores);
        this.initSelectAllStore(stores, 'stores.searchStore.fullSearchData.queryResult.data', 'selected');
        this.initAdvancedSearchStore(stores)
        this.initBatchExecuteSmartProcessStore(stores)
        this.initBindBCProgramCodeStore(stores);
        this.initCreateDatasetStore(stores);

        this.searchTreeStore = new SearchTreeStore(stores);
        this.relativeInfoStore = new RelativeInfoStore(stores);
    }

    @action
    public queryFullSearchData(page?: number): Promise<any> {
        this.fullSearchData = undefined;
        this.selectAllStore.switchAllSelected(false);
        //查询相关信息，智能模式才查询
        // if (this.stores.configStore.config.intelligentEnable){
        //     this.relativeInfoStore.emptyRelativeInfo();
        //     if (this.params.keyword && this.params.keyword.length > 0){
        //         this.relativeInfoStore.getRelativeInfo(this.params.keyword[0])
        //     }
        // }
        return new Promise((resolve, reject) => {
            this.getSortItems();
            if (this.stores.configStore.config.searchResultNum){
                this.pageSize = this.stores.configStore.config.searchResultNum;
            }
            const pageSizeStorage = localStorage.getItem('searchStore.pageSize');
            if (pageSizeStorage !== null){
                this.pageSize = parseInt(pageSizeStorage, 10)
            }
            let req: ccTypes.searchTypes.IFullSearchReq = {
                page: page || this.params.page,
                size: this.pageSize,
                keyword: this.params.keyword,
                sortBys: [{
                    fieldName: this.currentSort.field,
                    isDesc: this.currentSort.desc === 'desc'
                }],
                conditions: [],
                highLight: true
            };
            if(this.stores.configStore.config.wuXiCustomSwitch){
                req.conditions.push({
                    field: "privilege_",
                    value: ["public_S1"] 
                })
            }
            if (this.params.conditions) {
                this.params.conditions.forEach((condition) => {
                    if (condition.items) {
                        condition.items.forEach((n) => {
                            let item = _.find(req.conditions, { field: n.field });
                            if (item == null) {
                                req.conditions.push(n);
                            } else {
                                if (_.isArray(item.value)) {
                                    if (!_.includes(item.value, n.value)) {
                                        item.value.push(n.value as string);
                                    }
                                } else {
                                    if (item.value !== n.value) {
                                        item.value = [item.value, n.value as string];
                                    }
                                }
                            }
                        });
                    } else {
                        if (condition.field && condition.value) {
                            req.conditions.push({ field: condition.field, value: condition.value })
                        }
                    }
                });
            }
            if (this.params.advancedSearch && this.params.advancedSearch.field.length > 0 && this.params.advancedSearch.isOnlyAvSearching) {
                if(this.stores.configStore.config.wuXiCustomSwitch){
                    const params: ccTypes.searchTypes.IAdvancedSearchTypes = {
                        field:  this.params.advancedSearch.field.filter(item => {
                            if(item.value !== '' && item.value !== "notFound" && item.value !== undefined && item.value !== '[]' && item.value !== null) {
                                return item
                            }else {
                                return false
                            }
                        }),
                        isOnlyAvSearching: this.params.advancedSearch.isOnlyAvSearching
                    }
                    if(params.field && params.field.length > 0 ){
                        req.advancedSearch = this.handleWuxiAdvancedSearch(params)
                    }
                }else{
                    req.advancedSearch = this.params.advancedSearch
                }
            }
            if (this.params.resourceName) {
                req.resourceName = this.params.resourceName
            }
            this.isLoadingFullSearchData = true;
            ccApi.searchApi.fullSearch(req).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        res.data.data = this.mappingData2SectionSearch(res.data.data)
                        this.initFacets(res.data.data.facets);
                        if (res.data.data.queryResult && res.data.data.queryResult.data) {
                            this.resolveQueryResult(res.data.data.queryResult.data);

                            // 智能模式下，主检索关键词模糊搜索结果少于countForShowRecommendSearch条情况下才获取相关推荐的检索
                            if(this.stores.configStore.config.intelligentEnable && this.searchKeyword1 && res.data.data.queryResult.recordTotal < this.countForShowRecommendSearch){
                                let recommendReq = commonUtil.copyObj(req)
                                recommendReq.size = this.recommendSearchSize
                                recommendReq.lastCount = res.data.data.queryResult.data.length
                                this.getRecommendSearchData(recommendReq)
                            }
                        }
                        /** 无锡定制：根据素材id获取云端高低码状态 */
                        if(this.stores.configStore.config.pushPullSwitch){
                            this.getProgramNameCloud(res.data.data.queryResult.data)
                        }
                        this.fullSearchData = res.data.data;
                    }
                    this.isLoadingFullSearchData = false;
                })
                resolve(undefined);
            }, () => {
                reject();
            });
        });
    }

    // 智能模式下，主检索关键词模糊搜索结果少于5条情况下才获取相关推荐的检索
    @action
    public getRecommendSearchData(req: ccTypes.searchTypes.IFullSearchReq) {
        ccApi.searchApi.getRecommendSearchData(req).then((res)=>{
            runInAction(() => {
                if(res.data.success && res.data.data){
                    res.data.data = this.mappingRecommendData2SectionSearch(res.data.data)
                    if (res.data.data.data) {
                        this.resolveQueryResult(res.data.data.data);
                    }
                    this.recommendSearchData = res.data.data;
                }
            })
        })
    }
    @action
    public setParams(searchParams: ccTypes.searchTypes.ISearchParams) {
        this.params = searchParams;
        if (searchParams.keyword){
            if (searchParams.keyword[0]){
                this.setSearchKeyword1(searchParams.keyword[0]);
            }
            if (searchParams.keyword[1]){
                this.setSearchKeyword2(searchParams.keyword[1]);
            }
        }
        //如果有高级检索条件，则自动显示高级检索面板
        if (searchParams.advancedSearch && searchParams.advancedSearch.field && searchParams.advancedSearch.field.length > 0
            && this.advancedSearchStore){
            this.advancedSearchStore.setShowAdvancedSearch(true)
        }
    }

    @action
    public setSearchKeyword1(str: string) {
        this.searchKeyword1 = str;
    }
    @action
    public setSearchKeyword2(str: string) {
        this.searchKeyword2 = str;
    }

    /**
     * 重命名
     */
    @action
    public setRename(index: number, editMode: boolean) {
        if (this.fullSearchData) {
            if (editMode) {
                this.fullSearchData.queryResult.data[index].oldName = this.fullSearchData.queryResult.data[index].name
            }
            this.fullSearchData.queryResult.data[index].editMode = editMode
        }
    }

    @action
    public setNewRename(index: number, newName: string) {
        if (this.fullSearchData) {
            this.fullSearchData.queryResult.data[index].name = newName
        }
    }

    /** 设置收藏状态 */
    @action
    public setItemFavorite(item: any, favorite: boolean){
        if (this.fullSearchData && this.fullSearchData.queryResult && this.fullSearchData.queryResult.data){
            let target = _.find(this.fullSearchData.queryResult.data, {contentId: item.contentId});
            if (target){
                target.isFavorite = favorite;
                target.isCollected = favorite;
            }
        }
    }

    @action
    public setPageSize(size: number){
        this.pageSize = size;
        localStorage.setItem('searchStore.pageSize', size.toString());
        this.queryFullSearchData(1).then(()=>{});
    }

    /** 将片段检索结果映射成素材检索所需要的字段 */
    public mappingData2SectionSearch(res: ccTypes.searchTypes.IFullSearchRes | ccTypes.searchTypes.ISmartFullSearchRes): ccTypes.searchTypes.IFullSearchRes {
        if (!this.params.resourceName || this.params.resourceName === 'entity') {
            return res as ccTypes.searchTypes.IFullSearchRes
        } else {
            let newRes: ccTypes.searchTypes.IFullSearchRes = {
                facets: res.facets || [],
                sortFields: res.sortFields,
                usedTime: res.usedTime,
                queryResult: {
                    pageIndex: res.queryResult.pageIndex,
                    pageTotal: res.queryResult.pageTotal,
                    pageSize: res.queryResult.pageSize,
                    recordTotal: res.queryResult.recordTotal,
                    data: (res.queryResult.data as any).map((item: any): any => {
                        return {
                            id: '',
                            site_: item.site_,
                            contentId_: item.contentId_,
                            contentId: item.contentId_,
                            name_: item.name_ || item.title,
                            name: item.name || item.title,
                            createDate_: item.createDate_,
                            createUser_: item.userName,
                            createdate: item.createDate_,
                            creator: item.createUser_,
                            catalogstate: '',
                            type_: item.type_,
                            type: item.type_,
                            aspect_: 0,
                            filesize: 0,
                            fileSize_: 0,
                            size_: 0,
                            tree_path_: '',
                            treePath: '',
                            formatFlag: 0,
                            trimin: '',
                            trimout: '',
                            frameRate: 0,
                            durationTc: item.durationTc,
                            distributionMark: '',
                            isCollected: false,
                            siteName: item.site_,
                            isPickedup: false,
                            isPlaceholder: 0,
                            isPublish: 0,
                            keyframepath: item.keyframepath || '',
                            keyframe: item.keyframepath || '',
                            privilege_: item.privilege_,
                            permissionInfo: {
                                canRead: false,
                                canWrite: false,
                                canExecute: false,
                                canDelete: false,
                            },
                            resourceType: '',
                            archiveList: [],
                            barcodes: [],
                            meterialLabel: [],
                            dynamicResponse: {},
                            duration: '',
                            isFavorite: item.isFavorite,
                            storagedDate: '',
                            storagedUser: '',
                            isMy: false,
                            canRead: false,
                            canWrite: false,
                            canExecute: false,
                            canDelete: false,
                            fileExt: '',
                            storyid: item.storyid,
                            inpoint: item.inpoint,
                            outpoint: item.outpoint,
                            tags: extractTags(item),
                            sceneid:item.sceneid || undefined,
                            shotid:item.shotid || undefined
                        }
                    })
                }
            };
            return newRes
        }
    }
    /** 推荐检索：将片段检索结果映射成素材检索所需要的字段 */
    public mappingRecommendData2SectionSearch(res: ccTypes.searchTypes.IRecommendSearchRes | ccTypes.searchTypes.IRecommendSmartFullSearchRes): ccTypes.searchTypes.IRecommendSearchRes {
        if (!this.params.resourceName || this.params.resourceName === 'entity') {
            return res as ccTypes.searchTypes.IRecommendSearchRes;
        } else {
            let newRes: ccTypes.searchTypes.IRecommendSearchRes = {
                words: res.words || [],
                recommendType: res.recommendType || '',
                data: (res.data as ccTypes.searchTypes.ISmartQueryResultData[]).map((item): ccTypes.searchTypes.IQueryResultData => {
                    return {
                        id: '',
                        site_: item.site_,
                        contentId_: item.contentId_,
                        contentId: item.contentId_,
                        name_: item.scenename || item.title,
                        name: item.scenename || item.title,
                        createDate_: item.createDate_,
                        createUser_: item.userName,
                        createdate: item.createDate_,
                        creator: item.createUser_,
                        catalogstate: '',
                        type_: item.type_,
                        type: item.type_,
                        aspect_: 0,
                        filesize: 0,
                        fileSize_: 0,
                        size_: 0,
                        tree_path_: '',
                        treePath: '',
                        formatFlag: 0,
                        trimin: '',
                        trimout: '',
                        frameRate: 0,
                        durationTc: item.durationTc,
                        distributionMark: '',
                        isCollected: false,
                        siteName: item.site_,
                        isPickedup: false,
                        isPlaceholder: 0,
                        isPublish: 0,
                        keyframepath: item.keyframepath || '',
                        keyframe: item.keyframepath || '',
                        privilege_: item.privilege_,
                        permissionInfo: {
                            canRead: false,
                            canWrite: false,
                            canExecute: false,
                            canDelete: false,
                        },
                        resourceType: '',
                        archiveList: [],
                        barcodes: [],
                        meterialLabel: [],
                        dynamicResponse: {},
                        duration: '',
                        isFavorite: false,
                        storagedDate: '',
                        storagedUser: '',
                        isMy: false,
                        canRead: false,
                        canWrite: false,
                        canExecute: false,
                        canDelete: false,
                        fileExt: '',
                        storyid: item.storyid,
                        inpoint: item.inpoint,
                        outpoint: item.outpoint,
                        tags: extractTags(item),
                        sceneid:item.sceneid || undefined,
                        shotid:item.shotid || undefined
                    }
                })
            };
            return newRes
        }
    }

    /** 设置预览素材 */
    @action
    public setPreviewEntity(entity:ccTypes.searchTypes.IQueryResultData){
        this.previewEntity = entity;
        this.getFilePathByContentId(entity.contentId);
    }

    /** 根据素材id获取地址 */
    @action
    public getFilePathByContentId(contentId:string){
        ccApi.searchApi.getEntityPath(contentId).then(res=>{
            runInAction(() => {
                if(res.data.success&&res.data.data&&res.data.data[0]){
                    this.selectedPreviewEntity = res.data.data[0];
                }else{
                    this.selectedPreviewEntity = undefined
                }
            })
        },res=>{
            this.selectedPreviewEntity = undefined;
        })
    }
}
