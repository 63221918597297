import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import { puts } from 'util';
import { throws } from 'assert';
import {Stores} from "../index";

export default class UnlockStore extends BaseStore<Stores>{
    @observable
    public showUnlockModal:boolean = false

    @observable
    public lockInfo:any

    @observable
    public items:any[]

    @action
    public setShowUnlockModal(show:boolean){
        this.showUnlockModal = show
    }

    @action
    public setLockInfo(lockInfo:any){
        this.lockInfo = lockInfo
    }

    @action
    public setItems(items:any[]){
        this.items = items
    }
}