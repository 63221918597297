import { AxiosPromise } from 'axios'
import { IResponse, http } from 'mam-core-react/dist/modules/http'
import { IEntitiesReq, IEntitiesRes, IRemoveEntityReq, IUpdateKeyframeReq ,IGetBreadcrumbRes} from 'src/types/iAlbumDetailTypes'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'

namespace iAlbumDetailApi{
    export const queryEntities = (params: IEntitiesReq):AxiosPromise<IResponse<IEntitiesRes>> => {
        return http.post<IResponse<IEntitiesRes>>('/es/search/album/content', params)
    }

    export const removeEntity = (params: IRemoveEntityReq):AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/aialbum/remove', params)
    }

    export const updateKeyframe = (params: IUpdateKeyframeReq):AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>('/aialbum/keyframe', params)
    }

    export const getAialbumConvergConditions = (contentId: string):AxiosPromise<IResponse<IFormItem[]>> => {
        return http.get<IResponse<IFormItem[]>>(`/aialbum/${contentId}/converg-conditions`)
    }

    export const updateAialbumConvergConditions = (contentId: string, params: IFormItem[]):AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/aialbum/${contentId}/converg-conditions`, params)
    }
    export const getBreadcrumb = (contentId: string):AxiosPromise<IResponse<IGetBreadcrumbRes[]>> => {
        return http.get<IResponse<IGetBreadcrumbRes[]>>(`/aialbum/bread-crumbs/${contentId}`)
    }
}

export default iAlbumDetailApi