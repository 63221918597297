import { BaseStore } from 'mam-core-react';
import { Stores } from 'src/stores';
import { observable, action } from 'mobx';
import SmartViewStore from './smartViewStore';

export interface IAutoReplaceItem{
    searchContent: string
    replaceContent: string
}

export default class RegularSettingStore extends BaseStore<Stores> {
    @observable
    public autoIgnoreTxt: string;
    @observable
    public autoReplaceList: IAutoReplaceItem[] = [{searchContent: '', replaceContent: ''}]

    private parentStore: SmartViewStore;

    public constructor(stores: Stores, parentStore: SmartViewStore){
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public setAutoIgnoreTxt(autoIgnoreTxt: string){
        this.autoIgnoreTxt = autoIgnoreTxt;
    }

    @action
    public setAutoReplace(key: string, value: string, index: number){
        this.autoReplaceList[index][key] = value;
        if (this.autoReplaceList.length - 1 === index){//如果编辑的是最后一行，则新增一行
            this.autoReplaceList.push({searchContent: '', replaceContent: ''});
        }
    }
}