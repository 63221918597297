import { observable, action, runInAction } from 'mobx';
import favoriteTypes from 'src/types/favoriteTypes';
import ccTypes from 'src/types/ccTypes';
import { Stores } from '..';
import CcStore from './ccStore';
import favoriteApi from 'src/apis/favoriteApi';
import {l} from "mam-core-react";
import _ from "lodash";

export default class FavoriteStore extends CcStore {
    @observable
    public params: favoriteTypes.IFavoriteParams;
    @observable
    public fullSearchData?: favoriteTypes.IFavoritePageResponse
    @observable
    public tagData?: favoriteTypes.ITag[]
    @observable
    public fullSearchReq: favoriteTypes.IQueryFullDataRequest

    public sortItems: ccTypes.searchTypes.ISortItem[] = [
        { text: l('favorite.timeDesc', '收藏时间降序'), field: 'collectiontime', desc: 'desc' },
        { text: l('favorite.timeAsc', '收藏时间升序'), field: 'collectiontime', desc: 'asc' }
    ]
    /** 当前选中排序类型 */
    @observable
    public currentSort: ccTypes.searchTypes.ISortItem = this.sortItems[0]

    @observable
    public searchKeyword1: string = '';  // 主搜索框值

    constructor(stores: Stores) {
        super(stores)
        this.initSelectAllStore(stores, 'stores.favoriteStore.fullSearchData.data', 'selected')
        this.initOutstoreStore(stores);
        this.initCopyStore(stores);
        this.initDownloadStore(stores);
        this.initCreatePlanningStore(stores);
        this.initDeleteStore(stores);
        this.initAdvancedSearchStore(stores);
        this.initBindBCProgramCodeStore(stores);
        
        this.favoriteStore.onFavoriteCallback = () => { setTimeout(() => { this.refresh() }, 500) }
    }

    @action
    public setSearchKeyword1(str: string) {
        this.searchKeyword1 = str;
    }

    @action
    public setParams(params: favoriteTypes.IFavoriteParams) {
        this.params = params;
    }

    @action
    public setCurrentSort(sort: ccTypes.searchTypes.ISortItem) {
        this.currentSort = sort;
    }

    @action
    public refresh = () => {
        this.queryFullData().then(() => { }, () => { });
        this.queryTags()
    }

    @action
    public queryTags = () => {
        if (!this.stores.configStore.config.favoriteTagEnable) {
            return
        }
        favoriteApi.queryTags().then((res) => {
            runInAction(() => {
                if (res.data.success && res.data.data && res.data.data.tags) {
                    this.tagData = res.data.data.tags;
                }
            });
        })
    }

    @action
    public queryFullData(page?: number) {
        this.selectAllStore.switchAllSelected(false);
        this.fullSearchData = undefined;
        let param = this.params || {};
        this.fullSearchReq  = {
            request: {
                pageIndex: page || param.page || 1,
                pageSize: param.pageSize || 20,
                sort: param.sort ? param.sort : this.currentSort.field,
                order: param.order ? param.order : this.currentSort.desc,
                keyword: param.keyword || ''
            },
            tagCondition: param.tagCondition || []
        }
        // this.fullSearchReq = req
        if(this.stores.configStore.config && this.stores.configStore.config.searchResultNum){
            this.fullSearchReq.request.pageSize = this.stores.configStore.config.searchResultNum
        }
        if (this.params.advancedSearch && this.params.advancedSearch.field.length > 0 && this.params.advancedSearch.isOnlyAvSearching) {
            this.fullSearchReq.request.advancedSearch = this.params.advancedSearch
        }
        // let url =  this.stores.configStore.config.wuXiCustomSwitch ? '/wx/favorite/query' : '/favorite/query/full'
        let url = '/favorite/query/full'
        return new Promise((resolve, reject) => {
            favoriteApi.queryPageData(url,this.fullSearchReq).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        /** 无锡定制：根据素材id获取云端高低码状态 */
                        if(this.stores.configStore.config.pushPullSwitch){
                            this.getProgramNameCloud(res.data.data.data)
                        }
                        this.fullSearchData = res.data.data;
                    }
                });
                resolve();
            }, () => {
                reject();
            })
        });
    }

    /** 设置收藏状态 */
    @action
    public setItemFavorite(item: any, favorite: boolean){
        if (this.fullSearchData && this.fullSearchData && this.fullSearchData.data){
            let target = _.find(this.fullSearchData.data, {contentId: item.contentId});
            if (target){
                target.isFavorite = favorite;
                target.isCollected = favorite;
            }
        }
        item.isFavorite = favorite;
    }
}
