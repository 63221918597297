import BaseStore from "../../../base/baseStore";
import _ from "lodash";
import entityTypes from "../../../../types/entityTypes";
import {action, computed, observable, runInAction} from "mobx";
import {SmartMetadataType} from "../../extends/smartViewStore";
import {message} from "antd";
import {Stores} from "../../../index";
import entityApi from "../../../../apis/entityApi";
import EntityStore from "../../entityStore";
import CatalogStore from "../../../catalog/catalogStore";
import {defaultMetaField} from "../defaultMetaField";
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import {sensitiveReg} from "mam-metadata-react/dist/controls/mfcTag/mfcTag";
import MetadataOpeStore from './metadataOpeStore';
import IEntityStore from 'src/stores/iEntity/iEntityStore';

export interface IAddPartParam{
    title?: string
    inpoint?: number
    outpoint?:number
}

export interface IModifyPartParam{
    title?: string
    keyword?: string
    inpoint?: number
    outpoint?:number
}

export default abstract class CatalogBaseStore extends BaseStore<Stores>{
    /** story和scene接口原始数据 */
    @observable
    public storyMetaList: entityTypes.contentIndex.IMetaOriginal[];
    @observable
    public sceneMetaList: entityTypes.contentIndex.IMetaOriginal[];
    @observable
    public shotMetaList: entityTypes.contentIndex.IMetaOriginal[];
    /** 片段入出点信息 */
    @observable
    public entityMetaList: entityTypes.contentIndex.ISmartEntity[];
    /** story和scene转化为元数据结构类型 */
    @observable
    public metaInfoList: entityTypes.contentIndex.IMetaInfo[]
    /** 展开的片段id集合 */
    @observable
    public expandPartIds: string[] = [];
    /** 当前全部展开的类型 */
    @observable
    public currentExpandAllType?: SmartMetadataType;
    /** 选中的片段id集合 */
    @observable
    public checkedPartIds: string[] = [];
    /** 是否正在加载 */
    @observable
    public isLoadingStructureMetadatas = true;
    /** 元数据相关扩展操作 */
    public metadataOpeStore: MetadataOpeStore;

    public constructor(stores: Stores) {
        super(stores);
        this.metadataOpeStore = new MetadataOpeStore(stores, this);
    }

    /** 获取当前story */
    @computed
    public get currentStory(): entityTypes.contentIndex.IMetaOriginal | undefined{
        const storyId = this.getCurrentStore().params.storyId;
        if (storyId){
            return _.find(this.storyMetaList, {storyid: storyId});
        }
        else{
            //shot找story
            let currentShot = this.currentShot;
            if (currentShot){
                let scene = this.getParent(currentShot);
                if (scene){
                    return this.getParent(scene);
                }
            }
            else {
                //scene找story
                let currentScene = this.currentScene;
                if (currentScene){
                    return this.getParent(currentScene);
                }
            }
        }
        return undefined;
    }
    /** 获取当前scene */
    @computed
    public get currentScene(): entityTypes.contentIndex.IMetaOriginal | undefined{
        const sceneId = this.getCurrentStore().params.sceneId;
        if (sceneId){
            return _.find(this.sceneMetaList, {sceneid: sceneId});
        }
        else{
            //shot找scene
            let currentShot = this.currentShot;
            if (currentShot){
                return this.getParent(currentShot);
            }
        }
        return undefined;
    }
    /** 获取当前shot */
    @computed
    public get currentShot(): entityTypes.contentIndex.IMetaOriginal | undefined{
        const shotId = this.getCurrentStore().params.shotId;
        if (shotId){
            return _.find(this.shotMetaList, {shotid: shotId});
        }
        return undefined;
    }

    /** 获取当前片段 */
    @computed
    public get currentMeta(): entityTypes.contentIndex.IMetaOriginal | undefined{
        const currentShot = this.currentShot;
        if (currentShot){
            return currentShot;
        }
        else{
            let currentScene = this.currentScene;
            if (currentScene){
                return currentScene;
            }
            else{
                let currentStory = this.currentStory;
                if (currentStory){
                    return currentStory;
                }
            }
        }
        return undefined;
    }

    @action
    public handleSmartMetadatas(metadatas: any[]) {
        this.metadataOpeStore.selectedTag = undefined;
        _.forEach(metadatas, (item) => {
            switch (item.type) {
                case SmartMetadataType.story:
                    this.storyMetaList = _.orderBy(commonUtil.copyObj(item.metadata), ['inpoint'], ['asc']);
                    if (this.storyMetaList){
                        this.storyMetaList.forEach((part)=>{
                            part.partType = SmartMetadataType.story;
                        });
                    }
                    else {
                        this.storyMetaList = [];
                    }
                    break;
                case SmartMetadataType.scene:
                    this.sceneMetaList = _.orderBy(commonUtil.copyObj(item.metadata), ['inpoint'], ['asc']);
                    if (this.sceneMetaList){
                        this.sceneMetaList.forEach((part)=>{
                            part.partType = SmartMetadataType.scene;
                        });
                    }
                    else {
                        this.sceneMetaList = [];
                    }
                    break;
                case SmartMetadataType.shot:
                    this.shotMetaList = _.orderBy(commonUtil.copyObj(item.metadata), ['inpoint'], ['asc']);
                    if (this.shotMetaList){
                        this.shotMetaList.forEach((part)=>{
                            part.shotid = part.shotid ? part.shotid.toString() : '';
                            part.partType = SmartMetadataType.shot;
                        });
                    }
                    else {
                        this.shotMetaList = [];
                    }
                    break;
                case SmartMetadataType.entity:
                    this.entityMetaList = item.metadata;
                    break;
                default:
                    break;
            }
        });

        this.metaInfoList = [];
        if (this.storyMetaList){
            this.produceMeta(this.storyMetaList, SmartMetadataType.story);
        }
        if (this.sceneMetaList){
            this.produceMeta(this.sceneMetaList, SmartMetadataType.scene);
        }
        if (this.shotMetaList){
            this.produceMeta(this.shotMetaList, SmartMetadataType.shot);
        }
        //如果只有一个story，则默认展开
        if (this.storyMetaList && this.storyMetaList.length === 1){
            this.switchPartExpand(this.storyMetaList[0], true);
        }
    }

    @action
    public getStructureMetadatas(){
        this.isLoadingStructureMetadatas = true;
        let allMetadatas: entityTypes.contentIndex.IMetadata[] = [];
        const allP: Array<Promise<any>> = [];
        allP.push(new Promise(((resolve, reject) => {
            entityApi.getMetadataByType(this.getCurrentStore().params.contentId, SmartMetadataType.story).then(res=>{
                if (res.data.data && res.data.data.length > 0 && res.data.data[0].metadata){
                    allMetadatas = allMetadatas.concat(res.data.data);
                }
                resolve();
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            });
        })));
        allP.push(new Promise(((resolve, reject) => {
            entityApi.getMetadataByType(this.getCurrentStore().params.contentId, SmartMetadataType.scene).then(res=>{
                if (res.data.data && res.data.data.length > 0 && res.data.data[0].metadata){
                    allMetadatas = allMetadatas.concat(res.data.data);
                }
                resolve();
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            })
        })));
        allP.push(new Promise(((resolve, reject) => {
            entityApi.getMetadataByType(this.getCurrentStore().params.contentId, SmartMetadataType.shot).then(res=>{
                if (res.data.data && res.data.data.length > 0 && res.data.data[0].metadata){
                    allMetadatas = allMetadatas.concat(res.data.data);
                }
                resolve();
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            });
        })));
        return new Promise((resolve, reject)=>{
            Promise.all(allP).then(()=>{
                this.handleSmartMetadatas(allMetadatas);
                runInAction(()=>{
                    this.isLoadingStructureMetadatas = false;
                })
                resolve();
            });
        })
    }

    @action
    public getEntityMetadatas(){
        return new Promise((resolve, reject)=>{
            entityApi.getMetadataByType(this.getCurrentStore().params.contentId, SmartMetadataType.entity).then(res=>{
                if (res.data.data && res.data.data.length > 0 && res.data.data[0].metadata){
                    this.handleSmartMetadatas(res.data.data);
                }
                resolve();
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            });
        })
    }

    @action
    public switchPartExpand(part: entityTypes.contentIndex.IMetaOriginal, expand: boolean){
        if (part.partType === SmartMetadataType.story){
            if (expand){
                this.expandPartIds.push(part.storyid);
            }
            else {
                _.remove(this.expandPartIds, (o)=>{
                    return o === part.storyid
                })
            }
        }
        else if (part.partType === SmartMetadataType.scene){
            if (expand) {
                this.expandPartIds.push(part.sceneid as string);
            }
            else {
                _.remove(this.expandPartIds, (o)=>{
                    return o === part.sceneid
                })
            }
        }
        else if (part.partType === SmartMetadataType.shot){
            if (expand) {
                this.expandPartIds.push(part.shotid as string);
            }
            else {
                _.remove(this.expandPartIds, (o)=>{
                    return o === part.shotid
                })
            }
        }
    }
    public isExpand(part: entityTypes.contentIndex.IMetaOriginal): boolean{
        let id;
        if (part.partType === SmartMetadataType.story){
            id = _.find(this.expandPartIds, (o)=>{
                return o === part.storyid
            })
        }
        else if (part.partType === SmartMetadataType.scene){
            id = _.find(this.expandPartIds, (o)=>{
                return o === part.sceneid
            })
        }
        else if (part.partType === SmartMetadataType.shot){
            id = _.find(this.expandPartIds, (o)=>{
                return o === part.shotid
            })
        }
        return id !== undefined;
    }

    @action
    public expandAll(type: SmartMetadataType){
        if (this.currentExpandAllType && this.currentExpandAllType === type){//收起
            if (type === SmartMetadataType.shot){
                this.sceneMetaList.forEach((story)=>{
                    this.switchPartExpand(story, false);
                })
                this.currentExpandAllType = SmartMetadataType.scene;
            }
            else if (type === SmartMetadataType.scene){
                this.storyMetaList.forEach((story)=>{
                    this.switchPartExpand(story, false);
                })
                this.sceneMetaList.forEach((story)=>{
                    this.switchPartExpand(story, false);
                })
                this.currentExpandAllType = undefined;
            }
        }
        //当前展开是shot，选择story收起
        else if (this.currentExpandAllType && this.currentExpandAllType !== type && this.currentExpandAllType === SmartMetadataType.shot){
            this.storyMetaList.forEach((story)=>{
                this.switchPartExpand(story, false);
            })
            this.sceneMetaList.forEach((story)=>{
                this.switchPartExpand(story, false);
            })
            this.currentExpandAllType = undefined;
        }
        else {//展开
            this.currentExpandAllType = type;
            if (type === SmartMetadataType.shot){
                this.storyMetaList.forEach((story)=>{
                    this.switchPartExpand(story, true);
                })
                this.sceneMetaList.forEach((story)=>{
                    this.switchPartExpand(story, true);
                })
            }
            else if (type === SmartMetadataType.scene){
                this.storyMetaList.forEach((story)=>{
                    this.switchPartExpand(story, true);
                })
            }
        }
    }

    public getSceneByStory(story: entityTypes.contentIndex.IMetaOriginal): entityTypes.contentIndex.IMetaOriginal[]{
        if (!this.sceneMetaList){
            return [];
        }
        return this.sceneMetaList.filter((item)=>{
            return item.inpoint >= story.inpoint && item.outpoint <= story.outpoint;
        });
    }

    public getShotByScene(scene: entityTypes.contentIndex.IMetaOriginal): entityTypes.contentIndex.IMetaOriginal[]{
        if (!this.shotMetaList){
            return [];
        }
        return this.shotMetaList.filter((item)=>{
            return item.inpoint >= scene.inpoint && item.outpoint <= scene.outpoint;
        });
    }

    public getShotByStory(story: entityTypes.contentIndex.IMetaOriginal): entityTypes.contentIndex.IMetaOriginal[]{
        if (!this.shotMetaList){
            return [];
        }
        return this.shotMetaList.filter((item)=>{
            return item.inpoint >= story.inpoint && item.outpoint <= story.outpoint;
        });
    }

    /** 获取当前片段的所有列表 */
    public getMetaListByPart(part: entityTypes.contentIndex.IMetaOriginal): entityTypes.contentIndex.IMetaOriginal[]{
        if (part.partType === SmartMetadataType.story){
            return this.storyMetaList;
        }
        else if (part.partType === SmartMetadataType.scene){
            return this.sceneMetaList;
        }
        else{
            return this.shotMetaList;
        }
    }

    public getPartIndex(part: entityTypes.contentIndex.IMetaOriginal): number{
        let index: number = -1;
        if (part.partType === SmartMetadataType.story){
            index = _.findIndex(this.storyMetaList, {storyid: part.storyid});
        }
        else if (part.partType === SmartMetadataType.scene){
            index = _.findIndex(this.sceneMetaList, {sceneid: part.sceneid});
        }
        else if (part.partType === SmartMetadataType.shot){
            index = _.findIndex(this.shotMetaList, {shotid: part.shotid});
        }
        return index;
    }

    public isParentOf(part: entityTypes.contentIndex.IMetaOriginal, parent: entityTypes.contentIndex.IMetaOriginal): boolean{
        return parent.inpoint <= part.inpoint && parent.outpoint >= part.outpoint;
    }

    public getParent(part: entityTypes.contentIndex.IMetaOriginal): entityTypes.contentIndex.IMetaOriginal | undefined{
        let parent: entityTypes.contentIndex.IMetaOriginal | undefined;
        if (part.partType === SmartMetadataType.shot){
            parent = _.find(this.sceneMetaList, (item)=>{
                return item.inpoint <= part.inpoint && item.outpoint >= part.outpoint;
            })
        }
        else if (part.partType === SmartMetadataType.scene){
            parent = _.find(this.storyMetaList, (item)=>{
                return item.inpoint <= part.inpoint && item.outpoint >= part.outpoint;
            })
        }
        return parent;
    }

    /** 获取上一个片段 */
    public getPrev(part: entityTypes.contentIndex.IMetaOriginal): entityTypes.contentIndex.IMetaOriginal | undefined{
        let prev: entityTypes.contentIndex.IMetaOriginal | undefined;
        let prevIdx: number;
        if (part.partType === SmartMetadataType.scene){
            let parentStory = this.getParent(part);
            let currSceneList: entityTypes.contentIndex.IMetaOriginal[] = [];
            if (parentStory){
                currSceneList = this.getSceneByStory(parentStory);
            }
            if (currSceneList){
                prevIdx = _.findIndex(currSceneList, (item)=>{
                    return item === part;
                })
                if (prevIdx === 0){
                    prev = undefined;
                }
                else{
                    prev = currSceneList[prevIdx - 1];
                }
            }
        }
        else if (part.partType === SmartMetadataType.story){
            prevIdx = _.findIndex(this.storyMetaList, (item)=>{
                return item === part;
            });
            if (prevIdx === 0){
                prev = undefined;
            }
            else{
                prev = this.storyMetaList[prevIdx - 1];
            }
        }
        return prev;
    }

    /** 获取下一个片段 */
    public getNext(part: entityTypes.contentIndex.IMetaOriginal): entityTypes.contentIndex.IMetaOriginal | undefined{
        let next: entityTypes.contentIndex.IMetaOriginal | undefined;
        let nextIdx: number;
        if (part.partType === SmartMetadataType.scene){
            let parentStory = this.getParent(part);
            let currSceneList: entityTypes.contentIndex.IMetaOriginal[] = [];
            if (parentStory){
                currSceneList = this.getSceneByStory(parentStory);
            }
            if (currSceneList){
                nextIdx = _.findIndex(currSceneList, (item)=>{
                    return item === part;
                })
                if (nextIdx === currSceneList.length - 1){
                }
                else{
                    next = currSceneList[nextIdx + 1];
                }
            }
        }
        else if (part.partType === SmartMetadataType.story){
            nextIdx = _.findIndex(this.storyMetaList, (item)=>{
                return item === part;
            })
            if (nextIdx === this.storyMetaList.length - 1){
            }
            else{
                next = this.storyMetaList[nextIdx + 1];
            }
        }
        return next;
    }

    @action
    public checkAllParts(check: boolean) {
        this.storyMetaList.forEach((story) => {
            this.switchPartCheckStatus(story, check);
        });
        this.sceneMetaList.forEach((scene) => {
            this.switchPartCheckStatus(scene, check);
        });
        this.shotMetaList.forEach((shot) => {
            this.switchPartCheckStatus(shot, check);
        });
    }

    /** 切换片段是否check状态 */
    @action
    public switchPartCheckStatus(part: entityTypes.contentIndex.IMetaOriginal, check: boolean){
        if (part.partType === SmartMetadataType.story){
            if (check){
                this.checkedPartIds.push(part.storyid);
            }
            else {
                _.remove(this.checkedPartIds, (o)=>{
                    return o === part.storyid
                })
            }
        }
        else if (part.partType === SmartMetadataType.scene){
            if (check) {
                this.checkedPartIds.push(part.sceneid as string);
            }
            else {
                _.remove(this.checkedPartIds, (o)=>{
                    return o === part.sceneid
                })
            }
        }
        else if (part.partType === SmartMetadataType.shot){
            if (check) {
                this.checkedPartIds.push(part.shotid as string);
            }
            else {
                _.remove(this.checkedPartIds, (o)=>{
                    return o === part.shotid
                })
            }
        }
    }
    public isChecked(part: entityTypes.contentIndex.IMetaOriginal): boolean{
        let id: number = -1;
        if (part.partType === SmartMetadataType.story){
            id = _.findIndex(this.checkedPartIds, (o)=>{
                return o === part.storyid
            })
        }
        else if (part.partType === SmartMetadataType.scene){
            id = _.findIndex(this.checkedPartIds, (o)=>{
                return o === part.sceneid
            })
        }
        else if (part.partType === SmartMetadataType.shot){
            id = _.findIndex(this.checkedPartIds, (o)=>{
                return o === part.shotid
            })
        }
        return id > -1;
    }

    /** 获取选中的片段 */
    public getCheckedParts(): entityTypes.contentIndex.IMetaOriginal[]{
        let selectedParts: entityTypes.contentIndex.IMetaOriginal[] | undefined;
        if (this.storyMetaList) {
            selectedParts = this.storyMetaList.filter((story)=>{
                return this.isChecked(story);
            });
        }
        if ((!selectedParts || selectedParts.length === 0) && this.sceneMetaList){
            selectedParts = this.sceneMetaList.filter((scene)=>{
                return this.isChecked(scene);
            });
            if ((!selectedParts || selectedParts.length === 0) && this.shotMetaList) {
                selectedParts = this.shotMetaList.filter((shot) => {
                    return this.isChecked(shot);
                });
            }
        }
        selectedParts = _.orderBy(selectedParts, ['inpoint'], ['asc']);//按入点排序
        return selectedParts;
    }

    /**
     * 新增片段信息
     */
    @action
    public addPart(part: entityTypes.contentIndex.IMetaOriginal, param: IAddPartParam): boolean{
        return true;
    }
    /**
     * 修改片段信息
     *
     * @return 是否修改成功
     */
    @action
    public modifyPart(part: entityTypes.contentIndex.IMetaOriginal, param: IModifyPartParam): boolean{
        let metaInfo = this.getMetaInfoByPart(part);
        if (param.title){
            if (part.partType === SmartMetadataType.story){//story
                part.title = param.title;
            }
            else if (part.partType === SmartMetadataType.scene) {
                part.scenename = param.title;
            }
            else {
                part.title = param.title;
            }
        }
        if (param.inpoint && param.outpoint){
            return this.modifyInoutpoint(part, param.inpoint, param.outpoint);
        }
        if (param.keyword){
            part.keyword = param.keyword;
            if (metaInfo){
                let keywordMetas = commonUtil.copyObj(metaInfo.metadata);
                let keywordMeta = _.find(keywordMetas, {fieldName: 'keyword'});
                if (keywordMeta){
                    keywordMeta.value = part.keyword;
                }
                metaInfo.metadata = keywordMetas;
            }
        }
        return true;
    }

    @action
    public removePart(part: entityTypes.contentIndex.IMetaOriginal): boolean{
        if (part.partType === SmartMetadataType.story){
            _.remove(this.storyMetaList, {storyid: part.storyid});
        }
        else if (part.partType === SmartMetadataType.scene) {
            _.remove(this.sceneMetaList, {sceneid: part.sceneid});
        }
        else{
            _.remove(this.shotMetaList, {shotid: part.shotid});
        }
        return true;
    }

    /** 获取当前part的标签元数据信息 */
    public getMetaInfoByPart(part: entityTypes.contentIndex.IMetaOriginal){
        let metaInfo;
        const metaInfoList = this.metaInfoList;
        if (part.partType === SmartMetadataType.story){
            metaInfo = _.find(metaInfoList, {id: part.storyid});
        }
        else if (part.partType === SmartMetadataType.scene){
            metaInfo = _.find(metaInfoList, {id: part.sceneid});
        }
        else if (part.partType === SmartMetadataType.shot){
            metaInfo = _.find(metaInfoList, {id: part.shotid});
        }
        return metaInfo;
    }

    /** 用元数据信息获取片段原始数据 */
    public getOriginalMetaByMetaInfo(metaInfo: entityTypes.contentIndex.IMetaInfo){
        let orimeta: entityTypes.contentIndex.IMetaOriginal | undefined;
        if (metaInfo.type === SmartMetadataType.story){
            orimeta = _.find(this.storyMetaList, {storyid: metaInfo.id});
        }
        else if (metaInfo.type === SmartMetadataType.scene) {
            orimeta = _.find(this.sceneMetaList, {sceneid: metaInfo.id});
        }
        else{
            orimeta = _.find(this.shotMetaList, {shotid: metaInfo.id});
        }
        return orimeta;
    }

    /**
     * 修改元数据信息
     *
     * @param metaInfo entityTypes.contentIndex.IMetaOriginal转换的元数据结构
     * @param form ref to MetadataForm
     */
    @action
    public updateOriginalMetaByForm(metaInfo: entityTypes.contentIndex.IMetaInfo,
                              form: IFormItem[], isCatalogue: boolean){
        let orimeta: entityTypes.contentIndex.IMetaOriginal | undefined = this.getOriginalMetaByMetaInfo(metaInfo);
        if (!orimeta){
            return Promise.reject();
        }
        let meta;
        form.forEach((item)=>{
            if (orimeta){
                //更新原始数据
                orimeta[item.fieldName] = item.value;
            }
            //更新元数据信息
            meta = _.find(metaInfo.metadata, {fieldName: item.fieldName});
            if (meta){
                meta.value = item.value;
            }
        });
        return this.updateOriginalMeta(1, orimeta, isCatalogue)
    }

    /**
     * 修改元数据信息
     *
     * @param operaType 1：修改；2：删除
     */
    public updateOriginalMeta(operaType: number, orimeta: entityTypes.contentIndex.IMetaOriginal, isCatalogue: boolean,
                              showSuccess?: boolean){
        return new Promise((resolve, reject)=> {
            const contentId = this.getCurrentStore().entity.contentId;
            const site = this.getCurrentStore().params.site;
            const request: entityTypes.characterRecognitionTypes.ISmartMetadatasRequest = {
                'contentId': contentId,
                'type': orimeta.partType,
                operaType,
                metadata: orimeta,
                isCatalogue
            }
            entityApi.updateIntelligentResult(request, site, this.stores.catalogStore.params ? this.stores.catalogStore.params.isCatalog : 'false').then(res => {
                if (res.data.success) {
                    if (showSuccess === undefined || showSuccess === true){
                        if (operaType === 1){
                            message.success('修改成功！'.l('entity.modifySuccess'));
                        }
                        else if (operaType === 2){
                            message.success('删除成功！'.l('entity.deleteSuccess'));
                        }
                    }
                    resolve();
                }
                else if (res.data.error) {
                    message.error(res.data.error.title)
                    reject();
                }
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                    reject();
                }
            })
        });
    }

    /** 
     * 保存所有语言信息,只需传入type，就可以把所有前端的metadata保存到后台
     * 
     * @param type 要保存的metadata type
     */
    public patchUpdateOriginalMeta(type: SmartMetadataType){
        const entity = this.getCurrentStore().entity;
        const smartViewStore = this.getCurrentStore().smartViewStore;
        const site = this.getCurrentStore().params.site;
        const allVoiceMetadatas: entityTypes.SmartInfo.ISmartMetadatasVoiceMetadata[] 
        = commonUtil.copyObj(smartViewStore.smartMetadatas[type].metadata as entityTypes.SmartInfo.ISmartMetadatasVoiceMetadata[]);
        const req: entityTypes.SmartInfo.IPatchUpdateIntelligentReq = {
            contentId: entity.contentId,
            'type': type,
            operaType: 3,
            metadatas: allVoiceMetadatas
        }
        entityApi.patchUpdateIntelligentResult(req, site).then(res => {
            if (res.data.success) {
                message.success('修改成功！'.l('entity.modifySuccess'));
            }
            else if (res.data.error) {
                message.error(res.data.error.title)
            }
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title)
            }
        });
    }

    /** 将fieldName转换成后端需要用的type参数 */
    public getTypeByFieldName(fieldName: string): string{
        let ret = '';
        switch (fieldName){
            case 'eventpeople':
            case 'scenepeople':
            case 'relatedperson':
            case 'reporter':
            case 'face':
            case 'host':
                ret = 'NAME';
                break;
            case 'eventplace':
            case 'sceneplace':
            case 'district':
                ret = 'LOC';
                break;
            case 'eventorg':
            case 'sceneorg':
                ret = 'ORG';
                break;
            case 'eventdate':
            case 'scenedate':
                ret = 'TIME';
                break;
            case 'audio_category':
                ret = 'audio_category';
                break;
            default: break;
        }
        return ret;
    }

    /** 从metadata-entity中获取用户id信息 */
    public getPersonIdFromEntity(name: string): string{
        const nameEntity = _.find(this.entityMetaList, {type: 'NAME'});
        if (nameEntity){
            const personInfo = _.find(nameEntity.entitydata, (ed)=>{
                return ed.name.replace(sensitiveReg, '') === name.replace(sensitiveReg, '');
            })
            if (personInfo){
                return personInfo.ids;
            }
            return '';
        }
        return '';
    }

    protected abstract getCurrentStore(): EntityStore | IEntityStore | CatalogStore;

    /** 获取片段元数据配置 */
    private getMetaFields(type: SmartMetadataType): IFormItem[]{
        let catalogCode = type.substring(type.lastIndexOf('_') + 1);
        if (catalogCode === 'story'){//暂时将story转换成sequence
            catalogCode = 'sequence';
        }
        if ((this.getCurrentStore() as EntityStore).mediaStore && (this.getCurrentStore() as EntityStore).mediaStore.catalog){
            let catalogInfo = _.find((this.getCurrentStore() as EntityStore).mediaStore.catalog, {code: catalogCode})
            if (catalogInfo){
                return catalogInfo.fields;
            }
        }
        else if (!(this.getCurrentStore() as EntityStore).mediaStore && (this.getCurrentStore() as CatalogStore).catalog){
            let catalogInfo = _.find((this.getCurrentStore() as CatalogStore).catalog, {code: catalogCode})
            if (catalogInfo){
                return catalogInfo.fields;
            }
        }
        return defaultMetaField;
    }

    private produceMetaSingle(meta: entityTypes.contentIndex.IMetaOriginal, type: SmartMetadataType){
        let metaInfo: entityTypes.contentIndex.IMetaInfo = {
            id: meta.shotid || meta.sceneid || meta.storyid,
            metadata: [],
            'type': type
        }
        if (meta.partType === SmartMetadataType.shot){
            metaInfo.id = meta.shotid as string;
        }
        else if (meta.partType === SmartMetadataType.scene){
            metaInfo.id = meta.sceneid as string;
        }
        else {
            metaInfo.id = meta.storyid;
        }
        metaInfo.metadata = this.getMetaFields(type).filter((field)=>{
            if (meta.partType === SmartMetadataType.scene && field.fieldName === 'title'){
                return false;
            }
            else if (meta.partType !== SmartMetadataType.scene && field.fieldName === 'scenename'){
                return false;
            }
            //return meta[field.fieldName] !== undefined;
            return true;
        }).map((field)=>{
            let f = commonUtil.copyObj(field);
            f.value = meta[field.fieldName];
            return f;
        })
        return metaInfo;
    }
    private produceMeta(metaList: any[], type: SmartMetadataType){
        metaList.forEach((meta)=>{
            this.metaInfoList.push(this.produceMetaSingle(meta, type));
        });
    }

    /**
     * 修改片段入出点
     *
     * @return 是否修改成功
     */
    private modifyInoutpoint(part: entityTypes.contentIndex.IMetaOriginal, inpoint: number, outpoint: number): boolean{
        let metaList: entityTypes.contentIndex.IMetaOriginal[];
        let currIdx: number;
        if (!part.sceneid){//story
            metaList = this.storyMetaList;
            currIdx = _.findIndex(metaList, {storyid: part.storyid});
        }
        else {
            metaList = this.sceneMetaList;
            currIdx = _.findIndex(metaList, {sceneid: part.sceneid});
        }
        //判断是否超出上一个相邻片段的入点
        let lastPart = metaList[currIdx - 1];
        if (lastPart){
            if (lastPart.inpoint > inpoint){
                message.error('设置入点已经超过上一个片段 ${name} 的入点!'.l('newEntity.inpointIsLessThanLast', {
                    name: lastPart.title || lastPart.scenename
                }));
                return false;
            }
            else {
                lastPart.outpoint = inpoint;
            }
        }
        //判断是否超出下一个相邻节点的出点
        let nextPart = metaList[currIdx + 1];
        if (nextPart){
            if (nextPart.outpoint < outpoint){
                message.error('设置出点已经超过下一个片段 ${name} 的出点！'.l('newEntity.outpointIsGreaterThanNext', {
                    name: nextPart.title || nextPart.scenename
                }));
                return false;
            }
            else {
                nextPart.inpoint = outpoint;
            }
        }
        part.inpoint = inpoint;
        part.outpoint = outpoint;
        return true;
    }
}