import ExtendStore from '../base/extendStore';
import { action, observable, runInAction } from 'mobx';
import _ from 'lodash'
import { IPortletItem } from 'src/types/portletTypes';
import portletApi from 'src/apis/portletApi';
import { message } from 'antd';

export default class PortletStore extends ExtendStore {
    @observable
    public portletList: IPortletItem[] = [];

    @action
    public queryPortlet(pagename: string){
        this.portletList = []
        portletApi.getLayout(pagename).then(res=>{
            runInAction(()=>{
               if (res.data.data){
                   res.data.data = _.orderBy(res.data.data, ['ordinal'], ['desc'])
                   this.portletList = res.data.data
               } 
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        });
    }
}