import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import {IResponse} from "mam-core-react/dist/modules/http";
import iAdvancedSearchTypes from 'src/types/iAdvancedSearchTypes'

namespace iAdvancedSearchApi{
    export const getFields = ():AxiosPromise<IResponse<iAdvancedSearchTypes.IField[]>> => {
        return http.get<IResponse<iAdvancedSearchTypes.IField[]>>('/es/search/config/advanced-conditions');
    }
}

export default iAdvancedSearchApi