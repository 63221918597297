import { action, observable, runInAction } from 'mobx';
import datasetDetailsApi from 'src/apis/datasetDetailsApi';
import { message } from 'antd'
import { Stores } from 'src/stores';
import DatasetDetailsStore from '../../datasetDetailsStore';
import datasetDetailsTypes from 'src/types/datasetDetailsTypes';
import ExtendStore from 'src/stores/base/extendStore';

export default class TreeStore extends ExtendStore {
    
    public datasetDetailsStore: DatasetDetailsStore
    @observable
    public TreeDataArr: datasetDetailsTypes.ITreeItem[]=[]//左侧树菜单
    @observable
    public expandedKeys: string[] = []//左侧树菜单展开key
    @observable
    public selectedKeys: string[] = []//左侧树菜单选中的key
    @observable
    public loadedKeys: string[] = []//加载过的key
    @observable
    public pathArray: datasetDetailsTypes.IPathArray[]=[]//面包屑路径
    public constructor(stores: Stores, datasetDetailsStore: DatasetDetailsStore) {
        super(stores);
        this.datasetDetailsStore = datasetDetailsStore;
    }
    @action
    public setTreeDataArr = (arr: datasetDetailsTypes.ITreeItem[]) => {
        this.TreeDataArr = arr
    }
    @action
    public setPathArray = (arr: datasetDetailsTypes.IPathArray[]) => {
        this.pathArray = arr
    }
    @action
    public setLoadedKeys = (loadedKeys: string[]) => {
        this.loadedKeys=loadedKeys
    }
    @action
    public setExpandedKeys = (expandedKeys: string[]) => {
        this.expandedKeys = expandedKeys
    }
    @action
    public setSelectedKeys = (selectedKeys: string[]) => {
        this.selectedKeys = selectedKeys
    }
    @action
    public pushExpandedKeys = (keyword: string) => {
        this.expandedKeys = [...this.expandedKeys,keyword]
    }
    //查询左侧树
    @action
    public queryTreeData = (params: datasetDetailsTypes.ITreeParams, key:string) => {
        return new Promise((resolve, reject) => {
            datasetDetailsApi.getTree(`datasetId=${params.datasetId}`).then(res => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        res.data.data.map((v, i) => {
                            v.title = v.name
                            v.key = key + "-" + v.contentId
                            v.isLeaf = v.type === "folder" ? false : true
                        })
                        resolve(res.data.data)
                    } else {
                        reject(false)
                    }
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject(false)
            })
        })
    }
    //添加
    @action
    public add=(params:datasetDetailsTypes.IAddParams)=>{
        datasetDetailsApi.add(params).then(res => {
            if (res.data.success && res.data.data) {
                message.success("添加成功")
                let arr= this.findIndexArray(this.TreeDataArr,this.datasetDetailsStore.folderId?this.datasetDetailsStore.folderId:this.datasetDetailsStore.contentId, [])
                let newArr= {
                    contentId:res.data.data.contentId,
                    key:`${arr.length!==1?arr[arr.length-1].key:this.datasetDetailsStore.contentId}`+"-"+ res.data.data.contentId,
                    title: res.data.data.name,
                    name: res.data.data.name,
                    type: res.data.data.type,
                    isLeaf:false,
                    children:[]
                }
                if(arr[arr.length-1].children){
                    arr[arr.length-1].children.unshift(newArr)
                }else{
                    arr[arr.length-1].children=[newArr]
                }
                this.setTreeDataArr([...this.TreeDataArr])
                this.datasetDetailsStore.entitysDataStore.queryEntitysData(1)
                this.datasetDetailsStore.changeAddEditName("")
            } 
            }, res => {
            if(res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }
    //修改
    @action
    public edit=(params:datasetDetailsTypes.IEditFolderNameParams)=>{
        datasetDetailsApi.editFolderName(params).then(res => {
            if (res.data.success && res.data.data) {
                message.success("修改成功")
                let arr= this.findIndexArray(this.TreeDataArr,this.datasetDetailsStore.folderId,[]) 
                runInAction(() => {
                    arr[arr.length-1].title=params.dto.name
                })
                this.setTreeDataArr([...this.TreeDataArr])
                this.datasetDetailsStore.entitysDataStore.queryEntitysData(1)
                this.datasetDetailsStore.changeAddEditName("")
            } 
        }, res => {
            if(res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }
    //初始化左侧树
    @action
    public initializationTreeData = () => {
        this.datasetDetailsStore.treeStore.setTreeDataArr([]);
        this.datasetDetailsStore.treeStore.setSelectedKeys([])
        this.datasetDetailsStore.treeStore.setExpandedKeys([])
        this.datasetDetailsStore.treeStore.setLoadedKeys([])
        this.queryTreeData({ datasetId:this.datasetDetailsStore.contentId },this.datasetDetailsStore.contentId).then(res => {
            if(res){
                let rootNode = [{
                    title: this.datasetDetailsStore.title,
                    key: this.datasetDetailsStore.contentId,
                    contentId: this.datasetDetailsStore.contentId,
                    name: this.datasetDetailsStore.title,
                    type:"folder",
                    children:res as  datasetDetailsTypes.ITreeItem[],
                }]
            this.datasetDetailsStore.treeStore.setTreeDataArr(rootNode)
            this.setExpandedKeys([this.datasetDetailsStore.contentId])
            this.setSelectedKeys([this.datasetDetailsStore.contentId])
            }
        }).catch(err => {
            message.error(err)
        })
    }
   
    //根据id返回id所在位置路径
    @action
    public  findIndexArray = (data: datasetDetailsTypes.ITreeItem[], contentId:string, indexArray:any[]): datasetDetailsTypes.ITreeItem[] =>{
        let arr=Array.from(indexArray)
        for(let i=0 ,len=data.length;i<len;i++){
            arr.push(data[i]);
            if(data[i].contentId===contentId){
                return arr
            }
            let children=data[i].children;
            if(children&&children.length){
                let result=this.findIndexArray(children,contentId,arr);
                if(result){
                    return result
                }
            }
            arr.pop()
        }
        return []
    }

}