import CatalogTaskExtendStore from "./catalogTaskExtendStore"
import { observable, action, runInAction } from 'mobx'
import { Stores } from 'src/stores'
import ContentMgStore from '../../contentMgStore'
import contentMgApi from 'src/apis/contentMgApi'
import contentMgTypes from 'src/types/contentMgTypes'
import _ from 'lodash'
import { message } from 'antd'

export default class CatalogTaskStore extends CatalogTaskExtendStore {
    /* myCatalog */
    @observable
    public claimModalVisible: boolean = false  //认领
    @observable
    public assignModalVisible: boolean = false  //指派
    /* auditTask */
    @observable
    public rejectModalVisible: boolean = false //打回
    /* allTask */
    @observable
    public users: contentMgTypes.catalogTask.IUserRes

    /*公共-菜单导航*/
    @observable
    public mode: string = 'catalog'   //编目catalog/审核audit/全部all
    @observable
    public topNav: string = 'working'  //顶部导航菜单
    @observable
    public auditLevel: number = 1  //审核级别

    /*公共-列表*/
    @observable
    public listSelectedRowKeys: any = []
    @observable
    public listSelectedRows: any = []
    @observable
    public keyword: string = ''
    @observable
    public page: number = 1
    @observable
    public size: number = 20
    public pageSizes: number[] = [20, 50, 100, 200]
    @observable
    public currentSort: string = "claim_time:desc"  //desc降序asc升序
    @observable
    public params: contentMgTypes.catalogTask.IQueryReq
    @observable
    public queryListRes: contentMgTypes.catalogTask.IQueryRes
    @observable
    public isSearching: boolean = false
    @observable
    public catalogConfig: contentMgTypes.catalogTask.ICatalogConfig | undefined

    //固定的检索项
    @observable
    public fields: contentMgTypes.catalogTask.IFieldItem[] = [
        {
            fieldName: "栏目".l('entity.column'),
            fieldType: "column",
            value: "",
            options: [{ name: "全部".l('com.all'), value: "" }]
        },
        {
            fieldName: "频道".l('entity.channel'),
            fieldType: "channel",
            value: "",
            options: [{ name: "全部".l('com.all'), value: "" }]
        },
        {
            fieldName: "来源".l('entity.contentSource'),
            fieldType: "contentSource",
            value: "",
            options: [{ name: "全部".l('com.all'), value: "" }]
        },
        {
            fieldName: "分析状态".l('entity.analysisStatus'),
            fieldType: "smartstate",
            value: "",
            options: [
                { name: "全部".l('com.all'), value: "" },
                { name: '失败'.l('contentAudit.fail'), value: "-1" },
                { name: '未分析'.l('contentAudit.notAnalyzed'), value: "0" },
                { name: '分析中'.l('contentAudit.analyzing'), value: "1" },
                { name: '已完成'.l('contentAudit.finished'), value: "2" }
            ]
        },
    ]

    public constructor(stores: Stores, contentMgStore: ContentMgStore) {
        super(stores);
        this.initCatalogAdvancedSearchStore();
    }

    /* 设置过滤项值 */
    @action
    public setFieldsItem(fieldType: string, value: string) {
        let item = _.find(this.fields, { fieldType })
        if (item) {
            item.value = value
        }
    }
    /* 获取过滤项值 */
    @action
    public getFieldsItemValue(fieldType: string) {
        let item = _.find(this.fields, { fieldType })
        return item ? item.value : ""
    }
    @action
    public setAssignModalVisible(assignModalVisible: boolean) {
        this.assignModalVisible = assignModalVisible
    }
    @action
    public setRejectModalVisible(rejectModalVisible: boolean) {
        this.rejectModalVisible = rejectModalVisible
    }
    @action
    public setCurrentSort(currentSort: string) {
        this.currentSort = currentSort
    }
    @action
    public setMode(mode: string) {
        this.mode = mode
    }
    @action
    public setTopNav(topNav: string) {
        this.topNav = topNav
        this.clearSearch()
        this.initCurrentSort()
    }
    @action
    public initCurrentSort() {
        if (this.mode === 'catalog') {
            if (this.topNav === 'submited') {
                this.currentSort = 'commit_time:desc'
            } else if (this.topNav === 'rejected') {
                this.currentSort = 'reject_time:desc'
            } else if (this.topNav === 'waited') {
                this.currentSort = 'createTime:desc'
            } else {
                this.currentSort = 'claim_time:desc'
            }
        } else if (this.mode === 'audit') {
            if (this.topNav === 'submited') {
                this.currentSort = 'commit_time:desc'
            } else if (this.topNav === 'rejected') {
                this.currentSort = 'reject_time:desc'
            } else if (this.topNav === 'waited') {
                this.currentSort = 'updateTime:desc'
            } else {
                this.currentSort = 'claim_time:desc'
            }
        } else {
            //if(this.mode === 'all'){}
            this.currentSort = 'createTime:desc'
        }
    }
    @action
    public setAuditLevel(auditLevel: number) {
        this.auditLevel = auditLevel
    }
    @action
    public setKeyword(keyword: string) {
        this.keyword = keyword
    }
    @action
    public setPage(page: number) {
        this.page = page
    }
    @action
    public setSize(size: number) {
        this.size = size
        this.queryList()
    }
    /* 获取taskState //编目/审核任务中：状态： 10中、20已、30打回*/
    @action
    public getTaskState() {
        switch (this.topNav) {
            case "working": return 10;
            case "submited": return 20;
            case "rejected": return 30;
            default: return 10;
        }
    }
    /* 获取taskType //全部任务中：''全部 1进行中、2失败、3成功、4取消*/
    @action
    public getTaskType() {
        switch (this.topNav) {
            case "all": return '';
            case "unAssign": return '';
            case "doing": return 1;
            case "success": return 3;
            case "fail": return 2;
            case "cancel": return 4;
            default: return '';
        }
    }
    /* 清空筛选条件 */
    @action
    public clearSearch() {
        this.setKeyword('')
        this.fields.map((o) => {
            o.value = ""
        })
    }
    /* 设置查询参数 */
    @action
    public setParams(params: contentMgTypes.catalogTask.IQueryReq) {
        this.params = params
    }

    /*列表选中项keys及设置选中的id*/
    @action
    public setListSelectedRowKeys = (selectedRowKeys: any, selectedRows: any[]) => {
        this.listSelectedRowKeys = selectedRowKeys
        this.listSelectedRows = selectedRows
    }
    /*列表清空选中*/
    @action
    public clearListSelected = () => {
        this.listSelectedRowKeys = []
        this.listSelectedRows = []
    }

    /* 任务列表数据 */
    @action
    public queryList(page?: number) {
        this.clearListSelected()
        this.isSearching = true
        this.params = {
            keyword: this.keyword,
            pageIndex: page || this.page,
            pageSize: this.size,
            smartstate: this.getFieldsItemValue('smartstate') || '',
        }
        if (this.currentSort) {
            this.params.sort = this.currentSort.split(':')[0]
            this.params.order = this.currentSort.split(':')[1]
        } else {
            this.params.sort = 'claim_time'
            this.params.order = 'desc'
        }

        // todo-高级检索
        // let advancedConditions: contentMgTypes.catalogTask.ISearchCondition[]= []
        // if (this.catalogAdvancedSearchStore.conditions) {
        //     this.catalogAdvancedSearchStore.conditions.forEach((condition) => {
        //         if (condition.type !== 'advancedSearch') {
        //             return;
        //         }
        //         if (condition.items) {
        //             condition.items.forEach((n) => {
        //                 let value = n.value;
        //                 //日期参数处理
        //                 if (condition.fieldType === 'date' && value && value instanceof Array && value.length > 0) {
        //                     let valueArr = value[0].split(',');
        //                     if (valueArr && valueArr.length === 2) {
        //                         value = [`${valueArr[0]} 00:00:00 TO ${valueArr[1]} 23:59:59`]
        //                     }
        //                 }
        //                 advancedConditions.push({
        //                     field: n.field,
        //                     nexus: n.nexus,
        //                     value
        //                 });
        //             });
        //         }
        //     });
        // }
        // console.log(advancedConditions)
        //新接口：高级检索查询

        //以前的5个查询检索
        if (this.mode === 'catalog') {
            //编目/审核任务中：状态： 10中、20已、30打回
            this.params.taskState = this.getTaskState() || 10
            if (this.topNav === 'waited') {
                this.queryWaitedCatalogTask()
            } else {
                this.queryCatalogTask()
            }
        } else if (this.mode === 'audit') {
            //审核任务中：审核级别： 1、2、3，全部任务中：-1表示全部
            this.params.auditLevel = this.auditLevel || 1
            //编目/审核任务中：状态： 10中、20已、30打回
            this.params.taskState = this.getTaskState() || 10
            if (this.topNav === 'waited') {
                this.queryWaitedAuditTask()
            } else {
                this.queryAuditTask()
            }
        } else if (this.mode === 'all') {
            //this.params.auditLevel = -1 
            //全部任务中taskType：''全部 ''未分配  1进行中、2失败、3成功、4取消  //未分配还得传unAssign:true
            this.params.taskType = this.getTaskType() || ''
            //全部任务中增加栏目频道来源的筛选查询项
            this.params.column = this.getFieldsItemValue('column') || ''
            this.params.channel = this.getFieldsItemValue('channel') || ''
            this.params.contentSource = this.getFieldsItemValue('contentSource') || ''
            if (this.topNav === 'unAssign') {
                this.params.unAssign = true
            }
            this.queryTraceTask()
        }

    }

    /* 高级检索查询：我的编目任务、审核任务、全部任务--新接口，当开关开启才用，否则仍使用以前的5个查询接口 */
    @action
    public advancedSearchCatalogTask() {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.advancedSearchCatalogTask(this.params).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.queryListRes = res.data.data
                        this.isSearching = false
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        });
    }

    /* 编目任务：taskState?: number 状态： 10中、20已、30打回 */
    @action
    public queryCatalogTask() {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.queryCatalogTask(this.params).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.queryListRes = res.data.data
                        this.isSearching = false
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        });
    }
    /* 编目任务-待认领 */
    @action
    public queryWaitedCatalogTask() {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.queryWaitedCatalogTask(this.params).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.queryListRes = res.data.data
                        this.isSearching = false
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 审核任务：taskState?: number 状态： 10中、20已、30打回; auditLevel?: number //审核级别： 1、2、3，全部任务中：-1表示全部*/
    @action
    public queryAuditTask() {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.queryAuditTask(this.params).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.queryListRes = res.data.data
                        this.isSearching = false
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 审核任务-待认领 */
    @action
    public queryWaitedAuditTask() {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.queryWaitedAuditTask(this.params).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.queryListRes = res.data.data
                        this.isSearching = false
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 全部任务： taskType: number //全部任务中： taskType： ''全部 1进行中、2失败、3成功、4取消 //全部任务中：未分配 unAssign: true */
    @action
    public queryTraceTask() {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.queryTraceTask(this.params).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.queryListRes = res.data.data
                        this.isSearching = false
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /*认领编目任务，认领不传usercode,也就是分配给自己*/
    @action
    public claimCatalogTask(taskIds: number[]) {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.createMyTask(taskIds).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.success)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /*认领审核任务，认领不传usercode,也就是分配给自己*/
    @action
    public claimAuditTask(taskIds: number[], auditLevel: number) {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.createAuditTask(taskIds, auditLevel).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.success)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 取消认领我的编目任务 */
    @action
    public cancelMyCatalogTask(taskIds: number[]) {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.cancelMyCatalogTask(taskIds).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.success)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 取消认领审核任务 */
    @action
    public cancelAuditTask(taskIds: number[], auditLevel: number) {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.cancelAuditTask(taskIds, auditLevel).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.success)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /*分配编目任务，分配必须传usercode*/
    @action
    public assignCatalogTask(req: contentMgTypes.catalogTask.IAssignReq) {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.assignCatalogTask(req).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.success)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /*分配审核任务，分配必须传usercode*/
    @action
    public assignAuditTask(req: contentMgTypes.catalogTask.IAssignReq) {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.assignAuditTask(req).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.success)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /*失败任务重做*/
    @action
    public reworkTask(taskIds: number[]) {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.auditRework(taskIds).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.success)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /*取消审校*/
    @action
    public cancelReview(taskIds: number[]) {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.cancelReview(taskIds).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.success)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /*取消分配*/
    @action
    public cancelAssign(taskIds: number[]) {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.cancelAssign(taskIds).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.success)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /*取消审校，也就是取消编目流程*/
    @action
    public cancelCatalog(taskIds: number[]) {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.cancelCatalog(taskIds).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.success)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    /*给任务分配用户： 分配编目人员、一审、二审、三审人员*/
    @action
    public assignUsers(req: contentMgTypes.catalogTask.IAssignUsersReq) {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.assignUsers(req).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.success)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    /* 获取用户 */
    @action
    public getUsers() {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.getUsers().then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.users = res.data.data
                        Object.keys(this.users).map((key, index) => {
                            if (this.users[key] && this.users[key].length > 0) {
                                this.users[key].map((o: any) => {
                                    o.count = o.catalogingCount || 0
                                })
                            }
                        })
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        });
    }

    /* 获取全部的栏目、频道、来源 */
    @action
    public getFieldOptions(fieldType: string) {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.getFieldOptions(fieldType).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        res.data.data.map((o) => {
                            o.value = o.code || ""
                        })
                        this.handleFields(fieldType, res.data.data)
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 处理条件筛选项 */
    @action
    public handleFields(fieldType: string, options: contentMgTypes.catalogTask.IFieldOptionsItem[]) {
        let idx = _.findIndex(this.fields, { fieldType })
        if (idx >= 0 && options && options.length > 0) {
            this.fields[idx].options = _.concat([{ name: "全部".l('com.all'), value: "" }], options)
        }
    }

    /* 获取编目的所有配置 */
    @action
    public getCatalogConfig() {
        return new Promise((resolve, reject) => {
            contentMgApi.catalogTask.getCatalogConfig().then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.catalogConfig = res.data.data
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

}

