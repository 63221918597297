import configApi from "./apis/configApi";
import {stores} from "./stores";
import {IConfig, IConfigRes} from "./types/configTypes";
import _ from 'lodash';
import {AxiosResponse} from 'axios';
import {Language} from "mam-core-react";
import {l} from "mam-core-react";
import {IResponse} from "mam-core-react/dist/modules/http";
import { commonUtil,initCookie } from 'mam-common-utils';
import {getApiDict, setApiOptions} from "mam-apis";
import { nxt } from './nxt'
import { ApiVersions } from 'mam-apis/publish/types/apiVersions'

class Config {
    public initCfg(): Promise<any> {
        initCookie();
        setApiOptions({
            apiVersion: ApiVersions.v1
        });
        nxt.apis = getApiDict();

        const promises: Array<Promise<any>> = [];
        promises.push(this.getConfig());
        promises.push(this.getLanguage());
        return Promise.all(promises);
    }

    private getConfig(): Promise<IConfig> {
        return new Promise((resolve, reject) => {
            configApi.getBaseInfo().then((res) => {
                if (res.data && res.data.success === true && res.data.data) {
                    if (commonUtil.isDebug()) {
                        // res.data.data.config.theme.name = 'standard2'
                        // res.data.data.config.theme.name = 'intelligent';
                        // res.data.data.config.theme.name = 'chongqing';
                    }

                    stores.configStore.setConfig(res.data.data.config);
                    if (res.data.data.currentUser) {
                        stores.userStore.setCurrentUser(res.data.data.currentUser);
                    }
                    if (res.data.data.lang) {
                        stores.langStore.setLangCfg(res.data.data.lang);
                    }

                    //加载皮肤
                    if (commonUtil.isDebug()) {
                        if (res.data.data) {
                            import('./styles/' + res.data.data.config.theme.name + '/antd/index').then(() => {
                                if (res.data.data) {
                                    import('./styles/' + res.data.data.config.theme.name + '/index').then(() => {
                                        if (res.data.data) {
                                            resolve(res.data.data.config);
                                        }
                                    })
                                }
                            })
                        }
                    } else {
                        this.getCss().then(() => {
                            if (res.data.data) {
                                resolve(res.data.data.config);
                            }
                        });
                    }

                    //设置title
                    if (stores.configStore.config && stores.configStore.config.siteName) {
                        document.getElementsByTagName("title")[0].innerText = stores.configStore.config.siteName;
                    }
                } else {
                    this.showError(res);
                    reject();
                }
            }, (res) => {
                this.showError(res);
            });
        });
    }

    private showError(res: AxiosResponse<IResponse<IConfigRes<IConfig>>>) {
        let error = '';
        if (!res || res.status === 0) {
            error = '无法连接到服务器，请稍后再试';
        } else {
            error = _.get(res.data, 'error.title', '');
            if (error === '') {
                error = l('system.500', '系统繁忙中，请稍后再试！');
            } else {
                error = l(_.get(res.data, 'error.code', ''), error);
            }
        }
        $('#spinner').fadeOut(function () {
            $(this).remove();
        });
        $('body').append('<div id="bootstrapper-load-error"><p>' + error + '</p><p><a onclick="location.reload()">' + l('system.retry', '立即重试') + '</a></p></div >');
    }

    /** 生产模式引入css */
    private getCss(): Promise<any> {
        return new Promise((resolve, reject) => {
            //先加载antd样式
            let antdPromise = new Promise((antdResolve, antdReject) => {
                commonUtil.asyncLoadScript((window as any).mam.skinPath.css['style-' + stores.configStore.config.theme.name + '-antd'], () => {
                    antdResolve();
                });
            });
            antdPromise.then(() => {
                commonUtil.asyncLoadScript((window as any).mam.skinPath.css['style-' + stores.configStore.config.theme.name], () => {
                    resolve();
                });
            });
        });
    }

    private getLanguage(): Promise<any> {
        return new Promise((resolve, reject) => {
            (window as any).mam.language = new Language();
            (window as any).mam.language.load((window as any).mam.language.get(), () => {
                resolve();
            }, {
                zh: import('./assets/lang/zh.js'),
                cht: import('./assets/lang/cht.js'),
                en: import('./assets/lang/en.js')
            });
        });
    }
}

const config = new Config();
export default config;
