import ExtendStore from 'src/stores/base/extendStore';
import _ from 'lodash'
import { Stores } from 'src/stores';
import ManageCenterStore from '../../../manageCenterStore';
import CreateEditTemplateStore from './createEditTemplateStore';
import { action, observable, runInAction } from 'mobx';
import manageCenterApi from 'src/apis/manageCenterApi';
import { message } from 'antd';
import manageCenterTypes from 'src/types/manageCenterTypes';

export default class SearchTemplateStores extends ExtendStore {
    @observable
    public searchTemplates: manageCenterTypes.search.ISearchTemplateItem[] = []
    public createEditTemplateStore: CreateEditTemplateStore
    private manageCenterStore: ManageCenterStore;

    public constructor(stores: Stores, manageCenterStore: ManageCenterStore){
        super(stores)
        this.manageCenterStore = manageCenterStore;
        this.createEditTemplateStore = new CreateEditTemplateStore(stores, this);
    }

    @action
    public querySearchTemplate(){
        manageCenterApi.search.querySearchTemplate().then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.searchTemplates = res.data.data;
                }
            })
        }).catch(res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }
}