import { http } from 'mam-core-react'
import { IResponse } from 'mam-core-react/dist/modules/http'
import { IPortletItem, IEntityDetailRes } from 'src/types/portletTypes'
import { AxiosPromise } from 'axios'

namespace portletApi{
    export const getLayout = (pageName: string): AxiosPromise<IResponse<IPortletItem[]>> => {
        return http.get<IResponse<IPortletItem[]>>(`/layout/name?pagename=${pageName}`)
    }

    export const getEntityDetail = (contentId: string): AxiosPromise<IResponse<IEntityDetailRes>> => {
        return http.get<IResponse<IEntityDetailRes>>(`/ficus-statistic/portal/category/topcontent/${contentId}`)
    }
}

export default portletApi