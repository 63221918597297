import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { BrowserRouter, Switch } from 'react-router-dom'
import { Provider } from 'mobx-react'

import registerServiceWorker from './registerServiceWorker'
import { Route } from 'react-router-dom'

import './nxt';
import config from './config';
import { stores } from './stores'
import AuthRoute from "./router/authRoute";
import NotFound from "./pages/NotFound";
import LoadingComponent from "./router/loadingComponent";
import Loadable from "react-loadable";
import { ConfigProvider, message } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import zh_TW from 'antd/lib/locale-provider/zh_TW';

import moment from "moment";
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import SignalrNotification from "./components/signalr/signalrNotification"
import { http, setHttpOptions } from "./utils/http";
import { mamCoreReactLang } from "mam-core-react";
import commonUtil from "mam-common-utils/dist/modules/commonUtil";

let showRequestLoadingTc: NodeJS.Timeout | undefined;
setHttpOptions({
    baseReqPath: commonUtil.isDebug() ? '/proxy' : '',
    headers: {
        'mam-product': process.env.BUILD_TYPE === 'mch' ? 'mch' : 'mah'
    },
    addReqCountCallback: (requestLoadingCount: number) => {
        if (showRequestLoadingTc) {
            window.clearTimeout(showRequestLoadingTc);
        }
        // 加载转圈动画最长不超过10秒，提升用户体验
        showRequestLoadingTc = setTimeout(() => {
            if (stores.configStore.showRequestLoading) {
                stores.configStore.setShowRequestLoading(false);
            }
        }, 10000)

        if (!stores.configStore.showRequestLoading) {
            stores.configStore.setShowRequestLoading(true);
        }
    },
    removeReqCountCallback: (requestLoadingCount: number) => {
        if (requestLoadingCount <= 0) {
            stores.configStore.setShowRequestLoading(false);

            if (showRequestLoadingTc) {
                window.clearTimeout(showRequestLoadingTc);
            }
        }
    }
});

config.initCfg().then((data) => {
    let antdLocale: any;
    const lang = (window as any).mam.language.get();
    if (!lang || lang === 'zh') {
        moment.locale('zh-cn');
        antdLocale = zh_CN;
    }
    else if (lang === 'cht') {
        moment.locale('zh-tw');
        antdLocale = zh_TW;
    }
    //合并国际化字典
    (window as any).mam.language.append(mamCoreReactLang[lang]);

    message.config({
        top: 70,
    })

    ReactDOM.render(
        <Provider {...stores}>
            <ConfigProvider locale={antdLocale}>
                <BrowserRouter>
                    <Switch>
                        <AuthRoute path="/login" name="login" component={Loadable({
                            loader: () => import('./pages/login/login'),
                            loading: LoadingComponent
                        })} exact={true}></AuthRoute>
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/portletSubs" name="portletSubs" component={Loadable({
                                loader: () => import('./pages/portlet/portletSubs/portletSubs'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/userCenter/personas" name="personas" component={Loadable({
                                loader: () => import('./pages/userCenter/personas/personas'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/app" name="app" component={Loadable({
                                loader: () => import('./pages/app/app'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/iHome" name="iHome" component={Loadable({
                                loader: () => import('./pages/iHome'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {/* 浙江台门户首页 */}
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/zj/index" name="index" component={Loadable({
                                loader: () => import('src/custom/zhejiang/pages/subModules/index/index'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {/* 浙江台租户门户首页 */}
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/zj/iIndex" name="iIndex" component={Loadable({
                                loader: () => import('src/custom/zhejiang/pages/subModules/iIndex/iIndex'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        <AuthRoute path="/" name="home" component={Loadable({
                            loader: () => import('./pages/home'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        <Route component={NotFound}></Route>
                    </Switch>
                </BrowserRouter>
                {
                    stores.userStore.currentUser &&
                    stores.configStore.config.signalrSwitch &&
                    <SignalrNotification ip={stores.configStore.config.signalrServerIp} />
                }
            </ConfigProvider>
        </Provider>,
        document.getElementById('root') as HTMLElement
    )
});

registerServiceWorker();

//心跳
(window as any).mam.setInterval({
    worker: '/libs/interval/worker.js',
    interval: 1000 * 60 * 10,
    callback: () => {
        http.get('/user/beat');
    }
});
