import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'
import entityApi from 'src/apis/entityApi'
import { IParams, IEntitiesReq, IEntitiesRes ,IGetBreadcrumbRes} from 'src/types/iAlbumDetailTypes'
import _ from 'lodash'
import { Stores } from '..'
import iAlbumDetailApi from 'src/apis/iAlbumDetailApi'
import iIndexApi from 'src/apis/iIndexApi'
import { message } from 'antd'
import iIndexTypes from 'src/types/iIndexTypes'
import ModifyAutoCollectStore from './modifyAutoCollectStore'
import iSearchTypes from 'src/types/iSearchTypes'

export default class IAlbumDetailStore extends ExtendStore {
    @observable
    public params: IParams
    @observable
    public metadatas: IFormItem[] = []
    @observable
    public keyframePath: string = ''
    @observable
    public isMetaEdit: boolean
    @observable
    public entitiesRes?: IEntitiesRes
    @observable
    public isLoadingEntities = true;
    @observable
    public isReqAlbums = true;
    @observable
    public breadcrumb:IGetBreadcrumbRes[];//浙江台面包屑
    @observable
    public relatedAlbumsRes: iIndexTypes.secList.ISearchAlbumsRes | undefined;
    @observable
    public topAlbumsRes: iIndexTypes.secList.ISearchAlbumsRes | undefined;
    public modifyAutoCollectStore: ModifyAutoCollectStore;
    @observable
    public sortItems: iSearchTypes.ISortItem[] = []
    /** 当前选中排序类型 */
    @observable
    public currentSort: iSearchTypes.ISortItem;

    public constructor(stores: Stores) {
        super(stores);
        this.initSelectAllStore(stores, 'stores.iAlbumDetailStore.entitiesRes.data', 'selected');
        this.initDownloadStore(stores);
        this.initOutstoreStore(stores);
        this.initNewCreateAlbumStore(stores)
        this.modifyAutoCollectStore = new ModifyAutoCollectStore(stores, this)
    }

    @action
    public setParams(params: IParams){
        this.params = params;
    }

    @action
    public getSortItems = () => {
        const config = this.stores.configStore.config;
        let sortItems: iSearchTypes.ISortItem[] = []
        if (config && config.searchSortFields && config.searchSortFields.length > 0) {
            sortItems = config.searchSortFields.filter((item)=>{
                return item.field !== '_score';
            }).map((item) => {
                return {
                    text: item.name || '',
                    field: item.field,
                    desc: item.desc ? 'desc' : 'asc',
                    hideDirection: true
                }
            })
        } else {
            sortItems = [
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'desc' },
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'asc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'desc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'asc' }
            ]
        }
        this.sortItems = sortItems;
        if(!this.currentSort){
            this.setCurrentSort(sortItems[0]);
        }
    }

    @action
    public setCurrentSort(sort: iSearchTypes.ISortItem) {
        this.currentSort = sort;
    }
    @action
    public getBreadcrumb(contentId:string){
        iAlbumDetailApi.getBreadcrumb(contentId).then((res) => {
            runInAction(() => {
                if (res.data.data) {
                    this.breadcrumb=res.data.data
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }
    @action
    public getBasicDatas(){
        this.metadatas = [];
        this.keyframePath = '';
        entityApi.getInitializeData(this.params.contentId).then((res) => {
            runInAction(() => {
                if (res.data.data && res.data.data.entitydata) {
                    this.metadatas = res.data.data.entitydata;
                    let fileGroups = res.data.data.fileGroups;
                    let keyframeG = _.find(fileGroups, {groupName: "keyframe_"});
                    if (keyframeG && keyframeG.fileItems && keyframeG.fileItems.length > 0){
                        this.keyframePath = keyframeG.fileItems[0].filePath
                    }
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }

    @action
    public getEntitys(page?: number, keyword?: string){
        let param: IEntitiesReq = {
            pageIndex: page || 1,
            pageSize: 45,
            keyword: keyword ? [keyword] : [],
            contentId: this.params.contentId,
            sorts:[
                {
                    field: this.currentSort.field,
                    desc: this.currentSort.desc === 'desc'
                }
            ]
        }
        this.isLoadingEntities = true;
        this.entitiesRes = undefined;
        iAlbumDetailApi.queryEntities(param).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    res.data.data.data.forEach((item)=>{
                        item.name = item.name_;
                        item.duration = item.duration_;
                        item.contentId = item.contentId_;
                        item.framerate = item.framerate_;
                    })
                    this.entitiesRes = res.data.data
                }
                this.isLoadingEntities = false;
            })
        }, res=>{
            runInAction(()=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                this.isLoadingEntities = false;
            })
        })
    }
    // 查询相关专辑和热门专辑
    @action
    public getRelativeOrHotAlbums(name?: any){
        return new Promise((resolve, reject)=>{
            let param: iIndexTypes.secList.ISearchAlbumsReq = {
                pageIndex: 1,
                pageSize: 3,
                keyword: name? [name] : [],
                conditions: [
                    {
                        field: "contentId_",
                        nexus: "not",
                        value: [this.params.contentId]
                    }
                ],
                sorts:[
                    {
                        field: 'createDate_',
                        desc: true
                    }
                ]
            }
            if(name) {
                this.relatedAlbumsRes = undefined
            } else {
                this.topAlbumsRes = undefined
            }
            this.isReqAlbums = true;
            iIndexApi.searchAlbums(param).then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        res.data.data.data.forEach((item)=>{
                            item.name = item.name_;
                            item.duration = item.duration_;
                            item.contentId = item.contentId_;
                            item.framerate = item.framerate_;
                        })
                        if(name) {
                            this.relatedAlbumsRes = res.data.data
                        } else {
                            this.topAlbumsRes = res.data.data
                        }
                    }
                    this.isReqAlbums = false;
                    resolve(true);
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                this.isReqAlbums = false;
                reject();
            });
        })
    }

    @action
    public setMetaEdit(isEdit: boolean){
        this.isMetaEdit = isEdit;
    }
}