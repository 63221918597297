import ExtendStore from 'src/stores/base/extendStore';
import { action, observable, runInAction } from 'mobx';
import navConfigTypes from 'src/types/navConfigTypes';
import navConfigApi from 'src/apis/navConfigApi';

export default class NavConfigStore extends ExtendStore {
    @observable 
    public dragKey = "00000000000000000000000000000000"
    @observable 
    public nav: navConfigTypes.IQueryAllNavigationRes[]
    @observable 
    public changeTopNav: boolean=true//改变头部导航
    @observable 
    public selectedNav:navConfigTypes.ISelectedNav={
                    name:"",
                    title: "添加根目录",
                    parentId: -1,
                    openNewPage: true,
                    code:"",
                    url:"",
                    icon:"",
                    apppermission:"",
    }
    @observable 
    public fromType:boolean =true
    @action.bound
    public setDragKey(value:string) {
        this.dragKey = value
    }
    @action.bound
    public setChangeTopNav() {
        this.changeTopNav = !this.changeTopNav
    }
    @action.bound
    public queryAllNavigation=()=>{
        return new Promise((resolve, reject)=>{
            navConfigApi.queryAllNavigation().then(datas => {
                runInAction(()=>{
                    if (datas.data.data) {
                        this.nav=this.addKey(datas.data.data)
                        resolve(true)
                    }else{
                        reject()
                    }
                })
            });
        })
    }
    @action.bound
    public setSelectedNav(value:navConfigTypes.ISelectedNav) {
        this.selectedNav = value
    }
    @action.bound
    public changeSelectedNavOpenNewPage(type:boolean) {
        this.selectedNav.openNewPage = type
    }
    @action.bound
    public changeFromType(type:boolean) {
        this.fromType=type
    }
    public addKey(arr: navConfigTypes.IQueryAllNavigationRes[], key?: string) {
        if (arr && arr.length) {
            arr.map((v, i) => {
                if (key) {
                    v.key = key + v.id
                } else {
                    v.key = v.id + ""
                }
                if (v.children && v.children.length) {
                    this.addKey(v.children, v.key + "-")
                }
            })
        }
        return arr
    }
}