import {http} from "../utils/http";
import {AxiosPromise} from "axios";
import exportAuditTypes from "src/types/exportAuditTypes";
import {IResponse} from "mam-core-react/dist/modules/http";

namespace exportAuditApi {
    export const getExportauditList = (params: exportAuditTypes.IGetExportauditListParams): AxiosPromise<IResponse<exportAuditTypes.IExportAuditListResponse>> => {
        return http.post('/flowstep/exportaudit/list-audittask', params);
    }
    export const getKeyframe = (contentIds: string[]): AxiosPromise<IResponse<exportAuditTypes.IGetExportauditList[]>> => {
        return http.post<IResponse<exportAuditTypes.IGetExportauditList[]>>(`/flowstep/exportaudit/list-audittask-extend`, contentIds)
    }
    export const exportData = (params: exportAuditTypes.IGetExportauditListParams): AxiosPromise<IResponse<any>> => {
        return http.post('/flowstep/exportaudit/export-audittask', params);
    }
    // 通过、打回功能移到mms中了
    // export const auditTask = (params: exportAuditTypes.IAudittask): AxiosPromise<IResponse<exportAuditTypes.IAudittaskResponse>> => {
    //     return http.post('/flowstep/exportaudit/audittask', params);
    // }
}
export default exportAuditApi
