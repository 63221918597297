import { BaseStore } from 'mam-core-react';
import { Stores } from 'src/stores';
import IMetadataOpeStore, { ICustomControl, IHighlightKeywords } from 'mam-metadata-react/dist/store/metadataOpeStore';
import IMetadataTagOpeStore, { ITagShellCom } from 'mam-metadata-react/dist/store/metadataTagOpeStore';
import { observable, action, runInAction } from 'mobx';
import MfcExtraTag from 'mam-metadata-react/dist/controls/mfcTag/mfcExtraTag';
import React from 'react';
import { ISelectedTag } from 'mam-metadata-react/dist/types/metadataOpeTypes';
import entityTypes from 'src/types/entityTypes';
import entityApi from 'src/apis/entityApi';
import { sensitiveReg } from 'mam-metadata-react/dist/controls/mfcTag/mfcTag';
import IMetadataTableOpeStore from 'mam-metadata-react/dist/store/metadataTableOpeStore';
import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import IMetadataInputOpeStore, { IPrefixNode } from 'mam-metadata-react/dist/store/metadataInputOpeStore';
import iEntityTypes from 'src/types/iEntityTypes';
import iEntityApi from 'src/apis/iEntityApi';
import { message } from 'antd';
import _ from 'lodash';
import iIndexTypes from 'src/types/iIndexTypes';
import iIndexApi from 'src/apis/iIndexApi'
import IEntityStore from '../../iEntityStore';
import ICatalogStore from 'src/stores/iCatalog/iCatalogStore';
import { IModifyPartParam } from '../../extends/contentIndexStore';
import { TimelineType } from 'src/pages/iEntityPart/modules/rightTabContent/baseInfo/tagInfoPanel/timelineInfo/timelineItem';

interface IContentIndexStore{
    currentMeta: iEntityTypes.IMetaOriginal | undefined
    getTypeByFieldName: (field: string)=>string
    getParentStore: ()=>IEntityStore | ICatalogStore
    modifyPart: (part: iEntityTypes.IMetaOriginal, param: IModifyPartParam)=>boolean
}

export default class MetadataOpeStore extends BaseStore<Stores> implements IMetadataOpeStore,IMetadataTagOpeStore,IMetadataTableOpeStore,IMetadataInputOpeStore{
    /** 格外的标签组件 */
    @observable
    public ExtraTagComs?: Array<typeof MfcExtraTag>;
    /** 标签能否支持修改 */
    @observable
    public tagEditable: boolean = false;
    /** 删除标签前 */
    @observable
    public beforeHandleDeleteTag: (done: ()=>void)=>void
    /** 格外的元数据组件 */
    public StartExtraComs?: Array<typeof React.Component>;
    /** 格外的元数据组件 */
    public EndExtraComs?: Array<typeof React.Component>;
    /** 标签新增回调 */
    public tagOnAddCallback?: (fieldPath: string, fieldName: string, tagText: string)=>void;
    /** 标签修改回调 */
    public tagOnEditCallback?: (fieldPath: string, fieldName: string, tagText: string, oldText: string)=>void;
    /** 删除标签回调 */
    public tagOnDeleteCallback?: (fieldPath: string, fieldName: string, tagText: string)=>void
    /** 检索词标红 */
    public highlightKeywords?: IHighlightKeywords[]
    /** 元数据组件选中的tag */
    @observable
    public selectedTag?: ISelectedTag;
    @observable
    public selectedTagDesc: entityTypes.contentIndex.ITermDetail[];
    /** 正在查询tag的desc的请求数 */
    @observable
    public requestTagDescCount: number = 0;
    @observable
    public selectedTagRelatedParts: any[];
    /** 正在查询tag的desc的请求数 */
    @observable
    public requestTagRelatedPartsCount: number = 0;
    /** 正在查询tag时间线请求数量 */
    @observable
    public requestTagTimelinesCount: number = 0;
    @observable
    public tagTimelines: iEntityTypes.ITagTimeline[] = [];
    @observable
    public speechAndSubtitleDatas: iEntityTypes.ISpeechAidata[] | undefined;
    @observable
    public isReqSpeechAndSubtitle: boolean = false;
    /** 修改复杂类型之前调用 */
    public beforeEditMetadataTableRow?: (item: IFormItem, tableDatas: IFormItem[][], x: number, y: number, value: string) => void;
    /** 输入框后缀集合 */
    public suffixNodes?: IPrefixNode[]
    /** 自定义组件 */
    public customControls?: ICustomControl[]
    /** 有时间线的标签 */
    @observable
    public namedEntityTags: string[] = [];
    @observable
    public shell: React.FC<ITagShellCom>;

    public contentIndexStore: IContentIndexStore;

    private allFaces: iEntityTypes.IFaceAidata[] = []

    public constructor(stores: Stores, contentIndexStore: IContentIndexStore) {
        super(stores);
        this.contentIndexStore = contentIndexStore;
    }

    @action
    public setExtraTagComs(com?: Array<typeof MfcExtraTag>){
        this.ExtraTagComs = com;
    }

    @action
    public setTagEditable(tagEditable: boolean){
        this.tagEditable = tagEditable;
    }

    @action
    public setBeforeHandleDeleteTag(beforeHandleDeleteTag: (done: ()=>void)=>void){
        this.beforeHandleDeleteTag = beforeHandleDeleteTag;
    }

    @action
    public setStartExtraComs(com?: Array<typeof React.Component>){
        this.StartExtraComs = com;
    }
    @action
    public setEndExtraComs(com?: Array<typeof React.Component>){
        this.EndExtraComs = com;
    }
    @action
    public setTagOnAddCallback(tagOnAddCallback: (fieldPath: string, fieldName: string, tagText: string)=>void){
        this.tagOnAddCallback = tagOnAddCallback;
    }
    @action
    public setTagOnEditCallback(tagOnEditCallback: (fieldPath: string, fieldName: string, tagText: string, oldText: string)=>void){
        this.tagOnEditCallback = tagOnEditCallback;
    }
    @action
    public setTagOnDeleteCallback(tagOnDeleteCallback: (fieldPath: string, fieldName: string, tagText: string)=>void){
        this.tagOnDeleteCallback = tagOnDeleteCallback;
    }
    @action
    public setHighlightKeywords(keywords: IHighlightKeywords[]){
        this.highlightKeywords = keywords;
    }

    @action
    public setSelectedTag(selTag?: ISelectedTag){
        this.selectedTag = selTag;
        if (selTag && (selTag.fieldName.toLowerCase() === 'relatedpeople'
                || selTag.fieldName.toLowerCase() === 'eventpeople'
                || selTag.fieldName.toLowerCase() === 'eventorg'
                || selTag.fieldName.toLowerCase() === 'eventplace'
                || selTag.fieldName.toLowerCase() === 'relatedperson'
                || selTag.fieldName.toLowerCase() === 'reporter'
                || selTag.fieldName.toLowerCase() === 'face'
                || selTag.fieldName.toLowerCase() === 'host'
                || selTag.fieldName.toLowerCase() === 'sensitiveperson')){
            this.querySelectedTagDesc();
            this.querySelectedTagRelatedParts();
        }
        else {
            this.selectedTagDesc = [];
            this.selectedTagRelatedParts = [];
        }
        if (selTag 
            && selTag.fieldName.toLowerCase() !== 'keyword' 
            && selTag.fieldName.toLowerCase() !== 'topic'){
            let tagTimeline = _.find(this.tagTimelines, {fieldName: selTag.fieldName, fieldPath: selTag.fieldPath, tagName: selTag.tag});
            if (!tagTimeline){
                this.requestTagTimelinesCount++;
                this.queryTagTimelines().then(()=>{
                    tagTimeline = _.find(this.tagTimelines, {fieldName: selTag.fieldName, fieldPath: selTag.fieldPath, tagName: selTag.tag});
                    if (!tagTimeline){// 未查询到时间线信息，则断定可能是原子能力分析的素材，需要前端提取时间线信息
                        this.extractTagTimelines().then(()=>{
                            runInAction(()=>{
                                this.requestTagTimelinesCount--;
                            })
                        }).catch(()=>{
                            runInAction(()=>{
                                this.requestTagTimelinesCount--;
                            })
                        });
                    }
                    else {
                        runInAction(()=>{
                            this.requestTagTimelinesCount--;
                        })
                    }
                }).catch(()=>{
                    runInAction(()=>{
                        this.requestTagTimelinesCount--;
                    })
                });
            }
        }
        if (selTag && (selTag.fieldName.toLowerCase() === 'keyword')){
            this.querySpeechAndSubtitle();
        }
    }

    @action
    public querySelectedTagDesc(){
        if (this.selectedTag){
            this.selectedTagDesc = [];
            const type = this.contentIndexStore.getTypeByFieldName(this.selectedTag.fieldName);
            if (!type){
                return;
            }
            this.requestTagDescCount++;
            if (type === 'NAME'){
                this.getPersonIdByName(this.selectedTag.tag).then(personId=>{
                    if (this.selectedTag){
                        entityApi.getTermDetail(personId || '', this.selectedTag.tag.replace(sensitiveReg, '').replace(/\(.*?\)/, ''), type).then((res)=>{
                            runInAction(()=>{
                                this.requestTagDescCount--;
                                if (this.selectedTag && res.data.data && res.data.data.length > 0){
                                    if (this.selectedTag.tag.replace(sensitiveReg, '').replace(/\(.*?\)/, '') === res.data.data[0].name){
                                        this.selectedTagDesc = res.data.data;
                                    }
                                }
                            });
                        }, err=>{
                            runInAction(()=>{
                                this.requestTagDescCount--;
                                this.selectedTagDesc = [];
                            });
                        });
                    }
                })
            }
            else {
                entityApi.getTermDetail('', this.selectedTag.tag.replace(sensitiveReg, '').replace(/\(.*?\)/, ''), type).then((res)=>{
                    runInAction(()=>{
                        this.requestTagDescCount--;
                        if (this.selectedTag && res.data.data && res.data.data.length > 0){
                            if (this.selectedTag.tag.replace(sensitiveReg, '').replace(/\(.*?\)/, '') === res.data.data[0].name){
                                this.selectedTagDesc = res.data.data;
                            }
                        }
                    });
                }, err=>{
                    runInAction(()=>{
                        this.requestTagDescCount--;
                        this.selectedTagDesc = [];
                    });
                });
            }
        }
    }

    @action
    public querySelectedTagRelatedParts(){
        if (this.selectedTag){
            this.selectedTagRelatedParts = [];
            this.requestTagRelatedPartsCount++;

            let param: iIndexTypes.thiDetail.ISearchByEsOriginReq = {
                pageIndex: 1,
                pageSize: 5,
                conditions: [
                    {
                        field: "type_",
                        nexus: "Eq",
                        value: [
                            "AI_Story"
                        ]
                    }
                ],
                sourceType: 'custom',
                extension: {
                    returnFields: [
                        "*"
                    ]
                },
                keyword: [this.selectedTag.tag.replace(sensitiveReg, '')]
            }
            iIndexApi.searchByEsOrigin(param).then((res)=>{
                runInAction(()=>{
                    this.requestTagRelatedPartsCount--;
                    if (res.data.data && res.data.data.data){
                        res.data.data.data.forEach((item) => {
                            item.name = item.name_;
                            item.duration = item.duration_;
                            item.contentId = item.contentId_;
                            item.framerate = item.framerate_;
                        })
                        this.selectedTagRelatedParts = res.data.data.data;
                    }
                });
            }, err=>{
                runInAction(()=>{
                    this.requestTagRelatedPartsCount--;
                    this.selectedTagRelatedParts = [];
                });
            });
        }
    }

    @action
    public queryTagTimelines(){
        return new Promise((resolve, reject)=>{
            if (this.selectedTag){
                iEntityApi.getNamedEntity(this.contentIndexStore.getParentStore().params.contentId, this.selectedTag.tag).then((res)=>{
                    runInAction(()=>{
                        if (res.data.data && res.data.data.length > 0 && res.data.data[0].timelines && res.data.data[0].timelines.length > 0 && this.selectedTag){
                            this.tagTimelines.push({
                                fieldName: this.selectedTag.fieldName,
                                fieldPath: this.selectedTag.fieldPath,
                                tagName: this.selectedTag.tag,
                                namedEntity: res.data.data[0]
                            });
                        }
                        resolve(undefined);
                    });
                }, err=>{
                    reject();
                });
            }
            else {
                reject();
            }
        })
    }

    /** 提取标签时间线 */
    @action
    public extractTagTimelines(){
        return new Promise((resolveAll, rejectAll)=>{
            if (this.selectedTag && (this.selectedTag.fieldName === 'face' 
            || this.selectedTag.fieldName === 'eventpeople'
            || this.selectedTag.fieldName === 'eventorg'
            || this.selectedTag.fieldName === 'eventplace')) {
                const smartViewStore = this.contentIndexStore.getParentStore().smartViewStore;
                const tagTimeline: iEntityTypes.ITagTimeline = {
                    fieldName: this.selectedTag.fieldName,
                    fieldPath: this.selectedTag.fieldPath,
                    tagName: this.selectedTag.tag,
                    namedEntity: {
                        contentId: this.contentIndexStore.getParentStore().params.contentId,
                        type: this.contentIndexStore.getParentStore().entity.type,
                        frameRate: this.contentIndexStore.getParentStore().frameRate,
                        timelines: []
                    }
                }
                const allPromises: Array<Promise<any>> = [];
                if (this.selectedTag.fieldName === 'face' 
                    || this.selectedTag.fieldName === 'eventpeople'){// 出境人物和新闻人物
                    // 从人脸当中提取
                    allPromises.push(new Promise((resolve, reject)=>{
                        smartViewStore.getFaces().then(res=>{
                            if (smartViewStore.smartPerson && smartViewStore.smartPerson.length > 0){
                                const person = _.find(smartViewStore.smartPerson, {'name': this.selectedTag?.tag.replace(sensitiveReg, '').replace(/\(.*?\)$/, '')})
                                if (person){
                                    iEntityApi.getFaceSequence(this.contentIndexStore.getParentStore().params.contentId, person.personId, person.clusterId).then(res1=>{
                                        runInAction(()=>{
                                            if (res1.data.data && res1.data.data.data){
                                                res1.data.data.data.forEach((item)=>{
                                                    tagTimeline.namedEntity.timelines.push({
                                                        inpoint: item.inpoint,
                                                        outpoint: item.outpoint,
                                                        source: 3
                                                    })
                                                })
                                            }
                                            resolve(undefined);
                                        })
                                    }).catch(res1=>{
                                        if (res1.data.error && res1.data.error.title){
                                            message.error(res1.data.error.title);
                                        }
                                        reject();
                                    });
                                }
                            }
                        }).catch(res=>{
                            if (res.data.error && res.data.error.title){
                                message.error(res.data.error.title);
                            }
                            reject();
                        });
                    }))
                }
                if (this.selectedTag.fieldName === 'eventpeople'
                    || this.selectedTag.fieldName === 'eventorg'
                    || this.selectedTag.fieldName === 'eventplace'){// 相关机构和相关地点
                    // 从语音中提取
                    allPromises.push(new Promise((resolve, reject)=>{
                        iEntityApi.getSpeechAidata(this.contentIndexStore.getParentStore().params.contentId, 1, 1000, undefined, undefined, this.selectedTag?.tag).then(res=>{
                            runInAction(()=>{
                                if (res.data.data && res.data.data.data.length > 0){
                                    res.data.data.data.forEach((item)=>{
                                        tagTimeline.namedEntity.timelines.push({
                                            inpoint: item.inpoint,
                                            outpoint: item.outpoint,
                                            source: 1
                                        })
                                    })
                                }
                                resolve(undefined);
                            })
                        }).catch(res=>{
                            if (res.data.error && res.data.error.title){
                                message.error(res.data.error.title);
                            }
                            reject();
                        })
                    }))
                    // 从字幕中提取
                    allPromises.push(new Promise((resolve, reject)=>{
                        iEntityApi.getSubtitleAidata(this.contentIndexStore.getParentStore().params.contentId, 1, 1000, undefined, undefined, this.selectedTag?.tag).then(res=>{
                            runInAction(()=>{
                                if (res.data.data && res.data.data.data.length > 0){
                                    res.data.data.data.forEach((item)=>{
                                        tagTimeline.namedEntity.timelines.push({
                                            inpoint: item.inpoint,
                                            outpoint: item.outpoint,
                                            source: 2
                                        })
                                    })
                                }
                                resolve(undefined);
                            })
                        }).catch(res=>{
                            if (res.data.error && res.data.error.title){
                                message.error(res.data.error.title);
                            }
                            reject();
                        });
                    }));
                }
                if (allPromises.length > 0){
                    Promise.all(allPromises).then(()=>{
                        runInAction(()=>{
                            this.tagTimelines.push(tagTimeline);
                            resolveAll(undefined)
                        })
                    }).catch(()=>{
                        rejectAll();
                    })
                }
                else {
                    resolveAll(undefined)
                }
            }
            else {
                resolveAll(undefined)
            }
        })
    }

    /** 从词库查询标红敏感词 */
    @action
    public querySensitiveKeywords(){
        let req: entityTypes.IQLexiconReq = {
            pageIndex: 1,
            pageSize: 9999,
            keyword:"",
            sortBy: {
                sortInput:"count",
                desc:true
            },
            conditions: [
                {
                    field: "name",
                    value: "规则词"
                },
                {
                    field: "type",
                    value: "5"
                }
            ]
        }
        entityApi.queryLexiconList(req).then(res=>{
            let keywords: string[] = [];
            if (res.data.success === true && res.data.data && res.data.data.data){
                res.data.data.data.filter((item)=>item.isRed).forEach(lexicon => {
                    lexicon.entryList.forEach(item=>{
                        keywords.push(item.keyword);
                    })
                });
            }
            this.setHighlightKeywords([{keywords, color: '#c00'}]);
        });
    }

    @action
    public querySpeechAndSubtitle(){
        if (this.selectedTag){
            this.isReqSpeechAndSubtitle = true;
            this.speechAndSubtitleDatas = [];
            let allPromise: Array<Promise<any>> = [];
            let keyword: string = this.selectedTag.tag;
            allPromise.push(new Promise((resolve, reject)=>{
                iEntityApi.getSpeechAidata(this.contentIndexStore.getParentStore().params.contentId, 1, 1000, undefined, undefined, keyword).then(res=>{
                    runInAction(()=>{
                        if (res.data.data && res.data.data.data.length > 0 && this.speechAndSubtitleDatas){
                            res.data.data.data.forEach((item)=>{
                                item.type = 'speech'
                                if (this.speechAndSubtitleDatas){
                                    this.speechAndSubtitleDatas.push(item);
                                }
                            })
                        }
                        resolve(undefined);
                    })
                }, (res)=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                    reject();
                })
            }));
            allPromise.push(new Promise((resolve, reject)=>{
                iEntityApi.getSubtitleAidata(this.contentIndexStore.getParentStore().params.contentId, 1, 1000, undefined, undefined, keyword).then(res=>{
                    runInAction(()=>{
                        if (res.data.data && res.data.data.data.length > 0 && this.speechAndSubtitleDatas){
                            res.data.data.data.forEach((item)=>{
                                item.type = 'subtitle'
                                if (this.speechAndSubtitleDatas){
                                    this.speechAndSubtitleDatas.push(item);
                                }
                            })
                        }
                        resolve(undefined);
                    })
                }, (res)=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                    reject();
                });
            }))
            Promise.all(allPromise).then(()=>{
                runInAction(()=>{
                    this.isReqSpeechAndSubtitle = false;
                })
            })
        }
    }

    @action
    public getNamedEntityTags(){
        iEntityApi.getNamedEntityTags(this.contentIndexStore.getParentStore().params.contentId).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.namedEntityTags = res.data.data;
                }
            })
        }).catch(res=>{
            if (res.data && res.data.error && res.data.error.title) {
                message.error(res.data.error.title);
            }
            else {
                console.error(res);
            }
        })
    }

    /** 设置标签外壳，可对标签做进一步处理 */
    @action
    public setTagShell(shell: React.FC<ITagShellCom>){
        this.shell = shell;
    }

    public setBeforeEditMetadataTableRow(fun: (item: IFormItem, tableDatas: IFormItem[][], x: number, y: number, value: string) => void){
        this.beforeEditMetadataTableRow = fun;
    }
    
    public setSuffixNodes(nodes: IPrefixNode[]){
        this.suffixNodes = nodes;
    }

    public setCustomControls(customControls?: ICustomControl[]){
        this.customControls = customControls;
    }
    
    private getPersonIdByName(name: string): Promise<string | undefined>{
        return new Promise((resolve, reject)=>{
            if (!this.allFaces || this.allFaces.length === 0){
                iEntityApi.getFaceAidata(this.contentIndexStore.getParentStore().params.contentId).then(res=>{
                    runInAction(()=>{
                        if (res.data.data && res.data.data.data.length > 0){
                            this.allFaces = res.data.data.data;
                            const faceInfo = _.find(this.allFaces, (face)=>{
                                return face.name.replace(sensitiveReg, '') === name.replace(sensitiveReg, '');
                            })
                            resolve(faceInfo?.personId);
                        }
                        resolve('');
                    })
                }, (res)=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                        reject();
                    }
                });
            }
            else {
                const faceInfo = _.find(this.allFaces, (face)=>{
                    return face.name.replace(sensitiveReg, '') === name.replace(sensitiveReg, '');
                })
                resolve(faceInfo?.personId);
            }
        })
    }
}