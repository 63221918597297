import { observable, action } from "mobx";
import { BaseStore } from 'mam-core-react';
import { Stores } from 'src/stores';

export interface IDataSource {
    index: number
    start: number
    end: number
    succeed: boolean
}

export default abstract class ExcelExportModalStore extends BaseStore<Stores>{
    @observable
    public showModal: boolean
    @observable
    public dataSource: IDataSource[] = []
    @observable
    public selectedItems: any[] = []

    public pageSize: number
    public title: string = '导出数据'.l('modal.excelExportTitle')
    constructor(stores: Stores) {
        super(stores)
        this.showModal = false
        this.dataSource = []
        this.pageSize = 3000
    }

    public abstract exportSelectedItems(): void

    public abstract exportPageData(index: number): void

    public abstract open(): void

    @action
    public setShowExcelExportModal(show: boolean) {
        this.showModal = show
    }

    @action
    public initPageData(recordTotal: number, pageSize: number = 3000) {
        if (this.selectedItems.length > 0) {
            return;
        }
        this.pageSize = pageSize
        const pageTotal: number = Math.ceil(recordTotal / pageSize)
        this.dataSource = [];
        for (let i = 1; i <= pageTotal; i++) {
            this.dataSource.push({
                index: i,
                start: ((i - 1) * pageSize) + 1,
                end: (i !== pageTotal) ? i * pageSize : recordTotal,
                succeed: false
            })
        }
    }
}