import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import ccTypes from "../types/ccTypes";
import folderPermissionTypes from 'src/types/folderPermissionTypes';
import { IConfig, IConfigRes } from "../types/configTypes";
import { IResponse } from "mam-core-react/dist/modules/http";
import entityTypes from 'src/types/entityTypes';
import mmsTypes from "src/types/mmsTypes";

namespace ccApi {
    /**
     * 获取多图片标志
     */
    export const getPictureCount = (contentIds: string[]): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/search/get-picture-count', contentIds);
    }

    /**
     * 片段查询关键帧
     */
     export const getPartsKeyframes = (req: ccTypes.IPartsKeyframesReq[]): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/get-entity-keyframes', req);
    }

    /** 获取空间资源 */
    export const getPrivateTreeStorageSpace = (): AxiosPromise<IResponse<ccTypes.IPrivateTreeStorageSpaceRes>> => {
        return http.get<IResponse<ccTypes.IPrivateTreeStorageSpaceRes>>('/api/content-center/private-tree-storage-space')
    }

    export namespace sideContentApi {
        /**
         * 左侧树
         */
        export const getSearchTree = (): AxiosPromise<IResponse<ccTypes.sideContentTypes.ISearchTreeRes[]>> => {
            return http.get<IResponse<ccTypes.sideContentTypes.ISearchTreeRes[]>>('/search/get-fixed-condition-tree')
        }

        /**
         * 文件夹
         */
        export const getFolders = (param: ccTypes.sideContentTypes.IFolderReq): AxiosPromise<IResponse<ccTypes.cloudTypes.IFullSearchRes>> => {
            return http.post<IResponse<ccTypes.cloudTypes.IFullSearchRes>>('/private-tree/query', param)
        }

        /**
         * 查询工作组
         */
        export const getWorkteam = (): AxiosPromise<IResponse<ccTypes.sideContentTypes.IWorkteamRes[]>> => {
            return http.get<IResponse<ccTypes.sideContentTypes.IWorkteamRes[]>>('/work-team/my-teams')
        }

        export const getPublicTree = (param: ccTypes.sideContentTypes.IFolderReq): AxiosPromise<IResponse<ccTypes.cloudTypes.IFullSearchRes>> => {
            return http.post<IResponse<ccTypes.cloudTypes.IFullSearchRes>>('/public-tree/query', param)
        }

        /**
         * 查询workspace(工作空间)
         */

        export const getWorkSpace = (params: ccTypes.workSpaceTypes.IWorkSpaceReq): AxiosPromise<IResponse<ccTypes.cloudTypes.IFullSearchRes>> => {
            return http.post<IResponse<ccTypes.cloudTypes.IFullSearchRes>>('/workspace/query', params)
        }
    }

    export namespace searchApi {
        export const fullSearch = (req: ccTypes.searchTypes.IFullSearchReq): AxiosPromise<IResponse<ccTypes.searchTypes.IFullSearchRes>> => {
            return http.post<IResponse<ccTypes.searchTypes.IFullSearchRes>>('/search/full-search', req)
        }
        //检索的相关推荐检索（当主检索结果个数小于5个时才显示推荐）
        export const getRecommendSearchData = (req: ccTypes.searchTypes.IFullSearchReq): AxiosPromise<IResponse<ccTypes.searchTypes.IRecommendSearchRes>> => {
            return http.post<IResponse<ccTypes.searchTypes.IRecommendSearchRes>>('/search/recommendSearch', req)
        }

        export const recordOperationHabit = (param: ccTypes.searchTypes.IRecordOperationHabitReq) => {
            http.post('/search/record/keyword', param)
        }

        /**  根据素材id返回路径 */
        export const getEntityPath = (contentId: string): AxiosPromise<IResponse<ccTypes.searchTypes.IEntityPath>> => {
            return http.get<IResponse<ccTypes.searchTypes.IEntityPath>>('/entity/get-entity-path?contentId=' + contentId);
        }
        /**  央视定制：根据素材contentIds返回云端高低码状态 */
        export const getProgramNameCloud = (contentIds: string[]): AxiosPromise<IResponse<ccTypes.searchTypes.IProgramNameCloudResItem[]>> => {
            return http.post<IResponse<ccTypes.searchTypes.IProgramNameCloudResItem[]>>('/search/quality-search', contentIds, {headers:{hideLoading:true}})
        }
        /**  无锡定制：成品素材根据粗精切状态不同来检索 */
        export const wxFinishedFullSearch = (req: ccTypes.searchTypes.IFullSearchReq): AxiosPromise<IResponse<ccTypes.searchTypes.IFullSearchRes>> => {
            return http.post<IResponse<ccTypes.searchTypes.IFullSearchRes>>('/search/wx-finished-search', req)
        }
        // 无锡定制导出字段接口-----线上环境会自动添加token   本地环境需要添加token
        export const wxGetCatalogueField = (): AxiosPromise<IResponse<mmsTypes.mmsOutputExcelWX.IField[]>> => {
            return http.get<IResponse<any>>(`/wx/excel/group/fields?entityType=video&groupCode=cqm2&site=S1`)
        }
         // 无锡定制excel导出-----线上环境会自动添加token  本地环境需要添加token
        export const wxExportExcel = (params:any,token?:string): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/wx/excel/export?`,params)
        }
    }

    export namespace cloudApi {
        export const fullSearch = (baseUrl: string, req: ccTypes.cloudTypes.IFullSearchReq): AxiosPromise<IResponse<ccTypes.cloudTypes.IFullSearchRes>> => {
            return http.post<IResponse<ccTypes.cloudTypes.IFullSearchRes>>(baseUrl + 'query', req)
        }
        /** 获取公共文件夹的权限信息 */
        export const getFolderRelated = (queryId: string, parentId: string): AxiosPromise<IResponse<folderPermissionTypes.IRelatedPermission>> => {
            return http.get<IResponse<folderPermissionTypes.IRelatedPermission>>('/folder/get-related?contentId=' + queryId + '&parentId=' + parentId)
        }
        /** 保存文件夹权限 */
        export const saveFolderPermission = (req: folderPermissionTypes.ISaveFolderPermissionRequest): AxiosPromise<IResponse<ccTypes.cloudTypes.IFullSearchData>> => {
            return http.post<IResponse<ccTypes.cloudTypes.IFullSearchData>>('/folder/save-public-folder', req)
        }

        export const getUpdatePermissionResult = (params: string): AxiosPromise<IResponse<folderPermissionTypes.IUpdatePermissionTask>> => {
            return http.get<IResponse<folderPermissionTypes.IUpdatePermissionTask>>(`/resource/get-update-permission-result?taskId=${params}`)
        }
    }

    export namespace relativeInfoApi {
        //相关信息
        export const getRelativeInfo = (keyword: string): AxiosPromise<IResponse<ccTypes.relativeInfo.IGetRelativeInfoRes[]>> => {
            return http.post<IResponse<ccTypes.relativeInfo.IGetRelativeInfoRes[]>>('/search/recommend/relevant', {
                keyword
            })
        }
        //实体关系
        export const getEntityRelation = (keyword: string, type: string): AxiosPromise<IResponse<ccTypes.relativeInfo.IGetEntityRelationRes>> => {
            return http.post<IResponse<ccTypes.relativeInfo.IGetEntityRelationRes>>('/search/recommend/relation', {
                keyword,
                type
            })
        }
        //相关事件
        export const getRelativeEvents = (keyword: string): AxiosPromise<IResponse<ccTypes.relativeInfo.IGetRelativeEventsRes[]>> => {
            return http.post<IResponse<ccTypes.relativeInfo.IGetRelativeEventsRes[]>>('/search/recommend/event', {
                keyword,
                size: 10
            })
        }
        //近似推荐
        export const getSimilarRecommendation = (keyword: string): AxiosPromise<IResponse<string[]>> => {
            return http.post<IResponse<string[]>>('/search/recommend/approximate', {
                keyword
            })
        }
        //相关专辑
        export const getRelativeAlbums = (keyword: string): AxiosPromise<IResponse<ccTypes.relativeInfo.IGetRelativeAlbums[]>> => {
            return http.post<IResponse<ccTypes.relativeInfo.IGetRelativeAlbums[]>>('/search/recommend/album', {
                keyword,
                size: "2"
            })
        }
    }

    /**
     * check-name(查重)
     */
    export const checkName = (url: string, params: ccTypes.CheckName.ICheckNameReq[]): AxiosPromise<IResponse<ccTypes.CheckName.ICheckNameRes>> => {
        return http.post<IResponse<ccTypes.CheckName.ICheckNameRes>>(`${url}check-name`, params)
    }

    /**
     * rename(重命名)
     */
    export const rename = (url: string, params: ccTypes.CheckName.ICheckNameReq): AxiosPromise<IResponse<string>> => {
        return http.post<IResponse<string>>(`${url}rename`, params)
    }

    /**
     * searchRename(search重命名)
     * 和别的不是同一个接口
     */
    export const searchRename = (data: ccTypes.CheckName.ISearchRenameReq): AxiosPromise<IResponse<null | string>> => {
        return http.post<IResponse<null | string>>('/entity/update-base-entity-data', data)
    }

    /**
     * 新建文件夹
     */
    export const createFolder = (url: string, params: ccTypes.createFolder.ICreateFolderReq): AxiosPromise<IResponse<ccTypes.createFolder.ICreateFolder>> => {
        return http.post<IResponse<ccTypes.createFolder.ICreateFolder>>(`${url}create`, params)
    }

    /** 获取检索模板 */
    export const getUserHabit = (key: string): AxiosPromise<IResponse<ccTypes.IUserHabit[]>> => {
        return http.get('/user/get-user-habit?key=' + key);
    }

    /** 保存检索模板 */
    export const saveUserHabit = (req: ccTypes.ISaveUserHabitReq): AxiosPromise<IResponse<any>> => {
        return http.post('/user/save-user-habit', req);
    }
    /** 获取所有密级 */
    export const getAllPrivilege = (): AxiosPromise<IResponse<ccTypes.IPrivilege[]>> => {
        return http.get<IResponse<ccTypes.IPrivilege[]>>('/role/get-permissiontemplate')
    }
    /** 挑选篮获取素材的数量 获取挑选篮的列表 */
    export const basketGetItems = (data: ccTypes.IBasketItemsReqData): AxiosPromise<IResponse<ccTypes.IBasketItems>> => {
        return http.post<IResponse<ccTypes.IBasketItems>>('/search_pickedup/query', data)
    }
    /** 添加到挑选篮 */
    export const basketAddItems = (data: ccTypes.IBasketAddItems[]): AxiosPromise<any> => {
        return http.post<any>('/search_pickedup/add', data)
    }
    /** 置换影音档 */
    export const replaceEmptyEntity = (data: any): AxiosPromise<any> => {
        return http.post<any>('/ww_custom/empty-material-replace', data)
    }
    /** 从挑选篮删除素材 */
    export const basketDeleteItems = (data: number[]): AxiosPromise<any> => {
        return http.post<any>('/search_pickedup/delete', data)
    }
    /** 从挑选篮清空素材 */
    export const basketClear = (): AxiosPromise<IResponse<boolean>> => {
        return http.get<IResponse<boolean>>('/search_pickedup/clear')
    }
    /** 查询挑选篮素材是否有目录路径 */
    export const checkBasketEntityHasTreepath = (contentIds: string[]): AxiosPromise<IResponse<ccTypes.Dataset.ICheckIsInFolderResponse>> => {
        return http.post<IResponse<ccTypes.Dataset.ICheckIsInFolderResponse>>('/search_pickedup/check-entity-is-in-folder', contentIds)
    }

    /** 发起编目任务 */
    export const createCatalogueTask = (contentId: string[]): AxiosPromise<IResponse<ccTypes.ICreateCatalogueRes[] | string>> => {
        return http.post<IResponse<ccTypes.ICreateCatalogueRes[] | string>>('/catalog/task/add', contentId);
    }

    export namespace dataset {
        /** 只用标题创建资料集 */
        export const create = (name: string): AxiosPromise<IResponse<entityTypes.IEntity>> => {
            return http.post<IResponse<entityTypes.IEntity>>('/dataset/create', {
                entityData: [{
                    fieldName: 'name_',
                    value: name
                }]
            })
        }

        /* 从挑选篮选中的素材检查是否能加入到资料集中-旧接口 */
        // export const entityIsInFolder = (params: ccTypes.Dataset.IAddEntityRequest): AxiosPromise<IResponse<any[]>> => {
        //     return http.post(`/dataset/check-entity-is-in-folder`,params)
        // }

        /* 已有素材加入到资料集中 */
        export const addFromUploaded = (request: ccTypes.Dataset.IAddEntityRequest): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/dataset/add-from-uploaded', request)
        }

    }

    export const square = (requset:string[]): AxiosPromise<IResponse<string>> => {
        return http.post<IResponse<string>>('/settlement/square',requset)
    }

    export const cancelSquare = (requset:string[]): AxiosPromise<IResponse<string>> => {
        return http.post<IResponse<string>>('/settlement/unsquare',requset)
    }
}

export default ccApi
