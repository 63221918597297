import { Stores } from 'src/stores';
import ExtendStore from 'src/stores/base/extendStore';
import ZjStore from '../../zjStore';
import { action, runInAction, observable } from 'mobx';
import zhejiangApi from 'src/custom/zhejiang/apis/zhejiangApi';
import ccApi from "src/apis/ccApi";
import { message } from 'antd';
import _ from 'lodash'
import ccTypes from "src/types/ccTypes";

export default class TenementStore extends ExtendStore {
    @observable
    public tabs: any[] = [];
    /** 选中的类型 */
    @observable
    public selectedTab: string = '';
    @observable
    public isReqList: boolean = false;
    @observable
    public fullSearchData?: ccTypes.searchTypes.IFullSearchRes;
    private zjStore: ZjStore

    public constructor(stores: Stores, zjStore: ZjStore) {
        super(stores);
        this.zjStore = zjStore;
    }

    @action
    public getTenement() {
        return new Promise((resolve, reject) => {
            zhejiangApi.getTenement().then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        let newArr: any[] = [{
                            tabName: '全部',
                            code: '*'
                        }]
                        res.data.data.forEach((item: any)=> {
                            let obj: any = {}
                            obj.tabName = item.siteName
                            obj.code = item.siteType === 1 ? 'mch_' + item.siteCode : 'mah_' + item.siteCode
                            newArr.push(obj)
                        })
                        this.setTabs(newArr)
                        this.setSelectedTab(newArr[0].code)
                        this.queryFullSearchData(newArr[0].code, 1)
                    }
                })
                resolve(true);
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action 
    public queryFullSearchData(code: string ,page?: number) {
        this.fullSearchData = undefined
        this.setIsReqList(true)
        let req: ccTypes.searchTypes.IFullSearchReq = {
            page: page || 1,
            size: 30,
            keyword: [],
            sortBys: [{
                fieldName: 'default',
                isDesc: false
            }],
            conditions: [
                {
                    field: 'zj_pick_orgsite',
                    value: [code]
                }
            ],
            highLight: true,
            resourceName: "entity"
        };
        return new Promise((resolve, reject) => {
            ccApi.searchApi.fullSearch(req).then(res => {
                runInAction(() => {
                    this.setIsReqList(false)
                    if (res.data.success === true && res.data.data) {
                        this.fullSearchData = res.data.data
                    }
                })
                resolve(true);
            }, res => {
                if (res.data.error && res.data.error.title) {
                    this.setIsReqList(false)
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public setSelectedTab(tab: string) {
        this.selectedTab = tab;
    }

    @action
    public setIsReqList(payload: boolean) {
        this.isReqList = payload;
    }

    @action
    public setTabs(tabs: any[]) {
        this.tabs = tabs;
    }
}