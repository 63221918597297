import BaseStore from '../base/baseStore';
import { observable, action, runInAction } from 'mobx';
import emptyEntityTypes from 'src/types/emptyEntityTypes';
import emptyEntityApi from 'src/apis/emptyEntityApi';
import { Stores } from '..';
import _ from 'lodash';
import { message } from 'antd';
import React from 'react';
import { AxiosPromise } from 'axios';
import { IResponse } from "mam-core-react/dist/modules/http";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import { IEmptyEntityConfig } from 'src/types/configTypes';

enum WwCustomField {
    progcd = 'progcd',
    prognm = 'prognm',
    tapeno = 'tapeno',
    name = 'name_',
    videoCategory = 'wwvideocategory',
    newSeasonsStart = 'seasons_newstart',
    newEpisodesCount = 'episodes_newcount',
    newEpisodesStart = 'episodes_newstart',
    tvseries = 'ww_tvseries',
    sequence = 'sequence'
}

export default class ProgramInfoStore extends BaseStore<Stores> {
    @observable
    public metadataGroup: emptyEntityTypes.IProgramField[] = []
    @observable
    public currentProgramCode: IFormItem
    @observable
    public programCodeSource: string[] = []
    @observable
    public openMetadataCodes: string[]
    @observable
    public isSaving: boolean
    public tab: string
    @observable
    public selectFrameRate?: number
    /** 是否正在自动创建分集 */
    @observable
    public isAutoCreating: boolean
    @observable
    public modalInfo: {
        percent: number
        errorList: Array<{ title: string, message: string }>
        done: number
        total: number
        seasons: number
        start: number
    }
    /** 节目信息弹框 */
    @observable
    public showProgramModal = false
    /** 只创建或修改 来决定是否disabled节目代码框 */
    @observable
    public isCreate = true

    constructor(stores: Stores, tab: 'common-metadata' | 'create-entity') {
        super(stores)
        this.tab = tab
    }

    @action
    public enterSaving = () => {
        this.isSaving = !this.isSaving
    }

    @action
    public onProgramCodeChange = (code: string) => {
        this.currentProgramCode.value = code
        let item = this.stores.emptyEntityStore.metadataGroup.find(s => s.groupCode === this.openMetadataCodes[0])
        this.changeMetadata(item)
        if (item && code) {
            const progcdIndex = item.fields.findIndex(s => s.fieldName === WwCustomField.progcd)
            if (progcdIndex > -1) {
                this.onMetadataChange(item.fields, progcdIndex, code)
            }
        }
        if (this.currentProgramCode.value) {
            emptyEntityApi.getProgram(code).then(res => {
                runInAction(() => {
                    this.programCodeSource = (res.data.data || []).map(s => s.programCode)
                })
            })
        } else {
            this.programCodeSource = []
        }
    }

    @action
    public saveProgramMetadata = (programMetadata: emptyEntityTypes.IProgramField[], isUpdate: boolean = false) => {
        const request: emptyEntityTypes.IProgramField[] = []
        programMetadata.forEach(item => {
            let reqItem = _.cloneDeep(item)
            reqItem.metadataTable = undefined

            const progcd = reqItem.fields.find(f => f.fieldName === WwCustomField.progcd)
            if (progcd) {
                progcd.value = this.currentProgramCode.value
                progcd.isShow = true
            }
            if (reqItem.groupCode === 'basic') {
                const tapeno = reqItem.fields.find(f => f.fieldName === WwCustomField.tapeno)
                if (tapeno) {
                    tapeno.value = this.stores.emptyEntityStore.currentEntityCode.value
                    tapeno.isShow = true
                }
            }
            request.push(reqItem)
        })
        let saveApi: AxiosPromise<IResponse<any>> = isUpdate ?
            emptyEntityApi.updateProgramFields(this.currentProgramCode.value, request) :
            (this.tab === 'create-entity' ? emptyEntityApi.createEmptyEntity(request) :
                emptyEntityApi.createProgram(this.currentProgramCode.value, request))
        const successTip = isUpdate ? '修改節目模板成功'.l('emptyEntity.updateProgramSuccess') :
            (this.tab === 'create-entity' ? '空素材创建成功'.l('emptyEntity.addEntitySuccess') : '添加節目模版成功'.l('emptyEntity.addProgramSuccess'))
        const errorTip = isUpdate ? '修改節目模板失败'.l('emptyEntity.updateProgramFail') :
            (this.tab === 'create-entity' ? '空素材创建失败'.l('emptyEntity.addEntityFail') : '添加節目模版失败'.l('emptyEntity.addProgramFail'))
        saveApi.then(res => {
            if (res.data) {
                message.success(successTip)
            }
            if (this.tab === 'create-entity') {
                this.stores.emptyEntityStore.selectEntityCode('')
            } else {
                this.onProgramCodeChange('')
                if (this.showProgramModal) {
                    this.setProgramModalShow(false)
                    this.stores.programListStore.query()
                }
            }
        }, res => {
            message.error(res.data && res.data.error && res.data.error.title ? res.data.error.title : errorTip)
        }).finally(() => {
            this.enterSaving()
        })
    }

    public selectProgramCode = (code: string) => {
        this.getEmptyEntityFields(code, 'common-metadata')
    }

    @action
    public getEmptyEntityFields(code: string, tab: string) {
        let getFieldsFunc = tab === 'create-entity' ?
            emptyEntityApi.getEmptyEntityFields() :
            emptyEntityApi.getProgramFields(code)
        if (this.currentProgramCode) {
            this.currentProgramCode.value = code
        }
        else {
            this.currentProgramCode = {
                "id": 14335,
                "alias": '节目代码'.l('emptyEntity.programCode'),
                "fieldName": "progcd",
                "isReadOnly": false,
                "isMustInput": false,
                "isMultiSelect": false,
                "isArray": false,
                "isShow": true,
                "minLength": 0,
                "maxLength": 0,
                "controlType": 5,
                "fieldPath": "",
                value: code
            }
        }
        if (tab === 'create-entity') {
            if (this.stores.emptyEntityStore.currentEntityCode) {
                this.stores.emptyEntityStore.currentEntityCode.value = code
            } else {
                this.stores.emptyEntityStore.currentEntityCode = {
                    "id": 14346,
                    "alias": '节目编号'.l('emptyEntity.entityCode'),
                    "fieldName": "tapeno",
                    "isReadOnly": false,
                    "isMustInput": false,
                    "isMultiSelect": false,
                    "isArray": false,
                    "isShow": false,
                    "minLength": 0,
                    "maxLength": 0,
                    "controlType": 5,
                    "fieldPath": "",
                    value: code
                }
            }
        }
        getFieldsFunc.then(res => {
            runInAction(() => {
                const metadataGroup = res.data.data || []
                this.openMetadataCodes = metadataGroup.length > 0 ? [metadataGroup[0].groupCode] : []
                let programName = ''
                metadataGroup.forEach(s => {
                    s.metadataTable = React.createRef()
                    if (s.groupCode === 'program' && s.fields.length) {
                        const progcdIndex = s.fields.findIndex(f => f.fieldName === WwCustomField.progcd)
                        const progcd = s.fields[progcdIndex]
                        if (progcd) {
                            progcd.isShow = false
                            this.currentProgramCode = progcd
                        } else {
                            message.warn('未配置节目代码'.l('emptyEntity.noProgcdField'))
                        }
                        const prognmIndex = s.fields.findIndex(f => f.fieldName === 'prognm')
                        if (prognmIndex > -1) {
                            programName = s.fields[prognmIndex].value + ''
                            s.fields.unshift(s.fields.splice(prognmIndex, 1)[0])
                        }
                    }
                    if (this.tab === 'create-entity' && s.groupCode === 'basic') {
                        s.fields.forEach((f)=>{//显示这几个字段，定制
                            if (f.fieldName === WwCustomField.newSeasonsStart 
                                || f.fieldName === WwCustomField.newEpisodesCount
                                || f.fieldName === WwCustomField.newEpisodesStart){
                                f.isShow = true;
                            }
                            else if (f.fieldName === WwCustomField.tvseries){
                                f.isShow = false;
                            }
                        })
                        const tapeno = s.fields.find(f => f.fieldName === WwCustomField.tapeno)
                        if (tapeno) {
                            // tapeno.isShow = false
                            this.stores.emptyEntityStore.currentEntityCode = tapeno
                        }
                        const frameRateMeta = _.find(s.fields, { fieldName: 'framerate' })
                        if (frameRateMeta) {
                            this.onSelectFrameRate(frameRateMeta.value)
                        }
                    }
                })
                if (this.metadataGroup.length) {
                    this.metadataGroup.forEach((item, index) => {
                        if (!metadataGroup[index]) {
                            metadataGroup[index] = this.metadataGroup[index]
                        } else {
                            metadataGroup[index].metadataTable = this.metadataGroup[index].metadataTable
                        }
                    })
                }
                this.metadataGroup = metadataGroup
                if (this.tab === 'create-entity') {
                    this.stores.emptyEntityStore.metadataGroup = this.metadataGroup
                    if (tab === 'common-metadata') {
                        const group = this.metadataGroup.find(s => s.groupCode === 'program')
                        if (group) {
                            const progcdIndex = group.fields.findIndex(f => f.fieldName === WwCustomField.progcd)
                            if (progcdIndex > -1) {
                                this.onMetadataChange(group.fields, progcdIndex, group.fields[progcdIndex].value)
                            }
                        }
                        const basicGroup = this.metadataGroup.find(s => s.groupCode === 'basic')
                        if (basicGroup) {
                            const nameField = basicGroup.fields.find(f => f.fieldName === WwCustomField.name)
                            if (nameField) {
                                nameField.value = programName
                            }
                        }
                    }
                }
            })
            this.stores.emptyEntityStore.setEmptyEntityConfig()
        })
    }

    @action
    public changeOpenMetadata(openMetadataCodes: string[]) {
        if (openMetadataCodes[0] !== this.openMetadataCodes[0]) {
            let item = this.metadataGroup.find(s => s.groupCode === this.openMetadataCodes[0])
            this.changeMetadata(item)
        }
        this.openMetadataCodes = openMetadataCodes
    }

    @action
    public changeMetadata(item: emptyEntityTypes.IProgramField | undefined) {
        if (item && item.metadataTable && item.metadataTable.current) {
            item.fields = item.metadataTable.current.getItems() || []
        }
    }

    public create = () => {
        if (!this.currentProgramCode.value) {
            message.warn('请填写节目代码'.l('emptyEntity.noProgramCode'))
            return false
        }
        if (this.tab === 'create-entity') {
            if (this.stores.emptyEntityStore.entityCodeSource.length) {
                message.warn('节目编号已存在！'.l('emptyEntity.entityCodeRepeat'))
                return false
            }
        }
        if (String(this.currentProgramCode.value).length !== this.stores.emptyEntityStore.emptyEntityConfig.programCodeLength) {
            message.warn('节目代码不规范'.l('emptyEntity.programCodeInvalid'))
            return false
        }
        this.validate().then(results => {
            if (this.tab === 'create-entity') {
                this.autoCreateEntity(this.currentProgramCode.value as string)
                this.enterSaving()
                return
            } else {
                this.saveProgramMetadata(this.metadataGroup)
            }
        })
        return true
    }

    public update = () => {
        if (String(this.currentProgramCode.value).length !== this.stores.emptyEntityStore.emptyEntityConfig.programCodeLength) {
            message.warn('节目代码不规范'.l('emptyEntity.programCodeInvalid'))
            return false
        }
        this.validate().then(results => {
            this.saveProgramMetadata(this.metadataGroup, true)
        })
        return true
    }

    public reset = () => {
        this.getEmptyEntityFields('', this.tab)
    }

    @action
    public autoCreateEntity(progCode: string) {
        let entityName = '节目名称'.l('emptyEntity.programName')
        const metadataGroup: emptyEntityTypes.IProgramField[] = []

        // 节目编号自动生成
        this.modalInfo = {
            percent: 0,
            errorList: [],
            done: 0,
            total: 0,
            seasons: 0,
            start: 1
        }
        this.metadataGroup.forEach(s => {
            /**  获取素材名称 */
            const nameField = s.fields.find(f => f.fieldName === WwCustomField.name)
            if (nameField) {
                entityName = (nameField.value as string) || entityName
            }
            if (s.groupCode === 'basic') {
                /** 获取季集数、起始集数 */
                const seasonsField = s.fields.find(f => f.fieldName === WwCustomField.newSeasonsStart)
                const episodesField = s.fields.find(f => f.fieldName === WwCustomField.newEpisodesCount)
                const episodesStartField = s.fields.find(f => f.fieldName === WwCustomField.newEpisodesStart)
                if (seasonsField && episodesField && (seasonsField.value !== '' && !isNaN(Number(seasonsField.value))) &&
                    (episodesField.value !== '' && !isNaN(Number(episodesField.value)))) {
                    this.modalInfo.total = Number(episodesField.value)
                    this.modalInfo.seasons = Number(seasonsField.value)
                }
                if (episodesStartField) {
                    this.modalInfo.start = Number(episodesStartField.value)
                }
            }
            metadataGroup.push({
                groupCode: s.groupCode,
                groupName: s.groupName,
                fields: s.fields
            })
        })
        if (!this.modalInfo.total) {
            message.warn('请填写季集数'.l('emptyEntity.noSessionOrEpisodes'))
            return
        }
        if (this.modalInfo.start > this.modalInfo.total) {
            message.warn('起始集数不能大于总集数'.l('emptyEntity.episodesStartError'))
            return
        }

        /** 循环创建 */
        const promises: any[] = []
        const seasons = this.numberAddZeroPrefix(this.modalInfo.seasons, this.stores.emptyEntityStore.emptyEntityConfig.seasonsDigit)
        for (let i = this.modalInfo.start; i <= this.modalInfo.total; i++) {
            const diversity = _.cloneDeep(metadataGroup)
            const episodesStartStr = this.numberAddZeroPrefix(i, this.stores.emptyEntityStore.emptyEntityConfig.episodesDigit)
            const name = `${entityName}SR.${seasons}#${episodesStartStr}`
            let isReady = false
            diversity.forEach(s => {
                s.metadataTable = undefined
                if (s.groupCode === 'basic') {
                    if (this.fillWwTvseries(s.fields, seasons, episodesStartStr, name, progCode)) {
                        isReady = true
                    } else {
                        return
                    }
                }
                if (s.groupCode === 'program') {
                    const progcd = s.fields.find(f => f.fieldName === WwCustomField.progcd)
                    if (progcd) {
                        progcd.value = this.currentProgramCode.value
                        progcd.isShow = true
                    }
                }
            })
            if (isReady) {
                /** 自动创建分集 */
                if (!promises[Math.floor(i / 5)]) {
                    promises[Math.floor(i / 5)] = []
                }
                promises[Math.floor(i / 5)].push(
                    emptyEntityApi.createEmptyEntity(diversity).then(cRes => {
                        this.changeModalInfo(cRes.data.success, '', name)
                    }, err => {
                        this.changeModalInfo(false, (err.data && err.data.error && err.data.error.title) || '', name)
                    })
                )
            }
        }

        if (promises.length) {
            this.isAutoCreating = true
            let task = new Promise((resolve: any) => { resolve() })
            promises.forEach(pArr => {
                if (pArr && pArr.length) {
                    task = task.then(() => {
                        if (!this.isAutoCreating) {
                            return
                        }
                        if (this.modalInfo.percent === 100) {
                            message.success('创建完毕'.l('emptyEntity.autoCreateDone'))
                        } else {
                            Promise.all(pArr)
                        }
                    })
                }
            })
        }
    }

    @action
    public setAutoCreateModalShow(show: boolean) {
        this.isAutoCreating = show
    }

    @action
    public setProgramModalShow(show: boolean, type?: 'create' | 'edit') {
        this.showProgramModal = show
        this.isCreate = type !== 'edit'
    }

    @action
    public calcDuration = (group: emptyEntityTypes.IProgramField, index: number, value: string) => {
        const fields: IFormItem[] = _.clone(group.metadataTable.current.getItems())
        try {
            const table = JSON.parse(value)
            if (_.isArray(table)) {
                table.forEach((seq: any) => {
                    if (seq.outpoint !== undefined && seq.outpoint > (seq.inpoint || 0)) {
                        seq.duration = seq.outpoint - (seq.inpoint || 0)
                    } else {
                        seq.duration = 0
                    }
                })
            }
            fields[index].value = JSON.stringify(table)
            group.fields = fields
        } catch (error) { }
    }

    @action
    public setVideoCategoryReadolny = (group: emptyEntityTypes.IProgramField) => {
        const progcdValue = this.currentProgramCode.value as string
        if (!progcdValue || progcdValue.length !== this.stores.emptyEntityStore.emptyEntityConfig.programCodeLength) {
            return
        }
        const fields: IFormItem[] = _.cloneDeep(group.metadataTable.current.getItems())
        const seasonsField = fields.find(s => s.fieldName === WwCustomField.newSeasonsStart)
        const episodesStartField = fields.find(s => s.fieldName === WwCustomField.newEpisodesStart)
        const seasonsStr = (seasonsField && !isNaN(Number(seasonsField.value))) ?
            this.numberAddZeroPrefix(Number(seasonsField.value), this.stores.emptyEntityStore.emptyEntityConfig.seasonsDigit) : '00'
        const episodesStartStr = (episodesStartField && !isNaN(Number(episodesStartField.value))) ?
            this.numberAddZeroPrefix(Number(episodesStartField.value), this.stores.emptyEntityStore.emptyEntityConfig.episodesDigit) : '00'

        const categoryField = fields.find(s => s.fieldName === WwCustomField.videoCategory)
        // if (categoryField) {
        //     categoryField.isReadOnly = progcdValue.length === 8
        //     if (categoryField.isReadOnly && categoryField.controlData) {
        //         const vcDict = JSON.parse(categoryField.controlData)
        //         if (vcDict) {
        //             Object.keys(vcDict).forEach(key => {
        //                 if (vcDict[key] && vcDict[key].substring(vcDict[key].length - 1) === progcdValue.substring(progcdValue.length - 1)) {
        //                     categoryField.value = JSON.stringify([key])
        //                 }
        //             })
        //         }
        //     }
        // }
        const tapenoField = fields.find(s => s.fieldName === WwCustomField.tapeno)
        if (tapenoField) {
            tapenoField.value = this.createTapeno(progcdValue, seasonsStr, episodesStartStr, categoryField)
        }
        group.fields = fields
    }

    public onMetadataChange = (items: IFormItem[], index: number, value: string | number) => {
        const { fieldName } = items[index]
        this.metadataGroup.forEach(group => {
            if (group.groupCode === 'basic' && (fieldName === WwCustomField.progcd || fieldName === WwCustomField.newEpisodesStart ||
                fieldName === WwCustomField.newSeasonsStart || fieldName === WwCustomField.videoCategory)) {
                this.setVideoCategoryReadolny(group)
            } else if (fieldName === WwCustomField.sequence && group.fields.length > index && group.fields[index].fieldName === fieldName) {
                this.calcDuration(group, index, value as string)
            }
        })
    }

    @action
    public onSelectFrameRate = (value: string | number) => {
        let frameRate: number | string = ''
        try {
            if (_.isString(value)) {
                const frameRateArr = JSON.parse(value)
                if (_.isNumber(frameRateArr)) {
                    frameRate = frameRateArr
                } else {
                    frameRate = frameRateArr[0]
                }
            } else if (_.isNumber(value)) {
                frameRate = value
            }
            this.selectFrameRate = Number(frameRate)
            const group = this.metadataGroup.find(s => s.groupCode === 'basic')
            if (group) {
                const frameRateMeta = _.find(group.fields, { fieldName: 'framerate' })
                if (frameRateMeta) {
                    frameRateMeta.value = frameRate
                }
            }
        } catch (error) {
            frameRate = value
        }
    }

    private validate() {
        let promise: any[] = []// Array<Promise<IMetaValidateAllResult>> = []
        this.metadataGroup.forEach(item => {
            if (item.metadataTable && item.metadataTable.current) {
                this.changeMetadata(item)
                promise.push(item.metadataTable.current.validateAll())
            }
        })
        return new Promise((resolve, reject) => {
            Promise.all(promise).then(results => {
                this.enterSaving()
                resolve(results)
            }, formCode => {
                reject(formCode)
                message.error('数据验证未通过'.l('emptyEntity.validateFail'))
            })
        })
    }

    @action
    private changeModalInfo(isSuccess: boolean, error: string, title: string) {
        if (!isSuccess) {
            this.modalInfo.errorList.push({
                title,
                message: error || '創建失败'.l('emptyEntity.addEntityFail')
            })
        }
        this.modalInfo.done++
        this.modalInfo.percent = Math.floor(this.modalInfo.done / (this.modalInfo.total - this.modalInfo.start + 1)) * 100
    }


    private fillWwTvseries(fields: IFormItem[], seasonsStr: string, episodesStartStr: string, entityName: string, progCode: string) {
        const wwTvseriesField = fields.find(f => f.fieldName === WwCustomField.tvseries)
        // 修改表格里的季集数
        if (wwTvseriesField && wwTvseriesField.controlData) {
            try {
                let table = [{
                    seasons_start: this.modalInfo.seasons,
                    episodes_count: this.modalInfo.total,
                    episodes_start: Number(episodesStartStr)
                }]
                wwTvseriesField.value = JSON.stringify(table)
                wwTvseriesField.isShow = true
            } catch (error) {
                return false
            }
        } else {
            message.warn('请填写季集数'.l('emptyEntity.noSessionOrEpisodes'))
            return false
        }
        const nameField = fields.find(f => f.fieldName === WwCustomField.name)
        if (nameField) {
            nameField.value = entityName
            nameField.isShow = true
        }
        const videoCategoryField = fields.find(f => f.fieldName === WwCustomField.videoCategory)
        if (progCode.length === this.stores.emptyEntityStore.emptyEntityConfig.programCodeLength && (!videoCategoryField || !videoCategoryField.value || (videoCategoryField.value as string).length < 3)) {
            message.warn('请选择影带类别'.l('emptyEntity.noVideocategory'))
            return false
        }
        if (videoCategoryField) {
            videoCategoryField.isReadOnly = false
        }
        const tapeno = fields.find(f => f.fieldName === WwCustomField.tapeno)
        if (tapeno) {
            tapeno.value = this.createTapeno(progCode, seasonsStr, episodesStartStr, videoCategoryField);
            if (!tapeno.value) {
                return false
            }
            tapeno.isShow = true
        }
        return true
    }

    private createTapeno(progCode: string, seasonsStr: string, episodesStartStr: string, videocategory: IFormItem | undefined) {
        let t20 = ''
        if (progCode.length === this.stores.emptyEntityStore.emptyEntityConfig.programCodeLength) {
            /** 规则2 生成8位代码 在结尾+季集数(季数不带占位0)+ */
            /** 影带类别显示名的最后一个字母（或中视取第一个字母） */
            let videocategoryCode = ''
            if (videocategory) {
                if (videocategory.controlData && videocategory.value !== undefined && (videocategory.value as string).length > 2) {
                    try {
                        const vcDict = JSON.parse(videocategory.controlData)
                        let vcValue = ''
                        if ((videocategory.value as string).indexOf('[') > -1) {
                            vcValue = JSON.parse(videocategory.value as string)[0]
                        } else {
                            vcValue = (videocategory.value as string)
                        }
                        const vcShowName = vcDict[vcValue]
                        if (vcValue) {
                            if (this.stores.emptyEntityStore.emptyEntityConfig.getVideocategoryFirstCode) {
                                videocategoryCode = vcShowName.substring(0, 1)
                            } else {
                                videocategoryCode = vcShowName.substring(vcShowName.length - 1)
                            }
                            /** 规则1 生成12位代码 前缀添加t20 季数带0占位 播出挡p的key必须是0 */
                            if (vcValue === '0' && this.stores.emptyEntityStore.emptyEntityConfig.useT20) {
                                t20 = 'T20'
                            }
                        }
                    } catch (error) { }
                }
            }
            if (!videocategoryCode) {
                return ''
            }
            return t20 +
                progCode +
                (t20 ? this.numberAddZeroPrefix(Number(seasonsStr), 10) : seasonsStr) +
                episodesStartStr +
                videocategoryCode
        }
        return ''
    }

    private numberAddZeroPrefix(num: number, digit: number) {
        let res = String(num)
        if (num < digit) {
            if (digit === 10) {
                res = '00'.substr(res.length) + res
            } else if (digit === 100) {
                res = '000'.substr(res.length) + res
            } else if (digit === 1000) {
                res = '0000'.substr(res.length) + res
            }
        }
        return res
    }
}