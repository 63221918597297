import { observable, action, runInAction } from 'mobx'
import ExportAuditExtendStore from "./exportAuditExtendStore"
import ContentMgStore from '../../contentMgStore'
import { Stores } from 'src/stores'
import exportAuditApi from "src/apis/exportAuditApi"
import exportAuditTypes from "src/types/exportAuditTypes"
import _ from 'lodash'
import { message } from 'antd'
import { commonUtil } from "mam-common-utils"

export default class ExportAuditStore extends ExportAuditExtendStore {
    @observable
    public topNav: string = '2'  //顶部导航菜单
    @observable
    public type: number = exportAuditTypes.Type.WAIT;/** 素材类型 */
    @observable 
    public pageSizeTypes:number[] = [20, 50, 100, 200]//页数
    @observable
    public page: number = 1
    @observable 
    public defaultPageSize:number= 20//选中页数
    @observable 
    public entityName:string= ""//任务标题
    @observable 
    public creatorName:string= ""//申请人
    @observable 
    public auditCurrentStep:number|null= null//审核步骤
    @observable 
    public createTaskTimeStart:string|null= null//开始时间
    @observable 
    public createTaskTimeEnd:string|null= null//结束时间
    @observable 
    public refuseOpinion:string=""//拒绝意见
    @observable 
    public columnsSetmodalVisible:boolean=false//列设置模态框
    @observable 
    public exportModalVisible:boolean=false//导出模态框
    @observable 
    public screenModalVisible:boolean=false//筛选模态框
    @observable 
    public  tableOptions:exportAuditTypes.ITableOptions[] = []//表格分类
    @observable 
    public  selectTableOptions:string[] = []//选中的表格分类
    @observable
    public isSearching: boolean = false
    @observable
    public exportAuditListResponse:exportAuditTypes.IExportAuditListResponse /** 素材 */
    @observable 
    public selectData:exportAuditTypes.IGetExportauditList[]=[]//选中的数组
    @observable
    public selectedEntity: exportAuditTypes.IGetExportauditList //当前列表点击的素材
    @observable
    public showViewEntityModal: boolean = false
    @observable
    public showMetadata: boolean = false

    public constructor(stores: Stores, contentMgStore: ContentMgStore) {
        super(stores)
        this.initEntityStore();
    }
    @action
    public changeType(type: number) {
        this.type = type;
        this.getExportauditList(1)
    }
    @action
    public setScreenData(screenData:exportAuditTypes.IScreenData) {
            this.entityName = screenData.entityName;
            this.creatorName = screenData.creatorName;
            this.auditCurrentStep = screenData.auditCurrentStep;
            this.createTaskTimeStart = screenData.createTaskTimeStart;
            this.createTaskTimeEnd = screenData.createTaskTimeEnd;
            this.getExportauditList(1)
    }
    @action
    public setTableOptions(tableOptions:exportAuditTypes.ITableOptions[]) {
        this.tableOptions = tableOptions;
    }
    @action
    public setTopNav(topNav:string) {
        this.topNav = topNav;
    }
    @action
    public setPage(page:number) {
        this.page = page
    }
    @action
    public setSelectTableOptions(tableOptions:string[]) {
        this.selectTableOptions = tableOptions;
    }
    @action
    public setColumnsSetmodal(modal: boolean) {
        this.columnsSetmodalVisible = modal;
    }
    @action
    public setExportModal(modal: boolean) {
        this.exportModalVisible = modal;
    }
    @action
    public setScreenModal(modal: boolean) {
        this.screenModalVisible = modal;
    }
    @action
    public setRefuseOpinion(value: string) {
        this.refuseOpinion = value;
    }
    @action
    public changeDefaultPageSize(pageSize:number){
        this.defaultPageSize=pageSize
        this.getExportauditList(1)
    }
    @action
    public changeSelectData(selectData:exportAuditTypes.IGetExportauditList[]){
        this.selectData=selectData
    }
    //表格数据
    @action
    public getExportauditList(page?:number){
        this.isSearching = true
        if(page){
            this.page = page
        }
        let params={
            auditStatus: this.type,
            order: "desc",
            sort: "createTaskTime",
            taskState: null,
            creatorName: this.creatorName,
            entityName: this.entityName,
            auditCurrentStep: this.auditCurrentStep,
            createTaskTimeEnd: this.createTaskTimeEnd,
            createTaskTimeStart: this.createTaskTimeStart,
            keyword: "",
            pageIndex: this.page,
            pageSize: this.defaultPageSize,
        }
        return new Promise((resolve, reject) => {
            exportAuditApi.getExportauditList(params).then(res => {
                runInAction(() => {
                    if(res.data.success && res.data.data) {
                        if(res.data.data.data){
                            res.data.data.data.map((o,i)=>{
                                if(!o.contentId){
                                    o.contentId = o.contentID
                                }
                            })
                        }
                        this.getKeyframe(res.data.data)
                        // this.exportAuditListResponse=res.data.data
                        resolve(true)
                    } else {
                        reject(false)
                    }
                })
            }, res => {
                if(res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    //下载
    @action
    public exportData(item:exportAuditTypes.IExportData,isAll?:boolean){
        let params={
            auditStatus: this.type,
            order: "desc",
            sort: "createTaskTime",
            taskState: null,
            creatorName: this.creatorName,
            entityName: this.entityName,
            auditCurrentStep: this.auditCurrentStep,
            createTaskTimeEnd: this.createTaskTimeEnd,
            createTaskTimeStart: this.createTaskTimeStart,
            keyword: "",
            pageIndex: item.start,
            pageSize: item.end,
            exportAll:false
        }
        if(isAll){
            params.exportAll=true
        }
        return new Promise((resolve, reject) => {
            commonUtil.downloadFile('/proxy/flowstep/exportaudit/export-audittask', (xmlResquest:any) => {
                let filename = "";
                let disposition = xmlResquest.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]){
                        filename = matches[1].replace(/['"]/g, '');
                    } 
                }
                    return decodeURI(filename);
            }, undefined, 'POST' ,JSON.stringify(params))        })
    }
    //type=true通过||type=false拒绝  
    // @action
    // public auditTask(type:boolean){
    //     let params={
    //         auditPass: type,
    //         reason: this.refuseOpinion,
    //         taskIds: this.selectData.map(v=>v.exportTaskID)
    //     }
    //     return new Promise((resolve, reject) => {
    //         exportAuditApi.auditTask(params).then(res => {
    //             if(res.data.success&&res.data.data) {
    //                 if(type){//通过
    //                     let failedCount = 0;
    //                     for (let i = 0; i < res.data.data?.data.length; i++) {
    //                         let currentRow = res.data.data?.data[i];
    //                         //有错误信息
    //                         if (currentRow.message) {
    //                             ++failedCount;
    //                         }
    //                     }
    //                     if (failedCount === this.selectData.length) {
    //                         message.error("审核" + this.selectData.length + "条任务，但服务对每条任务均返回了异常，请检查后再试!");
    //                     } else if (failedCount > 0) {
    //                         message.error("审核" + this.selectData.length + "条任务，但发送到务的时候，失败了:" + failedCount + "条!");
    //                     } else {
    //                         message.success(res.data.data.message);
    //                     }
    //                 }else{//拒绝
    //                     message.success(res.data.data.message);
    //                 }
    //                 setTimeout( ()=> {
    //                     this.getExportauditList(1)
    //                 }, 600); //延迟更新
    //                 resolve(true)
    //             } else {
    //                 reject(false)
    //             }
    //         }, res => {
    //             if(res.data.error && res.data.error.title) {
    //                 message.error(res.data.error.title)
    //             }
    //             reject()
    //         })
    //         this.selectData=[]
    //         this.refuseOpinion=""
    //     })
    // }
    //导出计算
    @action 
    public getExportData() {
        if(this.exportAuditListResponse){
            this.exportAuditListResponse.pageSize = Number(this.exportAuditListResponse.pageSize)
            let pageTotal = Math.ceil(this.exportAuditListResponse.recordTotal / this.exportAuditListResponse.pageSize);
            let models = [];
            for (let i = 1; i <= pageTotal; i++) {
                models.push({
                    index: i,
                    start: ((i - 1) * this.exportAuditListResponse.pageSize) + 1,
                    end: (i !== pageTotal) ? i * this.exportAuditListResponse.pageSize : this.exportAuditListResponse.recordTotal,
                });
            }
            return models
        }
        return []
    }

    @action
    public setSelectedEntity(selectedEntity: exportAuditTypes.IGetExportauditList) {
        this.selectedEntity = selectedEntity
    }
    
    @action
    public setShowMetadata(showMetadata:boolean) {
        this.showMetadata = showMetadata
    }
    @action
    public setShowViewEntityModal(showViewEntityModal:boolean) {
        this.showViewEntityModal = showViewEntityModal
    }
    /*继续查询获取关键帧、归属者 */
    @action
    public getKeyframe(listRes: exportAuditTypes.IExportAuditListResponse) {
        if(listRes && listRes.data){
            let contentIds: string[] = _.map(listRes.data, 'contentId')
            contentIds = Array.from(new Set(contentIds))
            exportAuditApi.getKeyframe(contentIds).then(res=>{
                runInAction(()=>{
                    if (res.data.success){
                        if(res.data.data && res.data.data.length > 0){
                            res.data.data.map((o)=>{
                                listRes.data.map((item)=>{
                                    if(item.contentId === o.contentId_){
                                        item.keyframe_ = o.keyframe_
                                        item.createUser_ = o.createUser_
                                    }
                                })
                            })
                        }
                        this.exportAuditListResponse = listRes
                        this.isSearching = false
                    }
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            })
        }
    }
}
