import {IConfigRes, IConfig, ILoginConfig, IUiModule} from "../types/configTypes";
import {http} from "../utils/http";
import {AxiosPromise} from "axios";
import {IResponse} from "mam-core-react/dist/modules/http";

namespace configApi {
    export const getBaseInfo = (): AxiosPromise<IResponse<IConfigRes<IConfig>>> =>  {
        return http.get<IResponse<IConfigRes<IConfig>>>('/config/get-base-info')
    }

    export const getLoginConfig = (): AxiosPromise<IResponse<IConfigRes<ILoginConfig>>> => {
        return http.get<IResponse<IConfigRes<ILoginConfig>>>('/config/get-login-config')
    }

    export const getSearchConfig = (): AxiosPromise<IResponse<IConfigRes<IConfig>>> => {
        return http.get<IResponse<IConfigRes<IConfig>>>('/config/get-search-config')
    }

    export const getUiModules = (keys: string): AxiosPromise<IResponse<IUiModule[]>> => {
        let version = process.env.BUILD_TYPE;
        return http.get<IResponse<IUiModule[]>>(`/config/ui-module?keys=${keys}&version=${version}`)
    }

    //获取配置
    export const getEntityConfig = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/config/get-entity-config');
    }
}

export default configApi