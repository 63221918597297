import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import AppStore from '../../appStore';

export default class FindStore extends ExtendStore {
    @observable
    public selectedTabKey: string = 'recommend'
    private appStore: AppStore;
    
    public constructor(stores: Stores, appStore: AppStore) {
        super(stores);
        this.appStore = appStore;
    }

    @action
    public setSelectedTabKey(key: string){
        this.selectedTabKey = key;
        if (this.stores.iIndexStore.secListStore){
            this.stores.iIndexStore.secListStore.setSelectedTab('');
        }
    }
}