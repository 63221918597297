import { observable, action, runInAction } from 'mobx'
import { Stores } from 'src/stores'
import UseAuditExtendStore from "./useAuditExtendStore"
import ContentMgStore from '../../contentMgStore'
import contentMgApi from 'src/apis/contentMgApi'
import contentMgTypes from 'src/types/contentMgTypes'
import _ from 'lodash'
import { message } from 'antd'

export default class UseAuditStore extends UseAuditExtendStore {
    @observable
    public topNav: string = 'waited'  //顶部导航菜单
    
    /*公共-列表*/
    @observable
    public listSelectedRowKeys: string[] = []
    @observable
    public listSelectedRows:contentMgTypes.useAudit.IUseAuditListItem[] = []
    @observable
    public keyword: string = ''
    @observable
    public page: number = 1
    @observable
    public size: number = 30
    @observable
    public currentSort: string = "createTaskTime:desc"  //desc降序asc升序
    @observable
    public params: contentMgTypes.useAudit.IQueryReq
    @observable
    public queryListRes: contentMgTypes.useAudit.IQueryRes
    @observable
    public isSearching: boolean = false
    @observable
    public auditStep: string = ""
    @observable
    public selectedEntity: contentMgTypes.useAudit.IUseAuditListItem
    @observable
    public showViewEntityModal: boolean = false
    @observable
    public showMetadata: boolean = false
    
    public auditStepList: contentMgTypes.useAudit.IAuditStepListItem[] = [
        {name:"全部".l('com.all'),value:""},
        {name:"一审".l('useAudit.firstAudit'),value:"1"},
        {name:"二审".l('useAudit.secondAudit'),value:"2"},
        {name:"终审".l('useAudit.endAudit'),value:"3"},
    ]

    public constructor(stores: Stores, contentMgStore: ContentMgStore) {
        super(stores);
        this.initEntityStore();
    }


    @action
    public setSelectedEntity(selectedEntity: contentMgTypes.useAudit.IUseAuditListItem) {
        this.selectedEntity = selectedEntity
    }
    
    @action
    public setShowMetadata(showMetadata:boolean) {
        this.showMetadata = showMetadata
    }
    @action
    public setShowViewEntityModal(showViewEntityModal:boolean) {
        this.showViewEntityModal = showViewEntityModal
    }
    @action
    public setAuditStep(auditStep:string) {
        this.auditStep = auditStep
    }
    @action
    public setCurrentSort(currentSort: string) {
        this.currentSort = currentSort
    }
    @action
    public setTopNav(topNav: string) {
        this.topNav = topNav
        this.clearSearch()
        this.initCurrentSort()
    }
    @action
    public initCurrentSort() {
        // if(this.topNav === 'all'){
        //     this.currentSort = 'createTaskTime:desc'
        // }else{
        //     this.currentSort = 'auditTime:desc'
        // }
        this.currentSort = 'createTaskTime:desc'
    }
    @action
    public setKeyword(keyword:string) {
        this.keyword = keyword
    }
    @action
    public setPage(page:number) {
        this.page = page
    }
    @action
    public setSize(size:number) {
        this.size = size
    }
    /* auditStatus 审核状态：''全部  2待审 8通过 4已打回*/
    @action
    public getAuditStatus() {
        switch(this.topNav){
            case "all": return '';
            case "waited": return 2;
            case "passed": return 8;
            case "rejected": return 4;
            default: return '';
        }
    }
    /* 清空筛选条件 */
    @action
    public clearSearch() {
        this.setKeyword('')
        this.setAuditStep('')
        this.initCurrentSort()
    }
    /* 设置查询参数 */
    @action
    public setParams(params: contentMgTypes.useAudit.IQueryReq) {
        this.params = params
    }

    /*列表选中项keys及设置选中的id*/
    @action
    public setListSelected=(selectedRowKeys:any,selectedRows:any[])=>{
        this.listSelectedRowKeys = selectedRowKeys
        this.listSelectedRows = selectedRows
    }
    /*列表清空选中*/
    @action
    public clearListSelected=()=>{
        this.setListSelected([],[])
    }

    /* 任务列表数据 */
    @action
    public queryList(page?: number){
        this.clearListSelected()
        this.isSearching = true
        this.params = {
            keyword: this.keyword,
            pageIndex: page || this.page,
            pageSize: this.size,
            taskState: null,
            auditCurrentStep: this.auditStep,
            auditStatus: this.getAuditStatus(),
            order: "desc",
            sort: "createTaskTime",
        }
        if(this.currentSort){
            this.params.sort = this.currentSort.split(':')[0]
            this.params.order = this.currentSort.split(':')[1]
        }else{
            this.params.sort = 'createTaskTime'
            this.params.order = 'desc'
        }
        if(this.topNav === 'waited'){
            
        }
        this.queryTask()
    }

    @action
    public queryTask(){
        return new Promise((resolve, reject) => {
            contentMgApi.useAudit.getList(this.params).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        if(res.data.data.data){
                            res.data.data.data.map((o,i)=>{
                                if(!o.contentId){
                                    o.contentId = o.contentID
                                }
                            })
                        }
                        this.getKeyframe(res.data.data)
                        resolve(true)
                    }else {
                        reject()
                    }
                })
            },(res)=>{
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        });
    }

    /*继续查询获取关键帧、归属者 */
    @action
    public getKeyframe(listRes: contentMgTypes.useAudit.IQueryRes) {
        if(listRes && listRes.data){
            let contentIds: string[] = _.map(listRes.data, 'contentId')
            contentIds = Array.from(new Set(contentIds))
            contentMgApi.useAudit.getKeyframe(contentIds).then(res=>{
                runInAction(()=>{
                    if (res.data.success){
                        if(res.data.data && res.data.data.length > 0){
                            res.data.data.map((o)=>{
                                listRes.data.map((item)=>{
                                    if(item.contentId === o.contentId_){
                                        item.keyframe_ = o.keyframe_
                                        item.createUser_ = o.createUser_
                                    }
                                })
                            })
                        }
                        this.queryListRes = listRes
                        this.isSearching = false
                    }
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            })
        }
    }
}

