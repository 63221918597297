import { observable, action, runInAction } from 'mobx';
import BaseStore from '../base/baseStore';
import emptyEntityTypes from 'src/types/emptyEntityTypes';
import emptyEntityApi from 'src/apis/emptyEntityApi';
import ProgramInfoStore from './programInfoStore';
import BindUploadStore from './bindUploadStore'
import { Stores } from '..';
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import { IEmptyEntityConfig } from 'src/types/configTypes';

export default class EmptyEntityStore extends BaseStore<Stores> {
    public bindUploadStore: BindUploadStore
    public entityCommonMetadataStore: ProgramInfoStore
    public commonMetadataStore: ProgramInfoStore
    /*create-entity、common-metadata、upload */
    @observable
    public selectedTab: string = 'create-entity'
    @observable
    public entityMetadataGroup: emptyEntityTypes.IProgramField[] = []
    @observable
    public metadataGroup: emptyEntityTypes.IProgramField[] = []
    @observable
    public entityCodeSource: string[] = []
    @observable
    public currentEntityCode: IFormItem
    /** 配置 */
    @observable
    public emptyEntityConfig: IEmptyEntityConfig = {
        programCodeLength: 4,
        episodesDigit: 10,
        seasonsDigit: 0,
        useT20: true
    }

    constructor(stores: Stores) {
        super(stores)
        this.bindUploadStore = new BindUploadStore(stores)
        this.entityCommonMetadataStore = new ProgramInfoStore(stores, 'create-entity')
        this.commonMetadataStore = new ProgramInfoStore(stores, 'common-metadata')
    }

    @action
    public onEntityCodeChange = (code: string) => {
        this.currentEntityCode.value = code
        let item = this.metadataGroup.find(s => s.groupCode === this.entityCommonMetadataStore.openMetadataCodes[0])
        this.entityCommonMetadataStore.changeMetadata(item)
        if (this.currentEntityCode.value) {
            emptyEntityApi.getEmptyEntity(code).then(res => {
                runInAction(() => {
                    this.entityCodeSource = (res.data.data || []).filter(s => s.tapeno).map(s => s.tapeno)
                })
            }, () => {
                runInAction(() => {
                    this.entityCodeSource = []
                })
            })
        } else {
            this.entityCodeSource = []
        }
    }

    @action
    public selectEntityCode = (code: string) => {
        this.currentEntityCode.value = code
    }

    @action
    public changeTab(tabKey: string) {
        this.selectedTab = tabKey
    }

    @action
    public setEmptyEntityConfig() {
        if (this.stores.configStore.config.emptyEntityConfig) {
            this.emptyEntityConfig = this.stores.configStore.config.emptyEntityConfig
        }
    }
}