import { IBaseEntity, IBasePermission } from './commonTypes';
import { ITag } from "../pages/newEntity/modules/rightTabContent/contentStruct/storyTreeList/tags";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";

namespace ccTypes {
    export enum DataViewModes {
        LARGE = 1,   // 图例模式
        CARD = 2,    // 明信片模式
        LIST = 3,   // 列表模式
        PREVIEW = 4  // 预览模式
    }

    export interface IParams {
        isPublic?: boolean
        isChild?: boolean
        page: number
        conditions: ccTypes.searchTypes.ISearchCondition[]
    }

    export enum FormatFlag {
        SD = 1,
        HD = 2,
        FHD = 3,
        SHD = 4,
        None = 0
    }

    export const COMMON_USER_HABIT_KEY: string = 'common_systemSearch';
    export const COMMON_MODULE_NAME: string = 'common';
    export const SEARCH_USER_HABIT_KEY: string = 'systemSearch';
    export const SEARCH_MODULE_NAME: string = 'search';

    export interface IUserHabitHabitValue {
        templateName: string
        conditions: ccTypes.searchTypes.ISearchCondition[]
    }

    export interface IUserHabit {
        habitKey: string
        habitValue: string
        id: number
        moduleName: string
        userCode: string
    }

    export interface ISaveUserHabitReq {
        key: string,
        value: string,
        moduleName: string,
        creator?: string
    }

    export interface IPartsKeyframesReq{
        contentId: string
        keyFrameNo: string
        frameRate: number
    }

    export interface IPrivilege {
        createTime: Date
        deleted: number
        id: number
        ownerCode: string
        siteCode: string
        siteName: string
        templateCode: string
        templateName: string
        templateType: string
        selected: boolean;
    }
    export interface IBasketItemData {
        addTime: string
        addUser: string
        desc: string
        entity: createFolder.ICreateFolder
        entityType: string
        id: number
        inPoint: number
        outPoint: number
        title: string
        relateId: string
        // 在store使用
        selected: boolean
        contentId?: string
        guid?: string
    }
    export interface IBasketItemsReqData {
        pageSize: number
        pageIndex: number
        type?: string
    }
    export interface IBasketAddItems {
        desc: string
        entityType: string
        relateId: string
        inPoint?: number
        outPoint?: number
        title?: string
        guid?: string
    }
    // 挑选篮获取对应的数量
    export interface IBasketItems {
        data: IBasketItemData[]
        pageIndex: number
        pageSize: number
        pageTotal: number
        recordTotal: number
    }

    export interface IPrivateTreeStorageSpaceItem{
        usedSize: number
        remainingSpace: number
    }
    export interface IPrivateTreeStorageSpaceRes{
        high: IPrivateTreeStorageSpaceItem
        keyframe: IPrivateTreeStorageSpaceItem
        low: IPrivateTreeStorageSpaceItem
    }

    export namespace sideContentTypes {
        export interface ISearchReqCondition {
            field: string
            nexus?: string
            value: string | string[]
        }
        export interface ISearchTreeSubItem {
            showName: string
            conditions: ISearchReqCondition[]
            active?: boolean
        }
        export interface ISearchTreeRes {
            id: number
            showName: string
            conditions: ISearchReqCondition[]
            subItems: ISearchTreeSubItem[],
            showCon?: boolean
            active?: boolean
        }

        export interface IFolderReq {
            pageIndex: number
            pageSize: number
            sort: string
            order: string
            folderCode: string
            type: string
            keyword: string
            name: string
        }

        export interface IWorkteamRes {
            creatorCode: string
            createTime?: string
            delete?: number
            groupCode: string
            groupName?: string
            id?: number
            priviligeLevel?: number
            siteCode?: string
            siteName?: string
            type?: string[]
        }
    }

    export namespace searchTypes {
        export interface ISearchCondition {
            key?: string
            value?: string[]
            items?: ccTypes.sideContentTypes.ISearchReqCondition[]
            name?: string
            field?: string
            array?: boolean
            type?: string
        }

        export interface ISearchParams extends ccTypes.IParams {
            folder?: string
            folderCode?: string
            order?: string
            keyword: string[]
            advancedSearch?: IAdvancedSearchTypes
            resourceName?: string
            imageSearchBase64?: string
        }

        export interface IAdvancedSearchTypes {
            field: IAdvancedSearchField[],
            isOnlyAvSearching: boolean
        }

        export interface IAdvancedSearchField {
            alias: string
            displayValue: string
            field: string
            searchRelation: string
            value: string | boolean
            controlData?: string
        }

        export interface ISortItem {
            text: string
            field: string
            desc: string
            hideDirection?: boolean
        }

        export interface IFullSearchSortBy {
            fieldName: string
            isDesc: boolean
        }
        export interface IFullSearchReq {
            conditions: ccTypes.sideContentTypes.ISearchReqCondition[]
            keyword: string[]
            page: number
            size: number
            sortBys: IFullSearchSortBy[]
            advancedSearch?: {
                field: IAdvancedSearchField[]
                isOnlyAvSearching: boolean
            },
            highLight?: boolean
            resourceName?: string
            lastCount?: number //检索相关推荐增加一个请求参数：主检索结果的个数
            editConditions?: any[] //无锡定制粗精切状态的检索
            rejectConditions?: any[] //无锡定制驳回任务的检索
        }

        export interface IQueryResultDataPermissionInfo {
            canRead: boolean
            canWrite: boolean
            canExecute: boolean
            canDelete: boolean
        }
        export interface IQueryResultData {
            id: string
            site_: string
            contentId_: string
            contentId: string
            name_: string
            name: string
            createDate_: string
            createUser_: string
            description?: string
            createdate: string
            creator: string
            catalogstate: string
            type_: string
            type: string
            aspect_: number
            filesize: number
            fileSize_: number
            size_: number
            tree_path_: string
            treePath: string
            formatFlag: number
            trimin: string
            trimout: string
            frameRate: number
            durationTc: string
            distributionMark: string
            isCollected: boolean
            siteName: string
            isPickedup: boolean
            isPlaceholder: number
            isPublish: number
            keyframepath: string
            keyframe: string
            privilege_: string
            permissionInfo: IQueryResultDataPermissionInfo
            resourceType: string
            archiveList: string[]
            barcodes: string[]
            meterialLabel: string[]
            dynamicResponse: any
            duration: string
            isFavorite: boolean
            storagedDate: string
            storagedUser: string
            isMy: boolean
            isOwner?: boolean
            canRead: boolean
            canWrite: boolean
            canExecute: boolean
            canDelete: boolean
            fileExt: string
            pictureCount?: number
            editMode?: boolean
            planDeleteTime?: string
            selected?: boolean  // 是否选中
            shortUrl?: string
            oldName?: string
            storyid?: string // 片段id
            sceneid?: string // sceneid
            shotid?: string //shotid
            inpoint?: number // 入点
            outpoint?: number // 出点
            tags?: ITag[]//片段标签
            relevance?: string // 相关度
            guid_?: string
            programNameCloud?: string  //云端状态码
            personId?: string
            clusterid?: string
            face_keyword?: any[]
        }
        export interface IQueryResult {
            pageIndex: number
            pageTotal: number
            pageSize: number
            recordTotal: number
            data: IQueryResultData[]
        }
        export interface IFacetsFacetValue {
            count?: number
            showValue: string
            value: string | string[]
            isShow?: boolean
            isSelected?: boolean
        }
        export interface IFacets {
            facetFieldName: string
            facetLayer?: number
            facetShowName: string
            facetValue?: IFacetsFacetValue[]
            checkboxIsOpen?: boolean
            startDate?: string
            endDate?: string
            showMore?: boolean
            searchKeyword?: string
        }
        export interface IFullSearchRes {
            queryResult: IQueryResult
            facets: IFacets[]
            sortFields: string[]
            usedTime: number
        }
        export interface IRecommendSearchRes {
            data: IQueryResultData[]
            words: string[]
            recommendType: string
        }
        export interface IRecommendSmartFullSearchRes {
            data: ISmartQueryResultData[]
            words: string[]
            recommendType: string
        }

        export interface ISmartFullSearchRes {
            queryResult: ISmartQueryResult
            sortFields: string[]
            facets: IFacets[]
            usedTime: number
        }

        export interface ISelectedFacets {
            showValue: string,
            parentName: string,
            value: string[]
        }

        export interface ISmartQueryResult {
            data: ISmartQueryResultData[]
            pageIndex: number
            pageSize: number
            pageTotal: number
            recordTotal: number
        }

        export interface ISmartQueryResultData {
            contentId_: string
            createDate_: string
            createUser_: string
            distributionMark: string
            eventorg: string
            guid_: string
            inpoint: number
            keyframeno: string
            keyword: string
            metaid_: string
            namespace_: string
            operateCode: string
            outpoint: number
            privilegeUserGroup_: string
            privilege_: string
            score: string
            site_: string
            storyid: string
            system_: string
            title: string
            scenename?: string
            type_: string
            _identity_: string
            _version_: string
            userName: string
            eventpeople: string
            eventplace: string
            keyframepath?: string
            isFavorite: boolean
            topic?: string
            durationTc: string
            sceneid?: string
            shotid?: string
        }

        export interface IEntityPathData {
            duration: number,
            filePathPrefix: string,
            bitRate: number,
            fileGUID: string,
            filePath: string,
            frameRate: number,
            size: number,
            height: number,
            width: number,
            durationTc: string,
            groupName: string,
            imageHeight: number,
            imageWidth: number
        }

        export interface IEntityPath {
            hostId: string,
            success: boolean
            data: IEntityPathData[]
        }

        export interface IRecordOperationHabitReq {
            id: string
            entityType: string
        }
        export interface IProgramNameCloudResItem {
            contentId: string
            programNameCloud: string
        }
        
    }

    export namespace cloudTypes {
        export interface ICloudParamsFieldConditions {
            resourceType: string
        }
        export interface ICloudParams extends ccTypes.IParams {
            folderCode: string
            searchType: string
            keyword: string
            type: string
            isQueryDirectSub?: boolean
            fieldConditions?: ICloudParamsFieldConditions
            sort?: string
            order?: string
            name?: string
            advancedSearch?: ccTypes.searchTypes.IAdvancedSearchTypes
            metadataFieldInfos?: IFormItem[]
        }

        export interface IFullSearchParentDynamicResponse {
            folderpath: string
            deleteflag: string
            subtype: string
            rlvideoformatid: string
            userCode: string
            rhvideoformatid: string
            id: string
        }
        export interface IFullSearchParent {
            count: number
            treeType: number
            name: string
            type: string
            contentId: string
            frameRate: number
            fileExt: string
            isFavorite: boolean
            aspect_: number
            formatFlag: number
            isMy: boolean
            dynamicResponse: ccTypes.cloudTypes.IFullSearchParentDynamicResponse
            canRead: boolean
            canWrite: boolean
            canExecute: boolean
            canDelete: boolean
        }
        export interface IFullSearchFacetsFacetValue {
            count: number
            showValue: string
            value: string
            isSelected?: boolean
            isShow?: boolean
        }
        export interface IFullSearchFacets {
            facetFieldName: string
            facetLayer: number
            facetShowName: string
            facetValue: ccTypes.cloudTypes.IFullSearchFacetsFacetValue[]
            checkboxIsOpen?: boolean
            showMore?: boolean
            searchKeyword?: string
            startDate?: string
            endDate?: string
        }
        export interface IFullSearchDataDynamicResponse {
            shareflag: string
            previewFile_: string
            namespace_: string
            keyframe: string
            subtype: string
            subentitycount: number
            fileGroupNames_: string
            smartProcessResult_: string
            selector: string
            clipsource: string
            digitizstate?: string
            programguid?: string
        }
        export interface IFullSearchData extends IBaseEntity, IBasePermission {
            count: number
            treeType: number
            name: string
            type: string
            type_: string
            frameRate: number
            fileExt: string
            isFavorite: boolean
            isCollected: boolean
            keyframe: string
            keyframepath?: string
            folderPath: string
            siteCode: string
            storagedDate: string
            storagedUser: string
            aspect_: number
            formatFlag: number
            isMy: boolean
            dynamicResponse: ccTypes.cloudTypes.IFullSearchDataDynamicResponse
            pictureCount?: number
            selected?: boolean
            editMode?: boolean
            oldName?: string
            isCreate?: boolean
            parentId?: string
            description?: string
            duration?: string
            durationTc?: string
            site_?: string
            inpoint?: number
            outpoint?: number
            programNameCloud?: string  //云端状态码
            children?: any  //子集
            showSub?: boolean
        }
        export interface IFullSearchRes {
            parent: ccTypes.cloudTypes.IFullSearchParent[]
            storageSize: number
            storageUsage: number
            facets: ccTypes.cloudTypes.IFullSearchFacets[]
            pageIndex: number
            pageTotal: number
            pageSize: number
            recordTotal: number
            data: ccTypes.cloudTypes.IFullSearchData[]
            extendedData?: any
        }
        export interface IFullSearchCondition {
            field: string
            value: string | string[]
        }
        export interface IFullSearchReq {
            pageIndex: number
            pageSize: number
            sort: string
            order: string
            keyword: string
            folderCode: string
            type: string
            name: string
            fieldConditions?: ccTypes.cloudTypes.ICloudParamsFieldConditions
            condition: ccTypes.cloudTypes.IFullSearchCondition[]
            isQueryDirectSub: boolean
            advancedSearch?: ccTypes.searchTypes.IAdvancedSearchTypes
            metadataFieldInfos?: IFormItem[]
        }
    }

    export namespace workSpaceTypes {
        export interface IWorkSpaceReq {
            pageIndex: number
            pageSize: number
            sort: string
            order: string
            folderCode: string
            type: string
            keyword: string
            name: string
        }
    }

    export namespace relativeInfo {
        export interface IGetRelativeInfoRes {
            tag: string[]
            value: {
                confidence: number
                description: string
                iconPath: string
                level: number
                name: string
                tag: string[]
                title: string
                type: string
            }
        }

        export interface IEntityRelationRelationShip {
            type: string
            name: string
        }
        export interface IGetEntityRelationRes {
            relationShip: IEntityRelationRelationShip[]
            tag: string[]
        }

        export interface IGetRelativeEventsRes {
            createTime: string
            description: string
            eventDate: string
            iconPath: string
            id: number
            name: string
            picturePaths: string[]
            propertyData: any
            source: string
            type: any
        }

        export interface IGetRelativeAlbums {
            albumtype: string
            name_: string
            createDate_: string
            createUser_: string
            keyframe_: string
            contentId_: string
            operateCode: string
            entityCount: number
            broswerCount: number
            site_: string
        }
    }

    /**
     * checkName
     */

    export namespace CheckName {
        export interface ICheckNameReq {
            ContentId: string
            Type: string
            NewName: string
            ParentId: string
        }

        export interface ICheckNameRes {
            haveQuestionModel: boolean
            questionModelCount: number
            questionModels: IQuestionModels[]
        }

        interface IQuestionModels {
            aspect_: number
            canDelete: boolean
            canExecute: boolean
            canRead: boolean
            canWrite: boolean
            contentId: string
            dynamicResponse: any
            fileExt: string
            formatFlag: number
            frameRate: number
            isFavorite: boolean
            isMy: boolean
            name: string
        }

        export interface ISearchRenameReq {
            basicInfo: {
                contentId: string
            }
            updateInfo: {
                name_: string
            }
        }
    }

    export namespace createFolder {
        export interface ICreateFolderReq {
            contentId: string
            name: string
            parentId: string
            type: string
        }
        export interface ICreateFolder {
            aspect_: number
            canDelete: boolean
            canExecute: boolean
            canRead: boolean
            canWrite: boolean
            contentId: string
            count: number
            createTime: string
            dynamicResponse: any
            fileExt: string
            formatFlag: number
            frameRate: number
            isFavorite: boolean
            isMy: boolean
            name: string
            treeType: number
            type: string
            size?: string
            siteCode?: string
            durationTc?: string
            isCreate?: boolean
            keyframe: string
            catalogstate: string
            archiveList?:string[]
            inpoint?: number 
            outpoint?: number
            type_?: string
            guid_?: string
        }
    }

    export interface ICreateCatalogueRes {
        key: string
        value: string
        name?: string
    }

    export interface IBasketEditItem {
        contentId: string
        title: string
        keyframe: string
        entityType: string
    }

    export interface IBasketCannotWriteItem {
        name: string
        reason: string
    }

    export interface IExportFileds {
        selected?: boolean,
        resourceFieldName?: string,
        resourceDesc?: string,
        type?: string,
        format?: string
        key?: number
        pitchOn?: number
        createUser?: string
        createTime?: string
    }

    export namespace Dataset {
        export interface IAddEntityRequest {
            /** 资料集id */
            datasetId: string
            /** 文件夹id 目标文件夹，不传默认为根目录 */
            folderId?: string
            /** 素材列表 */
            entitys: any[]
        }

        // export interface ICheckIsInFolderReq {
        //     datasetId: string //当前资料集ID
        //     entitys: any[]  //素材
        //     folderId: string  //当前路径
        // }

        export interface ICheckIsInFolderResponse {
            error: IBaseEntity[]
            success: IBaseEntity[]
        }
    }

    //元数据某一项的配置数据
     export interface IMetadataFieldSetItem {
        name: string
        code: string
    }
}
export default ccTypes
