import ExtendStore from 'src/stores/base/extendStore';
import _ from 'lodash'
import IIndexStore from '../iIndexStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import { message } from 'antd';
import iSearchTypes from 'src/types/iSearchTypes';
import iSearchApi from 'src/apis/iSearchApi';
import { commonUtil } from 'mam-common-utils';
import { http } from 'mam-core-react';
import { IResponse } from 'mam-core-react/dist/modules/http';

interface ITabs {
    title: string;
    key: string;
}

interface IRepData {
    page_index: number
    page_size: number
    page_total: number
    record_total: number
    data: any[]
}

export default class UserDetailStore extends ExtendStore {
    @observable
    public entityTypes: ITabs[] = [];
    @observable
    public currType: string = '';
    @observable
    public isReqList: boolean = false;
    @observable
    public entityList?: IRepData

    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
    }

    @action
    public queryFullSearchData(): Promise<iSearchTypes.IFullSearchDataRes | undefined> {
        return new Promise((resolve, reject) => {
            let param: iSearchTypes.IFullSearchDataReq = {
                pageIndex: 1,
                pageSize: 60,
                personId: "",
                keyword: [],
                facetConditions: [],
                conditions: [],
                sorts: [
                    {
                        field: "_score",
                        desc: true
                    }
                ]
            }
            iSearchApi.queryFullSearchData(param).then(res => {
                runInAction(() => {
                    if (res.data.data && res.data.data.data) {
                        let currArrs = _.find(res.data.data.aggrs, { facetFieldName: 'type_' })
                        if (currArrs && currArrs.facetValue && currArrs.facetValue.length > 0) {
                            let arr: ITabs[] = []
                            currArrs.facetValue?.forEach(item => {
                                let obj: any = {}
                                obj.title = item.showValue
                                obj.key = item.value
                                arr.push(obj)
                            })
                            this.setCurrType(arr[0].key)
                            this.entityTypes = arr
                        }
                    }
                    resolve(res.data.data);
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            })
        })
    }

    @action
    public queryEntity(page: number, isAppend?: boolean): Promise<any> {
        const userCode: string | undefined = commonUtil.getUrlQueryParam('userCode');
        this.isReqList = true
        return new Promise((resolve, reject) => {
            http.get<IResponse<IRepData>>(`/smcds/v1/report/user/content?user_code=${userCode}&page_index=${page || 1}&page_size=20&entity_type=${this.currType}`).then(res => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        if (!isAppend){
                            this.entityList = res.data.data;
                        } else if(this.entityList && res.data.data && res.data.data.data.length > 0){
                            this.entityList.data = this.entityList.data.concat(res.data.data.data);
                            this.entityList.page_index = res.data.data.page_index;
                            this.entityList.page_size = res.data.data.page_size;
                            this.entityList.page_total = res.data.data.page_total; 
                            this.entityList.record_total = res.data.data.record_total;
                        }
                        resolve(res.data.data);
                        this.isReqList = false;
                    }
                })
            }).catch((err) => {
                if (err.data && err.data.error && err.data.error.title){
                    this.isReqList = false;
                    message.error(err.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public setCurrType(type: string) {
        this.entityList = undefined
        this.currType = type;
        this.queryEntity(1)
    }
}