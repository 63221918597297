import ExtendStore from "../base/extendStore"
import { Stores } from '..';
import LeftNavStore from './leftNavStore';
import AllAlbumsStore from './subModules/allAlbumsStore/allAlbumsStore';
import CatalogTaskStore from './subModules/catalogTask/catalogTaskStore'
import UseAuditStore from './subModules/useAudit/useAuditStore'
import ColumnManageStore from './subModules/columnManage/columnManageStore'
import { observable, action } from 'mobx';
import contentMgTypes from 'src/types/contentMgTypes';
import BulletinManageStore from './subModules/bulletinManage/BulletinManageStore'
import RecycleBinStore from './subModules/recycleBinStore/recycleBinStore'
import MyCollectionStore from './subModules/myCollectionStore/myCollectionStore'
import AllTopicStore from './subModules/allTopicStore/allTopicStore'
import CatalogStatisticStore from "./subModules/catalogStatistic/catalogStatisticStore";
import ExportAuditStore from "./subModules/exportAuditStore/exportAuditStore";
export default class ContentMgStore extends ExtendStore {
    @observable
    public iframeInfo: contentMgTypes.IIframeInfo = {
        src: '',
        show: false
    };
    public leftNavStore: LeftNavStore;
    public allAlbumsStore: AllAlbumsStore;
    public catalogTaskStore: CatalogTaskStore;
    public useAuditStore: UseAuditStore;
    public columnManageStore: ColumnManageStore;
    public bulletinManageStore: BulletinManageStore;
    public recycleBinStore: RecycleBinStore;
    public myCollectionStore: MyCollectionStore;
    public allTopicStore: AllTopicStore;
    public exportAuditStore: ExportAuditStore;
    public catalogStatisticStore:CatalogStatisticStore
    
    public constructor(stores: Stores) {
        super(stores);
        this.leftNavStore = new LeftNavStore(stores, this);
        this.allAlbumsStore = new AllAlbumsStore(stores, this);
        this.catalogTaskStore = new CatalogTaskStore(stores, this);
        this.useAuditStore = new UseAuditStore(stores, this);
        this.columnManageStore = new ColumnManageStore(stores, this)
        this.bulletinManageStore = new BulletinManageStore(stores, this)
        this.recycleBinStore = new RecycleBinStore(stores, this)
        this.myCollectionStore = new MyCollectionStore(stores)
        this.allTopicStore = new AllTopicStore(stores)
        this.exportAuditStore = new ExportAuditStore(stores, this)
        this.catalogStatisticStore = new CatalogStatisticStore(stores, this)
    }

    @action
    public setIframeInfo(info: contentMgTypes.IIframeInfo){
        this.iframeInfo = info;
    }
}
