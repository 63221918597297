import UserStore from './userStore'
import ConfigStore from "./configStore";
import SearchStore from "./cc/searchStore/searchStore";
import CloudStore from "./cc/cloudStore/cloudStore";
import WorkteamStore from "./workteam/workteamStore";
import ShareStore from "./share/shareStore";
import WorkSpaceStore from 'src/stores/cc/workSpaceStore';
import EntityStore from "./entity/entityStore";
import RecycleStore from "./cc/recycleStore";
import WorkteamDetailsStore from './cc/workteamDetailsStore';
import FavoriteStore from './cc/favoriteStore';
import ShareDetailStore from './shareDetail/shareDetailStore';
import BasketStore from './basket/basketStore'
import LangStore from './langStore'
import EmptyEntityStore from './emptyEntity/emptyEntityStore';
import CatalogStore from "./catalog/catalogStore";
import IndexStore from "./index/indexStore";
import ProgramListStore from './emptyEntity/programListStore';
import ImageSearchStore from './imageSearch/imageSearchStore';
import RejectTaskStore from './cc/rejectTask/rejectTaskStore';
import AttachmentStore from './attachment/attachmentStore';
import PrivateSaveStore from '../custom/wuxi/stores/privateSaveStore';
import ISearchStore from './iSearch/iSearchStore';
import IEntityStore from './iEntity/iEntityStore';
import ICatalogStore from './iCatalog/iCatalogStore';
import PortletStore from './portlet/portletStore';
import IAlbumDetailStore from './iAlbumDetail/iAlbumDetailStore';
import ContentMgStore from './contentMg/contentMgStore';
import IIndexStore from './iIndex/iIndexStore';
import AppStore from './app/appStore';
import ZjStore from 'src/custom/zhejiang/stores/zjStore';
import PersonalCenterStore from 'src/stores/personalCenter/personalCenterStore'
import WebUploadStore from './webUpload/webUploadStore';
import MrcStore from 'src/custom/mrc/stores/mrcStore';
import BatchEditStore from './batchEdit/batchEditStore';
import RepeatAuditStore from './contentMg/subModules/repeatAudit/repeatAuditStore';
import DatasetDetailsStore from './datasetDetails/datasetDetailsStore';
import IHeaderStore from './iHeader/iHeaderStore';
import ManageCenterStore from './manageCenter/manageCenterStore';
import CopyrightStore from './copyright/copyrightStore';
import WorkbenchStore from './workbench/workbenchStore';

export class Stores {
    public configStore: ConfigStore = new ConfigStore(this)
    public iHeaderStore: IHeaderStore = new IHeaderStore(this)
    public userStore: UserStore = new UserStore(this)
    public searchStore: SearchStore = new SearchStore(this)
    public cloudStore: CloudStore = new CloudStore(this)
    public workteamStore: WorkteamStore = new WorkteamStore(this)
    public shareStore: ShareStore = new ShareStore(this)
    public workSpaceStore: WorkSpaceStore = new WorkSpaceStore(this)
    public entityStore: EntityStore = new EntityStore(this)
    public recycleStore: RecycleStore = new RecycleStore(this)
    public workteamDetailsStore: WorkteamDetailsStore = new WorkteamDetailsStore(this)
    public favoriteStore: FavoriteStore = new FavoriteStore(this)
    public shareDetailStore: ShareDetailStore = new ShareDetailStore(this)
    public basketStore: BasketStore = new BasketStore(this)
    public langStore: LangStore = new LangStore(this)
    public emptyEntityStore: EmptyEntityStore = new EmptyEntityStore(this)
    public programListStore: ProgramListStore = new ProgramListStore(this)
    public catalogStore: CatalogStore = new CatalogStore(this)
    public indexStore: IndexStore = new IndexStore(this)
    public imageSearchStore: ImageSearchStore = new ImageSearchStore(this)
    public rejectTaskStore: RejectTaskStore = new RejectTaskStore(this)
    public attachmentStore: AttachmentStore = new AttachmentStore(this)
    public privateSaveStore: PrivateSaveStore = new PrivateSaveStore(this)
    public iSearchStore: ISearchStore = new ISearchStore(this)
    public iEntityStore: IEntityStore = new IEntityStore(this)
    public iCatalogStore: ICatalogStore = new ICatalogStore(this)
    public portletStore: PortletStore = new PortletStore(this)
    public iAlbumDetailStore: IAlbumDetailStore = new IAlbumDetailStore(this)
    public contentMgStore: ContentMgStore = new ContentMgStore(this)
    public iIndexStore: IIndexStore = new IIndexStore(this)
    public appStore: AppStore = new AppStore(this);
    public zjStore: ZjStore = new ZjStore(this);
    public personalCenterStore: PersonalCenterStore = new PersonalCenterStore(this)
    public webUploadStore: WebUploadStore = new WebUploadStore(this)
    public manageCenterStore: ManageCenterStore = new ManageCenterStore(this)
    public mrcStore: MrcStore = new MrcStore(this)
    public batchEditStore:BatchEditStore = new BatchEditStore(this)
    public repeatAuditStore: RepeatAuditStore = new RepeatAuditStore(this)
    public datasetDetailsStore: DatasetDetailsStore = new DatasetDetailsStore(this)
    public copyrightStore:CopyrightStore = new CopyrightStore(this)
    public workbenchStore: WorkbenchStore = new WorkbenchStore(this)
}
export let stores = new Stores();
