import ExtendStore from 'src/stores/base/extendStore';
import ContentMgStore from '../../contentMgStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import contentMgTypes from 'src/types/contentMgTypes';
import contentMgApi from 'src/apis/contentMgApi';
import { message } from 'antd';
import iSearchTypes from 'src/types/iSearchTypes';
import _ from 'lodash'

export default class AllAlbumsStore extends ExtendStore {
    @observable
    public allAlbumsRes: contentMgTypes.allAlbums.ISearchAlbumsRes | undefined;
    @observable
    public isReqAlbums: boolean = false;
    private contentMgStore: ContentMgStore;

    public constructor(stores: Stores, contentMgStore: ContentMgStore) {
        super(stores);
        this.contentMgStore = contentMgStore;
        this.initSearchFilterStore(stores);
        this.initSelectAllStore(stores, 'stores.contentMgStore.allAlbumsStore.allAlbumsRes.data', 'selected');
    }

    @action
    public searchAlbums(page?: number){
        return new Promise((resolve, reject)=>{
            let params: contentMgTypes.allAlbums.ISearchAlbumsReq = {
                pageIndex: page || 1,
                pageSize: 30,
                keyword: [],
                sorts:[
                    {
                        field: 'createDate_',
                        desc: true
                    }
                ]
            }
            this.allAlbumsRes = undefined;
            this.isReqAlbums = true;
            contentMgApi.allAlbums.searchAlbums(params).then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        res.data.data.data.forEach((item)=>{
                            item.name = item.name_;
                            item.duration = item.duration_;
                            item.contentId = item.contentId_;
                            item.framerate = item.framerate_;
                        })
                        this.allAlbumsRes = res.data.data;
                        if (this.allAlbumsRes){
                            this.initFacets(this.allAlbumsRes.aggrs);
                        }
                    }
                    this.isReqAlbums = false;
                    resolve(true);
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                this.isReqAlbums = false;
                reject();
            });
        })
    }

    /** 初始化层面数据 */
    private initFacets(facets: iSearchTypes.IFacets[]) {
        if (facets){
            facets.forEach(item => {
                if (item.facetFieldName === 'createDate_') {
                    item.startDate = '';
                    item.endDate = '';
                }
                item.showMore = false;
                if (item.facetValue) {
                    item.facetValue.forEach((o: iSearchTypes.IFacetsFacetValue) => {
                        o.isShow = true;
                        o.isSelected = false;
                    });
                }
                item.checkboxIsOpen = false;
            });
        }
    }
}