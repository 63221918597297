import ExtendStore from "../../base/extendStore";
import { action, observable, runInAction } from "mobx";
import entityTypes from "../../../types/entityTypes";
import entityApi from "../../../apis/entityApi";
import * as React from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ExportSectionModal from "../../../pages/entity/modules/exportSectionModal/exportSectionModal";
import { Stores } from "../../index";
import SaveSectionModal from "../../../pages/entity/modules/saveSectionModal/saveSectionModal";
import TvbSaveSectionModal from "src/custom/tvb/components/tvbSaveSectionModal/tvbSaveSectionModal";
import _ from "lodash";
import playerUtil from "mam-common-utils/dist/modules/playerUtil";
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import EntityStore from '../entityStore';
import IEntityStore from 'src/stores/iEntity/iEntityStore';
import iEntityTypes from 'src/types/iEntityTypes';

//子节点映射，配置规则告诉代码该如何查找子节点
const catalogTreeChildrenMapping: any = {
    "program": ["sequence", "scene", "shot"],
    "sequence": ["scene", "shot"],
    "scene": ["shot"]
};

export default class MediaStore extends ExtendStore {
    /** 编目信息 */
    @observable
    public catalog: entityTypes.ICatalog[];
    /** 编目信息（树形） */
    @observable
    public catalogTree: entityTypes.ICatalogTreeItem[];
    /** 编目树展开的keys */
    @observable
    public catalogTreeExpandedKeys: string[];
    /** 当前正在编辑的标记点 */
    @observable
    public currentKeyframe: entityTypes.IMarKeyframe | iEntityTypes.IKeyframe;
    /** 当前正在编辑的标记段 */
    @observable
    public currentClip: entityTypes.IEntityScenes;
    /** 当前正在编辑的编目 */
    @observable
    public currentCatalog: entityTypes.IEditCatalog;
    /** 编目层数量统计 */
    @observable
    public catalogDataCount: entityTypes.ICatalogDataCount = {
        program: 0,
        sequence: 0,
        scene: 0,
        shot: 0
    };
    /** 关键帧字典，可通过帧号寻找关键帧 */
    @observable
    public keyframeDict: any;

    public exportSectionModal: React.RefObject<ExportSectionModal>;
    public saveSectionModal: React.RefObject<SaveSectionModal>
    public tvbSaveSectionModal: React.RefObject<TvbSaveSectionModal>

    private entityStore: EntityStore | IEntityStore;

    public constructor(stores: Stores, entityStore: EntityStore | IEntityStore) {
        super(stores)

        this.exportSectionModal = React.createRef();
        this.saveSectionModal = React.createRef();
        this.tvbSaveSectionModal = React.createRef();
        this.entityStore = entityStore;
    }

    @action
    public setCurrentKeyframe(keyframe: entityTypes.IMarKeyframe | iEntityTypes.IKeyframe) {
        this.currentKeyframe = keyframe;
    }

    @action
    public setCurrentClip(clip: entityTypes.IEntityScenes) {
        this.currentClip = clip;
    }

    @action
    public setCurrentCatalog(catalog: entityTypes.IEditCatalog) {
        this.currentCatalog = catalog;
    }

    /**
     * 显示新增标记点界面
     */
    @action
    public showCreateKeyframe(data: any) {
        const frameRate = this.entityStore.entity.paths && this.entityStore.entity.paths.length > 0
            ? this.entityStore.entity.paths[0].frameRate : 0;
        let keyframe: entityTypes.IMarKeyframe = {
            isNew: true,
            keyframeNo: data.currentTime,
            inpoint: data.currentTime,
            filePath: data.img,
            description: '',
            isIconFrame: false
        };
        this.showKeyframeTab(keyframe, true);
    }

    /** 显示指定标记点界面 */
    @action
    public showKeyframeTab(keyframe: entityTypes.IMarKeyframe | iEntityTypes.IKeyframe, edit: boolean) {
        this.setCurrentKeyframe(keyframe);
        this.entityStore.changeTopTab('keyframeMetadata')
        if (edit) {
            this.entityStore.setMetaEditable(true);
        }
        else {
            this.entityStore.setMetaEditable(false);
        }
    }

    @action
    public getCatalog() {
        return new Promise((resolve, reject) => {
            const entity = this.entityStore.entity;
            //获取节目类型
            let programForm = '';
            let pf = _.find(this.entityStore.entity.entitydata, {
                fieldName: 'programform'
            });
            if (pf && pf.value) {
                let pfvalue = JSON.parse(pf.value as string);
                if (_.isArray(pfvalue) && pfvalue.length > 0) {
                    programForm = pfvalue[0];
                }
            }
            entityApi.getCatalog(entity.contentId, entity.type, entity.paths && entity.paths.length > 0 ? entity.paths[0].frameRate : 0, programForm).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.catalog = res.data.data;
                        this.catalog.forEach((cata) => {
                            switch (cata.code) {
                                case 'program':
                                    this.catalogDataCount.program = cata.data ? cata.data.length : 0;
                                    break;
                                case 'sequence':
                                    this.catalogDataCount.sequence = cata.data ? cata.data.length : 0;
                                    break;
                                case 'scene':
                                    this.catalogDataCount.scene = cata.data ? cata.data.length : 0;
                                    break;
                                case 'shot':
                                    this.catalogDataCount.shot = cata.data ? cata.data.length : 0;
                                    break;
                                default: break;
                            }
                        });
                        this.produceCatalogTree(entity.paths && entity.paths.length > 0 ? entity.paths[0].frameRate : 0);
                        resolve();
                    }
                })
            })
        });
    }

    @action
    public getKeyframes(): Promise<entityTypes.IMarKeyframe[]> {
        return new Promise((resolve, reject) => {
            let entity = this.entityStore.entity;
            entityApi.getKeyframes(entity.contentId, this.entityStore.params.site, entity.type, entity.paths && entity.paths.length > 0 ? entity.paths[0].frameRate : 0).then((res) => {
                if (res.data.data) {
                    this.updateMarkeyframes(res.data.data);
                    resolve(res.data.data);
                }
            })
        })
    }

    @action
    public updateMarkeyframes(keyframes: entityTypes.IMarKeyframe[]) {
        this.entityStore.entity.markeyframes = keyframes;
    }

    /**
     * 显示新增标记段界面
     */
    @action
    public showCreateClip(data: any) {
        let clip: entityTypes.IEntityScenes;

        if (this.entityStore.entity.type === 'video') {
            clip = {
                keyframePath: data.filePath,
                markIn: Math.round(data.trimin * 10000000),
                markOut: Math.round(data.trimout * 10000000),
                isNew: true
            }
            clip.duration = clip.markOut - clip.markIn;
        } else {
            clip = {
                markIn: Math.round(data.trimin * 10000000),
                markOut: Math.round(data.trimout * 10000000),
                isNew: true
            }
            clip.duration = clip.markOut - clip.markIn;
        }
        this.showClipTab(clip, true);
    }

    /** 显示指定标记段界面 */
    @action
    public showClipTab(clip: entityTypes.IEntityScenes, edit: boolean) {
        this.setCurrentClip(clip);
        this.entityStore.changeTopTab('clipMetadata')
        if (edit) {
            this.entityStore.setMetaEditable(true);
        }
        else {
            this.entityStore.setMetaEditable(false);
        }
    }

    /** 显示编目tab */
    @action
    public showCatalogTab(catalog: entityTypes.IEditCatalog) {
        this.setCurrentCatalog(catalog);
        this.entityStore.selectedTab.top = 'catalogInfo';
        this.entityStore.setMetaEditable(true);
    }

    @action
    public getClips() {
        let entity = this.entityStore.entity;
        entityApi.getClips(entity.contentId, this.entityStore.params.site, entity.type, entity.paths && entity.paths.length > 0 ? entity.paths[0].frameRate : 0).then((res) => {
            if (res.data.data) {
                this.updateScenes(res.data.data);
            }
        })
    }

    @action
    public updateScenes(scenes: entityTypes.IEntityScenes[]) {
        this.entityStore.entity.scenes = scenes;
    }

    @action
    public onCurrentClipDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.currentClip.markDesc = e.target.value;
    }

    @action
    public onCurrentClipCharaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.currentClip.character = e.target.value;
    }

    @action
    public onCurrentClipSubtitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.currentClip.subtitle = e.target.value;
    }

    @action
    public onCurrentClipLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.currentClip.location = e.target.value;
    }

    @action
    public onCurrentKeyframeDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.currentKeyframe.description = e.target.value;
    }

    @action
    public handleKeyframeIsIconFrame = (e: CheckboxChangeEvent) => {
        this.currentKeyframe.isIconFrame = e.target.checked;
    }

    @action
    public setCatalogTreeExpandedKeys(expandedKeys: string[]) {
        this.catalogTreeExpandedKeys = expandedKeys;
    }

    @action
    public initKeyframeDict(fileGroups: entityTypes.IFileGroup[]) {
        this.keyframeDict = {};
        if (fileGroups){
            fileGroups.forEach(groupItem => {
                if (!groupItem || groupItem.groupName !== 'keyframe_') {
                    return;
                }
                groupItem.fileItems.forEach(fileItem => {
                    if (fileItem.fileFormat.keyFrameFormat) {
                        this.keyframeDict[fileItem.fileFormat.keyFrameFormat.keyFrameNo] = {
                            keyframePath: fileItem.filePath
                        };
                    }
                });
            });
        }
    }

    private produceCatalogTree(frameRate: number) {
        this.catalogTree = [];
        let programInst = _.find(this.catalog, {
            code: "program"
        });
        this.catalogTreeExpandedKeys = [];
        if (programInst) {
            this.catalogTree = commonUtil.copyObj(programInst.data as entityTypes.ICatalogTreeItem[]);
            let programCount = 1;
            this.catalogTree.forEach((cd, index) => {
                if (programInst) {
                    cd.key = commonUtil.getUuid();
                    this.catalogTreeExpandedKeys.push(cd.key);
                    cd.code = programInst.code;
                    cd.level = 1;
                    cd.baseData.duration = cd.baseData.outPoint - cd.baseData.inPoint;
                    cd.num = programCount;
                    cd.guid = programInst.guid;
                    programCount++;
                    this.produceChildren(cd, frameRate);
                }
            });
        }
    }

    //用编码获取子节点
    private getChildByCode(codeNum?: string) {
        if (!codeNum) {
            return undefined;
        }
        let childCodes = catalogTreeChildrenMapping[codeNum];
        if (!childCodes) {
            return undefined;
        }
        let children: entityTypes.ICatalog[] = [];
        let child: entityTypes.ICatalog | undefined;
        let code;
        for (let i = 0, j = childCodes.length; i < j; i++) {
            code = childCodes[i];
            child = _.find<entityTypes.ICatalog>(this.catalog, {
                'code': code
            });
            if (child) {
                children.push(child);
            }
        }
        return children;
    }

    //按顺序插入数组
    private insertIntoArr(arr: entityTypes.ICatalogData[], item: entityTypes.ICatalogData) {
        if (arr.length === 0) {
            arr.push(item);
            return;
        }
        let insertIdx = 0;
        arr.forEach((o) => {
            if (item.baseData.inPoint > o.baseData.inPoint) {
                insertIdx++;
            }
        });
        arr.splice(insertIdx, 0, item);
    }

    private produceChildren(parent: entityTypes.ICatalogTreeItem, frameRate: number) {
        parent.children = [];
        let children = this.getChildByCode(parent.code);
        if (!children) {
            return;
        }
        children.forEach((child) => {
            _.forEach(child.data || child.layerFields, (data: entityTypes.ICatalogTreeItem) => {
                data.notesLength = 0;
                if (data.fieldData) {
                    data.fieldData.forEach(ele => {
                        if ((ele as any).note && data.notesLength) {
                            data.notesLength++;
                        }
                    })
                } else if (data.itemData) {
                    data.itemData.forEach(ele => {
                        if ((ele as any).note && data.notesLength) {
                            data.notesLength++;
                        }
                    })
                }
                if (!data.hasParent) //防止节点被添加到祖先节点下
                {
                    data.key = commonUtil.getUuid();
                    this.catalogTreeExpandedKeys.push(data.key);
                    data.code = child.code;
                    let dataIn = playerUtil.second2Frame(data.baseData.inPoint / Math.pow(10.0, 7), frameRate);
                    let dataOut = playerUtil.second2Frame(data.baseData.outPoint / Math.pow(10.0, 7), frameRate);
                    let parentIn = playerUtil.second2Frame(parent.baseData.inPoint / Math.pow(10.0, 7), frameRate);
                    let parentOut = playerUtil.second2Frame(parent.baseData.outPoint / Math.pow(10.0, 7), frameRate);
                    if (dataIn >= parentIn &&
                        dataOut <= parentOut) //在父节点范围内
                    {
                        data.hasParent = true;
                        if (parent.level) {
                            data.level = parent.level + 1;
                        }
                        data.baseData.duration = data.baseData.outPoint - data.baseData.inPoint;
                        data.guid = child.guid;
                        if (parent.children) {
                            this.insertIntoArr(parent.children, data);
                        }
                        this.produceChildren(data, frameRate);
                    }
                }
            });
        });
    }
}
