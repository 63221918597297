import ExtendStore from '../../base/extendStore';
import { action, observable, runInAction } from 'mobx';
import _ from 'lodash'
import { message } from 'antd';
import { Stores } from 'src/stores'
import PersonalCenterStore from '../personalCenterStore'
import personalCenterTypes from 'src/types/personalCenterTypes'
import personalCenterApi from 'src/apis/personalCenterApi';


export default class MessageStore extends ExtendStore {
    public personalCenterStore: PersonalCenterStore

    @observable
    public defaultBtnValue: string = 'all'
    @observable
    public statisticType: personalCenterTypes.INavType[] = [
        {
            value: 'all',
            title: '全部类型'.l('personalCenter.allType'),
            count: 100
        },
        {
            value: 'success',
            title: '任务成功'.l('personalCenter.successType'),
            count: 50
        },
        {
            value: 'reject',
            title: '任务打回'.l('personalCenter.rejectType'),
            count: 20
        },
        {
            value: 'fail',
            title: '任务失败'.l('personalCenter.failType'),
            count: 30
        }
    ]
    @observable
    public selectedRowKeys: string[]

    @observable
    public tableDataInfo: personalCenterTypes.ITableRes;

    constructor(stores: Stores, personalCenterStore: PersonalCenterStore) {
        super(stores)
        this.personalCenterStore = personalCenterStore
    }

    @action
    public setDefaultBtnValue(value: string) {
        this.defaultBtnValue = value
    }
    @action
    public setSelectedRowKeys(key: string[]) {
        this.selectedRowKeys = key
    }

    @action
    public setPageIndex(index: number) {
        this.query(index)
    }

    @action
    public query(pageIndex: number) {
        return new Promise((resolve, reject) => {
            personalCenterApi.getMessageTable(pageIndex).then(res => {
                runInAction(() => {
                    if(res.data.success && res.data.data) {
                        this.tableDataInfo = res.data.data
                        resolve(true)
                    }
                })
            }, err => {
                if (err.data.error && err.data.error.title){
                    message.error(err.data.error.title);
                }
                reject();
            })
        })
    }

}