import { Stores } from 'src/stores';
import ExtendStore from 'src/stores/base/extendStore';
import IIndexStore from '../iIndexStore';
import { action, runInAction, observable } from 'mobx';
import iIndexApi from 'src/apis/iIndexApi';
import { message } from 'antd';
import _ from 'lodash'
import iIndexTypes from 'src/types/iIndexTypes';
import moment from 'moment'
import iSearchTypes from 'src/types/iSearchTypes';
import iSearchApi from 'src/apis/iSearchApi';

export default class TimeListStore extends ExtendStore {
    @observable
    public selectedColumnValue: string | undefined;
    @observable
    public timelist: iIndexTypes.secList.ITimeList[] = [];
    @observable
    public selectedTime = 'day';
    @observable
    public timeListAndColumns?: iIndexTypes.secList.IGetTimeListAndColumnsRes = undefined;
    @observable
    public columns?: iIndexTypes.secList.IGetTimeListAndColumnsColumns[];
    public qTimeIdx = 0;// 当前查询到第几个时间点
    public qTimeHasCtCount = 0;// 当前查询有内容的时间点总数
    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
    }

    @action
    public getTimeListAndColumns(){
        return new Promise((resolve, reject)=>{
            iIndexApi.getTimeListAndColumns(this.selectedTime, this.selectedColumnValue || '').then(res=>{
                runInAction(()=>{
                    if (res.data.data){
                        if (res.data.data.dateFacets){
                            res.data.data.dateFacets.forEach(item=>{
                                if (this.selectedTime === 'day') {
                                    let dateStr = item.key;
                                    const dateObj = moment(dateStr);
                                    item.showKey = dateObj.format('YYYY-MM-DD HH:mm')
                                }
                                else if (this.selectedTime === 'week' || this.selectedTime === 'month') {
                                    let dateStr = item.key;
                                    const dateObj = moment(dateStr);
                                    item.showKey = dateObj.format('YYYY-MM-DD')
                                }
                                else if (this.selectedTime === 'year') {
                                    let dateStr = item.key;
                                    const dateObj = moment(dateStr);
                                    item.showKey = dateObj.format('YYYY-MM')
                                }
                            })
                        }
                        this.timeListAndColumns = res.data.data;
                        if (!this.columns && this.timeListAndColumns){
                            this.columns = this.timeListAndColumns.columns
                        }
                    }
                    resolve();
                })
            }).catch(res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            })
        })
    }

    @action
    public setSelectedTime(time: string) {
        this.selectedTime = time;
    }

    @action
    public queryFullSearchData(timeItem: iIndexTypes.secList.IGetTimeListAndColumnsDateFacets, page?: number) {
        return new Promise((resolve, reject) => {
            let param: iSearchTypes.IFullSearchDataReq = {
                pageIndex: page || 1,
                pageSize: 8,
                personId: "",
                keyword: [],
                facetConditions: [],
                conditions: [
                    {
                        field: 'createDate_',
                        value: this.getDateareaConditionVal(timeItem)
                    }
                ],
                sorts: [
                    {
                        field: 'createDate_',
                        desc: true
                    }
                ],
                sourceType: 'entity'
            }
            if (this.selectedColumnValue) {
                param.facetConditions.push({
                    field: "column",
                    value: [this.selectedColumnValue]
                })
            }
            iSearchApi.queryFullSearchData(param).then(res => {
                runInAction(() => {
                    if (res.data.data && res.data.data.data && res.data.data.data.length > 0) {
                        res.data.data.data.forEach((item) => {
                            item.name = item.name_;
                            item.duration = item.duration_;
                            item.contentId = item.contentId_;
                            item.framerate = item.framerate_;
                        })
                        if (!page){
                            timeItem.entityList = res.data.data.data;
                        }
                        else if (page && page > 1 && timeItem.entityList){
                            timeItem.entityList = timeItem.entityList.concat(res.data.data.data);
                        }
                        this.qTimeHasCtCount++;
                    }
                    else {
                        timeItem.entityList = [];
                    }
                    resolve(undefined);
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public setSelectedColumnValue(value: string) {
        this.selectedColumnValue = value;
    }

    private getDateareaConditionVal(timeItem: iIndexTypes.secList.IGetTimeListAndColumnsDateFacets): string[] {
        if (this.selectedTime === 'day') {
            let dateStr = timeItem.key;
            const dateObj = moment(dateStr);
            const startDate = dateStr;
            const endDate = dateObj.format('YYYY-MM-DD HH') + ':59:59';
            return [`${startDate} TO ${endDate}`];
        }
        else if (this.selectedTime === 'week' || this.selectedTime === 'month') {
            let dateStr = timeItem.key;
            const dateObj = moment(dateStr);
            const startDate = dateStr;
            const endDate = dateObj.format('YYYY-MM-DD') + ' 23:59:59';
            return [`${startDate} TO ${endDate}`];
        }
        else { // year
            let dateStr = timeItem.key;
            const dateObj = moment(dateStr);
            const endObj = dateObj.add(1, 'months').add(-1, 'seconds');
            const startDate = dateStr;
            const endDate = endObj.format('YYYY-MM-DD HH:mm:ss');
            return [`${startDate} TO ${endDate}`];
        }
    }
}