import ExtendStore from "../../../base/extendStore"
import { observable, action, runInAction } from 'mobx'
import ContentMgStore from '../../contentMgStore'
import { Stores } from 'src/stores'
import myCollectionApi from 'src/apis/myCollectionApi'
import myCollectionTypes from 'src/types/myCollectionTypes'
import _ from 'lodash'
import { message } from 'antd'
import SelectAllStore from "../../../extends/selectAllStore";
import { IEntityType } from "src/types/configTypes";
import datasetDetailsApi from "src/apis/datasetDetailsApi"

export default class MyCollectionStore extends ExtendStore {
    @observable
    public order: string//时间升序降序
    @observable
    public type: boolean = false//模式
    @observable
    public myCollectionListResponse: myCollectionTypes.IGetMyCollectionListResponse | undefined
    public selectAllStore: SelectAllStore
    @observable
    public filterEntityTypes: IEntityType[] = []//分类
    public constructor(stores: Stores) {
        super(stores)
        this.initDownloadStore(stores)
        this.initFavoriteStore(stores)
        this.initOutstoreStore(stores);
        this.initSelectAllStore(stores, 'stores.contentMgStore.myCollectionStore.myCollectionListResponse.data', 'selected');

    }
    @action
    public init = () => {
        // //设置分类和选中分类
        this.filterEntityTypes = this.stores.configStore.config.entityTypes.filter((item) => {
            return item.code !== 'rundown' && item.code !== 'sequence'
                && item.code !== 'scene' && item.code !== 'shot' && item.code !== 'biz_sobey_newsscript'
        })
    }
    @action
    public getMyCollectionList(page: number) {
        let params = {
            keyword: "",
            order: "desc",
            pageIndex: 1,
            pageSize: 20,
            sort: "collectiontime"
        }
        if (this.order) {
            params = { ...params, order: this.order }
        }
        this.myCollectionListResponse = undefined
        return new Promise((resolve, reject) => {
            myCollectionApi.getMyCollectionList({ request: params }).then(res => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.myCollectionListResponse = res.data.data
                        resolve(true)
                    } else {
                        reject(false)
                    }
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    //取消收藏
    @action
    public cancelCollection(contentIds: string[]) {
        return new Promise((resolve, reject) => {
            datasetDetailsApi.collection({ contentIds, favorite: true }).then(res => {
                if (res.data.data?.successCount) {
                    message.success("取消收藏成功")
                    this.getMyCollectionList(1)
                }
                resolve(res)
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public changeType(type: boolean) {
        this.type = type
    }
    @action
    public changeOrder(type: string) {
        this.order = type
        this.getMyCollectionList(1)
    }
    public getIsSelectItem = () => {
        if (!this.myCollectionListResponse) {
            return []
        } else {
            if (this.myCollectionListResponse.data) {
                return this.myCollectionListResponse.data.filter((item) => {
                    return item.selected
                })
            }
            return []
        }
    }
    //下载
    public download = (item: myCollectionTypes.IGetMyCollectionList) => {
        if (item.type !== 'sequence' && item.type !== 'scene' && item.type !== 'shot') {
            this.downloadStore.addContentIds([item.contentId])
            this.downloadStore.initAllItems();
        }
    }
}