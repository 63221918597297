import CcStore from './ccStore';
import { action, observable } from "mobx";
import ccTypes from "../../types/ccTypes";
import { Stores, stores } from './../index';
import { workspaceTypes } from 'src/types/workspaceTypes';
import ccApi from 'src/apis/ccApi';

export default class WorkSpaceStore extends CcStore {
    @observable
    public params: workspaceTypes.IParams

    @observable
    public fullSearchData: ccTypes.cloudTypes.IFullSearchRes

    public sortItems: ccTypes.searchTypes.ISortItem[] = [
        { text: '入库时间', field: 'createDate_', desc: 'desc' },
        { text: '入库时间', field: 'createDate_', desc: 'asc' },
        { text: '标题名', field: 'name_', desc: 'desc' },
        { text: '标题名', field: 'name_', desc: 'asc' },
        { text: '相关度', field: 'default', desc: 'desc', hideDirection: true }
    ]
    /** 当前选中排序类型 */
    @observable
    public currentSort: ccTypes.searchTypes.ISortItem = this.sortItems[0];

    @observable
    public workSpaceTree: workspaceTypes.IWorkspaceTree[] = []

    @observable
    public showSub: boolean = false

    constructor(store: Stores) {
        super(store)
        this.initDataViewModeStore(store)
        this.initSelectAllStore(store, 'stores.workSpaceStore.fullSearchData.data', 'selected')
        this.initCopyStore(store)
        this.initDownloadStore(store)
        this.initFavoriteStore(store)
        this.initShareStore(store)
        this.initMoveStore(store)
        this.initDeleteStore(store)
        this.initRenameStore(store)
        this.initCreateFolderStore(store)
        this.initCreatePlanningStore(store)
        this.initUnlockStore(store)
        this.initOutstoreStore(store)
        this.initUploadStore(store);
        this.initUploadPanelStore(store)
    }

    @action
    public setParams(workSpaceParams: workspaceTypes.IParams) {
        this.params = workSpaceParams
    }

    @action
    public setCurrentSort(sort: ccTypes.searchTypes.ISortItem) {
        this.currentSort = sort;
    }

    @action
    public queryFullSearchData(data: ccTypes.cloudTypes.IFullSearchRes) {
        /** 无锡定制：根据素材id获取云端高低码状态 */
        if(this.stores.configStore.config.pushPullSwitch){
            this.getProgramNameCloud(data.data)
        }
        this.fullSearchData = data
    }

    @action
    public setNewRename(index: number, newName: string) {
        this.fullSearchData.data[index].name = newName
    }

    @action
    public setRename(index: number, editMode: boolean) {
        if (editMode) {
            this.fullSearchData.data[index].oldName = this.fullSearchData.data[index].name
        }
        this.fullSearchData.data[index].editMode = editMode
    }

    @action
    public getWorkSpaceTree() {
        if (!this.workSpaceTree || this.workSpaceTree.length === 0) {
            this.params.folder = ''
        }
        return new Promise((resolve) => {
            ccApi.sideContentApi.getWorkSpace(this.buildParams('folder')).then(res => {
                if (res.data.success && res.data.data) {
                    this.setWorkspaceTree(res.data.data.data.map(item => {
                        return {
                            name: item.name,
                            contentId: item.contentId,
                            children: [],
                            level: 1,
                            showChildren: true
                        }
                    }))
                    if (!this.params.folder) {
                        this.queryFullSearchData(res.data.data)
                    }
                    resolve()
                }
            })
        })
    }

    public queryData() {
        return new Promise((resolve) => {
            ccApi.sideContentApi.getWorkSpace(this.buildParams(!this.params.folder ? 'folder' : 'all')).then(data => {
                if (data.data.data) {
                    this.queryFullSearchData(data.data.data)
                    resolve()
                }
            })
        })
    }

    @action
    public setWorkspaceTree(tree: workspaceTypes.IWorkspaceTree[]) {
        if (this.workSpaceTree.length > 0 && this.params.folder) {
            this.workSpaceTree.forEach(item => {
                if (item.contentId === this.params.folder) {
                    item.children = tree
                } else {
                    item.children.forEach(o => {
                        if (o.contentId === this.params.folder) {
                            o.children = tree
                        }
                    })
                }
            })
        } else {
            this.workSpaceTree = tree
        }
    }

    @action
    public setShowSub(show: boolean) {
        this.showSub = show
    }

    public getParent = (): any => {
        if (this.fullSearchData && this.fullSearchData.parent && this.fullSearchData.parent.length > 0) {
            return this.fullSearchData.parent[this.fullSearchData.parent.length - 1]
        } else {
            return {
                canDelete: false,
                canExecute: false,
                canRead: false,
                canWrite: false,
                contentId: '',
                treeType: 1,
                dynamicResponse: {}
            }
        }
    }

    public isWorkspace = () => {
        const parent = this.getParent()
        //用户能使用的工作区，而不是工作区根目录('workspace_root')和制作中心('workspace')
        return parent.dynamicResponse &&
            parent.dynamicResponse.subtype !== 'workspace_root' &&
            parent.dynamicResponse.subtype !== 'workspace'
    }

    @action
    public setNewFullSearchData(fullSearchData: ccTypes.cloudTypes.IFullSearchData) {
        if (this.fullSearchData) {
            if (!fullSearchData.isCreate) {
                setTimeout(() => {
                    this.getWorkSpaceTree()
                }, 500)
            }else{
                this.fullSearchData.data.unshift(fullSearchData)
            }

        }
    }

    @action
    public refresh() {
        this.getWorkSpaceTree()
    }

    @action
    public onCreateSuccess(fullSearchData: ccTypes.cloudTypes.IFullSearchData) {
        if (this.fullSearchData) {
            this.selectAllStore.switchAllSelected(false)
            this.selectAllStore.switchListItemSelected(undefined, false)
            if (this.fullSearchData.data && this.fullSearchData.data.length > 0) {
                this.fullSearchData.data[0].contentId = fullSearchData.contentId
                this.fullSearchData.data[0].name = fullSearchData.name
                this.fullSearchData.data[0].storagedDate = fullSearchData.storagedDate
                this.fullSearchData.data[0].canDelete = true
                this.fullSearchData.data[0].canWrite = true
                this.fullSearchData.recordTotal += 1
            }
        }
    }

    @action
    public create = (model: any) => {
        this.selectAllStore.switchAllSelected(false)
        this.selectAllStore.switchListItemSelected(undefined, false)
        if (this.fullSearchData) {
            this.setNewFullSearchData(model)
        }
    }

    @action
    private buildParams(type: string): ccTypes.workSpaceTypes.IWorkSpaceReq {
        return {
            pageIndex: 1,
            pageSize: 100,
            sort: stores.configStore.config.dohaUserSortSetting.sortField ?
                stores.configStore.config.dohaUserSortSetting.sortField : 'createTime',
            order: stores.configStore.config.dohaUserSortSetting.isDesc ? 'desc' : 'asc',
            folderCode: this.params.folder,
            type,
            keyword: '',
            name: ''
        }
    }


}
