import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import IIndexStore from '../iIndexStore';
import { observable, action, runInAction } from 'mobx';
import iIndexTypes from 'src/types/iIndexTypes';
import iIndexApi from 'src/apis/iIndexApi';
import { message } from 'antd';
interface ITypeItem {
    title: string
    code: string
}
export default class TopHotStore extends ExtendStore {
    @observable
    public types: ITypeItem[] = [
        {
            title: '按浏览量排序',
            code: 'browse'
        },
        {
            title: '按收藏量排序',
            code: 'collect'
        },
        {
            title: '按使用量排序',
            code: 'use'
        }
    ]
    @observable
    public listData: iIndexTypes.secList.ITopHotItem[] | undefined;
    @observable
    public isQueryData: boolean = false;
    @observable
    public currType: string = 'browse';
    private iIndexStore: IIndexStore

    public constructor(stores: Stores, iIndexStore: IIndexStore) {
        super(stores);
        this.iIndexStore = iIndexStore;
    }


    @action
    public query(num?: number){
        this.listData = undefined
        this.isQueryData = true
        iIndexApi.getTopHotList(this.currType, num || 100).then(res=>{
            runInAction(()=>{
                if (res.data.success && res.data.data){
                    this.listData = res.data.data
                }
                this.isQueryData = false;
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
                this.isQueryData = false;
            }
        });
    }
    @action
    public setCurrType(type: string) {
        this.currType = type
    }
}